import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Platform, PlatformsV2 } from '../../enums';
import {
  UpsertableSequence,
  SequenceAPIResponse,
  Stage,
  SequenceUpsertedEvent,
  SequenceUniqueInfo,
  Sequence,
} from './../../interfaces/email-sequence.interface';
import { Subject } from 'rxjs';

@Injectable()
export class EmailSequenceService {
  constructor(private http: HttpClient) {}

  private emailSequenceUpserted = new Subject<SequenceUpsertedEvent>();
  emailSequenceUpserted$ = this.emailSequenceUpserted.asObservable();

  sendEmailSequenceUpsertedEvent(sequence: SequenceUpsertedEvent): void {
    this.emailSequenceUpserted.next(sequence);
  }

  async getEmailSequence(sequenceId: string): Promise<UpsertableSequence> {
    return this.http.get<UpsertableSequence>(environment.sequenceApi + `/sequences/${sequenceId}`).toPromise();
  }

  async createEmailSequence(sequence: Partial<UpsertableSequence>): Promise<SequenceAPIResponse> {
    return this.http.post<SequenceAPIResponse>(environment.sequenceApi + '/sequences', sequence).toPromise();
  }

  async updateEmailSequence(sequenceId: string, sequence: Partial<UpsertableSequence>): Promise<SequenceAPIResponse> {
    return this.http
      .put<SequenceAPIResponse>(environment.sequenceApi + `/sequences/${sequenceId}`, sequence)
      .toPromise();
  }

  addInfluencersToExistingSequence(
    sequenceId: string,
    influencerUsernames: string[],
    platform: Platform | PlatformsV2 = 'instagram',
  ): Promise<{ success: boolean }> {
    return this.http
      .post<{ success: boolean }>(environment.sequenceApi + `/sequences/${sequenceId}/influencers`, {
        platform: platform.toUpperCase(),
        influencerUsernames,
      })
      .toPromise();
  }

  getUsernamesForExistingMatchingInfluencers(
    sequenceId: string,
    influencerUsernames: string[],
    platform: Platform | PlatformsV2 = 'instagram',
  ): Promise<{ matchingUsernames: string[] }> {
    return this.http
      .post<{ matchingUsernames: string[] }>(
        environment.sequenceApi + `/sequences/${sequenceId}/get-usernames-for-existing-matching-influencers`,
        {
          platform: platform.toUpperCase(),
          influencerUsernames,
        },
      )
      .toPromise();
  }

  getCampaignSequences(
    campaignSlugName: string,
    pageNumber: number,
    options: {
      excludeDelivered?: boolean;
      excludeUniqueOpens?: boolean;
      excludeUniqueReplies?: boolean;
      excludeInfluencerIds?: boolean;
      statusFilter?: string;
      searchTerm?: string;
    } = {},
  ): Observable<{ sequences: SequenceUniqueInfo[]; pageNumber: number; hasMoreSequences: boolean }> {
    const {
      excludeDelivered,
      excludeUniqueOpens,
      excludeUniqueReplies,
      excludeInfluencerIds,
      statusFilter,
      searchTerm,
    } = options;
    return this.http.get<{ sequences: SequenceUniqueInfo[]; pageNumber: number; hasMoreSequences: boolean }>(
      environment.sequenceApi + '/sequences',
      {
        params: {
          campaignSlugName,
          pageNumber: pageNumber.toString(),
          ...(!!excludeDelivered && { excludeDelivered: excludeDelivered.toString() }),
          ...(!!excludeUniqueOpens && { excludeUniqueOpens: excludeUniqueOpens.toString() }),
          ...(!!excludeUniqueReplies && { excludeUniqueReplies: excludeUniqueReplies.toString() }),
          ...(!!excludeInfluencerIds && { excludeInfluencerIds: excludeInfluencerIds.toString() }),
          ...(!!statusFilter && { statusFilter }),
          ...(!!searchTerm && { searchTerm }),
        },
      },
    );
  }

  addEmailsToSequence(sequenceId: string, stages: Stage[]): Promise<{ success: boolean; stages: Stage[] }> {
    return this.http
      .post<{ success: boolean; stages: Stage[] }>(environment.sequenceApi + '/stages', {
        sequenceId,
        stages,
      })
      .toPromise();
  }

  updateEmailsToSequence(sequenceId: string, stages: Stage[]): Promise<{ success: boolean; stages: Stage[] }> {
    return this.http
      .put<{ success: boolean; stages: Stage[] }>(environment.sequenceApi + '/stages', {
        stages,
        sequenceId,
      })
      .toPromise();
  }

  changeEmailSequenceStatus(sequenceId: string, isEnabled: boolean): Promise<{ success: boolean; sequence: Sequence }> {
    return this.http
      .put<{ success: boolean; sequence: Sequence }>(environment.sequenceApi + `/sequences/${sequenceId}/status`, {
        isEnabled,
      })
      .toPromise();
  }

  getStagesInSequence(sequenceId: string): Promise<{ success: boolean; stages: Stage[] }> {
    return this.http
      .get<{ success: boolean; stages: Stage[] }>(environment.sequenceApi + `/stages/inSequence`, {
        params: {
          sequenceId,
        },
      })
      .toPromise();
  }

  getCampaignSequencesCount(campaignSlugName: string): Promise<{ sequencesCount: number }> {
    return this.http
      .get<{ sequencesCount: number }>(environment.sequenceApi + '/sequences/count', {
        params: {
          campaignSlugName,
        },
      })
      .toPromise();
  }

  getMatchingInfluencersThatWillBeTerminatedByPolicy(
    campaignSlugName: string,
    influencerUsernames: string[],
    terminationRule: Sequence['terminationRules'][number],
    platform: Platform | PlatformsV2 = 'instagram',
  ): Promise<{ willBeTerminatedUsernames: string[] }> {
    return this.http
      .post<{ willBeTerminatedUsernames: string[] }>(
        environment.sequenceApi + `/sequences/get-already-matching-termination-influencers`,
        {
          platform: platform.toUpperCase(),
          influencerUsernames,
          campaignSlugName,
          terminationRule,
        },
      )
      .toPromise();
  }
}
