import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { RetryParams } from '../interfaces';

// add endpoints to exclude here.
const EXCLUDED_ENDPOINTS = new Set([
  '/api/campaigns/upsert',
  '/api/chats/initiateChat',
  '/api/chats/saveMessageAndSendMail',
  '/api/notes/influencers',
]);

const DEFAULT_RETRY_PARAMS: RetryParams = {
  maxAttempts: 3,
  scalingDuration: 200,
  shouldRetry: ({ status }) => status === 0,
};
const NO_RETRY_PARAMS: RetryParams = {
  ...DEFAULT_RETRY_PARAMS,
  maxAttempts: 0,
};

const isAbsoluteURL = (url: string): boolean => {
  const pattern = /^https?:\/\//i;
  return pattern.test(url);
};

const genericRetryStrategy = (params: RetryParams) => (attempts: Observable<any>) =>
  attempts.pipe(
    mergeMap((error, i) => {
      const { maxAttempts, scalingDuration, shouldRetry } = { ...params };
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (retryAttempt > maxAttempts || !shouldRetry(error)) {
        return throwError(error);
      }
      console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
  );

export const retryStrategyFor = (url: string): ((errors: Observable<any>) => Observable<any>) => {
  const endpoint = isAbsoluteURL(url) ? new URL(url).pathname : url;

  if (EXCLUDED_ENDPOINTS.has(endpoint)) {
    return genericRetryStrategy(NO_RETRY_PARAMS);
  } else {
    return genericRetryStrategy(DEFAULT_RETRY_PARAMS);
  }
};
