import { Component, Input, OnInit } from '@angular/core';
import { groupBy, map, values } from 'lodash';
import { TimeSeriesItem } from '../../@core/data/influencer.service';
import { Platforms, PlatformsV2 } from '../../enums';

@Component({
  selector: 'ngx-followers-timeline-card',
  templateUrl: './followers-timeline-card.component.html',
  styleUrls: ['./followers-timeline-card.component.scss'],
})
export class FollowersTimelineCardComponent implements OnInit {
  @Input() followersTimeSeries: TimeSeriesItem[];
  @Input() platformName: PlatformsV2 = Platforms.instagram;
  @Input() printable: boolean;
  timelineOptions: any;

  ngOnInit(): void {
    /***
     * According to Youtube's policy, it is prohibited to store any engagement data (such as likes, follows, etc.)
     * from Youtube in our databases for more than 1 month. Therefore, this restriction should also be taken into
     * account when exporting this data.
     */
    if (this.platformName === Platforms.youtube) {
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      this.followersTimeSeries = this.followersTimeSeries.filter(
        (timeSerie) => new Date(timeSerie.date) > new Date(oneMonthAgo),
      );
    }

    this.timelineOptions = {
      legend: {
        data: ['Influencer followers over time'],
        align: 'left',
      },
      grid: {
        left: '12%',
      },
      xAxis: [
        {
          type: 'time',
          name: 'Date',
          nameLocation: 'middle',
          axisLabel: {
            rotate: 25,
            formatter: (timestamp) => {
              const date = new Date(timestamp);
              return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
            },
          },
          nameGap: 50,
        },
      ],
      color: this.printable ? '#FF3D71' : '#40dc7e',
      yAxis: [
        {
          name: `${this.platformName} Followers`,
          axisLabelInterval: 30,
          nameRotate: 90,
          nameLocation: 'middle',
          nameGap: 80,
        },
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          params = params[0];
          const date = params.value[0];
          const tooltipHTML =
            '<div style="display:flex; flex-direction: column;"> <div style="font-size:12px">' +
            'Date: ' +
            date.getDate() +
            '/' +
            (date.getMonth() + 1) +
            '/' +
            date.getFullYear() +
            '</div>' +
            '<div style="color:black;font-size:14px"> Followers: ' +
            '<strong>' +
            params.value[1] +
            '</strong></div>';
          return tooltipHTML;
        },
        axisPointer: {
          animation: false,
        },
      },
      series: [
        {
          name: 'Followers',
          type: 'line',
          data: map(this.groupByWeek(this.followersTimeSeries), (ts) => {
            const date = new Date(ts.date);
            return {
              value: [date, ts.followers],
            };
          }),
        },
      ],
      animationEasing: 'elasticOut',
    };
  }

  groupByWeek(timeseries: any[]): any[] {
    const groups = groupBy(timeseries, (item) => {
      const date = new Date(item.date);
      const week = this.getWeek(date);
      return date.getFullYear() + ':' + week;
    });

    return values(groups).map((value) => value[0]);
  }

  getWeek(date: Date): number {
    const dayNum = date.getUTCDay() || 7;
    date.setUTCDate(date.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    return Math.ceil(((+date - +yearStart) / 86400000 + 1) / 7);
  }
}
