<div>
    <nb-tabset id="tabset" name="tabset" #tabset (changeTab)="changeTabSelection($event)">
        <nb-tab #proposalTab tabTitle="Proposal">
            <ngx-auto-reply #autoReplyCompProposal [campaignSlugName]="campaignSlugName" [types]="proposalTypes"
            [scope]="'proposal'" [supportedTemplatingVariables]="supportedTemplatingVariables"></ngx-auto-reply>
        </nb-tab>
        <nb-tab #contentApprovalTab tabTitle="Content Approval">
            <ngx-auto-reply #autoReplyCompContentApproval [campaignSlugName]="campaignSlugName" [types]="contentApprovalTypes"
            [scope]="'content-approval'" [supportedTemplatingVariables]="supportedTemplatingVariables"></ngx-auto-reply>
        </nb-tab>
    </nb-tabset>
</div>
