// profile page tooltips

export const PROFILE_TOOLTIPS = {
  'audience-age-distribution': 'Age distribution of influencer’s engaged followers',
  'audience-gender-distribution': 'Gender distribution of influencer’s engaged followers',
  'audience-location-distribution': 'Location distribution of influencer’s engaged followers',
  'influencer-brand-affinity': 'Brands that influencer has mentioned & tagged in their posts',
  'audience-brand-affinity': 'Top brands that influencer’s followers follow on Instagram',
  engagement: 'Metrics about influencer’s reach on Instagram',
  'facebook-engagement': 'Metrics about influencer’s reach on Facebook',
  'audience-interest-distribution': 'Top interests of influencer’s followers',
  'sponsored-post': 'This is a sponsored post',
  'total-engagement': 'Average total engagement that influencer receives per post (upto last 100 images)',
  'likes-engagement': 'Average likes that influencer receives per post (upto last 100 images)',
  'comments-engagement': 'Average comments that influencer receives per post (upto last 100 images)',
  'suspicious-followers-engagement': 'Either inactive or fake followers (on average 5-15%)',
  'influencer-in-news': 'Recent news articles mentioning the influencer',
  'media-gallery': 'Recent media published by this influencer',
  'influencer-follower-timeline': 'Follower count timeline for the influencer',
  'instagram-engagement-timeline': 'Instagram Engagement timeline for the influencer',
  'video-gallery': 'Recent videos published by this influencer',
  'youtube-engagement': 'Metrics about influencer’s reach on Youtube',
  'youtube-likes-engagement': 'Average likes that influencer receives per view',
  'youtube-comments-engagement': 'Average comments that influencer receives per view',
  'youtube-views-engagement': 'Average likes that influencer receives per view',
  'youtube-dislikes-engagement': 'Average dislikes that influencer receives per view',
  'youtube-favourites-engagement': 'Average favourites that influencer receives per view',
  'youtube-engagement-timeline': 'Youtube Engagement timeline for the influencer',
  'send-influencer-mail': 'Reach out to influencer via InMail Chat feature',
  'add-to-groups': 'Add influencer to new/existing group',
  'export-influencer-data': 'Export and download influencer data',
  'open-instagram-page': 'Open influencer’s instagram page',
  'open-facebook-page': 'Open influencer’s facebook page',
  'open-youtube-page': 'Open influencer’s youtube page',
  'similar-influencer': 'Similar influencers profiles',
  posts: 'Recents posts by this influencer',
  'facebook-engagement-timeline': 'Facebook Engagement timeline for the influencer',
  'ig-post-popup': 'Estimated Cost per Post',
  'open-tiktok-page': 'Open influencer’s tiktok page',
  'tiktok-engagement': 'Metrics about influencer’s reach on Tiktok',
  'tiktok-engagement-timeline': 'Tiktok Engagement timeline for the influencer',
  'twitter-engagement': 'Metrics about influencer’s reach on Twitter',
  'twitter-engagement-timeline': 'Twitter Engagement timeline for the influencer',
  'open-twitter-page': 'Open influencer’s twitter page',
  'missing-influencer-email': `Influencer's email address is not found. Make sure to enter the email address by clicking the 'Notes' option.`,
  'engagement-timeline': 'Engagement timeline for the influencer',
  'branded-content-distribution': `Ratio of Branded and Non branded content posted by this influencer`,
};
