import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { SubscribedClientUser } from '../../../enums';
import { AuthService } from '../../../@core/auth/auth.service';
import { UserService } from '../../../@core/data/user.service';

@Component({
  selector: 'ngx-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})
export class PublicAppSubscriptionPlans implements OnInit {
  subscriptionPlans: any[];
  isUpdatingPlan: boolean;
  currentUser: SubscribedClientUser;
  freePlanName: string;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.isUpdatingPlan = !!this.route.snapshot.queryParamMap.get('isUpdatingPlan');
    this.currentUser = await this.userService.getClientUser();
    if (
      !this.isUpdatingPlan &&
      this.currentUser?.isSubscriptionUser &&
      this.currentUser.app_metadata.stripePlan !== 'SUBSCRIPTION_CANCELED'
    ) {
      return this.router.navigate(['pages', 'public']);
    }

    this.subscriptionPlans = (await this.authService.fetchSubscriptionPlans()).map(({ currency, ...plan }) => {
      if (!this.freePlanName && plan.charge === 0) this.freePlanName = plan.name;
      const subscriptionPlan = { isCurrentPlan: plan.name === this.currentUser.app_metadata.stripePlan, ...plan };
      return currency ? { currency: getCurrencySymbol(currency, 'narrow'), ...subscriptionPlan } : subscriptionPlan;
    });
    this.loading = false;
  }

  choosePlan(subscriptionPlan) {
    if (subscriptionPlan.isCurrentPlan) {
      this.router.navigate(['pages', 'public']);
    } else if (subscriptionPlan.isEnterprise) {
      window.open('https://www.affable.ai/demo?source=pricing', '_blank');
    } else {
      this.loading = true;
      this.authService
        .createSubscription(subscriptionPlan.name, this.isUpdatingPlan)
        .then(({ confirmationUrl }) => (window.location.href = confirmationUrl))
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    }
  }
}
