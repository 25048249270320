import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Gender } from '../../../@core/data/influencer-filter.service';

@Component({
  selector: 'ngx-gender-filter',
  templateUrl: './gender-filter.component.html',
  styleUrls: ['./gender-filter.component.scss', '../styles.scss'],
})
export class GenderFilterComponent {
  @Input() gender: Gender;

  @Input() displayLabel = true;

  @Output() triggerUpdate: EventEmitter<void> = new EventEmitter();

  triggerUpdateEvent(): void {
    this.triggerUpdate.emit();
  }
}
