import { JwtHelperService } from '@auth0/angular-jwt';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';
import { FeatureFlagUser } from '../../interfaces/feature-flag.interface';
import { RELEASE_FLAGS } from './flags';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../auth/local-storage.service';

@Injectable()
export class FeatureFlagService {
  private LDClient: LaunchDarkly.LDClient;

  public constructor(private jwtHelperService: JwtHelperService, private localStorageService: LocalStorageService) {}

  private initializeClient(user?: FeatureFlagUser) {
    let ldUser: LaunchDarkly.LDUser;
    if (user) {
      ldUser = {
        key: user.id,
        email: user.email,
      };
    } else {
      // case where user not logged in
      ldUser = {
        anonymous: true,
        key: 'affable-anonymous-user-key',
      };
    }
    this.LDClient = LaunchDarkly.initialize(environment.launchDarklyKey, ldUser);
    this.LDClient.on('error', (err) => {
      /**
       * Calling the Identify 'initialize()' function requires the SDK to terminate the previous streaming connection and initiate a new one.
       * This action typically does not trigger an error, but Sentry still captures and reports it.
       * https://docs.launchdarkly.com/docs/js-sdk-reference#section-identify
       * https://launchdarkly.github.io/js-client-sdk/interfaces/LDClient.html#on
       */
      if (!err.stack.includes('LaunchDarklyFlagFetchError')) {
        throw err;
      }
      return;
    });
  }

  terminateClient(): void {
    if (this.LDClient) {
      this.LDClient.close();
      delete this.LDClient;
    }
  }

  async isFeatureReleased(flagKey: RELEASE_FLAGS): Promise<boolean> {
    let user: FeatureFlagUser;
    if (environment.host.includes('api.beta.affable.ai')) return true;
    if (!this.LDClient) {
      // get user object from localstorage::access_token
      user = this.getFeatureFlagUserFromToken();
      if (!user) {
        return false;
      }
      // init ld client with the user object to use it furhter
      this.initializeClient(user);
    }
    await this.LDClient.waitForInitialization();
    return this.LDClient?.variation(flagKey, false) ?? false;
  }

  private getFeatureFlagUserFromToken(): FeatureFlagUser {
    const accessToken =
      this.localStorageService.getItem('access_token') || this.localStorageService.getItem('collaborator_access_token');
    const _namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      // eslint-disable-next-line no-prototype-builtins
      if (decodedToken.hasOwnProperty('sub') || decodedToken.hasOwnProperty('id')) {
        return {
          id: decodedToken['sub'] || decodedToken['id'],
          email: decodedToken[`${_namespace}email`], // not explicitly checking if it is available as its optional
        };
      }
    }
    return undefined;
  }

  public userUpdated(): void {
    this.terminateClient();
  }
}

export const featureFlagService = new FeatureFlagService(new JwtHelperService(), new LocalStorageService());
