import { Injectable } from '@angular/core';
import { includes, startCase } from 'lodash';

@Injectable()
export class LanguageService {
  private languagesForSearch = [
    'English',
    'Spanish',
    'Indonesian',
    'Arabic',
    'Chinese',
    'Portuguese',
    'Japanese',
    'Thai',
    'Italian',
    'Malay',
    'Russian',
    'Korean',
    'French',
    'German',
    'Turkish',
    'Persian',
    'Polish',
    'Vietnamese',
    'Dutch',
    'Tagalog',
    'Hindi',
    'Khmer',
    'Swedish',
    'Czech',
    'Danish',
    'Punjabi',
    'Greek',
    'Urdu',
    'Romanian',
    'Hebrew',
    'Norwegian',
    'Swahili',
    'Croatian',
    'Bangla',
    'Finnish',
    'Azerbaijani',
    'Ukrainian',
    'Serbian',
    'Hungarian',
    'Kurdish',
    'Slovak',
    'Malayalam',
    'Javanese',
    'Catalan',
    'Marathi',
    'Bulgarian',
    'Gujarati',
    'Kazakh',
    'Uzbek',
    'Albanian',
    'Wolof',
    'Burmese',
    'Lithuanian',
    'Somali',
    'Bosnian',
    'Galician',
    'Afrikaans',
    'Sundanese',
    'Lao',
    'Norwegian Nynorsk',
    'Slovenian',
    'Kinyarwanda',
    'Sinhala',
    'Hausa',
    'Kyrgyz',
    'Irish',
    'Haitian Creole',
    'Scots',
    'Tamil',
    'Latvian',
    'Estonian',
    'Icelandic',
    'Tatar',
    'Armenian',
    'Waray',
    'Corsican',
    'Klingon',
    'Latin',
    'Pashto',
    'Guarani',
    'Kannada',
    'Inuktitut',
    'Sanskrit',
    'Macedonian',
    'Malagasy',
    'Manx',
    'Sindhi',
    'Basque',
    'Turkmen',
    'Yoruba',
    'Faroese',
    'Luxembourgish',
    'Quechua',
    'Breton',
    'Georgian',
    'Mongolian',
    'Khasi',
    'Zulu',
    'Bislama',
    'zzp',
    'Afar',
    'Oromo',
    'Scottish Gaelic',
    'Xhosa',
    'Tsonga',
    'Welsh',
    'Belarusian',
    'Tajik',
    'Interlingua',
    'Fijian',
    'Volapük',
    'Interlingue',
    'Kalaallisut',
    'Occitan',
    'Ganda',
    'Maori',
    'Shona',
    'Zhuang',
    'Cherokee',
    'Hmong',
    'Esperanto',
    'Nyanja',
    'Maltese',
    'Abkhazian',
    'Telugu',
    'Romansh',
    'Cebuano',
    'Tongan',
    'Amharic',
    'Southern Sotho',
    'Lingala',
    'Western Frisian',
    'Morisyen',
    'Seselwa Creole French',
    'Tswana',
    'bh',
    'Hawaiian',
    'Nauru',
    'Nepali',
    'Samoan',
    'Aymara',
    'Akan',
    'Igbo',
    'Assamese',
    'Uyghur',
    'Rundi',
    'Swati',
    'Odia',
    'Venda',
    'Northern Sotho',
    'South Ndebele',
    'Bashkir',
    'Inupiaq',
    'Kashmiri',
    'Sango',
    'Tibetan',
    'Divehi',
    'Syriac',
    'Tigrinya',
  ];

  languagesList(): string[] {
    return this.languagesForSearch;
  }
}
