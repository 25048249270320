import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignsService } from '../../@core/data/campaigns.service';
import { MailType } from '../../enums';
import { CampaignInfluencer } from '../../interfaces';
import { CampaignInfluencerService } from '../../pages/campaigns/campaign/campaign-influencers/campaign-influencers.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { ShowAllInfluencerModalComponent } from './show-all-influencers-modal/show-all-influencer-modal.component';

@Component({
  selector: 'ngx-reminder-banner',
  templateUrl: './reminder-banner.component.html',
  styleUrls: ['./reminder-banner.component.scss'],
})
export class ReminderBannerComponent {
  @Input() influencers: CampaignInfluencer[] = [];
  @Input() description = "Influencers who received product gifts but haven't published any content yet.";
  @Output() disableReminder = new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private campaignInfluencerService: CampaignInfluencerService,
    private campaignService: CampaignsService,
  ) {
    this.campaignService.emailInfluencersSuccess$.subscribe(({ usernames, mailType }) => {
      if (mailType === MailType.reminder) {
        this.influencers = this.influencers.filter((inf) => !usernames.includes(inf.influencer.username));
      }
    });
  }

  showAllInfluencer(): void {
    const showAllInfluencerModal = this.modalService.open(ShowAllInfluencerModalComponent, {
      windowClass: 'modal-md',
      centered: true,
      backdrop: 'static',
    });
    const modalContent = showAllInfluencerModal.componentInstance;
    modalContent.influencers = this.influencers;
  }

  sendReminder(): void {
    this.campaignInfluencerService.startChatWithInfluencers(
      this.influencers.map((inf) => ({ ...inf, selected: true })),
      MailType.reminder,
    );
  }

  closeReminder(): void {
    const confirmModal = this.modalService.open(MessageModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    const modalContent = confirmModal.componentInstance as MessageModalComponent;
    modalContent.headerText = 'Close Reminder';
    modalContent.contentText = `It will permanently close the list of influencers who require follow-up.\nAre you sure you want to close this reminder? `;
    modalContent.buttonText = 'YES';
    modalContent.buttonClass = 'secondary';
    modalContent.centerContent = false;

    confirmModal.result.then(async (res) => {
      if (res) {
        this.disableReminder.emit(true);
      }
    });
  }
}
