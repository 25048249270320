import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { merge } from 'lodash';
import { PrintService } from '../../pages/print/print-layout/print.service';
import { BaseEchartsPrintedComponent } from './echarts-printed.component';

@Component({
  selector: 'ngx-echarts-horizontal-bar',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss'],
})
export class EchartsHorizontalBarComponent
  extends BaseEchartsPrintedComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  options: any = {};
  themeSubscription: any;

  @Input() dataOptions: any = {};
  @Input() colorChoice: 'success' | 'primary' = 'primary';
  printedChart: boolean;
  constructor(private theme: NbThemeService, private print: PrintService) {
    super();
    this.printedChart = print.isPrinting;
  }

  ngOnChanges(): void {
    this.ngAfterViewInit();
  }

  getColor(colorChoice, colors) {
    switch (colorChoice) {
      case 'success': {
        return colors.successLight;
      }
      case 'primary': {
        return colors.primaryLight;
      }
      default: {
        return colors.primaryLight;
      }
    }
  }

  ngAfterViewInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      const echarts: any = config.variables.echarts;
      const labelOptions: any = {
        normal: {
          show: true,
          position: 'insideLeft',
          formatter: '{b}',
          fontSize: 13,
          fontWeight: 'bold',
        },
      };

      const color = this.getColor(this.colorChoice, colors);
      this.options = merge(
        {
          backgroundColor: echarts.bg,
          color: [color],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: (params) => `${params[0].marker} ${params[0].name}: ${params[0].value}`,
          },
          grid: {
            containLabel: true,
          },
          yAxis: [
            {
              type: 'category',
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              axisLabel: { show: false },
              axisTick: { show: false },
              splitLine: { show: false },
            },
          ],
          xAxis: [
            {
              nameLocation: 'middle',
              nameGap: 30,
              type: 'value',
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              splitLine: {
                lineStyle: {
                  color: echarts.splitLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
              },
            },
          ],
          series: [
            {
              type: 'bar',
              label: labelOptions,
              barWidth: '65%',
            },
          ],
        },
        this.dataOptions,
      );
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  onChartEvent(event: any) {
    console.log(event);
  }
}
