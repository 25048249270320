import { Component, EventEmitter, Input, Output } from '@angular/core';
import utils from '../../../@core/utils/utils';
import { Note } from '../../../interfaces';

@Component({
  selector: 'ngx-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  editingNote = false;
  deletingNote = false;
  note: Note;
  editedNote: string;
  deletedFile = false;
  changedPrivacy = false;

  // fixes popper not anchoring to the context menu button
  popperModifiers = {
    flip: { enabled: false },
  };

  @Input() ownNote: boolean;
  @Input() isSingleUserOrg: boolean;
  @Input() isCollabPortal = false;
  @Input()
  set noteValue(value: Note) {
    value.note = utils.replaceNonBreakByBreakSpaces(value.note);
    this.note = value;

    this.editedNote = utils.getTextFromHTML(value.note);
  }

  @Output() editNoteEvent = new EventEmitter();
  @Output() deleteNoteEvent = new EventEmitter();
  @Output() downloadFileEvent = new EventEmitter();

  enableEditing(): void {
    this.editingNote = true;
    this.deletingNote = false;

    // show caret when Edit Note is triggered
    const id = this.note.id;
    setTimeout(function (): void {
      document.getElementById(id).focus();
    }, 0);
  }

  disableEditing(): void {
    this.editingNote = false;

    // resets any changes to privacy when edit action is cancelled
    this.changedPrivacy = false;
    this.deletedFile = false;
    this.editedNote = utils.getTextFromHTML(this.note.note);
  }

  deleteFile(): void {
    // ensures file is not shown after deletion
    this.deletedFile = true;
  }

  editNote(): void {
    const noteId = this.note.id;
    const editedNoteAsHtml = utils.getHtmlfromPlainText(this.editedNote);
    const deletedNoteFile = this.deletedFile ? this.note.file.name : '';
    const privacy = this.changedPrivacy ? !this.note.isPrivate : this.note.isPrivate;
    this.editNoteEvent.emit({ noteId, editedNoteAsHtml, deletedNoteFile, privacy });
    this.disableEditing();
  }

  enableDeleting(): void {
    this.editingNote = false;
    this.deletingNote = true;
  }

  disableDeleting(): void {
    this.deletingNote = false;
  }

  deleteNote(): void {
    const noteId = this.note.id;
    this.deleteNoteEvent.emit({ noteId });
  }

  downloadFile(): void {
    this.downloadFileEvent.emit({ file: this.note.file });
  }
}
