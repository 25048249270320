<nb-card class="add-note-card">

  <nb-card-body class="notes-card">
    <!-- Header with attach and clear buttons -->
    <div class="title-container">
      <div>Add Note</div>

      <div class="actions">
        <label *ngIf="!fileToUpload" class="attach-btn" for="file">
          <i class="fas fa-paperclip" title="attach file"></i> Attach file
          <input type="file" id="file" class="file-uploader" (change)="handleFileInput($event.target.files)" />
        </label>
        <div *ngIf="newNote && !fileToUpload" class='v-separator'></div>
        <button *ngIf="newNote" (click)="newNote = ''" title="clear" class="clear-btn">Clear</button>
      </div>
    </div>

    <div class="note-item">
      <textarea rows="3" cols="50" type="text" [(ngModel)]="newNote" placeholder="Type your note here"> </textarea>
    </div>

    <div class="message" *ngIf="!isSingleUserOrg">
      {{message}}
    </div>
  </nb-card-body>

  <nb-card-footer>
    <!-- Handler for uploaded file -->
    <div class="note-file" *ngIf="fileToUpload">
      <div class="file-name" *ngIf="fileToUpload">
        <i class="attach-icon fas fa-paperclip"></i>
        <div class="name">
          {{ fileToUpload.name }}
        </div>
        <button (click)="removeAttachement()" title="remove attachement" class="x-button">
          <i class="fas fa-close"></i>
        </button>
      </div>
      <div class="file-error" *ngIf="fileToUpload && !validFile">
        {{ fileErrorMessage }}
      </div>
    </div>

    <!-- Save button and Privacy Toggle -->
    <div class="actions-container" [class.single-user-actions-container]="isSingleUserOrg">
      <nb-checkbox *ngIf="!isCollabPortal && !isSingleUserOrg" [(ngModel)]="isPrivate">Make it Private</nb-checkbox>

      <button [disabled]="isSaveDisabled" (click)="addNote()" title="Save note"
        class="btn btn-xs btn-primary list-action-button">Save Note</button>
    </div>
  </nb-card-footer>
</nb-card>