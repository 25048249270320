<div class="form-group">
    <div class="filter-label-container" *ngIf="displayLabel">
        <label *ngIf="asyncLocations" class="filter-label">Locations (Country or City)</label>
        <label *ngIf="!asyncLocations" class="filter-label">Locations (Country)</label>
        <ngx-tooltip [placement]="'bottom-left'" size="large" [tooltip]="'Click - to exclude selected location.<br>Click + to include selected location.'">
        </ngx-tooltip>
    </div>
    <div class="input-group input-group-sm" >

        <ng-container *ngIf="asyncLocations">
            <ng-select placeholder="Eg: Singapore, Mumbai, New York" addTagText="Search for location"
                (change)="triggerUpdateEvent()" typeToSearchText="Type to search for location" dropdownPosition="bottom"
                [items]="locations | async" [typeahead]="locationInput" [multiple]="true" [hideSelected]="true"
                [virtualScroll]="true" [loading]="loadingLocations" [markFirst]="false" [(ngModel)]="selectedLocations" [disabled]="isDisabled">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value {{item.isIncluded}}" *ngFor="let item of items">
                        <span class="ng-value-label">{{item.name}}</span>
                        <span class="ng-value-icon" (click)="toggleLocationLogic(item)" aria-hidden="true"
                            *ngIf="item.isIncluded && allowToggle">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                        </span>
                        <span class="ng-value-icon" (click)="toggleLocationLogic(item)" aria-hidden="true"
                            *ngIf="!item.isIncluded && allowToggle">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </span>
                        <span class="ng-value-icon right" (click)="clear(item)" data-cy="clear-location" aria-hidden="true">x</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div class="location-item">
                        <span [ngOptionHighlight]="search">{{item.name}}</span>
                        <i *ngIf="item.disabled" class="fa fa-lock"></i>
                    </div>
                </ng-template>
            </ng-select>
        </ng-container>
        <ng-container *ngIf="!asyncLocations">
            <ng-select placeholder="Eg: Singapore" addTagText="Search for location" (change)="triggerUpdateEvent()"
                bindLabel="name" typeToSearchText="Type to search for location" dropdownPosition="bottom"
                [items]="locations" [multiple]="true" [hideSelected]="true" [virtualScroll]="true"
                [loading]="loadingLocations" [markFirst]="false" [(ngModel)]="selectedLocations" [disabled]="isDisabled">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value {{item.isIncluded}}" *ngFor="let item of items">
                        <span class="ng-value-label">{{item.name}}</span>
                        <span class="ng-value-icon" (click)="toggleLocationLogic(item)" aria-hidden="true"
                            *ngIf="item.isIncluded">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                        </span>
                        <span class="ng-value-icon" (click)="toggleLocationLogic(item)" aria-hidden="true"
                            *ngIf="!item.isIncluded">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div class="location-item">
                        <span [ngOptionHighlight]="search">{{item.name}}</span>
                        <i *ngIf="item.disabled" class="fa fa-lock"></i>
                    </div>
                </ng-template>
            </ng-select>
        </ng-container>
    </div>
</div>
