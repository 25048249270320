import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CreatorPortalGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const creatorsPortalEnabled = this.auth.isCreatorsPortalEnabled();
    if (!creatorsPortalEnabled) {
      console.error('creator portal guard rejected access to ' + state.url);
      this.router.navigate(['pages', 'dashboard']);
    }
    return creatorsPortalEnabled;
  }
}
