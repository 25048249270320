<div class="modal-body">

  <div *ngIf="isMediaPost && tweet.media[0].type=='photo'" class="content-display"
    [style.height]="loaded ? 'auto' : '0px'">
    <div>
      <img [src]="tweet.media[0]?.url" (load)="loaded = true; progress.ref().complete();"
        (error)="tweet.hasExpiredThumbnail = true;">
    </div>
  </div>
  <div *ngIf="isMediaPost && (tweet.media[0].type=='video' || tweet.media[0].type=='animated_gif')"
    class="content-display video" [style.height]="loaded ? 'auto' : '0px'">
    <i class="far fa-play"></i>
    <div>
      <img [src]="tweet?.media[0].url" (load)="loaded = true; progress.ref().complete();" (click)="openTweet(tweet)"
        (error)="post.hasExpiredThumbnail = true;">
    </div>
  </div>

  <div class="content-information" [class.text-post]="!isMediaPost">
    <div class="content-header">
      <a href="/pages/twitter/profile/{{profile?.username}}" class="content-author" target="_blank">
        <nb-user [name]="profile?.name" [picture]="profile?.profilePictureUrl" [title]="profile?.username" size="large">
        </nb-user>
      </a>
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="content-body">
      <div class="content-stats">
        <div *ngIf="!hideStats && tweet.estimatedReach" class="stat">
          <label>Est. Reach</label> {{tweet.estimatedReach | formatNumber:2}}
        </div>
        <div *ngIf="!hideStats && tweet.estimatedImpressions" class="stat">
          <label>Est. Impressions</label> {{tweet.estimatedImpressions | formatNumber: 2}}
        </div>
        <div *ngIf="!hideStats && tweet.estimatedMediaValue" class="stat">
          <label>EMV</label> {{tweet.estimatedMediaValue | currency:currency}}
        </div>
        <div class="h-space" *ngIf="!hideStats"></div>
        <div class="stat">
          <i class="far fa-calendar"></i>
          {{tweet?.createdAt | date: 'mediumDate'}}
        </div>
        <ng-container *ngIf="!hideStats && tweet?.statistics">

          <!-- <div *ngIf="tweet.statistics.replyCount >= 0" class="stat">
                        <i class="far fa-comment"></i>
                        {{tweet.statistics.replyCount | formatNumber:0}} Replies
                    </div> -->
          <div *ngIf="tweet.statistics.retweetCount >= 0" class="stat">
            <i class="far fa-retweet"></i>
            {{tweet.statistics.retweetCount | formatNumber:0}} Retweets
          </div>
          <div *ngIf="tweet.statistics.likeCount >= 0" class="stat">
            <i class="far fa-thumbs-up"></i>
            {{tweet.statistics.likeCount | formatNumber:0}} Likes
          </div>
          <!-- <div *ngIf="tweet.statistics.quoteCount >= 0" class="stat">
                        <i class="far fa-quote-left"></i>
                        {{tweet.statistics.quoteCount | formatNumber:0}} Quote Tweets
                    </div> -->
        </ng-container>
      </div>
      <div *ngIf="tweet?.description" class="content-caption">
        {{tweet.description}}
      </div>
      <div *ngIf="!tweet?.description" class="content-caption not-found">
        No description found!
      </div>
      <div class="content-list" *ngIf="tweet?.hashtags?.length > 0">
        <div class="content-list-header">Hashtags</div>
        <span class="chip" *ngFor="let hashtag of tweet?.hashtags">
          #{{hashtag}}
        </span>
      </div>
      <div class="content-list" *ngIf="tweet?.taggedUsers?.length > 0">
        <div class="content-list-header">Mentions</div>
        <span class="chip" *ngFor="let taggedUser of tweet?.taggedUsers">
          @{{taggedUser}}
        </span>
      </div>
    </div>
    <div class="content-footer">
      <a class="btn btn-xs btn-primary btn-block" [attr.href]="getTwitterURL()" target="_blank">
        <i class="fab fa-twitter"></i> GO TO TWITTER
      </a>
    </div>
  </div>
</div>