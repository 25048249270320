import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-empty-widget',
  styleUrls: ['./empty-widget.component.scss'],
  templateUrl: './empty-widget.component.html',
})
export class EmptyWidgetComponent {
  @Input() imageSrc = 'console/empty-res.svg';
  @Input() header;
  @Input() description;

  get image(): string {
    return `../../../../assets/images/${this.imageSrc}`;
  }
}
