<button *ngIf="!printable" class="filter-btn" [popper]="brandedContentMenu" [popperTrigger]="'click'"
  popperApplyClass="context-menu-md" [popperPlacement]="'bottom-end'">
  {{filterKey.label}} <i class="fas fa-chevron-down"></i>
</button>

<ngx-loader *ngIf='!brandedContentOptions && !printable' class='profile-card-placeholder'></ngx-loader>
<div class='' [ngClass]="{'visitors-statistics': !printable, 'visitors-statistics-print':!!printable}"
  *ngIf='brandedContentOptions?.series?.length > 0'>
  <div class='statistics-header'>
    <div class='visitors-value'>{{ totalContents }}</div>
    <div class='visitors-title'>Total Posts</div>
  </div>
  <div class='statistics-chart'>
    <div echarts [options]='brandedContentOptions' class='echart'
      [ngClass]="{'echart': !printable, 'echart-print':!!printable}" (chartInit)='onChartInit($event)'>
    </div>
  </div>
  <div *ngIf='!printable' class='statistics-footer'>
    <div class='chart-values'>
      <span class='chart-value'>{{ brandedContentPercentage }}%</span>
      <span class='chart-value'>{{ 100 - brandedContentPercentage }}%</span>
    </div>
    <div class='legends'>
      <div *ngFor='let legend of chartLegend' class='legend'>
        <div class='legend-item-color' [style.background]='legend.iconColor'></div>
        <div class='legend-title'>{{ legend.title }}</div>
      </div>
    </div>
  </div>
  <div *ngIf='!!printable' class='statistics-footer-print'>
    <div class='legends-print'>
      <div *ngFor='let legend of chartLegend; let i =index' class='legend'>
        <div class='legend-title-printed'>
          <span class='chart-value' *ngIf='i>0'>{{ i * 100 - brandedContentPercentage }}%</span>
          <span class='chart-value' *ngIf='i<=0'>{{ brandedContentPercentage }}%</span>
          <div class='legend-item-color' [style.background]='legend.iconColor'></div>
          <div class='legend-title'>{{ legend.title }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf='brandedContentOptions?.series?.length == 0' class='profile-card-placeholder'>
  Branded Content distribution data not found for {{ influencer }}
</div>

<popper-content #brandedContentMenu>
  <div class="list-group">
    <div class="list-group-item list-group-item-action cursor-pointer" *ngFor="let option of filterOptions"
      (click)="updateBrandedContentStats(option.value); filterKey = option; brandedContentMenu.hide()">
      <div class="item-text">{{option.label}}</div>
      <i class="ion-md-checkmark" *ngIf="option.value === filterKey.value"></i>
    </div>
  </div>
</popper-content>