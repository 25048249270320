<div class="aiControl">
  <label class="sub-heading-h2" *ngIf="label" [innerHTML]="label" [ngClass]="{'required-field': required}"></label>
  <div class="aiInputControlBlock">
    <div class="input-wrapper">
      <input #aiControl [readonly]="readonly" [attr.disabled]="disabled ? '' : null" type="tel"
        [placeholder]="placeholder" [ngModel]="standingValue" (ngModelChange)="onInputChange($event)" autoWidth
        (keydown)="onKeyDown($event)" />
    </div>
    <div class="action-btn-container">
      <button (click)="incrementValue()"><i class="far fa-angle-up"></i></button>
      <button (click)="decrementValue()"><i class="far fa-angle-down"></i></button>
    </div>
  </div>
  <div *ngIf="hint || error" class="body-4 mt-2 d-flex">
    <ng-container *ngIf="control.touched && error && !focused && !hideError; else hintTemplate">
      <span *ngIf="!hideError" [innerHTML]="error" class="error text-left"></span>
    </ng-container>
    <ng-template #hintTemplate>
      <span *ngIf="hint" [innerHTML]="hint" class="hint text-left"></span>
    </ng-template>
  </div>
</div>