import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-result-modal',
  templateUrl: './result-modal.component.html',
  styleUrls: ['./result-modal.component.scss'],
})
export class ResultModalComponent {
  @Input() showCloseOption = true;
  @Input() headerText: string;
  @Input() contentText: string;
  @Input() image: string;
  @Input() primaryActionButton: boolean | { label: string; type?: string; suffix?: string };
  @Input() secondaryActionButton: boolean | { label: string; type?: string; prefix?: string };

  @Input() areContentsCentered = true;

  @Output() buttonClicked: EventEmitter<'PRIMARY' | 'SECONDARY'> = new EventEmitter<'PRIMARY' | 'SECONDARY'>();
  @Input() closeOnAction = true;
  @Input() loading = false;

  constructor(public activeModal: NgbActiveModal) {}

  toNextButton(): void {
    this.buttonClicked.emit('PRIMARY');
    if (this.closeOnAction) {
      this.closeModal(true);
    }
  }

  toBackButton(): void {
    this.buttonClicked.emit('SECONDARY');
    if (this.closeOnAction) {
      this.closeModal();
    }
  }

  closeModal(result?: boolean): void {
    this.activeModal.close(result);
  }
}
