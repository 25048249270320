import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'ai-toggle-switch',
  templateUrl: './toggle-switch.control.html',
  styleUrls: ['./toggle-switch.control.scss'],
})
export class ToggleSwitchControl {
  @Input() key: string;
  @Input() checked: boolean;

  @Input() disabled = false;

  @Output() toggleSwitchChangedEvent: EventEmitter<any> = new EventEmitter();
  // more options goes here !

  /**
   * @ignore
   */
  handleChange(event) {
    this.checked = event.srcElement.checked;
    this.toggleSwitchChangedEvent.emit({ key: this.key, checked: this.checked });
  }
}
