import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CampaignWorkflowSubTabs } from '../../../enums';
import { IndexedTab } from '../../../interfaces';
import { NgProgress } from 'ngx-progressbar';

const CAMPAIGN_WORKFLOW_SUBTABS: IndexedTab[] = [
  { tabName: CampaignWorkflowSubTabs.proposalSettings, index: 0 },
  { tabName: CampaignWorkflowSubTabs.campaignBrief, index: 1 },
  { tabName: CampaignWorkflowSubTabs.productGiftings, index: 2 },
  { tabName: CampaignWorkflowSubTabs.fixedPay, index: 3 },
  { tabName: CampaignWorkflowSubTabs.paymentSettings, index: 4 },
  { tabName: CampaignWorkflowSubTabs.influencerTasks, index: 5 },
  { tabName: CampaignWorkflowSubTabs.brandAssets, index: 6 },
  { tabName: CampaignWorkflowSubTabs.otherDetails, index: 7 },
  { tabName: CampaignWorkflowSubTabs.socialAccounts, index: 8 },
  { tabName: CampaignWorkflowSubTabs.contentRights, index: 9 },
];

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  private tabChangedSource = new Subject<CampaignWorkflowSubTabs>();
  tabChanged$ = this.tabChangedSource.asObservable();

  currentActiveSubTab: CampaignWorkflowSubTabs;
  workflowSubtabCompletedToIndex: number;

  public loading = false;

  constructor(public progress: NgProgress) {
    this.resetTabs();
  }

  startLoading(): void {
    this.loading = true;
    this.progress.ref().start();
  }

  completeLoading(): void {
    this.loading = false;
    this.progress.ref().complete();
  }

  get getState(): boolean {
    return this.loading;
  }

  resetTabs(): void {
    this.currentActiveSubTab = CampaignWorkflowSubTabs.campaignBrief;
    this.workflowSubtabCompletedToIndex = 0;
  }

  changeTab(newTab: CampaignWorkflowSubTabs): void {
    this.setCurrentTab(newTab);
    this.tabChangedSource.next(newTab);
  }

  setCurrentTab(newTab: CampaignWorkflowSubTabs): void {
    this.currentActiveSubTab = newTab;
  }

  checkIsSubTabEnabled(tab: CampaignWorkflowSubTabs): boolean {
    const tabIndex = this.getTabIndexFromName(tab);
    const currentActiveSubTabIndex = this.getTabIndexFromName(this.currentActiveSubTab);
    return tabIndex <= this.workflowSubtabCompletedToIndex || currentActiveSubTabIndex === tabIndex;
  }

  moveToNextWorkflowSubtab(nextTab: CampaignWorkflowSubTabs): void {
    const currentActiveSubTabIndex = this.getTabIndexFromName(this.currentActiveSubTab);
    if (currentActiveSubTabIndex >= this.workflowSubtabCompletedToIndex) {
      this.updateCompletedToTabIndex(currentActiveSubTabIndex);
    }
    this.changeTab(nextTab);
  }

  getTabIndexFromName(tabName: CampaignWorkflowSubTabs): number {
    return CAMPAIGN_WORKFLOW_SUBTABS.find((tab) => tab.tabName === tabName).index;
  }

  updateCompletedToTabIndex(tabIndex: number): void {
    this.workflowSubtabCompletedToIndex = tabIndex;
  }
}
