/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../@core/data/user.service';
import { AuthService } from '../../../@core/auth/auth.service';

@Component({
  selector: 'ngx-public-app-landing',
  templateUrl: './public-app-landing.component.html',
  styleUrls: ['./public-app-landing.component.scss'],
})
export class PublicAppLandingComponent implements OnInit {
  actions = [
    {
      title: '1. Create Campaigns',
      image: 'create-campaigns.svg',
      description: [
        'Create product gifting and affiliate campaigns with hashtag and mention tracking.',
        'Customise the campaign portal for influencers. Select products to offer as gifts and configure affiliate codes.',
      ],
      buttonText: 'CREATE',
      navigationUrl: ['pages', 'campaigns'],
    },
    {
      title: '2. Search Influencers',
      image: 'search-influencers.svg',
      description: [
        'Search and discover influencers using filters and add the right ones to the campaign.',
        'Send personalised email invites to influencers on a large scale, using dynamic fields.',
      ],
      buttonText: 'SEARCH',
      navigationUrl: ['discovery'],
    },
    {
      title: '3. Analyse Reports',
      image: 'analyse-reports.svg',
      description: [
        'Analyse engagement and reach reports to help you measure performance and make more informed decisions.',
        'Track the unique discount codes and measure the Shopify sales generated by each influencer automatically.',
      ],
      buttonText: 'ANALYSE',
      navigationUrl: ['pages', 'reports', 'campaigns'],
    },
  ];

  isShopifySubscription: boolean;
  shopifySubscriptionCanceled: boolean;
  constructor(private router: Router, private auth: AuthService, private userService: UserService) {}

  async ngOnInit() {
    const user = await this.userService.getClientUser();

    const isShopifySubscription = user.app_metadata.stripePlan?.toUpperCase().includes('SHOPIFY-');
    if (!isShopifySubscription) {
      return this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }

    this.shopifySubscriptionCanceled =
      !user.app_metadata?.stripePlan || user.app_metadata?.stripePlan?.includes('SUBSCRIPTION_CANCELED');
  }

  navigate(navigationUrl) {
    if (this.shopifySubscriptionCanceled) {
      return;
    }
    this.router.navigate(navigationUrl);
  }
}
