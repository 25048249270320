<div class="modal-header">
  <b>Mail Feature</b>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    <b>For a better experience, use the Mail feature from the Campaigns</b>
  </p>
  <p>To send emails, first create a new campaign and add influencers to the campaign.<span *ngIf="showMailButton">
      Alternatively, you can also send
      an email from here by clicking on '{{mailButton}}'</span></p>
</div>
<div class="modal-footer">
  <ai-button type="outline" *ngIf="showMailButton" (click)="toCampaign(false)" data-cy="send-mail-influencer-button" [label]="mailButton"></ai-button>
  <ai-button type="fill" (click)="toCampaign(true)" data-cy="go-to-create-campaign" label="GO TO CAMPAIGNS"></ai-button>
</div>