import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CostType, FulfillmentStatus, PlatformsV2, ProposalStatus } from '../../enums';
import { CampaignOrder, InfluencersCommission, BasicInfluencerInfo, Proposal } from '../../interfaces';

@Injectable()
export class MetadataService {
  constructor(private http: HttpClient) {}

  @Output() cancelChangesEvent: EventEmitter<void> = new EventEmitter();

  cancelChanges(): void {
    this.cancelChangesEvent.emit();
  }

  getBasicInfoWithMetadata(
    influencerUsername: string,
    platform: PlatformsV2,
  ): Promise<BasicInfluencerInfoWithMetadata> {
    return this.http
      .get<BasicInfluencerInfoWithMetadata>(environment.api + `/api/metadata/${influencerUsername}/with-basic-info`, {
        params: { platform },
      })
      .toPromise();
  }

  getInfluencerMetadata(influencerUsername: string, platform: PlatformsV2): Promise<InfluencerMetadata> {
    return this.http
      .get<InfluencerMetadata>(environment.api + `/api/metadata/${influencerUsername}`, {
        params: { platform },
      })
      .toPromise();
  }

  updateInfluencerMetadata(
    influencerUsername: string,
    platform: PlatformsV2,
    updatedMetadata: InfluencerMetadata,
  ): Promise<any> {
    return this.http
      .put(environment.api + `/api/metadata/${influencerUsername}`, {
        platform: platform,
        metadata: updatedMetadata,
      })
      .toPromise();
  }
}

interface AffiliatePay {
  commissionType: 'PERCENTAGE' | 'FIXED';
  commissionValue: number;
  currency: string;
}

export interface CampaignInfluencerAssignee {
  _id: string;
  email: string;
  name?: string;
}

export interface InfluencerMetadata extends Proposal {
  rating: number;
  phone: string;
  email: string;
  firstname?: string;
  paypalId?: string;
  lastname?: string;
  addedDirectViaFixedPay?: boolean;
  affiliatePay: AffiliatePay;
  cost: Cost[];
  totalCost: TotalCost;
  status?: string;
  proposalStatus?: ProposalStatus;
  orders?: CampaignOrder[];
  latestOrderDeliveredAt?: string; // only possible if some of the orders are delivered
  fixedPay?: { value: number };
  createOrderInProgress?: boolean;
  allowOrderCreation?: boolean;
  hasCustomProducts?: boolean;
  orderStatus?: FulfillmentStatus;
  partnerizePublisherName?: string;
  partnerizePublisherId?: string;
  shopifySales?: number;
  shopifyCurrency?: string;
  affiliateCode?: AffiliateCode | null;
  influencersCommission?: InfluencersCommission;
  metadataShippingId?: string;
  assignee?: CampaignInfluencerAssignee;
}

export interface BasicInfluencerInfoWithMetadata extends BasicInfluencerInfo {
  influencerMetadata: InfluencerMetadata;
}

export class Cost {
  type: CostType;
  value: string;
  currency: string;
}

export class TotalCost {
  value: number;
  currency: string;
}

export class AffiliateCode {
  code: string;
  url: string;
  custom?: boolean;
}
