import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'sum',
})
export class SumPipe implements PipeTransform {
  transform(value: any[]): number {
    return value.reduce((prevVal, val) => {
      if (Number.isFinite(val)) return prevVal + val;
      return prevVal;
    }, 0);
  }
}
