import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightVariable',
})
export class HighlightVariablePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  private regex = new RegExp('{{[a-zA-Z0-9&._-]*}}', 'ig');

  transform(inputText: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.parseText(inputText));
  }

  private parseText(text: string) {
    if (text.match(this.regex)) {
      text = text.replace(this.regex, function replacer(text: string) {
        return '<span style="color:#DC3545">' + text + '</span>';
      });
    }
    return text;
  }
}
