import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FacebookContentSortKey } from '../../../enums';
import { ContentSearchResponse } from '../../../interfaces';
import { FacebookContentFilters } from '../content-filter.service';

@Injectable()
export class FacebookContentService {
  constructor(private http: HttpClient) {}

  searchContent(filters: FacebookContentFilters, page: number, allContent = false): Promise<ContentSearchResponse> {
    return this.http
      .post<ContentSearchResponse>(`${environment.api}/api/facebook/content/`, filters, {
        params: {
          page: page.toString(),
          allContent: allContent.toString(),
        },
      })
      .toPromise();
  }

  getTopContent(location: string, sortKey: FacebookContentSortKey): Promise<ContentSearchResponse> {
    return this.http
      .get<ContentSearchResponse>(`${environment.api}/api/facebook/content/top`, {
        params: { location, sortKey },
      })
      .toPromise();
  }

  exportMediaReport(contentIds: string[], campaignSlugName?: string): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/facebook/content/export/search`,
        {
          contentIds: contentIds,
          campaignSlugName,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }
}
