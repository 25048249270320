import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayRef, ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'ai-tooltip',
  templateUrl: './tooltip.control.html',
  styleUrls: ['./tooltip.control.scss'],
})
export class TooltipControl implements OnInit {
  @ViewChild('tooltipTemplate') tooltipTemplate: TemplateRef<unknown>;
  @ViewChild('trigger', { static: true }) trigger: ElementRef;
  @ViewChild('tooltipContainer') tooltipContainer: ElementRef;
  @Input() tooltips: string[];
  @Input() placement: 'left' | 'top' | 'right' | 'bottom' = 'bottom';
  @Input() offsetX = 5;
  @Input() offsetY = 5;
  @Input() disabled = false;
  @Input() displayArrow = true;
  overlayRef: OverlayRef;
  isOverlayAttached = false;
  delay = 10;
  private positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
  ];
  constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.setPosition();
  }

  onMouseEnter(): void {
    if (!this.isOverlayAttached && !this.disabled) {
      this.initTooltip();
    }
  }

  onMouseLeave(event: MouseEvent): void {
    const targetElem = event.relatedTarget as Element;
    if (targetElem !== this.tooltipContainer?.nativeElement) {
      this.overlayRef?.detach();
      this.isOverlayAttached = false;
    }
  }

  private setPosition() {
    if (this.placement === 'top') {
      this.positions[0].originX = 'center';
      this.positions[0].originY = 'top';
      this.positions[0].overlayX = 'center';
      this.positions[0].overlayY = 'bottom';
    } else if (this.placement === 'left') {
      this.positions[0].originX = 'start';
      this.positions[0].originY = 'center';
      this.positions[0].overlayX = 'end';
      this.positions[0].overlayY = 'center';
      this.positions[0].offsetX = -10;
    } else if (this.placement === 'right') {
      this.positions[0].originX = 'end';
      this.positions[0].originY = 'center';
      this.positions[0].overlayX = 'start';
      this.positions[0].overlayY = 'center';
    }
  }

  private initTooltip(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.trigger.nativeElement)
      .withPositions(this.positions);
    this.overlayRef = this.overlay.create({
      positionStrategy: positionStrategy.withViewportMargin(10),
    });
    this.isOverlayAttached = !this.isOverlayAttached;
    const templatePortal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef);
    const templateRef = this.overlayRef.attach(templatePortal);
  }
}
