import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlAbstractDirective } from '../control';
import { AutoComplete } from '../control.enum';

@Component({
  selector: 'ai-select',
  templateUrl: './select.control.html',
  styleUrls: ['./select.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectControl),
      multi: true,
    },
  ],
})
export class SelectControl extends ControlAbstractDirective implements OnInit {
  @Input() autoComplete: AutoComplete = 'off';
  @Input() telCode: string;
  @Input() spellCheck = false;
  @Input() readonly = false;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() items;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() isWarnBorderEnabled = false;

  @Input() set disable(value: boolean) {
    if (value && this.control.enabled) {
      this.control.disable();
    }
    if (!value && this.control.disabled) {
      this.control.enable();
    }
  }

  /**
   * @ignore
   */
  hide = false;

  /**
   * @ignore
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.hide = this.inputType === 'password';
  }
}
