import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignReportsService } from '../../../../../../src/app/@core/data/campaign-reports.service';
import { AggregatedComments, MediaComment } from '../../../../../../src/app/interfaces';
import { CommentSnapshotsModalComponent } from './comment-snapshots-modal/comment-snapshots-modal.component';

@Component({
  selector: 'ngx-report-comment-snapshots',
  templateUrl: './comment-snapshots.component.html',
  styleUrls: ['./comment-snapshots.component.scss'],
})
export class CommentSnapshotsComponent implements OnInit {
  @Input()
  maxComments: number;

  @Input()
  media: boolean;

  @Input()
  name: string;

  @Input()
  picture: string;

  @Input()
  mediaComments: MediaComment[] = [];

  @Input()
  filterByMediaPk: string | undefined;

  commentSnapshots: AggregatedComments = { positive: [], negative: [], neutral: [] };

  constructor(private modalService: NgbModal, private campaignReportsService: CampaignReportsService) {}

  ngOnInit(): void {
    this.commentSnapshots = this.getCommentSnapshots();
  }

  getCommentSnapshots(): AggregatedComments {
    if (this.filterByMediaPk) {
      this.mediaComments = this.mediaComments.filter((c) => c.mediaPk === this.filterByMediaPk);
    }
    return this.campaignReportsService.getAggregatedComments(this.mediaComments);
  }

  loadMore(sentiment: string, name?: string, picture?: string): void {
    const loadMoreModal = this.modalService.open(CommentSnapshotsModalComponent, {
      centered: true,
      windowClass: 'comment-snapshots-modal',
    });

    const modalContent = loadMoreModal.componentInstance;
    modalContent.comments = { sentiment, media: this.media, content: this.commentSnapshots[sentiment] };
    modalContent.creator = { name, picture };
  }
}
