import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOutAnimation } from '../../../@core/utils/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-unique-followers',
  templateUrl: './unique.followers.component.ts.component.html',
  styleUrls: ['./unique.followers.component.ts.component.scss'],
  animations: fadeInOutAnimation,
  providers: [NgbModule],
})
export class UniqueFollowersComponent {
  @Input() showGroupStatistics: boolean;
  @Input() showComputeGroupStatisticsButton: boolean;
  @Input() showComputePendingStatus: boolean;
  @Input() uniqueReach: number;
  @Input() uniqueReachPercentage: number;
  @Input() totalInfluencers: number;
  @Output() calculate = new EventEmitter();
  action() {
    this.calculate.emit({
      event: 'calculate-overlap',
    });
  }
}
