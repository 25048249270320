import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Typography } from '../../common.enum';
import { CdkOverlayOrigin, ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DropDownOverlayBaseComponent } from '../base-component/drop-down-overlay-base.component';
import { OverlayComponent } from '../overlay-component/overlay.component';
import { FormControl } from '@angular/forms';
import { DropDownOption } from '../../control.interface';
import { isEqual, cloneDeep } from 'lodash';
@Component({
  selector: 'ai-search-dropdown',
  templateUrl: './search-dropdown.control.html',
  styleUrls: ['./search-dropdown.control.scss'],
})
export class SearchDropdownControl extends DropDownOverlayBaseComponent implements OnInit, OnDestroy, DoCheck {
  //Subscribe to control valueChanges event to  create search logic externally
  //Change value of options input to  control the dropdown values
  //Subscribe to action for checkbox clicked events
  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @ViewChild('trigger', { static: true }) trigger: ElementRef;
  @ViewChild(CdkOverlayOrigin, { static: true }) origin: CdkOverlayOrigin;
  @Output() clear: EventEmitter<PointerEvent> = new EventEmitter();
  @Output() dropDownToggle: EventEmitter<boolean> = new EventEmitter();

  inputElement: HTMLInputElement;
  inputClearable = false;
  canvas: HTMLCanvasElement;
  groupNameTypo: Typography = 'caption-2';
  isOverlayAttached = false;
  customDisplayText: string;
  addNumber = '';
  customDisplayWidth: string;
  private valueChangeSubscriber;
  private actionSubscriber;
  private previousOptions: DropDownOption;

  constructor(private overlay: Overlay) {
    super();
  }

  //check if options changed
  ngDoCheck(): void {
    if (!isEqual(this.previousOptions, this.options)) {
      const searchTerm = this.control?.value;
      if (searchTerm) this.highlightOptions(searchTerm);
      this.previousOptions = cloneDeep(this.options);
    }
  }

  ngOnInit(): void {
    this.inputElement = this.trigger.nativeElement.querySelector('input');
    this.actionSubscriber = this.action.subscribe((e) => {
      this.inputElement.focus();
    });
    this.inputElement.addEventListener('focus', (event) => {
      if (!this.isOverlayAttached) {
        this.control.setValue('');
        this.inputClearable = false;
        this.displayOverlay();
      }
    });
    this.inputElement.addEventListener('blur', (event: FocusEvent) => {
      if (event.relatedTarget) {
        if (this.isOverlayAttached) {
          this.isOverlayAttached = false;
          this.overlayRef.detach();
        }
      }
    });
    this.inputElement.addEventListener('mouseover', (event) => {
      if (this.isOverlayAttached) document.addEventListener('wheel', this.disableScroll, { passive: false });
    });
    this.inputElement.addEventListener('mouseleave', (event) => {
      document.removeEventListener('wheel', this.disableScroll);
    });
    this.valueChangeSubscriber = this.control.valueChanges.subscribe((val) => {
      this.onControlValueChanged(val);
    });
  }
  disableScroll(e): void {
    e.preventDefault();
    e.stopPropagation();
  }
  ngOnDestroy(): void {
    this.valueChangeSubscriber.unsubscribe();
    this.actionSubscriber.unsubscribe();
  }

  private positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];
  private initOverlayRef() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.trigger.nativeElement)
        .withPositions(this.positions)
        .withViewportMargin(10),
    });
  }

  private setupOverlayComponent() {
    const component = new ComponentPortal(OverlayComponent);
    const componentRef = this.overlayRef.attach(component);
    componentRef.instance.options = this.options;
    componentRef.instance.action = this.action;
    componentRef.instance.multi = this.multi;
    componentRef.instance.overlayRef = this.overlayRef;
    componentRef.instance.size = this.size;
    componentRef.instance.stateless = this.stateless;
    componentRef.instance.emptyDisplay = this.emptyDisplay;
    componentRef.instance.customWidth = this.trigger.nativeElement.offsetWidth;
    componentRef.instance.groupNameTypo = this.groupNameTypo;
    componentRef.instance.shiftSelectedToTop = this.shiftSelectedToTop;
    componentRef.instance.dropdownItemType = this.dropdownItemType;
  }

  displayOverlay(): void {
    this.addNumber = '';
    if (this.isOverlayAttached) return;
    this.inputElement.style.backgroundColor = null;
    this.inputElement.placeholder = this.placeholder;
    this.customDisplayText = '';
    this.options.groupOptions.forEach((group) => {
      group.items.forEach((item) => {
        item.displayName = '';
        item.displaySubName = '';
      });
    });
    this.initOverlayRef();
    this.isOverlayAttached = !this.isOverlayAttached;
    this.setupOverlayComponent();
    this.overlayRef.backdropClick().subscribe(() => {
      this.isOverlayAttached = false;
      this.overlayRef.detach();
    });
    this.overlayRef.detachments().subscribe(() => {
      this.onOverlayDetached();
    });
    this.dropDownToggle.emit(true);
    document.addEventListener('wheel', this.disableScroll, { passive: false });
  }

  @HostListener('document:keydown.escape')
  onEscapeDown() {
    if (this.isOverlayAttached) {
      this.isOverlayAttached = false;
      this.inputElement.blur();
      this.overlayRef.detach();
    }
  }

  onClear(event) {
    this.inputClearable = false;
    this.options.groupOptions.forEach((group) => {
      group.items.forEach((item) => {
        item.value = false;
      });
    });
    this.inputElement.style.backgroundColor = null;
    this.customDisplayText = '';
    this.inputElement.placeholder = this.placeholder;
    this.clear.emit(event);
  }

  onOverlayDetached() {
    const selectedKeys = [];
    let selectedItemsDisplay = '';
    this.options.groupOptions.forEach((group) => {
      group.items.forEach((item) => {
        item.displayName = '';
        item.displaySubName = '';
        if (item.value) {
          selectedKeys.push(item.key);
        }
      });
    });
    let index;
    for (index = 0; index < selectedKeys.length; index++) {
      const key = selectedKeys[index];
      if (
        this.measureTextLength(selectedItemsDisplay + `${key} +${selectedKeys.length - index} X`) >
        (this.inputElement.offsetWidth - 24) * 0.9
      ) {
        selectedItemsDisplay += ` <span class=sub-heading-h2>+${selectedKeys.length - index}</span>`;
        break;
      }
      selectedItemsDisplay += index > 0 ? ', ' : '';
      selectedItemsDisplay += key;
    }
    if (index === 0 && selectedItemsDisplay) {
      selectedItemsDisplay = selectedKeys[0];
      if (selectedKeys.length > 1) this.addNumber = `+${selectedKeys.length - 1}`;
    }
    this.inputClearable = !!selectedItemsDisplay;
    if (selectedItemsDisplay) {
      this.inputElement.style.backgroundColor = '#F5F6F7';
      this.inputElement.placeholder = '';
    } else this.inputElement.style.backgroundColor = null;
    this.control.setValue('');
    this.customDisplayWidth = `${this.inputElement.offsetWidth - 24 - this.measureTextLength(this.addNumber)}px`;
    this.customDisplayText = selectedItemsDisplay;
    this.isOverlayAttached = false;
    this.dropDownToggle.emit(false);
    document.removeEventListener('wheel', this.disableScroll);
  }

  onControlValueChanged(val) {
    if (this.isOverlayAttached) {
      this.highlightOptions(val);
    }
  }
  private highlightOptions(text: string): void {
    text = text.trim();
    this.options.groupOptions.forEach((group) => {
      group.items.forEach((item) => {
        item.displayName = item.key.toString().replace(new RegExp(text, 'gi'), (match) => `<u><b>${match}</b></u>`);
        item.displaySubName = item.subName
          ?.toString()
          .replace(new RegExp(text, 'gi'), (match) => `<u><b>${match}</b></u>`);
      });
    });
  }
  private measureTextLength(text: string): number {
    if (!this.canvas) this.canvas = document.createElement('canvas');
    const context = this.canvas.getContext('2d');
    const inputStyle = window.getComputedStyle(this.inputElement);
    context.font = `${inputStyle.font}`;
    return context.measureText(text).width;
  }
}
