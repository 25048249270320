import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AdminGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAdmin = this.auth.isAdmin();
    if (!isAdmin) {
      console.error('admin guard rejected access to ' + state.url);
      this.router.navigate(['pages', 'dashboard']);
    }
    return isAdmin;
  }
}
