import { Directive, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[autoWidth]',
})
export class AutoWidthDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.adjustWidth();

    const observer = new MutationObserver(() => {
      this.adjustWidth();
    });

    observer.observe(this.el.nativeElement, { childList: true, subtree: true });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.adjustWidth();
    }, 150);
  }

  @HostListener('input')
  onInput(): void {
    this.adjustWidth();
  }

  adjustWidth(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;

    const tempSpan = document.createElement('span');
    tempSpan.innerText = inputElement.value || '\u00A0';
    tempSpan.style.font = window.getComputedStyle(inputElement).font;

    document.body.appendChild(tempSpan);
    const contentWidth = tempSpan.getBoundingClientRect().width;
    document.body.removeChild(tempSpan);

    const padding =
      parseFloat(window.getComputedStyle(inputElement).paddingLeft) +
      parseFloat(window.getComputedStyle(inputElement).paddingRight);
    inputElement.style.width = `${contentWidth + 3.5 + padding}px`; // +3.5 for the cursor vertical line and some breathing space just in case
  }
}
