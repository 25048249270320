import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GroupInfo, GroupService } from '../../../@core/data/group.service';
import { Platform, Platforms, PlatformsV2, VeraPlatforms } from '../../../enums';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../@core/feature-flag/flags';

@Component({
  selector: 'ngx-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss'],
})
export class CreateGroupModalComponent implements OnInit {
  @Input() influencersUsernames: string[];
  @Input() platform: PlatformsV2 = Platforms.instagram;
  @Input() singlePlatformSelectionEnabled = false;
  @Input() enabledPlatform: Platform[];
  @Input() fromCommunityAndVeraEnabled: boolean;
  @Output() groupCreatedEvent = new EventEmitter<string>();
  @ViewChildren('groupTagSelect') groupTagSelect: any[];
  @ViewChild('groupnameinput', { static: true }) groupNameInput: ElementRef;
  availablePlatforms = Object.keys(Platforms).filter((key) => key !== 'facebook' && key !== 'twitter');

  groupName: string;
  groupTags?: string[] = [];
  isAgencyBrandGroupEnabled = false;
  errorMessage: string;
  loading: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private groupService: GroupService,
    private toastrService: ToastrService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.groupNameInput?.nativeElement.focus(), 0);
    this.featureFlagService
      .isFeatureReleased(RELEASE_FLAGS.AGENCY_X_BRAND_GROUP)
      .then((result) => (this.isAgencyBrandGroupEnabled = result));
  }

  closeModal(): void {
    this.activeModal.close();
  }

  createGroup(): void {
    this.loading = true;
    if (this.groupName.trim().length > 0) {
      this.createGroupAndAddInfluencers();
    } else {
      this.errorMessage = 'Please enter a valid group name.';
    }
  }

  createGroupAndAddInfluencers(): void {
    const platformDetail =
      this.fromCommunityAndVeraEnabled && this.platform === Platforms.instagram
        ? VeraPlatforms.instagram
        : this.platform;

    this.groupService
      .createGroup(this.groupName.trim(), platformDetail, this.groupTags)
      .then(async (createGroupRes) => {
        if (createGroupRes?.createdGroup?.slugName) {
          this.groupService.groupCreatedNewEvent(createGroupRes.createdGroup);
        }

        if (this.singlePlatformSelectionEnabled) {
          this.groupService.groupCreatedEvent(createGroupRes.slugName);
          this.groupCreatedEvent.emit(createGroupRes.slugName);
          this.toastrService.success(createGroupRes?.message);
          this.activeModal.close({
            createGroupRes,
            platform: platformDetail,
            createdGroup: createGroupRes.createdGroup,
          });
          return;
        }
        if (this.influencersUsernames && this.influencersUsernames?.length > 0) {
          const group = await this.groupService.getGroup(createGroupRes.slugName, this.platform);
          await this.groupService
            .addInfluencerToGroup(group as GroupInfo, this.influencersUsernames, this.platform)
            .then((addInfluencerRes) => {
              this.groupService.groupCreatedEvent(createGroupRes.slugName);
              this.groupCreatedEvent.emit(createGroupRes.slugName);
              this.toastrService.success(createGroupRes?.message);
              this.toastrService.success(addInfluencerRes?.message);
              this.activeModal.close({ createdGroup: createGroupRes.createdGroup });
            })
            .catch((err: HttpErrorResponse) => {
              this.groupService.groupCreatedEvent(createGroupRes.slugName);
              this.groupCreatedEvent.emit(createGroupRes.slugName);
              this.toastrService.success(createGroupRes?.message);
              if (err.status === 429) {
                this.toastrService.error(
                  `Exceeded grouped influencers limit. Delete groups or remove grouped influencers and try again.`,
                );
              } else {
                this.toastrService.error(`Something went wrong while adding influencers to group. Try again.`);
              }
            });
        } else {
          this.groupService.groupCreatedEvent(createGroupRes.slugName);
          this.groupCreatedEvent.emit(createGroupRes.slugName);
          this.toastrService.success(createGroupRes?.message);
          this.activeModal.close({ createdGroup: createGroupRes.createdGroup });
        }
      })
      .catch(
        (res: HttpErrorResponse) =>
          (this.errorMessage =
            res.error?.message === 'Error (invalid groupName!)'
              ? 'Please enter a valid group name.'
              : res.error.message),
      )
      .finally(() => (this.loading = false));
  }
  onPlatformSelectionChange(event: Platform | VeraPlatforms): void {
    this.platform = Platforms[event];
  }

  groupTagKeyDown(event): void {
    if (event.keyCode === 32 || event.keyCode === 13) {
      // * `Space` or `Enter` keys pressed
      this.groupTagSelect.forEach((element) => {
        const tag = element['searchTerm']?.trim();
        if (tag) {
          this.groupTags = (this.groupTags || []).concat(tag).slice(0, 5);
          element['searchTerm'] = '';
        }
      });
    }
  }
}
