import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CollaborationTeam } from '../../interfaces/collaboration.interface';

@Injectable({
  providedIn: 'root',
})
export class CollaborationTeamsService {
  constructor(private http: HttpClient) {}

  async getCollaborationTeams(): Promise<CollaborationTeam[]> {
    return this.http.get<CollaborationTeam[]>(`${environment.api}/api/collaboration-team`).toPromise();
  }

  async createCollaborationTeam(collaborationTeam: CollaborationTeam): Promise<CollaborationTeam> {
    return this.http
      .post<CollaborationTeam>(`${environment.api}/api/collaboration-team`, collaborationTeam)
      .toPromise();
  }

  async updateCollaborationTeam(collaborationTeam: {
    _id: string;
    name: string;
    members: { added: string[]; removed: string[] };
  }): Promise<CollaborationTeam> {
    return this.http.put<CollaborationTeam>(`${environment.api}/api/collaboration-team`, collaborationTeam).toPromise();
  }

  async deleteCollaborationTeam(collaborationTeamId: string): Promise<CollaborationTeam> {
    return this.http
      .delete<CollaborationTeam>(`${environment.api}/api/collaboration-team/${collaborationTeamId}`)
      .toPromise();
  }
}
