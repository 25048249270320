import { Component, Input } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplate, Stage } from '../../../../interfaces';
import { compact, every, findIndex, flatten, maxBy } from 'lodash';
import { CampaignsService } from '../../../../@core/data/campaigns.service';
import { environment } from '../../../../../environments/environment';
import { EmailSequenceService } from '../../../../@core/data/email-sequence.service';
import { ToastrService } from 'ngx-toastr';
import { PlatformsV2 } from '../../../../enums';
import { ChatService } from '../../../../@core/data/chat.service';
import utils from '../../../../@core/utils/utils';

interface SequenceEmail {
  _id?: string;
  selected: boolean;
  delay: number;
  subject: string;
  message: string;
  isDeleted?: boolean;
  isNew?: boolean;
  index: number;
}

@Component({
  selector: 'app-follow-up-email',
  templateUrl: './follow-up-email.component.html',
  styleUrls: ['./follow-up-email.component.scss'],
})
export class FollowUpEmailComponent {
  @Input() sequenceName: string;
  @Input() sequenceId: string;
  @Input() isSequenceEnabled: boolean;
  @Input() campainSlugName: string;
  @Input() isNewSequence = true;
  @Input() platform: PlatformsV2;
  @Input() campaignWorkflowConfigured = false;

  supportedTemplatingVariables: string[];
  isLoading = false;

  MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
  emailForm: FormGroup;
  emailTemplate = new FormControl();
  emailTemplates: EmailTemplate[] = [];
  sequenceEmails: SequenceEmail[] = [
    {
      selected: true,
      delay: 0,
      subject: '',
      message: '',
      isDeleted: false,
      index: 1,
      isNew: true,
    },
  ];

  deletedEmails: SequenceEmail[] = [];
  restrictedKeywordsUsed = false;
  MAX_ALLOWED_FOLLOW_UPS = 10;

  constructor(
    private activeModal: NgbActiveModal,
    private campaignsService: CampaignsService,
    private chatService: ChatService,
    private emailSequenceService: EmailSequenceService,
    private toastrService: ToastrService,
    private _fb: FormBuilder,
  ) {
    this.createForm();
  }

  get totalEmailsInSequence(): number {
    return this.sequenceEmails?.length ?? 1;
  }

  get apiPath(): string {
    return environment.api;
  }

  createForm(): void {
    this.emailForm = this._fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  closeModal(result?: any): void {
    this.activeModal.close(result);
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    try {
      const varaiablesParam = this.campaignWorkflowConfigured ? 'campaigns' : 'campaigns-no-workflow';

      const getSupportedTemplatingVariablesPromise = this.chatService.getSupportedTemplatingVaribles(varaiablesParam);
      const getCampaignTemplatesPromise = this.campaignsService.getTemplates(this.campainSlugName);

      const [supportedTemplatingVariables, campaignTemplates] = await Promise.all([
        getSupportedTemplatingVariablesPromise,
        getCampaignTemplatesPromise,
      ]);

      const invitationTypes = campaignTemplates?.invitationTypes || [];
      this.supportedTemplatingVariables = supportedTemplatingVariables;

      // Now you can use supportedTemplatingVariables and invitationTypes in your code

      this.emailTemplates = flatten(invitationTypes.map(({ templates }) => templates));

      const { success, stages } = await this.emailSequenceService.getStagesInSequence(this.sequenceId);
      if (success && stages && stages.length > 0) {
        const sortedStages = stages.sort((a, b) => a.index - b.index);
        this.sequenceEmails = sortedStages
          .filter((stage) => !stage.isDeleted)
          .map((stage) => ({
            _id: stage._id,
            delay: Math.floor(stage.delayInMs / this.MILLISECONDS_IN_DAY),
            message: stage.action.body,
            subject: stage.action.subject,
            selected: false,
            isDeleted: stage.isDeleted,
            index: stage.index,
          }));

        this.deletedEmails = sortedStages
          .filter((stage) => stage.isDeleted)
          .map((stage) => ({
            _id: stage._id,
            delay: Math.floor(stage.delayInMs / this.MILLISECONDS_IN_DAY),
            message: stage.action.body,
            subject: stage.action.subject,
            selected: false,
            isDeleted: stage.isDeleted,
            index: stage.index,
          }));

        this.sequenceEmails[0].selected = true;
        this.emailForm.patchValue({
          message: this.sequenceEmails[0].message,
          subject: this.sequenceEmails[0].subject,
        });
      }
    } finally {
      this.isLoading = false;
      if (!this.supportedTemplatingVariables) {
        this.supportedTemplatingVariables = [];
      }
    }
  }

  loadTemplate(template: EmailTemplate): void {
    this.emailForm.patchValue({ message: template?.template || '', subject: template?.subject });
    this.saveCurrentEmailEdit();
  }

  scrollToBottom(element: any): void {
    element.scrollTop = element.scrollHeight;
  }

  addFollowUpEmail(element: any): void {
    if (!this.isAddingFollowUpEmailEnabled) {
      return;
    }
    // setting contents of emailForm to corresponding email
    this.saveCurrentEmailEdit(true);
    // adding new blank email for validators to kick in
    const maxIndexMail = maxBy([...this.sequenceEmails, ...this.deletedEmails], (email) => email.index);
    this.sequenceEmails.push({
      delay: 1,
      subject: '',
      message: '',
      selected: true,
      isNew: true,
      index: (maxIndexMail?.index ?? 0) + 1,
    });

    // clear emailForm
    this.emailForm.reset();
    // clear selected email template
    this.emailTemplate.reset();

    setTimeout(() => this.scrollToBottom(element), 100);
  }

  get isAddingFollowUpEmailEnabled(): boolean {
    return this.emailForm.valid && this.sequenceEmails?.length < this.MAX_ALLOWED_FOLLOW_UPS;
  }

  emailsUpdated(stages: Stage[], isExistingStages: boolean): void {
    this.closeModal(true);
    if (!isExistingStages && this.isNewSequence) {
      this.emailSequenceService.sendEmailSequenceUpsertedEvent({
        kind: 'EMAILS_ADDED',
        metadata: {
          sequenceId: this.sequenceId,
          sequenceName: this.sequenceName,
          stages,
        },
      });
    } else {
      this.emailSequenceService.sendEmailSequenceUpsertedEvent({
        kind: 'OPEN_SEQUENCES',
        metadata: {
          sequenceId: this.sequenceId,
          sequenceName: this.sequenceName,
          stages,
        },
      });
    }
  }

  editSequence(): void {
    this.emailSequenceService.sendEmailSequenceUpsertedEvent({
      kind: 'EDIT_SEQUENCE',
      metadata: {
        sequenceId: this.sequenceId,
        campaignSlugName: this.campainSlugName,
        platform: this.platform,
      },
    });
    this.closeModal();
  }

  async saveSequence(): Promise<void> {
    this.isLoading = true;
    try {
      this.saveCurrentEmailEdit();
      const allSortedEmails = [...this.sequenceEmails, ...this.deletedEmails].sort((a, b) => a.index - b.index);
      const newStages = this.emailsToStages(allSortedEmails, 'NEW');
      const existingStages = this.emailsToStages(allSortedEmails, 'EXISTING');
      let stagesAfterInsertionAndUpdation: Stage[] = [];

      for (const mail of this.sequenceEmails) {
        if (!this.checkMessageForRestrictedKeywords(mail?.subject + ' ' + mail?.message)) {
          this.isLoading = false;
          return;
        }
      }

      if (newStages && newStages.length > 0) {
        const addedStages = await this.emailSequenceService
          .addEmailsToSequence(this.sequenceId, newStages)
          .then(({ stages }) => {
            return stages;
          });
        stagesAfterInsertionAndUpdation = stagesAfterInsertionAndUpdation.concat(addedStages);
      }

      if (existingStages && existingStages.length > 0) {
        const updatedStages = await this.emailSequenceService
          .updateEmailsToSequence(this.sequenceId, existingStages)
          .then(({ stages }) => {
            return stages;
          });
        stagesAfterInsertionAndUpdation = stagesAfterInsertionAndUpdation.concat(updatedStages);
      }

      this.emailsUpdated(stagesAfterInsertionAndUpdation, existingStages?.length > 0);
    } catch (error) {
      this.toastrService.error(`Failed to save emails to sequence ${this.sequenceName}, please try again!`);
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  checkMessageForRestrictedKeywords(message: string): boolean {
    const restrictedKeywords = utils.hasIncorrectTemplateKeywords(message, this.supportedTemplatingVariables);

    if (restrictedKeywords?.length) {
      this.restrictedKeywordsUsed = true;
      this.toastrService.error(
        `You have not yet configured the variable (${restrictedKeywords.join(', ')}) you are trying to insert`,
      );
      return false;
    }
    this.restrictedKeywordsUsed = false;
    return true;
  }

  emailsToStages(emails: SequenceEmail[], type: 'EXISTING' | 'NEW'): Stage[] {
    return compact(
      emails.map((email) => {
        if ((type === 'EXISTING' && !email.isNew) || (type === 'NEW' && email.isNew)) {
          return {
            _id: email._id,
            sequenceId: this.sequenceId,
            action: {
              kind: 'SEND_MAIL',
              subject: email.subject,
              body: email.message,
            },
            delayInMs: email.delay ? email.delay * this.MILLISECONDS_IN_DAY : 0, // convert days to milliseconds
            index: email.index,
            isDeleted: email.isDeleted,
          };
        }

        return undefined;
      }),
    );
  }

  selectEmail(index: number): void {
    if (!this.isLoading) {
      this.saveCurrentEmailEdit();
      this.sequenceEmails.forEach((i) => (i.selected = false));
      this.sequenceEmails[index].selected = true;
      this.emailForm.patchValue({
        message: this.sequenceEmails[index].message,
        subject: this.sequenceEmails[index].subject,
      });
      this.emailTemplate.reset();
    }
  }

  saveCurrentEmailEdit(deselect = false): void {
    const indexOfCurrentEmail = findIndex(this.sequenceEmails, (e) => e.selected, 0);
    if (indexOfCurrentEmail !== -1) {
      const currentEmail = this.sequenceEmails[indexOfCurrentEmail];
      this.sequenceEmails[indexOfCurrentEmail] = {
        ...currentEmail,
        ...this.emailForm.value,
        selected: deselect ? false : currentEmail.selected,
      };
    }
  }

  get isAnyEmailInvalid(): boolean {
    return (
      this.emailForm.invalid ||
      !every(
        this.sequenceEmails
          .filter((email) => !email.selected)
          .map((i) => i.subject?.trim()?.length > 0 && i.message?.trim()?.length > 0),
      )
    );
  }

  async deleteEmail(i: number): Promise<void> {
    if (this.sequenceEmails && this.sequenceEmails[i]) {
      const allMailsAfterDeletion = this.sequenceEmails.splice(i);
      const [deletedEmail, nextMailAfterDeletion, ...undeletedMailBehindDeletion] = allMailsAfterDeletion;
      if (nextMailAfterDeletion) {
        nextMailAfterDeletion.selected = true;
        this.emailForm.patchValue({
          message: nextMailAfterDeletion.message,
          subject: nextMailAfterDeletion.subject,
        });
      } else {
        const firstMail = this.sequenceEmails[0];
        firstMail.selected = true;
        this.emailForm.patchValue({
          message: firstMail.message,
          subject: firstMail.subject,
        });
      }

      if (deletedEmail.isNew) {
        if (nextMailAfterDeletion) {
          this.sequenceEmails.push({ ...nextMailAfterDeletion, index: nextMailAfterDeletion.index - 1 });
        }
        this.sequenceEmails.push(...undeletedMailBehindDeletion.map((mail) => ({ ...mail, index: mail.index - 1 })));
      } else {
        deletedEmail.isDeleted = true;
        this.deletedEmails.push(deletedEmail);
        if (nextMailAfterDeletion) {
          this.sequenceEmails.push(nextMailAfterDeletion);
        }
        this.sequenceEmails.push(...undeletedMailBehindDeletion);
      }
    }
  }
}
