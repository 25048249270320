import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { registerMap } from 'echarts';
import { max, min, sample } from 'lodash';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MapItem } from '../../interfaces';
import { PrintService } from '../../pages/print/print-layout/print.service';
import { BaseEchartsPrintedComponent } from './echarts-printed.component';

@Component({
  selector: 'ngx-echarts-bubble-map',
  styleUrls: ['./echarts.component.scss'],
  templateUrl: './echarts.component.html',
})
export class EchartsBubbleMapComponent extends BaseEchartsPrintedComponent implements OnDestroy, OnInit {
  options: any;
  @Input() mapData: MapItem[];

  private alive = true;
  printedChart: boolean;

  constructor(private theme: NbThemeService, private http: HttpClient, private print: PrintService) {
    super();
    this.printedChart = print.isPrinting;
  }

  ngOnInit(): void {
    combineLatest([this.http.get('assets/map/world.json'), this.theme.getJsTheme()])
      .pipe(takeWhile(() => this.alive))
      .subscribe(([map, config]: [any, any]) => {
        registerMap('world', map);

        const colors = config.variables;
        const bubbleTheme = config.variables.bubbleMap;

        // give random color if not provided
        this.mapData.forEach((mapItem) => {
          if (!mapItem.color) {
            mapItem.color = sample([
              colors.primary,
              colors.info,
              colors.success,
              colors.warning,
              colors.danger,
            ] as string[]);
          }
        });

        this.options = {
          tooltip: {
            trigger: 'item',
            formatter: (params) => `${params.marker} ${params.name}: ${params.value[2].toFixed(1)}%`,
          },
          visualMap: {
            show: false,
            min: min(this.mapData.map((mapItem) => mapItem.value)),
            max: max(this.mapData.map((mapItem) => mapItem.value)),
            inRange: { symbolSize: [10, 50] },
          },
          geo: {
            name: 'Audience Distribution',
            type: 'map',
            map: 'world',
            roam: true,
            label: { emphasis: { show: false } },
            itemStyle: {
              normal: {
                areaColor: bubbleTheme['areaColor'],
                borderColor: bubbleTheme['areaBorderColor'],
              },
              emphasis: { areaColor: bubbleTheme['areaHoverColor'] },
            },
            zoom: 1.25,
            scaleLimit: {
              min: 1,
              max: 5,
            },
          },
          series: [
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              data: this.mapData.map((mapItem) => {
                return {
                  name: mapItem.label,
                  value: [
                    mapItem.coordinates ? mapItem.coordinates.longitude : null,
                    mapItem.coordinates ? mapItem.coordinates.latitude : null,
                    mapItem.value,
                  ],
                  label: {
                    emphasis: {
                      show: false,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: mapItem.color,
                    },
                  },
                };
              }),
            },
          ],
        };
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onChartEvent(event: any) {
    console.log(event);
  }
}
