import { Component, Input } from '@angular/core';
import { CommentAnalysisComponentAbstract } from './comment-analysis-abstract';

@Component({
  selector: 'ngx-report-aggregated-comment-analysis',
  templateUrl: './comment-analysis.component.html',
  styleUrls: ['./report-aggregated-comment-analysis.component.scss', './comment-analysis-shared.scss'],
})
export class ReportAggregatedCommentAnalysisComponent extends CommentAnalysisComponentAbstract {
  wrapperClassName = 'aggregated-comment-analysis';
  isGeneralIconShow = true;
  @Input()
  printed: true;

  constructor() {
    super();
  }
}
