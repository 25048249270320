<div class="add-to-container">
  <ul>
    <li class="sub-heading-h2 {{tab.active?'active':''}}" *ngFor="let tab of tabs" (click)="selectTab(tab.name)">
      <i class="{{tab.icon}}"></i><span> {{tab.name}} </span>
    </li>
  </ul>
  <div class="seperator thick"></div>
  <div class="add-to-list" infinite-scroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="150"
    [scrollWindow]="false" (scrolled)="onScroll()" fromRoot="true">
    <ng-container *ngIf="isGroup?recentGroup:recentCampaign; else emptyContent">
      <form>
        <div class="recent">
          <p class="caption">RECENT</p>
          <div class="entries">
            <ai-checkbox labelSize="body-2" [label]="isGroup?recentGroup?.name:recentCampaign?.campaignName"
              (action)="entryChecked(isGroup?recentGroup:recentCampaign)" overflow="hidden">
            </ai-checkbox>
            <div
              *ngIf="(isGroup?recentGroup?.influencers:recentCampaign?.influencers)?.length>0||recentCampaign?.pendingInfluencers?.length>0"
              class="img-group">
              <ng-container
                *ngFor="let influencer of getInfluencersInEntry(isGroup?recentGroup:recentCampaign);index as i;count as total">
                <ng-container *ngIf="(i<3 && total>4)||
                      (i<4 && total<=4)">
                  <img *ngIf="!!influencer.profilePicture" [src]="influencer.profilePicture">
                </ng-container>
                <ai-tooltip placement="right" *ngIf="i===3 && total>4"
                  [tooltips]="getRemainingInfluencersUsername(isGroup?recentGroup:recentCampaign)">
                  <div class="additional-influencers af-caption">
                    +{{total-3}}</div>
                </ai-tooltip>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="seperator" *ngIf="isGroup?groups.length:campaigns.length"></div>
        <div class="all" *ngIf="isGroup?groups.length:campaigns.length">
          <p class="caption">ALL {{isGroup?'GROUPS':'CAMPAIGNS'}}</p>
          <ng-container *ngFor="let item of isGroup?groups:campaigns">
            <div class="entries">
              <ai-checkbox labelSize="body-2" [label]="isGroup?item?.name:item?.campaignName"
                (action)="entryChecked(item)" overflow="hidden">
              </ai-checkbox>
              <div *ngIf="item?.influencers?.length>0 || item?.pendingInfluencers?.length>0" class="img-group">
                <ng-container *ngFor="let influencer of getInfluencersInEntry(item);index as i;count as total">
                  <ng-container *ngIf="(i<3 && total>4)||
                          (i<4 && total<=4)">
                    <img *ngIf="!!influencer.profilePicture" [src]="influencer.profilePicture">
                  </ng-container>
                  <ai-tooltip placement="right" *ngIf="i===3 && total>4"
                    [tooltips]="getRemainingInfluencersUsername(item)">
                    <div class="additional-influencers af-caption">
                      +{{total-3}}</div>
                  </ai-tooltip>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </ng-container>
    <ng-template #emptyContent>
      <span class="placeholder-text empty" *ngIf="!loading">Looks like you have not created a {{currentTab}} yet!</span>
    </ng-template>
    <ngx-loader *ngIf="loading"></ngx-loader>
  </div>
  <footer>
    <ai-button [disabled]="!!loading" prefix="far fa-plus" type="text" label="{{creationSubLabel}} {{currentTab}}"
      (click)="createNew()"></ai-button>
    <ai-button class="submit-btn" *ngIf="isGroup?recentGroup:recentCampaign"
      [disabled]="!!loading || isSubmitDisabled()" size="small" type="fill" [label]="getSubmitLabel()"
      (click)="addToExisting()"></ai-button>
  </footer>

</div>