import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../@core/auth/local-storage.service';

@Component({
  selector: 'ngx-auth-block',
  styleUrls: ['./auth-block.component.scss'],
  template: `
    <nb-alert *ngIf="showCookiesAlert" outline="warning"> Please allow cookies from the browser settings. </nb-alert>
    <ng-content></ng-content>
  `,
})
export class NgxAuthBlockComponent implements OnInit {
  showCookiesAlert = false;

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.showCookiesAlert = !this.localStorageService.checkLocalStorage();
  }
}
