import { UploadStatusService } from './upload-status.service';
import { ToastrService } from 'ngx-toastr';

export class CustomUploadAdapter {
  xhr: any;
  loader: any;
  apiPath: string;

  constructor(loader, apiPath, private uploadStatusService: UploadStatusService, private toastrService: ToastrService) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.apiPath = apiPath;
    this.toastrService = toastrService;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        }),
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open('POST', `${this.apiPath}/api/chats/assets/upload`, true);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;
    const ToastErrorText = `Sorry, we couldn't upload the file: ${file.name}. Please try again and make sure that the file is not too large or in an unsupported format`;

    xhr.addEventListener('error', () => {
      // reject(genericErrorText);
      this.OnError(ToastErrorText, genericErrorText);
      this.uploadStatusService.updateUploadStatus('error');
    });
    xhr.addEventListener('abort', () => {
      reject();
      this.uploadStatusService.updateUploadStatus('abort');
    });
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      // Handle different types of errors based on the status code
      if (xhr.status === 200 || xhr.status === 201) {
        resolve({
          default: response.url,
        });
      } else if (xhr.status > 400) {
        this.OnError(ToastErrorText, `${genericErrorText}, ${xhr.response?.message}`);
        this.uploadStatusService.updateUploadStatus('error');
      }

      /**
       * will keep this code for reference, as UploadAdapter is a generic class,
       * and we may need to handle errors differently in the future
       */
      // We may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      // if (!response || response.error) {
      //   return reject(response && response.error ? response.error.message : genericErrorText);
      // }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // resolve({
      //   default: response.url,
      // });
    });

    xhr.addEventListener('loadstart', () => {
      this.uploadStatusService.updateUploadStatus('progress');
    });
    xhr.addEventListener('loadend', () => {
      this.uploadStatusService.updateUploadStatus('completed');
    });

    // Upload progress
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  private OnError(ToastErrorText: string, genericErrorText: string) {
    this.toastrService.warning(ToastErrorText, 'Warning', {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
      newestOnTop: true,
    });
    console.error(`[CK_EDITOR] ${genericErrorText}`);
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append('upload', file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, we can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}
