import { Component, Input } from '@angular/core';
import { ProfileInfluencersModalComponent } from '../../../pages/discovery/profile-influencers-modal/profile-influencers-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupInfo, GroupService } from '../../../@core/data/group.service';
import { PlatformsV2 } from '../../../enums';

@Component({
  selector: 'ngx-empty-widget-group',
  templateUrl: './empty-widget-group.component.html',
  styleUrls: ['./empty-widget-group.component.scss'],
})
export class EmptyWidgetGroupComponent {
  @Input() selectedGroup: GroupInfo;
  @Input() platform: PlatformsV2;
  @Input() showButton = true;
  constructor(private modalService: NgbModal, private groupService: GroupService) {}

  openProfileInfluencersModal(): void {
    const profileInfluencersModal = this.modalService.open(ProfileInfluencersModalComponent, {
      size: 'lg',
      windowClass: '',
      centered: true,
      backdrop: 'static',
    });

    profileInfluencersModal.componentInstance.selectedGroupProps = this.selectedGroup;

    profileInfluencersModal.result
      .then((groupSlug: string) => {
        if (groupSlug === this.selectedGroup.slugName) {
          this.groupService.sendGroupFilterInfuencersChangedEvent(this.selectedGroup);
        }
      })
      .catch(() => ({}));

    const modalContent: ProfileInfluencersModalComponent = profileInfluencersModal.componentInstance;
    modalContent.platform = this.platform;
  }
}
