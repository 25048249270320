import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-input-slider',
  templateUrl: './input-slider.component.html',
  styleUrls: ['./input-slider.component.scss'],
})
export class InputSliderComponent {
  @Input() label: string;

  @Input() unitLabel: string;

  @Input() description: string;

  @Input() min: number;

  @Input() max: number;

  @Input() step: number;

  @Input() value: number;

  @Output() valueChange: EventEmitter<number>;

  constructor() {
    this.valueChange = new EventEmitter();
  }

  onInputChange(): void {
    this.valueChange.next(this.value);
  }
}
