<nb-layout windowMode>
  <nb-layout-column style="padding: 1rem 2rem;">
    <div class="jumbotron">
      <img style="height: 300px; padding-bottom: 1rem;" src="../../../../../assets/images/insufficient-permission.svg">
      <h4>Insufficient Permission</h4>
      <br>
      <p>Oops! Looks like you don't have enough permission to perform this action!</p>
      <p>Please reach out to us in case of any query.</p>
      <br>
      <button class="btn btn-md btn-primary" routerLink="/">Return to Discovery</button>
    </div>
  </nb-layout-column>
</nb-layout>
