<nb-card class="modal-card">
  <nb-card-header class="modal-header">
    <label>Mail Preview ({{chats?.length}})</label>
    <button class="close" aria-label="Close" (click)="closeChatModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </nb-card-header>
  <nb-card-body class="modal-body">
    <div class="preview-container">
      <div class="influencers">
        <div class="header">
          <div class="search-container" [class.disabled]="action !== Actions.default">
            <label class="title">Search</label>
            <div class="search">
              <input type="text" name="keyword" placeholder="Search influencers here" class="input-group input-group-sm"
                [(ngModel)]="searchKeyword" (keyup.enter)="searchSortChats()" keyupDebounce
                (onEvent)="searchSortChats()" trim />
              <button *ngIf="!searchKeyword" (click)="searchSortChats()">
                <i class="fas fa-search"></i></button>
              <button *ngIf="searchKeyword" (click)="searchKeyword = ''; searchSortChats();">
                <i class="fas fa-close"></i></button>
            </div>
          </div>

          <div class="sort-container" [class.disabled]="action !== Actions.default">
            <label class="title">Sort by</label>
            <div class="sort">
              <ng-select bindValue="value" dropdownPosition="bottom" [(ngModel)]="selectedSorting" [items]="Sortings"
                [multiple]="false" [hideSelected]="false" [markFirst]="true" [clearable]="false" [searchable]="false"
                [virtualScroll]="false" class="ng-filter" (change)="searchSortChats()">
                <ng-template ng-label-tmp let-item="item">
                  <span class="ng-value-label">{{item}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="ng-label" [ngClass]="{'selected': item == selectedSorting}">
                    <span class="label">{{item}}</span>
                    <span class="selected">
                      <i *ngIf="item == selectedSorting" class="ion-ios-checkmark"></i>
                    </span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="infuencers-list">
          <nb-list>
            <nb-list-item *ngFor="let chat of chats; let chatIndex = index" size="large"
              [ngClass]="{'selected': chat === selectedChat}"
              [class.disabled]="chat !== selectedChat && action !== Actions.default">
              <user-tile (click)="previewChat(chat)" [userPicture]="chat.picture"
                [name]="chat.fullName | highlight: searchKeyword" [description]="chat.username" [platform]='platform'>
              </user-tile>
              <div class="delete-btn-container">
                <button class="action-btn" (click)="action=Actions.deleting"
                  *ngIf="chats.length > 1 && chat === selectedChat && (action===Actions.editing || action == Actions.deleting)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </div>
            </nb-list-item>
          </nb-list>
        </div>
      </div>
      <div class="chat-preview">
        <div class="header preview">
          <ng-container *ngIf="action === Actions.default">
            <div class="to">
              <label>To</label>
              <div class="email-chip">{{selectedChat.email}}</div>
            </div>
            <button (click)="editChat()" class="action-btn text">Edit</button>

          </ng-container>
          <ng-container *ngIf="action === Actions.deleting">
            <div class="text">
              Are you sure that you want to delete the influencer from the mailing list?
            </div>
            <div>
              <button (click)="deleteInfluencer()" class="action-btn text">YES</button>
              <button (click)="action=Actions.editing" class="action-btn text with-background">NO</button>
            </div>
          </ng-container>
          <ng-container *ngIf="action === Actions.editing">
            <form #form="ngForm" autocomplete="off" class="edit-email-container">
              <div class="form-group to">
                <label for="input-email">To</label>
                <input class="edit-email form-control" id="input-email" name="email" type="email" #email="ngModel"
                  [(ngModel)]="selectedChatEmail" (keydown.space)="$event.preventDefault();"
                  [class.warn]="email.invalid && email.touched" customEmailValidator [required]="true">
              </div>
              <div>
                <button (click)="action=Actions.default" class="action-btn text">Cancel</button>
                <button (click)="saveEditedPreviewChat()"
                  [disabled]="email.invalid && email.touched || !previewChatForm.value?.message"
                  class="btn action-btn text with-background">Save</button>
              </div>
            </form>
          </ng-container>
        </div>
        <div *ngIf="action===Actions.default" class="message-body"
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(selectedChatPreview)"></div>
        <form *ngIf="action !==Actions.default" [formGroup]="previewChatForm">
          <ai-ckeditor [supportedTemplatingVariables]="supportedTemplatingVariables"
            [defaultTemplate]="selectedChatPreview" formControlName="message" [apiPath]="apiPath"></ai-ckeditor>
        </form>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div style="width:100%">
      <span *ngIf="restrictedKeywordsUsed" class="fx-row warn-font">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>Some of the template keywords are not allowed.</span>
      </span>
      <span *ngIf="errorInSendMail" class="fx-row error-font">
        <i class="fa fa-times" aria-hidden="true"></i>
        Looks like you are out of credits! Contact the team or message unlocked influencers.
      </span>
      <div class="footer-tip">
        <ngx-tooltip [placement]="'top-left'">
        </ngx-tooltip>
        <label>
          Click on the influencer's name to see the mail preview.
        </label>
      </div>
    </div>
    <div class="send-mail">
      <button (click)="backToChat()" class="btn btn-xs btn-outline-primary cursor-pointer"
        [disabled]="action===Actions.editing || loading || generatingPreview">
        Back
      </button>
      <button (click)="sendChat()" class="btn btn-xs btn-primary cursor-pointer"
        [disabled]="action===Actions.editing || loading || generatingPreview">
        Send Mail
      </button>
    </div>
  </nb-card-footer>

  <nb-spinner *ngIf="loading"
    [message]="noOfMessagesSent? 'Sending '+ noOfMessagesSent +'/'+totalNumberOfMessagesToBeSend + ' emails...':' Sending...'">
  </nb-spinner>
  <nb-spinner *ngIf="generatingPreview" message="Generating preview..."></nb-spinner>
</nb-card>