import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AggregatedComment } from '../../../../../interfaces';
import { CampaignReportsService } from '../../../../../@core/data/campaign-reports.service';
import utils from '../../../../../@core/utils/utils';
import { DropDownOption } from '../../../../../../../../controls/src';
import { DropDownAction } from '../../../../../../../../controls/src/common.enum';
import { CommentSentimentType, Platforms, PlatformsUpperEnum, VeraPlatforms } from '../../../../../enums';
import { FeatureFlagService } from '../../../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../../../@core/feature-flag/flags';

@Component({
  selector: 'ngx-comment-snapshots-modal',
  templateUrl: './comment-snapshots-modal.component.html',
  styleUrls: ['./comment-snapshots-modal.component.scss'],
})
export class CommentSnapshotsModalComponent implements OnInit, OnDestroy {
  comments: { sentiment: CommentSentimentType; media: boolean; content: AggregatedComment[] };
  commentSentimentEdits: { sentiment?: CommentSentimentType }[] = [];
  creator: { name?: string; picture?: string };
  platform: string;
  profileBaseUrl: string;
  commentOptions: DropDownOption[] = [];
  VeraPlatforms = VeraPlatforms;
  editSentimentEnabledPlatforms = [VeraPlatforms.instagram.toLowerCase()];

  constructor(
    private activeModal: NgbActiveModal,
    private campaignReportsService: CampaignReportsService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnDestroy(): void {
    const edited = !this.commentSentimentEdits.every((sentiment) => {
      if (sentiment.sentiment) return false;
      return true;
    });
    if (edited) {
      this.campaignReportsService.getReportTrigger$.next(true);
    }
  }

  ngOnInit(): void {
    this.campaignReportsService.reportPlatform.subscribe((platform) => {
      this.platform = platform;
      this.profileBaseUrl = utils.getExternalProfileBaseUrl(this.platform);
    });
    this.commentOptions = [];
    this.commentSentimentEdits = [];
    this.comments.content.forEach((comment) => {
      this.commentSentimentEdits.push({});
      this.commentOptions.push(this.getChangeSentimentOptions());
    });
    this.editSentimentEnabledPlatforms.push(Platforms.tiktok.toLowerCase());

    this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.YT_REPORT_EDIT_COMMENT_SENTIMENT).then((isReleased) => {
      if (isReleased) {
        this.editSentimentEnabledPlatforms.push(Platforms.youtube.toLowerCase());
      }
    });
  }

  closeModal(): void {
    this.activeModal.close();
  }

  onChangeCommentSentiment(action: DropDownAction, commentSentimentEdit, comment: AggregatedComment): void {
    action.groupOptions[0].items.forEach((item) => {
      if (item.key !== action.lastAction.key) item.value = false;
    });
    if (!action.lastAction.value) {
      delete commentSentimentEdit.sentiment;
      this.campaignReportsService.editCommentSentiment(
        comment.mediaPk,
        comment.id,
        this.comments.sentiment,
        this.platform as PlatformsUpperEnum,
      );
    } else {
      const chosenSentiment = action.lastAction.key as CommentSentimentType;
      commentSentimentEdit.sentiment = chosenSentiment;
      this.campaignReportsService.editCommentSentiment(
        comment.mediaPk,
        comment.id,
        chosenSentiment,
        this.platform as PlatformsUpperEnum,
      );
    }
  }

  getChangeSentimentOptions(): DropDownOption {
    const positiveOpt = {
      key: 'positive',
      value: false,
      image: 'assets/images/emotions/01_sentiment_satisfied.svg',
      imageStyle: { border: 'none' },
      displayName: 'Positive',
    };
    const negativeOpt = {
      key: 'negative',
      value: false,
      image: 'assets/images/emotions/02_sentiment_dissatisfied.svg',
      imageStyle: { border: 'none' },
      displayName: 'Negative',
    };
    const neutralOpt = {
      key: 'neutral',
      value: false,
      image: 'assets/images/emotions/03_sentiment_neutral.svg',
      imageStyle: { border: 'none' },
      displayName: 'Neutral',
    };

    const opt: DropDownOption = {
      header: 'Mark comment as',
      groupOptions: [
        {
          selector: 'none',
          items: [
            ...(this.comments?.sentiment === 'positive' ? [neutralOpt, negativeOpt] : []),
            ...(this.comments?.sentiment === 'negative' ? [positiveOpt, neutralOpt] : []),
            ...(this.comments?.sentiment === 'neutral' ? [positiveOpt, negativeOpt] : []),
          ],
        },
      ],
    };
    return opt;
  }
}
