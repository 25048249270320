<div [ngClass]="{'form-group': type == 'legacy'}">
  <div [ngClass]="{'mordern': type == 'mordern', 'row': type == 'legacy'}" >
    <div class="col-6 from">
      <label>From</label>
      <div class="input-group input-group-sm">
        <div class="form-control clearable-input-container" [ngClass]="{'disabled': isDisabled}">
          <input [placeholder]="fromPlaceHolder" angular-mydatepicker (dateChanged)="onFromDateChanged($event)"
            [(ngModel)]="fromDate" [options]="fromDatePickerOptions" #fromDatePicker="angular-mydatepicker"
            readonly="true">

          <button *ngIf="isFromDateClearable" type="button" class="btn clear-btn" (click)="fromDatePicker.clearDate()"
            [disabled]="isDisabled">
            <span>×</span>
          </button>
        </div>

        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="fromDatePicker.toggleCalendar()" type="button"
            [disabled]="isDisabled">
            <i class="fa-regular fa-calendar-day"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-6 to">
      <label>To</label>
      <div class="input-group input-group-sm">
        <div class="form-control clearable-input-container to-date-picker" [ngClass]="{'disabled': isDisabled}">
          <input [placeholder]="toPlaceHolder" angular-mydatepicker (dateChanged)="onToDateChanged($event)"
            [(ngModel)]="toDate" [options]="toDatePickerOptions" #toDatePicker="angular-mydatepicker" readonly="true">

          <button *ngIf="isToDateClearable" type="button" class="btn clear-btn" (click)="toDatePicker.clearDate()"
            [disabled]="isDisabled">
            <span>×</span>
          </button>
        </div>

        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="toDatePicker.toggleCalendar()" type="button"
            [disabled]="isDisabled">
            <i class="fa-regular fa-calendar-day"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>