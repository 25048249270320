<div class="col-md-12">
    <div class="not-registered-banner d-flex align-items-center">
        <i class="far fa-triangle-exclamation"></i> 
        <div class="card-text mr-auto registration-invite">
            {{unRegisteredInfluencers?.length}} influencers insights will be estimated or excluded due to
            pending registration.
        </div>
        <ai-button prefix="d-block" typography="body-3" class="mr-2" type="outline" label="COPY INVITE LINK"
            (click)="copyInviteLink()">
        </ai-button>
        <span class="tooltip-wrapper" container="body">
            <ai-button prefix="d-block" typography="body-3" class="mr-2 d-block" label="INVITE NOW"
                (click)="messageInfluencer()">
            </ai-button>
        </span>
    </div>
</div>