import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class ConsoleGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isConsoleEnabled = await this.auth.isConsoleEnabled();
    if (!isConsoleEnabled) {
      console.error('Console guard rejected access to ' + state.url);
      await this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return isConsoleEnabled;
  }
}
