import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IAngularMyDpOptions, IMyDate, IMyDateModel } from 'angular-mydatepicker';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { ContentTimeFilter } from '../../@core/data/content-filter.service';

const TIME_FILTER_MIN_DAYS_BEFORE_TODAY = 7;

@Component({
  selector: 'ngx-date-range',
  styleUrls: ['./date-range.component.scss'],
  templateUrl: './date-range.component.html',
})
export class DateRangeComponent implements OnInit, OnChanges {
  fromDate: IMyDateModel;
  toDate: IMyDateModel;
  maxFromDate: Date;
  fromDatePickerOptions: IAngularMyDpOptions;
  toDatePickerOptions: IAngularMyDpOptions;
  @Input() timeFilter: DatePicker | ContentTimeFilter;
  @Input() PastCalender = true;
  @Input() anyStartDate = false;
  @Input() restrictFromDate = false;
  @Input() restrictDates = false;
  @Input() restrictFromAfterTo = true;
  @Input() dateFormat = 'mmm dd, yyyy';
  @Input() useUTCFormat = false;
  @Output() valueChange = new EventEmitter();
  @Input() type: 'legacy' | 'mordern' = 'legacy';
  private readonly defaultDatePickerOptions: IAngularMyDpOptions = {
    dateFormat: 'mmm dd, yyyy',
    firstDayOfWeek: 'su',
    showSelectorArrow: false,
    selectorWidth: '250px',
    selectorHeight: 'auto',
    sunHighlight: false,
    markWeekends: { marked: false, color: '' },
  };

  @Input() fromPlaceHolder? = 'Select start date';
  @Input() toPlaceHolder? = 'Select end date';

  @Input() isFromDateClearable = false;
  @Input() isToDateClearable = false;

  @Input() isDisabled = false;

  ngOnInit(): void {
    this.defaultDatePickerOptions.dateFormat = this.dateFormat;
    this.fromDatePickerOptions = this.defaultDatePickerOptions;
    this.toDatePickerOptions = this.defaultDatePickerOptions;
    this.setInitialDate();
  }

  ngOnChanges(): void {
    this.setInitialDate();
  }

  setInitialDate(): void {
    // initialises date picker model
    this.fromDate = this.timeFilter.from ? this.toIMyDateModel(this.timeFilter.from) : undefined;
    this.toDate = this.timeFilter.to ? this.toIMyDateModel(this.timeFilter.to) : undefined;
    if (this.restrictFromAfterTo) {
      // prevent from dates to be selectable after toDates and viceversa.
      this.fromDatePickerOptions = {
        ...this.fromDatePickerOptions,
        disableSince: this.toDatePickerDate(moment(this.timeFilter.to).add(1, 'd').toDate()),
      };
      this.toDatePickerOptions = {
        ...this.toDatePickerOptions,
        disableUntil: this.toDatePickerDate(moment(this.timeFilter.from).subtract(1, 'd').toDate()),
      };
    } else {
      this.fromDatePickerOptions = cloneDeep(this.defaultDatePickerOptions);
      this.toDatePickerOptions = cloneDeep(this.defaultDatePickerOptions);
    }

    if (this.anyStartDate) {
      // dont set any restictions on start date
      // Only disables 'to' dates that are before the default 'from' date
      // update this when the from date changes
      this.toDatePickerOptions.disableUntil = this.toDatePickerDate(this.timeFilter.from);
      delete this.fromDatePickerOptions.disableSince;
      return;
    }

    // restrict dates to be between the initial from and to dates, used by campaign content picker
    if (this.restrictDates) {
      const lastDay = moment(this.timeFilter.to).add(1, 'day').toDate();
      const firstDay = moment(this.timeFilter.from).subtract(1, 'day').toDate();
      // disable From dates before the campaign start date
      this.fromDatePickerOptions.disableUntil = this.toDatePickerDate(firstDay);
      // disable To dates before from date and after campaign end date
      this.toDatePickerOptions.disableSince = this.toDatePickerDate(lastDay);
      this.toDatePickerOptions.disableUntil = this.toDatePickerDate(this.timeFilter.from);
    } else if (this.PastCalender) {
      // disables dates tomorrow onwards
      const tomorrow = moment().add(1, 'day').toDate();
      this.toDatePickerOptions.disableSince = this.toDatePickerDate(tomorrow);

      // disables dates before the 'from' date
      // update this when the from date changes
      this.toDatePickerOptions.disableUntil = this.toDatePickerDate(this.timeFilter.from);

      // restricts from date at least TIME_FILTER_MIN_DAYS_BEFORE_TODAY days before today
      this.maxFromDate = moment(tomorrow).subtract(TIME_FILTER_MIN_DAYS_BEFORE_TODAY, 'days').toDate();
      this.fromDatePickerOptions.disableSince = this.toDatePickerDate(this.maxFromDate);

      if (this.restrictFromDate) {
        const oneMonth = moment().subtract(30, 'day').toDate();
        this.fromDatePickerOptions.disableUntil = this.toDatePickerDate(oneMonth);
      }
    } else {
      // disables dates before yesterday
      const yesterday = moment().subtract(1, 'day').toDate();
      this.fromDatePickerOptions.disableUntil = this.toDatePickerDate(yesterday);

      // disables dates before the 'from' date
      // update this when the from date changes
      this.toDatePickerOptions.disableUntil = this.toDatePickerDate(this.timeFilter.from);
    }
  }

  onFromDateChanged(date: IMyDateModel): void {
    if (this.useUTCFormat) {
      this.timeFilter.to = new Date(
        this.timeFilter.to.getUTCFullYear(),
        this.timeFilter.to.getUTCMonth(),
        this.timeFilter.to.getUTCDate(),
      );
    }
    // update filter model's value
    this.timeFilter.from = date.singleDate.jsDate;
    this.valueChange.emit();

    // update toDatePicker's disableUntil
    // need to copy current options to detect change
    const copy = cloneDeep(this.defaultDatePickerOptions);
    copy.disableUntil = date.singleDate.date;
    if (this.restrictFromAfterTo) {
      this.toDatePickerOptions = {
        ...this.defaultDatePickerOptions,
        disableUntil: this.toDatePickerDate(moment(this.timeFilter.from).subtract(1, 'd').toDate()),
      };
    }
  }

  onToDateChanged(date: IMyDateModel): void {
    if (this.useUTCFormat) {
      this.timeFilter.from = new Date(
        this.timeFilter.from.getUTCFullYear(),
        this.timeFilter.from.getUTCMonth(),
        this.timeFilter.from.getUTCDate(),
      );
    }
    // update filter model's value
    this.timeFilter.to = date.singleDate.jsDate;
    this.valueChange.emit();

    // update fromDatePicker's disableSince
    // need to copy current options to detect change
    const copy = cloneDeep(this.defaultDatePickerOptions);
    copy.disableSince =
      this.maxFromDate && moment(date.singleDate.jsDate).isAfter(this.maxFromDate)
        ? this.toDatePickerDate(this.maxFromDate)
        : date.singleDate.date;
    this.fromDatePickerOptions = copy;
    if (this.restrictFromAfterTo) {
      this.fromDatePickerOptions = {
        ...this.fromDatePickerOptions,
        disableSince: this.toDatePickerDate(moment(this.timeFilter.to).add(1, 'd').toDate()),
      };
    }
  }

  private toDatePickerDate(date: Date): IMyDate {
    date = new Date(date);
    return {
      year: this.useUTCFormat ? date.getUTCFullYear() : date.getFullYear(),
      month: this.useUTCFormat ? date.getUTCMonth() + 1 : date.getMonth() + 1, // 0 indexed
      day: this.useUTCFormat ? date.getUTCDate() : date.getDate(),
    };
  }

  private toIMyDateModel(date: Date): IMyDateModel {
    return { isRange: false, singleDate: { date: this.toDatePickerDate(date) } };
  }
}

// Date picker used in stories and dashboard components, from now to now+10 dats
export class DatePicker {
  to: Date;
  from: Date;

  constructor(offset = 10, future = true) {
    if (future) {
      this.from = new Date();
      this.to = moment(this.from).add(offset, 'days').toDate();
    } else {
      this.to = new Date();
      this.from = moment(this.from).subtract(offset, 'days').toDate();
    }
  }

  // custom to JSON because by default Date objects get serialized to ISO UTC datetime strings
  // but, we want to preserve the local timezone of the user.
  toJSON(): { from: string; to: string } {
    return {
      to: moment(this.to).toISOString(true),
      from: moment(this.from).toISOString(true),
    };
  }

  onlyDateJSON(): { from: string; to: string } {
    return {
      to: `${moment(this.to).format('YYYY-MM-DD')}T00:00:00.000Z`,
      from: `${moment(this.from).format('YYYY-MM-DD')}T00:00:00.000Z`,
    };
  }
}
