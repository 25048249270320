import { CampaignsService } from './../../../@core/data/campaigns.service';
import { isEqual } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from './../../../@core/utils/country.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformsV2 } from './../../../enums/social.enum';
import { InfluencerMetadata } from './../../../@core/data/metadata.service';
import { InfluencerShippingDetails } from './../../../interfaces/proposal.interface';
import { ShippingDetailsService } from './../../../@core/data/shipping-details.service';
import { Component, Input, OnInit } from '@angular/core';
import utils from '../../../@core/utils/utils';

@Component({
  selector: 'ngx-meta-shipping-details',
  templateUrl: './meta-shipping-details.component.html',
  styleUrls: ['./meta-shipping-details.component.scss'],
})
export class MetaShippingDetailsComponent implements OnInit {
  loading: boolean;
  editingData = false;
  phoneUtil = PhoneNumberUtil.getInstance();
  shippingAddress: InfluencerShippingDetails;
  initialValues: any = {};

  @Input() influencerMetadata: InfluencerMetadata;
  @Input() influencerName: string;
  @Input() influencerUsername: string;
  @Input() platform: PlatformsV2;
  @Input() isExpanded = true;
  @Input() campaignSlugName?: string;
  shippingAddressId: string;
  countryCode: string;
  phoneCode: string;
  dialCode: string;
  defaultCountryCode: string;
  shippingAddressForm: FormGroup;
  countries: { value: string; name: string }[];
  phone: string;

  constructor(
    private shippingDetailsService: ShippingDetailsService,
    private campaignsService: CampaignsService,
    private countryService: CountryService,
    private toastService: ToastrService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.countries = this.countryService.countriesListWithCode();

    this.shippingAddressId = this.influencerMetadata?.metadataShippingId || '';
    if (this.shippingAddressId.length) {
      await this.shippingDetailsService
        .getInfluencerShippingDetails(this.shippingAddressId)
        .then((resShippingAddress) => {
          this.setShippingAddress(resShippingAddress);
        });
    } else {
      this.setShippingAddress();
    }
    this.shippingAddressForm = new FormGroup({
      firstName: new FormControl(this.shippingAddress.firstName, Validators.required),
      lastName: new FormControl(this.shippingAddress.lastName, Validators.required),
      email: new FormControl(this.shippingAddress.email, utils.emailValidator),
      line1: new FormControl(this.shippingAddress.address.line1, Validators.required),
      line2: new FormControl(this.shippingAddress.address.line2),
      state: new FormControl(this.shippingAddress.address.state, Validators.required),
      country: new FormControl(this.shippingAddress.address.country, Validators.required),
      city: new FormControl(this.shippingAddress.address.city, Validators.required),
      postalCode: new FormControl(this.shippingAddress.address.postalCode, Validators.required),
      phone: new FormControl({
        countryCode: this.countryCode,
        dialCode: this.dialCode,
        e164Number: this.dialCode + this.shippingAddress.phone,
        number: this.shippingAddress.phone,
      }), // TODO: handle full phone paste by separating dialcode from phone number
    });

    this.initialValues = this.shippingAddressForm.value;

    this.loading = false;
  }

  resetForm(): void {
    this.shippingAddressForm.setValue(this.initialValues);
  }

  get formControl() {
    return this.shippingAddressForm.controls;
  }

  isSubmitDisabled(): boolean {
    return (
      this.shippingAddressForm.invalid ||
      isEqual(
        { ...this.initialValues, phone: this.initialValues?.phone?.e164Number },
        { ...this.shippingAddressForm.value, phone: this.shippingAddressForm.value?.phone?.e164Number },
      )
    );
  }

  onSubmit(): void {
    this.loading = true;

    const shippingFormData: InfluencerShippingDetails = {
      firstName: this.shippingAddressForm.value.firstName,
      lastName: this.shippingAddressForm.value.lastName,
      email: this.shippingAddressForm.value.email,
      phone: this.shippingAddressForm.value.phone?.e164Number,
      address: {
        line1: this.shippingAddressForm.value.line1,
        line2: this.shippingAddressForm.value.line2,
        country: this.shippingAddressForm.value.country,
        city: this.shippingAddressForm.value.city,
        state: this.shippingAddressForm.value.state,
        postalCode: this.shippingAddressForm.value.postalCode,
      },
    };
    (this.campaignSlugName
      ? this.campaignsService.addInfluencerShippingDetails(
          // update shipping on campaign level
          this.campaignSlugName as string,
          shippingFormData,
          this.platform,
          this.influencerUsername,
        )
      : this.shippingDetailsService.addInfluencerShippingDetails(
          // update shipping on client-influencer level
          shippingFormData,
          this.platform,
          this.influencerUsername,
        )
    )
      .then((newShippingAddress) => {
        this.setShippingAddress(newShippingAddress);
        this.initialValues = this.shippingAddressForm.value;
        this.influencerMetadata.metadataShippingId = newShippingAddress._id;
        this.toastService.success("Successfully updated influencer's shipping detail!");
      })
      .catch((err) => {
        if (err.status === 422) {
          const msgs = err.error.message.split(',');
          msgs.forEach((msg) => {
            const splitMsg = msg.split('"');
            let errorField = splitMsg?.[1].split('.')?.[1];
            errorField = errorField?.[0]?.toUpperCase() + errorField?.slice(1);
            const errorMsg = splitMsg?.[2]?.trim();
            if (errorMsg === 'must be a valid email') {
              this.toastService.error('Invalid Email.Please submit a valid Email.');
            } else if (errorMsg === 'is required' || errorMsg === 'is not allowed to be empty') {
              this.toastService.error(`${errorField} is required`);
            }
          });
        } else this.toastService.error("Couldn't update influencer's shipping detail. Please Try Again!");
      })
      .finally(() => {
        this.loading = false;
      });
  }

  isFieldInValid(fieldName: string): boolean {
    return (
      (this.shippingAddressForm.controls[fieldName]?.status === 'INVALID' &&
        this.shippingAddressForm.get(fieldName)?.errors &&
        this.shippingAddressForm.get(fieldName)?.touched) ||
      false
    );
  }

  getFieldMessage(fieldName: string, fieldLabel: string): string {
    if (this.shippingAddressForm.get(fieldName)?.errors && this.shippingAddressForm.get(fieldName)?.touched) {
      if (this.shippingAddressForm.get(fieldName)?.errors?.['required']) {
        return `${fieldLabel} is required.`;
      }
    }
    return `${fieldLabel} is invalid.`;
  }

  getPhone(rawPhone: string): string {
    if (!rawPhone || !rawPhone?.length) {
      return '';
    }
    if (rawPhone[0] !== '+') {
      rawPhone = '+' + rawPhone;
    }
    const number = this.phoneUtil.parseAndKeepRawInput(rawPhone);
    const countryCodePrefix = '+' + number.getCountryCode();
    if (!rawPhone.startsWith(countryCodePrefix)) {
      return (number.getNationalNumber() ?? '').toString();
    }
    return rawPhone.slice(rawPhone.indexOf(countryCodePrefix) + countryCodePrefix?.length).trim();
  }

  setCountryCode(rawPhone: string): void {
    if (!rawPhone || !rawPhone?.length) {
      return;
    }
    if (rawPhone[0] !== '+') {
      rawPhone = '+' + rawPhone;
    }
    this.countryCode =
      this.phoneUtil.getRegionCodeForNumber(this.getPhoneFromRawPhone(rawPhone)) || this.defaultCountryCode;
    this.dialCode = '+' + this.phoneUtil.parse(rawPhone).getCountryCode();
  }

  getCountryCode(rawPhone: string): string {
    if (!rawPhone || !rawPhone?.length) {
      return this.defaultCountryCode;
    }

    if (rawPhone[0] !== '+') {
      rawPhone = '+' + rawPhone;
    }

    return this.phoneUtil.getRegionCodeForNumber(this.getPhoneFromRawPhone(rawPhone)) || this.defaultCountryCode;
  }

  getPhoneFromRawPhone(rawPhone: string): libphonenumber.PhoneNumber {
    return this.phoneUtil.parseAndKeepRawInput(rawPhone);
  }

  setShippingAddress(shippingData?: any): void {
    const [first, ...rest] = (this.influencerName || this.influencerUsername)
      ?.replace(/[^\p{L}\p{N}\p{P}\p{Z}\n]/gu, '')
      ?.trim()
      ?.split(/[^\p{L}\p{N}]/gu)
      ?.filter(Boolean);

    const firstName = shippingData?.firstName || this.influencerMetadata?.firstname || first || '';

    const lastName = shippingData?.lastName || this.influencerMetadata?.lastname || rest.pop() || '';

    const email = shippingData?.email || this.influencerMetadata?.email || '';

    const phone = shippingData?.phone || this.influencerMetadata?.phone || '';

    const country = shippingData?.country;

    this.defaultCountryCode = country || 'SG';
    this.setCountryCode(phone);

    const address = {
      line1: shippingData?.address?.line1 || '',
      line2: shippingData?.address?.line2 || '',
      country: shippingData?.address?.country || null,
      state: shippingData?.address?.state || '',
      city: shippingData?.address?.city || '',
      postalCode: shippingData?.address?.postalCode || '',
    };

    this.phone = this.getPhone(phone);

    this.phoneCode = this.getCountryCode(phone);

    this.shippingAddress = {
      firstName,
      lastName,
      email,
      phone: this.getPhone(phone),
      address,
    };

    this.shippingAddressId = shippingData?._id;
  }
}
