import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { AiTableData, AiTableOptions, ColumnCustomization, TableCustomization } from '../../common.interface';
import { merge } from 'lodash';

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
export class AiTableConfig<T> extends DataSource<T> implements AiTableData<T> {
  /** Stream of data that is provided to the table. */
  rows = new BehaviorSubject<T[]>(this.initialRows);
  table: Partial<TableCustomization>;
  selectionColumn: Partial<ColumnCustomization>;
  actionColumn: Partial<ColumnCustomization>;
  allSelected: boolean;

  get cols() {
    return this.table.columns
      ?.filter(({ hide, property }) => !hide && !['selected', 'actions'].includes(property))
      ?.sort((a, b) => a?.order - b?.order);
  }

  get shownCols() {
    return [
      ...(this.selectionColumn ? ['selected'] : []),
      ...this.cols.map(({ dataKey }) => dataKey),
      ...(this.actionColumn ? ['actions'] : []),
    ];
  }

  get customizabableCols() {
    return this.table.columns?.filter(({ property }) => !['selected', 'actions'].includes(property));
  }

  get allChecked() {
    return !!this.selection.length && this.isAllSelected();
  }

  get allIndeterminate() {
    return !!this.selection.length && !this.isAllSelected();
  }

  get selection() {
    return this.rows?.value?.filter((v) => v['selected']);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.length;
    const numRows = this.rows?.value?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(e) {
    e.stopPropagation();
    if (this.isAllSelected()) {
      this.allSelected = false;
      return this.replaceRows(this.rows.value.map((v) => ({ ...v, selected: false })));
    }

    this.allSelected = true;
    this.replaceRows(this.rows.value.map((v) => ({ ...v, selected: true })));
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<T[]> {
    return this.rows;
  }

  disconnect() {
    this.rows?.complete();
    this.rows?.unsubscribe();
  }

  setConfig(options: AiTableOptions) {
    const { commonTable = {}, customTable = {} } = options || {};
    this.table = merge(commonTable, customTable);
    if (this.table?.columns?.length) {
      this.setColumns(this.table?.columns);
    }
  }

  setColumns(columns: Array<Partial<ColumnCustomization>>) {
    this.selectionColumn = columns.find(({ property }) => property === 'selected');
    this.actionColumn = columns.find(({ property }) => property === 'actions');
    this.table.columns = columns;
  }

  replaceRows(newValue: T[]) {
    this.rows.next(newValue);
  }

  constructor(private initialRows: T[], private options?: AiTableOptions) {
    super();
    this.setConfig(this.options);
  }
}
