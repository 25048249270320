import { Component, Input, OnInit } from '@angular/core';
import { AiTableConfig } from '../helpers';
import { ModalService } from '../modal/modal.service';
import { TableCustomizerControl } from './table-customizer/table-customizer.control';

@Component({
  selector: 'ai-table',
  templateUrl: 'table.control.html',
  styleUrls: ['table.control.scss'],
})
export class TableControl implements OnInit {
  @Input() dataSource: AiTableConfig<unknown>;
  @Input() width = '100%';
  @Input() height = '500px';

  constructor(private aiModal: ModalService) {}

  ngOnInit() {}

  openCustomizer() {
    const { componentRef, overlayRef } = this.aiModal.open<TableCustomizerControl>(TableCustomizerControl, {
      hasBackdrop: true,
      width: 560,
      height: 462,
    });
    componentRef.instance.dataSource = this.dataSource;
    componentRef.instance.overlayRef = overlayRef;
  }
}
