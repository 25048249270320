import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  inMemoryStorage: { [key: string]: string } = {};
  length = 0;
  localStorageEnabled: boolean;
  readonly collaboratorAccessTokenKey: string = 'collaborator_access_token';

  constructor() {
    this.checkLocalStorage();
  }

  checkLocalStorage(): boolean {
    try {
      const testKey = 'test';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      this.localStorageEnabled = true;
      return true;
    } catch (e) {
      this.localStorageEnabled = false;
      return false;
    }
  }

  getItem(name: string): string | null {
    if (this.localStorageEnabled) {
      return localStorage.getItem(name);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (this.inMemoryStorage.hasOwnProperty(name)) {
      return this.inMemoryStorage[name];
    }
    return null;
  }

  setItem(name: string, value: string): void {
    if (this.localStorageEnabled) {
      localStorage.setItem(name, value);
    } else {
      this.inMemoryStorage[name] = String(value);
    }
  }

  removeItem(name: string): void {
    if (this.localStorageEnabled) {
      localStorage.removeItem(name);
    } else {
      delete this.inMemoryStorage[name];
    }
  }

  removeCollaboratorItems(): void {
    if (this.localStorageEnabled) {
      const keys = [this.collaboratorAccessTokenKey, 'collaborator_token_expires_at'];
      keys.forEach((key: string) => localStorage.removeItem(key));
    } else {
      this.inMemoryStorage = {};
    }
  }

  removeAllItems(): void {
    if (this.localStorageEnabled) {
      const keys = [
        'access_token',
        'id_token',
        'expires_at',
        'refresh_token',
        'organization_settings',
        'organization_id',
      ];
      keys.forEach((key: string) => localStorage.removeItem(key));
    } else {
      this.inMemoryStorage = {};
    }
  }
}

export const localStorageService = new LocalStorageService();
