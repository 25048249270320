import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, ValidationErrors, Validators } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../@core/data/user.service';
import { CategoryService } from '../../../@core/data/category.service';
import { EcommerceService, PricingRuleValueType } from '../../../@core/data/ecommerce.service';
import { InfluencerMetadata } from '../../../@core/data/metadata.service';
import { Platforms, Status } from '../../../enums';
import {
  AffiliateCodeExistsEnum,
  CampaignWorkflow,
  ExternalLink,
  InfluencersCommission,
  PartnerizeCampaign,
  PartnerizeCampaignPublisher,
  PricingRule,
  BasicInfluencerInfo,
} from '../../../interfaces';
import { Router } from '@angular/router';
import { MessageModalComponent } from '../../message-modal/message-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import utils from '../../../@core/utils/utils';
import { Observable, Subject, defer } from 'rxjs';
import { isEmpty, isNumber, isNil } from 'lodash';
import { FixedPayService } from '../../../@core/data/campaigns/fixed-pay.service';
import { CountryService } from '../../../@core/utils/country.service';

@Component({
  selector: 'ngx-influencer-metadata',
  templateUrl: './influencer-metadata.component.html',
  styleUrls: ['./influencer-metadata.component.scss'],
})
export class InfluencerMetadataComponent implements OnInit {
  @Input() set metadata(value: InfluencerMetadata) {
    if (!this.intiallyLoaded) {
      this.loading = true;
      this.influencerMetadata = value;
      this.ratingForm.patchValue({
        rating: this.influencerMetadata.rating,
      });
      this.phone = this.getPhone(true);
      this.email = this.influencerMetadata.email;
      this.firstname = this.influencerMetadata.firstname ?? '';
      this.lastname = this.influencerMetadata.lastname ?? '';
      this.status = this.influencerMetadata.status ?? '';
      this.affiliateCode = this.influencerMetadata?.affiliateCode?.code ?? '';
      this.paypalId.patchValue(this.influencerMetadata.paypalId);
      this.fixedPayValue.patchValue(this.influencerMetadata.fixedPay?.value ?? null);

      this.categoryService.fetchClientUserCountryCode().then((code) => {
        this.defaultCountryCode = code || 'SG';
        this.countryCode = this.getCountryCode();
        this.loading = false;
      });
    }
    this.intiallyLoaded = true;
  }

  @Input() set publisher(value: ExternalLink) {
    this.partnerizeCampaignPublisherId = value?.id ?? null;
    this.publisherEditable = !this.partnerizeCampaignPublisherId;
  }

  @Input() set cancelMetaDataChanges(value: boolean) {
    if (value) {
      this.cancelChanges();
    }
  }

  @Input() set pricingRuleEnabled(value: boolean) {
    this.disableGeneratingDiscountCode = !!value;
  }

  @Input() partnerizeCampaign: PartnerizeCampaign;
  @Input() publishers: PartnerizeCampaignPublisher[];
  @Input() campaignWorkflow: CampaignWorkflow;
  @Input() campaignSlug?: string;
  @Input() platform: Platforms;
  @Input() basicInfo: BasicInfluencerInfo;

  @Output() editedMetadataEmitter = new EventEmitter();
  @Output() editedPartnerizeMetadataEmitter = new EventEmitter();

  @ViewChild('pricingRuleModal') pricingRuleModal: TemplateRef<unknown>;
  @ViewChild('phoneNumber') phoneNumberModel: NgModel;

  loading = true;
  intiallyLoaded = false;
  influencerMetadata: InfluencerMetadata;

  paypalId: FormControl = new FormControl('');
  fixedPayValue = new FormControl(null, Validators.min(0));
  affiliateCode: string;
  ratingForm: FormGroup;

  phone: string;
  countryCode: string;
  email: string;
  firstname: string;
  lastname: string;
  rating: number;
  status: string;
  defaultCountryCode: string;
  statusArray = Object.values(Status);

  fixedPayCurrency: string;
  affiliatePay: FormGroup;
  editingEmail = false;
  editingFixedPayValue = false;
  editingAffiliatePayValue = false;
  editingPaypal = false;
  editingFirstname = false;
  editingLastname = false;
  editingRating = false;
  editingPhone = false;
  editingStatus = false;
  publisherEditable: boolean;
  disableGeneratingDiscountCode: boolean;

  phoneUtil = PhoneNumberUtil.getInstance();
  partnerizeCampaignPublisherId: string;
  affiliateCodeDisplayValue = '--';

  pricingRuleForm: FormGroup = new FormGroup({
    pricingRuleId: new FormControl(null),
  });
  pricingRulesList$: Observable<PricingRule[]>;
  pricingRuleInput$ = new Subject<string>();
  loadingPricingRulesList = false;
  defaultPricingRulesList: PricingRule[];
  fixedPayPaidAmount: number;
  fallbackMetadataName: {
    firstname;
    lastname;
  };

  isAffiliateAllowed: boolean;

  constructor(
    private categoryService: CategoryService,
    private ecommerceService: EcommerceService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private user: UserService,
    private router: Router,
    private fixedPayService: FixedPayService,
    private modalService: NgbModal,
    private countryService: CountryService,
  ) {
    this.ratingForm = this.fb.group({
      rating: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.setupFirstAndLastName(); // constructs names based on metadata and basic info
    this.setToFallBackEmailValue(); // sets email based on metadata and basic info
    this.setBasicInfoPhone(); //  format basic info phone if it is not up to standard
    this.setPhone(); // sets phone based on metadata and basic info
    // populate fixed pay and affiliate pay only when invoked from campaigns
    if (this.campaignSlug) {
      this.createAffiliateForm();
      this.setAffiliateAvailability();
      this.fixedPayCurrency = await this.user.getLinkedCurrency();
      this.fixedPayValue.patchValue(this.influencerMetadata?.fixedPay?.value ?? null);
      this.affiliatePay.patchValue(this.getInfluencerCommissionForm);
      this.affiliateCodeDisplayValue = this.affiliateCode;

      this.defaultPricingRulesList = await this.fetchPricingRules();
      this.pricingRulesList$ = utils.buildAutocompleteObservable(
        this.pricingRuleInput$,
        (term: string) => this.convertPricingRulePromise(term),
        () => (this.loadingPricingRulesList = true),
        () => (this.loadingPricingRulesList = false),
        this.defaultPricingRulesList,
      );
    }
  }

  setupFirstAndLastName(): void {
    const [first, ...rest] = (this.basicInfo.name || this.basicInfo.username)
      ?.replace(/[^\p{L}\p{N}\p{P}\p{Z}\n]/gu, '')
      ?.trim()
      ?.split(/[^\p{L}\p{N}]/gu)
      ?.filter(Boolean);
    this.firstname = this.influencerMetadata.firstname || first || '';
    this.lastname = this.influencerMetadata.lastname || rest.pop() || '';
    if (!isNil(this.influencerMetadata.lastname)) {
      this.lastname = this.influencerMetadata.lastname;
    }
    this.fallbackMetadataName = {
      firstname: this.firstname,
      lastname: this.lastname,
    };
  }

  async setAffiliateAvailability(): Promise<void> {
    this.user.getClientUser().then((client) => (this.isAffiliateAllowed = !!client.integrations?.eCommerceStore));
  }

  async fetchPricingRules(term?: string): Promise<PricingRule[]> {
    return this.ecommerceService
      .getStoreShop()
      .then(() => {
        return this.ecommerceService.getPricingRulesList(this.platform, term);
      })
      .catch(() => {
        return [];
      });
  }

  convertPricingRulePromise(term?: string): Observable<PricingRule[]> {
    return defer(() => this.fetchPricingRules(term));
  }

  getPriceRuleDescription(pricingRule: PricingRule): string {
    let description = '';
    if (pricingRule.value_type === PricingRuleValueType.percentage) {
      description = pricingRule.value + '% OFF';
    } else if (pricingRule.value_type === PricingRuleValueType.fixed) {
      description = 'Flat ' + pricingRule.value + ' OFF';
    }

    description += ' on products';
    return description;
  }

  createAffiliateForm(): void {
    this.affiliatePay = this.fb.group(
      {
        commissionType: new FormControl('PERCENTAGE'),
        fixedCommissionValue: new FormControl(null),
        percentageCommissionValue: new FormControl(null),
        currency: new FormControl(null),
      },
      { validators: this.validateAffiliatePayForm },
    );
  }

  get isProfilePage(): boolean {
    return this.router.url.includes('profile');
  }

  get getInfluencerCommissionForm(): InfluencerCommissionForm {
    let influencerCommission: InfluencersCommission = {
      commissionType: 'PERCENTAGE',
      commissionValue: 0,
      currency: this.fixedPayCurrency,
    };
    if (
      this.influencerMetadata?.influencersCommission?.commissionType &&
      isNumber(this.influencerMetadata?.influencersCommission?.commissionValue)
    ) {
      influencerCommission = this.influencerMetadata?.influencersCommission;
    } else if (
      this.campaignWorkflow?.storeConfigurations?.pricingRule?.enabled &&
      this.campaignWorkflow?.influencersCommission?.commissionType &&
      isNumber(this.campaignWorkflow?.influencersCommission?.commissionValue)
    ) {
      influencerCommission = this.campaignWorkflow?.influencersCommission;
    }
    const { commissionType, commissionValue, currency } = influencerCommission;
    return {
      commissionType,
      fixedCommissionValue: commissionType === 'FIXED' ? commissionValue : null,
      percentageCommissionValue: commissionType === 'PERCENTAGE' ? commissionValue : null,
      currency: currency || this.fixedPayCurrency,
    };
  }

  get getInfluencerCommissionDisplayValue(): string {
    const { commissionType, fixedCommissionValue, percentageCommissionValue, currency } =
      this.getInfluencerCommissionForm;
    return commissionType === 'PERCENTAGE' && percentageCommissionValue
      ? `${percentageCommissionValue}% per sale`
      : commissionType === 'FIXED'
      ? `${currency} ${fixedCommissionValue} per sale`
      : '--';
  }

  get isAffiliateCodeDisabled(): boolean {
    return !(
      this.influencerMetadata?.influencersCommission?.commissionType ||
      this.campaignWorkflow?.influencersCommission?.commissionType ||
      this.affiliatePay.valid
    );
  }

  validateAffiliatePayForm(form: FormGroup): ValidationErrors | null {
    const commissionType = form.get('commissionType')?.value;
    const fixedCommissionValue = form.get('fixedCommissionValue')?.value;
    const percentageCommissionValue = form.get('percentageCommissionValue')?.value;

    const invalid =
      commissionType === 'PERCENTAGE'
        ? percentageCommissionValue < 0 || percentageCommissionValue > 100
        : commissionType === 'FIXED'
        ? fixedCommissionValue < 0
        : false;
    return invalid ? { invalid } : null;
  }

  getPhone(onlyUseMetadataPhone = false): string {
    const phone = onlyUseMetadataPhone ? this.influencerMetadata?.phone : this.getPhoneFromInfluencer();
    if (!phone) {
      return '';
    }
    let number;
    try {
      number = this.phoneUtil.parseAndKeepRawInput(phone);
    } catch (err) {
      return '';
    }
    const countryCodePrefix = '+' + number.getCountryCode();
    if (!phone.startsWith(countryCodePrefix)) {
      return number.getNationalNumber()?.toString() ?? '';
    }
    return phone.slice(phone.indexOf(countryCodePrefix) + countryCodePrefix?.length).trim();
  }

  getCountryCode(): string {
    const phone = this.getPhoneFromInfluencer();
    if (!phone) {
      return this.defaultCountryCode;
    }
    let number;
    try {
      number = this.phoneUtil.parseAndKeepRawInput(phone);
    } catch (err) {
      return this.defaultCountryCode;
    }
    return this.phoneUtil.getRegionCodeForNumber(number) ?? '';
  }

  getPhoneFromInfluencer(): string {
    return this.campaignSlug
      ? this.influencerMetadata.phone
      : (this.influencerMetadata.phone?.length ? this.influencerMetadata.phone : this.basicInfo?.phone) || '';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setPhone(phone?): void {
    if (this.editingPhone) {
      this.phone = phone && phone.number;
      this.countryCode = phone && phone.countryCode;
    } else {
      this.phone = this.getPhone();
      this.countryCode = this.getCountryCode();
    }
  }

  private getInfluencerCountryCodes(): string[] {
    if (this.basicInfo?.locations?.length) {
      return this.basicInfo?.locations?.map((country) => this.countryService.countryCode(country));
    }
    return [];
  }

  resetPhone(): void {
    this.editingPhone = false;
    this.setPhone();
  }

  setBasicInfoPhone(): void {
    const phone = this.basicInfo?.phone;

    if (phone?.length && !phone.startsWith('+')) {
      // try to fix the phone number by comparing into influencer locations
      for (const locationCode of this.getInfluencerCountryCodes()) {
        if (phone.startsWith(this.phoneUtil.getCountryCodeForRegion(locationCode)?.toString())) {
          this.basicInfo.phone = `+${phone}`;
          return;
        }
      }
    }

    this.basicInfo.phone = phone;
  }

  get isPhoneValid(): boolean {
    return this.phoneNumberModel?.valid || this.phoneNumberModel?.status !== 'INVALID';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updatePhone(phone?): void {
    this.editingPhone = false;
    const phoneNumber = phone?.internationalNumber ?? '';
    this.updateMetadata({ phone: phoneNumber });
  }

  updateEmail(): void {
    this.editingEmail = false;
    this.updateMetadata({ email: this.email });
  }

  updatePaypalId(): void {
    this.editingPaypal = false;
    this.updateMetadata({ paypalId: this.paypalId.value });
  }

  async enableEditingFixPay(): Promise<void> {
    this.editingFixedPayValue = true;
    this.fixedPayPaidAmount = isNaN(this.fixedPayPaidAmount)
      ? ((this.campaignSlug &&
          (
            await this.fixedPayService.getFixedPaySummary(this.campaignSlug, this.platform, {
              usernames: [this.basicInfo.username],
            })
          ).paid) as number) || 0
      : this.fixedPayPaidAmount;
  }

  updateFixedPayValue(): void {
    this.editingFixedPayValue = false;
    if (this.influencerMetadata.fixedPay === this.fixedPayValue.value || this.fixedPayValue.invalid) return;
    this.updateMetadata({ fixedPay: { value: this.fixedPayValue.value } });
  }

  updateFirstname(): void {
    this.editingFirstname = false;
    if (this.influencerMetadata.firstname === this.firstname) return;
    if (!this.firstname?.trim()) {
      this.cancelFirstnameChanges();
      return;
    }
    this.updateMetadata({ firstname: this.firstname?.trim() });
  }

  cancelFirstnameChanges(): void {
    this.firstname = this.fallbackMetadataName.firstname || '';
    this.editingFirstname = false;
  }

  updateLastname(): void {
    this.editingLastname = false;
    if (this.influencerMetadata.lastname === this.lastname) return;
    if (this.lastname && !this.lastname?.trim()) {
      this.cancelLastnameChanges();
      return;
    }
    this.updateMetadata({ lastname: this.lastname?.trim() });
  }

  cancelLastnameChanges(): void {
    this.lastname = this.fallbackMetadataName.lastname || '';
    this.editingLastname = false;
  }

  updateRating(): void {
    this.editingRating = false;
    if (this.influencerMetadata.rating === this.ratingForm.get('rating')?.value) return;
    this.updateMetadata({ rating: this.ratingForm.get('rating')?.value });
  }

  cancelRating(): void {
    this.ratingForm.patchValue({ rating: this.influencerMetadata.rating });
  }

  updatePublisher(): void {
    if (this.partnerizeCampaignPublisherId) {
      const partnerizeCampaignPublisher = this.publishers.find(
        (publisher) => publisher.publisherId === this.partnerizeCampaignPublisherId,
      );
      this.influencerMetadata.partnerizePublisherId = partnerizeCampaignPublisher?.publisherId;
      this.influencerMetadata.partnerizePublisherName = partnerizeCampaignPublisher?.publisherName;
      this.updatePartnerizeMetaData();
    }
  }

  updateStatus(): void {
    this.editingStatus = false;
    if (this.influencerMetadata.status === this.status) return;
    this.updateMetadata({ status: this.status });
  }

  updatePartnerizeMetaData(): void {
    this.editedPartnerizeMetadataEmitter.emit(this.influencerMetadata);
  }

  updateMetadata(influencerMetadata: Partial<InfluencerMetadata> = this.influencerMetadata): void {
    this.editedMetadataEmitter.emit(influencerMetadata);
    this.influencerMetadata = { ...this.influencerMetadata, ...influencerMetadata };
    this.updateFallbackMetadataName(influencerMetadata);
    if (!this.campaignSlug) {
      if (influencerMetadata.email?.length === 0) {
        this.setToFallBackEmailValue();
      }
      if (influencerMetadata.phone?.length === 0) {
        this.setPhone();
      }
    }
  }

  updateFallbackMetadataName(influencerMetadata: Partial<InfluencerMetadata>): void {
    if (influencerMetadata?.firstname) {
      this.fallbackMetadataName.firstname = influencerMetadata?.firstname;
    }
    if (!isNil(influencerMetadata?.lastname)) {
      this.fallbackMetadataName.lastname = influencerMetadata?.lastname;
    }
  }

  setToFallBackEmailValue(): void {
    this.email = this.campaignSlug
      ? this.influencerMetadata.email
      : (this.influencerMetadata.email?.length ? this.influencerMetadata.email : this.basicInfo?.email) || '';
  }

  cancelChanges(): void {
    this.ratingForm.patchValue({ rating: this.influencerMetadata.rating });
    this.paypalId.patchValue(this.influencerMetadata.paypalId);
    this.affiliateCode = this.influencerMetadata?.affiliateCode?.code ?? '';
    this.affiliateCodeDisplayValue = this.influencerMetadata?.affiliateCode?.code ?? '--';
    this.editingPhone = false;
    this.editingRating = false;
    this.editingEmail = false;
    this.editingFixedPayValue = false;
    this.editingPaypal = false;
    this.editingAffiliatePayValue = false;
    this.setToFallBackEmailValue();
    this.setPhone();
  }

  async updateAffiliateCodeAndCommission(): Promise<void> {
    this.editingAffiliatePayValue = true;
    if (this.affiliatePay.invalid || !this.campaignSlug?.trim()?.length) return;
    let isAffiliateCodeChanged = false;
    let selectedPricingRuleId;
    // Check if affiliate code is available and/or used in another campaign
    if (this.affiliateCode?.trim()?.length && this.influencerMetadata?.affiliateCode?.code !== this.affiliateCode) {
      const validCodeRegex = /^[a-zA-Z0-9-._]{4,30}$/;
      const isValidCode = validCodeRegex.test(this.affiliateCode?.trim());
      if (!isValidCode) {
        this.toastrService.error(
          `Alphanumeric letters within 4 to 30 character length with optional special characters ". _ -" allowed`,
        );
        return;
      }
      isAffiliateCodeChanged = true;
      const { exists, records, pricingRuleId } = await this.ecommerceService.checkIsAffiliateCodeAvailable(
        this.affiliateCode.trim().toUpperCase(),
        this.platform,
        this.campaignSlug,
      );

      if (exists === AffiliateCodeExistsEnum.CAMPAIGN && records?.length) {
        const { influencer, campaignName } = records[0];
        const confirmed = await this.openConfirmationModal(this.affiliateCode, influencer?.username, campaignName);
        if (!confirmed) {
          this.affiliateCode = this.influencerMetadata?.affiliateCode?.code ?? '';
          return;
        }
      } else if (exists === AffiliateCodeExistsEnum.NONE) {
        if (isEmpty(pricingRuleId)) {
          selectedPricingRuleId = await this.openPricingRuleModal();
        } else {
          selectedPricingRuleId = pricingRuleId;
        }
      }
    }
    const { commissionType, currency, percentageCommissionValue, fixedCommissionValue } = this.affiliatePay.value;
    this.influencerMetadata.influencersCommission = {
      currency,
      commissionType,
      commissionValue: commissionType === 'PERCENTAGE' ? percentageCommissionValue : fixedCommissionValue,
    };
    try {
      const affiliateCodeAndComission = await this.ecommerceService.updateAffiliateCodeAndCommission(
        this.basicInfo.username,
        this.campaignSlug ?? '', // campaignSlug can never be null here, this is done just to mitigate linting error
        this.affiliateCode?.toUpperCase(),
        this.platform ?? Platforms.instagram,
        this.influencerMetadata.influencersCommission,
        selectedPricingRuleId,
      );

      this.affiliateCode = affiliateCodeAndComission?.affiliateCode?.code;
      this.affiliateCodeDisplayValue = this.affiliateCode;
      this.updateMetadata({
        affiliateCode: affiliateCodeAndComission?.affiliateCode ?? null,
        influencersCommission: affiliateCodeAndComission?.influencersCommission,
      });
      if (isAffiliateCodeChanged) {
        this.toastrService.success(`Affiliate code created successfully.`);
      }
    } catch (error) {
      this.toastrService.error(error.error?.message);
    } finally {
      this.editingAffiliatePayValue = false;
    }
  }

  handleCommissionTypeChange(event: Event): void {
    if (event.target?.['defaultValue'] === 'FIXED') {
      this.affiliatePay.patchValue({ percentageCommissionValue: null });
    } else if (event.target?.['defaultValue'] === 'PERCENTAGE') {
      this.affiliatePay.patchValue({ fixedCommissionValue: null });
    }
  }

  cancelAffiliatePayChanges(): void {
    this.editingAffiliatePayValue = false;
    this.affiliatePay.patchValue(this.getInfluencerCommissionForm);
    this.affiliateCode = this.influencerMetadata?.affiliateCode?.code ?? '';
    this.affiliateCodeDisplayValue = this.influencerMetadata?.affiliateCode?.code ?? '--';
  }

  async openConfirmationModal(affiliateCode: string, username: string, campaignName: string): Promise<boolean> {
    const confirmModal = this.modalService.open(MessageModalComponent, {
      centered: true,
      backdrop: 'static',
    });

    const modalContent = confirmModal.componentInstance as MessageModalComponent;
    modalContent.headerText = 'Confirm';
    modalContent.contentText = `The affiliate code <b>${affiliateCode}</b> is already used for influencer <b>${username}</b> in campaign <b>${campaignName}</b>.<br>Do you want to use the same code for selected influencer instead?<br>Henceforth, affiliate sales will be calculated for the code in this campaign`;
    modalContent.buttonText = 'Confirm';
    modalContent.buttonClass = 'primary';
    modalContent.cancelButton = true;
    return await confirmModal.result;
  }

  async openPricingRuleModal(): Promise<string> {
    const pricingRuleModal = this.modalService.open(this.pricingRuleModal, {
      windowClass: '',
      centered: true,
      backdrop: 'static',
    });
    await pricingRuleModal.result;
    return this.pricingRuleForm.value.pricingRuleId;
  }
}

interface InfluencerCommissionForm {
  commissionType: 'PERCENTAGE' | 'FIXED';
  fixedCommissionValue: number | null;
  percentageCommissionValue: number | null;
  currency: string;
}
