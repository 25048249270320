<div #trigger class="content-container" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave($event)">
  <ng-content></ng-content>
</div>
<ng-template #tooltipTemplate>
  <div #tooltipContainer class="tooltip-container"
    [ngStyle]="{'padding-left.px': offsetX,'padding-right.px':offsetX,'padding-top.px':offsetY,'padding-bottom.px':offsetY}"
    (mouseleave)="onMouseLeave($event)">
    <div class="tooltip-content">
      <div *ngFor="let tooltip of tooltips" [innerHTML]="tooltip">
      </div>
      <div *ngIf="displayArrow" [class]="'arrow '+placement"></div>
    </div>
  </div>
</ng-template>