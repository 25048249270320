import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../@core/data/user.service';
import { SubscribedClientUser } from '../../enums';
import { InfluencerSavedSearchFilters, SearchFiltersPrivacy } from '../../interfaces/influencer-filters.interface';
import { AuthService } from '../../@core/auth/auth.service';
import utils from '../../@core/utils/utils';

@Component({
  selector: 'ngx-load-filter-modal',
  templateUrl: './load-filter-modal.component.html',
  styleUrls: ['./load-filter-modal.component.scss'],
})
export class LoadFilterModalComponent implements OnInit {
  @Input() savedSearchFilters: InfluencerSavedSearchFilters[];
  selectedPrivacyFilter: 'shared' | 'private' = 'shared';
  searchFilters: InfluencerSavedSearchFilters[];
  searching = false;
  filterTerm: string;
  selectedFilter: InfluencerSavedSearchFilters;
  user: SubscribedClientUser;
  organizationId: string;
  popperModifiers: any = {
    flip: { enabled: false },
  };
  contextMenu;
  contextMenuOptions = [
    {
      title: 'Make this the default',
      onClick: () => this.updateDefaultSearch(true),
      visible: true,
      enabledForAll: true,
    },
    {
      title: 'Remove from default',
      onClick: () => this.updateDefaultSearch(false),
      visible: true,
      enabledForAll: true,
    },
    {
      title: 'Make it as private',
      onClick: () => this.setSearchPrivacy('private'),
      visible: true,
      enabledForAll: false,
    },
    { title: 'Make it as shared', onClick: () => this.setSearchPrivacy('org'), visible: true, enabledForAll: false },
    { title: 'Edit Search', onClick: () => this.editSearch(), visible: true, enabledForAll: false },
    { title: 'Delete Search', onClick: () => this.deleteSearch(), visible: true, enabledForAll: false },
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.contextMenu = this.contextMenuOptions;
    this.searchFilters = this.savedSearchFilters;
    this.user = await this.userService.getClientUser();
    this.organizationId = this.authService.getOrganizationId();
    this.selectedPrivacyFilter = this.getFiltersCount('shared') > 0 ? 'shared' : 'private';
  }

  getFilterList(filterPrivacy) {
    return this.searchFilters.filter((s) => s.filterPrivacy === filterPrivacy);
  }

  getUserPicture(filter: InfluencerSavedSearchFilters) {
    const affableIcon = 'assets/images/affable-logo-new.png';
    return filter.filterPrivacy === 'global'
      ? affableIcon
      : filter.clientId === this.user?.id
      ? this.user.picture
      : null;
  }

  filterSavedSearch(term: string) {
    this.searchFilters = this.savedSearchFilters.filter((s) => {
      if (s.filterName.toLowerCase().includes(term.trim().toLowerCase())) {
        return s;
      }
    });
    this.searching = !!this.filterTerm;
    this.changeDetectorRef.detectChanges();
  }

  switchTab(privacy: 'shared' | 'private') {
    this.selectedPrivacyFilter = privacy;
  }

  clearFilters(): void {
    this.searching = false;
    this.filterTerm = '';
    this.searchFilters = this.savedSearchFilters;
  }

  getFiltersCount(privacy?: 'shared' | 'private') {
    return this.savedSearchFilters.filter((s) => {
      if (privacy === 'private') {
        return s.filterPrivacy === 'private';
      } else if (privacy === 'shared') {
        return s.filterPrivacy === 'org' || s.filterPrivacy === 'global';
      }
      return true;
    }).length;
  }

  onContextMenuDisplay($event, filter: InfluencerSavedSearchFilters) {
    this.selectedFilter = filter;
    const accountId = utils.getAccountIdFromClientUser(this.user);
    if (this.selectedFilter.clientId !== accountId) {
      this.contextMenu = this.contextMenuOptions.filter(
        (menu) =>
          menu.enabledForAll && menu.title !== (filter.isDefault ? 'Make this the default' : 'Remove from default'),
      );
    } else {
      this.contextMenu = this.contextMenuOptions.filter(
        (menu) =>
          menu.title !== (filter.filterPrivacy === 'private' ? 'Make it as private' : 'Make it as shared') &&
          menu.title !== (filter.isDefault ? 'Make this the default' : 'Remove from default'),
      );
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  loadFilter(filter: InfluencerSavedSearchFilters) {
    this.activeModal.close({ action: 'load', filter: filter });
  }

  updateDefaultSearch(isDefault: boolean) {
    this.selectedFilter.isDefault = isDefault;
    this.activeModal.close({ action: 'default', filter: this.selectedFilter });
  }

  setSearchPrivacy(filterPrivacy: SearchFiltersPrivacy) {
    this.selectedFilter.filterPrivacy = filterPrivacy;
    this.activeModal.close({ action: 'privacy', filter: this.selectedFilter });
  }

  editSearch(): void {
    this.activeModal.close({ action: 'edit', filter: this.selectedFilter });
  }

  deleteSearch(): void {
    this.activeModal.close({ action: 'delete', filter: this.selectedFilter });
  }
}
