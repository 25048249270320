import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TiktokContentService } from '../../../@core/data/tiktok/tiktok-content.sevice';
import utils from '../../../@core/utils/utils';
import { OembedTiktokContent, TiktokVideo } from '../../../interfaces';

@Component({
  selector: 'ngx-tiktok-video-modal',
  templateUrl: './tiktok-video-modal.component.html',
  styleUrls: ['./tiktok-video-modal.component.scss'],
})
export class TiktokVideoModalComponent implements OnInit {
  @Input() video: TiktokVideo;

  postingTime: string;
  videoURL: string;
  videoExternalURL: string;
  loadingVideoURL = true;
  loadError = false;
  @Input() currency: string;
  @Input() hideStats = false;

  constructor(private activeModal: NgbActiveModal, private tiktokContentService: TiktokContentService) {}

  ngOnInit(): void {
    // this.tiktokContentService.getTiktokVideoURL(this.video.id).then(response => {
    //   this.videoURL = response.urls;
    // this.loadingVideoURL = false;
    // }).catch(() => {
    //   this.loadError = true;
    //   this.loadingVideoURL = false;
    // });
    this.postingTime = moment(this.video.createdAt).fromNow();
    this.videoExternalURL = 'https://www.tiktok.com/@' + this.video.author.username + '/video/' + this.video.id;
  }

  closeModal(): void {
    this.activeModal.close();
  }

  handleProfileCoverImgError(error) {
    const element = error.target;
    const newUrl = utils.changeTiktokMediaHost(element.currentSrc);
    if (newUrl !== element.currentSrc) {
      this.video.author.profilePictureUrl = newUrl;
    }
  }

  async handleCoverImgError(error) {
    try {
      const element = error.target;
      const contentImage: OembedTiktokContent = await this.tiktokContentService.getContentImage(
        this.video.id,
        this.video.author.username,
      );
      if (contentImage.thumbnail_url && contentImage.thumbnail_url !== element.currentSrc) {
        element.src = contentImage.thumbnail_url;
        return;
      } else {
        // If OembedTiktokContent image has error in loading then hide image
        this.loadingVideoURL = false;
      }
    } catch (error) {
      this.video.missing = true;
    }
  }
}
