import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { chain, map } from 'lodash';
import { environment } from '../../../environments/environment';
import { InstagramContentSortKey, MediaType, PlatformsV2, PlatformsUpperEnum } from '../../enums';
import { BrandLookup } from '../../interfaces';
import { InstagramContentFilters } from './content-filter.service';
import { Location } from './location.service';

@Injectable()
export class ContentService {
  constructor(private http: HttpClient) {}

  searchContent(filters: InstagramContentFilters, page: number, allContent = false): Promise<ContentAPIResponse> {
    return this.http
      .post<ContentAPIResponse>(
        `${environment.api}/api/content/`,
        chain(filters)
          .clone()
          .update('brands', (brands: BrandLookup[]) =>
            map(brands, (brand) => {
              return {
                name: brand.username,
                isIncluded: brand.isIncluded,
              };
            }),
          )
          .update('locations', (locations: Location[]) =>
            locations.map((location) => ({
              name: location.name,
              isIncluded: location.isIncluded,
            })),
          ),
        {
          params: {
            page: page.toString(),
            allContent: allContent.toString(),
          },
        },
      )
      .toPromise();
  }

  getTopContent(location: string, sortKey: InstagramContentSortKey): Promise<ContentAPIResponse> {
    return this.http
      .get<ContentAPIResponse>(`${environment.api}/api/content/top`, {
        params: {
          location,
          sortKey,
          timezone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
        },
      })
      .toPromise();
  }

  getContent(contentPk: number, inCampaign: boolean): Promise<Content> {
    return this.http
      .get<Content>(`${environment.api}/api/content/${contentPk}?page=${inCampaign ? 'campaign' : 'content'}`)
      .toPromise();
  }

  getContentsDetails(
    contentPks: string[],
    platform: PlatformsUpperEnum = PlatformsUpperEnum.INSTAGRAM,
  ): Promise<any[]> {
    // * Returned contents types depends on platform
    return this.http.post<any>(`${environment.api}/api/content/details`, { contentPks, platform }).toPromise();
  }

  exportMediaReport(contentPks: string[], platform: PlatformsV2, campaignSlugName?: string): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/content/export/search`,
        {
          contentPks: contentPks,
          campaignSlugName: campaignSlugName,
          platform: platform,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }
}

export class RecommendationList {
  searchTerm: string;
  recommendations: string[];
  needRecommendations: boolean;
}

export class ContentAPIResponse {
  content: PartialContent[];
  total: number;
  recommendation: RecommendationList[];
}

export class PartialContent {
  pk: string;
  likeCount: number;
  caption: string;
  commentCount: number;
  viewCount?: number;
  instagramUrl: string;
  author: ContentAuthor;
  takenAtTimestamp?: number;
  media: {
    urlMedium: string;
    urlLarge: string;
    urlThumbnail: string;
  };
  estimatedMediaValue?: number;
  estimatedReach?: number;
  currency?: string;
  estimatedImpressions?: number;
  selected: boolean;
  areInsightsVerified?: boolean;
  playCount?: number;
}

export class Content extends PartialContent {
  brands: string[];
  influencers: string[];
  sponsored: boolean;
  takenAt: string;
  mediaType: MediaType;
}

export class ContentAuthor {
  username: string;
  fullName: string;
  picture: string;
  isProfiled = false;
}
