<div class="form-group">
    <label class="filter-label">Countries</label>
    <div class="input-group input-group-sm">
        <ng-select placeholder="Pick country" bindValue="label" dropdownPosition="bottom" [items]="allCountries"
            (change)="triggerUpdateEvent()" [multiple]="true" [hideSelected]="true" [virtualScroll]="true"
            [loading]="loading" [markFirst]="false" [(ngModel)]="selectedCountries">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <span [ngOptionHighlight]="search">{{item}}</span><br>
            </ng-template>
        </ng-select>
    </div>
</div>