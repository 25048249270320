import { Component, Input } from '@angular/core';
import { PlatformsV2, VeraPlatforms } from '../../enums';

@Component({
  selector: 'ngx-user-tile',
  templateUrl: './user-tile.component.html',
  styleUrls: ['./user-tile.component.scss'],
})
export class UserTileComponent {
  @Input() type: 'legacy' | 'standard' | 'small' = 'legacy';
  @Input() picture: string;
  @Input() title: string;
  @Input() name: string;
  @Input() isRegistered = false;
  @Input() badgeSize = 'xlarge';
  @Input() badgeOver: string;
  @Input() platform: PlatformsV2;

  isVeraInstagram(): boolean {
    return !!this.platform && this.platform.toLowerCase() === VeraPlatforms.instagram.toLowerCase();
  }
}
