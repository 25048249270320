import { Component, Input, ViewChild } from '@angular/core';
import { EmailTemplate } from '../../../../interfaces';
import { NbTabComponent, NbTabsetComponent } from '@nebular/theme';
import { AutoReplyComponent } from './auto-reply/auto-reply';

@Component({
  selector: 'ngx-auto-reply-tab',
  templateUrl: './auto-reply-tab.html',
  styleUrls: ['./auto-reply-tab.scss'],
})
export class AutoReplyTabComponent {
  @Input() campaignSlugName: string;
  @Input() contentApprovalTypes: { type: string; templates: EmailTemplate[] }[] = [];
  @Input() proposalTypes: { type: string; templates: EmailTemplate[] }[] = [];
  @Input() supportedTemplatingVariables = [];

  @ViewChild('autoReplyCompProposal', { static: false }) autoReplyCompPropasal: AutoReplyComponent;
  @ViewChild('autoReplyCompContentApproval', { static: false }) autoReplyCompContentApproval: AutoReplyComponent;
  @ViewChild('tabset', { static: false }) tabsetEl: NbTabsetComponent;
  @ViewChild('proposalTab', { static: false }) proposalTab: NbTabComponent;
  @ViewChild('contentApprovalTab', { static: false }) contentApprovalTab: NbTabComponent;

  activeTabTitle = 'Proposal';

  createNew(index: number, scope: string) {
    if (scope === 'content-approval') {
      this.tabsetEl.selectTab(this.contentApprovalTab);
      this.activeTabTitle = 'Conntent Approval';
      this.autoReplyCompContentApproval.createNew(index, scope);
    } else {
      this.tabsetEl.selectTab(this.proposalTab);
      this.activeTabTitle = 'Proposal';
      this.autoReplyCompPropasal.createNew(index, scope);
    }
  }

  isTemplateLimitExceed(index: number, scope: string): boolean {
    if (scope === 'content-approval') {
      this.activeTabTitle = 'Content Approval';
      return this.autoReplyCompContentApproval.isTemplateLimitExceed(index);
    } else {
      this.activeTabTitle = 'Proposal';
      return this.autoReplyCompPropasal.isTemplateLimitExceed(index);
    }
  }

  changeTabSelection(e: NbTabComponent): void {
    this.activeTabTitle = e.tabTitle;
  }
}
