import { Observable, Subject, Subscription, zip } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { CampaignsService } from '../../@core/data/campaigns.service';
import { MetadataService } from '../../@core/data/metadata.service';
import { Platforms } from '../../enums';
import { CampaignInfluencerLookup, InfluencerDiscoveryLookup, InfluencerLookup } from '../../interfaces';
import utils from '../../@core/utils/utils';
import { map } from 'rxjs/operators';
import { FeatureFlagService } from '../../@core/feature-flag/feature-flag.service';

@Component({
  selector: 'ai-influencer-select',
  templateUrl: './influencer-select.control.html',
  styleUrls: ['./influencer-select.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfluencerSelectControl),
      multi: true,
    },
  ],
})
export class InfluencerSelectControl implements OnInit, OnDestroy {
  @Input() selectedInfluencers: InfluencerLookup[] = [];
  @Input() isDisabled = true;
  @Input() platform: Platforms;
  @Input() maxNumberOfInfluencersVisible = 11;
  @Input() campaignSlugName = '';
  @Input() showLabel = true;
  @Input() isRequired = true;
  @Input() loadingInfluencers = false;
  @Input() isPaginated = false;
  @Input() totalInfluencers = 0;
  @Input() selectedTerminationRule: string;

  @Input() isScrollable = false;

  @Input() singleItemClearable = true;

  influencers: Observable<CampaignInfluencerLookup[]>;
  influencerNameInput = new Subject<string>();

  @Output()
  updatedInfluencersEmitter = new EventEmitter();

  @Output()
  fetchMoreEmitter = new EventEmitter();

  isChipOnEditMode = false;
  _showMoreBtnVisible = false;

  // used in showing/editing email on hover in influencers list
  hoverInfluencer: InfluencerLookup;

  pageBreak = 0;
  private updateInfluencerEmailSubscription: Subscription;

  async ngOnInit(): Promise<void> {
    // used to send the status of the selection before any edit by Samantha
    this.pageBreak = this.maxNumberOfInfluencersVisible;
    this.updateInfluencersSelection();
    this.setShowMoreVisibility();
  }

  constructor(
    private metadataSerivce: MetadataService,
    private toastrService: ToastrService,
    private campaignsService: CampaignsService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.influencers = utils.buildAutocompleteObservable(
      this.influencerNameInput,
      (term: string) => this.lookupCampaignInfluencersAsObservable(term),
      () => (this.loadingInfluencers = true),
      () => (this.loadingInfluencers = false),
    );
  }

  lookupCampaignInfluencersAsObservable(term: string): Observable<InfluencerDiscoveryLookup[]> {
    return zip(this.lookUpInfluencers(term)).pipe(map((x) => [...x[0]]));
  }

  setShowMoreVisibility(): void {
    if (!this.isPaginated) {
      this._showMoreBtnVisible = this.selectedInfluencers.length > this.maxNumberOfInfluencersVisible;
    }
  }

  lookUpInfluencers(term: string): Promise<InfluencerDiscoveryLookup[]> {
    return this.campaignsService
      .lookupCampaignInfluencers(this.campaignSlugName, term)
      .toPromise()
      .then((res: InfluencerLookup[]) => {
        const modifiedInfluencers: InfluencerDiscoveryLookup[] = res.map(
          (i: InfluencerLookup): InfluencerDiscoveryLookup => ({
            ...i,
            category: 'Influencers',
          }),
        );
        return modifiedInfluencers;
      });
  }

  addInfluencerToFilters = (name: string) => {
    if (!name) {
      return undefined;
    }
    return { username: name };
  };

  filterInfluencersToShow(influencers: InfluencerLookup[]): InfluencerLookup[] {
    if (this.isPaginated) {
      return influencers.slice(0, this.pageBreak);
    }
    return influencers.slice(0, this._showMoreBtnVisible ? this.maxNumberOfInfluencersVisible : undefined);
  }

  handleChipEdit(item, isChipOnEditMode: boolean): void {
    if (!this.loadingInfluencers) {
      item.editable = isChipOnEditMode;
      this.isChipOnEditMode = isChipOnEditMode;
    }
  }

  updateCampaignInfluencerMetadataEmail(email: string, username: string): void {
    if (this.updateInfluencerEmailSubscription) {
      this.updateInfluencerEmailSubscription.unsubscribe();
    }
    this.updateInfluencerEmailSubscription = this.campaignsService
      .updateCampaignInfluencerMetadataEmail(this.campaignSlugName, email, username, this.platform)
      .subscribe(
        () => {
          this.toastrService.success('Influencer email updated successfully');
          this.campaignsService.sendInfluencersChangedEvent(
            this.campaignSlugName,
            this.campaignsService.influencersChanges.influencersEmailChipEdited,
          );
        },
        (error) => {
          console.error(error);
          this.toastrService.error('Failed to update campaign influencer email');
        },
      );
  }

  async handleSaveChip(input, item): Promise<void> {
    item.email = input.value;
    item.editable = false;
    if (this.campaignSlugName) {
      this.updateCampaignInfluencerMetadataEmail(item.email, item.username);
    } else {
      this.updateMetadata(item.username, input.value);
    }
    this.updateInfluencersSelection();
    this.isChipOnEditMode = false;
  }

  updateMetadata(influencerUsername: string, updatedEmail: string): void {
    this.metadataSerivce
      .getInfluencerMetadata(influencerUsername, this.platform)
      .then((influencerMetadata) => {
        if (influencerMetadata['Error']) {
          throw Error(influencerMetadata['Error']);
        }
        influencerMetadata.email = updatedEmail;
        this.metadataSerivce
          .updateInfluencerMetadata(influencerUsername, this.platform, influencerMetadata)
          .then(() => {
            this.toastrService.success('Email updated successfully');
            if (this.campaignSlugName) {
              this.campaignsService.sendInfluencersChangedEvent(
                this.campaignSlugName,
                this.campaignsService.influencersChanges.influencersEmailChipEdited,
              );
            }
          })
          .catch(() => {
            console.log('Error in updating influencer metadata'); // Intentional
            this.toastrService.error("Email couldn't be updated");
          });
      })
      .catch(() => {
        console.log('Error in fetching influencer metadata'); // Intentional
        this.toastrService.error("Email couldn't be updated");
      });
  }

  updateInfluencersSelection(): void {
    this.updatedInfluencersEmitter.emit({
      influencers: cloneDeep(this.selectedInfluencers),
      isValid:
        !this.numberOfInfluencersWithInvalidOrMissingEmail() &&
        (this.isRequired ? !!this.selectedInfluencers.length : true) &&
        (this.singleItemClearable || this.selectedInfluencers.length > 0),
    });
    if (!this.selectedInfluencers?.length && this.showMoreBtnVisible) {
      this.showNextInfluencers(); // This means the user has deleted the last influencer visible but still have more to paginate from server
    }
  }

  showNextInfluencers(): void {
    if (!this.isPaginated) {
      this._showMoreBtnVisible = false;
      return;
    }

    const selectedInfluencersLength = this.selectedInfluencers?.length || 0;

    if (selectedInfluencersLength > this.pageBreak) {
      const nextRemainingLoadedInfluencers =
        this.pageBreak + this.maxNumberOfInfluencersVisible - selectedInfluencersLength;
      this.pageBreak +=
        nextRemainingLoadedInfluencers <= 0
          ? this.maxNumberOfInfluencersVisible
          : this.maxNumberOfInfluencersVisible - nextRemainingLoadedInfluencers;
    } else {
      this.fetchMoreEmitter.emit();
      this.pageBreak += this.maxNumberOfInfluencersVisible;
      if (selectedInfluencersLength < this.maxNumberOfInfluencersVisible) {
        this.pageBreak -= this.maxNumberOfInfluencersVisible - selectedInfluencersLength;
      }
    }
  }

  numberOfInfluencersWithInvalidOrMissingEmail(): number {
    return this.selectedInfluencers.filter(({ email }) => !this.isEmailValid(email))?.length;
  }

  isEmailValid(email: string | undefined): boolean {
    return utils.isEmailValidAndNotEmpty(email);
  }

  numberOfInfluencersWithProposalSubmitted(): number {
    return this.selectedTerminationRule === 'PROPOSAL_SUBMITTED'
      ? this.selectedInfluencers.filter(({ willBeTerminatedInSequence }) => !!willBeTerminatedInSequence)?.length ?? 0
      : 0;
  }

  getInfluencersWithoutProposalSubmitted(): InfluencerLookup[] {
    return this.selectedTerminationRule === 'PROPOSAL_SUBMITTED'
      ? this.selectedInfluencers.filter(({ willBeTerminatedInSequence }) => !willBeTerminatedInSequence)
      : this.selectedInfluencers;
  }

  getMessageForInfluencersWithNoValidEmail(): string {
    return `${this.numberOfInfluencersWithInvalidOrMissingEmail()} of ${this.selectedInfluencers?.length}
      ${this.selectedInfluencers?.length === 1 ? 'influencer' : 'influencers'} selected either 
      ${this.numberOfInfluencersWithInvalidOrMissingEmail() === 1 ? "doesn't" : "don't"} have
      email address or have an invalid one. No email will be sent to them.`;
  }

  getMessageForInfluencersWithProposalsSubmitted(): string {
    return `${this.numberOfInfluencersWithProposalSubmitted()} of ${this.selectedInfluencers?.length}
      influencer(s) have already submitted proposals. They will be removed from this sequence. Select a different Termination Policy to change this.`;
  }

  influencersWithValidEmail(): InfluencerLookup[] {
    return this.selectedInfluencers.filter(({ email }) => this.isEmailValid(email));
  }

  get remainingInfluencers(): number {
    return (
      (this.isPaginated
        ? this.totalInfluencers - this.filterInfluencersToShow(this.selectedInfluencers)?.length
        : this.selectedInfluencers?.length - this.maxNumberOfInfluencersVisible) ?? 0
    );
  }

  get showMoreBtnVisible(): boolean {
    return !this.loadingInfluencers && this.isPaginated ? !!this.remainingInfluencers : this._showMoreBtnVisible;
  }

  removeInfluencersWithNoValidEmail(): void {
    this.selectedInfluencers = this.influencersWithValidEmail();
    this.setShowMoreVisibility();
    this.updateInfluencersSelection();
  }

  removeSubmittedProposalInfluencers(): void {
    this.selectedInfluencers = this.getInfluencersWithoutProposalSubmitted();
    this.setShowMoreVisibility();
    this.updateInfluencersSelection();
  }

  ngOnDestroy(): void {
    if (this.updateInfluencerEmailSubscription) {
      this.updateInfluencerEmailSubscription.unsubscribe();
    }
  }
}
