import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone, update, map, each } from 'lodash';
import { environment } from '../../../../environments/environment';
import {
  BrandLookup,
  Influencer,
  InfluencerLookup,
  InstagramInfluencer,
  InstagramInfluencerAPIResponse,
} from '../../../interfaces';
import { InstagramInfluencerSortKey, VeraPlatforms } from '../../../enums';
import { InfluencerSearchFilters } from '../influencer-filter.service';
import utils from '../../utils/utils';
import { Location } from '../location.service';
import { Observable, of } from 'rxjs';
@Injectable()
export class InstagramDiscoveryService {
  constructor(private http: HttpClient) {}

  getTopInfluencers(location: string, sortKey: InstagramInfluencerSortKey): Promise<InstagramInfluencer[]> {
    return this.http
      .get<InstagramInfluencer[]>(`${environment.api}/api/instagram/influencers/top`, {
        params: {
          location,
          sortKey,
        },
      })
      .toPromise();
  }

  getInfluencers(
    searchFilters: Readonly<InfluencerSearchFilters>,
    page: number,
  ): Promise<InstagramInfluencerAPIResponse> {
    const influencerFilters = clone(searchFilters.influencerFilters);
    if (influencerFilters.username) {
      influencerFilters.username = utils.getUsernameFromInstagramUrlIfPresent(influencerFilters.username);
      influencerFilters.username = influencerFilters.username.trim();
    }
    const audienceFilters = clone(searchFilters.audienceFilters);
    update(influencerFilters, 'locations', (locations: Location[] = []) => {
      return locations.map((location) => ({
        name: location.name,
        isIncluded: location.isIncluded,
      }));
    });
    return this.http
      .post<InstagramInfluencerAPIResponse>(
        `${environment.api}/api/instagram/influencers/search`,
        {
          influencerFilters: update(influencerFilters, 'brands', (brands: BrandLookup[]) =>
            map(brands, (brand) => {
              return {
                name: brand.username,
                isIncluded: brand.isIncluded,
              };
            }),
          ),
          audienceFilters: update(audienceFilters, 'brands', (brands: BrandLookup[]) => map(brands, 'username')),
        },
        {
          params: {
            page: page.toString(),
            sortKey: searchFilters.sortKey,
          },
        },
      )
      .toPromise();
  }

  lookupInfluencers(term: string): Observable<InfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    term = utils.getUsernameFromInstagramUrlIfPresent(term);
    return this.http.get<InfluencerLookup[]>(`${environment.api}/api/instagram/users/lookup`, {
      params: { term: term.trim() },
    });
  }

  lookupBrands(term: string): Observable<BrandLookup[]> {
    if (!term) {
      return of([]);
    }
    term = term.startsWith('@', 0) ? term.slice(1) : term;
    return this.http.get<BrandLookup[]>(`${environment.api}/api/instagram/brands/lookup`, {
      params: { term },
    });
  }

  async exportInfluencers(
    usernames: string[],
    campaignSlugName?: string,
    groupSlugName?: string,
    allUsers = false,
    context: 'INVITATION' | 'PROPOSAL' = 'INVITATION',
  ): Promise<Blob> {
    const response = await this.http
      .post(
        `${environment.api}/api/instagram/export/influencers`,
        {
          usernames,
          context,
        },
        {
          params: {
            ...(campaignSlugName && { campaignSlugName }),
            ...(groupSlugName && { groupSlugName }),
            allUsers: allUsers?.toString(),
            platform: VeraPlatforms.instagram,
          },
          responseType: 'blob',
        },
      )
      .toPromise();

    return utils.getCSVBlob(response);
  }

  async searchOnInstagram(username: string): Promise<InstagramInfluencer> {
    try {
      const response = await this.http.get(`${environment.api}/api/instagram/discovery/${username}`, {}).toPromise();

      const instagramProfile = response as InstagramInfluencer;

      return {
        ...instagramProfile,
        isPotentialInfluencer: true,
      };
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
