import { Component, Input, HostBinding } from '@angular/core';
import { Typography } from '../common.enum';

@Component({
  selector: 'ai-button',
  templateUrl: './button.control.html',
  styleUrls: ['./button.control.scss'],
})
export class ButtonControl {
  @Input() label: string;
  @Input() customClasses: string;
  @Input() size: 'medium' | 'small' | 'large' | 'default' = 'default';
  @Input() type: 'fill' | 'outline' | 'text' | 'icon' | 'dash' | 'ghost' | 'toggle' = 'fill';
  @Input() prefix: string;
  @Input() prefixImage: string;
  @Input() suffix: string;
  @Input() color: 'string';
  @Input() textColor: 'string';
  @Input() length: 'fixed' | 'block' = 'fixed';
  @Input() disabled = false;
  @Input() typography: Typography; // refer to projects/controls/src/styles.css
  @Input() active = false;
  @Input() split = false;
  @Input() iconPadding = true;

  @HostBinding('class')
  get hostClass(): string {
    return this.disabled ? 'disabled' : 'enabled';
  }

  get textClass(): Typography {
    switch (this.size) {
      case 'small':
        return 'body-3';
      default:
        return 'sub-heading-h2';
    }
  }

  get classes() {
    return [
      `ai-btn`,
      `ai-btn-${this.length}`,
      `ai-btn-${this.color}`,
      `ai-btn-${this.size}`,
      `ai-btn-${this.type}`,
      ...(this.disabled ? [`ai-btn-disabled`] : []),
      ...(this.active ? [`ai-btn-active`] : []),
      ...(this.customClasses?.length ? this.customClasses.split(' ') : []),
    ];
  }
}
