import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename',
})
export class FilenameTruncatePipe implements PipeTransform {
  transform(name: string, offset: number) {
    const leftRightStrings = name.split('.');
    const fileExtension = leftRightStrings[leftRightStrings.length - 1];
    leftRightStrings.pop();
    const fileName = leftRightStrings.reduce((acc, value) => acc + `.${value}`);
    const fileNameLength = fileName.length;
    if (fileNameLength > offset) {
      return fileName.substr(0, offset - 5) + '...' + fileName.substr(-5) + '.' + fileExtension;
    }
    return name;
  }
}
