import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import { Product, ProductMessage, SelectionConstrains } from '../../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProductGiftsService {
  selectionConstrains: SelectionConstrains = { quantity: 1 };
  private productListSource = new BehaviorSubject<Product[]>([]);
  productListChanged$ = this.productListSource.asObservable();

  constructor(private httpClient: HttpClient) {}

  uploadCustomProductImage(campaignSlugName: string, productImage: File): Promise<{ imageUrl: string }> {
    const formData = new FormData();
    formData.append('files', productImage);
    return this.httpClient
      .post<{ imageUrl: string }>(
        `${environment.api}/api/campaigns/v2/${campaignSlugName}/product/custom/image`,
        formData,
      )
      .toPromise();
  }

  saveProductGifts(
    campaignSlugName: string,
    products: Product[] = [],
    productGiftingEnabled = true,
    collectShippingAddress = false,
  ): Promise<ProductMessage> {
    return this.httpClient
      .post<ProductMessage>(`${environment.api}/api/campaigns/v2/${campaignSlugName}/workflow/productGift`, {
        products,
        selectionConstrains: this.selectionConstrains,
        productGiftingEnabled,
        collectShippingAddress: productGiftingEnabled || collectShippingAddress,
      })
      .toPromise();
  }

  removeProduct(campaignSlugName: string, productId: string): Promise<ProductMessage> {
    return this.httpClient
      .delete<ProductMessage>(
        `${environment.api}/api/campaigns/v2/${campaignSlugName}/workflow/product/${encodeURIComponent(productId)}`,
      )
      .toPromise();
  }

  addToProductList(products: Product[], isCustomProduct?: boolean): void {
    const existingProducts = this.getProductList();
    const existingCustomProducts = existingProducts.filter((p) => p.productType.source == 'CUSTOM');
    if (isCustomProduct) {
      this.changeProductList([...products, ...existingProducts]);
    } else {
      this.changeProductList([...products, ...existingCustomProducts]);
    }
  }

  updateCustomProduct(productId: string, updatedProduct: Product): void {
    const index = this.getProductList().findIndex((product) => product.productType.id === productId);
    if (index > -1) {
      const products = [...this.getProductList()];
      products[index] = updatedProduct;
      this.changeProductList(products);
    }
  }

  changeProductList(productList: Product[]) {
    this.productListSource.next(productList);
  }

  getProductList(): Product[] {
    return this.productListSource.value || [];
  }
}

export class CustomProduct implements Product {
  productTitle: string;
  description: string;
  productUrl: string;
  productImage: string;
  productType: { id: string; source: 'CUSTOM' | 'SHOPIFY' };
  constructor(title: string, description: string, productUrl: string, productImage: string) {
    this.productTitle = title;
    this.description = description;
    this.productUrl = productUrl;
    this.productImage = productImage;
    this.productType = { id: uuidv4(), source: 'CUSTOM' };
  }
}
