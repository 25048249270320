/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { remove } from 'lodash';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Platforms, PlatformsUpperEnum, PlatformsV2, VeraPlatforms } from '../../enums';
import {
  FacebookInfluencer,
  GroupFilter,
  GroupStatistics,
  IdsSharedTo,
  Influencer,
  TiktokInfluencer,
  TwitterInfluencer,
  YoutubeInfluencer,
  InstagramInfluencer,
} from '../../interfaces';
import { tap } from 'rxjs/operators';
import { UserguidingService } from './userguiding.service';

export class GroupInfo {
  type: 'USER_CREATED' | 'SHARED_TO_USER';
  name: string;
  slugName: string;
  owner: string;
  ownerName?: string;
  ownerEmail?: string;
  timestamp: number;
  statistics?: GroupStatistics;
  tags?: string[];
  updatedBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sharedWith?: string[];
  sharedWithNames?: string[];
  // TODO: Remove Unused field idsToShareWith
  idsToShareWith?: string[];
}

export class GroupInfoWithInfluencers extends GroupInfo {
  influencers?: string[];
}

// Object used in influencer & groups dropdown search in content and influencer discovery,
// category is set to 'Goups', username is set to group slug
export class GroupInfoLookup extends GroupInfo {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  username?: any;
  category?: string;
  id?: string;
  isIncluded?: boolean;
}

export const getGroupInfoLookupFromGroupname = (username: string): GroupInfoLookup => {
  return {
    username: username,
    name: '',
    slugName: '',
    type: 'USER_CREATED',
    timestamp: 0,
    owner: '',
    category: 'Groups',
    isIncluded: true,
  };
};
export class Group extends GroupInfo {
  groupName: string;
  influencersToBeProfiled?: string[];
  excludedInfluencerUsernames?: string[];
  /**
   * @deprecated you should use new type GroupInfluencers now
   */
  influencers?:
    | Influencer[]
    | YoutubeInfluencer[]
    | FacebookInfluencer[]
    | TiktokInfluencer[]
    | TwitterInfluencer[]
    | InstagramInfluencer[];
}

export interface StandardInfluencer {
  influencerPk: string;
  name: string;
  username: string;
  profilePicture: string;
  platform: PlatformsUpperEnum;
  email?: string;
}

export type GroupInfluencer =
  | Influencer
  | YoutubeInfluencer
  | FacebookInfluencer
  | TiktokInfluencer
  | TwitterInfluencer
  | InstagramInfluencer;

export type GroupInfluencers = (
  | Influencer[]
  | YoutubeInfluencer[]
  | FacebookInfluencer[]
  | TiktokInfluencer[]
  | TwitterInfluencer[]
  | InstagramInfluencer[]
) & { hasNotes?: boolean };

export type InfluencerCount = {
  totalInfluencers: number;
  totalReach: number;
  averageEngagement: number;
};

@Injectable()
export class GroupService {
  private groupFilterChangedSource = new Subject<GroupFilter>();
  groupFilterChanged$ = this.groupFilterChangedSource.asObservable();

  private instagramGroupsChangedSource = new Subject<GroupInfo[]>();
  instagramGroupsChanged$ = this.instagramGroupsChangedSource.asObservable();

  private youtubeGroupsChangedSource = new Subject<GroupInfo[]>();
  youtubeGroupsChanged$ = this.youtubeGroupsChangedSource.asObservable();

  private facebookGroupsChangedSource = new Subject<GroupInfo[]>();
  facebookGroupsChanged$ = this.facebookGroupsChangedSource.asObservable();

  private tiktokGroupsChangedSource = new Subject<GroupInfo[]>();
  tiktokGroupsChanged$ = this.tiktokGroupsChangedSource.asObservable();

  private twitterGroupsChangedSource = new Subject<GroupInfo[]>();
  twitterGroupsChanged$ = this.twitterGroupsChangedSource.asObservable();

  private groupsChangedSource = new Subject<GroupInfo[]>();
  groupsChanged$ = this.groupsChangedSource.asObservable();

  private groupDeletedSource = new Subject<GroupInfo>();
  groupDeleted$ = this.groupDeletedSource.asObservable();

  private groupCreatedSource = new Subject<string>();
  groupCreated$ = this.groupCreatedSource.asObservable();

  private veraInstagramGroupsChangedSource = new Subject<GroupInfo[]>();
  veraInstagramGroupsChanged$ = this.veraInstagramGroupsChangedSource.asObservable();

  private instagramGroupList: GroupInfo[];
  private youtubeGroupList: GroupInfo[];
  private facebookGroupList: GroupInfo[];
  private tiktokGroupList: GroupInfo[];
  private twitterGroupList: GroupInfo[];
  private veraInstagramGroupList: GroupInfo[];

  /** TODO:
   * Below variables are added as part of the cross platform group revamp.
   * Delete above variables once this feature is released */

  private groupList: GroupInfo[];

  private groupDeletedSourceNew = new Subject<GroupInfo>();
  groupDeletedNew$ = this.groupDeletedSourceNew.asObservable();

  private groupCreatedSourceNew = new Subject<GroupInfo>();
  groupCreatedNew$ = this.groupCreatedSourceNew.asObservable();

  private groupUpdatedSource = new Subject<GroupInfo>();
  groupUpdated$ = this.groupUpdatedSource.asObservable();

  constructor(public http: HttpClient, private router: Router, private userguiding: UserguidingService) {
    // fetch groups for all the platforms in the app
    // Object.values(Platforms).forEach(platform => {
    //   this.fetchInfluencerGroups(platform);
    // });
  }

  fetchInfluencerGroups(platform: PlatformsV2): Promise<void> {
    return this.http
      .get(environment.api + `/api/users/groups`, {
        params: {
          version: 'v2',
          platform,
        },
      })
      .toPromise()
      .then((response) => {
        this.updateGroupList(response, platform);
      });
  }

  shareGroupV2(
    payload: {
      organizationMember?: { added: string[]; removed: string[] };
      externalCollaborationMembers?: { added: string[]; removed: string[]; teamId: string };
    },
    slugName: string,
  ): Promise<unknown> {
    return this.http.put(environment.api + `/api/users/groups/${slugName}/share`, payload).toPromise();
  }

  getAll(platform: PlatformsV2): Promise<GroupInfo[]> {
    return this.http
      .get<GroupInfo[]>(environment.api + `/api/users/groups`, {
        params: {
          version: 'v2',
          platform: platform,
        },
      })
      .toPromise();
  }

  getAllGroupInfoWithInfluencers(influencerIds: string[], platform: PlatformsV2): Promise<GroupInfoWithInfluencers[]> {
    return this.http
      .post<GroupInfoWithInfluencers[]>(environment.api + `/api/users/groups/allWithInfluencers`, {
        influencerIds,
        platform,
      })
      .toPromise();
  }

  async getGroup(groupSlugName: string, platform: PlatformsV2, page?: number): Promise<Group | undefined> {
    try {
      /**
       * This API will not return statistics anymore for "INSTAGRAM" platform (Refere: getGroupStastics)
       */
      return await this.http
        .get<Group>(environment.api + `/api/users/groups/${groupSlugName}`, {
          params: {
            version: 'v2',
            platform: platform,
            page: page >= 0 ? page.toString() : undefined,
          },
        })
        .toPromise();
    } catch (error) {
      if (error.status === 404) {
        this.router.navigate(['404-not-found']);
      }
      return undefined;
    }
  }

  async getGroupInfluencerStats(groupSlugName: string, platform: PlatformsV2): Promise<InfluencerCount> {
    try {
      const res = await this.http
        .get<InfluencerCount>(environment.api + `/api/users/groups/${groupSlugName}/statistics`, {
          params: {
            version: 'v2',
            platform: platform,
          },
        })
        .toPromise();
      return res;
    } catch (error) {
      if (error.status === 404) {
        this.router.navigate(['404-not-found']);
      }
      return undefined;
    }
  }

  async getGroupInfluencers(
    groupSlugName: string,
    platform: PlatformsV2,
    page: number,
    limit: number,
  ): Promise<GroupInfluencers | undefined> {
    const groupInfluencers = await this.getGroupInfluencersV2(
      groupSlugName,
      platform,
      page,
      limit,
      {},
      '',
      'engagementRatio',
      'desc',
    );
    return groupInfluencers.data;
  }

  async getGroupInfluencersV2(
    groupSlugName: string,
    platform: PlatformsV2,
    page: number,
    limit: number,
    filter: Record<string, string[]> = {},
    search?: string,
    sortBy?: 'influencer' | 'followers' | 'engagementRatio',
    sortDirection?: 'asc' | 'desc',
  ): Promise<{ data: GroupInfluencers; totalCount: number }> {
    const params = {
      version: 'v2',
      platform: platform,
      page: page.toString(),
      limit: limit.toString(),
      search,
      sortBy,
      sortDirection,
    };

    Object.keys(filter).forEach((key) => {
      params[key] = filter[key].join(',');
    });
    try {
      return await this.http
        .get<{ data: GroupInfluencers; totalCount: number }>(
          environment.api + `/api/users/groups/${groupSlugName}/influencers`,
          {
            params,
          },
        )
        .toPromise();
    } catch (error) {
      if (error.status === 404) {
        this.router.navigate(['404-not-found']);
      }
      return undefined;
    }
  }

  async getGroupStatistics(groupSlugName: string, platform: PlatformsV2): Promise<GroupStatistics> {
    try {
      return await this.http
        .get<GroupStatistics>(environment.api + `/api/users/groups/${groupSlugName}/statistics`, {
          params: {
            version: 'v2',
            platform: platform,
          },
        })
        .toPromise();
    } catch (error) {
      if (error.status === 404) {
        this.router.navigate(['404-not-found']);
      }
      return undefined;
    }
  }

  getInfluencerGroups(username: string, platform: PlatformsV2): Promise<GroupInfo[]> {
    return this.http
      .get<GroupInfo[]>(environment.api + `/api/users/groups/${username}/groups`, {
        params: {
          version: 'v2',
          platform: platform,
        },
      })
      .toPromise();
  }

  createGroup(groupName: string, platform: PlatformsV2, tags?: string[]): Promise<any> {
    return this.http
      .post(environment.api + `/api/users/groups/`, {
        groupName,
        platform,
        tags,
      })
      .toPromise()
      .then((response) =>
        this.fetchInfluencerGroups(platform).then(() => {
          // this.influencerGroups.next(this.influencerGroupList);
          return response;
        }),
      );
  }

  deleteGroup(group: GroupInfo, platform: PlatformsV2, deleteSearchFilters?: string): Promise<any> {
    return this.http
      .delete(environment.api + `/api/users/groups/${group.slugName}`, {
        params: { platform: platform, deleteSearchFilters },
      })
      .toPromise()
      .then((response) => {
        this.filterAndUpdateGroupList(group, platform);
        return response;
      });
  }

  addInfluencerToGroup(group: GroupInfo, influencerUsernamesOrIds: string[], platform: PlatformsV2): Promise<any> {
    if (platform === VeraPlatforms.instagram) {
      return this.http
        .post(environment.api + `/api/users/groups/${group.slugName}/influencers`, {
          ids: influencerUsernamesOrIds,
          platform: platform,
        })
        .pipe(
          tap(() => {
            const identifier = this.userguiding.getSourceIdentifier('added_influencer_group');
            this.userguiding.track('Added influencer to a group', {
              slugName: group.slugName,
              influencerUsernamesOrIds,
            });
            this.userguiding.identify({ [identifier]: 1 });
          }),
        )
        .toPromise()
        .then((response) => {
          return response;
        });
    } else {
      return this.http
        .post(environment.api + `/api/users/groups/${group.slugName}/influencers`, {
          usernames: influencerUsernamesOrIds,
          platform: platform,
        })
        .pipe(
          tap(() => {
            const identifier = this.userguiding.getSourceIdentifier('added_influencer_group');
            this.userguiding.track('Added influencer to a group', {
              groupSlugNames: [group.slugName],
              influencerUsernames: influencerUsernamesOrIds,
            });
            this.userguiding.identify({ [identifier]: 1 });
          }),
        )
        .toPromise()
        .then((response) => {
          return response;
        });
    }
  }

  addInfluencersToGroups(groupSlugNames: string[], influencerUsernames: string[], platform: PlatformsV2): Promise<any> {
    return this.http
      .post(environment.api + `/api/users/groups/influencers`, {
        usernames: influencerUsernames,
        platform: platform,
        slugNames: groupSlugNames,
      })
      .pipe(
        tap(() => {
          const identifier = this.userguiding.getSourceIdentifier('added_influencer_group');
          this.userguiding.track('Added influencer to a group', { groupSlugNames, influencerUsernames });
          this.userguiding.identify({ [identifier]: 1 });
        }),
      )
      .toPromise()
      .then((response) => {
        return response;
      });
  }

  computeOverlap(group: GroupInfo, platform: PlatformsV2): Promise<GroupStatistics> {
    return this.http
      .post<GroupStatistics>(`${environment.api}/api/jobs/${platform.toLowerCase()}/influencers/compute-overlap`, {
        source: {
          triggeredBy: 'group',
          id: group.slugName,
        },
      })
      .toPromise();
  }

  removeInfluencerFromGroup(
    group: GroupInfo,
    influencerUsernames: string[],
    platform: PlatformsV2,
    allUsers = false,
    groupSlugName?: string,
  ): Promise<any> {
    return this.http
      .request('delete', `${environment.api}/api/users/groups/${group.slugName}/influencers`, {
        body: {
          usernames: influencerUsernames,
          platform: platform,
        },
        params: {
          allUsers: allUsers?.toString(),
          ...(groupSlugName && { groupSlugName }),
        },
      })
      .toPromise()
      .then((response) => {
        return response;
      });
  }

  removeInfluencerFromGroupById(
    group: GroupInfo,
    influencerIds: string[],
    platform: PlatformsV2,
    allUsers = false,
  ): Promise<any> {
    return this.http
      .request('delete', `${environment.api}/api/users/groups/${group.slugName}/influencers`, {
        body: {
          ids: influencerIds,
          platform: platform,
        },
        params: {
          allUsers: allUsers.toString(),
          groupSlugName: group.slugName,
        },
      })
      .toPromise()
      .then((response) => {
        return response;
      });
  }

  private filterAndUpdateGroupList(groupToBeRemoved: GroupInfo, platform: PlatformsV2) {
    switch (platform) {
      case Platforms.instagram: {
        remove(this.instagramGroupList, (g) => g.slugName === groupToBeRemoved.slugName);
        this.instagramGroupsChangedSource.next(this.instagramGroupList);
        break;
      }
      case Platforms.youtube: {
        remove(this.youtubeGroupList, (g) => g.slugName === groupToBeRemoved.slugName);
        this.youtubeGroupsChangedSource.next(this.youtubeGroupList);
        break;
      }
      case Platforms.facebook: {
        remove(this.facebookGroupList, (g) => g.slugName === groupToBeRemoved.slugName);
        this.facebookGroupsChangedSource.next(this.facebookGroupList);
        break;
      }
      case Platforms.tiktok: {
        remove(this.tiktokGroupList, (g) => g.slugName === groupToBeRemoved.slugName);
        this.tiktokGroupsChangedSource.next(this.tiktokGroupList);
        break;
      }
      case Platforms.twitter: {
        remove(this.twitterGroupList, (g) => g.slugName === groupToBeRemoved.slugName);
        this.twitterGroupsChangedSource.next(this.twitterGroupList);
        break;
      }
      case VeraPlatforms.instagram: {
        remove(this.veraInstagramGroupList, (g) => g.slugName === groupToBeRemoved.slugName);
        this.veraInstagramGroupsChangedSource.next(this.veraInstagramGroupList);
        break;
      }
    }
    /** TODO:
     * Below code is added as part of the cross platform group revamp.
     * Delete above code once this feature is released
     */
    remove(this.groupList, (g) => g.slugName === groupToBeRemoved.slugName);
    this.groupsChangedSource.next(this.groupList);
  }

  private updateGroupList(response, platform: PlatformsV2) {
    switch (platform) {
      case Platforms.instagram: {
        this.instagramGroupList = response as GroupInfo[];
        this.instagramGroupsChangedSource.next(this.instagramGroupList);
        break;
      }
      case Platforms.youtube: {
        this.youtubeGroupList = response as GroupInfo[];
        this.youtubeGroupsChangedSource.next(this.youtubeGroupList);
        break;
      }
      case Platforms.facebook: {
        this.facebookGroupList = response as GroupInfo[];
        this.facebookGroupsChangedSource.next(this.facebookGroupList);
        break;
      }
      case Platforms.tiktok: {
        this.tiktokGroupList = response as GroupInfo[];
        this.tiktokGroupsChangedSource.next(this.tiktokGroupList);
        break;
      }
      case Platforms.twitter: {
        this.twitterGroupList = response as GroupInfo[];
        this.twitterGroupsChangedSource.next(this.twitterGroupList);
        break;
      }
      case VeraPlatforms.instagram: {
        this.veraInstagramGroupList = response as GroupInfo[];
        this.veraInstagramGroupsChangedSource.next(this.veraInstagramGroupList);
        break;
      }
    }

    /** TODO:
     * Below code is added as part of the cross platform group revamp.
     * Delete above code once this feature is released
     */
    this.groupList = response as GroupInfo[];
    this.groupsChangedSource.next(this.groupList);
  }

  sendGroupFilterChangedEvent(group?: GroupInfo): void {
    this.groupFilterChangedSource.next({
      group: group,
      event: 'change-filter',
    });
  }

  sendGroupFilterInfuencersChangedEvent(group: GroupInfo): void {
    this.groupFilterChangedSource.next({
      group: group,
      event: 'add-influencer',
    });
  }

  sendDeleteGroupEvent(group: GroupInfo): void {
    this.groupDeletedSource.next(group);
  }

  groupDeletedEvent(group: GroupInfo): void {
    this.groupDeletedSourceNew.next(group);
  }

  groupUpdatedEvent(group: GroupInfo): void {
    this.groupUpdatedSource.next(group);
  }

  groupCreatedEvent(groupSlugName: string): void {
    this.groupCreatedSource.next(groupSlugName);
  }

  groupCreatedNewEvent(group: GroupInfo): void {
    this.groupCreatedSourceNew.next(group);
  }

  shareGroup(slugName: string, clientIdsToShareWith: string[], platform: Platforms): Promise<IdsSharedTo> {
    return this.http
      .patch<IdsSharedTo>(
        `${environment.api}/api/users/groups/${slugName}/share`,
        { clientIds: clientIdsToShareWith, modification: 'add' },
        { params: { platform } },
      )
      .toPromise();
  }

  unshareGroup(slugName: string, clientIdsToRemove: string[], platform: Platforms): Promise<any> {
    return this.http
      .patch(
        `${environment.api}/api/users/groups/${slugName}/share`,
        { clientIds: clientIdsToRemove, modification: 'remove' },
        { params: { platform: platform.toUpperCase() } },
      )
      .toPromise();
  }

  updateGroup(slugName: string, platform: PlatformsUpperEnum, updatedInfo: Partial<Group>): Promise<any> {
    return this.http
      .put(`${environment.api}/api/users/groups/${slugName}`, { updatedInfo }, { params: { platform } })
      .toPromise();
  }
}

export declare type GroupFilterEvent = 'add-influencer' | 'remove-influencer' | 'change-filter';
