<nb-layout windowMode>
  <nb-layout-column>
    <div *ngIf="!loading" class="subscription-plans">

      <div class="subscription-plans-header">
        <img style="height: 60px;" alt="" affLogo>
        <h4>Choose your plan to start your 14-day free trial*</h4>
        <p *ngIf="freePlanName">*{{ freePlanName.replace('SHOPIFY-', '') }} plan does not have the 14-day trial. It is
          free forever.</p>
      </div>

      <div class="subscription-plans-container">
        <div class="subscription-plan" *ngFor="let subscriptionPlan of subscriptionPlans">
          <div class="subscription-plan-info">

            <div class="updating-plan" *ngIf="isUpdatingPlan">
              <div class="current-badge" *ngIf="subscriptionPlan.isCurrentPlan">
                <p>{{subscriptionPlan.isTheBestValue ? "Current Plan & Recommended" : "Current Plan"}}</p>
              </div>

              <div class="recommended-badge" *ngIf="!subscriptionPlan.isCurrentPlan && subscriptionPlan.isTheBestValue">
                <p>Recommended</p>
              </div>
            </div>

            <div class="plan-name">
              <p>{{subscriptionPlan.name.replace('SHOPIFY-', '')}}</p>
              <div class="best-value-badge" *ngIf="subscriptionPlan.isTheBestValue">Best Value</div>
            </div>

            <div class="plan-price" [ngClass]="{'best-value': subscriptionPlan.isTheBestValue}">
              <p>
                <span *ngIf="subscriptionPlan.charge > 0">{{subscriptionPlan.currency}}</span>
                <strong [ngClass]="{'enterprise': subscriptionPlan.isEnterprise}">{{subscriptionPlan.charge > 0 ?
                  subscriptionPlan.charge : subscriptionPlan.isEnterprise ? 'For price contact us' : 'Free'}}</strong>
              </p>
              <span *ngIf="subscriptionPlan.charge && subscriptionPlan.chargeCycle">
                {{subscriptionPlan.chargeCycle}}
              </span>
            </div>

            <div class="features-description">
              <div *ngFor="let feature of subscriptionPlan.featuresDescription">
                <em class="fas fa-check"></em>
                <p>
                  {{feature.split(' - ')[0]}}
                  <small *ngIf="feature.split(' - ')[1]"><br>{{feature.split(' - ')[1]}}</small>
                </p>
              </div>
            </div>

          </div>

          <button class="btn btn-primary btn-xs"
            [ngClass]="{'btn-outline-primary': subscriptionPlan.isEnterprise || subscriptionPlan.isCurrentPlan }"
            (click)="choosePlan(subscriptionPlan)">
            {{ subscriptionPlan.isCurrentPlan ? "Continue" : "Choose" }} Plan
          </button>
        </div>
      </div>
    </div>

    <nb-spinner *ngIf="loading" class="large-spinner"></nb-spinner>
  </nb-layout-column>
</nb-layout>