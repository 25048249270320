<div class="modal-body">
  <div class="content-display" [ngClass]="content?.mediaType === 'CAROUSEL_ALBUM' ? 'carousel-media': 'video-media'">
    <ng-container *ngIf="content">
      <ngx-ig-embed class="instagram-media" [content]="content"></ngx-ig-embed>
    </ng-container>
    <ng-container [ngSwitch]="content?.mediaType">
      <i *ngSwitchCase="'VIDEO'" class="far fa-video media-type"></i>
      <i *ngSwitchCase="'IMAGE'" class="far fa-camera media-type"></i>
      <i *ngSwitchCase="'CAROUSEL_ALBUM'" class="far fa-clone media-type"></i>
    </ng-container>
  </div>
  <div class="content-information" *ngIf=content?.author>
    <div class="content-header">
      <a [attr.href]="content.author?.username? '/pages/veraInstagram/profile/'+content.author.username : null"
        target="_blank" [ngClass]="{'content-author': true, 'content-profiled-author': true}">
        <nb-user [name]="content.author?.fullName" [picture]="content.author?.picture"
          [title]="content.author?.username" size="large">
          <img id="hidden_image" src="{{content.author?.picture}}" style="display: none;" />
        </nb-user>
      </a>
      <button class="close" aria-label="Close" (click)="closeModal()">
        <i aria-hidden="true" class="far fa-times"></i>
      </button>
    </div>
    <div class="content-body">
      <div class="content-stats">
        <div class="stat" *ngIf="content?.estimatedReach">
          <label>{{ content?.areInsightsVerified || isInfluencerRegistered  ? 'Reach' : 'Est. Reach' }}</label> {{content.estimatedReach |
          formatNumber:2}}
        </div>
        <div class="stat" *ngIf="content?.estimatedImpressions">
          <label>{{ content?.areInsightsVerified || isInfluencerRegistered ? 'Impressions' : 'Est. Impressions' }}</label>
          {{content.estimatedImpressions | formatNumber: 2}}
        </div>
        <div class="stat" *ngIf="content?.estimatedMediaValue">
          <label>EMV</label> {{content.estimatedMediaValue | currency:currency}}
        </div>
        <div class="h-space"></div>
        <div class="stat">
          <i class="fa-regular fa-calendar af-icon"></i>
          {{content?.takenAt | ngxCapitalize}}
        </div>
        <div class="stat">
          <i class="fa-regular fa-heart af-icon"></i>
          {{content?.likeCount | formatNumber:0}} Likes
        </div>
        <div class="stat" *ngIf="content?.playCount">
          <i class="far fa-play af-icon"></i>
          {{content?.playCount | formatNumber:0}} Plays
        </div>
        <div class="stat">
          <i class="fa-regular fa-messages af-icon"></i>
          {{content?.commentCount | formatNumber:0}} Comments
        </div>
        <div class="stat" *ngIf="content?.sponsored">
          <i class="fa-regular fa-dollar-sign af-icon"></i>
          This is a sponsored post
        </div>
      </div>
      <div class="content-list" *ngIf="content?.brands?.length > 0">
        <div class="content-list-header">Brands</div>
        <span *ngFor="let brand of content?.brands">
          @{{brand.username}}
        </span>
      </div>
      <div class="content-caption">{{content?.caption}}</div>
    </div>
    <div class="content-footer">
      <a class="btn btn-xs btn-primary btn-block" [attr.href]="content?.instagramUrl" target="_blank">
        <i class="fa-brands fa-instagram af-icon"></i> GO TO INSTAGRAM
      </a>
    </div>
  </div>
</div>