<div class="tabs-container" [ngClass]="class">
  <ul class="nav nav-tabs {{tabsetClass}}" >
    <li *ngFor="let tab of tabs" [class.disable]="tab.disable" [class.active-tab]="tab.active" class="tab" [ngClass]="titleClass" (click)="selectTab(tab)" [attr.data-cy]="tab.dataCy">
      <i class="tabIcon" [ngClass]="tab.tabIcon" *ngIf="tab.tabIcon"></i>
      <ng-container *ngTemplateOutlet="tab.prefix"></ng-container>
      {{tab.tabTitle }}
      <ng-container *ngTemplateOutlet="tab.suffix"></ng-container>
    </li>
  </ul>
  <ng-content></ng-content>
</div>
