import { Component, Input } from '@angular/core';
import { MapItem } from '../../interfaces';

@Component({
  selector: 'ngx-audience-location-distribution-card',
  templateUrl: './audience-location-distribution-card.component.html',
  styleUrls: ['./audience-location-distribution-card.component.scss'],
})
export class AudienceLocationDistributionCardComponent {
  @Input() locationDistributionData: MapItem[];
  @Input() cityDistributionData: { name: string; value: number }[] = [];
  @Input() tooltipMessage: string;
  @Input() influencerUsername: string;

  selectedAudienceLocationView = 'Map';

  get audienceLocationViewOptions(): string[] {
    const options = ['Map', 'Country'];

    if (this.cityDistributionData?.length) {
      options.push('City');
    }

    return options;
  }
}
