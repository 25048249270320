<div class="table-options">
  <ng-content select="[leading]"></ng-content>
  <ai-button [disabled]="exportDisabled" label="Export as CSV" type="text" (click)="exportTableData.emit()"></ai-button>
  <div class="vertical-separator"></div>
  <div class="sort-filter">
    <ngx-sort-filter [sortKey]="sortKey" [sortOptions]="sortOptions" [disabled]="loading"
      (sortFilterChangedEvent)="sortFilterChangedEvent.emit($event)">
    </ngx-sort-filter>
  </div>
  <div class="vertical-separator"></div>
  <div class="search-input">
    <input type="text" name="title" placeholder="Search" (onEvent)="searchEvent.emit(search)" (input)="searchChange.emit($event.target.value)" [(ngModel)]="search"
      keyupDebounce trim />
    <i class="far" [ngClass]="{'fa-search':  !search, 'fa-times cursor-pointer': !!search}"
      (click)="clearSearch()"></i>
  </div>
  <ng-content select="[trailing]"></ng-content>
</div>
