<nb-card size="medium" [class.extra-long-card]="extraLongCard" [class.with-email-banner]="addEmailBanner">
  <nb-card-header>
    <span>{{name}} Recent Media</span>

    <ngx-tooltip [tooltip]="tooltip"></ngx-tooltip>
  </nb-card-header>
  <nb-card-body>
    <div class="gallery" [class.centered]="mediaItems?.length <=2 ">
      <div class="gallery-item" [ngClass]="[mediaItems?.length > 6 ? 'col-sm-4': 'col-sm-6']"
        *ngFor="let mediaItem of mediaItems; index as i" >

        <!-- Image -->
        <ng-container *ngIf="mediaViewer !== Platforms.instagram && mediaViewer !== VeraPlatforms.instagram">
          <img #eleRef [lazyLoad]="getMediaUrl(mediaItem) | async"
            (click)="presentContentDetailsModal(mediaItem)"
            (onStateChange)="handleStateChange($event, eleRef, i)"
            defaultImage="/assets/images/image-loader.svg"
            (error)="missingImage(eleRef, i)">
        </ng-container>
        <!-- oEmbed -->
        <ng-container *ngIf="mediaViewer === Platforms.instagram">
          <ngx-ig-embed [content]="mediaItem"></ngx-ig-embed>
          <div class="overlay">
            <div class="backdrop" (click)="presentContentDetailsModal(mediaItem)"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="mediaViewer === VeraPlatforms.instagram">
          <ngx-ig-embed [content]="mediaItem" [code]="mediaItem.code"></ngx-ig-embed>
          <div class="overlay">
            <div class="backdrop" (click)="presentContentDetailsModal(mediaItem)"></div>
          </div>
        </ng-container>

        <ng-container *ngIf="mediaViewer === !'Platforms.instagram' && mediaViewer !== VeraPlatforms.instagram">
          <img #eleRef [lazyLoad]="getMediaUrl(mediaItem) | async"
            (onStateChange)="handleStateChange($event, eleRef, i)" defaultImage="/assets/images/image-loader.svg"
            (error)="missingImage(eleRef, i)">
        </ng-container>
        <ng-container *ngIf="mediaViewer === 'Platforms.instagram'" [ngSwitch]="mediaItem.media.mediaType">
          <i *ngSwitchCase="'video'" class="fas fa-video media-type"></i>
          <i *ngSwitchCase="'photo'" class="fas fa-camera media-type"></i>
          <i *ngSwitchCase="'carousel'" class="fas fa-clone media-type"></i>
          <i *ngSwitchCase="'igtv'" class="fas fa-tv media-type"></i>
        </ng-container>
        <ng-container *ngIf="mediaViewer === 'VeraPlatforms.instagram'" [ngSwitch]="mediaItem.mediaType">
          <i *ngSwitchCase="'VIDEO'" class="far fa-video media-type"></i>
          <i *ngSwitchCase="'IMAGE'" class="far fa-camera media-type"></i>
          <i *ngSwitchCase="'CAROUSEL_ALBUM'" class="far fa-clone media-type"></i>
        </ng-container>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
  </nb-card-footer>
</nb-card>