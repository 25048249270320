import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-loading-box',
  styleUrls: ['./loading-box.component.scss'],
  template: `
    <div class="loading-box" [class.finished]="!loading">
      <ng-container *ngIf="loading; else content">
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
      </ng-container>
    </div>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class LoadingBoxComponent {
  private _loading = true;

  @Input()
  set loading(value: boolean) {
    this._loading = value;
  }

  get loading(): boolean {
    return this._loading;
  }
}
