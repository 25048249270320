<nb-card class="profile-engagement" size="large">
  <nb-card-header>
    <span>Engagement</span>
    <ngx-tooltip [tooltip]="tooltipMessages.get('engagement')"></ngx-tooltip>
  </nb-card-header>

  <ngx-loader *ngIf="!engagement" class="profile-card-placeholder"></ngx-loader>
  <nb-card-body *ngIf="engagement">
    <ngx-gauge *ngFor="let engagement of avgEngagementGaugeOptions" [gaugeOptions]="engagement" withBorder="true">
    </ngx-gauge>

    <div class="profile-engagement-info">
      <ul>
        <li class="title">
          <div>
            <i class="fa-regular fa-arrow-right-arrow-left af-icon"></i>
            <span class="af-heading-h2">Total Avg. Engagement</span>
            <i class="fa-regular fa-circle-question" [ngbPopover]="tooltipMessages.get('total-engagement')"></i>
          </div>
          <strong class="af-heading-h2">{{totalEngagement | formatNumber:2}}</strong>
        </li>
        <li>
          <div>
            <i class="fa-regular fa-thumbs-up af-icon"></i>
            <span class="af-body-2">Average Likes</span>
          </div>
          <strong class="af-heading-h2">{{engagement.avgLikesRatio | formatNumber:2}}</strong>
        </li>
        <li>
          <div>
            <i class="fa-regular fa-comments af-icon"></i>
            <span class="af-body-2">Average Comments</span>
          </div>
          <strong class="af-heading-h2">{{engagement.avgCommentsRatio | formatNumber:2}}</strong>
        </li>
      </ul>

      <ul *ngIf="engagement.estimatedReach">
        <li>
          <div>
            <i class="fa-regular fa-user-group af-icon"></i>
            <span class="af-body-2">{{engagement.areInsightsVerified ? 'Reach' : 'Estimated Reach'}}</span>
          </div>
          <strong class="af-heading-h2">{{engagement.estimatedReach | formatNumber:2}}</strong>

        </li>
      </ul>

      <ul *ngIf="engagement.estimatedImpressions">
        <li>
          <div>
            <i class="fa-regular fa-eye af-icon"></i>
            <span class="af-body-2">{{areInsightsVerified ? 'Impressions' : 'Estimated
              Impressions'}}</span>
          </div>
          <strong class="af-heading-h2">{{engagement.estimatedImpressions | formatNumber:2}}</strong>
        </li>
      </ul>
    </div>
  </nb-card-body>
</nb-card>