<div class="influencer-card">
  <div class="influencer-card-header">
    <form>
      <ai-checkbox class="col-xs" [label]="false" [ngModelOptions]="{standalone: true}" [ngModel]="selected"
        (change)="onSelect.emit($event)" [checked]="selected">
      </ai-checkbox>
    </form>
    <a class="{{profileNavigationLinkClass()}}" [href]="profileUrl" target="_blank">
      <ngx-user-tile [name]="name" [picture]="profilePictureUrl" [title]="username" [isRegistered]="isAffableVerified" [badgeSize]=badgeSize  [badgeOver]='badgeOver' [platform]='platform'>
      </ngx-user-tile>
      <img id="hidden_image" *ngIf="!isPotentialInfluencer || !isProfiling" [src]="profile"
        (error)="imageError.emit($event)" style="display: none;" />
    </a>
  </div>

  <div class="influencer-card-body">
    <div *ngIf="biography; else biographyMissingTemplate" class="influencer-biography">
      {{biography}}
    </div>
    <ng-template #biographyMissingTemplate>
      <div class="influencer-biography placeholder">
        biography not available<br>
        please check profile stats for more information
      </div>
    </ng-template>

  </div>

  <div class="influencer-card-footer">
    <div class="footer-center">
      <div class="icon-item">
        <div *ngIf="engagementRate && (!isYoutube || !isRestricted)">ER <strong>{{+engagementRate | number }}% </strong></div>

        <span *ngIf="matchRatio > 0 && showTargetedFollowersCount && sortInfluencersKey === 'relevance'"
          class="match-percentage">
          Match <strong>{{ (+matchRatio * 100).toFixed(0) | number}}%</strong> |</span>

        <div *ngIf="followers" class="icon-item">
          {{isSubscribers?'Subscribers':'Followers'}} <strong [ngbTooltip]="+followers | number">
            {{followers | shortNumber}} </strong>
          <i *ngIf="followersGrowth  ||followersGrowth" class="followers-growth far"
            [ngClass]="followersGrowth > 0 ? 'fa-caret-up' : 'fa-caret-down'">
            <strong> {{followersGrowth.toFixed(1)}}%</strong>
          </i>
        </div>

      </div>
      <div class="icon-item" *ngIf="showTargetedFollowersCount && (+audienceCount)">
        Targeted followers <strong>{{audienceCount | shortNumber}}</strong>
        <span *ngIf="audienceCount != null" class="match-percentage">
          Match <strong>{{ (+audienceCount * 100 / +followers).toFixed(0) |
            number}}%</strong>
        </span>
      </div>
    </div>
    <div *ngIf="markLocked" class="lock-icon mt-auto">
      <i class="far fa-lock-keyhole"></i>
    </div>
  </div>
</div>
