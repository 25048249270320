<div class="drop-down-item" [ngClass]="{ selected: selected && this.type === 'none' }">
  <div class="ai-py3 ai-ml10 ai-mr8" [ngClass]="{ 'ai-ml10': type === 'checkbox' }">
    <form [formGroup]="dropDownItem">
      <ai-checkbox
        key="dropdown"
        [label]="label"
        [labelSize]="labelSize"
        [labelWidth]="labelWidth"
        [type]="type"
        [value]="selected"
        [checked]="selected"
        [parent]="'dropdown'"
        (action)="select($event)"
        [image]="image"
        [imageStyle]="imageStyle"
        [subLabel]="subLabel"
        [suffix]="suffix"
        [labelType]="dropdownItemType"
        [isRegistered]="isRegistered"
      ></ai-checkbox>
    </form>
  </div>
</div>
