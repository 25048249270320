import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { merge } from 'lodash';

@Injectable({ providedIn: 'root' })
export class ModalService {
  DEFAULT_CONFIG: OverlayConfig = {
    hasBackdrop: true,
    panelClass: 'ai-modal',
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
  };
  overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  open<T>(component: ComponentType<T>, config?: OverlayConfig) {
    this.overlayRef = this.overlay.create(merge(this.DEFAULT_CONFIG, config || {}));
    const componentRef = this.overlayRef.attach(new ComponentPortal(component));
    this.overlayRef.backdropClick().subscribe(() => this.destroyoverlay());
    return { componentRef, overlayRef: this.overlayRef };
  }

  destroyoverlay(): void {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }
}
