import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { InfluencerMediaAPIResponse, InfluencerMediaItem } from '../../interfaces';

@Injectable()
export class MediaService {
  constructor(private http: HttpClient) {}

  getInfluencerMedia(username: string): Promise<InfluencerMediaItem[]> {
    return this.http
      .get(environment.api + `/api/users/media/${username}`)
      .toPromise()
      .then((json: InfluencerMediaAPIResponse) => {
        return json.mediaItems;
      });
  }
}
