import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Campaign, CampaignWithInfluencers, CampaignsService } from '../../@core/data/campaigns.service';
import { PlatformsUpperEnum } from '../../enums';
import { GroupInfo, GroupInfoWithInfluencers, GroupService } from '../../@core/data/group.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateGroupModalComponent } from '../group-modals/create-group-modal/create-group-modal.component';
import { CreateCampaignModalComponent } from '../create-campaign-modal/create-campaign-modal.component';
import utils from '../../@core/utils/utils';
import { map } from 'lodash';
import { FeatureFlagService } from '../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../@core/feature-flag/flags';

@Component({
  selector: 'ngx-add-to',
  styleUrls: ['./add-to.component.scss'],
  templateUrl: './add-to.component.html',
})
export class AddToComponent implements OnChanges, OnInit {
  @Input() platform: PlatformsUpperEnum;
  @Input() influencers: { username: string; influencerPk: string; profilePicture?: string; platform: string }[] = [];
  @Input() customSubmitLabel: string | undefined;
  @Input() creationSubLabel = 'New';
  @Input() firstTab?: tabEnum;
  @Output() onClose = new EventEmitter();

  currentTab: tabEnum;
  loading = false;
  campaigns: CampaignWithInfluencers[] = [];
  recentCampaign: CampaignWithInfluencers | undefined;
  hasMoreCampaigns = true;
  pageNumber = 0;
  groups: GroupInfoWithInfluencers[] = [];
  recentGroup: GroupInfo | undefined;
  selectedEntries: { [key: string]: boolean } = {};
  isRestricted = false;

  tabs: { name: string; icon: string; active: boolean }[] = [];

  get isGroup(): boolean {
    return this.currentTab === tabEnum.Group;
  }

  constructor(
    private campaignsService: CampaignsService,
    private groupService: GroupService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private featureFlagService: FeatureFlagService,
  ) {}
  async ngOnInit(): Promise<void> {
    this.isRestricted = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.APP_UPDATES);
    this.adjustTabOrder();
    if (this.influencers && this.influencers.length > 0) {
      this.platform = this.influencers?.[0]?.platform as PlatformsUpperEnum;
      if (this.firstTab === tabEnum.Campaign) {
        this.selectTab(tabEnum.Campaign);
      } else {
        this.selectTab(tabEnum.Group);
      }
    }
  }

  adjustTabOrder(): void {
    if (this.isRestricted) {
      this.tabs = [{ name: 'Group', icon: 'fa-regular fa-user-group', active: true }];
    } else {
      if (this.firstTab === tabEnum.Campaign) {
        this.tabs = [
          { name: 'Campaign', icon: 'fa-solid fa-group-arrows-rotate', active: true },
          { name: 'Group', icon: 'fa-regular fa-user-group', active: false },
        ];
        this.currentTab = tabEnum.Campaign;
      } else {
        this.tabs = [
          { name: 'Group', icon: 'fa-regular fa-user-group', active: true },
          { name: 'Campaign', icon: 'fa-solid fa-group-arrows-rotate', active: false },
        ];
        this.currentTab = tabEnum.Group;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const influencers = changes?.influencers?.currentValue;
    if (influencers?.length === changes?.influencers?.previousValue?.length) {
      let isSame = true;
      changes?.influencers?.previousValue?.every((element) => {
        isSame = isSame && !!influencers.find((inf) => inf?.influencerPk === element?.influencerPk);
        if (!isSame) return false;
        return true;
      });
      if (isSame) return;
    }
    if (influencers?.length === 1 && influencers?.[0]?.platform !== this.platform) {
      this.platform = influencers?.[0]?.platform;
      this.selectTab(tabEnum.Group);
    } else if (influencers?.length >= 1 && influencers?.[0]?.platform === this.platform) {
      this.selectTab(this.currentTab);
    }
  }

  selectTab(tab: tabEnum): void {
    this.currentTab = tab;
    this.tabs = this.tabs.map((v) => ({ ...v, active: tab === v.name }));
    this.loading = true;
    switch (this.currentTab) {
      case tabEnum.Group:
        this.resetGroups();
        break;
      case tabEnum.Campaign:
        this.resetCampaigns();
        break;
    }
  }

  resetGroups(): void {
    this.loading = true;
    this.selectedEntries = {};
    this.groups = [];
    this.recentGroup = undefined;
    this.fetchGroups();
  }

  resetCampaigns(): void {
    this.loading = true;
    this.selectedEntries = {};
    this.campaigns = [];
    this.recentCampaign = undefined;
    this.hasMoreCampaigns = false;
    this.pageNumber = 0;
    this.fetchCampaigns();
  }

  async fetchCampaigns(): Promise<void> {
    if (!this.platform) {
      return;
    }
    this.campaignsService
      .getCampaignInfosWithInfluencers(
        this.pageNumber,
        utils.findMatchedPlatform(this.platform),
        map(this.influencers, 'influencerPk'),
        map(this.influencers, 'username'),
      )
      .then((response) => {
        if (!this.recentCampaign) {
          this.recentCampaign = response?.campaigns?.shift();
        }
        this.campaigns = [...this.campaigns, ...(response?.campaigns || [])];
        this.pageNumber = this.pageNumber + 1;
        this.hasMoreCampaigns = response.hasMoreCampaigns;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  async fetchGroups(): Promise<void> {
    if (!this.platform) {
      return;
    }
    this.groups =
      (await this.groupService.getAllGroupInfoWithInfluencers(
        map(this.influencers, 'influencerPk'),
        utils.findMatchedPlatform(this.platform as string),
      )) || [];
    this.groups.forEach((group) => (group['selected'] = false));
    this.recentGroup = this.groups?.shift();
    this.loading = false;
  }

  async onScroll(): Promise<void> {
    if (this.loading || !this.hasMoreCampaigns || this.currentTab !== 'Campaign') {
      return;
    }

    this.loading = true;
    await this.fetchCampaigns();
  }

  async createNew(): Promise<void> {
    switch (this.currentTab) {
      case tabEnum.Group: {
        const createGroupModal = this.modalService.open(CreateGroupModalComponent, {
          centered: true,
          windowClass: 'modal-md',
          backdrop: 'static',
        });
        const modalContent = createGroupModal.componentInstance;
        modalContent.platform = utils.findMatchedPlatform(this.platform);
        modalContent.influencersUsernames = this.influencers.map(({ influencerPk }) => influencerPk);
        createGroupModal.componentInstance.groupCreatedEvent.subscribe(() => {
          this.selectTab(tabEnum.Group);
        });
        break;
      }
      case tabEnum.Campaign: {
        const selectedInfluencers = this.influencers;
        const createCampaignModal = this.modalService.open(CreateCampaignModalComponent, {
          windowClass: 'modal-md',
          backdrop: 'static',
          centered: true,
        });
        createCampaignModal.result.then((campaign: Campaign) => {
          if (campaign) {
            this.addInfluencersToCampaign(campaign, selectedInfluencers);
            this.selectTab(tabEnum.Group);
          }
        });
        const modalContent = createCampaignModal.componentInstance;
        modalContent.platform = utils.findMatchedPlatform(this.platform);
        break;
      }
    }
    this.onClose.emit();
  }

  async addToExisting(): Promise<void> {
    const pks = this.influencers.map(({ influencerPk }) => influencerPk);
    switch (this.currentTab) {
      case tabEnum.Group:
        this.groupService
          .addInfluencersToGroups(Object.keys(this.selectedEntries), pks, utils.findMatchedPlatform(this.platform))
          .then(() => {
            this.resetGroups();
            this.toastrService.success(`${pks.length} Influencers added to the group(s)`);
          })
          .catch((err: HttpErrorResponse) => {
            if (err.status === 429) {
              this.toastrService.error(
                `Exceeded grouped influencers limit. Delete groups or remove grouped influencers and try again.`,
              );
            } else {
              this.toastrService.error(`Something went wrong while adding influencers to group(s). Try again.`);
            }
          });
        break;
      case tabEnum.Campaign:
        this.campaignsService
          .addInfluencersToCampaigns(
            Object.keys(this.selectedEntries),
            this.influencers.map(({ username }) => username),
            utils.findMatchedPlatform(this.platform),
          )
          .then(() => {
            this.resetCampaigns();
            this.toastrService.success(this.influencers?.length + ' Influencers added to the campaign(s)');
          })
          .catch((res) => {
            this.toastrService.error(
              res.error?.message || `Something went wrong while adding influencers to campaign(s). Try again.`,
            );
          });
        break;
    }
    this.onClose.emit();
  }

  addInfluencersToCampaign(
    campaign: Campaign,
    selectedInfluencers: { username: string; influencerPk: string; profilePicture?: string; platform: string }[],
  ): void {
    const campaignSlug = campaign.campaignSlugName;
    const selectedInfluencersUsernames = selectedInfluencers.map(({ username }) => username);
    if (selectedInfluencersUsernames?.length > 0) {
      this.campaignsService
        .addInfluencersToCampaign(campaignSlug, selectedInfluencersUsernames, utils.findMatchedPlatform(this.platform))
        .then(() => {
          this.toastrService.success(selectedInfluencersUsernames?.length + ' Influencers added to the campaign');
        })
        .catch((res) => {
          this.toastrService.error(
            res.error?.message || `Something went wrong while adding influencers to campaign(s). Try again.`,
          );
        });
    }
  }

  entryChecked(entry: GroupInfoWithInfluencers | CampaignWithInfluencers): void {
    if (this.isGroup) {
      const group = entry as GroupInfoWithInfluencers;
      if (this.selectedEntries[group.slugName]) {
        delete this.selectedEntries[group.slugName];
      } else {
        this.selectedEntries[group.slugName] = true;
      }
    } else {
      const campaign = entry as Campaign;
      if (this.selectedEntries[campaign.campaignSlugName]) {
        delete this.selectedEntries[campaign.campaignSlugName];
      } else {
        this.selectedEntries[campaign.campaignSlugName] = true;
      }
    }
  }

  getSelectedInfluencerProfilePicture(selectedInfluencer: string): string | undefined {
    return this.influencers.find((inf) => inf['influencerPk'] === selectedInfluencer)?.['profilePicture'];
  }

  getSubmitLabel(): string {
    if (this.customSubmitLabel) {
      return this.customSubmitLabel;
    }

    const selectedEntriesLength = Object.keys(this.selectedEntries).length;
    return `ADD TO ${
      selectedEntriesLength > 0 ? '(' + selectedEntriesLength + ') ' : ''
    }${this.currentTab.toUpperCase()}${selectedEntriesLength > 1 ? 'S' : ''}`;
  }

  isSubmitDisabled(): boolean {
    return Object.keys(this.selectedEntries).length <= 0;
  }

  getInfluencersInEntry(
    entry: CampaignWithInfluencers | GroupInfoWithInfluencers,
  ): { username: string; influencerPk: string; profilePicture?: string; platform: string }[] {
    const influencers: { username: string; influencerPk: string; profilePicture?: string; platform: string }[] = [];
    entry?.influencers?.forEach((id) => {
      const inf = this.influencers.find((influencer) => influencer.influencerPk === id);
      if (inf) influencers.push(inf);
    });
    if (!this.isGroup) {
      const campaign = entry as CampaignWithInfluencers;
      if (!campaign?.pendingInfluencers) return influencers;
      campaign?.pendingInfluencers?.forEach((username) => {
        const inf = this.influencers.find((influencer) => influencer.username === username);
        if (inf) influencers.push(inf);
      });
    }
    return influencers;
  }
  getRemainingInfluencersUsername(entry: CampaignWithInfluencers | GroupInfoWithInfluencers): string[] {
    const influencers = this.getInfluencersInEntry(entry);
    return influencers?.slice(3)?.map((influencer) => {
      return influencer.username;
    });
  }
}
export enum tabEnum {
  Group = 'Group',
  Campaign = 'Campaign',
}
