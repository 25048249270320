/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { LoginErrorModalComponent } from '../login-error-modal/login-error-modal.component';
import { UserCredentials } from '../../../../../enums';

@Component({
  selector: 'ngx-public-app-login',
  templateUrl: './public-app-login.component.html',
  styleUrls: ['./public-app-login.component.scss'],
})
export class PublicAppLoginComponent implements OnInit {
  shop: string;
  email: string;
  storeName: string;
  exist: boolean;
  credentials: UserCredentials;
  showPassword = false;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.shop = this.route.snapshot.queryParamMap.get('shop');
    this.storeName = this.route.snapshot.queryParamMap.get('storeName');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.exist = this.route.snapshot.queryParamMap.get('exist') === 'true';
    this.credentials = new UserCredentials(this.email);
    this.loading = false;
  }

  async login() {
    if (!this.credentials.email || !this.credentials.password) return;

    this.loading = true;
    await this.authService
      .login(this.credentials.email, this.credentials.password, !this.exist && this.shop)
      .catch((error) => {
        this.loading = false;
        const loginErrorModal = this.modalService.open(LoginErrorModalComponent, {
          size: 'lg',
          centered: true,
        }).componentInstance;
        loginErrorModal.errorCode = error.message;
      });

    this.router.navigateByUrl('/subscription');
  }

  redirectToSignup() {
    const queryParams = { shop: this.shop, storeName: this.storeName, email: this.email };
    return this.router.navigate(['auth', 'signup'], { queryParams });
  }

  redirectToResetPassword() {
    const queryParams = { shop: this.shop, storeName: this.storeName, email: this.email };
    return this.router.navigate(['auth', 'public', 'request-password'], { queryParams });
  }
}
