import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { FacebookContentSortKey, InstagramContentSortKey, LogicalFilterType, YoutubeContentSortKey } from '../../enums';
import {
  BrandLookup,
  GroupsWithLogic,
  HashtagWithLogic,
  InfluencerWithLogic,
  YoutubeInfluencerWithLogic,
} from '../../interfaces';
import { Location } from './location.service';

@Injectable()
export class ContentFilterService {
  private contentFilters = new Subject<InstagramContentFilters>();
  contentFilters$ = this.contentFilters.asObservable();
  private facebookContentFilters = new Subject<FacebookContentFilters>();
  facebookContentFilters$ = this.facebookContentFilters.asObservable();
  private youtubeContentFilters = new Subject<YoutubeContentFilters>();
  youtubeContentFilters$ = this.youtubeContentFilters.asObservable();
  private recommendedInterests = new Subject<string[]>();
  recommendedInterests$ = this.recommendedInterests.asObservable();

  setInstagramFilters(filters: InstagramContentFilters, onlyTopContent = false): void {
    filters.onlyTopContent = onlyTopContent;
    this.contentFilters.next(filters);
  }

  setFacebookFilters(filters: FacebookContentFilters, onlyTopContent = false): void {
    filters.onlyTopContent = onlyTopContent;
    this.facebookContentFilters.next(filters);
  }

  setYoutubeFilters(filters: YoutubeContentFilters, onlyTopContent = false): void {
    filters.onlyTopContent = onlyTopContent;
    this.youtubeContentFilters.next(filters);
  }

  setRecommendedInterests(interests: string[]): void {
    this.recommendedInterests.next(interests);
  }
}

export class ContentTimeFilter {
  to: Date;
  from: Date;
  timezone: string;

  constructor(offset = 365) {
    this.to = new Date();
    this.from = moment(this.to).subtract(offset, 'days').toDate();
    this.timezone = Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC';
  }
}

export class InstagramContentFilters {
  time = new ContentTimeFilter();
  locations: Location[] = [];
  hashtags: HashtagWithLogic[] = [];
  sponsored = false;
  interests: string[] = [];
  brands: BrandLookup[] = [];
  influencers: InfluencerWithLogic[] = [];
  sortKey: InstagramContentSortKey = 'recency';
  onlyTopContent? = false;
  groupNames?: string[] = [];
  excludeGroupInfluencers?: boolean = false;
  followers = new Followers();
  searchModifiers: SearchModifiers = new SearchModifiers();
  groupsWithLogic?: GroupsWithLogic[];
}
export class Followers {
  min = 5000;
  max = 1000000;
}

export class FacebookContentFilters {
  time = new ContentTimeFilter();
  hashtags: HashtagWithLogic[] = [];
  locations: Location[] = [];
  influencers: InfluencerWithLogic[] = [];
  groups: GroupsWithLogic[] = [];
  sortKey: FacebookContentSortKey = 'recency';
  onlyTopContent? = false;
  followers = new Followers();
  searchModifiers: SearchModifiers = new SearchModifiers();
}

export class YoutubeContentFilters {
  time = new ContentTimeFilter();
  locations: Location[] = [];
  hashtags: HashtagWithLogic[] = [];
  interests: string[] = [];
  influencerIds: YoutubeInfluencerWithLogic[] = [];
  groups: GroupsWithLogic[] = [];
  sortKey: YoutubeContentSortKey = 'recency';
  onlyTopContent? = false;
  subscribers? = new Followers();
  searchModifiers: SearchModifiers = new SearchModifiers();
}

export class SearchModifiers {
  interests: LogicalFilterType = 'AND';
  hashtags: LogicalFilterType = 'AND';
  brands: LogicalFilterType = 'AND';
  keywords: LogicalFilterType = 'AND';
  biographyKeywords: LogicalFilterType = 'AND';
  languages: LogicalFilterType = 'AND';
}
