<div class="form-group">
    <label class="filter-label">Age Ranges</label>
    <div class="form-row">
        <ng-container *ngIf="ageGroups">
            <nb-checkbox *ngFor="let ageGroup of labeledAgeGroups; let i = index" [(ngModel)]="ageGroups[i]"
                class="col age-checkbox" (change)="triggerUpdateEvent()">
                {{ageGroup.label}}
            </nb-checkbox>
        </ng-container>
        <ng-container *ngIf="!ageGroups">
            <nb-checkbox *ngFor="let ageGroup of labeledAgeGroups" [(ngModel)]="ageGroup.selected"
                class="col age-checkbox" (change)="triggerUpdateEvent()">
                {{ageGroup.label}}
            </nb-checkbox>
        </ng-container>
    </div>
</div>