import { Injectable } from '@angular/core';
import {
  Group,
  GroupInfluencer,
  GroupInfluencers,
  GroupInfo,
  GroupService,
  StandardInfluencer,
} from '../../@core/data/group.service';
import { Platform, PlatformsUpperEnum } from '../../enums';
import {
  YoutubeInfluencer,
  FacebookInfluencer,
  TiktokInfluencer,
  TwitterInfluencer,
  InstagramInfluencer,
  InfluencerLookup,
} from '../../interfaces';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProfileInfluencersModalComponent } from '../../pages/discovery/profile-influencers-modal/profile-influencers-modal.component';
import utils from '../../@core/utils/utils';
import { InfluencerProfileCard } from '../influencer-profile-card/influencer-profile-card.component';
import { InstagramProfileService } from '../../@core/data/instagram/instagram-profile.service';
import { AudienceEstimateModalComponent } from '../../pages/instagram/instagram-discovery/instagram-influencers/audience-estimate-modal/audience-estimate-modal.component';
import { UserService } from '../../@core/data/user.service';
import { AuthService } from '../../@core/auth/auth.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { InfluencerSearchFilterService } from '../../@core/data/influencer-search-filter.service';
import { CreateGroupModalComponent } from '../group-modals/create-group-modal/create-group-modal.component';
import { FacebookService } from '../../@core/data/facebook/facebook.service';
import { InstagramDiscoveryService } from '../../@core/data/instagram/instagram-discovery.service';
import { TiktokService } from '../../@core/data/tiktok/tiktok.service';
import { TwitterService } from '../../@core/data/twitter/twitter.service';
import { YoutubeService } from '../../@core/data/youtube/youtube.service';
import { isEmpty } from 'lodash';
import { EditGroupModalComponent } from '../group-modals/edit-group-modal/edit-group-modal.component';
import { ShareGroupModalComponent } from '../group-modals/share-group-modal/share-group-modal.component';
import { NewChatComponent } from '../../chat/new-chat/new-chat.component';
import {
  ListViewCardContent,
  ListViewCardModalComponent,
} from '../list-view-card-modal/list-view-card-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ResourceAccessControlService, ResourceType } from '../../@core/data/resource-access-control.service';
import { IResourceAccessControl } from '../../interfaces/resource-access-control.interface';
export type GroupWithRac = Group & { rac: IResourceAccessControl[] };

@Injectable({
  providedIn: 'root',
})
export class InfluencerGroupHelperService {
  constructor(
    private groupService: GroupService,
    private modalService: NgbModal,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private twitterService: TwitterService,
    private youtubeService: YoutubeService,
    private facebookService: FacebookService,
    private tiktokService: TiktokService,
    private instagramDiscoveryService: InstagramDiscoveryService,
    private instagramProfileService: InstagramProfileService,
    private searchFilterService: InfluencerSearchFilterService,
    private toasterService: ToastrService,
    private resourceAccessControlService: ResourceAccessControlService,
  ) {}

  async sendMail(
    platform: PlatformsUpperEnum,
    selectedInfluencers: InfluencerLookup[],
    influencerEmailSetter: (updatedInfluencer: InfluencerLookup) => void,
  ): Promise<void> {
    const platformV2 = utils.convertToPlatformV2(platform);
    if (!this.authService.isMessagingEnabled()) {
      return this.openMessagingProErrorModal();
    }

    const newChatModalInstance = this.openModalWithContent(
      NewChatComponent,
      { size: 'lg' },
      { platform: platformV2, selectedInfluencers, influencerEmailSetter },
    );

    const newChatModalInstanceResult = await newChatModalInstance.result;
    if (newChatModalInstanceResult?.status?.success || newChatModalInstanceResult?.influencers?.status?.success) {
      this.router.navigate([`/pages/${platformV2.charAt(0).toLowerCase() + platformV2.slice(1)}/chat`]);
    }
  }

  public getRac(groupSlugName: string): Promise<IResourceAccessControl[]> {
    return this.resourceAccessControlService.getAccessControlList({
      resourceId: groupSlugName,
      resourceType: ResourceType.group,
    });
  }

  public async getGroup(groupSlugName: string, platform: PlatformsUpperEnum): Promise<GroupWithRac | null> {
    const [groupInfo, rac] = await Promise.all([
      this.groupService.getGroup(groupSlugName, utils.convertToPlatformV2(platform)),
      this.getRac(groupSlugName),
    ]);
    if (!groupInfo) return null;

    return { ...groupInfo, rac };
  }

  public getGroupInfluencers(
    groupSlugName: string,
    platform: PlatformsUpperEnum,
    page: number,
    limit: number,
    filter: Record<string, string[]> = {},
    search?: string,
    sortBy?: 'influencer' | 'followers' | 'engagementRatio',
    sortDirection?: 'asc' | 'desc',
  ): Promise<{ data: GroupInfluencers; totalCount: number }> {
    return this.groupService.getGroupInfluencersV2(
      groupSlugName,
      utils.convertToPlatformV2(platform),
      page,
      limit,
      filter,
      search,
      sortBy,
      sortDirection,
    );
  }

  public removeInfluencerFromGroupById(
    group: GroupInfo,
    influencerIds: string[],
    platform: PlatformsUpperEnum,
    allUsers: boolean,
  ): Promise<void> {
    return this.groupService.removeInfluencerFromGroupById(
      group,
      influencerIds,
      utils.convertToPlatformV2(platform),
      allUsers,
    );
  }

  public openCreateGroupModal(platform: PlatformsUpperEnum): void {
    this.openModalWithContent(
      CreateGroupModalComponent,
      { windowClass: 'modal-md' },
      { platform: utils.findMatchedPlatform(platform) },
    );
  }

  public async openDeleteGroupModal(group: GroupInfo, platform: PlatformsUpperEnum): Promise<void> {
    const searchFiltersAssociatedWithGroup = this.getSearchFiltersAssociatedWithGroup(group.slugName);
    const modalRef = this.openModalWithContent(
      MessageModalComponent,
      { windowClass: 'modal-md' },
      this.createDeleteGroupModalContent(searchFiltersAssociatedWithGroup),
    );
    const response = await modalRef.result;
    if (response) {
      await this.handleDeleteGroupAction(group, platform, !!searchFiltersAssociatedWithGroup.length);
    }
  }

  private getSearchFiltersAssociatedWithGroup(groupSlugName: string): string[] {
    return this.searchFilterService.getFiltersByGroupName(groupSlugName).map((search) => search.filterName);
  }

  private createDeleteGroupModalContent(searchFiltersAssociatedWithGroup: string[]): { [key: string]: any } {
    const content = {
      headerText: 'Confirm Delete Group',
      buttonText: 'DELETE GROUP',
      centerContent: false,
      buttonClass: 'secondary',
    };
    if (searchFiltersAssociatedWithGroup.length) {
      return {
        ...content,
        contentTextList: searchFiltersAssociatedWithGroup,
        contentText: `This group is included in saved search filters. Deleting this will delete saved searches too. Do you want to proceed?`,
        listItemClass: 'username',
      };
    }
    return { ...content, contentText: 'Are you sure you want to delete this group?' };
  }

  private async handleDeleteGroupAction(
    group: GroupInfo,
    platform: PlatformsUpperEnum,
    areSearchFiltersAssociatedWithGroup: boolean,
  ): Promise<void> {
    try {
      await this.groupService.deleteGroup(
        group,
        utils.convertToPlatformV2(platform),
        areSearchFiltersAssociatedWithGroup ? 'true' : 'false',
      );
      this.groupService.groupDeletedEvent(group);
      this.toasterService.success('Successfully Deleted Group');
    } catch (error) {
      this.toasterService.error(error.error.message);
    }
  }

  public async openProfileInfluencersModal(
    groupInfo: GroupInfo,
    platform: PlatformsUpperEnum,
  ): Promise<GroupInfo | void> {
    const modalRef = this.openModalWithContent(
      ProfileInfluencersModalComponent,
      { size: 'lg' },
      { selectedGroupProps: groupInfo, platform: utils.convertToPlatformV2(platform) },
    );
    try {
      const groupSlug = await modalRef.result;
      if (groupSlug === groupInfo.slugName) {
        this.groupService.sendGroupFilterInfuencersChangedEvent(groupInfo);
        return groupInfo;
      }
    } catch (error) {
      console.error('Error while opening profile influencers modal:', error);
    }
  }

  public async estimateAudienceInsights(usernames: string[]): Promise<void> {
    const { inProgressUsernames, availableUsernames, failedUsernames } =
      await this.instagramProfileService.bulkAnalyzeAudience(usernames);
    this.openAudienceAnalyticsModal(inProgressUsernames, availableUsernames, failedUsernames);
  }

  private async openAudienceAnalyticsModal(
    inProgressProfileUsernames: string[],
    availableProfileUsernames: string[],
    failedProfileUsernames: string[],
  ): Promise<void> {
    const modalRef = this.openModalWithContent(
      AudienceEstimateModalComponent,
      { size: 'lg' },
      { availableProfileUsernames, inProgressProfileUsernames, failedProfileUsernames },
    );
    const res = await modalRef.result;
    if (res && res.length) {
      this.estimateAudienceInsights(res);
    }
  }

  public async exportInfluencers(
    platform: PlatformsUpperEnum,
    usernames: string[],
    groupSlugName: string,
    groupName: string,
    allGroupInfluencersSelected = false,
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (!this.isExportsEnabled) {
        this.openExportProErrorModal();
        reject();
        return;
      }
      this.toasterService.info(`Downloading CSV!`, 'Please wait...', {
        progressBar: true,
        progressAnimation: 'increasing',
        disableTimeOut: true,
      });
      this.exportInfluencersFromSearch(platform, usernames, groupSlugName, allGroupInfluencersSelected)
        .then((blob) => {
          saveAs(blob, groupName ? `Influencer List - ${groupName}.csv` : `Influencer List.csv`);
          resolve(blob);
        })
        .catch(reject)
        .finally(() => this.toasterService.clear());
    });
  }

  get isExportsEnabled(): boolean {
    return this.authService.isExportsEnabled();
  }

  private async exportInfluencersFromSearch(
    platform: PlatformsUpperEnum,
    usernames: string[],
    groupSlugName: string,
    allGroupInfluencersSelected = false,
  ): Promise<Blob> {
    switch (platform) {
      case PlatformsUpperEnum.YOUTUBE:
        return this.youtubeService.exportInfluencersFromSearch(
          usernames,
          undefined,
          groupSlugName,
          allGroupInfluencersSelected,
        );
      case PlatformsUpperEnum.FACEBOOK:
        return this.facebookService.exportInfluencersFromSearch(
          usernames,
          undefined,
          groupSlugName,
          allGroupInfluencersSelected,
        );
      case PlatformsUpperEnum.TIKTOK:
        return this.tiktokService.exportInfluencersFromSearch(
          usernames,
          undefined,
          groupSlugName,
          allGroupInfluencersSelected,
        );
      case PlatformsUpperEnum.TWITTER:
        return this.twitterService.exportInfluencersFromSearch(
          usernames,
          undefined,
          groupSlugName,
          allGroupInfluencersSelected,
        );
      case PlatformsUpperEnum.VERAINSTAGRAM:
      case PlatformsUpperEnum.INSTAGRAM:
        return this.instagramDiscoveryService.exportInfluencers(
          usernames,
          undefined,
          groupSlugName,
          allGroupInfluencersSelected,
        );
      default:
        throw new Error(`Unsupported platform: ${platform}`);
    }
  }

  private async openExportProErrorModal(): Promise<void> {
    const modalRef = this.openModalWithContent(
      MessageModalComponent,
      { size: 'lg' },
      {
        headerText: 'Export Feature is only for PRO subscribers',
        contentText: 'Upgrade your subscription to PRO to enjoy full benefits of exporting the data.',
        imageURL: '../../../../../assets/images/search-csv-export.gif',
        imageClass: 'export-modal-image',
        buttonText: 'Upgrade to PRO',
      },
    );
    const res = await modalRef.result;
    if (res) {
      this.router.navigate(['/pages/account']);
    }
  }

  private async openMessagingProErrorModal(): Promise<void> {
    const modalRef = this.openModalWithContent(
      MessageModalComponent,
      { size: 'lg' },
      {
        headerText: 'Messaging feature is available as an add on only for PRO users.',
        contentText: 'Reach out to us to enable messaging and we can work it out :)',
        imageURL: `../../../../assets/images/demo-messaging.gif`,
        imageClass: 'messaging-demo',
        buttonText: 'CONTACT US',
      },
    );
    const modalResult = await modalRef.result;
    if (modalResult) {
      window.location.href = 'mailto:nisarg@affable.ai?subject=Enabling Messaging Feature on Affable';
    }
  }

  async openEditGroupModal(
    group: GroupInfo,
    platform: PlatformsUpperEnum,
  ): Promise<{ isSuccess: boolean; isModified: boolean; data?: GroupInfo }> {
    const modalRef = this.openModalWithContent(EditGroupModalComponent, { windowClass: 'modal-md' }, { group });
    const result = await modalRef.result;
    const isModified =
      result && (group.name !== result?.groupName || JSON.stringify(group.tags) !== JSON.stringify(result.groupTags));

    if (isModified) {
      const response = await this.groupService.updateGroup(group.slugName, platform, {
        groupName: result.groupName,
        tags: result.groupTags,
      });
      response?.data?.slugName && this.groupService.groupUpdatedEvent(response.data);
      return { isModified, isSuccess: response?.success, data: response?.data };
    }

    return { isSuccess: true, isModified };
  }

  async openShareGroupModal(group: GroupInfo, platform: PlatformsUpperEnum): Promise<{ success: boolean }> {
    const modalRef = this.openModalWithContent(
      ShareGroupModalComponent,
      { windowClass: 'modal-md' },
      { group, platform: utils.convertToPlatformV2(platform) },
    );
    return modalRef.result;
  }

  async openExcludedInfluencersModal(group: Group, platform: PlatformsUpperEnum) {
    const privateInf: ListViewCardContent = {
      name: 'Private Influencers',
      data: group.excludedInfluencerUsernames,
      tooltip: ['Influencer profiles are private.', 'Unable to access influencer information'],
    };
    const pendingInf: ListViewCardContent = {
      name: 'Pending Influencers',
      data: group.influencersToBeProfiled,
      tooltip: [
        'Influencer profiles are currently being processed.',
        'It may take some time to fetch their information.',
      ],
    };
    const contents = [
      ...(privateInf.data && privateInf.data.length > 0 ? [privateInf] : []),
      ...(pendingInf.data && pendingInf.data.length > 0 ? [pendingInf] : []),
    ];
    const modalRef = this.openModalWithContent(
      ListViewCardModalComponent,
      { windowClass: 'modal-md' },
      { title: `Excluded Influencers`, contents },
    );
    const result: ListViewCardContent[] = await modalRef.result;
    if (result) {
      const newPrivateInf = result.find((res) => res.name === 'Private Influencers');
      const newPendingInf = result.find((res) => res.name === 'Pending Influencers');
      this.groupService
        .updateGroup(group.slugName, platform, {
          groupName: group.name,
          excludedInfluencerUsernames: newPrivateInf ? newPrivateInf.data : [],
          influencersToBeProfiled: newPendingInf ? newPendingInf.data : [],
        })
        .then(() => {
          this.toasterService.success('Successfully updated group');
          group.excludedInfluencerUsernames = newPrivateInf ? newPrivateInf.data : [];
          group.influencersToBeProfiled = newPendingInf ? newPendingInf.data : [];
        })
        .catch(() => {
          this.toasterService.error('Something went wrong while updating group. Please try again.');
        });
    }
  }

  private openModalWithContent(component, options: NgbModalOptions, content: { [key: string]: any } = {}): NgbModalRef {
    options = { ...options, centered: true, backdrop: 'static' };
    const modal = this.modalService.open(component, options);
    if (!isEmpty(content)) {
      Object.assign(modal.componentInstance, content);
    }
    return modal;
  }
}

export function getInfluencerProfileCardInfo(
  influencer: GroupInfluencer,
  platform: PlatformsUpperEnum,
): InfluencerProfileCard {
  const standardInfluencer = transformToStandardInfluencer(influencer, platform);
  return {
    name: standardInfluencer.name ?? '',
    username: standardInfluencer.username,
    profilePictureUrl: standardInfluencer.profilePicture ?? '',
    platform: platformForProfileCard(platform),
  };
}

export function platformForProfileCard(platform: PlatformsUpperEnum): Platform {
  return platform === PlatformsUpperEnum.VERAINSTAGRAM ? 'instagram' : (platform.toLowerCase() as Platform);
}

export function transformToStandardInfluencer(
  influencer: GroupInfluencer,
  platform: PlatformsUpperEnum,
): StandardInfluencer {
  switch (platform) {
    case PlatformsUpperEnum.YOUTUBE: {
      const youtubeInfluencer = influencer as YoutubeInfluencer;
      return {
        email: youtubeInfluencer.email,
        influencerPk: youtubeInfluencer.id,
        name: youtubeInfluencer.name,
        username: youtubeInfluencer.username,
        profilePicture: youtubeInfluencer.profilePictureUrl,
        platform: PlatformsUpperEnum.YOUTUBE,
      };
    }
    case PlatformsUpperEnum.FACEBOOK: {
      const facebookInfluencer = influencer as FacebookInfluencer;
      return {
        email: facebookInfluencer.email,
        influencerPk: facebookInfluencer.id.toString(),
        name: facebookInfluencer.name,
        username: facebookInfluencer.username,
        profilePicture: facebookInfluencer.profilePictureUrl,
        platform: PlatformsUpperEnum.FACEBOOK,
      };
    }
    case PlatformsUpperEnum.TIKTOK: {
      const tiktokInfluencer = influencer as TiktokInfluencer;
      return {
        email: tiktokInfluencer.email,
        influencerPk: tiktokInfluencer.id || '',
        name: tiktokInfluencer.name,
        username: tiktokInfluencer.username,
        profilePicture: tiktokInfluencer.profilePictureUrl,
        platform: PlatformsUpperEnum.TIKTOK,
      };
    }
    case PlatformsUpperEnum.TWITTER: {
      const twitterInfluencer = influencer as TwitterInfluencer;
      return {
        email: twitterInfluencer.email,
        influencerPk: twitterInfluencer.id,
        name: twitterInfluencer.name,
        username: twitterInfluencer.username,
        profilePicture: twitterInfluencer.profilePictureUrl,
        platform: PlatformsUpperEnum.TWITTER,
      };
    }
    case PlatformsUpperEnum.INSTAGRAM:
    case PlatformsUpperEnum.VERAINSTAGRAM: {
      const instagramInfluencer = influencer as InstagramInfluencer;
      return {
        email: instagramInfluencer.email,
        influencerPk: instagramInfluencer.id,
        name: instagramInfluencer.fullName,
        username: instagramInfluencer.username,
        profilePicture: instagramInfluencer.profilePicture,
        platform: PlatformsUpperEnum.INSTAGRAM,
      };
    }
    default:
      throw new Error(`Unsupported platform: ${platform}`);
  }
}
