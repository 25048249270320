import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-audience-gender-distribution-card',
  templateUrl: './audience-gender-distribution-card.component.html',
  styleUrls: ['./audience-gender-distribution-card.component.scss'],
})
export class AudienceGenderDistributionCardComponent {
  @Input() genderDataOptions;
  @Input() tooltipMessage: string;
  @Input() influencerUsername: string;

  showGenderDistribution(): boolean {
    return this.genderDataOptions?.series?.length > 0;
  }
}
