/**
 * This allows optional chaining in ts files for lower versions with a fallback value
 * @param fn
 * @param fallback
 * @returns safely emitted value
 */

export const tryGet = <T>(fn: () => T, fallback?: T): T => {
  try {
    return typeof fallback !== 'undefined' && fallback !== null ? fn() || fallback : fn();
  } catch (error) {
    if (error instanceof ReferenceError) {
      return fallback;
    }
    return fallback || null;
  }
};

/**
 * This escapes , ', (, ), and * in text passed as params to router.navigate to prevent treating them as routes. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986
 * @param text param text to escape
 * @returns escaped string
 */
export const escapeRouteParamsText = (text: string): string => {
  return text.replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`); // no need to encodeUriComponent, browser already encodes
};
