import { EventEmitter, Input, Output } from '@angular/core';
import { DropDownOption } from '../../control.interface';
import { DropDownAction, DropDownSize } from '../../common.enum';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { Typography } from '../../common.enum';

@Component({
  template: '',
})
export class DropDownOverlayBaseComponent {
  @Input() options: DropDownOption;
  @Input() multi = true;
  // stateless @Input is used to control the state of the dropdown
  @Input() stateless = false;
  @Output() action: EventEmitter<DropDownAction> = new EventEmitter();
  public overlayRef: OverlayRef;
  @Input() size: DropDownSize;
  @Input() emptyDisplay: string = null;
  @Input() customWidth: number = null;
  @Input() groupNameTypo: Typography = 'caption';
  @Input() shiftSelectedToTop: boolean = false;
  @Input() dropdownItemType: 'singleLine' | 'doubleLine' = 'singleLine';
  @Input() closeOnSelect = false;

  get classes() {
    return [`ai-py12`, `aiDrop`, `aiDrop-${this.size}`];
  }

  getEntriesLength(): number {
    return this.options.groupOptions.reduce((total, group) => total + group.items.length, 0);
  }
  getCustomWidthStyle(): String {
    return this.customWidth && `min-width:${this.customWidth}px`;
  }
  getDropDownValue(action): void {
    if (!this.multi) {
      this.overlayRef.detach();
      if (this.options.groupOptions.length === 1) {
        this.options.groupOptions[0].items = this.options.groupOptions[0].items.map((obj) => {
          if (!this.stateless) {
            return obj.key === action.key
              ? { ...obj, value: true, disabled: true }
              : { ...obj, value: false, disabled: false };
          } else {
            return obj.key === action.key ? { ...obj, value: true } : { ...obj, value: false };
          }
        });
      } else {
        // TODO: need to check if the group is disabled !
        for (const [, groupOption] of this.options.groupOptions.entries()) {
          groupOption.items = groupOption.items.map((obj) =>
            obj.key === action.key ? { ...obj, value: true } : { ...obj, value: false },
          );
        }
      }
    } else {
      if (this.closeOnSelect) this.overlayRef.detach();
      if (this.options.groupOptions.length === 1) {
        const index = this.options.groupOptions[0].items.findIndex((obj) => obj.key === action.key);
        this.options.groupOptions[0].items[index] = {
          ...this.options.groupOptions[0].items[index],
          value: action.value,
        };
      } else {
        for (const [idx, groupOption] of this.options.groupOptions.entries()) {
          const index = groupOption.items.findIndex((obj) => obj.key === action.key);
          if (index > -1) {
            this.options.groupOptions[idx].items[index] = {
              ...this.options.groupOptions[idx].items[index],
              value: action.value,
            };
            break;
          }
        }
      }
    }
    this.updateOptions(action);
  }

  updateOptions(action): void {
    this.action.emit({
      lastAction: action,
      groupOptions: this.options.groupOptions,
    });
  }
}
