import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  CollabInfluencerEngagement,
  CollabInfluencerProfileBasicInfo,
  InstagramInfluencerMedia,
  InstagramPredictedPriceRange,
  TiktokMediaEngagementStat,
  MapItem,
  TiktokVideo,
  YoutubeMediaEngagementStat,
  YoutubeVideo,
} from '../../interfaces';
import { ResourceType } from '../../@core/data/resource-access-control.service';
import {
  EngagementSeriesItem,
  BrandWithMediaItems,
  TimeSeriesItem,
  BrandedContentStats,
} from '../../@core/data/influencer.service';
import { setAgeGenderDistribution, getAudienceCityLocation, setGenderDistribution } from '../collaboration.helper';
import { CountryService } from '../../@core/utils/country.service';
import utils from '../../@core/utils/utils';
import { reduce, sortBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CollaborationInfluencerService {
  constructor(private http: HttpClient, private countryService: CountryService) {}

  fetchInfluencerInfo(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<CollabInfluencerProfileBasicInfo> {
    return this.http
      .get<CollabInfluencerProfileBasicInfo>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}`,
      )
      .toPromise();
  }

  fetchInfluencerEngagement(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<CollabInfluencerEngagement> {
    return this.http
      .get<CollabInfluencerEngagement>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/engagement`,
      )
      .toPromise();
  }

  fetchInfluencerBrandAffinityWithMedia(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
    sortByField = 'recency',
    isSponsored = false,
  ): Promise<BrandWithMediaItems[]> {
    return this.http
      .get<BrandWithMediaItems[]>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/brand-affinity`,
        { params: new HttpParams().append('sortBy', sortByField).append('isSponsored', `${isSponsored}`) },
      )
      .toPromise();
  }

  fetchInfluencerBrandedContentDistribution(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
    days = 90,
  ): Promise<BrandedContentStats> {
    return this.http
      .get<BrandedContentStats>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/branded-content-distribution`,
        { params: new HttpParams().append('days', `${days}`) },
      )
      .toPromise();
  }

  getIGInfluencerPriceRanges(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<InstagramPredictedPriceRange[]> {
    return this.http
      .get<InstagramPredictedPriceRange[]>(
        environment.api + `/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/prices`,
      )
      .toPromise()
      .catch((err) => {
        if (err.status === 404) {
          return [];
        }
        throw err;
      });
  }

  getInfluencerMedia(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<YoutubeVideo[] | TiktokVideo[] | InstagramInfluencerMedia[]> {
    return this.http
      .get<YoutubeVideo[] | TiktokVideo[] | InstagramInfluencerMedia[]>(
        environment.api + `/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/media`,
      )
      .toPromise()
      .catch((err) => {
        if (err.status === 404) {
          return [];
        }
        throw err;
      });
  }

  getInfluencerFollowerTimeseries(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<TimeSeriesItem[]> {
    return this.http
      .get<TimeSeriesItem[]>(
        environment.api +
          `/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/follower-timeline`,
      )
      .toPromise();
  }

  getInfluencerEngagementTimeline(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<EngagementSeriesItem[] | TiktokMediaEngagementStat[] | YoutubeMediaEngagementStat[]> {
    return this.http
      .get<EngagementSeriesItem[] | TiktokMediaEngagementStat[] | YoutubeMediaEngagementStat[]>(
        environment.api +
          `/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/engagement-timeline`,
      )
      .toPromise();
  }

  fetchInfluencerAudienceGenderDistribution(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<{ series: []; legend?: [] }> {
    return this.http
      .get<any>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/audience-gender-distribution`,
      )
      .toPromise()
      .then((genderBuckets) => {
        return setGenderDistribution(genderBuckets);
      });
  }

  fetchInfluencerAudienceAgeGenderDistribution(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<{ ageDataOptions?: any; ageGenderDataOptions?: any; adultAudiencePercentage?: string }> {
    return this.http
      .get<any>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/audience-age-gender-distribution`,
      )
      .toPromise()
      .then(({ ageGenderBuckets, ageBuckets, adultAudiencePercentage }) => {
        return setAgeGenderDistribution(ageBuckets, ageGenderBuckets, adultAudiencePercentage);
      });
  }

  fetchInfluencerAudienceLocationistribution(
    influencerUsername: string,
    resourceId: string,
    resourceType: ResourceType,
  ): Promise<{ locationDistributionData: MapItem[]; cityDistributionData?: { name: string; value: number }[] }> {
    return this.http
      .get<any>(
        `${environment.api}/api/collabs/influencer/${resourceType}/${resourceId}/${influencerUsername}/audience-location-distribution`,
      )
      .toPromise()
      .then((res) => {
        return {
          locationDistributionData: this.getAudienceCountryLocation(res?.locationDistribution ?? []),
          cityDistributionData: getAudienceCityLocation(res?.cityDistribution),
        };
      });
  }

  private getAudienceCountryLocation(locationDistribution) {
    if (locationDistribution && !utils.isBucketsEmpty(locationDistribution)) {
      const sortedAudienceLocation = sortBy(locationDistribution, (location) => location.count).reverse();
      const totalCount = reduce(locationDistribution, (a, b) => (a['count'] || a) + b.count, 0);
      return sortedAudienceLocation.slice(0, 20).map((bucket) => {
        return {
          label: this.countryService.countryName(bucket.code),
          value: (bucket.count / totalCount) * 100,
          coordinates: this.countryService.location(bucket.code),
        };
      });
    } else {
      return [];
    }
  }
}
