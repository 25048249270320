/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as query from 'query-string';
import { AuthService } from '../../../../@core/auth/auth.service';
import { WhiteLabelService } from '../../../../@core/data/whitelabel.service';
import { MessageModalComponent } from '../../../../shared/message-modal/message-modal.component';
import { LoginErrorModalComponent } from './login-error-modal/login-error-modal.component';
import { UserCredentials } from '../../../../enums';
import { UserService } from '../../../../@core/data/user.service';
import { UserguidingService } from '../../../../@core/data/userguiding.service';
import { FeatureFlagService } from '../../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../../@core/feature-flag/flags';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
})
export class NgxLoginComponent implements AfterViewInit {
  user: UserCredentials = new UserCredentials();
  showMessages: any = {};
  messages: any[] = [];
  modalOn = false;
  domain: string;
  loading = false;
  tosAccepted = true;
  private queryParams;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private location: Location,
    private deviceService: DeviceDetectorService,
    private whiteLabelService: WhiteLabelService,
    private userguiding: UserguidingService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.domain = this.whiteLabelService.getDomain();
    this.route.queryParamMap.subscribe((paramsMap) => {
      const params = query.parse((window && window.location && window.location.search) || '');
      paramsMap.keys.forEach((key) => (params[key] = paramsMap.get(key)));
      this.queryParams = params;
      if (params['email'] && params['success'] === 'true') {
        this.user.email = params['email'];
        this.showMessages.success = true;
        this.showMessages.error = false;
        this.messages = [];
        this.messages.push('Email Verification Successful!');
      }
    });
  }

  async login() {
    this.messages = [];
    if (this.user.email && this.user.password) {
      this.loading = true;
      const user = await this.authService
        .login(this.user.email, this.user.password)
        .then(async () => {
          await this.featureFlagService
            .isFeatureReleased(RELEASE_FLAGS.IAM_MULTI_INSTANCE_SUPPORT)
            .then(async (isEnabled) => {
              if (isEnabled) {
                await this.userService.setupOrganizationsOfClient(true);
              }
            });
          return this.userService.getClientUser();
        })
        .then(async (clientUser) => {
          // At this point, clientUser is set as the logged in user on intercom.
          this.userService.pushIntercomEvent('accepted-2023-tos');
          return clientUser;
        })
        .catch((error) => {
          this.loading = false;
          const loginErrorModal = this.modalService.open(LoginErrorModalComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
          }).componentInstance;
          loginErrorModal.errorCode = error.message;
        });

      if (user) {
        const createdAt = this.authService.getCreatedAt();
        const ugUser = {
          id: user.id,
          email: user.email,
          name: user.name,
          createdAt,
        };
        this.userguiding.identify(ugUser, ugUser.id);

        const isQueryUrlAllowed = this.authService.queryUrlAllowed(this.queryParams['redirectUrl']);
        const defaultHomeUrl = isQueryUrlAllowed
          ? this.queryParams['redirectUrl']
          : this.authService.getDefaultHomeUrl();
        this.router.navigateByUrl(defaultHomeUrl);
      }
    } else {
      this.showMessages.error = true;
      this.showMessages.success = false;
      this.messages.push('Email & Password Required');
    }
  }

  isMobileDevice(): boolean {
    return this.deviceService.isMobile();
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.route.params.subscribe((params) => {
        if (params['event'] === 'session-expired') {
          this.showErrorModal('Login', 'Please login to continue.');
        } else if (params['event'] === 'session-revoked') {
          // TEMP: DISABLE POPUP
          // this.showErrorModal('You have been logged out!', params['message']);
        }
        /*if (params['event'] === 'success-signup') {
          this.showMessages.success = true;
          this.messages.push("Signup successful, please check your email for the verification link!");
          this.location.replaceState("/auth/login");
        }*/
      });
    });
  }

  showErrorModal(headerText: string, contentText: string) {
    // Check if there is already a modal displayed
    if (this.modalOn) {
      return;
    }
    this.modalOn = true;

    // Show the error modal
    const errorModal = this.modalService.open(MessageModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });

    // Set the header and content texts for the modal
    const modalContent = errorModal.componentInstance as MessageModalComponent;
    modalContent.headerText = headerText;
    modalContent.contentText = contentText;

    // When the modal is closed or dismissed
    errorModal.result.then(() => (this.modalOn = false)).catch(() => (this.modalOn = false));

    this.location.replaceState('/auth/login');
  }
}
