<div class="brand-header" (click)="toggleBrandMenu(brand)">
  <nb-user class="brand-user" [picture]="brand.profilePicUrl | thumbnail: 320 | async" [name]="brand.fullName"
    [title]="brand.username" size="large"></nb-user>

  <i class="ion-ios-arrow-forward" [ngClass]="{'open': brand.expanded}"></i>
</div>

<div *ngIf="brand.expanded" class="posts">
  <div class="post" *ngFor="let post of brand.mediaItems" (click)="openInstagramPost(post)" placement="top-left"
    [ngbPopover]="sponsoredTooltip" [disablePopover]="!post.sponsored" container="body" triggers="hover">
    <nb-user class="post-user" [picture]="post.mediaURL | thumbnail: 320 | async" showName="false"
      size="medium"></nb-user>

    <h6>{{post.takenAt | amTimeAgo}}</h6>
    <div class="sponsored" *ngIf="post.sponsored">$</div>
  </div>
</div>
