import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
})
@Injectable()
export class KeywordSearchPipe implements PipeTransform {
  /**
   * @items = object from array
   * @term = term's search
   * @key = nested object in item to search
   */
  transform(items: any, term: any, key: any): any {
    if (term === undefined) {
      return items;
    }

    return items.filter(function (item) {
      for (const property in item[key]) {
        if (item[key][property] === null) {
          continue;
        }
        if (item[key][property].toString().toLowerCase().includes(term.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }
}
