import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-discovery-header',
  templateUrl: './discovery-header.component.html',
  styleUrls: ['./discovery-header.component.scss'],
})
export class DiscoveryHeaderComponent {
  @Output() onUploadClick = new EventEmitter();
  @Output() openCreateGroupModal = new EventEmitter();
  @Output() openAddToGroupModal = new EventEmitter();
  @Output() openCreateCampaignModal = new EventEmitter();
  @Output() openAddToCampaignModal = new EventEmitter();
  @Output() popperOnShown = new EventEmitter();

  @Input() disabledUploadButton = true;
  @Input() selectedInfluencers = [];
  @Input() isCampaignsEnabled;
  @Input() isGroupsExist = false;
  @Input() filterInfluencerGroup = true;
  @Input() isSubscriptionUser = true;
}
