import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { chain, map } from 'lodash';
import { environment } from '../../../../environments/environment';
import { InstagramContentSortKey, MediaProductType, MediaType, VeraPlatforms } from '../../../enums';
import { BrandLookup } from '../../../interfaces';
import { InstagramContentFilters } from '../content-filter.service';
import { Location } from '../location.service';

@Injectable()
export class InstagramContentService {
  constructor(private http: HttpClient) {}

  searchContent(
    filters: InstagramContentFilters,
    page: number,
    allContent = false,
  ): Promise<InstagramContentAPIResponse> {
    return this.http
      .post<InstagramContentAPIResponse>(
        `${environment.api}/api/instagram/content/`,
        chain(filters)
          .clone()
          .update('brands', (brands: BrandLookup[]) =>
            map(brands, (brand) => {
              return {
                name: brand.username,
                isIncluded: brand.isIncluded,
              };
            }),
          )
          .update('locations', (locations: Location[]) =>
            locations.map((location) => ({
              name: location.name,
              isIncluded: location.isIncluded,
            })),
          ),
        {
          params: {
            page: page.toString(),
            allContent: allContent.toString(),
          },
        },
      )
      .toPromise();
  }

  getTopContent(location: string, sortKey: InstagramContentSortKey): Promise<InstagramContentAPIResponse> {
    return this.http
      .get<InstagramContentAPIResponse>(`${environment.api}/api/instagram/content/top`, {
        params: {
          location,
          sortKey,
          timezone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
        },
      })
      .toPromise();
  }

  getContent(contentPk: number, inCampaign: boolean): Promise<InstagramContent> {
    return this.http
      .get<InstagramContent>(`${environment.api}/api/content/${contentPk}?page=${inCampaign ? 'campaign' : 'content'}`)
      .toPromise();
  }

  exportMediaReport(contentPks: string[], campaignSlugName?: string): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/content/export/search`,
        {
          contentPks: contentPks,
          campaignSlugName: campaignSlugName,
          platform: VeraPlatforms.instagram,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }
}

export class RecommendationList {
  searchTerm: string;
  recommendations: string[];
  needRecommendations: boolean;
}

export class InstagramContentAPIResponse {
  content: PartialContent[];
  total: number;
  recommendation: RecommendationList[];
}

export class PartialContent {
  id: string;
  likeCount: number;
  caption: string;
  commentCount: number;
  viewCount?: number;
  instagramUrl: string;
  author: InstagramContentAuthor;
  takenAtTimestamp?: number;
  media: {
    urlMedium: string;
    urlLarge: string;
    urlThumbnail: string;
  };
  estimatedMediaValue?: number;
  estimatedReach?: number;
  reach?: number;
  currency?: string;
  estimatedImpressions?: number;
  selected: boolean;
  areInsightsVerified?: boolean;
  playCount?: number;
  mediaProductType?: MediaProductType;
}

export class InstagramContent extends PartialContent {
  brands: string[];
  influencers: string[];
  sponsored: boolean;
  takenAt: string;
  mediaType: MediaType;
}

export class InstagramContentAuthor {
  id: string;
  username: string;
  fullName: string;
  profilePicture?: string;
  isProfiled = false;
  isRegistered?: boolean = false;
  email: string;
}

export interface SelectedContent {
  pk: string;
  isRegistered: boolean;
  influencer: InstagramContentAuthor;
}
