import { Injectable } from '@angular/core';
import { SubscribedClientUser } from '../../enums';
import { LocalStorageService } from '../auth/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private localStorageService: LocalStorageService) {
    window['surveyService'] = this;
  }

  addSatisMeterScript(clientUser: SubscribedClientUser) {
    const status = this.localStorageService.getItem('survey');
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.id = 'statimeter';
    script.setAttribute('type', 'text/javascript');

    const scriptContent =
      document.createTextNode(`(function() { window.satismeter = window.satismeter || function() {(window.satismeter.q = window.satismeter.q || []).push(arguments);};window.satismeter.l = 1 * new Date();var script = document.createElement("script");var parent = document.getElementsByTagName("script")[0].parentNode;script.async = 1;script.src = "https://app.satismeter.com/js";parent.appendChild(script);})();
    satismeter({
      writeKey: "XGWj60YzSpt1Wghk",
      userId: "${clientUser.userId}",
      traits: {
        name: "${clientUser.name}",
        email: "${clientUser.email}",
        status: "${status}"
      },
      events: {
        submit: function(response) {
          if(response.completed && answers?.length === 6) {
            window.surveyService && window.surveyService.setUserSurveyStatus('completed');
          } else {
            window.surveyService && window.surveyService.setUserSurveyStatus('partial');
          }
        },
        dismiss: function() {
          window.userService && window.userService.setUserSurveyStatus('dismissed');
        }
      }
    });`);
    script.appendChild(scriptContent);
    body.appendChild(script);
  }

  setUserSurveyStatus(status: string) {
    // If the user answered some questions then dismissed, keep the status partial
    if (status === 'dismissed' && this.localStorageService.getItem('survey') === 'partial') {
      // do not change the status.
    } else {
      // update the status
      this.localStorageService.removeItem('survey');
      this.localStorageService.setItem('survey', status);
    }
  }
}
