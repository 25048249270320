import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LogicalFilterType } from '../../enums';

@Component({
  selector: 'ngx-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  @Input()
  set searchModifier(status: LogicalFilterType) {
    this.status = status !== 'AND';
  }

  @Output() searchModifierChange: EventEmitter<string> = new EventEmitter<string>();

  status: boolean;

  onModelChange(value: boolean): void {
    const emitValue = value ? 'OR' : 'AND';
    this.searchModifierChange.emit(emitValue);
  }
}
