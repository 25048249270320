export enum ChatProposalStatus {
  pending = 'pending',
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
  seen = 'seen',
  notSubmitted = 'not-submitted',
}

export enum LastMessageStatus {
  dropped = 'dropped',
  bounced = 'bounced',
  delivered = 'delivered',
  pending = 'pending',
}

export enum FilterType {
  none = 'NONE',
  starred = 'STARRED',
  notDelivered = 'NOT_DELIVERED',
  notRead = 'NOT_READ',
  notAccessed = 'NOT_ACCESSED',
  notSubmitted = 'NOT_SUBMITTED',
  submittedProposals = 'SUBMITTED_PROPOSALS',
  approvedProposals = 'APPROVED_PROPOSALS',
  declinedProposals = 'DECLINED_PROPOSALS',
  withAttachments = 'WITH_ATTACHMENTS',
}

export type ChatFilterTypes =
  | 'NONE'
  | 'STARRED'
  | 'NOT_DELIVERED'
  | 'NOT_READ'
  | 'NOT_ACCESSED'
  | 'NOT_SUBMITTED'
  | 'SUBMITTED_PROPOSALS'
  | 'APPROVED_PROPOSALS'
  | 'DECLINED_PROPOSALS'
  | 'WITH_ATTACHMENTS';

export enum MailType {
  default = 'DEFAULT',
  reminder = 'REMINDER',
  reinvite = 'REINVITE',
}

export enum EmailButtons {
  newEmail = 'SEND NEW EMAIL',
  addToNewEmailSequence = 'ADD TO NEW EMAIL SEQUENCE',
  addToExistingEmailSequence = 'ADD TO EXISTING EMAIL SEQUENCE',
}

export enum OutBoxMessageStatus {
  'PENDING' = 'PENDING',
}
