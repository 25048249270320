import { Component, Input } from '@angular/core';
import { ColumnCustomization } from '../../common.interface';

@Component({
  selector: 'ai-table-cell',
  templateUrl: 'table-cell.control.html',
  styleUrls: ['table-cell.control.scss'],
})
export class TableCellControl {
  @Input() row: unknown;
  @Input() col: ColumnCustomization;
}
