import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-tooltip-basic',
  styleUrls: ['./tooltip-basic.component.scss'],
  templateUrl: 'tooltip-basic.component.html',
})
export class TooltipBasicComponent {
  @Input() tooltips: string[];
}
