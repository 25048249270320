/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { merge, startCase } from 'lodash';
import { PrintService } from '../../pages/print/print-layout/print.service';
import { BaseEchartsPrintedComponent } from './echarts-printed.component';

@Component({
  selector: 'ngx-echarts-pie',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss'],
})
export class EchartsPieComponent extends BaseEchartsPrintedComponent implements AfterViewInit, OnDestroy, OnChanges {
  options: any = {};
  themeSubscription: any;
  @Input() displayLabel: boolean;
  @Input() dataOptions: any = {};
  @Input() printingStyle: string;
  printedChart: boolean;

  constructor(private theme: NbThemeService, private print: PrintService) {
    super();
    this.printedChart = print.isPrinting;
  }

  ngOnChanges(): void {
    this.ngAfterViewInit();
  }

  ngAfterViewInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors = config.variables;
      const echarts: any = config.variables.echarts;

      this.options = merge(
        {
          backgroundColor: echarts.bg,
          color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
          tooltip: {
            trigger: 'item',
            formatter: (params) => `${params.marker} ${startCase(params.name)}: ${params.percent}%`,
          },
          grid: {
            left: '50%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          legend: {
            orient: 'horizontal',
            left: 'left',
            textStyle: {
              color: echarts.textColor,
            },
          },
          series: [
            {
              type: 'pie',
              radius: '65%',
              center: ['50%', '50%'],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: echarts.itemHoverShadowColor,
                },
              },
              label: {
                normal: {
                  ...this.onPrintedPieChart(),
                  textStyle: {
                    color: echarts.textColor,
                  },
                  position: 'outside',
                },
              },
              labelLine: {
                normal: {
                  lineStyle: {
                    color: echarts.axisLineColor,
                  },
                },
              },
            },
          ],
        },
        this.dataOptions,
      );
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onChartEvent(event: any) {
    console.log(event);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  onPrintedPieChart(): { formatter: string; show: boolean } | {} {
    return this.printedChart && this.displayLabel
      ? {
          show: true,
          formatter: '{b} : ({d}%)',
        }
      : {};
  }
}
