import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlatformsV2, TimePeriodKey } from '../../enums';
import { ReportAPIResponse, TrendsAggregation } from '../../interfaces';
import { DashboardFilters } from '../../pages/trends/dashboard/dashboard-filters/dashboard-filters.component';

// All Trends page filters
export class TrendsFilters {
  country = 'Singapore';
  timePeriod: TimePeriodKey = 'LAST_WEEK';
  sponsoredOnly = true;
}

@Injectable()
export class TrendsReportService {
  private trendsFilters = new Subject<TrendsFilters>();
  trendsFilters$ = this.trendsFilters.asObservable();

  private dashboardFilters = new Subject<DashboardFilters>();
  dashboardFilters$ = this.dashboardFilters.asObservable();

  setTrendsFilters(filters: TrendsFilters) {
    this.trendsFilters.next(filters);
  }

  setDashboardFilters(filters: DashboardFilters) {
    this.dashboardFilters.next(filters);
  }

  constructor(private http: HttpClient) {}

  getAllTrendsReport(opts: TrendsFilters, platform: PlatformsV2): Promise<ReportAPIResponse> {
    return this.http
      .post(environment.api + `/api/reports/`, {
        params: {
          region: opts.country,
          timePeriodKey: opts.timePeriod,
          sponsoredOnly: opts.sponsoredOnly,
          platform,
        },
      })
      .toPromise()
      .then((json: ReportAPIResponse) => {
        return json;
      });
  }

  exportBrands(opts: TrendsFilters, platform: PlatformsV2) {
    return this.http
      .post(
        `${environment.api}/api/reports/export/brands`,
        {
          region: opts.country,
          timePeriodKey: opts.timePeriod,
          sponsoredOnly: opts.sponsoredOnly,
          platform,
        },
        {
          responseType: 'blob',
        },
      )
      .toPromise();
  }

  createTrendsDashboard(filters: DashboardFilters): Promise<TrendsAggregation[]> {
    // explicitly call toJSON since angular minification renames the method
    // which leads to JSON.stringify not being called...
    const parsedFilters = { ...filters, date: filters.date.toJSON() };
    return this.http
      .post(environment.api + `/api/reports/content-trends/`, parsedFilters)
      .toPromise()
      .then((result: TrendsAggregation[]) => {
        return result;
      });
  }

  exportTrends(filters: DashboardFilters) {
    const parsedFilters = { ...filters, date: filters.date.toJSON() };
    return this.http
      .post(`${environment.api}/api/reports/content-trends/export/`, parsedFilters, { responseType: 'blob' })
      .toPromise();
  }
}
