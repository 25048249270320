/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Content } from '../../@core/data/content.service';
import { TiktokContentService } from '../../@core/data/tiktok/tiktok-content.sevice';
import { Platforms, VeraPlatforms } from '../../enums';
import {
  InfluencerMediaItem,
  InfluencerVideoItem,
  OembedTiktokContent,
  TiktokVideo,
  InstagramInfluencerMedia,
  InstagramProfile,
} from '../../interfaces';
import { ContentDetailsComponent } from '../../pages/content-discovery/content-details/content-details.component';
import { TiktokVideoModalComponent } from '../../pages/tiktok/tiktok-video-modal/tiktok-video-modal.component';
import { ContentVideoDetailsComponent } from '../content-video-details/content-video-details.component';
import { InstagramThumbnailPipe } from '../pipes/instagram-thumbnail-pipe';
import { InstagramContentModalComponent } from '../../pages/instagram/instagram-content-modal/instagram-content-modal.component';
import * as moment from 'moment';
import { UserService } from '../../@core/data/user.service';

@Component({
  selector: 'ngx-media-gallery',
  styleUrls: ['./media-gallery.component.scss'],
  templateUrl: './media-gallery.component.html',
})
export class MediaGalleryComponent {
  @Input() mediaItems: InfluencerMediaItem[] | InfluencerVideoItem[] | TiktokVideo[] | InstagramInfluencerMedia[];
  @Input() mediaViewer: Platforms | VeraPlatforms;
  @Input() tooltip: string;
  @Input() name = '';
  @Input() reloadMediaContent = true;
  @Input() extraLongCard: boolean;
  @Input() influencer?: InstagramProfile;
  @Input() addEmailBanner?: boolean = false;
  @Input() currency?: string;

  Platforms = Platforms;
  VeraPlatforms = VeraPlatforms;
  mediaUrlMap = {};

  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
    private tiktokContentService: TiktokContentService,
    private userService: UserService,
  ) {}

  handleStateChange(error, ref, index): void {
    if (error.reason === 'loading-failed') {
      this.missingImage(ref, index);
    }
  }

  async missingImage(
    element,
    index,
  ): Promise<InfluencerMediaItem[] | InfluencerVideoItem[] | TiktokVideo[] | InstagramInfluencerMedia[] | undefined> {
    if (this.mediaViewer === Platforms.tiktok) {
      const tiktokVideo = this.mediaItems[index] as TiktokVideo;
      try {
        const contentImage: OembedTiktokContent = await this.tiktokContentService.getContentImage(
          tiktokVideo.id,
          tiktokVideo.author.username,
        );

        if (contentImage.thumbnail_url && contentImage.thumbnail_url !== element.currentSrc) {
          element.src = contentImage.thumbnail_url;
          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    return this.mediaItems.splice(index, 1);
  }

  /**
   * TODO:anupam Very hacky way to get updated url for instagram media
   * we can also extend this to fix tiktok urls
   */
  getMediaUrl(mediaItem: any) {
    let mapIndex;
    if (this.mediaViewer === Platforms.instagram) {
      mapIndex = mediaItem.media.urlMedium;
      return (this.mediaUrlMap[mapIndex] =
        this.mediaUrlMap[mapIndex] || new InstagramThumbnailPipe(this.http).transform(mediaItem));
    } else if (this.mediaViewer === Platforms.youtube) {
      mapIndex = mediaItem.video.thumbnailUrl;
      return (this.mediaUrlMap[mapIndex] = this.mediaUrlMap[mapIndex] || Promise.resolve(mapIndex));
    } else if (this.mediaViewer === Platforms.tiktok) {
      mapIndex = mediaItem.video.cover;
      return (this.mediaUrlMap[mapIndex] = this.mediaUrlMap[mapIndex] || Promise.resolve(mapIndex));
    }
  }

  transformToContent(mediaItem): Content | undefined {
    if (mediaItem.media.author === undefined || mediaItem.media.instagramUrl === undefined) {
      return undefined;
    }

    return {
      pk: mediaItem.media.pk,
      instagramUrl: mediaItem.media.instagramUrl,
      likeCount: mediaItem.media.likeCount,
      commentCount: mediaItem.media.commentCount,
      author: mediaItem.media.author,
      caption: mediaItem.media.caption,
      brands: mediaItem.media.brands,
      influencers: mediaItem.media.influencers,
      sponsored: mediaItem.media.sponsored,
      takenAt: mediaItem.media.takenAt,
      media: {
        urlMedium: mediaItem.media.urlMedium,
        urlLarge: mediaItem.media.urlLarge,
        urlThumbnail: mediaItem.media.urlThumbnail,
      },
      mediaType: mediaItem.media.mediaType,
      selected: mediaItem.media.selected,
    };
  }

  transformInstagramMediaToContent(mediaItem): Content | undefined {
    return {
      pk: mediaItem.id,
      instagramUrl:
        'https://www.instagram.com/p/' + mediaItem.code + '/?hl=en&taken-by=' + mediaItem.authorDocument?.username,
      likeCount: mediaItem.likeCount,
      commentCount: mediaItem.commentCount,
      caption: mediaItem.caption,
      brands: mediaItem.brands,
      influencers: mediaItem.influencers,
      sponsored: mediaItem.isSponsored,
      takenAt: mediaItem.takenAt ? moment(new Date(mediaItem.takenAt)).fromNow() : '',
      media: {
        urlMedium: this.instagramImageURL(mediaItem.code, 'Medium'),
        urlLarge: this.instagramImageURL(mediaItem.code, 'Large'),
        urlThumbnail: this.instagramImageURL(mediaItem.code, 'Thumbnail'),
      },
      mediaType: mediaItem.mediaType,
      selected: mediaItem.selected,
      author: { ...mediaItem.authorDocument, picture: this.influencer?.profilePicture },
      estimatedImpressions: mediaItem.estimatedImpressions,
      estimatedMediaValue: mediaItem.estimatedMediaValue,
      estimatedReach: mediaItem.estimatedReach,
      playCount: mediaItem.playCount,
    };
  }

  instagramImageURL(mediaCode: string, size: 'Thumbnail' | 'Medium' | 'Large') {
    /*
      Thumbnail -> 150 * 150 px
      Medium -> 320 * 320 px
      Large -> 1080 * 1080 px
       */
    if (size === 'Thumbnail') {
      return `https://instagram.com/p/${mediaCode}/media/?size=t`;
    }
    if (size === 'Medium') {
      return `https://instagram.com/p/${mediaCode}/media/?size=m`;
    }
    if (size === 'Large') {
      return `https://instagram.com/p/${mediaCode}/media/?size=l`;
    }
    return '';
  }

  async presentContentDetailsModal(mediaItem: any) {
    if (this.mediaViewer === Platforms.instagram) {
      const contentDetailsModal = this.modalService.open(ContentDetailsComponent, {
        centered: true,
        windowClass: 'content-details-modal',
        backdrop: 'static',
      });
      const modalContent: ContentDetailsComponent = contentDetailsModal.componentInstance;
      modalContent.content = !this.reloadMediaContent ? this.transformToContent(mediaItem) : undefined;
      modalContent.contentPk = mediaItem.media.pk;
      modalContent.inCampaign = false;
      if (this.currency) {
        modalContent.currency = this.currency;
      } else modalContent.currency = mediaItem.currency || (await this.userService.getLinkedCurrency());
    } else if (this.mediaViewer === Platforms.youtube) {
      const contentVideoDetailsModal = this.modalService.open(ContentVideoDetailsComponent, {
        centered: true,
        windowClass: 'content-details-modal',
      });
      const modalContent: ContentVideoDetailsComponent = contentVideoDetailsModal.componentInstance;
      modalContent.videoItem = mediaItem;
      if (this.currency) {
        modalContent.currency = this.currency;
      } else modalContent.currency = mediaItem.currency || (await this.userService.getLinkedCurrency());
    } else if (this.mediaViewer === Platforms.tiktok) {
      const tiktokVideoModalComponent = this.modalService.open(TiktokVideoModalComponent, {
        centered: true,
        windowClass: 'content-details-modal',
      });
      const modalContent: TiktokVideoModalComponent = tiktokVideoModalComponent.componentInstance;
      modalContent.video = mediaItem;
      if (this.currency) {
        modalContent.currency = this.currency;
      } else modalContent.currency = mediaItem.currency || (await this.userService.getLinkedCurrency());
    } else if (this.mediaViewer === VeraPlatforms.instagram) {
      const instContentModalComponent = this.modalService.open(InstagramContentModalComponent, {
        centered: true,
        windowClass: 'content-details-modal',
      });
      const modalContent: InstagramContentModalComponent = instContentModalComponent.componentInstance;
      modalContent.content = this.transformInstagramMediaToContent(mediaItem);
      modalContent.isInfluencerRegistered = this.influencer?.isRegistered;
      if (this.currency) {
        modalContent.currency = this.currency;
      } else modalContent.currency = mediaItem.currency || (await this.userService.getLinkedCurrency());
    }
  }
}
