import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Platforms } from '../../enums';
import { SocialPlatform } from '../../pages/community/community.type';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'ngx-profile-basic-info-card',
  styleUrls: ['./profile-basic-info-card.component.scss'],
  templateUrl: './profile-basic-info-card.component.html',
})
export class ProfileBasicInfoCardComponent implements OnInit {
  @Input() profiles: SocialPlatform[] = [];
  @ViewChild('allProfiles') allProfileModal: TemplateRef<unknown>;

  outerTemplateConfig = {
    showMandate: false,
    showAdditionalPlatformsCount: true,
  };

  innerTemplateConfig = {
    showMandate: true,
    showAdditionalPlatformsCount: false,
  };

  profiledPlatforms: SocialPlatform[];

  isOpenPopOver = false;
  prefferedPlatform: SocialPlatform;

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 0,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit(): void {
    this.prefferedPlatform = this.getPrefferedPlatform;
    this.profiledPlatforms = this.profiles.filter((profile) => profile.pk);
  }

  getPlatformImage(profile: ProfileBasicInfo): string {
    return profile ? `../../../../assets/images/platforms/${profile.platform.toLowerCase()}.svg` : '';
  }

  navigateToProfile(profile: ProfileBasicInfo): void {
    if (!profile.pk) {
      return;
    }
    const profileUrl =
      profile.platform === Platforms.instagram
        ? `/pages/profile/${profile.username}`
        : `/pages/${profile.platform.toLowerCase()}/profile/${encodeURIComponent(profile.username)}`;

    window.open(profileUrl, '_blank');
  }

  get haveUnprofiledPlatform(): boolean {
    return this.profiles.some((profile) => !profile.pk);
  }

  get getPrefferedPlatform(): SocialPlatform {
    return (
      this.profiles.find((profile) => profile.isMandatory && profile.isEnabled && profile.pk) ||
      (this.profiles.find((profile) => profile.pk) as SocialPlatform)
    );
  }

  get getAdditionalPlatforms(): SocialPlatform[] {
    const prefferedPlatform = this.getPrefferedPlatform;
    return this.profiles.filter((profile) => profile.platform !== prefferedPlatform.platform);
  }

  openAllProfilesModal(): void {
    this.modalService.open(this.allProfileModal, {
      windowClass: '',
      centered: true,
      backdrop: 'static',
    });
  }
}

export interface ProfileBasicInfo {
  platform: Platforms;
  username: string;
  pk: string;
  profilePictureUrl?: string;
  isMandatory: boolean;
  isPreferred: boolean;
  stats?: { key: string; value: string }[];
}
