import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import utils from '../../@core/utils/utils';
import { Platforms } from '../../enums';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ngx-connect-account-modal',
  templateUrl: './connect-account-modal.component.html',
  styleUrls: ['./connect-account-modal.component.scss'],
})
export class ConnectAccountModalComponent implements OnInit {
  connectionUrl: SafeResourceUrl;
  displaySocialAccountId: string | null;
  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    const url = utils.getVerificationUrl([
      {
        platform: Platforms.instagram,
        mandatory: true,
        enabled: true,
      },
    ]);
    this.connectionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    window.addEventListener('message', this.handleFrameMessage);
  }

  handleFrameMessage = (message): void => {
    if (message.origin !== environment.avenueAppURL.slice(0, environment.avenueAppURL.length - 1)) {
      return;
    }
    if (message.data['type'] === 'connection_success') {
      this.displaySocialAccountId = message.data['token'];
      if (!this.displaySocialAccountId) {
        return;
      }
      const connectionUrl = utils.getVerificationUrl(
        [
          {
            platform: Platforms.instagram,
            mandatory: true,
            enabled: true,
          },
        ],
        this.displaySocialAccountId,
      );
      this.connectionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(connectionUrl);
    }
    if (message.data['type'] === 'connection_removed') {
      const receivedToken = message.data['token'];
      if (this.displaySocialAccountId !== receivedToken) {
        return;
      }
      this.displaySocialAccountId = null;
      const connectionUrl = utils.getVerificationUrl([
        {
          platform: Platforms.instagram,
          mandatory: true,
          enabled: true,
        },
      ]);
      this.connectionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(connectionUrl);
    }
  };

  onAuthenticate(): void {
    if (this.displaySocialAccountId) {
      this.activeModal.close(this.displaySocialAccountId);
      this.toastrService.success('Successfully authenticated account');
    } else {
      this.toastrService.error('Failed to authenticate account');
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
