import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../fluence/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadStatusService {
  private uploadCount = 0;
  private uploadStatus = new Subject<{ status: string; count: number }>();

  updateUploadStatus(status: string) {
    if (status === 'progress') {
      this.uploadCount++;
    }
    // the completed status is always called after the XHR closed event,
    // so we can decrement the count here
    // this will cover the case when the user cancels the upload or the upload fails !  status === 'abort' || status === 'error'
    else if (status === 'completed') {
      this.uploadCount--;
    }
    this.uploadStatus.next({ status, count: this.uploadCount });
  }

  getUploadStatus() {
    return this.uploadStatus.asObservable();
  }
}
