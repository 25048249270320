/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PlatformsV2 } from '../../../enums';
import { environment } from '../../../../environments/environment';
import {
  FixedPayInfluencer,
  FixedPayInfluencerPaymentRequest,
  FixedPayInfluencersResponse,
  FixedPayProcessInfluencer,
  FixedPaySummary,
  Sort,
} from '../../../interfaces';
import utils from '../../utils/utils';

@Injectable()
export class FixedPayService {
  constructor(private http: HttpClient) {}

  private paymentSuccessful = new Subject<boolean>();
  paymentSuccessful$ = this.paymentSuccessful.asObservable();

  sendPaymentSuccessfulEvent(paymentSuccess: boolean): void {
    this.paymentSuccessful.next(paymentSuccess);
  }

  getFixedPaySummary(
    campaignSlugName: string,
    platform: string,
    { from, to, usernames }: { from?: Date; to?: Date; usernames?: string[] } = {},
  ): Promise<FixedPaySummary> {
    const { fromUTC, toUTCEndOfDay }: { fromUTC?: string; toUTCEndOfDay?: string } =
      from && to ? utils.getUTCDateRanges(from, to) : {};

    return this.http
      .post<FixedPaySummary>(`${environment.api}/api/campaigns/${campaignSlugName}/fixed-pay/summary`, {
        platform,
        from: fromUTC,
        to: toUTCEndOfDay,
        usernames,
      })
      .toPromise();
  }

  getFixedPayInfluencers(
    campaignSlugName: string,
    from: Date,
    to: Date,
    page: number,
    platform: string,
    sort?: Sort,
    search?: string,
  ): Promise<FixedPayInfluencersResponse> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post<FixedPayInfluencersResponse>(environment.api + `/api/campaigns/${campaignSlugName}/fixed-pay`, {
        from: fromUTC,
        to: toUTCEndOfDay,
        sortBy: sort ? sort.sortCriteria : undefined,
        sortDirection: sort ? sort.sortOrder : undefined,
        page,
        search,
        platform,
      })
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  addFixedPayInfluencersDirectly(campaignSlugName: string, body: any): Promise<FixedPayInfluencersResponse> {
    return this.http
      .post<FixedPayInfluencersResponse>(
        environment.api + `/api/campaigns/v2/${campaignSlugName}/add-influencer-direct-to-fixPay`,
        body,
      )
      .toPromise();
  }
  getFixedPayDetails(
    campaignSlugName: string,
    influencerUsername: string,
    from: Date,
    to: Date,
    platform = 'INSTAGRAM',
  ): Promise<any> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post<any>(
        environment.api +
          `/api/campaigns/${campaignSlugName}/fixed-pay/${influencerUsername}/details?platform=${platform}`,
        {
          from: fromUTC,
          to: toUTCEndOfDay,
        },
      )
      .toPromise();
  }

  fixedPayWithPaypal(
    campaignSlugName: string,
    influencerPaymentDetails: FixedPayInfluencerPaymentRequest[],
    platform: PlatformsV2,
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/v2/${campaignSlugName}/payments/fix`, {
        platform,
        influencerPaymentDetails,
      })
      .toPromise();
  }

  markFixedPayAsPaid(
    campaignSlugName: string,
    influencerPaymentDetails: FixedPayInfluencerPaymentRequest[],
    platform: PlatformsV2,
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/v2/${campaignSlugName}/manualPayments/fix`, {
        platform,
        influencerPaymentDetails,
      })
      .toPromise();
  }

  exportFixedPayInfluencers(
    campaignSlugName: string,
    usernames: string[],
    allInfluencers: boolean,
    from: Date,
    to: Date,
    platform: PlatformsV2,
  ): Promise<any> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post(
        `${environment.api}/api/campaigns/${campaignSlugName}/fixed-pay/export`,
        {
          usernames,
          allInfluencers,
          from: fromUTC,
          to: toUTCEndOfDay,
          platform,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }

  fixedPayInfluencerToFixedPayProcessInfluencer(influencer: FixedPayInfluencer): FixedPayProcessInfluencer {
    return {
      influencerName: influencer.influencerName as string,
      influencerUsername: influencer.influencerUsername as string,
      profilePictureUrl: influencer.profilePictureUrl as string,
      pending: influencer.pending as number,
      currency: influencer.currency,
      amountToPay: influencer.pending as number,
      paypalEmailId: influencer.paypalEmailId as string,
    };
  }
}
