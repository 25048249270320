import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CommunityGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isCommunityEnabled = this.auth.isCommunityEnabled();
    if (!isCommunityEnabled) {
      console.error('community guard rejected access to ' + state.url);
      this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return isCommunityEnabled;
  }
}
