import { Component, forwardRef, Input } from '@angular/core';

import * as Editor from '@affable/ckeditor/build/ckeditor';
import { InlinePlaceHolderConfig } from './ckeditor.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { URL_REG_EXP } from '../utils';
import { UploadStatusService } from './upload-status.service';
import { ToastrService } from 'ngx-toastr';
import { CustomUploadAdapter } from './custom-upload-adapter';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CkeditorControl),
  multi: true,
};
@Component({
  selector: 'ai-ckeditor',
  templateUrl: './ckeditor.control.html',
  styleUrls: ['./ckeditor.control.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CkeditorControl implements ControlValueAccessor {
  @Input() supportedTemplatingVariables = [];
  @Input() supportedEmailTemplates: string[] = [];
  @Input() apiPath: string;
  @Input() defaultTemplate = '';
  @Input() removePlugins: string[] = [];
  @Input() addPlugins: string[] = [];
  @Input() disabled = false;

  _model: string;
  editor = undefined;
  editorConfig;

  constructor(private uploadStatusService: UploadStatusService, private toastrService: ToastrService) {}

  async ngOnInit(): Promise<void> {
    await this.setEditorConfig();
  }

  get model() {
    return this._model;
  }

  set model(val) {
    this._model = val;
    this.propagateChange(this._model);
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.model = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}
  async setEditorConfig(): Promise<void> {
    this.editor = Editor;
    this.editorConfig = {
      placeholder: '',
      toolbar: [
        ...this.addPlugins,
        'placeholder',
        '|',
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        // TODO:  should applied on {{varaible}}
        // Because when the font size is changed the variable are not changing along with the text.
        // 'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'alignment',
        'numberedList',
        'bulletedList',
        'outdent',
        'indent',
        '|',
        'emoji',
        'link',
        'imageUpload',
        'reset',
      ],
      removePlugins: this.removePlugins,
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: [
          {
            mode: 'manual',
            label: 'External Link',
            callback: (url) => URL_REG_EXP.test(url),
            attributes: {
              target: '_blank',
            },
          },
        ],
      },
      placeholderProps: {
        label: 'Insert Variables',
        tooltip: 'Insert variables',
        types: this.supportedTemplatingVariables,
        position: 's',
      },
      templates: {
        label: 'Select Template',
        tooltip: 'Select email template',
        types: this.supportedEmailTemplates,
        position: 's',
      },
      resetProps: {
        defaultTemplate: this.defaultTemplate,
      },
      simpleUpload: {
        uploadUrl: `${this.apiPath}/api/chats/assets/upload`,
      },
      allowedContent: true,
      // inline placeholder plugin config
      mention: this.getInlinePlaceholderPluginConfig(this.supportedTemplatingVariables, '@', 0),
    };
  }

  getInlinePlaceholderPluginConfig(
    templateVaraibles: string[],
    marker: string,
    minimumCharacters: number,
  ): InlinePlaceHolderConfig {
    const feed = templateVaraibles.map((item) => {
      return { id: `${marker}${item}`, name: `{{${item}}}` };
    });
    return {
      feeds: [
        {
          marker,
          feed,
          minimumCharacters,
          itemRenderer: this.customItemRenderer,
        },
      ],
    };
  }

  /* Customizes the way the list of template suggestions is displayed.
   * Each variable has an @id, and name.
   */
  customItemRenderer(item: { id: string; name: string }): HTMLSpanElement {
    const placeHolder = document.createElement('span');
    // for custom style add('class')
    // placeHolder.classList.add('class_');
    placeHolder.textContent = item.name;
    return placeHolder;
  }

  onReady($event) {
    $event.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new CustomUploadAdapter(loader, this.apiPath, this.uploadStatusService, this.toastrService);
    };
  }
}
