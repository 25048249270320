import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrintService } from '../print-layout/print.service';
import { Platforms } from '../../../enums';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { NumberFormatPipe } from '../../../shared/pipes/number-format-pipe';
import { ReportPrintableProfile } from '../../../interfaces/print.interface';
import * as moment from 'moment';
import { CommentAnalysis, MediaComment, MediaReportData } from '../../../interfaces';

@Component({
  selector: 'printable-report',
  templateUrl: './printable-report.component.html',
  styleUrls: ['./printable-report.component.scss'],
})
export class PrintableReportComponent implements OnInit, AfterViewInit {
  canPrint: boolean;
  platform: string = Platforms.instagram;
  Platforms = Platforms;
  statsCardLabels: { label: string; icon: string; subtitle: string; value: number | string }[];
  ageDataOptions: any;
  genderDataOptions: any;
  locationDataOptions: any;
  private readonly printableReport: ReportPrintableProfile;
  reportName: string;
  createdAt: string | undefined;
  aggregatedCommentAnalysis: any;
  mediaComments: MediaComment[] = [];
  mediaReports: MediaReportData[] = [];
  currency: string;
  ageBuckets: string[];

  constructor(
    private printService: PrintService,
    private router: Router,
    private numberFormatPipe: NumberFormatPipe,
    private percentPipe: PercentPipe,
    private decimalPipe: DecimalPipe,
  ) {
    this.printableReport = this.printService.dataToPrint as ReportPrintableProfile;
    this.canPrint = !!this.printableReport;
    if (this.canPrint) {
      const {
        totalInfluencers,
        totalPosts,
        totalLikes,
        totalComments,
        totalEngagement,
        totalEstimatedReach,
        totalEstimatedMediaValue,
        currency,
      } = this.printableReport;
      ({
        ageDataOptions: this.ageDataOptions,
        genderDataOptions: this.genderDataOptions,
        locationDataOptions: this.locationDataOptions,
        aggregatedCommentAnalysis: this.aggregatedCommentAnalysis,
        mediaComments: this.mediaComments,
        mediaReports: this.mediaReports,
        ageBuckets: this.ageBuckets,
      } = this.printableReport);
      this.currency = currency;
      this.reportName = this.printableReport.campaignReport?.campaign?.name || 'camping report name';
      this.createdAt = moment(this.printableReport.campaignReport?.campaign?.createdAt, 'x').format(
        'dddd, MMMM Do YYYY, h:mm:ss a',
      );
      this.statsCardLabels = [
        {
          label: 'Influencers',
          icon: 'ion-ios-people',
          subtitle: 'Total Influencers',
          value: decimalPipe.transform(totalInfluencers),
        },
        {
          label: 'Posts',
          icon: 'ion-ios-images',
          subtitle: 'Total Posts',
          value: decimalPipe.transform(totalPosts),
        },
        {
          label: this.getTotalReactionsLabel().replace(`Total`, ''),
          icon: 'ion-md-heart',
          subtitle: this.getTotalReactionsLabel(),
          value: decimalPipe.transform(totalLikes),
        },
        {
          label: 'Comments',
          icon: 'ion-ios-chatboxes',
          subtitle: 'Total Comments',
          value: decimalPipe.transform(totalComments),
        },
        {
          label: 'Engagement',
          icon: 'ion-ios-swap',
          subtitle: 'Total Engagement',
          value: percentPipe.transform(totalEngagement, '1.0-2'),
        },
        {
          label: 'Est. Reach',
          icon: 'ion-md-wifi',
          subtitle: 'Total Est. Reach',
          value: this.numberFormatPipe.transform(totalEstimatedReach, 2),
        },
        {
          label: 'Est. Media Value',
          icon: 'ion-logo-usd',
          subtitle: 'Total Est. Media Value',
          value: `${currency} ${this.numberFormatPipe.transform(totalEstimatedMediaValue, 2)}`,
        },
      ];
    }
  }

  ngOnInit(): void {
    if (!this.canPrint) {
      this.router.navigate(['404-not-found']).then(() => {
        throw new Error(
          '[ PRINT EXCEPTION ]: This error can occur when no data is supplied for export or when the print link has expired!',
        );
      });
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.canPrint) {
      setTimeout(() => {
        this.printService.onDataReady();
      }, 1000);
    }
  }

  getTotalReactionsLabel(): string {
    switch (this.platform.toLowerCase()) {
      case Platforms.instagram.toLowerCase(): {
        return 'Total Likes';
      }
      case Platforms.facebook.toLowerCase(): {
        return 'Total Reactions';
      }
    }
  }

  get getUserProfileUrl(): string {
    switch (this.platform.toLowerCase()) {
      case Platforms.facebook.toLowerCase():
        return '/pages/facebook/profile/';
      case Platforms.youtube.toLowerCase():
        return '/pages/youtube/profile/';
      case Platforms.twitter.toLowerCase():
        return '/pages/twitter/profile/';
      case Platforms.tiktok.toLowerCase():
        return '/pages/tiktok/profile/';
      default:
        return '/pages/profile/';
    }
  }

  showAgeDistribution(ages: string[]): boolean {
    return !ages.every((age) => age === 'NaN');
  }

  private checkEmptyOrNull(obj: any): boolean {
    return Object.values(obj).some((v) => v === undefined || v === null);
  }

  isMediaCommentAnalysisEmpty(commentAnalysis: CommentAnalysis): boolean {
    return commentAnalysis ? this.checkEmptyOrNull(commentAnalysis) : true;
  }

  isMediaCommentsEmpty(mediaComments: MediaComment[]): boolean {
    return !mediaComments.length ? true : mediaComments.some((mediaComment) => this.checkEmptyOrNull(mediaComment));
  }
}
