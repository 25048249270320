import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginErrorCode } from '../../../../../@core/auth/auth.service';

@Component({
  selector: 'ngx-login-error-modal',
  templateUrl: './login-error-modal.component.html',
  styleUrls: ['./login-error-modal.component.scss'],
})
export class LoginErrorModalComponent {
  errorCode: LoginErrorCode;

  constructor(private activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.close();
  }
}
