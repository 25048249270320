import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmailTemplate } from '../../../../interfaces';
import { environment } from '../../../../../environments/environment';
import { ChatService } from '../../../../@core/data/chat.service';
import { CkeditorControl } from '../../../../../../../controls/src/ckeditor/ckeditor.control';
import { UploadStatusService } from '../../../../../../../controls/src/ckeditor/upload-status.service';

const NEW_TEMPLATE = "<p>Hi <span class='placeholder'>{{username}}</span>,</p>\n";

@Component({
  selector: 'ngx-invitations',
  templateUrl: './invitations.html',
  styleUrls: ['./invitations.scss'],
  animations: [
    trigger('fadeIn', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void => *', animate(350)),
    ]),
  ],
})
export class InvitationsComponent implements OnInit {
  @Input() campaignSlugName: string;
  @Input() types: { type: string; templates: EmailTemplate[] }[] = [];
  @Input() supportedTemplatingVariables = [];
  @ViewChild('ckeditorRef', { static: false }) ckeditorRef: CkeditorControl;
  @ViewChild('listSectionRef', { static: false }) listSectionRef: ElementRef;
  currentIndex = 0;
  currentTypeIndex = 0;
  revertIndex = null;
  isEditMode = false;
  tempIndex = null;
  name? = '';
  subject? = '';
  template? = '';
  @Input() searchText: string;
  isDeleteMode = false;
  deleteConfirmText = 'Are you sure that you want to delete the template?';
  editorUploading: boolean;

  filter(template: EmailTemplate) {
    return template?.name?.toLowerCase().includes(this.searchText?.toLowerCase());
  }

  get current() {
    return this.types[this.currentTypeIndex]?.templates?.[this.currentIndex];
  }

  set current(value: Partial<EmailTemplate>) {
    if (this.current) {
      Object.keys(value).forEach((key) => {
        this.types[this.currentTypeIndex].templates[this.currentIndex][key] = value[key];
      });
    }
  }

  scrollToBottomWithSmoothScroll(): void {
    setTimeout(() => {
      this.listSectionRef.nativeElement.scrollTo({
        top: this.listSectionRef.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  get allowDelete() {
    return !this.current?.conditions?.generic;
  }

  constructor(
    private chatService: ChatService,
    private uploadStatusService: UploadStatusService,
    private toastrService: ToastrService,
  ) {}

  async ngOnInit() {
    this.select(this.currentTypeIndex);
    this.uploadStatusService.getUploadStatus().subscribe((uploadState) => {
      this.editorUploading = !(uploadState.status === 'completed' && uploadState.count === 0);
    });
  }

  select(typeIndex: number, index = this.currentIndex || 0) {
    this.isEditMode = false;
    this.current = { selected: false };
    this.currentIndex = index;
    this.currentTypeIndex = typeIndex;
    const { name, subject, template } = this.current;
    this.name = name;
    this.subject = subject;
    this.template = template;
    if (this.ckeditorRef?.editorConfig) {
      this.ckeditorRef.editorConfig.resetProps.defaultTemplate = this.template;
    }
    this.current = { selected: true };
  }
  get isFormInValid(): boolean {
    return !(this.name?.trim().length && this.subject?.trim().length && this.template?.trim().length);
  }

  get apiPath(): string {
    return environment.api;
  }
  createNew({ subject, template } = { subject: 'Collaboration Opportunity', template: NEW_TEMPLATE }) {
    const { length } = this.types[1]?.templates || [];
    if (this.tempIndex === null) {
      if (template !== NEW_TEMPLATE) {
        this.revertIndex = this.currentIndex;
      }
      const name = `New Mail Template 0${length + 1}`;
      this.types[1].templates.push({
        template,
        category: 'campaigns',
        conditions: {
          invitations: true,
          campaignSlugName: this.campaignSlugName,
        },
        subject,
        name,
        i: length,
      });
      this.tempIndex = length;
      this.select(1, this.tempIndex);
      this.isEditMode = true;
    }
    this.scrollToBottomWithSmoothScroll();
  }

  cancel() {
    this.isEditMode = false;
    const { _id, template, conditions, subject, name } = this.current;
    if (_id && !conditions.generic) {
      this.current = { template, subject, name };
      this.select(this.currentTypeIndex);
    } else {
      if (typeof this.revertIndex === 'number') {
        this.select(0, this.revertIndex);
        return this.clear();
      }
      this.select(this.currentIndex ? this.currentTypeIndex : 0, this.currentIndex ? this.currentIndex - 1 : 0);
      return this.clear();
    }
  }

  clear(remove = true) {
    if (remove) {
      this.types[1].templates.pop();
    }
    this.tempIndex = null;
    this.revertIndex = null;
  }

  async save() {
    this.isEditMode = false;
    this.clear(false);
    const { _id } = this.current;
    const payload = {
      subject: this.subject,
      template: this.template,
      name: this.name,
      conditions: { invitations: true, campaignSlugName: this.campaignSlugName },
    };

    try {
      const newObject = await (_id
        ? this.chatService.updateTemplate(_id, payload)
        : this.chatService.createTemplate({
            ...this.current,
            ...payload,
          }));
      this.current = { ...newObject };
    } catch (error) {
      this.cancel();
      this.toastrService.error(error?.error?.message);
    }
  }

  async delete(): Promise<void> {
    const { _id } = this.current;
    if (_id) {
      await this.chatService.deleteTemplate(_id, this.campaignSlugName);
    }
    this.types[this.currentTypeIndex].templates.splice(this.currentIndex, 1);
    if (this.types[this.currentTypeIndex]?.templates?.length !== 0) {
      this.select(this.currentTypeIndex, this.types[this.currentTypeIndex]?.templates?.length - 1);
    } else {
      this.select(0, 0);
    }
    this.isDeleteMode = false;
  }

  get isSavingEnabled() {
    return this.isFormInValid || this.editorUploading;
  }
}
