<div class="profile-info">
  <ng-container *ngTemplateOutlet="profileCard; context: {profile:prefferedPlatform, config:outerTemplateConfig}">
  </ng-container>
</div>
<ng-template #profileCard let-profile="profile" let-config="config">
  <div class="platform pb-2">
    <div class="preffered">
      <span class="profile-card" (click)="navigateToProfile(profile)">
        <img [src]="getPlatformImage(profile)">
        <span class="af-caption username">{{profile?.username}}</span>
      </span>
      <span *ngIf="config.showMandate" class="mandate">{{profile.isMandatory ? 'Mandatory' : 'Optional'}}</span>
    </div>
    <div class="stats">
      <ng-container *ngFor="let stats of profile?.stats; index as i">
        <span *ngIf="stats.value">
          <span class="key">{{stats.key}}</span><span class="value" [ngSwitch]="stats.key">
            <ng-container *ngSwitchCase="'Followers'">
              {{stats.value | shortNumber}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{stats.value.toFixed(2)}}%
            </ng-container>
          </span>
          <span class="separator"
            *ngIf="(i + 1 < profile.stats.length && profile.stats[i+1].value)">{{i>0?'&bull;':'|'}}</span>
        </span>
      </ng-container>
    </div>
  </div>
  <div class="additional-platforms" *ngIf="config.showAdditionalPlatformsCount && (profiles.length > 1)">
    <div class="vertical-separator"></div>

    <div class="platforms-count" (mouseover)="isOpenPopOver = true"  (mouseout)="isOpenPopOver = false"  cdkOverlayOrigin #trigger="cdkOverlayOrigin" [class.text-warning]="haveUnprofiledPlatform"  (click)="openAllProfilesModal()">
      +{{profiles.length - 1}}
    </div>
    <!-- This template displays the overlay content and is connected to the button -->
<ng-template
cdkConnectedOverlay
cdkConnectedOverlayPush
cdkConnectedOverlayBackdropClass="overlay-community-panel-class"
[cdkConnectedOverlayPositions]="positionPairs"
[cdkConnectedOverlayOrigin]="trigger"
[cdkConnectedOverlayOpen]="isOpenPopOver"
>
<div class="profile-overlay-container">
  <div class="all-profiles" *ngFor="let profile of getAdditionalPlatforms">
    <div class="platform my-2"  *ngIf="!profile.pk; else haveProfile">
      <img class="unprofiled-logo"  [src]="getPlatformImage(profile)">
      <label class="af-caption ml-1 text-warning" >Unable to profile</label>
      <p class="af-caption-2 text-warning mx-0 mb-3 mt-1">The account doesn’t exist or is private.</p>
    </div>
    <ng-template #haveProfile>
      <ng-container *ngTemplateOutlet="profileCard; context: {profile: profile, config:innerTemplateConfig}">
      </ng-container>
    </ng-template>

  </div>
</div>

</ng-template>
  </div>

</ng-template>
<ng-template #allProfiles let-modal>
  <div>
    <div class="modal-header">
      <span>Channel Info</span>
      <button class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="all-profiles" *ngFor="let profile of profiledPlatforms">
        <ng-container *ngTemplateOutlet="profileCard; context: {profile: profile, config:innerTemplateConfig}">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
