<ngx-auth-block>
  <div class="title">
    <img style="height: 100px;" affLogo>
  </div>
  <h2 class="title">Forgot Password</h2>
  <small class="form-text sub-title">Enter your email adress and we'll send a link to reset your password</small>

  <form #requestPassForm="ngForm">
    <div *ngIf="showMessages.error && errors && errors?.length> 0 && submitted" class="alert alert-danger" role="alert">
      <div><strong>Oh snap!</strong></div>
      <div *ngFor="let error of errors">{{ error }}
      </div>
    </div>

    <div *ngIf="showMessages.success && messages && messages?.length> 0 && submitted" class="alert alert-success"
      role="alert">
      <div><strong>Hooray!</strong></div>
      <div *ngFor="let message of messages">{{ message }}
      </div>
    </div>

    <div class="form-group">
      <label for="input-email" class="sr-only">Enter your email address</label>
      <input name="email" [(ngModel)]="user.email" id="input-email" class="form-control" #email="ngModel" type="email"
        email [required]="true" (keydown.space)="$event.preventDefault();"
        [class.form-control-danger]="email.invalid && email.touched" autofocus>

      <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
        Email is required!
      </small>
      <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.pattern">
        Email should be the real one!
      </small>
    </div>

    <ai-button [disabled]="submitted || !requestPassForm.form.valid" typography="body-2" (click)="resetPassword()"
      label="Request password" suffix="far fa-arrow-right-long" length="block">
    </ai-button>
  </form>

  <div class="links col-sm-12">
    <small class="form-text">
      Already have an account? <a class="af-link-text" routerLink="../login"><strong>Sign In</strong></a>
    </small>
  </div>
</ngx-auth-block>
