import { Injectable } from '@angular/core';
import { FileInputConfig } from '../../interfaces';

@Injectable()
export class FilesService {
  handleFilesInput(
    files: FileList | File[],
    { validTypes, fileMaxSize, totalMaxSize }: FileInputConfig = {},
  ): { validFiles: File[]; error: string } {
    if (!Array.isArray(files)) {
      files = Array.from(files);
    }

    let totalSize = 0;
    const validFiles: File[] = [];

    for (const file of files) {
      if (file.size === 0) {
        return { validFiles, error: `Input file ${file.name} is empty` };
      }

      if (validTypes?.length && !validTypes.includes(file.type)) {
        return { validFiles, error: `Input file ${file.name} has invalid type` };
      }

      if (fileMaxSize && file.size > fileMaxSize) {
        return {
          validFiles,
          error: `Input file ${file.name} exceeded maximum size limit of ${fileMaxSize / 1024 / 1024}MB`,
        };
      }

      const isDuplicated = validFiles.find(
        (item) => item.name === file.name && item.type === file.type && item.size === file.size,
      );

      if (isDuplicated) {
        continue;
      }

      totalSize += file.size;

      if (totalMaxSize && totalSize > totalMaxSize) {
        return {
          validFiles,
          error: `Attachments files exceeded maximum total size limit of ${totalMaxSize / 1024 / 1024}MB`,
        };
      }

      validFiles.push(file);
    }

    return { validFiles, error: '' };
  }
}
