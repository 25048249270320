import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CampaignsService } from '../../../@core/data/campaigns.service';
import { GroupInfo, GroupService } from '../../../@core/data/group.service';
import { UserService } from '../../../@core/data/user.service';
import { Platforms, SubscribedClientUser } from '../../../enums';
import { ClientOrganizationMembers } from '../../../interfaces';
import { CollaborationTeamsService } from '../../../pages/collaboration-teams/collaboration-teams.service';
import { CollaborationTeam } from '../../../interfaces/collaboration.interface';
import { first, isEmpty, keyBy, map, uniq } from 'lodash';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { IResourceAccessControl } from '../../../interfaces/resource-access-control.interface';
import { GroupWithRac } from '../../influencer-group/influencer-group.helper.service';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../@core/feature-flag/flags';

type ShareWithIn = { name: string; id: string; type: 'organization' | 'collaboration'; members: string[] };

@Component({
  selector: 'ngx-share-group-modal',
  templateUrl: './share-group-modal.component.html',
  styleUrls: ['./share-group-modal.component.scss'],
})
export class ShareGroupModalComponent implements OnInit {
  @Input() group: GroupWithRac;
  @Input() platform: Platforms;

  client: SubscribedClientUser;

  loadingorganizationMembers = false;
  organizationMembers: ClientOrganizationMembers[];
  membersToShareWith: ClientOrganizationMembers[];
  collaborationTeams: CollaborationTeam[] = [];
  selectedCollaborationTeam: CollaborationTeam;
  membersToShareWithEmails: string[] = [];
  existingSharedMembers: string[] = [];
  shareWithOptions: ShareWithIn[] = [];
  shareWithIn: ShareWithIn;
  sharedWithCollaboratorMembers: IResourceAccessControl[] = [];
  isCollabWithGroupEnabled = false;
  alreadySharedWithCollaborator: boolean;
  isFeatureFlagEnabled = false;
  removedMembers: Set<string> = new Set();
  loading = false;

  get isSharingDisabled(): boolean {
    // * If the group is shared with the same members (No updated occurred), then disable the share button
    return JSON.stringify(this.existingSharedMembers) === JSON.stringify(this.membersToShareWithEmails);
  }

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private campaignsService: CampaignsService,
    private groupService: GroupService,
    private userService: UserService,
    private collaborationTeamsService: CollaborationTeamsService,
    private router: Router,
    private auth: AuthService,
    private featureFlagService: FeatureFlagService,
  ) {}

  checkCollaborationPortalEnabled(): void {
    this.isCollabWithGroupEnabled = this.auth.isCollaborationPortalEnabled();
  }
  get featureEnabledWithFlag(): boolean {
    return this.isFeatureFlagEnabled && this.isCollabWithGroupEnabled;
  }

  async fetchIsFeatureFlagEnabled() {
    this.isFeatureFlagEnabled = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.AGENCY_X_BRAND_GROUP);
  }

  navigateToCollaborationTeamsSetting(mode: 'add' | 'edit' = 'add'): void {
    this.activeModal.close();
    this.router.navigate(['/pages/account/collaboration'], {
      queryParams: {
        mode,
        ...(mode === 'edit' ? { selectedCollaborationTeamId: this.shareWithIn.id } : {}),
      },
    });
  }

  get isCollaborationTeamSelected(): boolean {
    return this.shareWithIn?.type === 'collaboration';
  }

  copyInviteLink(): void {
    navigator.clipboard.writeText(`${environment.baseUrl}/collabs/login`);
  }

  async ngOnInit(): Promise<void> {
    this.client = await this.userService.getClientUser();
    this.checkCollaborationPortalEnabled();
    this.fetchIsFeatureFlagEnabled();
    this.fetchData();
    this.fetchRac();
  }

  async fetchRac(): Promise<void> {
    if (!this.isCollabWithGroupEnabled) {
      return;
    }
    this.sharedWithCollaboratorMembers = this.group.rac ?? [];
  }

  async fetchData(): Promise<void> {
    await this.getOrganizationMembers();
    await this.getColaborationTeams();
    this.initSelectOptions();
  }

  initSelectOptions(): void {
    const orgData: ShareWithIn = {
      name: `Within ${this.client.organizationName ?? 'Organization'}`,
      id: this.client.organizationId ?? '',
      type: 'organization',
      members: this.organizationMembers.map((member) => member.email),
    };

    const options: ShareWithIn[] = [];
    options.push(orgData);

    if (!isEmpty(this.sharedWithCollaboratorMembers)) {
      const consumerTeamId = first(this.sharedWithCollaboratorMembers)?.consumerTeam._id;
      const team = this.collaborationTeams.find((entry) => entry._id === consumerTeamId);
      if (team) {
        this.selectedCollaborationTeam = team;
      }
      this.alreadySharedWithCollaborator = true;
      options.push({
        name: team?.name ?? '',
        id: team?._id ?? '',
        type: 'collaboration',
        members: team?.members ?? [],
      });
    } else {
      this.collaborationTeams.forEach((team) => {
        options.push({
          name: team.name,
          id: team._id ?? '',
          type: 'collaboration',
          members: team.members,
        });
      });
    }

    this.shareWithOptions = [...options];
    this.shareWithIn = orgData;
    this.membersToShareWithEmails = map(this.filterSharedOrgMembers, 'email');
    this.existingSharedMembers = this.membersToShareWithEmails;
  }

  get filterCollaboratorTeam(): CollaborationTeam[] {
    const sharedEmails = this.sharedWithCollaboratorMembers.map((member) => member.consumer.value);
    return this.collaborationTeams.filter((entry) => sharedEmails.every((email) => entry.members.includes(email)));
  }

  get filterSharedOrgMembers(): ClientOrganizationMembers[] {
    return this.organizationMembers.filter((member) => this.group.sharedWith?.includes(member.email));
  }

  async getColaborationTeams(): Promise<void> {
    if (!this.isCollabWithGroupEnabled) {
      return;
    }
    this.collaborationTeams = await this.collaborationTeamsService.getCollaborationTeams();
  }

  closeModal(success = false): void {
    this.activeModal.close({ success });
  }

  onChangeShareWithIn($event: ShareWithIn): void {
    if ($event.type === 'organization') {
      this.membersToShareWithEmails = map(this.filterSharedOrgMembers, 'email');
    } else {
      this.membersToShareWithEmails = this.sharedWithCollaboratorMembers.map((member) => member.consumer.value);
    }
    this.existingSharedMembers = this.membersToShareWithEmails;
    this.removedMembers.clear();
  }

  shareGroup(): void {
    this.isCollabWithGroupEnabled ? this.share() : this.shareGroupWithInOrg();
  }

  share(): void {
    const filterRemovedMembers = Array.from(this.removedMembers).filter(
      (email) => !this.membersToShareWithEmails.includes(email),
    );
    const key = this.shareWithIn.type === 'organization' ? 'organizationMember' : 'externalCollaborationMembers';
    // * Only add members that are not already shared with
    const addedMembers = this.membersToShareWithEmails.filter((email) => !this.existingSharedMembers.includes(email));
    const added = key === 'organizationMember' ? this.getClientIdsFromEmails(addedMembers) : addedMembers;
    const removed =
      key === 'organizationMember' ? this.getClientIdsFromEmails(filterRemovedMembers) : filterRemovedMembers;
    this.loading = true;
    this.groupService
      .shareGroupV2(
        { [key]: { added, removed, ...(key === 'organizationMember' ? {} : { teamId: this.shareWithIn.id }) } },
        this.group.slugName,
      )
      .then(() => {
        let message = 'Group shared successfully';
        if (key === 'externalCollaborationMembers') {
          message =
            added?.length && removed?.length
              ? 'Invitation sent to newly added members and Access updated for removed members'
              : added?.length
              ? 'Invitation sent successfully'
              : 'Members removed successfully';
        }
        this.toastrService.success(message);
        this.existingSharedMembers = this.membersToShareWithEmails;
        this.closeModal(true);
      })
      .catch(() => {
        this.toastrService.error('Failed to share group, please try again!');
        this.closeModal();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  cachedRemoveMember(email: string): void {
    this.removedMembers.add(email);
  }

  shareGroupWithInOrg(): void {
    // Handles case where user manually types email address instead of
    // selecting email and group owner filter is not applied
    let clientIdsToShareWith = this.getClientIdsFromEmails(this.membersToShareWithEmails);
    if (!clientIdsToShareWith.length) return;
    clientIdsToShareWith = clientIdsToShareWith.filter((member) => member !== this.group.owner);
    this.loading = true;
    this.groupService
      .shareGroup(this.group.slugName, clientIdsToShareWith, this.platform)
      .then((result) => {
        const unShared = clientIdsToShareWith.filter((clientId) => !result.clientIds.includes(clientId));
        const unsharedEmails = this.getEmails(unShared);
        const sharedEmails = this.getEmails(result.clientIds);
        this.group.sharedWith = uniq(this.group.sharedWith?.concat(sharedEmails));
        if (unShared?.length === 0) {
          this.toastrService.success('Group shared successfully');
        } else {
          this.toastrService.error(`No ClientUser record found for ${unsharedEmails.join(', ')}`);
        }
        this.closeModal(true);
      })
      .catch(() => {
        this.toastrService.error('Failed to share group with members list, please try again!');
        this.closeModal();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getClientIdsFromEmails(emails: string[]): string[] {
    if (this.shareWithIn.type !== 'organization') {
      return [];
    }
    const organizationMembersRecord = keyBy(this.organizationMembers, 'email');
    return emails.map((email) => organizationMembersRecord[email]).map((member) => member._id);
  }

  getOrganizationMembers(): Promise<ClientOrganizationMembers[]> {
    return new Promise((resolve, reject) => {
      this.loadingorganizationMembers = true;
      this.campaignsService.getOrganizationMembers(this.group.owner).subscribe((orgMembers) => {
        this.loadingorganizationMembers = false;
        if (orgMembers?.length) {
          // Filter out loggedIn user email, owner, emails of those that the group is already shared with
          this.organizationMembers = orgMembers.filter(
            (member) => member._id !== this.client.userId && member.email !== this.group.ownerEmail,
          );
          this.membersToShareWith = this.filterSharedOrgMembers;
          resolve(orgMembers);
          // Exclude members already shared with from rendering on the selection box
        } else {
          resolve([]);
          this.organizationMembers = [];
          this.membersToShareWith = [];
        }
      }, reject);
    });
  }

  getEmails(clientIds: string[]): string[] {
    return clientIds.map((clientId) => this.organizationMembers.find((member) => member._id === clientId)?.email || '');
  }
}
