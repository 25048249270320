/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone, each, map, update } from 'lodash';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InstagramInfluencerSortKey, Platforms, PlatformsV2 } from '../../enums';
import {
  AudienceLocation,
  BrandedContentService,
  BrandLookup,
  Influencer,
  InfluencerAPIResponse,
  InfluencerLookup,
  InfluencerProfileAPIResponse,
  InstagramPredictedPriceRange,
  PreviewInfluencer,
  PreviewInfluencerStats,
  ViewedInfluencer,
} from '../../interfaces';
import utils from '../utils/utils';
import { InfluencerSearchFilters } from './influencer-filter.service';
import { Location } from './location.service';

const INFLUENCER_VIEWED_CHANNEL = 'affable_influencer_viewed';

export class AgeBucket {
  min: number;
  max: number;
  count: number;
  label?: string;
}

export class Age {
  buckets: AgeBucket[];
}

export class AgeGender {
  buckets: AgeGenderBucket[];
}

export class AgeGenderBucket {
  min: number;
  max: number;
  maleCount?: number;
  femaleCount?: number;
  count?: number;
  label?: string;
}

export class Buckets {
  buckets: LabelBucket[];
}

export class LabelBucket {
  label: string;
  count: number;
}

export class Engagement {
  avgLikesRatio: number;
  avgCommentsRatio: number;
  avgReactionsRatio?: number;
  avgSharesRatio?: number;
  avgRetweetsRatio?: number;
  reactions?: {
    avgLoveRatio: number;
    avgWowRatio: number;
    avgLikeRatio: number;
    avgAngryRatio: number;
    avgSadRatio: number;
    avgHahaRatio: number;
  };
}

export class TimeSeriesItem {
  followers: number;
  date: Date;
}

export class EngagementSeriesItem {
  likeCount: number;
  commentCount: number;
  takenAt: Date;
  urlThumbnail: string;
}

export class BrandWithMediaItems {
  fullName: string;
  profilePicUrl: string;
  username: string;
  mediaItems: MediaItem[];
  show? = false;
  expanded = false;
}

export class MediaItem {
  postURL: string;
  takenAt: string;
  mediaURL: string;
  sponsored: boolean;
  show? = false;
}

export class InfluencerStats {
  age: Age;
  gender: Buckets;
  ageGender: AgeGender | null;
  interests: string[];
  engagement: Engagement;
  similarInfluencersEngagement: Engagement;
  audienceLocation: Buckets;
  audienceBrandAffinity: Buckets;
  audienceInterests: Buckets;
  influencerBrandAffinity: BrandWithMediaItems[];
  influencerFakeFollowersRatio: number;
  similarInfluencersFakeRatio: number;
  followersTimeseries: TimeSeriesItem[];
  engagementTimeseries: EngagementSeriesItem[];
  estimatedImpressions: number;
  estimatedMediaValue: number;
  estimatedReach: number;
}

export class AudienceStats {
  ageDistribution: Age;
  adultAudiencePercentage?: number;
  ageGenderDistribution: AgeGender | null;
  genderDistribution: Buckets;
  locationDistribution: AudienceLocation[];
  cityDistribution: { name: string; value: number }[];
}

export class BrandedContentStats {
  total: number;
  branded: number;
  nonBranded: number;
}

@Injectable()
export class InfluencerService implements BrandedContentService {
  constructor(private http: HttpClient) {}

  getInfluencer(username: string): Promise<Influencer> {
    return this.http
      .get(environment.api + `/api/users/profile/${username}`)
      .toPromise()
      .then(async (json: InfluencerProfileAPIResponse) => {
        // check needed when the credits not enough while trying to load a profile
        if (json) {
          return json.influencer;
        }
        return null;
      });
  }

  getInfluencerStats(username: string): Promise<InfluencerStats> {
    return this.http.get<InfluencerStats>(environment.api + `/api/users/stats/${username}`).toPromise();
  }

  getAudienceAgeGender(username: string, ageHistogram: Age): Promise<AgeGender> {
    return this.http
      .post<AgeGender>(environment.api + `/api/users/stats/${username}/audience/age-gender`, { ageHistogram })
      .toPromise();
  }

  getInfluencerBrandedContentStats(username: string, days: number): Promise<BrandedContentStats> {
    return this.http
      .post<BrandedContentStats>(environment.api + `/api/users/stats/${username}/distributions/branded-posts`, { days })
      .toPromise();
  }

  getInfluencers(searchFilters: Readonly<InfluencerSearchFilters>, page: number): Promise<InfluencerAPIResponse> {
    const influencerFilters = clone(searchFilters.influencerFilters);
    if (influencerFilters.username) {
      influencerFilters.username = utils.getUsernameFromInstagramUrlIfPresent(influencerFilters.username);
      influencerFilters.username = influencerFilters.username.trim();
    }
    const audienceFilters = clone(searchFilters.audienceFilters);
    update(influencerFilters, 'locations', (locations: Location[]) => {
      return locations.map((location) => ({
        name: location.name,
        isIncluded: location.isIncluded,
      }));
    });
    return this.http
      .post(
        `${environment.api}/api/users/`,
        {
          influencerFilters: update(influencerFilters, 'brands', (brands: BrandLookup[]) =>
            map(brands, (brand) => {
              return {
                name: brand.username,
                isIncluded: brand.isIncluded,
              };
            }),
          ),
          audienceFilters: update(audienceFilters, 'brands', (brands: BrandLookup[]) => map(brands, 'username')),
        },
        {
          params: {
            page: page.toString(),
            sortKey: searchFilters.sortKey,
          },
        },
      )
      .toPromise()
      .then(async (json: InfluencerAPIResponse) => json);
  }

  async getViewedInfluencers(page = 0): Promise<ViewedInfluencer[]> {
    return this.http
      .get(`${environment.api}/api/influencers/viewed`, { params: { page: page.toString() } })
      .toPromise()
      .then((response: any) => response);
  }

  async getViewedInfluencersAnalyses(): Promise<{
    monthlyLimit: number;
    thisMonthLimit: number;
    thisMonthCount: number;
    totalCount: number;
  }> {
    return this.http
      .get(`${environment.api}/api/influencers/viewed/analysis`)
      .toPromise()
      .then((response: any) => response);
  }

  searchOnInstagram(username: string): Promise<Influencer> {
    return this.http
      .post(`${environment.api}/api/users/instagram/${username}`, {})
      .toPromise()
      .then(async (json: Influencer) => json);
  }

  getTopInfluencers(
    location: string,
    sortKey: InstagramInfluencerSortKey,
    suspiciousFollowersPercent: number,
  ): Promise<InfluencerAPIResponse> {
    return this.http
      .get(`${environment.api}/api/users/top`, {
        params: {
          location,
          sortKey,
          suspiciousFollowersPercent: suspiciousFollowersPercent.toString(),
        },
      })
      .toPromise()
      .then(
        (response: InfluencerAPIResponse) => response,
        () => <InfluencerAPIResponse>{},
      );
  }

  getInfluencersMailList(
    usernames: string[],
    platform: Platforms = Platforms.instagram,
    allUsers = false,
    groupSlugName: string = null,
  ): Promise<Map<string, string>> {
    return this.http
      .post(
        environment.api + `/api/users/metadataemail`,
        {
          usernames,
          platform,
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
        },
      )
      .toPromise()
      .then((response: string[][]) => new Map(response.map(([id, email]) => [id, email])));
  }

  profileInfluencer(username): Promise<any> {
    return this.http.post(`${environment.api}/api/jobs/instagram/influencers/profile/${username}`, null).toPromise();
  }

  profileInfluencersList(
    usernames: string[],
    groupName: string | undefined,
    groupSlugName: string | undefined,
  ): Promise<string[]> {
    return this.http
      .post(`${environment.api}/api/jobs/instagram/influencers/profileList`, {
        usernames: usernames,
        groupName: groupName,
        groupSlugName: groupSlugName,
      })
      .toPromise()
      .then((response: any) => response.profiledUsernames);
  }

  lookupInfluencers(term: string): Observable<InfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    term = utils.getUsernameFromInstagramUrlIfPresent(term);
    return this.http.get<InfluencerLookup[]>(`${environment.api}/api/users`, {
      params: { term: term.trim() },
    });
  }

  getPreviewInfluencer(username: string): Promise<PreviewInfluencer> {
    return this.http
      .get(environment.api + `/api/users/preview/profile/${username}`)
      .toPromise()
      .then(async (json: PreviewInfluencer) => {
        return json;
      });
  }

  getPreviewInfluencerStats(username: string): Promise<PreviewInfluencerStats> {
    return this.http
      .get(environment.api + `/api/users/preview/profile/${username}/stats`)
      .toPromise()
      .then(async (json: PreviewInfluencerStats) => {
        return json;
      });
  }

  exportInfluencerProfile(username: string): Promise<any> {
    return this.http
      .get(`${environment.api}/api/users/export/profile/${username}`, {
        responseType: 'blob',
      })
      .toPromise();
  }

  exportInfluencersFromSearch(influencers: Influencer[], allUsers = false, groupSlugName: string = null): Promise<any> {
    const influencerUsernames = [];
    each(influencers, function (influencer) {
      influencerUsernames.push(influencer.profile.username);
    });
    return this.http
      .post(
        `${environment.api}/api/users/export/search`,
        {
          usernames: influencerUsernames,
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
          responseType: 'blob',
        },
      )
      .toPromise();
  }

  getSimilarInfluencers(username: string): Promise<InfluencerLookup[]> {
    return this.http
      .get(environment.api + `/api/users/recommendations/users/${username}`)
      .toPromise()
      .then(async (json: InfluencerLookup[]) => {
        return json;
      });
  }

  getInfluencerLocation(username: string): Promise<string[]> {
    return this.http.get<string[]>(environment.api + `/api/users/location/${username}`).toPromise();
  }

  getInfluencerPriceRange(username: string): Promise<InstagramPredictedPriceRange[]> {
    return this.http
      .get<InstagramPredictedPriceRange[]>(environment.api + `/api/users/price/${username}`)
      .toPromise()
      .catch((err) => {
        if (err.status === 404) {
          return [];
        }
        throw err;
      });
  }

  getInfluencerProfileImage(imgUrl): Promise<{ image: string }> {
    return this.http
      .post(environment.api + `/api/users/profile/image`, {
        url: imgUrl,
      })
      .toPromise()
      .catch((err) => {
        console.log('Error in fetching profile image');
        return err;
      });
  }

  getAudienceCountByAge(
    username: string,
    minAge: number,
    maxAge?: number,
  ): Promise<{
    count: number;
    percentage: number;
  }> {
    let url = environment.api + `/api/users/stats/${username}/audience/count/age?min_age=${minAge}`;
    if (maxAge) {
      url = `${url}&max_age=${maxAge}`;
    }
    return this.http
      .get(url)
      .toPromise()
      .catch((err) => {
        console.log(`Error while geting audience count by age range for ${username}: ${minAge} to ${maxAge}`);
        return err;
      });
  }

  getInfluencersByUsernames(
    usernames: string[],
    platform: PlatformsV2 = Platforms.instagram,
    campaignSlugName?: string,
  ): Promise<InfluencerLookup[]> {
    return this.http
      .post<InfluencerLookup[]>(environment.api + `/api/influencers/basic-info`, {
        usernames,
        platform,
        ...(!!campaignSlugName && { campaignSlugName }),
      })
      .toPromise()
      .catch((err) => {
        return err;
      });
  }
}

export class PreviewInfluencerService implements BrandedContentService {
  getInfluencerBrandedContentStats(username: string, days: number): Promise<BrandedContentStats> {
    const brandedContentStatsData: { [key: number]: BrandedContentStats } = {
      7: { total: 3, branded: 0.8, nonBranded: 0.2 },
      30: { total: 11, branded: 0.3, nonBranded: 0.7 },
      90: { total: 35, branded: 0.65, nonBranded: 0.35 },
    };

    return Promise.resolve(brandedContentStatsData[days]);
  }
}
