/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AggregatedComments,
  BasicCampaignInfo,
  CampaignReport,
  CampaignReportData,
  MediaComment,
  CampaignEditForm,
  CampaignForm,
} from '../../interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommentSentimentType, PlatformsUpperEnum } from '../../enums';

export class PostCampaignResult {
  msg: string;
}

@Injectable()
export class CampaignReportsService {
  private platform$ = new BehaviorSubject<string>('');
  reportPlatform = this.platform$.asObservable();
  campaignReport$: Subject<CampaignReportData> = new Subject<CampaignReportData>();
  getReportTrigger$: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient) {}

  updatePlatform(platform: string) {
    this.platform$.next(platform);
  }

  upsertCampaign(campaign: CampaignForm, platform: string): Promise<PostCampaignResult> {
    return this.http
      .post(`${environment.api}/api/campaign-reports/upsert`, JSON.parse(JSON.stringify(campaign)), {
        params: { platform },
      })
      .toPromise() as Promise<PostCampaignResult>;
  }

  getExistingCampaigns(platform: string): Promise<{ campaigns: BasicCampaignInfo[] }> {
    return this.http
      .get<{ campaigns: BasicCampaignInfo[] }>(`${environment.api}/api/campaign-reports/generated/all`, {
        params: { platform },
      })
      .toPromise();
  }

  addToExistingCampaign(campaignEditData: CampaignEditForm, platform: string): Promise<PostCampaignResult> {
    return this.http
      .post(`${environment.api}/api/campaign-reports/edit`, campaignEditData, {
        params: { platform },
      })
      .toPromise() as Promise<PostCampaignResult>;
  }

  getCampaignReport(campaignId: string, platform: string): void {
    this.http
      .get(`${environment.api}/api/campaign-reports/${campaignId}`, {
        params: { platform },
      })
      .subscribe((campaignData) => this.campaignReport$.next(campaignData as CampaignReportData));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCampaignReports(page, campaignName?: string, platform?: string): Promise<CampaignReport[]> {
    const params = campaignName ? { page, campaignName, platform } : { page, platform };
    return this.http
      .get<CampaignReport[]>(`${environment.api}/api/campaign-reports/`, {
        params,
      })
      .toPromise() as Promise<CampaignReport[]>;
  }

  softDeleteCampaignReport(campaignId: string, platform: string): Promise<any> {
    return this.http
      .delete(`${environment.api}/api/campaign-reports/${campaignId}`, { params: { platform } })
      .toPromise();
  }

  mergeCampaignReports(campaignName: string, campaignIds: string[], platform: string): Promise<CampaignReport> {
    return this.http
      .post(
        `${environment.api}/api/campaign-reports/merge`,
        {
          campaignIds,
          campaignName,
        },
        {
          params: { platform },
        },
      )
      .toPromise() as Promise<CampaignReport>;
  }

  exportCampaignReport(campaignId: string, platform: string): Promise<any> {
    return this.http
      .get(`${environment.api}/api/campaign-reports/export/${campaignId}`, {
        responseType: 'blob',
        params: { platform },
      })
      .toPromise();
  }

  exportCampaignSentiment(campaignId: string, platform: string): Promise<Blob> {
    return this.http
      .get(`${environment.api}/api/campaign-reports/exportSentiment/${campaignId}`, {
        responseType: 'blob',
        params: { platform },
      })
      .toPromise();
  }

  deleteMediaFromCampaign(campaignId: string, mediaPk: string, platform: string): Promise<boolean> {
    return this.http
      .delete(`${environment.api}/api/campaign-reports/${campaignId}/media/${mediaPk}`, { params: { platform } })
      .toPromise() as Promise<boolean>;
  }

  refreshCampaignReport(campaignId: string, platform: string): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/campaign-reports/refresh`,
        {
          campaignId: campaignId,
        },
        { params: { platform } },
      )
      .toPromise();
  }

  renameCampaignReport(campaignId: string, newName: string, platform: string): Promise<any> {
    return this.http
      .put(`${environment.api}/api/campaign-reports/${campaignId}/rename/${newName}`, {}, { params: { platform } })
      .toPromise();
  }

  getAggregatedComments(mediaComments: MediaComment[]): AggregatedComments {
    const aggregatedComment: AggregatedComments = { positive: [], negative: [], neutral: [] };
    mediaComments.forEach((comment) => {
      if (['positive', 'slightly positive'].includes(comment.sentiment)) {
        aggregatedComment.positive.push({
          id: comment.id,
          content: comment.text,
          username: comment?.username,
          mediaPk: comment.mediaPk,
        });
      }
      if (['negative', 'slightly negative'].includes(comment.sentiment)) {
        aggregatedComment.negative.push({
          id: comment.id,
          content: comment.text,
          username: comment?.username,
          mediaPk: comment.mediaPk,
        });
      }
      if (comment.sentiment === 'neutral') {
        aggregatedComment.neutral.push({
          id: comment.id,
          content: comment.text,
          username: comment?.username,
          mediaPk: comment.mediaPk,
        });
      }
    });
    return aggregatedComment;
  }

  async editCommentSentiment(
    mediaId: string,
    commentId: string,
    sentiment: CommentSentimentType,
    platform: PlatformsUpperEnum,
  ): Promise<any> {
    return this.http
      .put(`${environment.api}/api/campaign-reports/media-comments/${mediaId}/${commentId}/sentiment`, {
        sentiment,
        platform,
      })
      .toPromise();
  }
}
