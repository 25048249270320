<div class="notes-list">
  <time class="date-info t-black-light t-size-8">
    {{ dateValue | date }}
  </time>
  <div *ngFor="let link of links; let linkIndex = index">
    <div *ngIf="linkIndex !== 0" class="note-date">
      <div class="notes-v-separator"></div>
    </div>
    <ngx-link (deleteLinkEvent)="captureDeleteLink($event)" (editLinkEvent)="captureEditLink($event)"
      [linkValue]="link" [linkIndex]="linkIndex"></ngx-link>
  </div>
</div>