import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';
import { from, never, Observable, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ServerErrorComponent } from '../../@theme/components';
import { retryStrategyFor } from '../../helpers/retry-strategy';
import { AuthService } from './auth.service';
import { escapeRouteParamsText } from '../../utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  ERROR_500_EXCLUDED_ENDPOINTS = new Set(['/api/chats/initiateChat']);

  constructor(private router: Router, private authService: AuthService, private modalService: NgbModal) {}

  captureError(error: HttpErrorResponse, message: string): void {
    Sentry.captureException(new Error(message), (scope) => {
      scope.setTransactionName(error.name);
      scope.setTag(error.name, error.status);
      scope.setExtra('extra', error);
      return scope;
    });
  }

  displayModal(): void {
    this.modalService.dismissAll();
    this.modalService.open(ServerErrorComponent, { size: 'lg', centered: true });
  }

  get isCollaboratorPortal(): boolean {
    return this.router.url.includes('/collabs');
  }

  collaboratorPortalErrorHandler(error: HttpErrorResponse): void {
    if (error.status === 401) {
      localStorage.clear();
      this.router.navigate(['/collabs/login']);
    }
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<
    HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<unknown> | HttpUserEvent<unknown>
  > {
    return next
      .handle(this.cloneReqWithOrganizationId(req))
      .pipe(retryWhen(retryStrategyFor(req.url)))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (new URL(req.url).host !== environment.host && new URL(req.url).host !== environment.sequenceApiHost) {
            return throwError(error);
          }
          if (this.isCollaboratorPortal) {
            this.collaboratorPortalErrorHandler(error);
            return never();
          }

          if (error.status === 401 && error.error && ['USER_SETTINGS_CHANGED'].includes(error.error.code)) {
            return from(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (async (req: HttpRequest<any>, next: HttpHandler) => {
                // Refresh the current accessToken since it is expired from the backend
                await this.authService.refreshToken();
                // Retry the same request with the modifed access token
                const token = this.authService.getAccessTokenFromCache();
                const modifiedRequest = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                return next.handle(modifiedRequest).toPromise();
              })(req, next),
            );
          } else if (
            error.status === 401 &&
            error.error &&
            ['MAX_ACTIVE_SESSIONS_REACHED'].includes(error.error.code)
          ) {
            this.authService.notifyUnauthorized();
            const escapedMessage = escapeRouteParamsText(error.error?.message);
            this.router.navigate(['auth', 'login', { event: 'session-revoked', message: escapedMessage }]);
            return never();
          } else if (error.status === 401 && error.error && ['TOKEN_EXPIRED'].includes(error.error?.code)) {
            return from(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (async (req: HttpRequest<any>, next: HttpHandler) => {
                // Refresh the current accessToken since it is expired from the backend
                await this.authService.refreshToken();

                // Retry the same request with the modifed access token
                const token = this.authService.getAccessTokenFromCache();
                const modifiedRequest = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
                return next.handle(modifiedRequest).toPromise();
              })(req, next),
            );
          } else if (error.status === 401) {
            this.authService.notifyUnauthorized();
            this.router.navigate(['auth', 'login', { event: 'session-expired' }]);
            return never();
          } else if (error.status === 402) {
            this.router.navigate(['pages', 'account'], {
              queryParams: { plan: 'pay-per-use' },
            });
            return never();
          } else if (error.status === 403) {
            this.captureError(error, 'Insufficient Permission');
            this.router.navigate(['403InsufficientPermission']);
            return never();
          } else if (error.status === 500) {
            this.captureError(error, '500 Internal Server Error');
            const endpoint = isAbsoluteURL(req.url) ? new URL(req.url).pathname : req.url;
            if (this.ERROR_500_EXCLUDED_ENDPOINTS.has(endpoint)) {
              return throwError(error);
            }
            this.displayModal();
            return never();
          } else if (error.status === 0) {
            this.captureError(error, 'Server returned status code 0');
            this.displayModal();
            return never();
          } else if (error.status === 503 && error.error?.message === 'Affable_Site_Under_Maintenance') {
            this.router.navigate(['ServerUnderMaintenance']);
            return never();
          } else {
            return throwError(error);
          }
        }),
      );
  }

  private cloneReqWithOrganizationId(req: HttpRequest<unknown>): HttpRequest<unknown> {
    const organizationId = this.authService.getOrganizationId();
    const internalAPIs = [environment.host, environment.sequenceApiHost];
    // Clone the request and add the organization Id as a header
    let reqOrgProcessed = req;
    if (organizationId && internalAPIs.some((api) => req.url.includes(api))) {
      reqOrgProcessed = req.clone({
        setHeaders: {
          'x-affable-organization-id': organizationId,
        },
      });
    }
    return reqOrgProcessed;
  }
}

const isAbsoluteURL = (url: string): boolean => {
  const pattern = /^https?:\/\//i;
  return pattern.test(url);
};
