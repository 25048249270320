<nb-card size="large">
  <nb-card-header>
    <span>
      Branded Content Distribution
    </span>
    <ngx-tooltip [tooltip]="tooltipMessages.get('branded-content-distribution')"></ngx-tooltip>
  </nb-card-header>

  <ngx-loader *ngIf="loading" class="profile-card-placeholder"></ngx-loader>
  <nb-card-body *ngIf="!loading">
    <button class="filter-btn" [popper]="brandedContentFilterPopper" popperTrigger="click"
      popperApplyClass="context-menu-md" popperPlacement="bottom-end">
      {{ filterKey.label }} <i class="fas fa-chevron-down"></i>
    </button>

    <div *ngIf="totalContents" class="visitors-statistics">
      <div class="statistics-header">
        <div class="visitors-value">{{ totalContents }}</div>
        <div class="visitors-title">Total Posts</div>
      </div>

      <div class="statistics-chart">
        <div echarts class="echart" [options]="chartData"></div>
      </div>

      <div class="statistics-footer">
        <div class="chart-values">
          <span class="chart-value">{{ brandedContentPercentage }}%</span>
          <span class="chart-value">{{ 100 - brandedContentPercentage }}%</span>
        </div>

        <div class="legends">
          <div class="legend">
            <div class="legend-item-color" [style.background]="'linear-gradient(90deg, #ffcb17 0%, #ff874c 100%)'">
            </div>
            <div class="legend-title">Branded Content</div>
          </div>

          <div class="legend">
            <div class="legend-item-color" [style.background]="'#8defbb'"></div>
            <div class="legend-title">Non Branded Content</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!totalContents" class="profile-card-placeholder">
      Branded Content distribution data not found for {{ influencerUsername }}
    </div>

    <popper-content #brandedContentFilterPopper>
      <div class="list-group">
        <div class="list-group-item list-group-item-action cursor-pointer" *ngFor="let filterOption of filterOptions"
          (click)="filterKeyChangeHandler(filterOption); brandedContentFilterPopper.hide();">
          <div class="item-text">{{ filterOption.label }}</div>
          <i class="ion-md-checkmark" *ngIf="filterOption.value === filterKey.value"></i>
        </div>
      </div>
    </popper-content>
  </nb-card-body>
</nb-card>