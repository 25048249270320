import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-profile-campaign-email-modal',
  templateUrl: './profile-campaign-email-modal.component.html',
  styleUrls: [],
})
export class ProfileCampaignEmailModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  @Input() mailButton = 'SEND MAIL';
  @Input() showMailButton = true;

  closeModal(): void {
    this.activeModal.close();
  }

  toCampaign(decision: boolean): void {
    this.activeModal.close(decision);
  }
}
