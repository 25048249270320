<ng-template [ngIf]="canPrint">
  <!--Stats Header-->
  <div class="printed-card">
    <div class="title-bar"></div>
    <div class="title">{{reportName}}</div>
    <div class="created-at" *ngIf="createdAt">
      <i class="fa fa-calendar"></i>
      <div class="date">{{createdAt}}</div>
    </div>

    <!--Stats -->
    <div class="stats">
      <div *ngFor="let statCard of statsCardLabels" class="stats-item">
        <div class="header">
          <div class="icon">
            <i class="{{statCard.icon}}"></i>
          </div>
          <div class="title">
            <div class="label"> {{statCard.label}}</div>
            <div class="subtitle">{{statCard.subtitle}}</div>
          </div>
          <div class="body">
            <div class="value">{{statCard.value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Audience Analysis -->
  <div class="report-card">
    <div class="title-bar"></div>
    <div class="title">Audience Analysis</div>
    <div class="charts-wrapper">
      <div class="chart-card">
        <div class="chart-card-title">
          <div class="fa fa-chevron-circle-right"></div>
          Audience Age Distribution
        </div>
        <div class="chart-card-body">
          <ngx-echarts-bar [isAgeGender]="true" [dataOptions]="ageDataOptions"></ngx-echarts-bar>
        </div>
      </div>

      <div class="chart-card">
        <div class="chart-card-title">
          <div class="fa fa-chevron-circle-right"></div>
          Audience Gender Distribution
        </div>
        <div class="chart-card-body">
          <ngx-echarts-pie [dataOptions]="genderDataOptions" [displayLabel]="true"></ngx-echarts-pie>
        </div>
      </div>

      <div class="chart-card">
        <div class="chart-card-title">
          <div class="fa fa-chevron-circle-right"></div>
          Audience Location Distribution
        </div>
        <div class="chart-card-body" *ngIf="locationDataOptions">
          <ngx-echarts-bar [dataOptions]="locationDataOptions"></ngx-echarts-bar>
        </div>
      </div>

    </div>
  </div>

  <!--Comment Analysis-->
  <div class="report-card" *ngIf="!isMediaCommentAnalysisEmpty(aggregatedCommentAnalysis) || !isMediaCommentsEmpty(mediaComments)">
    <div class="title-bar"></div>
    <div class="title">Comment Analysis</div>
    <div class="charts-wrapper">
      <div class="chart-card-body">
        <ng-container  *ngIf="!isMediaCommentAnalysisEmpty(aggregatedCommentAnalysis)">
          <ngx-report-aggregated-comment-analysis [commentAnalysis]="aggregatedCommentAnalysis" [printed]="true"></ngx-report-aggregated-comment-analysis>
        </ng-container>
        <ng-container *ngIf="!isMediaCommentsEmpty(mediaComments)">
          <ngx-report-comment-snapshots [maxComments]="3" [mediaComments]="mediaComments"></ngx-report-comment-snapshots>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Posts -->
  <div class="printed-posts" *ngIf="mediaReports.length">
    <div class="title-bar"></div>
    <div class="title">Posts</div>
    <div class="charts-wrapper">
      <div class="chart-card-post" *ngFor="let post of mediaReports; let index= index">
        <!--        Post image & fullName-->
        <div class="chart-post-card-body">
          <div class="chart-card " style="display: inline-block">
            <div class="chart-card-title" >
              <div class="fa fa-link"></div>
              <label>{{index + 1}} </label> |  <a class="col-md-10" href="{{getUserProfileUrl + post.creator.username}}" target="_blank"> {{post.creator.fullName}} </a>
            </div>

            <div class="chart-card-body" >
              <div class="post-media">
                <div class="post-image">
                  <a href="{{post.media.urlMedium}}" target="_blank">
                    <img [src]="post.media.urlMedium"
                         defaultImage="/assets/images/print/post.svg" alt=""/>
                  </a>
                </div>
                <div class="caption">{{post.media.caption}}</div>
              </div>
            </div>
          </div>
          <!-- ENGAGEMENT  - PRINTED-->
          <div class="chart-card">
            <div class="chart-card-title">
              <div class="fa fa-chevron-circle-right"></div>
              Engagement
            </div>
            <div class="chart-post-card-body">
              <div class="chart-post-wrapper">
                <div>
                  <ngx-gauge [gaugeOptions]="post.aggregateData.likesEngagement" [printingStyle]="'true'"></ngx-gauge>
                </div>
                <div>
                  <ngx-gauge [gaugeOptions]="post.aggregateData.commentsEngagement"
                             [printingStyle]="'true'"></ngx-gauge>
                </div>
              </div>

            </div>
          </div>

          <!--  Comment Analysis Data - PRINTED  -->
          <div class="chart-card" *ngIf="!isMediaCommentAnalysisEmpty(post.commentAnalysis) || !isMediaCommentsEmpty(mediaComments)" >
            <div class="chart-card-title">
              <div class="fa fa-chevron-circle-right"></div>
              Comment Analysis
            </div>
            <div class="chart-post-card-body">
              <div class="chart-post-wrapper" *ngIf="!isMediaCommentAnalysisEmpty(post.commentAnalysis)">
                <div class="full-width-chart">
                  <ngx-report-media-comment-analysis [printed]="true" [commentAnalysis]="post.commentAnalysis">
                  </ngx-report-media-comment-analysis>
                </div>
              </div>

              <div class="chart-post-wrapper" *ngIf="!isMediaCommentsEmpty(mediaComments)">
                <div>
                  <ngx-report-comment-snapshots [maxComments]="1" [media]="true" [name]="post.creator.fullName"
                                                [picture]="post.creator.picture" [mediaComments]="mediaComments"
                                                [filterByMediaPk]="post.media.pk">
                  </ngx-report-comment-snapshots>
                </div>
              </div>

            </div>
          </div>

          <!--TODO: check for COST PER ENGAGEMENT -->
          <div class="" style="display: flex; gap: 0 15px">
            <!--GENDER DISTRIBUTION-->
            <div *ngIf="post.aggregateData.maleDistribution && post.aggregateData.femaleDistribution"
                 class="chart-card">
              <div class="chart-card-title">
                <div class="fa fa-chevron-circle-right"></div>
                Gender Distribution
              </div>
              <div class="chart-card-body">
                <div>
                  <i class="ion-md-man media-gender-icon"></i>
                  {{post.aggregateData.maleDistribution | percent: '1.0-2'}}
                </div>
                <div>
                  <i class="ion-md-woman media-gender-icon"></i>
                  {{post.aggregateData.femaleDistribution | percent: '1.0-2'}}
                </div>
              </div>
            </div>

            <!--LOCATION DISTRIBUTION-->
            <div *ngIf="post?.likersLocation?.length" class="chart-card">
              <div class="chart-card-title">
                <div class="fa fa-chevron-circle-right"></div>
                Location Distribution
              </div>
              <div class="chart-card-body">
                <div class="printed-location" *ngFor="let location of post?.likersLocation">
                  <div class="label">{{location.label}}</div>
                  <div class="count">{{location.count | percent: '1.0-2'}}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- AGE DISTRIBUTION -->
          <div *ngIf="showAgeDistribution(post?.aggregateData.age)" class="chart-card">
            <div class="chart-card-title">
              <div class="fa fa-chevron-circle-right"></div>
              Age Distribution
            </div>
            <div class="chart-card-body">
              <table class="table">
                <thead>
                <tr>
                  <th *ngFor="let ageBucket of ageBuckets">{{ageBucket}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td *ngFor="let age of post?.aggregateData.age">{{age}}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Estimated reach and media value -->
          <div class="chart-card">
            <div *ngIf="post.estimatedReach" class="chart-card-title">
              <div class="fa fa-chevron-circle-right"></div>
              Estimated reach and media value
            </div>
            <div *ngIf="!post.estimatedReach" class="chart-card-title">
              <div class="fa fa-chevron-circle-right"></div>
              Estimated impressions and media value
            </div>
            <div class="chart-card-body">
              <div class="col-md-6" *ngIf="post.estimatedReach">
                <label>Est. Reach</label> {{post.estimatedReach | formatNumber:2}}
              </div>
              <div class="col-md-6">
                <label>EMV</label> {{post.estimatedMediaValue | currency:currency }}
              </div>
              <div class="col-md-6">
                <label>Est. Impressions</label> {{post.estimatedImpressions | formatNumber: 2}}
              </div>
              <div *ngIf="platform.toLowerCase() == Platforms.tiktok.toLowerCase() || 
                          (platform.toLowerCase() == Platforms.instagram.toLowerCase() && post.media.mediaType == 'video')" class="col-md-6">
                  <label>Video views</label> {{post.estimatedImpressions | formatNumber: 2}}
                </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>


</ng-template>

