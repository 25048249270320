import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PlatformsV2, ProposalStatusAPI } from '../../../enums';
import {
  CampaignInfluencer,
  CampaignInfluencersResponse,
  CampaignOrder,
  formatProposalStatus,
  Proposal,
  ProposalsHistory,
  ProposalStatusUpdate,
  ProposalStatusUpdateResponse,
  WorkflowActionsStats,
} from '../../../interfaces';
import { tryGet } from '../../../utils';

@Injectable()
export class ProposalService {
  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  getInfluencerProposal(campaignSlug: string, influencerId: string): Promise<Proposal> {
    return this.http
      .get<Proposal>(`${environment.api}/api/campaigns/v2/${campaignSlug}/${influencerId}/proposal`)
      .toPromise();
  }

  updateInfluencersProposalStatus(
    campaignSlug: string,
    influencerId: string,
    proposalStatus: ProposalStatusAPI,
    proposalRemarkByBrand?: string,
  ): Observable<ProposalStatusUpdateResponse> {
    return this.http.put<ProposalStatusUpdateResponse>(
      environment.api + `/api/campaigns/v2/${campaignSlug}/proposal-status/${influencerId}`,
      {
        proposalStatus,
        proposalRemarkByBrand,
      },
    );
  }

  updateInfluencersProposalsStatus(
    campaignSlug: string,
    proposalsToUpdate: ProposalStatusUpdate[],
    proposalStatus: ProposalStatusAPI,
    hasIntegratedEmails: boolean,
  ): Observable<ProposalStatusUpdateResponse[]> {
    if (hasIntegratedEmails && proposalsToUpdate.length >= 2) {
      return from(this.sequentialInfluencersProposalsUpdate(campaignSlug, proposalsToUpdate, proposalStatus));
    } else {
      return this.http.put<ProposalStatusUpdateResponse[]>(
        environment.api + `/api/campaigns/v2/${campaignSlug}/proposal-status`,
        {
          proposals: proposalsToUpdate,
          proposalStatus,
        },
      );
    }
  }

  async sequentialInfluencersProposalsUpdate(
    campaignSlug: string,
    proposalsToUpdate: ProposalStatusUpdate[],
    proposalStatus: ProposalStatusAPI,
  ): Promise<ProposalStatusUpdateResponse[]> {
    const delay = (ms) => new Promise((_) => setTimeout(_, ms));
    const proposalsStatusUpdateRes: ProposalStatusUpdateResponse[] = [];
    for (const { influencerId, proposalRemarkByBrand } of proposalsToUpdate) {
      await this.updateInfluencersProposalStatus(campaignSlug, influencerId, proposalStatus, proposalRemarkByBrand)
        .toPromise()
        .then((proposalStatusUpdateRes: ProposalStatusUpdateResponse) => {
          proposalsStatusUpdateRes.push(proposalStatusUpdateRes);
        });
      await delay(2000);
    }
    return proposalsStatusUpdateRes;
  }

  createOrderForInfluencerProposal(campaignSlug: string, influencerId: string): Promise<CampaignOrder> {
    return this.http
      .post<CampaignOrder>(environment.api + `/api/campaigns/v2/${campaignSlug}/${influencerId}/orders`, {})
      .pipe(
        tap((v) => {
          if (tryGet(() => !!v.orderId)) {
            this.toastrService.success('Order Created Successfully');
          }
        }),
      )
      .toPromise()
      .catch((error) => {
        if (error instanceof HttpErrorResponse) {
          this.toastrService.error(error.error.message);
        }
        return error;
      });
  }

  getReceiveProposalsCount(campaignSlug: string, platform?: PlatformsV2): Promise<WorkflowActionsStats> {
    return this.http
      .get<WorkflowActionsStats>(environment.api + `/api/campaigns/v2/${campaignSlug}/workflow/actions/stats`, {
        params: {
          ...(platform && { platform }),
        },
      })
      .toPromise();
  }

  getContentNotPublishedInfluencers(
    campaignSlugName: string,
  ): Promise<{ message: string; influencers: CampaignInfluencer[] }> {
    return this.http
      .get<{ message: string; influencers: CampaignInfluencer[] }>(
        environment.api + `/api/campaigns/v2/${campaignSlugName}/workflow/proposals/reminders`,
      )
      .toPromise();
  }

  transformCampaignInfluencerResponse(campaignInfluencers: CampaignInfluencersResponse): CampaignInfluencersResponse {
    campaignInfluencers.influencers.map((influencer) => {
      influencer.influencerMetadata.proposalStatus = formatProposalStatus(influencer.influencerMetadata.proposalStatus);
      return influencer;
    });
    return campaignInfluencers;
  }

  getCampaignInfluencersWithProposals(
    campaignSlug: string,
    pageNumber: number,
    platform: PlatformsV2,
    name?: string,
    proposalStatusFilters?: ProposalStatusAPI[],
    sortCriteria?: string,
    sortOrder?: string,
    allInfluencers?: boolean,
  ): Observable<CampaignInfluencersResponse> {
    const params = {
      pageNumber: pageNumber.toString(),
      ...(proposalStatusFilters ? { proposalStatusFilters } : {}),
      ...(!!name && { search: name }),
      ...(!!allInfluencers && { allInfluencers: allInfluencers.toString() }),
      platform: platform.toUpperCase(),
      sortCriteria,
      sortOrder,
    };
    return this.http
      .get<CampaignInfluencersResponse>(environment.api + `/api/campaigns/V2/${campaignSlug}/workflow/proposals`, {
        params,
      })
      .pipe(
        map((campaignInfluencers: CampaignInfluencersResponse) =>
          this.transformCampaignInfluencerResponse(campaignInfluencers),
        ),
      );
  }
  disableInfluencerReminder(campaignSlugName: string): Promise<{ success: boolean }> {
    return this.http
      .post<{ success: boolean }>(
        environment.api + `/api/campaigns/v2/${campaignSlugName}/workflow/proposals/reminders/disable`,
        {},
      )
      .toPromise();
  }

  getProposalsHistory(campaignSlugName: string, influencerId: string): Promise<ProposalsHistory[]> {
    return this.http
      .get<ProposalsHistory[]>(
        `${environment.api}/api/campaigns/v2/${campaignSlugName}/${influencerId}/proposals-history`,
      )
      .toPromise();
  }

  async createOrderForInfluencerProposalHistory(
    campaignSlugName: string,
    influencerId: string,
    proposalNumber: number,
  ) {
    return this.http
      .post<CampaignOrder>(
        environment.api + `/api/campaigns/v2/${campaignSlugName}/${influencerId}/proposals-history/orders`,
        { proposalNumber },
      )
      .pipe(
        tap((v) => {
          if (tryGet(() => !!v.orderId)) {
            this.toastrService.success(`Order Created Successfully for Proposal ${proposalNumber} from History`);
          }
        }),
        catchError(() => EMPTY),
      )
      .toPromise();
  }
}
