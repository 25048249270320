<div class="modal-header">
  <h2 class="modal-title">Estimate Audience</h2>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="results">
    <ng-template *ngTemplateOutlet="listUsernames; context: {usernames: availableProfileUsernames, title: 'Available'}"></ng-template>
    <hr *ngIf="availableProfileUsernames.length && inProgressProfileUsernames.length">
    <ng-template *ngTemplateOutlet="listUsernames; context: {usernames: inProgressProfileUsernames, title: 'In Progress'}"></ng-template>
    <hr *ngIf="failedProfileUsernames.length && (inProgressProfileUsernames.length || availableProfileUsernames.length)">
    <ng-template *ngTemplateOutlet="listUsernames; context: {usernames: failedProfileUsernames, title: 'Failed'}"></ng-template>
  </div>
</div>
<div class="modal-footer">
  <ai-button
  type="fill"
  (click)="retryFailed()"
  label="RETRY FAILED ESTIMATES"
  *ngIf="failedProfileUsernames.length"
  ></ai-button>
  <ai-button
  type="fill"
  (click)="closeModal()"
  label="DONE"
  *ngIf="!failedProfileUsernames.length"
  ></ai-button>
</div>


<ng-template #listUsernames let-usernames="usernames" let-title="title">
  <div *ngIf="usernames.length">
    <p class="list-title">Audience Estimates {{title}} ({{usernames.length}})</p>
    <div class="usernames-list">
      <a class="username" *ngFor="let username of usernames | slice:0:limit[title];"
      href="/pages/profile/{{username}}">{{username}}</a>
      <button 
      type="button" 
      class="btn-link" 
      (click)="limit[title] = 10000000"
      *ngIf="usernames.length > limit[title]">
      +{{ usernames.length - limit[title] }} More
      </button>
      <button 
      type="button" 
      class="btn-link" 
      (click)="limit[title] = showMoreLimit" 
      *ngIf="limit[title] > usernames.length && usernames.length > showMoreLimit">
      Show Less
      </button>
    </div>
  </div>
</ng-template>