<button type="button" [class]="classes" [disabled]="disabled" [style.background]="color">
  <section *ngIf="iconPadding && (prefix || suffix)"></section>
  <i [class.prefix-icon]="prefix && !iconPadding" [ngClass]="prefix" *ngIf="prefix"></i>
  <img class="prefix-image" *ngIf="prefixImage" [src]='prefixImage' alt="label">
  <span *ngIf="label" [ngClass]="textClass" class="{{typography}}" [style.color]="textColor">
    {{ label }}
  </span>
  <ng-content></ng-content>
  <i [class.suffix-icon]="suffix && !iconPadding" [class]="suffix" [class.split]="suffix && split" *ngIf="suffix"></i>
  <section *ngIf="iconPadding && (prefix || suffix)"></section>
</button>
