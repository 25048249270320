import { AfterContentChecked, Component, OnDestroy } from '@angular/core';
import { DropDownOverlayBaseComponent } from '../base-component/drop-down-overlay-base.component';
import { DropDownOption, GroupOption } from '../../control.interface';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isUndefined } from 'lodash';

@Component({
  selector: 'ai-overlay-container',
  templateUrl: './overlay.component.html',
  styleUrls: ['../dropdown.control.scss'],
})
export class OverlayComponent extends DropDownOverlayBaseComponent implements AfterContentChecked, OnDestroy {
  optionsToDisplay: DropDownOption;
  routerSubscription: Subscription;
  constructor(private router: Router) {
    super();
    this.routerSubscription = this.router.events.subscribe(() => {
      this.overlayRef.detach();
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.optionsToDisplay = { ...this.options };
    this.optionsToDisplay.groupOptions = [];

    this.options.groupOptions.forEach((group) => {
      if (group.items.length != 0) this.optionsToDisplay.groupOptions.push(group);
    });
  }

  toggleAccordion(group: GroupOption): void {
    if (isUndefined(group.isCollapsed)) {
      group.isCollapsed = true;
    } else {
      group.isCollapsed = !group.isCollapsed;
    }
  }
}
