/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../../@core/auth/auth.service';
import { Campaign, CampaignsService } from '../../@core/data/campaigns.service';
import { CategoryService } from '../../@core/data/category.service';
import utils from '../../@core/utils/utils';
import { Platform, Platforms, VeraPlatforms } from '../../enums';
import { BrandLookup, Hashtag, PartnerizeCampaign } from '../../interfaces';
import { DatePicker } from '../date-range/date-range.component';
import { InstagramDiscoveryService } from '../../@core/data/instagram/instagram-discovery.service';

@Component({
  selector: 'ngx-create-campaign-modal',

  templateUrl: './create-campaign-modal.component.html',
  styleUrls: ['./create-campaign-modal.component.scss'],
})
export class CreateCampaignModalComponent implements OnInit {
  @Input() navigateToCampaign = true;
  @Input() singlePlatformSelectionEnabled = false;
  @Input() enabledPlatform: Platform[];
  @Input() fromCommunityAndVeraEnabled: boolean;
  selectedInfluencersUsernames?: string[];
  groupSlugName?: string;
  allGroupUsers?: boolean;
  totalInfluencersInGroup?: number;

  platform: Platforms | VeraPlatforms;
  platforms: FormGroup;
  availablePlatforms: string[] = [];
  selectedPlatform: string;

  campaignName: string;
  campaignHashtags: string[];
  campaignMentions: string[];

  // form properties
  loadingBrands = false;
  brands: Observable<BrandLookup[]>;
  brandNameInput = new Subject<string>();

  date: DatePicker = new DatePicker();
  hashtags: Hashtag[] = [];
  errorMessage: string;
  lastInvalidHashtag = undefined;
  creatingCampaign = false;
  addingInfluencers = false;

  isPartnerizeEnabled = false;
  loadingPartnerizeCampaigns = true;
  partnerizeCampaigns: PartnerizeCampaign[] = [];
  partnerizeCampaignId: string;

  @ViewChildren('hashtagSelect') hashtagSelect: any[];
  isHashtagOnEditMode = false;
  VeraPlatforms = VeraPlatforms;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private categoryService: CategoryService,
    private toastrService: ToastrService,
    private campaignsService: CampaignsService,
    private router: Router,
    private fb: FormBuilder,
    private igDiscoveryService: InstagramDiscoveryService,
  ) {}

  async ngOnInit(): Promise<void> {
    const availablePlatforms: { [key: string]: FormControl } = {};
    Object.keys(Platforms)
      .filter((key) => key !== 'facebook' && key !== 'twitter')
      .forEach((v) => {
        this.availablePlatforms.push(v);
        availablePlatforms[v] = new FormControl(false);
      });
    this.platforms = this.fb.group(availablePlatforms);
    // unset to date to undefined.
    this.date.to = undefined;
    if (this.platform.toLowerCase() === VeraPlatforms.instagram.toLowerCase()) {
      this.brands = utils.buildAutocompleteObservable(
        this.brandNameInput,
        (term: string) => this.igDiscoveryService.lookupBrands(term),
        () => (this.loadingBrands = true),
        () => (this.loadingBrands = false),
      );
    } else {
      this.brands = utils.buildAutocompleteObservable(
        this.brandNameInput,
        (term: string) => this.categoryService.lookupBrands(term),
        () => (this.loadingBrands = true),
        () => (this.loadingBrands = false),
      );
    }

    if (this.authService.isPartnerizeEnabled()) {
      this.isPartnerizeEnabled = true;
      this.partnerizeCampaigns = await this.campaignsService.getPartnerizeCampaigns();
      this.loadingPartnerizeCampaigns = false;
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  validateForm(): boolean {
    if (this.hashtags.some((hashtag) => /[!@$%^&*`~ #£§()+\-=[\]{};':"\\|,.<>/?]/.test(hashtag.value))) {
      this.errorMessage = 'Invalid hashtags format!';
      return false;
    } else {
      this.errorMessage = '';
      return true;
    }
  }

  createCampaign(): void {
    const platformDetail =
      this.fromCommunityAndVeraEnabled && this.platform === Platforms.instagram
        ? VeraPlatforms.instagram
        : this.platform;
    if (this.validateForm()) {
      this.campaignHashtags = this.hashtags.map((hashtag) => hashtag.value);
      const partnerizeCampaign = this.partnerizeCampaigns.find(
        (campaign) => campaign.campaignId === this.partnerizeCampaignId,
      );
      this.creatingCampaign = true;
      this.campaignsService
        .createCampaign(
          this.campaignName.trim(),
          this.campaignHashtags,
          this.campaignMentions,
          this.date.from,
          this.date.to,
          platformDetail,
          Object.keys(this.platforms.value).filter((v) => !!this.platforms.value[v]),
          partnerizeCampaign,
        )
        .then(async (res) => {
          this.toastrService.success('Campaign created successfully');
          this.creatingCampaign = false;
          if (res['campaignSlugName']) {
            if (
              (this.selectedInfluencersUsernames && this.selectedInfluencersUsernames?.length > 0) ||
              this.allGroupUsers
            ) {
              await this.addInfluencersToCampaign(res);
            }
            // Passing response to set the timezone and campaignSlugName
            this.activeModal.close(this.createCampaignObject(res, platformDetail));
            if (this.navigateToCampaign) {
              this.automaticNavigationToCampaign(res['campaignSlugName']);
            }
          }
        })
        .catch((error) => {
          this.creatingCampaign = false;
          if ([429, 400].includes(error.status)) {
            this.errorMessage = error.error?.message;
          } else {
            this.toastrService.error('Failed to create campaign, please try again!');
          }
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createCampaignObject(campaignInfo, platformDetail): Campaign {
    const campaign: Campaign = new Campaign({
      campaignName: this.campaignName,
      mentions: this.campaignMentions,
      hashtags: this.campaignHashtags,
      from: this.date.from.getTime(),
      to: this.date.to ? this.date.to.getTime() : undefined,
      campaignSlugName: campaignInfo.campaignSlugName,
      timezone: campaignInfo.timezone,
      platform: platformDetail,
    });
    return campaign;
  }

  async addInfluencersToCampaign(campaign: Campaign): Promise<void> {
    const campaignSlug = campaign.campaignSlugName;
    const usernamesToBAdded = this.allGroupUsers ? [] : this.selectedInfluencersUsernames;
    this.addingInfluencers = true;
    return this.campaignsService
      .addInfluencersToCampaign(
        campaignSlug,
        usernamesToBAdded ?? [],
        this.platform,
        this.groupSlugName,
        this.allGroupUsers,
      )
      .then(() => {
        this.toastrService.success(
          (this.allGroupUsers ? this.totalInfluencersInGroup : usernamesToBAdded?.length) +
            ' Influencers added to the campaign',
        );
      })
      .catch((res) => {
        this.toastrService.error(res.error?.message);
      })
      .finally(() => {
        this.addingInfluencers = false;
      });
  }

  automaticNavigationToCampaign(campaignSlugName: string): void {
    const campaignBaseUrl =
      this.platform === Platforms.instagram
        ? '/pages/campaigns/view/'
        : this.platform.toLowerCase() === VeraPlatforms.instagram.toLowerCase()
        ? '/pages/veraInstagram/campaigns/view/'
        : '/pages/' + this.platform.toLocaleLowerCase() + '/campaigns/view/';
    this.router.navigateByUrl(campaignBaseUrl + campaignSlugName);
  }

  convertToHashtag = (capture: string) => {
    const hashtag = capture.trim().startsWith('#') ? capture.trim().slice(1) : capture.trim();
    const isHashtagValid = /[!@$%^&*`~ #£§()+\-=[\]{};':"\\|,.<>/?]/.test(hashtag);
    if (isHashtagValid) {
      this.errorMessage = `Invalid hashtags format!`;
      this.lastInvalidHashtag = hashtag;
      return undefined;
    } else {
      this.errorMessage = '';
      return hashtag ? { label: `#${hashtag}`, value: hashtag, editable: false } : undefined;
    }
  };

  hashtagKeyDown(event): void {
    if (event.target.value !== this.lastInvalidHashtag && !this.isHashtagOnEditMode) {
      this.errorMessage = '';
    }
    if (event.keyCode === 32 || event.keyCode === 13) {
      // Space || Enter key pressed
      this.hashtagSelect.forEach((element) => {
        if (element['searchTerm']) {
          const hashtag = this.convertToHashtag(element['searchTerm']);
          const isHashtagDuplicated = this.hashtags.find((item) => item.value == element['searchTerm'].trim());
          if (isHashtagDuplicated) {
            this.errorMessage = `This hashtag already exists! `;
            this.lastInvalidHashtag = element['searchTerm'];
          }
          if (hashtag) {
            this.hashtags = [...this.hashtags, hashtag];
          }
          element['searchTerm'] = '';
        }
      });
    }
  }

  get isCreateCampaignDisabled(): boolean {
    return (
      this.isHashtagOnEditMode ||
      !this.platform ||
      !this.campaignName ||
      this.campaignName.trim() === '' ||
      this.isInProgress
    );
  }

  handleTagClicked(item: Hashtag): void {
    this.errorMessage = '';
    item.editable = true;
    this.isHashtagOnEditMode = true;
  }

  saveEditTagOnEnterKey(event, item: Hashtag, input): void {
    if (event.keyCode === 13) {
      this.handleSaveEditTag(input, item);
    }
  }

  handleCloseEditTag(input, item: Hashtag): void {
    item.editable = false;
    input.value = item.value;
    this.isHashtagOnEditMode = false;
  }

  handleSaveEditTag(input, item: Hashtag): void {
    const isHashtagDuplicated = this.hashtags.find((item) => item.value == input.value);
    const canEditHashtag = input.value && !isHashtagDuplicated;

    if (canEditHashtag && this.convertToHashtag(input.value)) {
      item.value = input.value;
      item.label = this.convertToHashtag(input.value).label;
    }

    if (isHashtagDuplicated && input.value !== item.value) {
      this.errorMessage = `This hashtag already exists! `;
      this.lastInvalidHashtag = input.value;
    }
    item.editable = false;
    this.isHashtagOnEditMode = false;
  }

  handleSaveEditTagOnBlur(input, item: Hashtag) {
    if (this.isHashtagOnEditMode) {
      this.handleSaveEditTag(input, item);
    }
  }

  onPlatformSelectionChange(event) {
    this.platform = Platforms[event];
  }

  get isInProgress(): boolean {
    return this.creatingCampaign || this.addingInfluencers;
  }
}
