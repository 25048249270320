import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../@core/auth/auth.service';
import { UserService } from '../../../../@core/data/user.service';
import { SubscribedClientUser } from '../../../../enums';
import { UserguidingService } from '../../../../@core/data/userguiding.service';

@Component({
  selector: 'ngx-subscription-success',
  templateUrl: './subscription-success.component.html',
  styleUrls: ['./subscription-success.component.scss'],
})
export class PublicAppSubscriptionSuccess implements OnInit {
  subscriptionPlanName: string;
  trialDays: number;
  countDown: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private userguiding: UserguidingService,
  ) {}

  private delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  async ngOnInit(): Promise<void> {
    this.countDown = 10;
    this.subscriptionPlanName = this.route.snapshot.queryParamMap.get('plan');
    this.trialDays = +(this.route.snapshot.queryParamMap.get('trial') || 0);
    await this.authService.refreshToken();
    const user: SubscribedClientUser = await this.userService.getClientUser();
    const createdAt = this.authService.getCreatedAt();
    const ugUser = {
      id: user.id,
      email: user.email,
      name: user.name,
      createdAt,
    };
    this.userguiding.identify(ugUser, ugUser.id);

    for (let i = 9; i > 0; i--) {
      await this.delay(1000);
      this.countDown = i;
    }
    this.getStart();
  }

  getStart(): void {
    this.router.navigate(['pages', 'public']);
  }
}
