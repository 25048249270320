<nb-card size="medium" [class.extra-long-card]="extraLongCard">
  <nb-card-header>
    <span> Recent Posts</span>
    <ngx-tooltip [tooltip]="tooltip"></ngx-tooltip>
  </nb-card-header>
  <nb-card-body>
    <div class="posts">
      <nb-list>
        <nb-list-item *ngFor="let post of posts">
          <div *ngIf="isContentPosts" class="post-checkbox">
            <nb-checkbox class="card-check" status="success" [(ngModel)]="post.checked"
              [disabled]="!post.checked && checkedCount() >= maxCheck"></nb-checkbox>
          </div>
          <div *ngIf="isContentPosts" class="post-author">
            <a href="/pages/facebook/profile/{{getEncodedUsername(post.author?.username)}}" class="content-author"
              target="_blank" title="{{post.author?.name}}">
              <nb-user [name]="post.author?.name" [picture]="post.author?.profilePictureUrl"
                [title]="post.author?.username" size="large">
              </nb-user>
            </a>
            <div class="date">
              {{post.postedOn | date: 'mediumDate' }}
            </div>
          </div>
          <div class="post" (click)="openPostDetailsModal(post)">
            <div *ngIf="!isContentPosts" class="details">
              <ng-container [ngSwitch]="post.mediaType">
                <div class="date">
                  {{post.postedOn | date: 'mediumDate' }}
                </div>
              </ng-container>
            </div>
            <div
              *ngIf="(post.mediaType==='photo' || post.mediaType === 'event') && post.media?.url && !post.hasExpiredThumbnail">
              <img [src]="post.media.url" loading="lazy" (error)="handleExpiredImage(post)">
            </div>
            <div class="video-thumbnail"
              *ngIf="post.mediaType==='video' && post.media?.thumbnailUrl && !post.hasExpiredThumbnail">
              <i class="far fa-play"></i>
              <img [src]="post.media.thumbnailUrl" loading="lazy" (error)="handleExpiredImage(post)">
            </div>
            <div *ngIf="post.mediaType==='shared_post' && post.media?.thumbnailUrl && !post.hasExpiredThumbnail"
              [class.video-thumbnail]="post.media.mediaType==='video'">
              <i *ngIf="post.media.mediaType==='video'" class="far fa-play"></i>
              <img [src]="post.media.thumbnailUrl" (error)="handleExpiredImage(post)">
            </div>
            <div *ngIf="post.hasExpiredThumbnail">
              <iframe [src]="post.safePostUrl" style="border:none; overflow:hidden; width: 6rem; height: 5rem"
                allowTransparency="true" allow="encrypted-media"></iframe>
            </div>
            <div *ngIf="post.description" class="description block-with-text">
              {{post.description}}
            </div>
            <div *ngIf="!post.description && post.media?.description" class="description block-with-text">
              {{ post.media.description}}
            </div>
            <div *ngIf="!post.description && !post.media?.description" class="description not-found block-with-text">
              No description found!
            </div>
            <div *ngIf="post.statistics && !hideStats" class="stats">
              <div *ngIf="post.statistics.reactionsCount >= 0 " class="stat-item">
                <i class="far fa-thumbs-up"></i> {{ post.statistics.reactionsCount | formatNumber:2 }} reactions
              </div>
              <div *ngIf="post.statistics.commentsCount >= 0 " class="stat-item">
                <i class="far fa-comment"></i> {{post.statistics.commentsCount | formatNumber:2 }} comments
              </div>
              <div *ngIf="post.statistics.sharesCount >= 0 " class="stat-item">
                <i class="far fa-share"></i> {{post.statistics.sharesCount | formatNumber:2}} Shares
              </div>
              <div *ngIf="post.mediaType=='video' && post.statistics.viewsCount >= 0 " class="stat-item">
                <i class="far fa-eye"></i> {{post.statistics.viewsCount | formatNumber:2}} Views
              </div>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
  </nb-card-body>
  <nb-card-footer>
  </nb-card-footer>
</nb-card>