<nb-card>
    <nb-card-header class="af-heading-2">Add to Email Sequences
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </nb-card-header>
    <nb-card-body>
        <div class="af-sub-heading-2 ai-mb8">
            Influencers
        </div>
        <ai-influencer-select [selectedInfluencers]="copyOfSelectedInfluencers" [platform]="platform"
            [campaignSlugName]="campaignSlugName" [maxNumberOfInfluencersVisible]="6" [showLabel]="false"
            [selectedTerminationRule]="selectedSequenceTerminationRule"
            (updatedInfluencersEmitter)="receiveUpdatedInfluencers($event)" [singleItemClearable]="false">
        </ai-influencer-select>

        <div class="af-sub-heading-2 ai-mb8 email-sequences-header">
            Email Sequences

        </div>
        <form class="sequence-filter ai-mb8">
            <div>

                <div class="search-input">
                    <input type="text" name="searchTerm" placeholder="Search Sequence" [(ngModel)]="searchTerm"
                        (onEvent)="searchEmailSequences()" keyupDebounce trim />
                    <i class="fas" [ngClass]="{'fa-search':  !searchTerm, 'fa-times cursor-pointer': !!searchTerm}"
                        (click)="!!searchTerm && searchEmailSequences(true)"></i>
                </div>

            </div>
            <div class="sequence-filter-by">
                <span>
                    Filter by :
                    <ai-select label="" bindValue="value" bindLabel="label" [items]="filterOptions" name="filter"
                        [searchable]="false" [clearable]="false" [formControl]="filterTypeInput">
                    </ai-select>
                </span>
            </div>
        </form>
        <div class="sequences-table">
            <div *ngIf="!emailSequences.length && !loading" class="sequences-empty-container">
                <span class="sequences-empty-title">No sequences found</span>
            </div>
            <ngx-table *ngIf="(!!emailSequences.length && !loading) || loading" #sequencesTable [showCheckbox]="false" [data]="emailSequences" [hasMoreData]="hasMoreData"
                infiniteScrollContainer=".sequences-table" [loading]="loading" [paginationLoader]="paginationLoader"
                (loadNextPage)="loadNextPage()">
                <ng-template #headers>
                    <th>Select</th>
                    <th>Status</th>
                    <th>Sequence</th>
                    <th>Start date</th>
                    <th>Influencers</th>
                </ng-template>
                <ng-template #rows let-row>
                    <td class="text-center">
                        <input class="radio-btn mr-1" type="radio" name="emailSequence" [(ngModel)]="selectedSequence"
                            (ngModelChange)="onSequenceSelected($event)"
                            [value]="row" />
                    </td>
                    <td>
                        <ng-container *ngTemplateOutlet="row.isEnabled ? enabled : disabled">
                        </ng-container>
                        <ng-template #enabled>
                            <div class="status-chip enabled">Enabled</div>
                        </ng-template>
                        <ng-template #disabled>
                            <div class="status-chip disabled">Disabled</div>
                        </ng-template>
                    </td>
                    <td>{{row.name}}</td>
                    <td [ngStyle]="{ 'text-align': 'center' }">{{row.isEnabled ? (row.eventTimestamp.enabledAt |
                        date:'MMM d, yyyy') : '-'}}</td>
                    <td>{{row.influencersCount}}</td>
                </ng-template>
            </ngx-table>
        </div>
        <div class="row float-right">
            <ai-button label="CANCEL" (click)="closeModal()" type="ghost"></ai-button>
            <div *ngIf="addToSequenceButtonLoading || isGetWillBeTerminatedUsernamesLoading" class="ngx-loader-button">
                <ngx-loader class="inline text-sm"></ngx-loader>
            </div>
            <ai-button *ngIf="!addToSequenceButtonLoading && !isGetWillBeTerminatedUsernamesLoading" [disabled]="isAddToSequenceButtonDisabled"
                class="ai-mr12" label="ADD TO SEQUENCE" (click)="addToSequence()" color="primary"></ai-button>
        </div>
    </nb-card-body>
</nb-card>