import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Currencies } from '../../enums';

@Component({
  selector: 'ngx-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(250)),
    ]),
  ],
})
export class StatCardComponent {
  @Input() label: string;
  @Input() value: string | number;
  @Input() loading: boolean;

  // Optional
  @Input() currency?: Currencies;
  @Input() appendClass?: string;
  @Input() isActive? = false;
  @Input() isDisabled? = false;
  @Input() isClickable? = true;
  @Input() align?: 'start' | 'center' | 'end' = 'start';
  @Input() isBold? = false;
}
