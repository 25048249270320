/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DetailedClientUser, ECommerceStoreType, NewInfluencerNewsItem, SubscriptionPlan } from '../../enums';
import {
  AddOns,
  BlacklistInfluencerResponse,
  BrandPortalSettings,
  ClientUser,
  ConsoleConfiguration,
  HashtagAnalysisForm,
  Influencer,
  InfluencerNewsItem,
  MinimalOrganizationInfoWithAccountId,
  NewClient,
  Organization,
  OrganizationSettings,
  PaymentMethods,
  Quota,
  Shopify,
  TiktokHashtagForm,
  TwitterHashtagForm,
  YoutuebHashtagForm,
} from '../../interfaces';

@Injectable()
export class AdminService {
  constructor(private http: HttpClient) {}

  getDomain(): string {
    return environment.production ? 'affable.ai' : 'staging.affable.ai';
  }

  async exportClientsMetrics(
    dateInterval: { gte: string | Date; lt: string | Date },
    clientsEmails: string[],
  ): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/admin/metrics/clients/export`,
        { dateInterval, clientsEmails },
        { responseType: 'blob' },
      )
      .toPromise();
  }

  saveInfluencerNews(newsItem: NewInfluencerNewsItem, username: string): Promise<InfluencerNewsItem> {
    return this.http
      .post<InfluencerNewsItem>(`${environment.api}/api/admin/news`, {
        ...newsItem,
        username,
      })
      .toPromise();
  }

  exportOrgOrClientAffiliateAsCSV(value: string, key: 'ORG_NAME' | 'CLIENT_ID'): Promise<string> {
    return this.http
      .post<string>(
        environment.api + '/api/admin/client/affiliate/sales',
        {
          value,
          key,
        },
        {
          responseType: 'blob' as any,
        },
      )
      .toPromise();
  }

  getAllClients(): Promise<ClientUser[]> {
    return this.http.get<ClientUser[]>(`${environment.api}/api/admin/allClients`).toPromise();
  }

  createSubscription(clientId: string, plan: SubscriptionPlan | string, isPublicAppPlan = false): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/admin/createSubscription`, {
        clientId,
        plan,
        isPublicAppPlan,
      })
      .toPromise();
  }

  getClientDetails(clientId: string): Promise<DetailedClientUser> {
    return this.http
      .get<DetailedClientUser>(`${environment.api}/api/admin/client`, {
        params: { id: clientId },
      })
      .toPromise();
  }

  setClientAddOns(clientId: string, addOns: AddOns): Promise<void> {
    return this.http
      .put<void>(`${environment.api}/api/admin/client/addOns`, { addOns }, { params: { id: clientId } })
      .toPromise();
  }

  getClientQuotas(clientId: string): Promise<Quota[]> {
    return this.http
      .get<Quota[]>(`${environment.api}/api/admin/client/quotas`, {
        params: { id: clientId },
      })
      .toPromise();
  }

  setClientQuotas(clientId: string, quotas: Quota[]): Promise<Quota[]> {
    return this.http
      .put<Quota[]>(`${environment.api}/api/admin/client/quotas`, { quotas }, { params: { id: clientId } })
      .toPromise();
  }

  setClientDetails(clientId: string, name: string, email: string): Promise<void> {
    return this.http
      .put<void>(
        `${environment.api}/api/admin/client/details`,
        { name: name, email: email },
        { params: { id: clientId } },
      )
      .toPromise();
  }

  public registerNewClient(newClient: NewClient): Promise<any> {
    return this.http
      .post<void>(`${environment.api}/api/admin/client/create`, {
        ...newClient,
      })
      .toPromise();
  }

  blockAllSessionsOfClient(userId: string) {
    return this.http
      .post<void>(`${environment.api}/api/admin/client/block-sessions`, {
        userId,
      })
      .toPromise();
  }

  markInfluencerToUserAndBlacklistedInfluencer(
    influencers: Influencer[],
    allUsers = false,
    groupSlugName: string = null,
  ): Promise<BlacklistInfluencerResponse> {
    const influencerUsernames = [];
    each(influencers, function (influencer) {
      influencerUsernames.push(influencer.profile.username);
    });
    return this.http
      .post<BlacklistInfluencerResponse>(
        `${environment.api}/api/admin/toggle/influencerToUserAndBlacklistedInfluencer`,
        {
          usernames: influencerUsernames,
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
        },
      )
      .toPromise();
  }

  markInfluencerToBrand(influencers: Influencer[], allUsers = false, groupSlugName: string = null): Promise<void> {
    const influencerUsernames = [];
    if (!allUsers) {
      each(influencers, function (influencer) {
        influencerUsernames.push(influencer.profile.username);
      });
    }
    return this.http
      .post<void>(
        `${environment.api}/api/admin/toggle/influencerToBrand`,
        {
          usernames: influencerUsernames,
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
        },
      )
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  markBrandToPotentialInfluencer(brandUsernames): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/admin/toggle/brandToPotentialInfluencer`, {
        usernames: brandUsernames,
      })
      .toPromise();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  markBrandToUserAndBlacklistedBrand(brandUsernames): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/admin/toggle/brandToUserAndBlacklistedBrand`, {
        usernames: brandUsernames,
      })
      .toPromise();
  }

  getAllCountries(): Promise<Country[]> {
    return this.http.get<Country[]>(`${environment.api}/api/admin/allLocations`).toPromise();
  }

  modifyLocationOfInfluencers(
    usernames: string[],
    countryName: string,
    allUsers = false,
    groupSlugName = null,
  ): Promise<void> {
    return this.http
      .post<void>(
        `${environment.api}/api/admin/modify/influencerLocation`,
        {
          data: {
            usernames,
            locations: [countryName],
          },
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
        },
      )
      .toPromise();
  }

  modifyGenderOfInfluencers(
    usernames: string[],
    gender: string,
    allUsers = false,
    groupSlugName: string = null,
  ): Promise<void> {
    return this.http
      .post<void>(
        `${environment.api}/api/admin/modify/influencerGender`,
        {
          data: {
            usernames,
            gender,
          },
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
        },
      )
      .toPromise();
  }

  modifyTagOfInfluencers(
    usernames: string[],
    tag: string,
    allUsers = false,
    groupSlugName: string = null,
  ): Promise<void> {
    return this.http
      .post<void>(
        `${environment.api}/api/admin/modify/influencerTag`,
        {
          data: {
            usernames,
            tag,
          },
        },
        {
          params: {
            groupSlugName,
            allUsers: allUsers?.toString(),
          },
        },
      )
      .toPromise();
  }

  computeOverlap(usernames: string[], loggedInClientId: string): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/jobs/instagram/influencers/compute-overlap`, {
        usernames: usernames,
        source: {
          triggeredBy: 'admin',
          id: loggedInClientId,
        },
      })
      .toPromise();
  }

  refreshProfile(username: string, platform = 'instagram', userId?: string): Promise<void> {
    if (platform?.toUpperCase() === 'VERAINSTAGRAM') {
      return this.http
        .post<void>(`${environment.api}/api/instagram/influencers/jobs/refresh`, {
          username,
          userId,
        })
        .toPromise();
    }
    return this.http
      .post<void>(`${environment.api}/api/jobs/${platform}/influencers/refresh/${username}`, {})
      .toPromise();
  }

  refreshProfileStatus(
    id: string,
    platform = 'verainstagram',
  ): Promise<{ lastRefreshedAt: string; profilingStatus: string }> {
    return this.http
      .get<{ lastRefreshedAt: string; profilingStatus: string }>(
        `${environment.api}/api/instagram/profile/${id}/refresh-status`,
        {},
      )
      .toPromise();
  }

  profileInfluencers(usernames: string[], reprofile: boolean, platform: string): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/jobs/${platform.toLowerCase()}/influencers/profileList`, {
        usernames: usernames,
        reprofile: reprofile,
      })
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateInfluencersMedia(usernames): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/jobs/instagram/influencers/updateMediaList`, {
        usernames: usernames,
      })
      .toPromise();
  }

  analyzeHashtag(hashtagOptions: HashtagAnalysisForm): Promise<any> {
    return this.http.post(`${environment.api}/api/jobs/instagram/hashtags/analyze`, hashtagOptions).toPromise();
  }

  analyzeTaggedMedia(username: string, limit = 1000): Promise<any> {
    return this.http
      .post(`${environment.api}/api/jobs/instagram/media/tagged/analyze`, {
        username: username.trim().toLowerCase(),
        limit: limit,
      })
      .toPromise();
  }

  analyzeYoutubeHashtag(youtubeHashtagForm: YoutuebHashtagForm): Promise<any> {
    const { hashtag, mode, limit } = youtubeHashtagForm;
    return this.http
      .post(`${environment.api}/api/jobs/youtube/hashtags/analyze`, {
        hashtag,
        mode,
        limit,
      })
      .toPromise();
  }

  analyzeTwitterHashtag(twitterHashtagForm: TwitterHashtagForm): Promise<any> {
    const { hashtag, tweetTimeline, limit } = twitterHashtagForm;
    return this.http
      .post(`${environment.api}/api/jobs/twitter/hashtags/analyze`, {
        hashtag,
        tweetTimeline,
        limit,
      })
      .toPromise();
  }

  analyzeTiktokHashtag(tiktokHashtagForm: TiktokHashtagForm): Promise<any> {
    const { hashtag, limit } = tiktokHashtagForm;
    return this.http
      .post(`${environment.api}/api/jobs/tiktok/hashtags/analyze`, {
        hashtag,
        limit,
      })
      .toPromise();
  }

  getAllOrganizationsNames(): Promise<string[]> {
    return this.http.get<string[]>(`${environment.api}/api/admin/allOrganizationNames`).toPromise();
  }

  fetchOrganizations(): Promise<{ _id: string; name: string }[]> {
    return this.http.get<any>(`${environment.api}/api/admin/organizations`).toPromise();
  }

  updateOrganizationMembers(
    organization: string,
    members: { _id: string; email: string }[],
    isIAMMultiInstanceSupportReleased = false,
  ): Promise<void> {
    let url = `${environment.api}/api/admin/organization/${organization}/members`;
    if (isIAMMultiInstanceSupportReleased) {
      url = `${environment.api}/api/admin/organization/${organization}/members/multi-instance`;
    }
    return this.http
      .post<void>(url, {
        organization,
        members,
      })
      .toPromise();
  }

  getRoles(): Promise<Role[]> {
    return this.http
      .get<Role[]>(`${environment.api}/api/admin/roles`)
      .pipe(map((rolesRes) => rolesRes.map((role) => new Role(role))))
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateClientRoles(clientId, roleIds: string[]) {
    return this.http
      .put(`${environment.api}/api/admin/client/roles`, { roles: roleIds }, { params: { id: clientId } })
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateManagedAccounts(clientId, managedAccounts: string[]) {
    return this.http
      .put(
        `${environment.api}/api/admin/client/managedAccounts`,
        { managedAccounts: managedAccounts },
        { params: { id: clientId } },
      )
      .toPromise();
  }

  setClientIsLiteStatus(clientId: string, isLiteUser: boolean): Promise<void> {
    return this.http
      .put<void>(`${environment.api}/api/admin/client/lite/`, { isLiteUser }, { params: { id: clientId } })
      .toPromise();
  }

  getOrganizationDetails(name: string): Promise<Organization> {
    return this.http
      .get<Organization>(`${environment.api}/api/admin/organization/${encodeURIComponent(name)}/details`)
      .toPromise();
  }

  setOrganizationStoreDetails(
    organizationName: string,
    storeDetails: Shopify,
    storeType: ECommerceStoreType,
  ): Promise<void> {
    return this.http
      .post<void>(
        `${environment.api}/api/admin/organization/${encodeURIComponent(organizationName)}/store/`,
        {
          storeUrl: storeDetails.shopUrl,
          privateApp: {
            apiKey: storeDetails.apiKey,
            secretKey: storeDetails.secretKey,
            apiPass: storeDetails.apiPass,
          },
          customApp: {
            apiKey: storeDetails['customApp'] ? storeDetails.customApp['apiKey'] : undefined,
            secretKey: storeDetails['customApp'] ? storeDetails.customApp['secretKey'] : undefined,
          },
          store: storeDetails.store,
        },
        { params: { storeType } },
      )
      .toPromise();
  }

  setOrganizationPaymentDetails(organizationName: string, paymentMethods: PaymentMethods): Promise<void> {
    return this.http
      .post<void>(
        `${environment.api}/api/admin/organization/${encodeURIComponent(organizationName)}/payment`,
        paymentMethods,
      )
      .toPromise();
  }

  setBrandPortalSettings(
    orgId: string,
    brandPortalSettings: Partial<BrandPortalSettings>,
    consoleConfiguration: Partial<ConsoleConfiguration>,
    logoAttachment?: File,
  ): Promise<{ brandPortalSettings: BrandPortalSettings; consoleConfiguration: ConsoleConfiguration }> {
    const formData = new FormData();
    formData.append('brandPortalSettings', JSON.stringify(brandPortalSettings));
    formData.append('consoleConfiguration', JSON.stringify(consoleConfiguration));
    if (logoAttachment) {
      formData.append('files', logoAttachment);
    }
    return this.http
      .post<{ brandPortalSettings: BrandPortalSettings; consoleConfiguration: ConsoleConfiguration }>(
        `${environment.api}/api/admin/organization/${orgId}/brand-portal/`,
        formData,
      )
      .toPromise();
  }

  setCompetitorSettings(orgId: string, consoleConfigurations: ConsoleConfiguration[]): Promise<ConsoleConfiguration[]> {
    return this.http
      .post<ConsoleConfiguration[]>(`${environment.api}/api/admin/organization/${orgId}/competitor/`, {
        consoleConfigurations,
      })
      .toPromise();
  }

  triggerConsoleRefreshJob(orgId: string): Promise<ConsoleConfiguration[]> {
    return this.http
      .post<ConsoleConfiguration[]>(`${environment.api}/api/admin/organization/${orgId}/console/trigger-job`, {})
      .toPromise();
  }

  deleteCompetitorSettings(orgId: string, consoleIds: string[]): Promise<string> {
    return this.http
      .post<string>(`${environment.api}/api/admin/organization/${orgId}/competitor/delete`, {
        consoleIds,
      })
      .toPromise();
  }

  updateOrganizationSettings(orgId: string, organizationSettings: OrganizationSettings): Promise<Organization> {
    return this.http
      .put<Organization>(`${environment.api}/api/organization/settings/${orgId}`, organizationSettings)
      .toPromise();
  }

  downloadFile(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  async getClientOrganizations(userId: string): Promise<MinimalOrganizationInfoWithAccountId[]> {
    return this.http
      .get<MinimalOrganizationInfoWithAccountId[]>(`${environment.api}/api/admin/client/organizations/${userId}`)
      .toPromise();
  }
}

export class Role {
  id: string;
  name: string;
  description: string;
  isEnabled = false;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(role) {
    this.id = role.id;
    this.name = role.name;
    this.description = role.description;
  }
}
