import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { SubscribedClientUser } from '../../enums';
import { ClientOrganizationMembers, MinimalOrganizationInfoWithAccountId } from '../../interfaces';
import { AuthService } from '../auth/auth.service';
import { EcommerceService } from './ecommerce.service';
import { SurveyService } from './survey.service';
import { escapeRouteParamsText, tryGet } from '../../utils';
import { UserguidingService } from './userguiding.service';
import { Subject } from 'rxjs';
import { FeatureFlagService } from './../feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../feature-flag/flags';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  isAuthenticated = true;
  public userUpdated$: Subject<boolean> = new Subject<boolean>();
  private clientUser: SubscribedClientUser; // from our database
  private clientUser$: Promise<SubscribedClientUser>;
  private organizationsOfClient: MinimalOrganizationInfoWithAccountId[];
  private organizationsOfClient$: Promise<MinimalOrganizationInfoWithAccountId[]>;
  private isSingleUserOrg: boolean;
  private _currency;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
    private surveyService: SurveyService,
    private ecommerceService: EcommerceService,
    private userguiding: UserguidingService,
    private featureFlag: FeatureFlagService,
  ) {
    authService.unauthroizedNotifier.subscribe(() => {
      this.clientUser$ = undefined;
      this.clientUser = undefined;
      this.isSingleUserOrg = undefined;
      this.organizationsOfClient$ = undefined;
      this.organizationsOfClient = undefined;
    });
  }

  checkGmailCallBack(Ref) {
    const emailIntegrationState: string = this.route.snapshot.queryParamMap.get('emailIntegrationState');
    if (emailIntegrationState === 'success') {
      const emailIntroductionModal = this.modalService.open(Ref, {
        centered: true,
        backdrop: 'static',
      });
      const modalComponent = emailIntroductionModal.componentInstance;
      modalComponent.status = 'synced';
      modalComponent.email = this.clientUser.integratedEmails[0]?.email;
      modalComponent.connectWith = this.clientUser.integratedEmails[0]?.provider;
      this.router.navigate([], { relativeTo: this.route });
    }
  }
  // This function gets the user information from Database
  async getClientUser(fetchOnly = false): Promise<SubscribedClientUser> {
    if (!fetchOnly && this.clientUser) {
      this.isAuthenticated = !!this.clientUser.displaySocialAccountId;
      return this.clientUser;
    }

    if (!fetchOnly && this.clientUser$) {
      // Request is in-flight, we simply return the inflight promise instead.
      this.clientUser$.then((clientUser) => {
        this.isAuthenticated = !!clientUser.displaySocialAccountId;
      });
      return this.clientUser$;
    }

    this.clientUser$ = this.fetchClientUser().then(async (clientUser) => {
      this.featureFlag.userUpdated();
      this.isAuthenticated = !!clientUser.displaySocialAccountId;
      this.clientUser = clientUser;
      this.setFullStoryUser(this.clientUser);
      this.setHeapUser(this.clientUser);
      this.setIntercomUser(this.clientUser);
      this.surveyService.addSatisMeterScript(this.clientUser);
      const ugUser = {
        id: clientUser.id,
        email: clientUser.email,
        name: clientUser.name,
        createdAt: clientUser.created_at,
      };
      tryGet(() => this.userguiding?.identify(ugUser, ugUser.id));
      this.userUpdated$.next(true);
      return this.clientUser;
    });
    if (!fetchOnly && (await this.featureFlag.isFeatureReleased(RELEASE_FLAGS.ORGANIZATION_SETTINGS))) {
      // * Set the user's organization settings while launching the app, when the user data does not exist (On refresh)
      await this.authService.setOrganizationSettings();
    }

    return this.clientUser$;
  }

  async setupOrganizationsOfClient(
    fetchOnly = false,
    orgNameFromUrl?: string,
  ): Promise<MinimalOrganizationInfoWithAccountId[]> {
    if (!fetchOnly && this.organizationsOfClient) {
      return this.organizationsOfClient;
    }

    if (!fetchOnly && this.organizationsOfClient$) {
      // Request is in-flight, we simply return the inflight promise instead.
      return this.organizationsOfClient$;
    }

    this.organizationsOfClient$ = this.fetchOrganizationsOfClient()
      .then(async (orgs) => {
        this.organizationsOfClient = orgs;
        await this.validateAndSaveCurrentOrg(orgNameFromUrl);
        return orgs;
      })
      .catch((error) => {
        this.authService.notifyUnauthorized();
        const escapedMessage = escapeRouteParamsText(error?.message);
        this.router.navigate(['auth', 'login', { event: 'session-revoked', message: escapedMessage }]);
        return [] as MinimalOrganizationInfoWithAccountId[];
      });

    return this.organizationsOfClient$;
  }

  async validateAndSaveCurrentOrg(orgNameFromUrl?: string): Promise<boolean> {
    const orgIdFromStorage = this.authService.getOrganizationId();

    // If organization ID is provided in the URL
    if (orgNameFromUrl) {
      const org = this.organizationsOfClient?.find((org) => org.organizationName === orgNameFromUrl);
      if (org) {
        // If the organization ID from URL is different from the one in storage, update storage
        if (org.organizationId !== orgIdFromStorage) {
          this.authService.saveOrganizationId(org.organizationId);
          this.authService.setOrganizationSettings();
          return true;
        }
      } else {
        this.logOutUser();
        return false;
      }
    }
    // If organization ID is stored but not provided in the URL
    else if (orgIdFromStorage) {
      const orgExists = this.organizationsOfClient?.some((org) => org.organizationId === orgIdFromStorage);
      if (!orgExists) {
        this.logOutUser();
        return false;
      }
    }
    // If no organization ID is found in URL or storage, use the first one in the list
    else {
      const firstOrgId = this.organizationsOfClient?.[0]?.organizationId;
      if (firstOrgId) {
        this.authService.saveOrganizationId(firstOrgId);
        return true;
      }
    }
    return false;
  }

  async getLinkedCurrency(): Promise<string> {
    if (this._currency) return this._currency;
    if (!this.clientUser) await this.getClientUser();
    if (this.clientUser?.integrations?.eCommerceStore) {
      const { currency } = await this.ecommerceService.getStoreShop().catch((err) => err);
      if (currency) {
        this._currency = currency;
        return this._currency;
      }
    }
    return this.authService.getCurrency();
  }

  async checkIfSingleUserOrg(): Promise<boolean> {
    if (this.isSingleUserOrg === undefined) {
      const orgMembers = await this.http
        .post<ClientOrganizationMembers[]>(environment.api + `/api/client/members`, {})
        .toPromise();
      this.isSingleUserOrg = orgMembers ? (orgMembers.length > 1 ? false : true) : true;
    }
    return this.isSingleUserOrg;
  }

  async clientEmailIntegration(provider: string): Promise<any> {
    return this.http
      .get<SubscribedClientUser>(
        `${environment.api}/api/client/email-integration?provider=${provider}&redirectUrl=${window.location.href}`,
      )
      .toPromise();
  }

  async requestClientGmailIntegration(): Promise<any> {
    return this.http.patch(`${environment.api}/api/client/request-gmail-link`, { gmailLinkRequest: true }).toPromise();
  }

  async revokeClientEmailIntegration(email: string): Promise<any> {
    return this.http
      .delete<SubscribedClientUser>(`${environment.api}/api/client/email-revoke-access/${email}`)
      .toPromise();
  }
  private fetchClientUser(): Promise<SubscribedClientUser> {
    return this.http.get<SubscribedClientUser>(`${environment.api}/api/client/current`).toPromise();
  }

  private fetchOrganizationsOfClient(): Promise<MinimalOrganizationInfoWithAccountId[]> {
    return this.http
      .get<MinimalOrganizationInfoWithAccountId[]>(`${environment.api}/api/client/organizations`)
      .toPromise();
  }

  private setFullStoryUser(clientUser) {
    if (window['FS']) {
      window['FS'].identify(clientUser.userId, {
        displayName: clientUser.name,
        email: clientUser.email,
      });
    }
  }

  private setHeapUser(clientUser) {
    if (window['heap']) {
      window['heap'].identify(clientUser.email);
      window['heap'].addUserProperties({
        Name: clientUser.name,
        email: clientUser.email,
        isSubscribed: !!clientUser.app_metadata.stripePlan,
      });
    }
  }

  private setIntercomUser(clientUser: SubscribedClientUser) {
    if (window['Intercom']) {
      window['Intercom']('boot', {
        app_id: environment.intercomAppID,
        email: clientUser.email,
        user_id: clientUser.userId,
        name: clientUser.name,
        user_plan: clientUser.app_metadata?.stripePlan,
      });
    }
  }

  public pushIntercomEvent(eventName: string, metadata?: any) {
    if (!window['Intercom']) {
      return;
    }

    try {
      window['Intercom']('trackEvent', eventName, metadata);
    } catch (e) {
      // This is a best effort event, we don't want to block anything.
    }
  }

  public createClientUser(accountDetails): Promise<any> {
    return this.http.post<any>(`${environment.api}/api/client/create`, accountDetails).toPromise();
  }

  public connectSocialAccount(displaySocialAccountId: string): void {
    this.updateOrganizationDisplaySocialAccountId(displaySocialAccountId).then((res) => {
      if (res.status === 200) {
        this.isAuthenticated = true;
      }
    });
  }

  async updateOrganizationDisplaySocialAccountId(token: string): Promise<HttpResponse<unknown>> {
    return this.http
      .patch<HttpResponse<unknown>>(
        environment.api + `/api/client/organization-socials`,
        {
          displaySocialAccountId: token,
        },
        { observe: 'response' },
      )
      .toPromise();
  }

  private logOutUser() {
    this.authService.notifyUnauthorized();
    this.router.navigate([
      'auth',
      'login',
      { event: 'session-revoked', message: 'Unauthorized access for organization' },
    ]);
  }
}
