export type fixedPayCardLabel =
  | 'Total Influencers'
  | 'Owed Pay'
  | 'Paid'
  | 'Pending'
  | 'Pay In Progress'
  | 'Pay Returned';

export enum PaymentStatus {
  paid = 'paid',
  inProgress = 'inProgress',
  partial = 'partial',
  returned = 'returned',
}
