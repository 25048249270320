import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Platform, Platforms, VeraPlatforms } from '../../../enums';
import { GroupInfo, GroupService } from '../../../@core/data/group.service';
import { takeUntil, takeWhile } from 'rxjs/operators';
import utils from '../../../@core/utils/utils';

@Component({
  selector: 'ngx-add-to-existing-group-modal',
  templateUrl: './add-to-existing-group-modal.component.html',
  styleUrls: ['./add-to-existing-group-modal.component.scss'],
})
export class AddToExistingGroupModalComponent implements OnInit, OnDestroy {
  @Input() allGroups: GroupInfo[];
  @Input() usernames: string[];
  @Input() platform: Platforms | VeraPlatforms;
  @Input() singlePlatformSelectionEnabled = false;
  @Input() enabledPlatform: Platform[];
  @Input() fromCommunityAndVeraEnabled: boolean;
  availablePlatforms = Object.keys(Platforms).filter((key) => key !== 'facebook' && key !== 'twitter');
  groups: GroupInfo[] = [];
  selectedGroup: GroupInfo;
  selectedGroups: { [slugName: string]: GroupInfo } = {};
  searchText: string;
  isLoading = false;
  alive = true;

  instagramGroups: GroupInfo[] = [];
  facebookGroups: GroupInfo[] = [];
  youtubeGroups: GroupInfo[] = [];
  tiktokGroups: GroupInfo[] = [];
  twitterGroups: GroupInfo[] = [];
  veraInstagramGroups: GroupInfo[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private groupService: GroupService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    if (this.singlePlatformSelectionEnabled) {
      this.fetchGroups();
      this.groupService.instagramGroupsChanged$.pipe(takeWhile(() => this.alive)).subscribe((influencerGroups) => {
        this.instagramGroups = influencerGroups;
        this.loadData();
      });
      this.groupService.facebookGroupsChanged$.pipe(takeWhile(() => this.alive)).subscribe((influencerGroups) => {
        this.facebookGroups = influencerGroups;
        this.loadData();
      });
      this.groupService.youtubeGroupsChanged$.pipe(takeWhile(() => this.alive)).subscribe((influencerGroups) => {
        this.youtubeGroups = influencerGroups;
        this.loadData();
      });
      this.groupService.tiktokGroupsChanged$.pipe(takeWhile(() => this.alive)).subscribe((influencerGroups) => {
        this.tiktokGroups = influencerGroups;
        this.loadData();
      });
      this.groupService.twitterGroupsChanged$.pipe(takeWhile(() => this.alive)).subscribe((influencerGroups) => {
        this.twitterGroups = influencerGroups;
        this.loadData();
      });
      this.groupService.veraInstagramGroupsChanged$.pipe(takeWhile(() => this.alive)).subscribe((influencerGroups) => {
        this.veraInstagramGroups = influencerGroups;
        this.loadData();
      });
    }
    this.groups = this.allGroups;
    if (this.platform && !this.groups?.length) {
      this.loadData();
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  fetchGroups(): void {
    Promise.all([
      this.groupService.fetchInfluencerGroups(Platforms.instagram),
      this.groupService.fetchInfluencerGroups(Platforms.facebook),
      this.groupService.fetchInfluencerGroups(Platforms.youtube),
      this.groupService.fetchInfluencerGroups(Platforms.tiktok),
      this.groupService.fetchInfluencerGroups(Platforms.twitter),
      this.groupService.fetchInfluencerGroups(VeraPlatforms.instagram),
    ]);
  }

  loadData(): void {
    const platformDetail =
      this.fromCommunityAndVeraEnabled && this.platform === Platforms.instagram
        ? VeraPlatforms.instagram
        : this.platform;
    this.groups = this.getPlatformGroupList(platformDetail)?.filter(this.isGroup);
  }

  getPlatformGroupList(platform: Platforms | VeraPlatforms): GroupInfo[] {
    switch (platform) {
      case Platforms.instagram:
        return this.instagramGroups;
      case Platforms.facebook:
        return this.facebookGroups;
      case Platforms.youtube:
        return this.youtubeGroups;
      case Platforms.tiktok:
        return this.tiktokGroups;
      case Platforms.twitter:
        return this.twitterGroups;
      case VeraPlatforms.instagram:
        return this.veraInstagramGroups;
    }
  }

  isUserGroup(influencerGroup: GroupInfo): boolean {
    return influencerGroup.type === 'USER_CREATED';
  }

  searchGroups(): void {
    const searchKeyword = this.searchText.toLocaleLowerCase();
    this.groups = this.allGroups.filter((group) => group.name.toLocaleLowerCase().includes(searchKeyword));
  }

  toggleSelectedGroup(group: GroupInfo): void {
    if (this.selectedGroups[group.slugName]) {
      delete this.selectedGroups[group.slugName];
      return;
    }
    this.selectedGroups[group.slugName] = group;
  }

  addToGroups(): void {
    const selectedGroups = Object.values(this.selectedGroups);
    const groupSlugNames = selectedGroups.map((group) => group.slugName);
    if (this.singlePlatformSelectionEnabled) {
      const platformDetail =
        this.fromCommunityAndVeraEnabled && this.platform === Platforms.instagram
          ? VeraPlatforms.instagram
          : this.platform;
      this.activeModal.close({ groupSlugNames, platform: platformDetail });
      return;
    }
    if (this.usernames?.length > 0 && selectedGroups.length) {
      this.isLoading = true;
      this.groupService
        .addInfluencersToGroups(groupSlugNames, this.usernames, this.platform)
        .then(() => {
          this.toastrService.success(this.usernames?.length + ' Influencers added to the group(s)');
          this.activeModal.close(true);
        })
        .catch((err: HttpErrorResponse) => {
          if (err.status === 429) {
            this.toastrService.error(
              `Exceeded grouped influencers limit. Delete groups or remove grouped influencers and try again.`,
            );
          } else {
            this.toastrService.error(`Something went wrong while adding influencers to group(s). Try again.`);
          }
          this.activeModal.close(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  isGroup(influencerGroup: GroupInfo): boolean {
    return influencerGroup.type === 'USER_CREATED' || influencerGroup.type === 'SHARED_TO_USER';
  }

  isSelectedGroupsEmpty(): boolean {
    return !Object.keys(this.selectedGroups ?? {}).length;
  }
  onPlatformSelectionChange(event: Platform): void {
    this.platform = Platforms[event];
    this.selectedGroups = {};
    this.loadData();
  }
}
