import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AgeGroup, AudienceAgeGroup, TiktokInfluencerSortKey } from '../../../enums';
import { TiktokInfluencerFilters, TiktokInfluencerLookup, TiktokInfluencersSearchResult } from '../../../interfaces';

@Injectable()
export class TiktokService {
  private filtersChangedSource = new Subject<TiktokInfluencerSearchFilters>();
  filtersChanged$ = this.filtersChangedSource.asObservable();

  constructor(private http: HttpClient) {}

  sendInfluencerFiltersChangedEvent(
    influencerFilters: TiktokInfluencerFilters,
    audienceFilters: TiktokAudienceFilters,
    onlyTopInfluencers: boolean,
    sortKey: TiktokInfluencerSortKey,
  ) {
    this.filtersChangedSource.next({ influencerFilters, audienceFilters, onlyTopInfluencers, sortKey });
  }

  // Get top tiktok influencers
  getTopInfluencers(location: string): Promise<TiktokInfluencersSearchResult> {
    return this.http
      .get<TiktokInfluencersSearchResult>(environment.api + `/api/tiktok/top`, {
        params: { location },
      })
      .toPromise();
  }

  // search tiktok influencers
  getInfluencers(searchFilters: TiktokInfluencerSearchFilters, page: number): Promise<TiktokInfluencersSearchResult> {
    searchFilters = cloneDeep(searchFilters);
    if (searchFilters.influencerFilters.username) {
      searchFilters.influencerFilters.username = searchFilters.influencerFilters.username.trim();
    }
    return this.http
      .post(`${environment.api}/api/tiktok/`, searchFilters, {
        params: {
          page: page.toString(),
          sortKey: searchFilters.sortKey,
        },
      })
      .toPromise()
      .then(async (json: TiktokInfluencersSearchResult) => json);
  }

  // Get tiktok influencer look up by term
  lookupInfluencers(term: string): Observable<TiktokInfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    return this.http.get<TiktokInfluencerLookup[]>(`${environment.api}/api/tiktok`, {
      params: { term: term.trim() },
    });
  }

  profileInfluencersList(
    usernames: string[],
    groupName: string | undefined,
    groupSlugName: string | undefined,
  ): Promise<string[]> {
    return this.http
      .post(`${environment.api}/api/jobs/tiktok/influencers/profileList`, {
        usernames: usernames,
        groupName: groupName,
        groupSlugName: groupSlugName,
      })
      .toPromise()
      .then((response: any) => response.profiledUsernames);
  }

  exportInfluencersFromSearch(
    usernames: string[],
    campaignSlugName?: string,
    groupSlugName?: string,
    allUsers = false,
    context: 'INVITATION' | 'PROPOSAL' = 'INVITATION',
  ): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/tiktok/export/search`,
        {
          usernames,
          context,
          ...(campaignSlugName && { campaignSlugName }),
        },
        {
          responseType: 'blob',
          params: {
            ...(groupSlugName && { groupSlugName }),
            allUsers: allUsers.toString(),
          },
        },
      )
      .toPromise();
  }

  audienceAgeGroupToAgeGroup(ageGroup: AudienceAgeGroup): AgeGroup {
    switch (ageGroup) {
      case '0_18':
        return 0;
      case '19_25':
        return 1;
      case '26_32':
        return 2;
      case '33_39':
        return 3;
      case '40_46':
        return 4;
      case '47_':
        return 5;
    }
  }

  ageGroupToAudienceAgeGroup(ageGroup: AgeGroup): AudienceAgeGroup {
    switch (ageGroup) {
      case 0:
        return '0_18';
      case 1:
        return '19_25';
      case 2:
        return '26_32';
      case 3:
        return '33_39';
      case 4:
        return '40_46';
      case 5:
        return '47_';
    }
  }
}

// Tiktok influencers search filters classes
export class TiktokInfluencerSearchFilters {
  influencerFilters: TiktokInfluencerFilters;
  audienceFilters: TiktokAudienceFilters;
  onlyTopInfluencers? = false;
  sortKey: TiktokInfluencerSortKey;
}

export class Gender {
  male = false;
  female = false;
}

export class Followers {
  min = 5000;
  max = 1000000;
}

export class TiktokAudienceFilters {
  ageGroups: AudienceAgeGroup[] = [];
  gender = new Gender();
  locations: string[] = []; // These are country codes, not names
}
