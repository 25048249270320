import { Component, EventEmitter, Output } from '@angular/core';
import { Link } from '../../../@core/data/campaigns.service';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';

@Component({
  selector: 'ngx-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss'],
})
export class AddLinkComponent {
  model: Link = new Link();
  invalidUrl: string | undefined;
  invalidCustomLink: string | undefined;
  @Output() addLinkEvent = new EventEmitter();

  constructor(private featureFlagService: FeatureFlagService) {}

  async addLink(): Promise<void> {
    this.invalidUrl = undefined;
    this.invalidCustomLink = undefined;
    this.model.customLink = this.model.customLink?.trim();
    if (this.model.customLink && !this.validCustomLink(this.model.customLink)) {
      this.invalidCustomLink = 'Invalid Custom Link, No space nor period is allowed';
      return;
    }
    if (this.model.long_url && this.model.long_url !== '' && this.validURL(this.model.long_url)) {
      const newLink = this.model;
      this.addLinkEvent.emit({ newLink: newLink });
      this.model = new Link();
    } else {
      this.invalidUrl = 'Invalid Url';
    }
  }

  validURL(str: string): boolean {
    const urlRegEx =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/;

    const ipRegEx = /^(http:\/\/|https:\/\/)(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:[0-9]{2,5})?$/;

    return new RegExp(urlRegEx.source + '|' + ipRegEx.source).test(str);
  }

  validCustomLink(customLink: string): boolean {
    return !/\s|\./g.test(customLink);
  }
}
