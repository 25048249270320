<nb-layout windowMode>
  <nb-layout-column style="padding: 1rem 2rem;">
    <div class="jumbotron">
      <img style="height: 200px; padding-bottom: 1rem;" src="../../../../../assets/images/404.svg">
      <h4>Not Found</h4>
      <br>
      <p>Uh oh! Looks like we could not find this page </p>
      <p>It either never existed, or has been removed. We regret the inconvenience caused</p>
      <br>
      <button class="btn btn-md btn-primary" routerLink="/">Return to Discovery</button>
    </div>
  </nb-layout-column>
</nb-layout>
