<div class="campaigns-row align-center">
  <ng-container *ngTemplateOutlet="campaignCard; context: {campaigns:campaigns}">
  </ng-container>
</div>
<ng-template #campaignCard let-campaigns="campaigns">
  <div class="first-campaign">
    {{campaigns[0].campaignName | truncate: 10}}
    <i class="far fa-arrow-up-right-from-square ml-1" [disabled]="campaigns[0].sharedWith"
      (click)="navigateToCommunity(campaigns[0])"></i>
  </div>

  <div class="campaigns-count" *ngIf="campaigns.length > 1" (click)="openAllCampaignsModal()">
    +{{campaigns.length - 1}}
  </div>
</ng-template>
<ng-template #allCampaigns let-modal>
  <div>
    <div class="modal-header">
      <span>Active Campaigns</span>
      <button class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="all-campaigns" *ngFor="let campaign of campaigns">
        <div class="campaign-details">
          <label>{{campaign.campaignName}}</label>
          <span>{{campaign.from | date:'MMMM dd, yyyy'}} - {{campaign.to ? (campaign.to * 1000 | date:'MMMM dd, yyyy') :
            'Open'}}</span>
        </div>
        <div class="additional-campaigns">
          <div class="vertical-separator"></div>
          <i class="far fa-arrow-up-right-from-square" [disabled]="campaign.sharedWith"
            (click)="navigateToCommunity(campaign,modal)"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>
