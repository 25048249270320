import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgProgress } from 'ngx-progressbar';
import { PostMediaType } from '../../enums';
import { InfluencerInfo, PostArticle, PostWithSafeUrl, ProfileAbout, SharedPost } from '../../interfaces';

@Component({
  selector: 'ngx-facebook-post-details-modal',
  templateUrl: './facebook-post-details-modal.component.html',
  styleUrls: ['./facebook-post-details-modal.component.scss'],
})
export class FacebookPostDetailsModalComponent {
  @Input()
  post: PostWithSafeUrl;

  @Input()
  isMediaPost: boolean;

  @Input()
  profile: ProfileAbout | InfluencerInfo;

  loaded = false;
  @Input() currency: string;

  @Input() hideStats = false;

  constructor(private activeModal: NgbActiveModal, public progress: NgProgress) {}

  openFBVideo(url: string) {
    window.open(url, '_blank');
  }

  closeModal(): void {
    this.activeModal.close();
  }

  getArticleUrl(): string {
    const postAritcle = this.post.media as PostArticle;
    return postAritcle.url;
  }

  getSharedPostMediaType(): PostMediaType {
    const postMedia = this.post.media as SharedPost;
    return postMedia.mediaType ? postMedia.mediaType : postMedia.thumbnailUrl ? 'photo' : null;
  }

  getSharedPostMediaDescription(): string {
    if (this.post.mediaType === 'shared_post') {
      const postMedia = this.post.media as SharedPost;
      return postMedia.description;
    } else {
      return null;
    }
  }

  getEncodedUsername(username: string): string {
    return encodeURIComponent(username);
  }
}
