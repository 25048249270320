<div class="sort-container" *ngIf="activeSort && dropDownOptions">
  <label class="body-2">
    {{label}}
  </label>
  <ai-dropdown
    [options]="dropDownOptions"
    [size]="'small'"
    (action)="sortFilterChanged($event)"
    [multi]="false"
    [type]="'sort'"
    [buttonOptions]="{
      label: activeSort,
      type: 'ghost',
      suffix: 'far fa-chevron',
      color: 'secondary',
      disabled: disabled
    }"
  ></ai-dropdown>
</div>
