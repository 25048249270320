import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../@core/feature-flag/flags';

@Component({
  selector: 'ngx-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent implements OnInit {
  newNote: string;
  isPrivate: boolean;
  isAgencyFeature = false;

  // for condition on whether to show privacy toggle
  @Input() isSingleUserOrg: boolean;

  @Input() isGroup = false;
  @Input() isCollabPortal = false;

  @Input()
  set clearNote(value: boolean) {
    if (value) {
      this.clearNotechanges();
    }
  }

  @Output() addNoteEvent = new EventEmitter();

  fileToUpload: File | undefined | null = undefined;
  validFile: boolean | undefined;
  fileErrorMessage: string;

  get isSaveDisabled(): boolean {
    return !this.newNote?.trim().length || this.validFile === false;
  }

  get message(): string {
    if ((this.isAgencyFeature || this.isCollabPortal) && this.isGroup)
      return 'All notes are shared with the accounts that have access to this group.';
    return 'By default, all new notes are shared to the accounts in your organization.';
  }

  public constructor(private featureFlagService: FeatureFlagService) {}

  async ngOnInit(): Promise<void> {
    this.isAgencyFeature = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.AGENCY_X_BRAND_GROUP);
  }

  addNote(): void {
    if (this.newNote && this.newNote !== '') {
      const newNote = this.newNote;
      this.addNoteEvent.emit({ newNote: newNote, file: this.fileToUpload, isPrivate: this.isPrivate });
      this.newNote = '';
    }
  }

  handleFileInput(files: FileList): void {
    if (files?.length > 0) {
      this.fileToUpload = files.item(0);
      this.validateFileSize();
    }
  }

  validateFileSize(): void {
    if (this.fileToUpload && this.fileToUpload.size / 1048576 > 20) {
      this.validFile = false;
      this.fileErrorMessage = 'Attachement exceeded maximum size limit of 20MB';
    } else {
      this.validFile = true;
    }
  }

  clearNotechanges(): void {
    this.newNote = '';
    this.fileToUpload = undefined;
  }

  removeAttachement(): void {
    this.fileToUpload = undefined;
    if (!this.validFile) this.validFile = undefined;
  }
}
