<div >
    <nb-card size="large">
      <nb-card-header>
        <span>Audience Gender Distribution</span>
        <ngx-tooltip [tooltip]="tooltipMessage"></ngx-tooltip>
      </nb-card-header>
      <nb-card-body>
        <ngx-echarts-pie *ngIf="showGenderDistribution()" [dataOptions]="genderDataOptions">
        </ngx-echarts-pie>
        <ngx-loader *ngIf="!genderDataOptions" class="profile-card-placeholder"></ngx-loader>
        <div *ngIf="genderDataOptions?.series?.length == 0" class="profile-card-placeholder">
          Audience gender
          distribution data not available for {{influencerUsername}}
        </div>
      </nb-card-body>
      <nb-card-footer class="stats-footer">
      </nb-card-footer>
    </nb-card>
  </div>