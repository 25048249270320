<div class="modal-header">
  <ng-container *ngIf="headerIconClass">
    <i [class]="headerIconClass"></i>
  </ng-container>
  <b>{{ headerText }}</b>
  <button *ngIf="showCloseOption" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [class.center]="centerContent">
  <div class="content-text-list" *ngIf="contentTextList">
    <div [class]="listItemClass" *ngFor="let item of contentTextList">
      {{item}}
    </div>
  </div>
  <p *ngIf='contentText' [innerHTML]="contentText"></p>
  <img [ngClass]="imageClass" *ngIf="imageURL" src="{{ imageURL }}" />
  <input class="input-group input-text-box" type="text" *ngIf="showInputText" [(ngModel)]="inputText" />
</div>
<div class="modal-footer" [class.with-cancel-btn]="cancelButton" [class.center]="centerContent">
  <button [ngClass]="buttonClass" data-cy="confirmed-message" (click)="confirm()">{{ buttonText }}</button>
  <button *ngIf="cancelButton" class="btn btn-xs btn-secondary" (click)="closeModal()">Cancel</button>
</div>
