import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { CampaignsService } from '../../@core/data/campaigns.service';
import { ChatService } from '../../@core/data/chat.service';
import { FacebookInfluencerLookup, FacebookService } from '../../@core/data/facebook/facebook.service';
import { InfluencerService } from '../../@core/data/influencer.service';
import { TiktokService } from '../../@core/data/tiktok/tiktok.service';
import { TwitterService } from '../../@core/data/twitter/twitter.service';
import { YoutubeService } from '../../@core/data/youtube/youtube.service';
import utils from '../../@core/utils/utils';
import { MailType, Platforms, PlatformsV2 } from '../../enums';
import {
  EmailTemplate,
  InfluencerLookup,
  TiktokInfluencerLookup,
  TwitterInfluencerLookup,
  YoutubeInfluencerLookup,
} from '../../interfaces';
import { environment } from '../../../environments/environment';
import { MetadataService } from '../../@core/data/metadata.service';
import { EmailTemplatesModal } from '../../pages/campaigns/email-templates/email-templates.modal';
import { flatten } from 'lodash';
import { UploadStatusService } from '../../../../../controls/src/ckeditor/upload-status.service';
import { EmailTemplateService } from '../../@core/data/email-template.service';
@Component({
  selector: 'ngx-new-chat',
  templateUrl: './new-chat.component.html',
  styleUrls: ['./new-chat.component.scss'],
})
export class NewChatComponent implements OnInit, OnDestroy {
  @Input() campaignSlugName = '';
  @Input() selectedInfluencers: InfluencerLookup[] = [];
  @Input() isEmbeded = false;
  @Input() platform: PlatformsV2;
  @Input() defaultTemplateMessage = '';
  @Input() supportedTemplatingVariables: string[] = [];
  @Input() subject: string;
  @Input() mailType: MailType = MailType.default;
  @Input() modalHeader = 'New Email';
  @Input() influencerEmailSetter?: (updatedInfluencer: InfluencerLookup) => void;

  private editorUploading = false;
  private updateInfluencerEmailSubscription: Subscription;

  get noOfMessagesSent(): number {
    return this.chatService.noOfMessagesSent;
  }
  totalNumberOfMessagesToBeSend = 0;

  isChipOnEditMode = false;
  loadingInfluencers = false;
  influencers:
    | Observable<InfluencerLookup[]>
    | Observable<FacebookInfluencerLookup[]>
    | Observable<YoutubeInfluencerLookup[]>
    | Observable<TiktokInfluencerLookup[]>
    | Observable<TwitterInfluencerLookup[]>;
  influencerNameInput = new Subject<string>();
  newChatForm: FormGroup;
  errorInSendMail = false;
  restrictedKeywordsUsed = false;
  loading = false;
  showTips = true;

  showMoreBtnVisible = false;
  maxInitialInfluencersVisible = 5;

  filesToAttach: File[] = [];
  validFile = true;

  // flag set to true if client is previewing chat
  preview: boolean;
  generatingPreview: boolean;
  chatPreviewInfluencers: InfluencerLookup[];
  emailTemplates: EmailTemplate[] = [];
  emailTemplate = new FormControl();
  isRegistrationWorkflow: boolean;

  @HostListener('window: reset-content', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openCustomPopup(event) {
    // TODO: consume reset content $event
  }

  // used in showing/editing email on hover in influencers list
  hoverInfluencer:
    | InfluencerLookup
    | FacebookInfluencerLookup
    | YoutubeInfluencerLookup
    | TiktokInfluencerLookup
    | TwitterInfluencerLookup;

  constructor(
    private activeModal: NgbActiveModal,
    private influencerService: InfluencerService,
    private facebookService: FacebookService,
    private youtubeService: YoutubeService,
    private tiktokService: TiktokService,
    private twitterService: TwitterService,
    private chatService: ChatService,
    private toastrService: ToastrService,
    private campaignsService: CampaignsService,
    private metadataService: MetadataService,
    private modalService: NgbModal,
    private uploadStatusService: UploadStatusService,
    private emailTemplateService: EmailTemplateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.influencers = utils.buildAutocompleteObservable<
      string,
      | InfluencerLookup
      | FacebookInfluencerLookup
      | YoutubeInfluencerLookup
      | TiktokInfluencerLookup
      | TwitterInfluencerLookup
    >(
      this.influencerNameInput,
      this.getInfluencerLookupFunction(),
      () => (this.loadingInfluencers = true),
      () => (this.loadingInfluencers = false),
    );

    this.newChatForm = new FormGroup({
      subject: new FormControl(this.subject),
      message: new FormControl(this.defaultTemplateMessage),
    });

    if (!this.campaignSlugName && this.modalHeader === 'Registration Invite') {
      this.isRegistrationWorkflow = true;
    }

    this.showMoreBtnVisible = this.selectedInfluencers.length > this.maxInitialInfluencersVisible;

    if (this.campaignSlugName) {
      const { invitationTypes = [] } = (await this.campaignsService.getTemplates(this.campaignSlugName)) || {};
      this.emailTemplates = flatten(invitationTypes.map(({ templates }) => templates));
    } else if (this.isRegistrationWorkflow) {
      const { invitationTypes = [] } = (await this.emailTemplateService.getInfluencerRegistrationTemplates()) || {};
      this.emailTemplates = flatten(invitationTypes.map(({ templates }) => templates));
    }
    // check if campaignWorkflow is created, then include {{creator-link}} template
    this.showTips = !!this.supportedTemplatingVariables;
    this.uploadStatusService.getUploadStatus().subscribe((uploadState) => {
      this.editorUploading = !(uploadState.status === 'completed' && uploadState.count === 0);
    });
  }

  showAllInfluencers(): void {
    this.showMoreBtnVisible = false;
  }

  get apiPath(): string {
    return environment.api;
  }

  closeModal(influencers?): void {
    if (influencers) {
      this.activeModal.close({
        influencers: influencers,
      });
    } else {
      this.activeModal.close();
    }
  }

  numberOfInfluencersWithInvalidOrMissingEmail(): number {
    return this.selectedInfluencers.filter(({ email }) => !this.isEmailValid(email))?.length;
  }

  getMessageForInfluencersWithNoValidEmail(): string {
    return `${this.numberOfInfluencersWithInvalidOrMissingEmail()} of ${this.selectedInfluencers?.length}
      ${this.selectedInfluencers?.length === 1 ? 'influencer' : 'influencers'} selected either
      ${this.numberOfInfluencersWithInvalidOrMissingEmail() === 1 ? "doesn't" : "don't"} have
      an email address or have an invalid one. No email will be sent to them.`;
  }

  influencersWithValidEmail(): InfluencerLookup[] {
    return this.selectedInfluencers.filter(({ email }) => this.isEmailValid(email));
  }

  isEmailValid(email: string | undefined): boolean {
    return utils.isEmailValidAndNotEmpty(email);
  }

  removeInfluencersWithNoValidEmail(): void {
    this.selectedInfluencers = this.influencersWithValidEmail();
  }

  sendChat(): void {
    if (!this.checkMessageForRestrictedKeywords()) {
      return;
    }
    const influencersWithEmail = this.influencersWithValidEmail();

    this.totalNumberOfMessagesToBeSend = influencersWithEmail.length;

    this.loading = true;
    this.chatService
      .startNewChat(
        influencersWithEmail.map((i) => i.username),
        this.campaignSlugName,
        this.newChatForm.value.subject,
        this.newChatForm.value?.message,
        this.filesToAttach,
        this.platform,
        undefined,
        this.mailType,
      )
      .then((status) => {
        this.loading = false;
        this.errorInSendMail = false;
        if (status?.failure) {
          this.toastrService.warning(
            `Warning! Failed to deliver ${status.failure} emails. Please wait for a while before trying again`,
          );
        } else {
          this.campaignsService.emailInfluencersSuccessEvent(
            influencersWithEmail.map((i) => i.username),
            this.mailType,
          );
        }
        this.activeModal.close({ status, influencers: influencersWithEmail, ...this.newChatForm.value });
      })
      .catch(async (error: HttpErrorResponse) => {
        if (error.status === 429 || error.status === 415) {
          this.toastrService.error(error.error.message);
        }
        if (error.status === 400) {
          this.restrictedKeywordsUsed = true;
          this.toastrService.error(error.error.Error || error.error.message);
          this.loading = false;
          return;
        }

        if (this.isEmbeded) {
          this.activeModal.close({
            status: { success: 0, failure: 1 },
            influencers: influencersWithEmail,
            ...this.newChatForm.value,
          });
        }
        this.loading = false;
        this.errorInSendMail = true;
      });
  }

  checkMessageForRestrictedKeywords(): boolean {
    const restrictedKeywords = utils.hasIncorrectTemplateKeywords(
      this.newChatForm?.value?.subject + ' ' + this.newChatForm?.value?.message,
      this.supportedTemplatingVariables,
    );

    if (restrictedKeywords?.length) {
      this.restrictedKeywordsUsed = true;
      this.toastrService.error(
        `You have not yet configured the variable (${restrictedKeywords.join(', ')}) you are trying to insert`,
      );
      return false;
    }
    this.restrictedKeywordsUsed = false;
    return true;
  }

  getTemplatingVariablesForTipsMessage(start?: number, end?: number): string {
    const slicedTemplatingVariables = this.supportedTemplatingVariables.slice(start, end);
    return slicedTemplatingVariables?.length ? '{{' + slicedTemplatingVariables.join('}}, {{') + '}}' : undefined;
  }

  private getInfluencerLookupFunction() {
    if (Platforms[this.platform] === Platforms.instagram) {
      return (term: string) => this.influencerService.lookupInfluencers(term);
    } else if (Platforms[this.platform] === Platforms.facebook) {
      return (term: string) => this.facebookService.lookupInfluencers(term);
    } else if (Platforms[this.platform] === Platforms.youtube) {
      return (term: string) => this.youtubeService.lookupInfluencers(term);
    } else if (Platforms[this.platform] === Platforms.tiktok) {
      return (term: string) => this.tiktokService.lookupInfluencers(term);
    } else if (Platforms[this.platform] === Platforms.twitter) {
      return (term: string) => this.twitterService.lookupInfluencers(term);
    }
  }

  filterInfluencersToShow(influencers) {
    return influencers.slice(0, this.showMoreBtnVisible ? this.maxInitialInfluencersVisible : undefined);
  }

  handleChipEdit(item, isChipOnEditMode: boolean): void {
    item.editable = isChipOnEditMode;
    this.isChipOnEditMode = isChipOnEditMode;
  }

  updateCampaignInfluencerMetadataEmail(email: string, username: string): void {
    if (this.updateInfluencerEmailSubscription) {
      this.updateInfluencerEmailSubscription.unsubscribe();
    }
    this.updateInfluencerEmailSubscription = this.campaignsService
      .updateCampaignInfluencerMetadataEmail(this.campaignSlugName, email, username, this.platform)
      .subscribe(
        () => {
          this.toastrService.success('Influencer email updated successfully');
          this.campaignsService.sendInfluencersChangedEvent(
            this.campaignSlugName,
            this.campaignsService.influencersChanges.influencersEmailChipEdited,
          );
        },
        (error) => {
          console.error(error);
          this.toastrService.error('Failed to update campaign influencer email');
        },
      );
  }

  async handleSaveChip(input, item): Promise<void> {
    item.email = input.value;
    item.editable = false;
    if (this.campaignSlugName) {
      this.updateCampaignInfluencerMetadataEmail(item.email, item.username);
    } else {
      this.updateMetadata(item.username, input.value);
    }

    if (this.influencerEmailSetter) {
      this.influencerEmailSetter(item);
    }
    this.isChipOnEditMode = false;
  }

  updateMetadata(influencerUsername: string, updatedEmail: string): void {
    this.metadataService
      .getInfluencerMetadata(influencerUsername, this.platform)
      .then((influencerMetadata) => {
        if (influencerMetadata['Error']) {
          throw Error(influencerMetadata['Error']);
        }
        influencerMetadata.email = updatedEmail;
        this.metadataService
          .updateInfluencerMetadata(influencerUsername, this.platform, influencerMetadata)
          .then(() => {
            this.toastrService.success('Email updated successfully');
            if (this.campaignSlugName) {
              this.campaignsService.sendInfluencersChangedEvent(
                this.campaignSlugName,
                this.campaignsService.influencersChanges.influencersEmailChipEdited,
              );
            }
          })
          .catch(() => {
            console.log('Error in updating influencer metadata'); // Intentional
            this.toastrService.error("Email couldn't be updated");
          });
      })
      .catch(() => {
        console.log('Error in fetching influencer metadata'); // Intentional
        this.toastrService.error("Email couldn't be updated");
      });
  }

  previewChat(): void {
    if (!this.checkMessageForRestrictedKeywords()) {
      return;
    }
    this.chatPreviewInfluencers = this.influencersWithValidEmail();
    this.preview = true;
  }

  async openTemplates(): Promise<void> {
    this.activeModal.close();
    const autoreplies = this.modalService.open(EmailTemplatesModal, {
      size: 'lg',
      windowClass: 'detail-modal',
      centered: true,
      backdrop: 'static',
    });
    const modalContent = autoreplies.componentInstance;
    modalContent.campaignSlugName = this.campaignSlugName;
    modalContent.platform = this.platform;
  }

  loadTemplate(template: EmailTemplate) {
    if (template) {
      const { subject, template: message } = template;
      this.newChatForm.patchValue({ subject, message });
    }
  }

  get isSendingMailDisabled(): boolean {
    return (
      !this.influencersWithValidEmail()?.length ||
      !this.newChatForm.value.subject ||
      !this.newChatForm.value?.message ||
      this.loading ||
      !this.validFile ||
      this.editorUploading
    );
  }

  ngOnDestroy(): void {
    if (this.updateInfluencerEmailSubscription) {
      this.updateInfluencerEmailSubscription.unsubscribe();
    }
  }
}
