import { FormGroup } from '@angular/forms';
import {
  AnyStore,
  CredentialsOf,
  MagentoRestApiVersion,
  QuotaPlatform,
  StoreType,
  UnknownObject,
  Platform,
} from '../enums';

export interface Country {
  name: string;
}

export interface BlacklistInfluencerResponse {
  abortedUsernames: string[];
  backlistedUsernames: string[];
}

export interface Auth0User {
  id: string;
  email: string;
  isBasicUser: boolean;
  isProUser: boolean;
  isFreeUser: boolean;
  isSubscriptionUser: boolean;
  isAdminUser: boolean;
  isManager: boolean;
  isLiteUser: boolean;
  managedAccounts: string[];
  addOns: AddOns;
}

export interface AddOns {
  isMessagingEnabled: boolean;
  isStoriesReportingEnabled: boolean;
  exports: boolean;
  contentExports: boolean;
  campaignReports: boolean;
  pdfExports: boolean;
  pdfExportsLogo: boolean;
  creatorCampaigns: boolean;
  contentTrends: boolean;
  regions: string[];
  platformsEnabled: string[];
  campaignUpdateFrequency: number;
  maxInfluencerSearchResults: number;
  notificationEmail: string;
  concurrentSessions: number;
  campaigns?: boolean;
  creatorsPortal?: boolean;
  partnerizeId?: string;
  currency?: string;
  shopify?: string;
  community?: boolean;
  contentDiscovery?: boolean;
  influencerDiscovery?: boolean;
  console?: boolean;
  skyeSearch?: boolean;
  isGmailLinkEnabled?: boolean;
  emailSequence?: boolean;
}

export interface PublicAppSubscriptionPlan {
  name: string;
  charge: number;
  currency: string;
  chargeCycle: 'MONTHLY' | 'ANNUAL';
  isTheBestValue: boolean;
  isEnterprise: boolean;
  trialDays: number;
  addOns: AddOns;
  quotas: any;
}

export interface RawAuth0User {
  email?: string;
  email_verified?: boolean;
  username?: string;
  phone_number?: string;
  phone_verified?: boolean;
  user_id?: string;
  created_at?: string;
  updated_at?: string;
  identities?: Identity[];
  app_metadata?: AppMetadata;
  user_metadata?: UserMetadata;
  picture?: string;
  name?: string;
  nickname?: string;
  multifactor?: string[];
  last_ip?: string;
  last_login?: string;
  logins_count?: number;
  blocked?: boolean;
  given_name?: string;
  family_name?: string;
}

export interface AppMetadata {
  [propName: string]: any;
}

export interface UserMetadata {
  [propName: string]: string;
}

export interface Identity {
  connection: string;
  user_id: string;
  provider: string;
  isSocial: boolean;
  access_token?: string;
  profileData?: {
    email?: string;
    email_verified?: boolean;
    name?: string;
    phone_number?: string;
    phone_verified?: boolean;
    request_language?: string;
  };
}

export class NewClient {
  email: string;
  password: string;
  fullName: string;
  company: string;
  confirmPassword: string;
}

export interface Quota {
  type: string;
  name: string;
  period: string;
  used: number;
  limit: number;
  platform: QuotaPlatform;
}

export class Shopify {
  appName: string;
  apiKey: string;
  secretKey: string;
  apiPass: string;
  customApp?: {
    apiKey: string;
    secretKey: string;
  };
  shopUrl: string;
  store?: UnknownObject;
}

export interface OrganizationAddOns {
  isMessagingEnabled?: boolean;
  isStoriesReportingEnabled?: boolean;
  exports?: boolean;
  contentExports?: boolean;
  campaignReports?: boolean;
  pdfExports?: boolean;
  pdfExportsLogo?: boolean;
  creatorCampaigns?: boolean;
  contentTrends?: boolean;
  regions?: string[];
  platformsEnabled?: string[];
  campaignUpdateFrequency?: number;
  maxInfluencerSearchResults?: number;
  notificationEmail?: string;
  concurrentSessions?: number;
  campaigns?: boolean;
  creatorsPortal?: boolean;
  partnerizeId?: string;
  currency?: string;
  shopify?: string;
  community?: boolean;
  contentDiscovery?: boolean;
  influencerDiscovery?: boolean;
  skyeSearch?: boolean;
  isGmailLinkEnabled?: boolean;
  emailSequence?: boolean;
  console?: boolean;
  collaborationPortal?: boolean;
}

export interface OrganizationSettings {
  addOns?: OrganizationAddOns;
}

export interface Organization extends OrganizationSettings {
  id?: string;
  name: string;
  members: string[];
  brandPortalSettings: BrandPortalSettings;
  ecommerceStore: EcommerceStore<AnyStore>;
  paymentDetails?: PaymentMethods;
  competitorConsoles: string[];
}

export interface MinimalOrganizationInfoWithAccountId {
  organizationId: string;
  organizationName: string;
  accountId?: string;
}

export type ConsoleSeedingStatus = 'INITIATED' | 'SEEDING' | 'SEEDED' | 'ERROR';
export class BrandPortalSettings {
  name: string;
  logoUrl?: string | null;
  creatorPortalLink: string;
  storeLink: string;
  website?: string;
  communityLink?: string;
  location?: string;
  category?: string;
  consoleId?: string;
}
export class ConsoleConfiguration {
  id?: string;
  name: string;
  hashtags?: string[];
  platformUsernames?: Record<Platform, string>;
  networkSocialMediaForm?: FormGroup;
  orgId: string;
  type: 'BRAND' | 'COMPETITOR';
  status?: {
    tiktok?: {
      status?: ConsoleSeedingStatus;
      updateDate?: Date;
    };
    youtube?: {
      status?: ConsoleSeedingStatus;
      updateDate?: Date;
    };
    instagram?: {
      status?: ConsoleSeedingStatus;
      updateDate?: Date;
    };
  };

  updateDate?: Date;

  constructor(type: 'BRAND' | 'COMPETITOR') {
    this.name = '';
    this.hashtags = [];
    this.platformUsernames = {
      instagram: '',
      facebook: '',
      youtube: '',
      twitter: '',
      tiktok: '',
    };
    this.orgId = '';
    this.type = type;
  }
}

export interface EcommerceStore<T extends StoreType> {
  type: StoreType;
  credentials: CredentialsOf<T>;
  meta?: EcommerceStoreCommonMeta;
}

export interface EcommerceStoreCommonMeta {
  shopUrl: string;
  appName: string;
}

export interface StoreTypes {
  SHOPIFY: ShopifyCredentials;
  MAGENTO: MagentoCredentials;
}

export interface ShopifyCredentials {
  apiKey: string;
  secretKey: string;
  apiPass: string;
  customApp: {
    apiKey: string;
    secretKey: string;
    accessToken: string;
  };
}

export interface MagentoCredentials {
  url: string;
  consumerKey: string;
  consumerSecret: string;
  accessToken: string;
  accessTokenSecret: string;
  type?: MagentoRestApiVersion;
  sha?: 256 | 1;
}

interface PaypalCredentials {
  accountId: string;
  clientId: string;
  secret: string;
}

export interface PaymentMethods {
  paypal?: PaypalCredentials;
}
