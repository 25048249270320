<div class="modal-header">
  <span>Add to Group</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-3" *ngIf="singlePlatformSelectionEnabled">
    <label class="af-sub-heading-h2  p-0">Select Platform <span class="required">*</span></label>
    <div class="d-flex" style="gap: 20px;">
      <div class="af-platform-radio-group" *ngFor="let account of availablePlatforms">
        <label class="af-radio-group" [ngClass]="!enabledPlatform.includes(account) ? 'disabled': null">{{account |
          titlecase}}
          <input [disabled]="!enabledPlatform.includes(account)" type="radio" [value]="account"
            [checked]="account === platform?.toLowerCase()" (change)="onPlatformSelectionChange($event.target.value)"
            name="radio">
          <span class="af-radio"></span>
        </label>
      </div>

    </div>
  </div>
  <div class="form-group">
    <input class="input-group input-group-sm" type="text" placeholder="Search a group" [(ngModel)]="searchText"
      keyupDebounce (onEvent)="searchGroups()">
  </div>
  <div class="groups-list">
    <div class="group" [class.selected]="!!selectedGroups[group.slugName]" *ngFor="let group of groups"
      (click)="toggleSelectedGroup(group)">
      <div class="group-name" data-cy="existing-group-select">{{group.name}}
        <span *ngIf="!isUserGroup(group)"> (shared) </span>
      </div>
      <i class="far fa-check" *ngIf="!!selectedGroups[group.slugName]"></i>
    </div>
  </div>
</div>
<nb-spinner *ngIf="isLoading" class="large-spinner"></nb-spinner>
<div class="modal-footer">
  <ai-button typography="body-3" class="mr-2" (click)="addToGroups()" [disabled]="isSelectedGroupsEmpty() || isLoading"
    label="ADD
    TO GROUPS" data-cy="add-to-groups-btn"></ai-button>
</div>