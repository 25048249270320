import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(inputText: string, searchText: string, escapeSequence?: string): SafeHtml {
    if (!inputText) {
      return '';
    }
    if (!searchText) {
      return inputText;
    }

    /*
     TO avoid the behavior of special characters "\\$&"
     // => Escaped character.
     $ => Matches the end of the string, or the end of a line if the multiline flag.
     & => Matches the special characters.

     OUTPUT
     . => /. , $ => /$
    */
    searchText = searchText.trim().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

    inputText = escapeSequence ? inputText.substr(escapeSequence.length) : inputText;

    const regEx = new RegExp(searchText, 'ig');
    const str = inputText.match(regEx);

    const value = str ? inputText.replace(regEx, `<span style='color:#40DC7E'>${str[0]}</span>`) : inputText;
    return this._sanitizer.bypassSecurityTrustHtml(escapeSequence ? escapeSequence + value : value);
  }
}
