import { Component, Output, TemplateRef, ViewChild, EventEmitter, Input, ContentChild } from '@angular/core';
import { DropdownPanel } from './dropdown-panel';

@Component({
  selector: 'ai-template-dropdown',
  templateUrl: './template-dropdown.component.html',
  styleUrls: ['./template-dropdown.component.css'],
})
export class TempplateDropdownComponent implements DropdownPanel {
  @ContentChild(TemplateRef) dropdownRef;
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();
  @Input() width = 330;
  @Input() height = 268;
  @Input() bgColor = 'white';
}
