import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RELEASE_FLAGS } from '../feature-flag/flags';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';

@Injectable()
export class VeraInstagramGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private featureFlagService: FeatureFlagService) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const hasInstagramEnabled = this.auth.isInstagramEnabled();

    const isVeraInstagramEnabled = hasInstagramEnabled;
    if (!isVeraInstagramEnabled) {
      console.error('Vera Instagram guard rejected access to ' + state.url);
      this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return isVeraInstagramEnabled;
  }
}
