import { AutoWidthDirective } from './../../../fluence/src/app/shared/directives/auto-width.directive';
import { isNumber } from 'lodash';
import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ai-num-input',
  templateUrl: './num-input.control.html',
  styleUrls: ['./num-input.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumInputControl),
      multi: true,
    },
  ],
})
export class NumInputControl implements ControlValueAccessor {
  @Input() label: string;
  @Input() readonly = false;
  @Input() clearable = false;
  @Input() placeholder: string = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() hint: string;
  @Input() error: string;
  @Input() hideError = false;
  @Input() minValue: number = null;
  @Input() maxValue: number = null;

  control: FormControl = new FormControl();

  standingValue = '';

  @ViewChild('aiControl', { static: false }) aiControl: ElementRef;
  @ViewChild(AutoWidthDirective, { static: false }) autoWidthDirective: AutoWidthDirective;

  private valueChangesSubscription: Subscription;

  ngOnInit(): void {
    this.standingValue = this.control.value?.toString() ?? '';

    this.valueChangesSubscription = this.control.valueChanges.subscribe((value) => {
      this.standingValue = value?.toString() ?? '';
    });
  }

  writeValue(value: number): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }

    this.valueChangesSubscription = this.control.valueChanges.subscribe((value) => {
      this.standingValue = value?.toString() ?? '';
      fn(value);
    });
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  registerOnTouched(fn: any): void {
    // No action needed
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  onInputChange(value: string): void {
    const inputNumber = parseInt(value);
    const isValid = this.checkNumberValidity(value) && this.checkAgainstBoundary(inputNumber, true, true);
    if (isValid) {
      this.standingValue = value;
      this.control.setValue(inputNumber);
    } else if ((!value || isNaN(inputNumber)) && this.clearable) {
      this.standingValue = '';
      this.control.setValue(null);
    } else {
      this.setValueBack();
    }
  }

  setValueBack(): void {
    this.aiControl.nativeElement.value = this.standingValue;
    const standingNumber = parseInt(this.standingValue);
    this.control.setValue(isNaN(standingNumber) ? null : standingNumber);
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp') {
      this.incrementValue();
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      this.decrementValue();
      event.preventDefault();
    }
  }

  adjustInputWidthManually(): void {
    if (this.autoWidthDirective) {
      setTimeout(() => {
        this.autoWidthDirective.adjustWidth();
      }, 50);
    }
  }

  incrementValue(): void {
    const incrementedValue = (this.control.value ?? 0) + 1;
    if (this.checkAgainstBoundary(incrementedValue, false, true) && !this.disabled) {
      this.control.setValue(incrementedValue);
      this.adjustInputWidthManually();
    }
  }

  decrementValue(): void {
    const decrementedValue = (this.control.value ?? 0) - 1;
    if (this.checkAgainstBoundary(decrementedValue, true, false) && !this.disabled) {
      this.control.setValue(decrementedValue);
      this.adjustInputWidthManually();
    }
  }

  checkNumberValidity(value: string | any): boolean {
    return /^-?\d+$/.test(value);
  }

  checkAgainstBoundary(num: number, checkMinBoundary = false, checkMaxBoundary = false): boolean {
    let res = true;
    if (checkMinBoundary && isNumber(this.minValue) && num < this.minValue) {
      res = false;
    }
    if (checkMaxBoundary && isNumber(this.maxValue) && num > this.maxValue) {
      res = false;
    }
    return res;
  }
}
