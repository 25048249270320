import { DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { SharedModule } from '../../shared/shared.module';
import { PrintableProfileComponent } from './printable-profile/printable-profile.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { BrandedContentDistributionCardComponent } from '../profile/branded-content-distribution-card/branded-content-distribution-card.component';
import { PrintChartContainerComponent } from './print-chart-container/print-chart-container.component';
import { PrintableReportComponent } from './printable-report/printable-report.component';
import { RouterModule, Routes } from '@angular/router';
import { CampaignReportsModule } from '../campaign-reports/campaign-reports.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

const printRoutes: Routes = [
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'profile/:username', component: PrintableProfileComponent },
      { path: 'report/:reportId', component: PrintableReportComponent },
    ],
  },
];

@NgModule({
  imports: [ThemeModule, SharedModule, RouterModule.forChild(printRoutes), CampaignReportsModule, LazyLoadImageModule],
  declarations: [
    PrintableProfileComponent,
    PrintLayoutComponent,
    PrintChartContainerComponent,
    PrintableReportComponent,
  ],
  providers: [PercentPipe, DecimalPipe],
  exports: [BrandedContentDistributionCardComponent, RouterModule],
})
export class PrintModule {}
