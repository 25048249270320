import {
  ImportAffiliateOrdersCsvResponse,
  ManualOrderSyncAPIResponse,
} from './../../../interfaces/campaign-affiliate.interface';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  AffiliateInfluencersResponse,
  AffiliateOrder,
  AffiliateOrdersDetailsResponse,
  AffiliateSummary,
  AffiliateSummaryAggregations,
  ManualOrderSync,
  Sort,
} from '../../../interfaces';
import utils from '../../utils/utils';
import { PlatformsV2 } from '../../../enums';

@Injectable()
export class AffiliateService {
  constructor(private http: HttpClient) {}

  private paymentSuccessful = new Subject<boolean>();
  paymentSuccessful$ = this.paymentSuccessful.asObservable();

  sendPaymentSuccessfulEvent(paymentSuccess: boolean): void {
    this.paymentSuccessful.next(paymentSuccess);
  }

  getAffiliateSummary(campaignSlugName: string, from: Date, to: Date): Promise<AffiliateSummary> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post<AffiliateSummary>(`${environment.api}/api/campaigns/${campaignSlugName}/affiliate/summary`, {
        from: fromUTC,
        to: toUTCEndOfDay,
      })
      .toPromise();
  }

  getAffiliateInfluencers(
    revenueGenerating = true,
    campaignSlugName: string,
    from: Date,
    to: Date,
    page: number,
    sort?: Sort,
    search?: string,
  ): Promise<AffiliateInfluencersResponse> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post<AffiliateInfluencersResponse>(
        environment.api +
          `/api/campaigns/${campaignSlugName}/${revenueGenerating ? 'affiliate' : 'affiliate-without-sales'}`,
        {
          from: fromUTC,
          to: toUTCEndOfDay,
          sortBy: sort ? sort.sortCriteria : undefined,
          sortDirection: sort ? sort.sortOrder : undefined,
          page,
          search,
        },
      )
      .toPromise();
  }

  getPreviousManualOrderSync(campaignSlugName: string): Promise<ManualOrderSync> {
    return this.http
      .get<ManualOrderSync>(`${environment.api}/api/campaigns/${campaignSlugName}/affiliate/sync-status`)
      .toPromise();
  }

  syncAffiliateOrdersManually(campaignSlugName: string): Promise<ManualOrderSyncAPIResponse> {
    return this.http
      .post<ManualOrderSyncAPIResponse>(
        `${environment.api}/api/campaigns/${campaignSlugName}/affiliate/sync-script`,
        {},
      )
      .toPromise();
  }

  getAffiliateOrderDetails(
    campaignSlugName: string,
    affiliateCode: string,
    from: Date,
    to: Date,
    page: number,
    limit: number,
    unpaidOnly?: boolean,
    allRecords?: boolean,
  ): Promise<AffiliateOrdersDetailsResponse> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post<AffiliateOrdersDetailsResponse>(
        environment.api + `/api/campaigns/${campaignSlugName}/affiliate/${affiliateCode}/details`,
        {
          from: fromUTC,
          to: toUTCEndOfDay,
          limit,
          page,
          allRecords,
          unpaidOnly,
        },
      )
      .toPromise();
  }

  importAffiliateOrdersAsCsv(
    campaignSlugName: string,
    affiliateOrdersCsv: File,
  ): Promise<ImportAffiliateOrdersCsvResponse> {
    const formData = new FormData();
    formData.append('affiliateOrdersCSV', affiliateOrdersCsv, affiliateOrdersCsv.name);
    return this.http
      .post<ImportAffiliateOrdersCsvResponse>(
        environment.api + `/api/campaigns/${campaignSlugName}/affiliate/import`,
        formData,
      )
      .toPromise();
  }

  exportAffiliateInfluencers(
    campaignSlugName: string,
    affiliateCodes: string[],
    allInfluencers: boolean,
    from: Date,
    to: Date,
    isOnlyRevenueGenInfluencers: boolean,
  ): Promise<Blob> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .post(
        `${environment.api}/api/campaigns/${campaignSlugName}/affiliate/export`,
        {
          affiliateCodes,
          allInfluencers,
          from: fromUTC,
          to: toUTCEndOfDay,
          isOnlyRevenueGenInfluencers,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }

  payAffiliateOrders(
    campaignSlugName: string,
    influencerUsername: string,
    AffiliateOrders: AffiliateOrder[],
    platform: PlatformsV2,
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/v2/${campaignSlugName}/payments`, {
        influencerUsername: influencerUsername,
        commissionDetails: AffiliateOrders,
        platform,
      })
      .toPromise();
  }

  markAffiliateOrdersAsPaid(
    campaignSlugName: string,
    influencerUsername: string,
    AffiliateOrders: AffiliateOrder[],
    affiliateCode: string,
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/v2/${campaignSlugName}/affiliate/${affiliateCode}/manualPayments`, {
        influencerUsername: influencerUsername,
        commissionDetails: AffiliateOrders,
      })
      .toPromise();
  }

  getAffiliateSummaryDetails(
    campaignSlugName: string,
    affiliateSummaryFilterBy: 'day' | 'week' | 'month',
    affiliateEventType: string,
    from: Date,
    to: Date,
  ): Promise<AffiliateSummaryAggregations> {
    const { fromUTC, toUTCEndOfDay } = utils.getUTCDateRanges(from, to);
    return this.http
      .get<AffiliateSummaryAggregations>(
        `${environment.api}/api/campaigns/${campaignSlugName}/affiliate/summary/details`,
        {
          params: {
            affiliateSummaryFilterBy,
            affiliateEventType,
            from: fromUTC,
            to: toUTCEndOfDay,
          },
        },
      )
      .toPromise();
  }
}
