import { Injectable } from '@angular/core';
import { includes } from 'lodash';
import { Subject } from 'rxjs';
import { AgeGroup, InstagramInfluencerSortKey } from '../../enums';
import {
  BiographyKeyword,
  BrandLookup,
  CategoryInfo,
  LabeledAgeGroup,
  LanguageInfo,
  InfluencerWithLogic,
} from '../../interfaces';
import { ContentTimeFilter, SearchModifiers } from './content-filter.service';
import { Location } from './location.service';

@Injectable()
export class InfluencerFilterService {
  private searchFilter = new Subject<InfluencerSearchFilters>();
  searchFilter$ = this.searchFilter.asObservable();

  setSearchFilters(
    influencerFilters: InfluencerFilters,
    audienceFilters: AudienceFilters,
    sortKey: InstagramInfluencerSortKey,
    onlyTopInfluencers: boolean,
  ): void {
    this.searchFilter.next({
      influencerFilters,
      audienceFilters,
      sortKey,
      onlyTopInfluencers,
    });
  }
}

@Injectable()
export class InfluencerFilterHelperService {
  readonly labeledAgeGroups: LabeledAgeGroup[] = [
    { label: '<19', ageGroup: 0 },
    { label: '19-25', ageGroup: 1 },
    { label: '26-32', ageGroup: 2 },
    { label: '33-39', ageGroup: 3 },
    { label: '40-46', ageGroup: 4 },
    { label: '46+', ageGroup: 5 },
  ];

  public ageGroupsToCheckboxes(ageGroups: AgeGroup[]): boolean[] {
    return this.labeledAgeGroups.map((labeledAgeGroup) => includes(ageGroups, labeledAgeGroup.ageGroup));
  }

  public checkboxesToAgeGroups(checkboxes: boolean[]): AgeGroup[] {
    return this.labeledAgeGroups
      .map((labeledAgeGroup, i) => (checkboxes[i] ? labeledAgeGroup.ageGroup : null))
      .filter((ageGroup) => ageGroup !== null);
  }
}

export class FollowersGrowth {
  bucket: 'last_week' | 'last_month' | 'last_3_months' | 'last_6_months';
  valueRange: { min: number; max?: number };
}

export class InfluencerFilters {
  time: ContentTimeFilter;
  username?: string = undefined;
  influencers?: InfluencerWithLogic[] = [];
  searchGroup = false;
  groupName: string = undefined;
  groupNames: string[] = [];
  ageGroups: AgeGroup[] = [];
  gender = new Gender();
  affableVerified = false;
  interests: CategoryInfo[] = [];
  bioKeywords: BiographyKeyword[] = [];
  brands: BrandLookup[] = [];
  followers = new Followers();
  suspiciousFollowersPercent = 15;
  engagementRatePercent = 0;
  locations: Location[] = [];
  contactInfoAvailable = false;
  searchModifiers: SearchModifiers = new SearchModifiers();
  sponsored = false;
  includePhotographers = false;
  includeMakeupArtists = false;
  excludeGroupInfluencers? = false;
  followersGrowth: FollowersGrowth = new FollowersGrowth();
  languages: LanguageInfo[] = [];
}

export class AudienceFilters {
  ageGroups: AgeGroup[] = [];
  gender = new Gender();
  interests: string[] = [];
  brands: BrandLookup[] = [];
  relative = false;
  countries: string[] = [];
}

export class Followers {
  min = 5000;
  max = 1000000;
}

export class Gender {
  male = false;
  female = false;
}

export class InfluencerSearchFilters {
  influencerFilters: InfluencerFilters;
  audienceFilters: AudienceFilters;
  sortKey: InstagramInfluencerSortKey;
  onlyTopInfluencers? = false;
}

export enum FollowerCountFilterLimitsEnum {
  MINIMUM = 0,
  MAXIMUM = 1000_000_000_000,
}
