<div class="support">
  <div class="support-button" (click)="onSupportClicked()" *ngIf="!hideSupportButton">
    <i *ngIf="!displaySupportMenu" class="fa-regular fa-circle-question"></i>
    <i *ngIf="displaySupportMenu" class="fa-regular fa-circle-xmark"></i>
  </div>
  <div *ngIf="displaySupportMenu" class="support-menu">
    <div class="header">
      <div class="img-container">
        <img src="../../../assets/images/affable-glyph-w-container-no-txt.png" alt="Affable Logo">
      </div>
      <div class="af-heading-h2">Help & Support</div>
    </div>
    <div class="body">

      <div class="support-card" *ngFor="let option of supportOptions">
        <a target="_blank" [href]="option.link">
          <div class="card-contents">
            <div class="af-heading-h2">
              <i [class]="option.icon"></i>
              {{option.header}}
            </div>
            <div class="af-body-4" [innerHTML]="option.body">
            </div>
          </div>
          <i class="fa-regular fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
</div>