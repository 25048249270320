import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgProgress } from 'ngx-progressbar';
import { ContentService } from '../../@core/data/content.service';
import { InfluencerVideoItem, YoutubeContent, YoutubeVideo } from '../../interfaces';
import { FeatureFlagService } from '../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../@core/feature-flag/flags';

@Component({
  selector: 'ngx-content-video-details',
  styleUrls: ['./content-video-details.component.scss'],
  templateUrl: './content-video-details.component.html',
})
export class ContentVideoDetailsComponent implements OnInit {
  @Input()
  set videoContent(videoContent: YoutubeContent) {
    if (videoContent) {
      this.video = videoContent;
      this.channelName = videoContent.author.username;
      this.profilePictureUrl = videoContent.author.profilePictureUrl;
      this.inContentDiscoveryPage = true;
    }
  }

  @Input()
  set videoItem(videoItem: InfluencerVideoItem) {
    if (videoItem) {
      this.video = videoItem.video;
      this.channelName = videoItem.channelInfo.name;
      this.profilePictureUrl = videoItem.channelInfo.profilePictureUrl;
    }
  }

  video: YoutubeContent | YoutubeVideo;
  channelName: string;
  profilePictureUrl: string;
  publishedAt: string;
  youtubeUrl: string;
  videoIframe: HTMLIFrameElement;
  loaded = false;
  inContentDiscoveryPage = false;
  isRestricted = false;
  @Input() currency: string;
  @Input() hideStats = false;

  constructor(
    private activeModal: NgbActiveModal,
    private contentService: ContentService,
    public progress: NgProgress,
    private featureFlagService: FeatureFlagService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.progress.ref().start();
    this.publishedAt = moment(this.video.publishedAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').fromNow();
    this.youtubeUrl = `https://www.youtube.com/embed/${this.video.id}?rel=0&theme=light`;
    this.videoIframe = document.getElementById('video-content-iframe') as HTMLIFrameElement;
    this.videoIframe.src = this.youtubeUrl;
    this.isRestricted = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.APP_UPDATES);
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
