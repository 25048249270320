<!-- hashtag or caption-->
<div class="form-group">
    <div class="filter-label-container">
        <label class="filter-label">Hashtag or Caption</label>
        <ngx-tooltip
            placement="bottom-left"
            size="large"
            [tooltip]="'Usage: Add hashtag (#) to keyword to search for hashtag.<br>Searching for a word returns all images that have a caption containing the word'">
        </ngx-tooltip>
        <div class="toggle-switch-container">
            <ngx-toggle-switch @fadeInOut *ngIf="hashtags?.length>1" [(searchModifier)]="searchModifiers"
                (searchModifierChange)="updateSearchModifierEvent()">
            </ngx-toggle-switch>
        </div>
    </div>
    <div class="input-group input-group-sm">
        <ng-select #hashtagSelect placeholder="Eg. #ootd or gardensbythebay" notFoundText="No hashtags found"
            (change)="updateHashtagsEvent()" [items]="[]" [multiple]="true" [(ngModel)]="hashtags"
            [addTag]="convertToTag" [hideSelected]="true" [markFirst]="false" [isOpen]="false"
            (blur)="onHashtagSelectBlur()">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value {{item.isIncluded}}" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.label}}</span>
                    <span class="ng-value-icon" (click)="toggleHashtagLogic(item)" aria-hidden="true"
                        *ngIf="item.isIncluded">
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </span>
                    <span class="ng-value-icon" (click)="toggleHashtagLogic(item)" aria-hidden="true"
                        *ngIf="!item.isIncluded">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
</div>
