/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  FacebookPrintableProfile,
  InstagramPrintableProfile,
  PagedCards,
  PrintableProfile,
  ReportPrintableProfile,
  TiktokPrintableProfile,
  YoutubePrintableProfile,
} from '../../../interfaces/print.interface';
import { ToastrService } from 'ngx-toastr';
import { A4, HEIGHTS } from '../../../enums/print.enum';
import { Platforms, PlatformsV2 } from '../../../enums';
import { WhiteLabelService } from '../../../@core/data/whitelabel.service';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  // card static height's
  heights = HEIGHTS;
  // A4 Height in 140 DPI Scale/Resolution
  readonly A4 = A4;
  isPrinting = false;
  dataToPrint:
    | PrintableProfile
    | FacebookPrintableProfile
    | ReportPrintableProfile
    | TiktokPrintableProfile
    | YoutubePrintableProfile
    | InstagramPrintableProfile; // | or any other Printable object;
  selectedCards: string[] = ['bio'];
  chartsFullyLoaded = new Subject<boolean>();
  chartsFullyLoaded$ = this.chartsFullyLoaded.asObservable();
  toast: ToastrService;
  totalPages = 0;
  selectedCardsCfg: PagedCards[];
  username;
  platform: PlatformsV2 = Platforms.instagram;
  isPaginated: boolean;
  documentName: string;
  constructor(private router: Router, private whiteLabelService: WhiteLabelService) {}

  isIGProfile(igProfile: any, platform?: PlatformsV2): igProfile is PrintableProfile {
    return (igProfile as PrintableProfile).influencer !== undefined && platform === 'Instagram';
  }

  isYTProfile(ytProfile: any): ytProfile is YoutubePrintableProfile {
    return (ytProfile as YoutubePrintableProfile).profile.channel !== undefined;
  }

  isFBProfile(fbProfile: any): fbProfile is FacebookPrintableProfile {
    return (fbProfile as FacebookPrintableProfile).profile !== undefined;
  }

  isVeraProfile(igProfile: any, platform?: PlatformsV2): igProfile is InstagramPrintableProfile {
    return (igProfile as InstagramPrintableProfile).influencer !== undefined && platform === 'VeraInstagram';
  }

  isReport(report: any): report is ReportPrintableProfile {
    return (report as ReportPrintableProfile).campaignReport !== undefined;
  }

  getProfileUsername(dataToPrint: any, platform?: PlatformsV2): string {
    /**
     * It is important to note that the order of conditions in the below statements matter.
     * The conditions should be arranged in a logical and meaningful way to ensure
     * that the correct code block is executed.
     */
    if (this.isIGProfile(dataToPrint, platform)) {
      return dataToPrint.influencer.profile.username;
    }
    if (this.isVeraProfile(dataToPrint, platform)) {
      return dataToPrint.influencer.username;
    }
    if (this.isYTProfile(dataToPrint)) {
      return dataToPrint.profile.channel.id;
    }
    if (this.isFBProfile(dataToPrint)) {
      return dataToPrint.profile.username;
    }
  }
  printProfileDocument(
    documentName: string,
    selectedCards: string[],
    isPaginated: boolean,
    profile?: string,
    printableObject?: any,
  ) {
    this.documentName = documentName;
    this.isPaginated = isPaginated;
    this.isPrinting = true;
    this.dataToPrint = printableObject;
    this.selectedCards = selectedCards;
    this.totalPages = this.getTotalPages();
    this.username = this.getProfileUsername(this.dataToPrint, this.dataToPrint.platform as PlatformsV2);
    this.platform = this.dataToPrint.platform as PlatformsV2;
    this.generatePagedCards();
    if (this.dataToPrint) {
      try {
        this.router.navigate([
          '/',
          {
            outlets: {
              print: ['print', documentName, profile],
            },
          },
        ]);
      } catch (e) {
        this.toast.error(`Error occurred while generating PDF !`, 'PDF export Error!');
      }
    }
  }

  printReportDocument(documentName: string, posts: any[], b: boolean, campaignId: string, printableObject: any) {
    this.documentName = documentName;
    this.isPrinting = true;
    this.dataToPrint = printableObject;
    this.platform = this.dataToPrint.platform as Platforms;
    try {
      this.router.navigate([
        '/',
        {
          outlets: {
            print: ['print', documentName, campaignId],
          },
        },
      ]);
    } catch (e) {
      this.toast.error(`Error occurred while generating PDF !`, 'PDF export Error!');
    }
  }

  onDataReady(): void {
    // get doc title & consider whitelabel clients !
    const docTitle = this.whiteLabelService.getConfig().appTitle;
    setTimeout(() => {
      // PDF file name // TODO next line working olny for Profiles (IG/FB)
      if (this.documentName === 'report') {
        if ('campaignReport' in this.dataToPrint) {
          window.document.title = `${docTitle}-${this.platform}-${this.dataToPrint.campaignReport?.campaign?.name}`;
        }
      } else {
        window.document.title = `${docTitle}-${this.platform}-${this.username}`;
      }
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }], { replaceUrl: true }).then(() => {
        window.document.title = docTitle;
        // expected delay on success message to prevent rendering toast message on the PDF document!
        // TODO: enable success message need design team review
        /*
        this.toast.success(
          `PDF Document generated successfully for user ${this.username} !`,
          'profile exported successfully',
        );
        */
      });
    }, 1000);
  }

  private getTotalPages(): number {
    let maxHeight = 0;
    for (const card of this.selectedCards) {
      maxHeight += this.heights[card];
    }
    return Math.ceil((maxHeight / this.A4.height).toFixed(1) as unknown as number);
  }

  // detect card pages, and set each first card of its page as page tag holder
  generatePagedCards(): void {
    const cards: any[] = this.selectedCards;
    const pages = [];
    let maxHeight = 0;
    for (const card of cards) {
      maxHeight += this.heights[card];
      pages.push(Math.ceil((maxHeight / this.A4.height).toFixed(1) as unknown as number));
    }
    let currentPage = 0;
    this.selectedCardsCfg = cards.map((card, index) => {
      if (currentPage != pages[index]) {
        currentPage = pages[index];
        return { card, page: pages[index], pageTag: true };
      } else {
        currentPage = pages[index];
        return { card, page: pages[index], pageTag: false };
      }
    });
  }
}
