/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CampaignAggregateStats,
  CampaignInfluencer,
  CampaignInfluencersResponse,
  CampaignInfoStoreConfigurations,
  CampaignReportResponse,
  CampaignsInfluencers,
  CampaignsInfosResponse,
  CampaignStoriesResponse,
  CampaignStory,
  ClientOrganizationMembers,
  ContentPost as FacebookContent,
  Creator,
  EmailTemplate,
  ExternalLink,
  formatProposalStatus,
  InfluencerInfo,
  PartnerizeCampaign,
  PartnerizeCampaignPublisher,
  Product,
  PublishersStats,
  SelectedStory,
  Sort,
  UpdateInfluencerResponse,
  BasicInstagramInfluencerInfo,
  CampaignStoriesCountResponse,
  CampaignsInfosWithInfluencersResponse,
  AccountOrganizationMember,
} from '../../interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject, of } from 'rxjs';
import { InfluencerLookup } from './../../interfaces/influencer.interface';
import { InfluencerShippingDetails } from './../../interfaces/proposal.interface';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  InfluencerChanges,
  InfluencerProductSelectionWithOriginalProduct,
  MailType,
  Platforms,
  PlatformsV2,
  ProposalStatusAPI,
  SortDirection,
  Status,
  VeraPlatforms,
} from '../../enums';
import { ContentTimeFilter } from './content-filter.service';
import { PartialContent } from './content.service';
import utils from '../utils/utils';
import { UserguidingService } from '../data/userguiding.service';
import { CampaignInfluencerAssignee } from './metadata.service';

@Injectable()
export class CampaignsService {
  influencersChanges = InfluencerChanges;

  private sendInfluencersMail = new Subject<{ influencers: InfluencerInfo[]; mailType: MailType }>();
  sendInfluencersMail$ = this.sendInfluencersMail.asObservable();

  private switchToInfluencersTabSource = new Subject<void>();
  switchToInfluencersTab$ = this.switchToInfluencersTabSource.asObservable();

  private emailInfluencersSuccess = new Subject<{ usernames: string[]; mailType: MailType }>();
  emailInfluencersSuccess$ = this.emailInfluencersSuccess.asObservable();

  private campaignChangedSource = new Subject<string>();
  campaignChanged$ = this.campaignChangedSource.asObservable();

  private influencersChangedSource = new Subject<{ campaignSlug: string; event: InfluencerChanges }>();
  influencersChanged$ = this.influencersChangedSource.asObservable();

  private filtersChangedSource = new Subject<CampaignContentFilters>();
  filtersChanged$ = this.filtersChangedSource.asObservable();

  private proposalStatusChangedSource = new Subject<{ campaignSlug: string }>();
  proposalStatusChangedSource$ = this.proposalStatusChangedSource.asObservable();

  private contentChangedSource = new Subject<string>();
  contentChangedSource$ = this.contentChangedSource.asObservable();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private userguiding: UserguidingService) {}

  createCampaign(
    name: string,
    hashtags: string[],
    mentions: string[],
    from: Date,
    to: Date,
    platform: PlatformsV2,
    platforms: string[],
    partnerizeCampaign?: PartnerizeCampaign,
  ): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/campaigns/new/info`,
        {
          campaignName: name,
          hashtags: hashtags,
          mentions: mentions,
          from: from.getTime(),
          to: to ? to.getTime() : undefined,
          // platforms: platforms.length ? platforms : ['INSTAGRAM'],
          timezone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
          partnerizeCampaignId: partnerizeCampaign ? partnerizeCampaign.campaignId : undefined,
          partnerizeCampaignTitle: partnerizeCampaign ? partnerizeCampaign.title : undefined,
        },
        { params: { platform } },
      )
      .pipe(
        tap(({ campaignSlugName, createdAt }: Campaign) => {
          this.userguiding.track('Created a campaign', { campaignSlugName, createdAt });
          this.userguiding.identify({ created_campaign: 1 });
        }),
        map((campaign: Campaign) => new Campaign(this.formatCampaignPlatform(campaign))),
      )
      .toPromise();
  }

  duplicateCampaign(campaignSlugName: string, platform: string): Promise<Campaign> {
    return this.http
      .post<Campaign>(environment.api + `/api/campaigns/v2/duplicate`, {}, { params: { campaignSlugName, platform } })
      .toPromise();
  }

  getTemplates(
    campaignSlugName: string,
    sortByCreationDate?: SortDirection,
    isSavedTemplatesAtTop?: boolean,
  ): Promise<{
    types: { type: string; templates: EmailTemplate[] }[];
    supportedVariables: string[];
    invitationTypes: { type: string; templates: EmailTemplate[] }[];
  }> {
    return this.http
      .get<{
        types: { type: string; templates: EmailTemplate[] }[];
        supportedVariables: string[];
        invitationTypes: { type: string; templates: EmailTemplate[] }[];
      }>(environment.api + `/api/campaigns/v2/${campaignSlugName}/templates`, {
        params: {
          ...(!!sortByCreationDate && { sortByCreationDate }),
          ...(!!isSavedTemplatesAtTop && { isSavedTemplatesAtTop: isSavedTemplatesAtTop.toString() }),
        },
      })
      .toPromise();
  }

  editCampaign(
    campaignSlug: string,
    name: string,
    hashtags: string[],
    mentions: string[],
    to: Date,
    from: Date,
    timezone: string,
    platform: PlatformsV2,
    immediateEnd = false,
    partnerizeCampaign?: PartnerizeCampaign,
  ): Promise<any> {
    return this.http
      .put(
        environment.api + `/api/campaigns/${campaignSlug}`,
        {
          campaignName: name,
          hashtags: hashtags,
          mentions: mentions,
          to: to ? to.getTime() : undefined,
          from: from.getTime(),
          timezone: timezone,
          immediateEnd: immediateEnd,
          partnerizeCampaignId: partnerizeCampaign ? partnerizeCampaign.campaignId : undefined,
          partnerizeCampaignTitle: partnerizeCampaign ? partnerizeCampaign.title : undefined,
        },
        { params: { platform } },
      )
      .pipe(map((campaign: Campaign) => new Campaign(this.formatCampaignPlatform(campaign))))
      .toPromise();
  }

  sendCampaignChangedEvent(campaignSlug: string): void {
    this.campaignChangedSource.next(campaignSlug);
  }

  deleteCampaign(campaignSlug: string, platform: PlatformsV2): Promise<any> {
    return this.http
      .delete(environment.api + `/api/campaigns/${campaignSlug}`, {
        params: { platform: platform },
      })
      .toPromise();
  }

  getCampaign(campaignSlug: string): Promise<Campaign> {
    return this.http
      .get<Campaign>(environment.api + `/api/campaigns/${campaignSlug}`)
      .pipe(map((campaign: Campaign) => new Campaign(this.formatCampaignPlatform(campaign))))
      .toPromise();
  }

  getCampaignAggregateStats(campaignSlug: string, platform: PlatformsV2): Promise<CampaignAggregateStats> {
    return this.http
      .get<CampaignAggregateStats>(environment.api + `/api/campaigns/${campaignSlug}/aggregates`, {
        params: { platform: platform },
      })
      .toPromise();
  }

  getCampaignInfluencers(
    campaignSlug: string,
    pageNumber: number,
    allInfluencers: boolean,
    sort?: Sort,
    platform?: PlatformsV2,
    statusFilters?: string[],
    searchTerm?: string,
    registered?: boolean,
  ): Promise<CampaignInfluencersResponse> {
    return this.http
      .get<CampaignInfluencersResponse>(environment.api + `/api/campaigns/${campaignSlug}/influencers`, {
        params: {
          pageNumber: pageNumber.toString(),
          allInfluencers: allInfluencers.toString(),
          ...sort,
          ...(platform && { platform }),
          ...(statusFilters ? { statusFilters } : {}),
          ...(searchTerm && { searchTerm }),
          registered: registered?.toString(),
        },
      })
      .pipe(map(this.sanitizeInfluencerLocations))
      .toPromise();
  }

  getCampaignInfluencersObservable(
    campaignSlug: string,
    pageNumber: number,
    allInfluencers: boolean,
    sort?: Sort,
    platform?: PlatformsV2,
    statusFilters?: string[],
    searchTerm?: string,
    registered?: boolean[],
  ): Observable<CampaignInfluencersResponse> {
    return this.http
      .get<CampaignInfluencersResponse>(environment.api + `/api/campaigns/${campaignSlug}/influencers`, {
        params: {
          pageNumber: pageNumber.toString(),
          allInfluencers: allInfluencers.toString(),
          ...sort,
          ...(platform && { platform }),
          ...(statusFilters ? { statusFilters } : {}),
          ...(searchTerm && { searchTerm }),
          registered: registered?.toString(),
        },
      })
      .pipe(map(this.sanitizeInfluencerLocations));
  }

  transformCampaignInfluencerResponse(campaignInfluencers: CampaignInfluencersResponse): CampaignInfluencersResponse {
    // change proposal status string from ProposalStatusAPI to ProposalStatusUI
    campaignInfluencers.influencers.map((influencer) => {
      influencer.influencerMetadata.proposalStatus = formatProposalStatus(influencer.influencerMetadata.proposalStatus);
      return influencer;
    });
    return campaignInfluencers;
  }

  sanitizeInfluencerLocations(campaignInfluencers: CampaignInfluencersResponse): CampaignInfluencersResponse {
    campaignInfluencers.influencers.forEach((influencer) => {
      influencer.influencer.locations = influencer.influencer.locations?.filter((location) => !!location);
    });
    return campaignInfluencers;
  }

  getCampaignPendingInfluencers(campaignSlug: string, platform: PlatformsV2): Promise<string[]> {
    return this.http
      .get<string[]>(environment.api + `/api/campaigns/${campaignSlug}/pending-influencers`, { params: { platform } })
      .toPromise();
  }

  getCampaignPrivateInfluencers(campaignSlug: string, platform: PlatformsV2): Promise<string[]> {
    return this.http
      .get<string[]>(environment.api + `/api/campaigns/${campaignSlug}/private-influencers`, { params: { platform } })
      .toPromise();
  }

  getCampaignNonRegisteredInfluencers(
    campaignSlug: string,
    platform: PlatformsV2,
  ): Promise<BasicInstagramInfluencerInfo[]> {
    return this.http
      .get<BasicInstagramInfluencerInfo[]>(environment.api + `/api/campaigns/${campaignSlug}/non-registered`, {
        params: { platform },
      })
      .toPromise();
  }

  addInfluencersToCampaign(
    campaignSlugName: string,
    usernames: string[],
    platform: PlatformsV2,
    groupSlugName?: string,
    allUsers = false,
    addedViaCommunity = false,
    communityApplicantIds: string[] = null,
  ): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/campaigns/${campaignSlugName}/influencers`,
        { usernames: usernames, communityApplicantIds },
        {
          params: {
            ...(groupSlugName ? { platform, groupSlugName, allUsers: allUsers.toString() } : { platform }),
            addedViaCommunity: addedViaCommunity.toString(),
          },
        },
      )
      .pipe(
        tap(() => {
          const identifier = this.userguiding.getSourceIdentifier('added_influencer_campaign');
          this.userguiding.track('Added influencer to a campaign', { campaignSlugName, usernames });
          this.userguiding.identify({ [identifier]: 1 });
        }),
      )
      .toPromise();
  }

  addInfluencersToCampaigns(
    campaignSlugNames: string[],
    usernames: string[],
    platform: PlatformsV2,
    groupSlugName?: string,
    allUsers = false,
    addedViaCommunity = false,
    communityApplicantIds: string[] = null,
  ): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/campaigns/influencers`,
        { campaignSlugNames, usernames: usernames, communityApplicantIds },
        {
          params: {
            ...(groupSlugName ? { platform, groupSlugName, allUsers: allUsers.toString() } : { platform }),
            addedViaCommunity: addedViaCommunity.toString(),
          },
        },
      )
      .pipe(
        tap(() => {
          const identifier = this.userguiding.getSourceIdentifier('added_influencer_campaign');
          this.userguiding.track('Added influencer to a campaign', { campaignSlugNames, usernames });
          this.userguiding.identify({ [identifier]: 1 });
        }),
      )
      .toPromise();
  }

  addMediasToCampaign(
    campaignSlug: string,
    mediaCodes: string[],
    platform: PlatformsV2,
    username?: string,
  ): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/campaigns/${campaignSlug}/influencers/media`,
        {
          mediaCodes,
          username,
        },
        { params: { platform } },
      )
      .toPromise();
  }

  lookupCampaignInfluencers(
    campaignSlug: string,
    term: string,
    platform: Platforms | VeraPlatforms = Platforms.instagram,
  ): Observable<InfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    term = utils.getUsernameFromInstagramUrlIfPresent(term);
    return this.http.get<InfluencerLookup[]>(`${environment.api}/api/campaigns/${campaignSlug}/search-influencers`, {
      params: {
        term: term.trim(),
        platform,
      },
    });
  }

  addStoryToCampaign(
    campaignSlug: string,
    campaignStroy: CampaignStory,
    storyImage: File,
    insights?: { reach?: number; impressions?: number },
  ): Promise<any> {
    const formData = new FormData();
    formData.append('campaignStory', JSON.stringify(campaignStroy));
    formData.append('storyImage', storyImage, storyImage.name);
    formData.append('insights', JSON.stringify(insights));
    return this.http.post(environment.api + `/api/campaigns/${campaignSlug}/influencers/story`, formData).toPromise();
  }

  removeInfluencersfromCampaign(campaignSlug: string, influencers: string[], platform: PlatformsV2): Promise<any> {
    return this.http
      .request('delete', `${environment.api}/api/campaigns/${campaignSlug}/influencer`, {
        body: {
          usernames: influencers,
        },
        params: { ...(platform && { platform }) },
      })
      .toPromise();
  }

  sendInfluencersChangedEvent(campaignSlug: string, event: InfluencerChanges): void {
    this.influencersChangedSource.next({ campaignSlug, event });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sendInfluencersEmailEvent(influencers, mailType: MailType = MailType.default): void {
    this.sendInfluencersMail.next({ influencers, mailType });
  }

  switchToInfluencersTabEvent(): void {
    this.switchToInfluencersTabSource.next();
  }

  emailInfluencersSuccessEvent(usernames: string[], mailType: MailType = MailType.default): void {
    this.emailInfluencersSuccess.next({ usernames, mailType });
  }

  searchInfluencersInCampaign(
    campaignSlug: string,
    name: string,
    pageNumber: number,
    platform: PlatformsV2,
    statusFilters?: string[],
    proposalStatusFilters?: ProposalStatusAPI[],
  ): Promise<CampaignInfluencersResponse> {
    const params = {
      pageNumber: pageNumber.toString(),
      ...(statusFilters ? { statusFilters } : {}),
      ...(proposalStatusFilters ? { proposalStatusFilters } : {}),
      search: name,
      platform: platform,
    };

    return this.http
      .get<CampaignInfluencersResponse>(environment.api + `/api/campaigns/${campaignSlug}/influencers/search`, {
        params,
      })
      .pipe(
        map((campaignInfluencers: CampaignInfluencersResponse) =>
          this.transformCampaignInfluencerResponse(campaignInfluencers),
        ),
      )
      .pipe(map(this.sanitizeInfluencerLocations))
      .toPromise();
  }

  editInfluencerInCampaign(
    campaignSlug: string,
    influencer: Partial<CampaignInfluencer>,
    platform: PlatformsV2,
  ): Promise<UpdateInfluencerResponse> {
    return this.http
      .put<UpdateInfluencerResponse>(
        environment.api + `/api/campaigns/${campaignSlug}/influencer`,
        {
          username: influencer?.influencer?.username,
          cost: influencer?.influencerMetadata?.cost,
          status: influencer?.influencerMetadata?.status,
          email: influencer?.influencerMetadata?.email,
          firstname: influencer?.influencerMetadata?.firstname,
          lastname: influencer?.influencerMetadata?.lastname,
          rating: influencer?.influencerMetadata?.rating,
          phone: influencer?.influencerMetadata?.phone,
          partnerizePublisherName: influencer?.influencerMetadata?.partnerizePublisherName,
          partnerizePublisherId: influencer?.influencerMetadata?.partnerizePublisherId,
          affiliateCode: influencer?.influencerMetadata?.affiliateCode,
          fixedPay: influencer?.influencerMetadata?.fixedPay,
          paypalId: influencer?.influencerMetadata?.paypalId,
          affiliatePay: influencer?.influencerMetadata?.affiliatePay,
          influencersCommission: influencer?.influencerMetadata?.influencersCommission,
        },
        { params: { ...(platform && { platform }) } },
      )
      .toPromise();
  }

  addAssigneeToCampaignInfluencers(
    campaignSlugName: string,
    campaignInfluencerAssigneeMap: { username: string; assignee?: CampaignInfluencerAssignee }[],
    platform: PlatformsV2,
  ): Promise<CampaignInfluencer> {
    return this.http
      .put<CampaignInfluencer>(
        environment.api + `/api/campaigns/${campaignSlugName}/influencers/assignee`,
        campaignInfluencerAssigneeMap,
        {
          params: {
            platform,
          },
        },
      )
      .toPromise();
  }

  updateCampaignInfluencerMetadataEmail(
    campaignSlug: string,
    email: string,
    username: string,
    platform: PlatformsV2,
  ): Observable<UpdateInfluencerResponse> {
    return this.http.put<UpdateInfluencerResponse>(
      environment.api + `/api/campaigns/${campaignSlug}/influencer`,
      {
        username: username,
        email: email,
      },
      { params: { ...(platform && { platform }) } },
    );
  }

  addInfluencerShippingDetails(
    campaignSlugName: string,
    shippingDetails: InfluencerShippingDetails,
    platform: PlatformsV2,
    username: string,
  ): Promise<InfluencerShippingDetails> {
    return this.http
      .put<InfluencerShippingDetails>(environment.api + `/api/campaigns/${campaignSlugName}/shipping-details/`, {
        shippingDetails,
        metadata: {
          username,
          platform,
        },
      })
      .toPromise();
  }

  updateInfluencersStatus(
    campaignSlug: string,
    influencerUsernames: string[],
    platform: PlatformsV2,
    status: Status,
  ): Promise<any> {
    return this.http
      .put(
        environment.api + `/api/campaigns/${campaignSlug}/influencers`,
        {
          influencerUsernames,
        },
        { params: { ...(platform && { platform }), status } },
      )
      .toPromise();
  }

  // TODO :  a refactor of API URLS to be introduced here and in other instances
  // better to have it as /api/v2/campaigns/${campaignSlugName}/influencers/${influencerId}/products
  updateStatusInfluencersProposalProductSelection(
    campaignSlugName: string,
    influencerId: string,
    selectedProducts: Product[],
  ): Promise<any> {
    return this.http
      .put(environment.api + `/api/campaigns/v2/${campaignSlugName}/edit-products/${influencerId}`, {
        selectedProducts,
      })
      .toPromise();
  }

  updateInfluencerPaymentDetails(
    campaignSlug: string,
    influencerUsername: string,
    platform: PlatformsV2,
    oldPaypalEmailId: string,
    paypalEmailId: string,
  ): Promise<string> {
    return this.http
      .put<string>(
        environment.api + `/api/campaigns/${campaignSlug}/influencer/payment`,
        {
          influencerUsername,
          oldPaypalEmailId,
          paypalEmailId,
        },
        { params: { ...(platform && { platform }) } },
      )
      .toPromise();
  }

  bulkUpdateInfluencerPaypalDetails(
    campaignSlug: string,
    influencers: { username: string; paypalId: string }[],
    platform: PlatformsV2,
  ): Promise<string[]> {
    return this.http
      .post<any>(
        environment.api + `/api/campaigns/${campaignSlug}/influencer/bulk-update-paypal-ids`,
        {
          influencers,
        },
        { params: { ...(platform && { platform }) } },
      )
      .pipe(
        map((res: any) => {
          return res.updatedInfluencerUsernames;
        }),
      )
      .toPromise();
  }

  getCampaignInfos(
    page: number,
    platform: Platforms | PlatformsV2,
    { owner, from, to, campaignName }: { from?: Date; to?: Date; owner?: string | null; campaignName?: string } = {},
  ): Promise<CampaignsInfosResponse> {
    return this.http
      .get<CampaignsInfosResponse>(environment.api + `/api/campaigns/all/infos`, {
        params: JSON.parse(
          JSON.stringify({
            page: page.toString(),
            platform,
            from: from ? Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()) : undefined,
            // * 23:59:59 to get the end of the day
            to: to ? Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59) : undefined,
            owner: owner ? owner : undefined,
            campaignName: campaignName ? campaignName : undefined,
          }),
        ),
      })
      .pipe(
        map((campaignsInfoRes: CampaignsInfosResponse) => {
          const campaignsInfo = [];
          for (const campaign of campaignsInfoRes.campaigns) {
            campaignsInfo.push(new Campaign(campaign));
          }
          campaignsInfoRes.campaigns = campaignsInfo;
          return campaignsInfoRes;
        }),
      )
      .toPromise();
  }

  getCampaignInfosWithInfluencers(
    page: number,
    platform: Platforms | PlatformsV2,
    influencerIds: string[],
    usernames: string[],
    { owner, from, to, campaignName }: { from?: Date; to?: Date; owner?: string | null; campaignName?: string } = {},
  ): Promise<CampaignsInfosWithInfluencersResponse> {
    return this.http
      .post<CampaignsInfosWithInfluencersResponse>(
        environment.api + `/api/campaigns/all/infosWithInfluencers`,
        {
          influencerIds,
          usernames,
        },
        {
          params: JSON.parse(
            JSON.stringify({
              page: page.toString(),
              platform,
              from: from ? Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()) : undefined,
              // * 23:59:59 to get the end of the day
              to: to ? Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59) : undefined,
              owner: owner ? owner : undefined,
              campaignName: campaignName ? campaignName : undefined,
            }),
          ),
        },
      )
      .pipe(
        map((campaignsInfoRes) => {
          const campaignsInfo = [];
          for (const campaign of campaignsInfoRes.campaigns) {
            campaignsInfo.push(new CampaignWithInfluencers(campaign));
          }
          campaignsInfoRes.campaigns = campaignsInfo;
          return campaignsInfoRes;
        }),
      )
      .toPromise();
  }

  searchCampaignContents(
    filters: CampaignContentFilters,
    campaignSlug: string,
    pageNumber: number,
    platform: PlatformsV2,
    allContent: boolean,
  ): Observable<ContentResponse> {
    return this.http
      .post<ContentResponse>(
        environment.api + `/api/campaigns/${campaignSlug}/content`,
        {
          ...filters,
          time: {
            ...filters.time,
            to: filters.time.to.getTime(),
            from: filters.time.from.getTime(),
          },
        },
        {
          params: {
            pageNumber: pageNumber.toString(),
            platform,
            allContent: allContent.toString(),
          },
        },
      )
      .pipe(
        map((res: ContentResponse) => {
          for (const content of res.content) {
            if ('fbUrl' in content) {
              const url = `https://www.facebook.com/plugins/post.php?href=${content.fbUrl}&show_text=false&height=10&width=10`;
              content.safePostUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
              content.hasExpiredThumbnail = false;
            }
          }
          return res;
        }),
      );
  }

  sendFiltersChangedEvent(filters: CampaignContentFilters): void {
    this.filtersChangedSource.next(filters);
  }

  sendProposalStatusChangedEvent(campaignSlug: string): void {
    this.proposalStatusChangedSource.next({ campaignSlug });
  }

  addLink(campaignSlug: string, influencerUsername: string, link: Link, platform: Platforms): Promise<Link[]> {
    return this.http
      .post<Link[]>(environment.api + `/api/campaigns/${campaignSlug}/influencers/${influencerUsername}/links`, link, {
        params: { platform: platform },
      })
      .toPromise();
  }

  deleteLink(campaignSlug: string, influencerUsername: string, link: string, platform: Platforms): Promise<Link[]> {
    return this.http
      .delete<Link[]>(environment.api + `/api/campaigns/${campaignSlug}/influencers/${influencerUsername}/links`, {
        params: { platform, link },
      })
      .toPromise();
  }

  updateLink(campaignSlug: string, influencerUsername: string, link: Link, platform: Platforms): Promise<Link> {
    return this.http
      .put<Link>(environment.api + `/api/campaigns/${campaignSlug}/influencers/${influencerUsername}/links`, link, {
        params: { platform: platform },
      })
      .toPromise();
  }

  getLinks(campaignSlug: string, influencerUsername: string, platform: Platforms): Promise<Link[]> {
    return this.http
      .get<Link[]>(environment.api + `/api/campaigns/${campaignSlug}/influencers/${influencerUsername}/links`, {
        params: { platform: platform },
      })
      .toPromise();
  }

  // Campaign Stories

  getCampaignStories(
    campaignSlug: string,
    pageNumber: number,
    platform: PlatformsV2,
    filters: CampaignContentFilters,
  ): Observable<CampaignStoriesResponse> {
    return this.http.post<CampaignStoriesResponse>(
      environment.api + `/api/campaigns/${campaignSlug}/stories`,
      {
        ...filters,
        time: {
          ...filters.time,
          to: filters.time.to.getTime(),
          from: filters.time.from.getTime(),
        },
      },
      { params: { pageNumber: pageNumber.toString(), platform } },
    );
  }

  getCampaignStoriesCountMap(
    campaignSlug: string,
    filters: CampaignContentFilters,
  ): Observable<CampaignStoriesCountResponse> {
    return this.http.post<CampaignStoriesCountResponse>(
      environment.api + `/api/campaigns/${campaignSlug}/stories/totalCount`,
      {
        ...filters,
        time: {
          ...filters.time,
          to: filters.time.to.getTime(),
          from: filters.time.from.getTime(),
        },
      },
    );
  }

  exportStories(
    campaignSlug: string,
    filters: CampaignContentFilters,
    allStories: boolean,
    selectedStories: SelectedStory[],
    platform: PlatformsV2,
  ): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/campaigns/${campaignSlug}/stories/export`,
        {
          ...filters,
          time: {
            ...filters.time,
            to: filters.time.to.getTime(),
            from: filters.time.from.getTime(),
          },
          allStories: allStories,
          selectedStories: selectedStories,
        },
        { responseType: 'blob', params: { platform } },
      )
      .toPromise();
  }

  // Campaign reports
  getCampaignReports(campaignSlug: string, pageNumber: number, platform: PlatformsV2): Promise<CampaignReportResponse> {
    return this.http
      .get<CampaignReportResponse>(`${environment.api}/api/campaigns/${campaignSlug}/reports`, {
        params: { pageNumber: pageNumber.toString(), platform: platform },
      })
      .toPromise();
  }

  exportCampaignAspptx(campaignSlug: string, platform: string): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/campaigns/${campaignSlug}/export`,
        {},
        { responseType: 'blob', params: { platform } },
      )
      .toPromise();
  }

  shareCampaign(campaignSlug: string, members: ClientOrganizationMembers[], platform: PlatformsV2): Promise<any> {
    return this.http
      .put(`${environment.api}/api/campaigns/${campaignSlug}/share`, { members }, { params: { platform } })
      .toPromise();
  }

  // Parternize Campaign
  getPartnerizeCampaigns(): Promise<PartnerizeCampaign[]> {
    return this.http.get<PartnerizeCampaign[]>(`${environment.api}/api/partnerize/campaigns`).toPromise();
  }

  // Parternize Campaign
  getPartnerizeCampaignPublishers(campaignId: string): Promise<PartnerizeCampaignPublisher[]> {
    return this.http
      .get<PartnerizeCampaignPublisher[]>(`${environment.api}/api/partnerize/campaign/${campaignId}/publishers`)
      .toPromise();
  }

  getCurrencies(): Promise<string[]> {
    return this.http.get<string[]>(`${environment.api}/api/partnerize/campaign/currencies`).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getPartnerizeCampaignStats(campaignId: string, body: any): Promise<PublishersStats> {
    return this.http
      .post<PublishersStats>(`${environment.api}/api/partnerize/campaign/${campaignId}/stats`, body)
      .toPromise();
  }

  exportCampaignInfluencers(campaignSlug: string, usernames: string[], platform: Platforms): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/users/export/search`,
        {
          campaignSlugName: campaignSlug,
          usernames,
        },
        { responseType: 'blob', params: { ...(platform && { platform }) } },
      )
      .toPromise();
  }

  exportCreatorPortalLinks(
    campaignSlugName: string,
    usernames: string[],
    isAllInfluencersSelected: boolean,
    platform: string,
  ): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/campaigns/v2/${campaignSlugName}/creator-portal-links/export`,
        {
          usernames,
          isAllInfluencersSelected,
        },
        { params: { platform }, responseType: 'blob' },
      )
      .toPromise();
  }

  exportContentApprovalLinks(campaignSlugName: string, usernames: string[], platform: string): Promise<any> {
    return this.http
      .post(
        environment.api + `/api/campaigns/v2/${campaignSlugName}/content-approval-links/export`,
        {
          usernames,
        },
        { params: { platform }, responseType: 'blob' },
      )
      .toPromise();
  }

  getOrganizationMembers(
    ownerId: string,
    {
      forSharing = 'true',
      showNames = 'false',
      filterWithoutNames = 'true',
    }: { forSharing?: 'true' | 'false'; showNames?: 'true' | 'false'; filterWithoutNames?: 'true' | 'false' } = {},
  ): Observable<ClientOrganizationMembers[]> {
    return this.http
      .post<ClientOrganizationMembers[]>(
        environment.api + `/api/client/members`,
        { ownerId },
        { params: { forSharing, showNames } },
      )
      .pipe(
        map((orgMembers) =>
          orgMembers.filter(
            (member) =>
              member &&
              (forSharing === 'false' || member._id !== ownerId) &&
              (showNames === 'false' || filterWithoutNames === 'false' || member.name),
          ),
        ),
      );
  }

  getAccountOrganizationMembers(): Observable<AccountOrganizationMember[]> {
    return this.http.get<AccountOrganizationMember[]>(`${environment.api}/api/client/organization/accounts`);
  }

  getCampaignsCreators(platform: string): Observable<{ id: string; name: string; email: string }[]> {
    return this.http.get<any>(environment.api + `/api/campaigns/creators/infos`, { params: { platform } });
  }

  private formatCampaignPlatform(campaign: Campaign): Campaign {
    campaign.platform =
      campaign.platform === Platforms.instagram.toUpperCase()
        ? Platforms.instagram
        : campaign.platform === Platforms.facebook.toUpperCase()
        ? Platforms.facebook
        : campaign.platform === Platforms.tiktok.toUpperCase()
        ? Platforms.tiktok
        : campaign.platform === Platforms.youtube.toUpperCase()
        ? Platforms.youtube
        : campaign.platform === VeraPlatforms.instagram.toUpperCase()
        ? VeraPlatforms.instagram
        : Platforms.twitter;

    return campaign;
  }

  getCampaignsInfluencersByUsernames(usernames: string[], platform: PlatformsV2): Promise<CampaignsInfluencers> {
    return this.http
      .post<CampaignsInfluencers>(
        environment.api + `/api/campaigns/by-influencer-usernames`,
        {
          usernames: usernames.toString(),
        },
        {
          params: { platform },
        },
      )
      .pipe(
        map((campaignsInfluencers: CampaignsInfluencers) => {
          for (const campaignSlugName in campaignsInfluencers) {
            if (campaignsInfluencers[campaignSlugName]?.campaignInfo) {
              const campaignInfo = new Campaign(campaignsInfluencers[campaignSlugName].campaignInfo);
              campaignsInfluencers[campaignSlugName].campaignInfo = campaignInfo;
            } else {
              delete campaignsInfluencers[campaignSlugName];
            }
          }
          return campaignsInfluencers;
        }),
      )
      .toPromise();
  }

  deleteCampaignContent(campaignSlugName: string, contentIds: string[]): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/${campaignSlugName}/delete-content`, {
        contentIds,
      })
      .toPromise();
  }

  sendContentChangedEvent(campaignSlug: string): void {
    this.contentChangedSource.next(campaignSlug);
  }

  deleteCampaignStories(
    campaignSlugName: string,
    storiesIds: string[],
    filters: CampaignContentFilters,
    allStories: boolean,
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/${campaignSlugName}/delete-stories`, {
        ...filters,
        time: {
          ...filters.time,
          from: filters.time.from.getTime(),
          to: filters.time.to.getTime(),
        },
        storiesIds,
        allStories,
      })
      .toPromise();
  }

  sendProductGiftsToInfluencer(
    campaignSlugName: string,
    influencerId: string,
    products: InfluencerProductSelectionWithOriginalProduct[],
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/${campaignSlugName}/send-product-gift`, {
        influencerId,
        products,
      })
      .pipe(
        tap((_) => {
          this.userguiding.track('Sent product gift to an influencer', {
            campaignSlugName,
            influencerIds: [influencerId],
            productsCount: products?.length,
          });
          this.userguiding.identify({ sent_product_gift: 1 });
        }),
      )
      .toPromise();
  }

  getCampaignInfluencerContentApprovalLink(
    campaignSlugName: string,
    influencerUsername: string,
    platform: string,
  ): Promise<any> {
    return this.http
      .post(environment.api + `/api/campaigns/${campaignSlugName}/content-approval/link`, {
        campaignSlugName,
        influencerUsername,
        platform,
      })
      .toPromise();
  }
}

export class CampaignContentFilters {
  time: ContentTimeFilter = new ContentTimeFilter();
  hashtags: string[] = [];
  sponsored = false;
  brands: string[] = [];
  influencerIds?: string[];
}

export class ContentResponse {
  content: (PartialContent | FacebookContent)[];
  total: number;
  hasMoreContent: boolean;
}

export class Campaign {
  id?: string;
  platform: PlatformsV2;
  platforms?: PlatformsV2[];
  clientId?: string;
  campaignName: string;
  campaignSlugName: string;
  to?: number;
  from: number;
  mentions: string[];
  timezone: string;
  hashtags: string[];
  createdAt?: string;
  updatedAt?: string;
  integrations: Map<string, ExternalLink>;
  isShared: boolean;
  isManagedAccount: boolean;
  creator?: Creator;
  sharedWith?: string[];
  storeConfigurations: CampaignInfoStoreConfigurations;
  isWorkflowAvailable?: boolean;
  pendingInfluencers?: string[];
  excludedInfluencerUsernames?: string[];

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(campaign) {
    this.id = campaign.id;
    this.clientId = campaign.clientId;
    this.campaignName = campaign.campaignName;
    this.campaignSlugName = campaign.campaignSlugName;
    this.to = campaign.to;
    this.from = campaign.from;
    this.mentions = campaign.mentions;
    this.timezone = campaign.timezone;
    this.hashtags = campaign.hashtags;
    this.createdAt = campaign.createdAt;
    this.updatedAt = campaign.updatedAt;
    this.platform = campaign.platform;
    this.platforms = campaign.platforms;
    this.integrations = campaign.integrations;
    this.isShared = campaign.isShared;
    this.isManagedAccount = campaign.isManagedAccount;
    this.creator = campaign.creator;
    this.sharedWith = campaign.sharedWith;
    this.storeConfigurations = campaign.storeConfigurations;
    this.isWorkflowAvailable = campaign.isWorkflowAvailable;
    this.pendingInfluencers = campaign.pendingInfluencers ?? [];
    this.excludedInfluencerUsernames = campaign.excludedInfluencerUsernames ?? [];
  }

  isActive(): boolean {
    const now = new Date().getTime();
    return this.to ? now > this.from && now < this.to : now > this.from;
  }
}
export class CampaignWithInfluencers extends Campaign {
  influencers?: string[];

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(campaign) {
    super(campaign);
    this.influencers = campaign.influencers;
  }
}

export class Link {
  long_url: string;
  title: string;
  link?: string;
  id?: string;
  created_at?: string;
  customLink?: string;
}
