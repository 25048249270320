export type InputType =
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'month'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'
  | 'checkbox'
  | 'radio';

export type AutoComplete =
  | 'off'
  | 'on'
  | 'name'
  | 'honorific-prefix'
  | 'given-name'
  | 'additional-name'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'one-time-code'
  | 'organization-title'
  | 'organization'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-level4'
  | 'address-level3'
  | 'address-level2'
  | 'address-level1'
  | 'country'
  | 'country-name'
  | 'postal-code'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'cc-number'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year'
  | 'cc-csc'
  | 'cc-type'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'tel'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-extension'
  | 'impp'
  | 'url';

export enum ControlError {
  email = '<strong>Invalid</strong> email id',
  required = 'This is a <strong>required</strong> field',
  min = 'Below <strong>min</strong> limit',
  max = 'Above <strong>max</strong> limit',
  minLength = 'Below <strong>min</strong> length',
  maxLength = 'Above <strong>max</strong> length',
  pattern = 'Does not match the <strong>format</strong>',
  validatePhoneNumber = '<strong>Invalid</strong> phone number',
  default = 'Control Error',
}

export type ColumnProperty =
  | 'text'
  | 'number'
  | 'date'
  | 'tags'
  | 'influencer'
  | 'platforms'
  | 'contact'
  | 'shipping'
  | 'selected'
  | 'actions';
export type SubColumnProperty = 'quantity' | 'cost' | 'percent';
