<ng-template #connect>
  <div class="email-introduction-modal">
    <div class="modal-header">
      <button data-cy="close-email-introduction-modal" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h4>Connect Your Email</h4>
      <div class="px-3 py-2">
        <label class="details mb-4">
          Connect your email account with Affable
        </label>
        <ul class="connection-points">
          <li *ngFor="let item of connectEmailPoints">{{ item }}</li>
        </ul>
        <label class="details mt-1">
          Choose your email service provider
        </label>
        <div class="d-flex justify-content-between mt-2">
          <div class="box" *ngFor="let item of emailData | keyvalue"
            [style.border-color]="connectWith === item.key ? '#72D887' : '#c5cee0'"
            (click)="emailData[item.key].disabled ? null :connectWith = item.key">
            <img [src]="emailData[item.key].icons" alt="" />
            <label class="d-block mt-3">{{ emailData[item.key].name }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer justify-content-{{isManagedAccountScreen ? 'start':'between'}}">
      <button [disabled]="!connectWith" class="btn btn-xs btn-primary" (click)="templateRefExp = nextConnectStep">
        CONTINUE
      </button>
      <button *ngIf="!isManagedAccountScreen" class="btn btn-xs btn-outline-primary" (click)="closeModal()">ASK ME
        LATER</button>
      <button *ngIf="!isManagedAccountScreen" class="btn btn-xs btn-link" (click)="templateRefExp = confirm">NEVER ASK
        ME AGAIN</button>
    </div>
  </div>
</ng-template>

<ng-template #nextConnectStep>
  <div class="email-introduction-modal">
    <div class="modal-header">
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <h3>Connect Your {{ emailData[connectWith].connect_name }} Account</h3>
      <img [src]="emailData[connectWith].connect_image" alt="" style="width: 180px;" />

      <p class="selected-mail-text mt-3">
        You have selected <strong>{{ emailData[connectWith].name }}</strong> as your email service provider.
      </p>
      <p class="selected-mail-text mb-4">
        To change the email service provider,
        <a href="javascript:void(0)" (click)="templateRefExp = connect">Click here</a>.
      </p>

      <strong class="mb-2 account selected-mail-text px-1">
        Here’s what to expect when you connect your email account to Affable
      </strong>

      <div class="info px-1">
        <strong>Info:</strong>
        <p [innerHTML]="emailData[connectWith].info"></p><br />
        <p>For more information on the Google integration and the types of data we can access, please see our
          <a target='_blank' href={{privacyPolicyHref}}>Privacy Policy</a>
        </p>
      </div>
      <p class="notes mt-2 px-1">
        <strong>Note:</strong> Access can be revoked anytime via the “manage account” section in your profile.
      </p>
    </div>
    <nb-spinner *ngIf="loading" message="loading..."></nb-spinner>
    <div class="modal-footer justify-content-start">
      <button *ngIf="connectWith === 'gmail' ? isGmailLinkEnabled : true" class="btn btn-xs btn-primary"
        (click)="integrate()">
        CONNECT YOUR ACCOUNT
      </button>
      <button *ngIf="connectWith ==='gmail' && !isGmailLinkEnabled" class="btn btn-xs btn-primary"
        (click)="requestGmailLink()">
        REQUEST ACCOUNT CONNECTION
      </button>
      <button class="btn btn-xs btn-link" (click)="neverIntegrate()">CANCEL</button>
    </div>
  </div>
</ng-template>

<ng-template #syncedAndRevokeRef>
  <div class="email-introduction-modal">
    <div class="modal-header">
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body text-center add-padding">
      <h3>{{ syncedAndRevoke[status]?.title }}</h3>
      <img [src]="syncedAndRevoke[status].image(connectWith)" alt="" style="width: 230px;" />

      <label class="selected-mail-text mt-3 d-block">{{ syncedAndRevoke[status].msg(connectWith)}}</label>
      <strong>{{email}}</strong>

      <p class="selected-mail-text my-4 text-center">
        {{ syncedAndRevoke[status].second_msg }} via the <strong>manage account</strong> section in your profile.
      </p>

      <button class="btn btn-xs btn-primary lg-btn my-2" (click)="navigate()">
        {{ syncedAndRevoke[status].btn }}
      </button>

      <p class="notes my-4 px-1 font-weight-bold">
        {{ syncedAndRevoke[status].redirect_msg }} {{count}}s
      </p>
    </div>
  </div>
</ng-template>

<ng-template #requestedGmailLinkRef>
  <div class="email-introduction-modal">
    <div class="modal-header">
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body text-center add-padding">
      <h3>Request Submitted</h3>
      <img src="../../../../assets/images/successful.svg" alt="" style="width: 230px;" />

      <label class="selected-mail-text mt-3 d-block">
        Request to connect your Google Account with Affable has been successfully submitted. Our support team will get
        back to you in the next 48 hours.
      </label>

      <button class="btn btn-xs btn-primary lg-btn my-2" (click)="navigate()">
        GO TO CAMPAIGN DASHBOARD
      </button>

      <p class="notes my-4 px-1 font-weight-bold">
        You’ll be taken to the Campaign Dashboard page automatically in {{count}}s
      </p>
    </div>
  </div>
</ng-template>

<ng-template #confirm>
  <div class="email-introduction-modal">
    <div class="modal-header">
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body add-padding">
      <h4>Are you sure?</h4>

      <p class="confirmed-message mt-4" [innerHTML]="confirmModal[confirmModalStatus].line1"></p>
      <p class="confirmed-message mt-4" [innerHTML]="confirmModal[confirmModalStatus].line2"></p>

      <button class="btn btn-xs btn-outline-primary mt-3 mb-2 lg-btn"
        (click)="submitConfirm(confirmModalStatus)">CONFIRM</button>
      <button class="btn btn-xs btn-primary mb-5 lg-btn" (click)="goBack(confirmModalStatus)">GO BACK</button>
    </div>
    <nb-spinner *ngIf="loading" message="loading..."></nb-spinner>
  </div>
</ng-template>


<ng-container *ngTemplateOutlet="templateRefExp"></ng-container>
