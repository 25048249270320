import { FacebookAccount, InstagramAccount } from '../interfaces';

export type InstagramInfluencerSortKey = 'relevance' | 'followers' | 'engagement';
export type FacebookInfluencerSortKey = 'relevance' | 'followers' | 'engagement';
export type YoutubeInfluencerSortKey = 'relevance' | 'subscribers' | 'averageViews';
export type TiktokInfluencerSortKey = 'relevance' | 'followers' | 'averageViews';
export type TwitterInfluencerSortKey = 'relevance' | 'followers' | 'engagement';

export type InstagramContentSortKey = 'recency' | 'likes' | 'comments' | 'followers';
export type FacebookContentSortKey = 'recency' | 'reactions' | 'comments' | 'followers';
export type YoutubeContentSortKey = 'recency' | 'likes' | 'comments' | 'views' | 'subscribers';
export type TiktokContentSortKey = 'recency' | 'likes' | 'comments' | 'plays' | 'followers';
export type TwitterContentSortKey = 'recency' | 'likes' | 'retweets' | 'followers';

export type InfluencerSortKey =
  | InstagramInfluencerSortKey
  | FacebookInfluencerSortKey
  | YoutubeInfluencerSortKey
  | TiktokInfluencerSortKey
  | TwitterInfluencerSortKey;

export type ContentSortKey =
  | InstagramContentSortKey
  | FacebookContentSortKey
  | YoutubeContentSortKey
  | TiktokContentSortKey
  | TwitterContentSortKey;

export type LastestOldestKey = 'latest' | 'oldest';

// Facebook Media Items
export type PostMediaType = 'status' | 'photo' | 'article' | 'video' | 'shared_post' | 'event';

export type AudienceAgeGroup = '0_18' | '19_25' | '26_32' | '33_39' | '40_46' | '47_';

export type FbPlatform = 'instagram' | 'facebook';

export type SocialAccount<T extends FbPlatform> = T extends 'instagram'
  ? InstagramAccount
  : T extends 'facebook'
  ? FacebookAccount
  : never;

export type TwitterTweetTimeline = 'last 7 days' | 'historic';

export const ALL_PLATFORMS = [
  'instagram',
  'facebook',
  'youtube',
  'twitter',
  'tiktok',
  'linkedin',
  'soundcloud',
  'twitch',
  'discord',
  'website',
  'snapchat',
  'pinterest',
  'unknown',
] as const;

export type AllPlatform = typeof ALL_PLATFORMS[number];

export enum Platforms {
  instagram = 'Instagram',
  facebook = 'Facebook',
  youtube = 'Youtube',
  tiktok = 'Tiktok',
  twitter = 'Twitter',
}

export enum VeraPlatforms {
  instagram = 'VeraInstagram',
}
export enum PlatformsUpperEnum {
  YOUTUBE = 'YOUTUBE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  VERAINSTAGRAM = 'VERAINSTAGRAM',
}

export enum CommentSentimentType {
  NEUTRAL = 'neutral',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  SLIGHTLY_NEGATIVE = 'slightly negative',
  SLIGHTLY_POSITIVE = 'slightly positive',
}
export type PlatformsV2 = Platforms | VeraPlatforms;

export function platormIcon(platform: PlatformsUpperEnum): string {
  if (platform === PlatformsUpperEnum.VERAINSTAGRAM) {
    return 'fa-instagram';
  }
  return `fa-${platform.toLowerCase()}`;
}

export type Platform = keyof typeof Platforms;
