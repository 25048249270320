import { UserService } from './../../../../@core/data/user.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SequenceUniqueInfoWithLoaders } from './../../../../interfaces/email-sequence.interface';
import { PlatformsV2 } from './../../../../enums/social.enum';
import { EmailSequenceFilters } from '../../../../interfaces';
import { EmailSequenceService } from '../../../../@core/data/email-sequence.service';

@Component({
  selector: 'ngx-email-sequences',
  templateUrl: './email-sequences.component.html',
  styleUrls: ['./email-sequences.component.scss'],
})
export class EmailSequencesComponent implements OnInit {
  @Input()
  campaignSlugName: string;

  @Input()
  platform: PlatformsV2;

  @Input()
  isSharedCampaign = false;

  @Output()
  close = new EventEmitter();

  filterOptions = [
    { label: 'All', value: EmailSequenceFilters.ALL },
    { label: 'Enabled', value: EmailSequenceFilters.ENABLED },
    { label: 'Disabled', value: EmailSequenceFilters.DISABLED },
  ];

  filterKey = this.filterOptions[0].value;

  searchKeyword: string;

  emailSequences: SequenceUniqueInfoWithLoaders[] = [];
  searchTerm = '';
  pageNumber = 0;
  loading = false;
  hasMoreData = false;
  paginationLoader = false;
  isEmailIntegrated: boolean;
  getCampaignSequencesRequest?: Subscription;
  changeEmailSequenceStatusRequest?: {
    [id: string]: Subscription;
  } = {};

  constructor(
    private sequenceService: EmailSequenceService,
    private toastrService: ToastrService,
    private userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.checkUserEmailStatus();
    this.getEmailSequences();
  }

  async checkUserEmailStatus(): Promise<void> {
    this.loading = true;
    this.isEmailIntegrated = !!(await this.userService.getClientUser()).integratedEmails?.[0]?.email;
  }

  async changeStatus(event: { key: string; checked: boolean }): Promise<void> {
    const sequence = this.emailSequences.find((sequence) => sequence._id === event.key);
    if (sequence) {
      try {
        sequence.changingStatus = true;
        await this.sequenceService.changeEmailSequenceStatus(event.key, event.checked);
        this.toastrService.success(
          `Sequence: ${sequence.name} have been ${event.checked ? 'enabled' : 'disabled'} successfully`,
        );
        sequence.isEnabled = event.checked;
      } catch {
        this.toastrService.error(`Error in ${event.checked ? 'enabling' : 'disabling'} sequence: ${sequence.name}`);
      } finally {
        sequence.changingStatus = false;
      }
    }
  }

  removeEmailSequence(): void {
    console.log('remove email sequence');
  }

  createEmailSequence(): void {
    this.sequenceService.sendEmailSequenceUpsertedEvent({
      kind: 'CREATE_SEQUENCE',
      metadata: {
        platform: this.platform,
        campaignSlugName: this.campaignSlugName,
      },
    });
    this.closeModal();
  }

  editEmailSequence(sequenceId: string): void {
    this.sequenceService.sendEmailSequenceUpsertedEvent({
      kind: 'EDIT_SEQUENCE',
      metadata: {
        sequenceId: sequenceId,
        platform: this.platform,
        campaignSlugName: this.campaignSlugName,
      },
    });
    this.closeModal();
  }

  closeModal(): void {
    this.close.emit();
  }

  loadNextPage(): void {
    if (!this.hasMoreData || this.paginationLoader) {
      return;
    }
    this.paginationLoader = true;
    this.pageNumber++;
    this.getEmailSequences();
  }

  getEmailSequences(): void {
    this.pageNumber ? (this.paginationLoader = true) : (this.loading = true);
    this.getCampaignSequencesRequest?.unsubscribe();
    this.getCampaignSequencesRequest = this.sequenceService
      .getCampaignSequences(this.campaignSlugName, this.pageNumber, {
        excludeInfluencerIds: true,
        statusFilter: this.filterKey,
        searchTerm: this.searchTerm,
      })
      .subscribe({
        next: (response) => {
          this.emailSequences = this.pageNumber ? [...this.emailSequences, ...response.sequences] : response.sequences;
          this.hasMoreData = response.hasMoreSequences;
          this.loading = false;
          this.paginationLoader = false;
        },
        complete: () => {
          this.loading = false;
          this.paginationLoader = false;
        },
        error: (error) => {
          this.loading = false;
          this.paginationLoader = false;
          this.toastrService.error(`Unable to retrieve data. Please refresh and check later`);
          console.error(error);
        },
      });
  }

  searchEmailSequences(clearSearchTerm?: boolean): void {
    if (clearSearchTerm) {
      this.searchTerm = '';
    }
    this.pageNumber = 0;
    this.getEmailSequences();
  }

  filterEmailSequences(filterKey: EmailSequenceFilters): void {
    this.filterKey = filterKey;
    this.pageNumber = 0;
    this.getEmailSequences();
  }
}
