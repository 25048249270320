import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { InfluencerNewsAPIResponse, InfluencerNewsItem } from '../../interfaces';

@Injectable()
export class NewsService {
  constructor(private http: HttpClient) {}

  getInfluencerNews(username: string): Promise<InfluencerNewsItem[]> {
    return this.http
      .get(environment.api + `/api/users/news/${username}`)
      .toPromise()
      .then((json: InfluencerNewsAPIResponse) => {
        return json.influencerNewsItems;
      });
  }
}
