<div class="modal-header">
  <span>Create Campaign</span>
  <button class="close" aria-label="Close" (click)="closeModal()" [disabled]="isInProgress">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label>Campaign Name<sup class="danger">*</sup></label>
        <input data-cy="new-campaign-title" type="text" placeholder="Enter campaign name" [(ngModel)]="campaignName"
          class="input-group input-group-sm" />
      </div>
    </div>
  </div>
  <div class="row">
    <ngx-date-range [timeFilter]="date" class="col-12" [anyStartDate]="true" [isToDateClearable]="true">
    </ngx-date-range>
  </div>
  <!-- <div class="row">
    <label class="sub-heading-h1 col-sm-12">Select Platform <span class="required">*</span></label>
    <form [formGroup]="platforms" class="col-sm-12">
      <ai-checkbox class="platforms" [label]="platform" [formControlName]="platform" *ngFor="let platform of availablePlatforms"></ai-checkbox>
    </form>
  </div> -->
  <div class="mb-2" *ngIf="singlePlatformSelectionEnabled">
    <label class="af-sub-heading-h2  p-0">Select Platform <span class="required">*</span></label>
    <div class="d-flex" style="gap: 20px;">
      <div class="af-platform-radio-group" *ngFor="let account of availablePlatforms">
        <label class="af-radio-group" [ngClass]="!enabledPlatform.includes(account) ? 'disabled': null">{{account |
          titlecase}}
          <input [disabled]="!enabledPlatform.includes(account)" type="radio" [value]="account"
            [checked]="account === platform?.toLowerCase()" (change)="onPlatformSelectionChange($event.target.value)"
            name="radio">
          <span class="af-radio"></span>
        </label>
      </div>

    </div>
  </div>
  <div class="row" *ngIf="platform==='Instagram' || platform.toLowerCase()=== VeraPlatforms.instagram.toLowerCase()">
    <div class="col-12">
      <div class="form-group">
        <label>Mentions</label>
        <ngx-tooltip
          [tooltip]="'Mentions used by influencers in this campaign. These mentions will be used to track relevant influencer content for the campaign.'">
        </ngx-tooltip>
        <ng-select placeholder="Filter by brands" notFoundText="No brands found" bindValue="username"
          [items]="brands | async" [typeahead]="brandNameInput" [multiple]="true" [hideSelected]="true"
          [virtualScroll]="true" [loading]="loadingBrands" [markFirst]="false" [(ngModel)]="campaignMentions">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label"><b> {{item.fullName}}</b> @{{item.username}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search" class="option-label">{{item.fullName}}</span><br>
            <span [ngOptionHighlight]="search" class="option-sublabel">@{{item.username}}</span>
          </ng-template>
        </ng-select>
        <div class="footnote"> Note: Filter by brands </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label>Hashtags</label>
        <ngx-tooltip
          [tooltip]="'Hashtags used by the influencers in this campaign. These hashtags will be used to track relevant influencer content for the campaign.'">
        </ngx-tooltip>
        <ng-select #hashtagSelect placeholder="#dyson" addTag="true" addTagText="Search For:" [items]="hashtags"
          [addTag]="convertToHashtag" [selectableGroup]="true" [multiple]="true" [markFirst]="false"
          [clearOnBackspace]="false" [(ngModel)]="hashtags" [isOpen]="false" bindLabel="value"
          (keydown)="hashtagKeyDown($event)">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value chip" *ngFor="let item of items" (dblclick)="handleTagClicked(item)">
              <div *ngIf="item">
                <div *ngIf="!item.editable">
                  <span class="ng-value-label">{{item.label}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
                <div *ngIf="item.editable" class="actions">
                  <input type="text" class="form-control edit-tag" [ngModel]="item.value"
                    (keydown)="saveEditTagOnEnterKey($event, item, tagInput )"
                    (clickOutside)="handleSaveEditTagOnBlur(tagInput,item)" [autofocus] #tagInput>
                  <button title="Save" (click)="handleSaveEditTag(tagInput, item)"><i class="fa fa-check"></i></button>
                  <button title="Cancel" (click)="handleCloseEditTag(tagInput, item)"><i
                      class="fa fa-close"></i></button>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-select>
        <div class="footnote">Note: Hit "Enter/Return" key to add a new hashtag </div>
      </div>
    </div>
  </div>
  <div *ngIf="isPartnerizeEnabled" class="row">
    <div class="col-12">
      <div class="form-group">
        <label>Partnerize Campaign</label>
        <ngx-tooltip [tooltip]="'Select Partnerize'"></ngx-tooltip>
        <ng-select placeholder="Select Partnerize" bindValue="campaignId" dropdownPosition="bottom"
          addTagText="Search For:" addTag="true" [items]="partnerizeCampaigns" [multiple]="false" [hideSelected]="true"
          [markFirst]="false" [loading]="loadingPartnerizeCampaigns" [virtualScroll]="true"
          [(ngModel)]="partnerizeCampaignId">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label">{{item.title}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search" class="option-label">{{item.title}}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="form-status">
    <div class='error-message' @fadeInOut *ngIf="errorMessage"> {{errorMessage}} <label>{{lastInvalidHashtag}}</label>
    </div>
    <ngx-loader class="modal-loader" *ngIf="creatingCampaign || addingInfluencers"></ngx-loader>
    <div *ngIf="addingInfluencers" class="center-text">Adding Influencers...</div>
  </div>

</div>
<div class="modal-footer">
  <button class="secondary-text" (click)="closeModal()" [disabled]="isInProgress">CANCEL</button>
  <button class="primary" data-cy="create-campaign" (click)="createCampaign()"
    [disabled]="isCreateCampaignDisabled">CREATE CAMPAIGN</button>
</div>