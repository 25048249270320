import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import { BRANDED_CONTENT_SERVICE } from '../../../enums';
import { BrandedContentService } from '../../../interfaces';
import { PrintService } from '../../print/print-layout/print.service';
import { InstagramProfileService } from '../../../@core/data/instagram/instagram-profile.service';
import { VeraPlatforms } from '../../../enums';

@Component({
  selector: 'ngx-branded-content-distribution-card',
  templateUrl: './branded-content-distribution-card.component.html',
  styleUrls: ['./branded-content-distribution-card.component.scss'],
})
export class BrandedContentDistributionCardComponent implements OnInit {
  /* echart exclusive variables */
  private variables: any;
  private visitorsPieLegend: any;
  @Input() printable: boolean;
  @Input() platform: VeraPlatforms;
  @Output() isReady = new EventEmitter<boolean>();
  chartLegend: { iconColor: string; title: string }[];
  echartsInstance: any;
  /* echart exclusive variables */

  influencer: string;
  brandedContentOptions: any;
  brandContentDays: number;
  brandedContentPercentage: number;
  totalContents: number;

  filterOptions: BrandedContentFilterOption[] = [
    { label: 'Last Week', value: 7 },
    { label: 'Last Month', value: 30 },
    { label: 'Last 3 Months', value: 90 },
  ];
  filterKey: BrandedContentFilterOption = this.filterOptions[2];

  constructor(
    private route: ActivatedRoute,
    @Inject(BRANDED_CONTENT_SERVICE)
    private brandedContentService: BrandedContentService,
    private theme: NbThemeService,
    private printService: PrintService,
    private instagramProfileService: InstagramProfileService,
  ) {}

  ngOnInit(): void {
    this.theme.getJsTheme().subscribe((config) => {
      this.variables = config.variables;
      this.visitorsPieLegend = config.variables.visitorsPieLegend;
      this.updateBrandedContentStats(90);
    });
  }

  updateBrandedContentStats(days: number) {
    this.brandContentDays = days;
    this.brandedContentOptions = undefined;

    const username = this.route.snapshot.paramMap.get('username');
    this.influencer = username;
    if (username && this.platform === VeraPlatforms.instagram) {
      this.instagramProfileService.getInfluencerBrandedContentDist(username, days).then((brandedContentStats) => {
        this.setBrandContentStats(brandedContentStats);
      });
    } else {
      this.brandedContentService.getInfluencerBrandedContentStats(username, days).then((brandedContentStats) => {
        this.setBrandContentStats(brandedContentStats);
      });
    }
  }

  setBrandContentStats(brandedContentStats): void {
    if (brandedContentStats && brandedContentStats.branded != null && brandedContentStats.nonBranded != null) {
      this.isReady.emit(true);
      this.totalContents = brandedContentStats.total;
      this.brandedContentPercentage = Math.round(brandedContentStats.branded * 100);
      this.setOptions();
      this.setLegendItems();
      if (this.printService.isPrinting) {
        this.printService.chartsFullyLoaded.next(true);
      }
    } else {
      this.isReady.emit(false);
      this.brandedContentOptions = {
        series: [],
      };
    }
  }
  /* echart exclusive functions */

  setLegendItems(): void {
    this.chartLegend = [
      {
        iconColor: this.visitorsPieLegend.firstSection,
        title: 'Branded Content',
      },
      {
        iconColor: this.visitorsPieLegend.secondSection,
        title: 'Non Branded Content',
      },
    ];
  }

  setOptions(): void {
    const visitorsPie: any = this.variables.visitorsPie;

    this.brandedContentOptions = {
      tooltip: {
        trigger: 'item',
        formatter: '',
      },
      series: [
        {
          name: ' ',
          clockWise: true,
          hoverAnimation: false,
          type: 'pie',
          center: ['50%', '50%'],
          radius: visitorsPie.firstPieRadius,
          data: [
            {
              value: 100 - this.brandedContentPercentage,
              name: ' ',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                  textStyle: {
                    fontSize: '22',
                    fontFamily: this.variables.fontSecondary,
                    fontWeight: '600',
                    color: this.variables.fgHeading,
                  },
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: visitorsPie.firstPieGradientLeft,
                    },
                    {
                      offset: 1,
                      color: visitorsPie.firstPieGradientRight,
                    },
                  ]),
                  shadowColor: visitorsPie.firstPieShadowColor,
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowOffsetY: 3,
                },
              },
              hoverAnimation: false,
            },
            {
              value: this.brandedContentPercentage,
              name: ' ',
              tooltip: {
                show: false,
              },
              label: {
                normal: {
                  position: 'inner',
                },
              },
              itemStyle: {
                normal: {
                  color: this.variables.layoutBg,
                },
              },
            },
          ],
        },
        {
          name: ' ',
          clockWise: true,
          hoverAnimation: false,
          type: 'pie',
          center: ['50%', '50%'],
          radius: visitorsPie.secondPieRadius,
          data: [
            {
              value: 100 - this.brandedContentPercentage,
              name: ' ',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                  textStyle: {
                    fontSize: '22',
                    fontFamily: this.variables.fontSecondary,
                    fontWeight: '600',
                    color: this.variables.fgHeading,
                  },
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1),
                },
              },
              hoverAnimation: false,
            },
            {
              value: this.brandedContentPercentage,
              name: ' ',
              tooltip: {
                show: false,
              },
              label: {
                normal: {
                  position: 'inner',
                },
              },
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: visitorsPie.secondPieGradientLeft,
                    },
                    {
                      offset: 1,
                      color: visitorsPie.secondPieGradientRight,
                    },
                  ]),
                  shadowColor: visitorsPie.secondPieShadowColor,
                  shadowBlur: 0,
                  shadowOffsetX: visitorsPie.shadowOffsetX,
                  shadowOffsetY: visitorsPie.shadowOffsetY,
                },
              },
            },
          ],
        },
      ],
    };
  }

  onChartInit(echarts) {
    this.echartsInstance = echarts;
  }

  /* echart exclusive functions */
}

export interface BrandedContentFilterOption {
  label: string;
  value: number;
}
