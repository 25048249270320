import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as moment from 'moment';
import { AffiliateEventType } from '../../enums';
import { AffiliateSummaryAggregations } from '../../interfaces';
import { DatePicker } from '../date-range/date-range.component';

@Component({
  selector: 'ngx-echarts-date-stat',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss'],
})
export class EchartsDateStatComponent implements OnInit, OnChanges {
  @Input() width? = '100%';
  @Input() height? = '230px';
  filterOption: { frequency: string; frequencyBy: string };
  @Input()
  set filterBy(option: string) {
    switch (option) {
      case 'Daily':
        this.filterOption = { frequency: 'Daily', frequencyBy: 'Days' };
        break;
      case 'Weekly':
        this.filterOption = { frequency: 'Weekly', frequencyBy: 'Weeks' };
        break;
      case 'Monthly':
        this.filterOption = { frequency: 'Monthly', frequencyBy: 'Months' };
        break;
    }
  }
  @Input() data: AffiliateSummaryAggregations;
  @Input() dateFilter: DatePicker;
  @Input() affiliateEventType: AffiliateEventType;
  chartOption: EChartsOption;

  async ngOnChanges(): Promise<void> {
    this.setChartOptions();
  }

  ngOnInit(): void {
    this.setChartOptions();
  }

  setChartOptions(): void {
    const { xAxisData, values, maxYAxisValue, intervalCeiled, xAxisName, yAxisName, title, subTitle } =
      this.getGraphData();
    const commonAxisParams = {
      axisTick: { show: false },
      splitLine: { show: true, lineStyle: { color: '#eeeeee' } },
      nameTextStyle: { color: '#777777', fontSize: 11 },
    };

    this.chartOption = {
      textStyle: { color: '#CCCCCC', fontFamily: 'Open Sans', fontSize: 10 },
      grid: { left: 70, height: '40%', bottom: 60, right: 20 },
      xAxis: {
        ...commonAxisParams,
        nameLocation: 'middle',
        name: xAxisName,
        nameGap: 30,
        axisLine: { show: true, lineStyle: { color: '#8e8e8e', width: 0.5, type: 'solid', opacity: 0.8 } },
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        ...commonAxisParams,
        axisLine: { show: true, lineStyle: { color: '#8e8e8e', width: 0.5, type: 'solid', opacity: 0.8 } },
        nameRotate: 90,
        nameGap: 40,
        max: maxYAxisValue,
        nameLocation: 'middle',
        name: yAxisName,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => {
            if (value >= 1000000) return value / 1000000 + 'M';
            if (value >= 1000) return value / 1000 + 'K';
            return value.toString();
          },
        },
        interval: intervalCeiled,
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        position: 'top',
        transitionDuration: 0.2,
        textStyle: { align: 'left', fontSize: 12 },
        formatter: (params) => this.getToopTip(params),
      },
      series: [
        {
          data: values,
          type: 'line',
        },
      ],
      title: { text: title, textStyle: { fontSize: 16 }, subtext: subTitle, padding: [15, 0, 0, 25], itemGap: 4 },
    };
  }

  getGraphData(): {
    maxYAxisValue: number;
    yAxisName: string;
    subTitle: string;
    xAxisName: string;
    values: number[];
    intervalCeiled: number;
    title: string;
    xAxisData: string[];
  } {
    const values: number[] = this.getValuesFromGraphData();
    const xAxisData = this.data.data.map((d) => d.label);
    const maxValue = Math.max(...values);
    const interval = maxValue / 3;
    const intervalDigit = Math.ceil(interval).toString().length;
    const intervalCeiled = (+interval.toString()[0] + 1) * Math.pow(10, intervalDigit - 1);
    const maxYAxisValue = intervalCeiled * 3;
    const xAxisName = `${this.filterOption.frequencyBy} - Year ${this.dateFilter.to.getFullYear()}`;
    const yAxisName = `${this.filterOption.frequency} ${this.affiliateEventType} ${
      this.valueUnit ? '-In ' + this.valueUnit : ''
    }`;
    const title = `Total ${this.capitalizeFirstLetter(this.affiliateEventType)} - ${this.total}`;
    const subTitle = `${this.getNumberOfDays(
      this.dateFilter.from,
      this.dateFilter.to,
    )} Days (${this.getFormattedDateRange(this.dateFilter)})`;
    return { xAxisData, values, maxYAxisValue, intervalCeiled, xAxisName, yAxisName, title, subTitle };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getToopTip(params): string {
    const toolTip = this.data.data[params['dataIndex']].tooltip;
    if (this.filterOption.frequency === 'Weekly') {
      const range = toolTip.split('-');
      return `${range[0]} - <br> ${range[1]}<br> <b>${this.capitalizeFirstLetter(this.affiliateEventType)} : ${
        this.valueUnit
      } ${params['value']}</b>`;
    }
    return `${toolTip}<br> <b>${this.capitalizeFirstLetter(this.affiliateEventType)} : ${this.valueUnit} ${params[
      'value'
    ].toLocaleString()}</b>`;
  }

  getValuesFromGraphData(): number[] {
    switch (this.affiliateEventType) {
      case 'revenue':
        return this.data.data.map((d) => d.totalRevenue);
      case 'orders':
        return this.data.data.map((d) => d.ordersCount);
      case 'paid':
      case 'commission':
      case 'pending':
        return this.data.data.map((d) => d.commission);
    }
  }

  get total(): string {
    let sum: number;
    switch (this.affiliateEventType) {
      case 'revenue':
        sum = this.data.data.reduce((p, c) => p + c.totalRevenue, 0);
        break;
      case 'orders':
        sum = this.data.data.reduce((p, c) => p + c.ordersCount, 0);
        break;
      case 'paid':
      case 'commission':
      case 'pending':
        sum = this.data.data.reduce((p, c) => p + c.commission, 0);
        break;
    }
    return this.valueUnit + ' ' + sum.toLocaleString();
  }

  getFormattedDateRange(datePicker: DatePicker): string {
    const { from, to } = datePicker;
    const monthFrom = moment(from).format('MMM D, YYYY');
    const monthTo = moment(to).format('MMM D, YYYY');
    return `${monthFrom} to ${monthTo}`;
  }

  getNumberOfDays(start: Date, end: Date): number {
    const diffInDays = moment(end).diff(start, 'days') + 1;
    return diffInDays;
  }

  get valueUnit(): string {
    return this.affiliateEventType === 'orders' ? '' : this.data.currency;
  }

  capitalizeFirstLetter(text: string): string {
    return text[0].toUpperCase() + text.slice(1);
  }
}
