import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MediaReportData } from '../../interfaces';

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  getMediaReport(mediaPk: string): Promise<MediaReportData> {
    return this.http.get(`${environment.api}/api/users/stats/media/${mediaPk}`).toPromise() as Promise<MediaReportData>;
  }
}
