import { StandardDropdownChoice } from './../../interfaces/shared.interface';
import {
  TerminationConditions,
  Days,
  TIMEZONES,
  DayDeliveryOptions,
  WorkflowBasedTerminationConditions,
} from './../../utils/constants';
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { every, get, keys, last, orderBy, set, sum, chunk, sample, sampleSize, shuffle } from 'lodash';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { ClientUser, TimeZone } from '../../interfaces';
import { Platforms, PlatformsUpperEnum, PlatformsV2, VeraPlatforms } from '../../../app/enums';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

const TIKTOK_ALTERNATE_CDN_HOST = 'https://p16-va.tiktokcdn.com';
const TIKTOK_PROFILE_PIC_CACHE_HOST = 'https://affable-influencer-profile-picture.s3.ap-southeast-1.amazonaws.com';

// source https://github.com/auth0/lock/blob/4aaefa4a9ed00423e665e0743a7b0a952c6a267d/src/field/email.js#L7
export const AUTH0_EMAIL_REG_EXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Utils {
  normaliseString(text: string): string {
    // from: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  replaceNonBreakByBreakSpaces(text: string) {
    return (text || '').split('\u00a0').join(' ');
  }

  getHtmlfromPlainText(plainText: string): string {
    return '<p>' + plainText.replace(/\n/g, '<br>') + '</p>';
  }

  getTextFromHTML(html: string): string {
    html = (html || '')
      .replace(/<\s*[/]?>|<[/]?p>/gi, ' ')
      .trim()
      .replace(/<br\s*[/]?>|<[/]?>/gi, '\n');
    return html;
  }

  private decodeEntities(encodedString) {
    const translate_re = /&(nbsp|amp|quot|lt|gt|#\d+);/g;
    const translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
    };
    return encodedString.replace(translate_re, function (match, entity) {
      // If the entity is numeric, decode it to the corresponding character
      if (entity.charAt(0) === '#') {
        const sign = String.fromCharCode(parseInt(entity.substr(1), 10));
        return sign;
      } else {
        // Otherwise, use the predefined translations
        const translated = translate[entity];
        return translated;
      }
    });
  }

  // removes all html tags from string and decodes special characters
  decodeAndRemoveHtmlTags(html: string): string {
    if (!html) return '';
    try {
      let htmlToClean = html;

      // Remove <script> and <style> content
      htmlToClean = htmlToClean.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, '');
      htmlToClean = htmlToClean.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');

      // Remove all other HTML tags
      htmlToClean = htmlToClean.replace(/<\/?[^>]+(>|$)/g, '');

      htmlToClean = this.decodeEntities(html);

      // Normalize whitespace
      htmlToClean = htmlToClean.replace(/\s\s+/g, ' ').trim();

      return htmlToClean;
    } catch (error) {
      return html;
    }
  }

  getUsernameFromInstagramUrlIfPresent(url: string) {
    if (url === undefined || url === null || url === '') {
      return url;
    }
    const parsedData = url.match(/(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Z0-9-_.]+)/im);
    if (parsedData !== null) {
      return parsedData[1];
    }
    return url;
  }

  loadExternalScript(scriptUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = () => resolve(true);
      document.body.appendChild(scriptElement);
    });
  }

  buildAutocompleteObservable<S, T>(
    input: Subject<S>,
    lookupFunction: (S) => Observable<T[]>,
    loadingCallback: () => void,
    doneLoadingCallback: () => void,
    defaultList: T[] = [],
  ): Observable<T[]> {
    return concat(
      of(defaultList),
      input.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => loadingCallback()),
        switchMap((term) =>
          lookupFunction(term).pipe(
            catchError(() => of([])),
            tap(() => doneLoadingCallback()),
          ),
        ),
      ),
    );
  }

  getDomain(): string {
    return window.location.protocol + '//' + window.location.host + '/';
  }

  averageByWeek<T>(arr: T[], n: number, groupingKey: string, fieldsToAggregate: string[]): T[] {
    // TODO: sort first
    if (!arr || !n) {
      return [];
    }
    const groups = [];
    const dataKeys = keys(arr[0]);
    while (arr?.length) {
      groups.push(arr.splice(0, n));
    }

    return groups
      .map((group) => orderBy(group, groupingKey, ['desc']))
      .map((group) =>
        group.reduce((total, obj) => {
          const temp = {};
          dataKeys.forEach((key) => {
            temp[key] = fieldsToAggregate.includes(key) ? +total[key] + +obj[key] : total[key];
          });
          return temp;
        }),
      )
      .map((g) => {
        dataKeys.forEach((key) => {
          g[key] = fieldsToAggregate.includes(key) ? g[key] / n : g[key];
        });
        return g;
      });
  }

  formatUsernames(usernames: string[]): string[] {
    return usernames.map((username) => this.formatUsername(username)).filter((username) => !!username);
  }

  formatUsername(username: string): string {
    username = username.split('?')[0];
    if (username.includes('instagram.com')) {
      return username.split('instagram.com/')[1]?.split('/')[0].replace(/["]/g, '').trim();
    } else if (username.includes('facebook.com')) {
      return username.split('facebook.com/')[1]?.split('/')[0].replace(/["]/g, '').trim();
    } else if (username.includes('tiktok.com/@')) {
      return username.split('tiktok.com/@')[1]?.split('/')[0].replace(/["]/g, '').trim();
    } else if (username.includes('youtube.com')) {
      if (username.includes('youtube.com/c/')) {
        return username.split('youtube.com/')[1];
      }
      return last(username.split('youtube.com/')[1]?.split('/')).trim();
    } else if (username.includes('twitter.com')) {
      return username.split('twitter.com/')[1]?.split('/')[0].replace(/["]/g, '').trim();
    } else {
      return username.replace(/[@"]/g, '');
    }
  }

  slugify(name: string): string {
    return name
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  validateHashtagString(hashtagsString: string): boolean {
    if (!hashtagsString) {
      return false;
    }
    const hashtags = hashtagsString.trim().split('\n');
    let status = true;
    // const regExpr = /[#\p{L}\d_]/ug; This does not matches the foreign language special chars
    const regExpr = /[!@$%^&*()+\-=[\]{};':"\\|,.<>/?]/;
    hashtags.forEach((hashtag) => {
      status = status && !regExpr.test(hashtag);
    });
    return status;
  }

  isBucketsEmpty(buckets) {
    if (buckets && buckets?.length > 0) {
      // returns true if count of each bucket is 0
      // eg [{label: male, count: 0}, {label: female, count:0}]
      return every(buckets, ['count', 0]);
    }
    return true;
  }

  isAgeGenderBucketsEmpty(ageGenderBuckets) {
    if (ageGenderBuckets && ageGenderBuckets?.length > 0) {
      return every(ageGenderBuckets, ['femaleCount', 0]) && every(ageGenderBuckets, ['maleCount', 0]);
    }
    return true;
  }

  toPercentagePoints(buckets): number[] {
    const total = sum(buckets.map((bucket) => bucket.count));
    return buckets.map((b) => +((b.count / total) * 100).toFixed(2));
  }

  toPercentagePointsByGender(ageGenderBuckets, gender): number[] {
    const total = sum(ageGenderBuckets.map((bucket) => bucket.maleCount + bucket.femaleCount));
    return ageGenderBuckets.map((b) => +(((gender === 'male' ? b.maleCount : b.femaleCount) / total) * 100));
  }

  /**
   * Checks for unreachable url and replaces its host
   * (hack)
   *
   * @param items
   * @param path - Path to the url field
   */
  async replaceBadTiktokMediaUrls<T>(items: T[], path): Promise<T[]> {
    if (!(items && items?.length)) {
      return items;
    }

    const resultItems: T[] = [];

    // split into chunks to reduce usage on browser because we're using promise.all
    const SIZE_OF_CHUNK = 20;
    const itemsChunks = chunk(items, SIZE_OF_CHUNK);

    for (const itemsChunk of itemsChunks) {
      const promises = itemsChunk.map(async (item) => {
        const srcUrl: string = get(item, path);

        if (
          !srcUrl ||
          srcUrl.startsWith(TIKTOK_ALTERNATE_CDN_HOST) ||
          srcUrl.startsWith(TIKTOK_PROFILE_PIC_CACHE_HOST)
        ) {
          return item;
        }

        const res = await fetch(srcUrl, { method: 'HEAD' }).catch(() => null);
        if (res && res.ok) {
          return item;
        }

        const newUrl = this.changeTiktokMediaHost(srcUrl as string);
        set(item as any, path, newUrl);
        return item;
      });

      resultItems.push(...(await Promise.all(promises).catch(() => itemsChunk)));
    }

    return resultItems;
  }

  changeTiktokMediaHost(url: string): string {
    if (!url || url.startsWith(TIKTOK_PROFILE_PIC_CACHE_HOST)) {
      return url;
    }

    return TIKTOK_ALTERNATE_CDN_HOST + url.slice(url.search(/[^/][/][^/]/) + 1); // Find first occurance of single '/'
  }

  getInstagramCDNUrl(url: string): string {
    // replace environment.api >> http://api.staging.affable.ai to view PDF images !
    return environment.api + '/cdn/instagram/profile/image?url=' + encodeURIComponent(url);
  }

  getCombinations(...arrayOfArray: unknown[][]): unknown[][] {
    /*

    Input format: [[1,2,3], [4,5,6]] (and so on)
    Output format: [[1,4], [1,5], [1,6], [2,4]....] (and so on)

    */
    const combinations = [];
    const max = arrayOfArray.length - 1;
    function helper(arr, i) {
      // i -> index of outer array (will traverse through array like [1,2,3], [], ...)
      // j -> index of inner array (will traverse through elements like 1, 2, 3...)
      for (let j = 0, l = arrayOfArray[i].length; j < l; j++) {
        const a = arr.slice(0); // clone arr
        a.push(arrayOfArray[i][j]);
        if (i === max) combinations.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return combinations;
  }

  getUTCDateRanges(from: Date, to: Date): { fromUTC: string; toUTCEndOfDay: string } {
    const fromUTC = Date.UTC(from.getUTCFullYear(), from.getUTCMonth(), from.getUTCDate()).toString();
    const toUTC = Date.UTC(to.getUTCFullYear(), to.getUTCMonth(), to.getUTCDate());
    const toUTCEndOfDay = moment(toUTC).utc().endOf('day').valueOf().toString();
    return { fromUTC, toUTCEndOfDay };
  }

  getCSVBlob(res): Blob {
    return new Blob(['\ufeff', res], { type: 'text/csv;charset=utf-8' });
  }

  isEmbeddable(url: string): string | null {
    const { origin, search, pathname } = new URL(url);

    if (origin.includes('youtube.com')) {
      let youtubeId = '';
      for (let i = 3; i < search.length; i++) {
        if (search[i] === '&') {
          break;
        }
        youtubeId = youtubeId + search[i];
      }
      if (pathname.includes('shorts')) {
        youtubeId = pathname.split('/')[2];
      }

      return `https://www.youtube.com/embed/${youtubeId}?ecver=2`;
    } else if (origin.includes('youtu.be')) {
      let youtubeId = '';
      for (let i = 1; i < pathname.length; i++) {
        if (pathname[i] === '?') {
          break;
        }
        youtubeId = youtubeId + pathname[i];
      }
      return `https://www.youtube.com/embed/${youtubeId}?ecver=2`;
    } else if (origin.includes('vimeo.com')) {
      let vimeoId = '';
      for (let i = 1; i < pathname.length; i++) {
        if (pathname[i] === '?') {
          break;
        }
        vimeoId = vimeoId + pathname[i];
      }
      return `https://player.vimeo.com/video/${vimeoId}?h=1b3e8a0eed`;
    } else if (origin.includes('drive.google.com') && !pathname.includes('/drive/folders')) {
      const googleDriveId = url.split('/');
      googleDriveId.pop();
      return googleDriveId.join('/') + '/preview';
    }
    return null;
  }

  getCurrentTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getTerminationConditionsAsOptions(campaignWorkflowConfigured = false): StandardDropdownChoice[] {
    const mappedTerminationOptions: StandardDropdownChoice[] = [];
    let indexOfNever = -1;

    for (const n in TerminationConditions) {
      if (TerminationConditions[n] === TerminationConditions.NEVER) {
        indexOfNever = mappedTerminationOptions.length;
      }
      mappedTerminationOptions.push({ label: <string>TerminationConditions[n], value: n });
    }

    if (campaignWorkflowConfigured) {
      for (const n in WorkflowBasedTerminationConditions) {
        mappedTerminationOptions.push({ label: <string>WorkflowBasedTerminationConditions[n], value: n });
      }
    }
    if (indexOfNever !== -1 && indexOfNever !== mappedTerminationOptions?.length - 1) {
      const conidtionToPlaceLast = mappedTerminationOptions.splice(indexOfNever, 1)[0];
      mappedTerminationOptions.push(conidtionToPlaceLast);
    }
    return mappedTerminationOptions;
  }

  getDaysListFromDeliveryDays(selectedOption: DayDeliveryOptions): string[] {
    const mappedDeliveryDays: string[] = [];
    const daysList = Object.values(Days) as string[];
    for (let n = 0; n < 7; n++) {
      if (typeof daysList[n] === 'string') {
        if (
          selectedOption === DayDeliveryOptions.ANY ||
          (selectedOption === DayDeliveryOptions.WEEKDAYS && +n < 5) ||
          (selectedOption === DayDeliveryOptions.WEEKENDS && +n > 4)
        ) {
          mappedDeliveryDays.push(daysList[n]);
        }
      }
    }
    return mappedDeliveryDays;
  }

  getDayDeliveryOptionsFromDays(dayList: string[]): DayDeliveryOptions {
    let weekdaysCount = 0;
    let weekendsCount = 0;

    for (const day of dayList) {
      switch (day) {
        case Days.MONDAY:
        case Days.TUESDAY:
        case Days.WEDNESDAY:
        case Days.THURSDAY:
        case Days.FRIDAY:
          weekdaysCount++;
          break;
        case Days.SATURDAY:
        case Days.SUNDAY:
          weekendsCount++;
          break;
        default:
          break;
      }
    }
    if (weekdaysCount + weekendsCount === 7) {
      return DayDeliveryOptions.ANY;
    } else if (weekdaysCount === 5) {
      return DayDeliveryOptions.WEEKDAYS;
    } else if (weekendsCount === 2) {
      return DayDeliveryOptions.WEEKENDS;
    } else {
      return DayDeliveryOptions.ANY;
    }
  }

  trackContent = (index: number): number => index;

  getInfluencerUsername(username: string): string {
    if (!username?.trim()) {
      return '';
    }

    try {
      return new URL(username.trim()).pathname.split('/')[1];
    } catch {
      return username.trim();
    }
  }

  validateUrl(str: string): URL | false {
    try {
      const url = new URL(str);
      return url;
    } catch (err) {
      return false;
    }
  }

  getTimezonesListWithLabel(): TimeZone[] {
    return TIMEZONES.map((tz) => {
      return { ...tz, label: `${tz.key} (${tz.title})` };
    });
  }

  getHourStringFromNumber(num: number) {
    return num < 10 ? `0${num}:00` : `${num}:00`;
  }

  convertNumberToTwelveHourFormat(num: number) {
    if (num < 0 || num > 23) {
      num = 0;
    }

    let suffix = 'AM';
    let hour = num;

    if (num >= 12) {
      suffix = 'PM';
      hour = num === 12 ? 12 : num - 12;
    } else if (num === 0) {
      hour = 12;
    }
    const hourString = hour < 10 ? `0${hour}` : `${hour}`;

    return `${hourString} ${suffix}`;
  }

  getFromAndToNumberFromHoursString(
    fromHourString: string,
    toHourString: string,
    minimumGap = 2,
  ): { fromTime: number; toTime: number } {
    const regex = /^(\d{1,2}):00$/;
    const fromMatch = fromHourString.match(regex);
    const toMatch = toHourString.match(regex);

    let fromTime = 0;
    let toTime = minimumGap;
    let parsedHour = fromTime;
    if (fromMatch) {
      parsedHour = parseInt(fromMatch[1], 10);
      if (parsedHour >= 0 && parsedHour <= 24) {
        fromTime = parsedHour;
      }
    }
    if (toMatch) {
      parsedHour = parseInt(toMatch[1], 10);
      if (parsedHour >= 0 && parsedHour <= 24 && parsedHour >= fromTime + minimumGap) {
        toTime = parsedHour;
      } else {
        toTime = fromTime + minimumGap;
      }
    }

    return {
      fromTime,
      toTime,
    };
  }

  // Intended to make sure switch case is exhaustive
  never(): never {
    throw new Error('Function not implemented.');
  }

  getInfluencerProfileUrl(platform, username): string {
    switch (platform?.toLowerCase()) {
      case Platforms.instagram.toLowerCase():
        return '/pages/profile/' + username;
      case VeraPlatforms.instagram.toLowerCase():
        return '/pages/veraInstagram/profile/' + username;
      default:
        return `/pages/${platform?.toLowerCase()}/profile/${username}`;
    }
  }

  getExternalProfileBaseUrl(platform): string {
    switch (platform?.toLowerCase()) {
      case Platforms.instagram.toLowerCase():
      case VeraPlatforms.instagram.toLowerCase():
        return 'https://www.instagram.com/';
      case Platforms.facebook.toLowerCase():
        return 'https://www.facebook.com/';
      case Platforms.youtube.toLowerCase():
        // channel id is the definitive link to the youtube profile
        return 'https://www.youtube.com/channel/';
      case Platforms.tiktok.toLowerCase():
        return 'https://www.tiktok.com/@';
      case Platforms.twitter.toLowerCase():
        return 'https://www.twitter.com/';
    }
  }

  hasIncorrectTemplateKeywords(message: string, allowedKeywords: string[]) {
    const keyPattern = /\{\{([\w-]+(?: [^\s}]+)?)\}\}/g;
    const matches = (message ?? '').match(keyPattern);
    const usedKeywords = matches ? matches.map((match) => match.slice(2, -2)) : [];
    return usedKeywords.filter((keyword) => !allowedKeywords.includes(keyword));
  }

  findMatchedPlatform(platform: string): PlatformsV2 {
    return VeraPlatforms[platform.toLowerCase()] ?? Platforms[platform.toLowerCase()];
  }

  convertToPlatformV2(platform: PlatformsUpperEnum): PlatformsV2 {
    return [PlatformsUpperEnum.INSTAGRAM, PlatformsUpperEnum.VERAINSTAGRAM].includes(platform)
      ? VeraPlatforms.instagram
      : this.findMatchedPlatform(platform);
  }

  getVerificationUrl = (
    platforms: { mandatory: boolean; enabled: boolean; platform: Platforms }[],
    token?: string,
  ): string => {
    let url = `${environment.avenueAppURL}/embedded?origin=affable.ai`;
    const mandatoryPlatforms = platforms
      ?.filter((e) => e.mandatory && e.enabled)
      .map((e) => e.platform)
      .join(',');
    const optionalPlatforms = platforms
      ?.filter((e) => e.enabled && !e.mandatory)
      .map((e) => e.platform)
      .join(',');

    if (mandatoryPlatforms) {
      url += `&mandatory=${mandatoryPlatforms}`;
    }

    if (optionalPlatforms) {
      url += `&optional=${optionalPlatforms}`;
    }

    if (token) {
      url += `&token=${token}`;
    }
    return url;
  };

  isNullUndefinedOrNaN = (value: any): boolean => {
    return value === null || value === undefined || Number.isNaN(value);
  };

  formatValueToFixedDecimal(val: any, decimalPlace = 1): string {
    return this.isNullUndefinedOrNaN(val) ? '-' : val.toFixed(decimalPlace);
  }

  get emailRegex(): RegExp {
    return /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  }
  // email input validator function

  emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    // Only validate if there is a value
    if (!(control.value as string)?.length) {
      return null;
    }

    // Regular expression for email validation
    const isValid = Validators.email(control) == null && utils.emailRegex.test(control.value);

    return isValid ? null : { email: true };
  };

  isEmailValidAndNotEmpty(email: string | undefined): boolean {
    return !!email?.length && this.emailRegex.test(email);
  }

  sanitzeURIQueryParam(queryParam: string | any): string | undefined {
    const val = decodeURIComponent(queryParam);
    if (!val || val === 'undefined' || val === 'null') return undefined;
    return val;
  }

  getInfluencerLocations(countries: string[], numberOfLocations: number): string {
    countries = countries.filter((country) => !!country);
    return (
      countries
        // * Get the first (numberOfLocations) countries
        .slice(0, numberOfLocations)
        .map((country) => {
          // * If the country name consists of multi words, then take the first letter of each word as a short form
          return country.includes(' ')
            ? country
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase())
                .join('')
            : country;
        })
        // * Show '---' as a placeholder if countries is not available
        .join(', ') || '---'
    );
  }

  getAccountIdFromClientUser(user: ClientUser): string {
    return user.account?.id ?? user?.id;
  }
  generatePassword() {
    const minLength = 10;
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numericChars = '0123456789';
    const specialChars = '!@#$%^&*';
    const allChars = lowerCaseChars + upperCaseChars + numericChars + specialChars;
    const requiredChars = [sample(lowerCaseChars), sample(upperCaseChars), sample(numericChars), sample(specialChars)];
    let password = sampleSize(allChars, minLength - 4).join('');
    password += requiredChars.join('');
    return shuffle(password.split('')).join('');
  }
}

const utils = new Utils();
export default utils;
