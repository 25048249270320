<div class="group-stats-card" *ngIf="statsLoading || totalInfluencers">
  <ng-container>
    <ngx-loading-box [loading]="statsLoading">
      <div class="group-stats-card-title">Total Influencers</div>
      <div class="group-stats-card-value">{{ totalInfluencers || 0 }}</div>
    </ngx-loading-box>

    <ngx-loading-box [loading]="statsLoading">
      <div class="group-stats-card-title">Average Engagement</div>
      <div class="group-stats-card-value">{{ (averageEngagement || 0) | percent: '1.0-2'}}</div>
    </ngx-loading-box>

    <ngx-loading-box [loading]="statsLoading">
      <div class="group-stats-card-title">Total Followers</div>
      <div class="group-stats-card-value">{{ (totalFollowers || 0) | formatNumber:1}}</div>
    </ngx-loading-box>

    <ngx-loading-box [loading]="!groupInfo" *ngIf="isAudienceOverlapEnabled && !isCollaborationPortal">
      <div class="group-stats-card-title">Total Unique Followers</div>
      <div class="group-stats-card-value" [ngSwitch]="groupInfo?.statistics?.status || 'invalid'">
        <div *ngSwitchCase="'invalid'">
          <ai-tooltip [disabled]="totalInfluencers >= 2 && totalInfluencers <= 50"
            [tooltips]="['Unique followers can only be calculated when the number of influencers in the group is more than 2 and less than 50']">
            <ai-button label="Calculate" type="text" class="calculations-button" (click)="computeGroupStatistics()"
              [disabled]="totalInfluencers < 2 || totalInfluencers > 50" placement="left"></ai-button>
          </ai-tooltip>
        </div>

        <div *ngSwitchCase="'pending'">
          <ngx-loader></ngx-loader>
        </div>

        <div *ngSwitchCase="'valid'">
          <span nbTooltip="{{ +uniqueReach | number: '1.0-0' }}" nbTooltipPlacement="right">
            {{ +uniqueReach | formatNumber: 1 }} ({{ +uniqueReachPercentage | number: '1.2-2' }}%)
          </span>
        </div>
      </div>
    </ngx-loading-box>
  </ng-container>
</div>