<nb-spinner *ngIf="loading" class="large-spinner"></nb-spinner>
<div class="modal-header" [class.center]="areContentsCentered === true">
  <span>
    {{headerText}}
  </span>
  <button *ngIf="showCloseOption" class="close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [class.center]="areContentsCentered === true">

  <img *ngIf="image" [src]="image">
  <p *ngIf="contentText" [innerHtml]="contentText"></p>
  <div class="action-buttons">
    <ai-button *ngIf="secondaryActionButton" [label]="secondaryActionButton.label" (click)="toBackButton();"
      color="secondary" [type]="secondaryActionButton.type" [prefix]="secondaryActionButton.prefix">
    </ai-button>
    <ai-button *ngIf="primaryActionButton" [label]="primaryActionButton.label" (click)="toNextButton();"
      [type]="primaryActionButton.type" [suffix]="primaryActionButton.suffix">
    </ai-button>
  </div>
</div>