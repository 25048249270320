<div class='gauge-item' [ngClass]="{'guage-print':printingStyle, 'fx-col': withBorder}">
  <ngx-echarts-pie [dataOptions]='chartOptions' [printingStyle]='printingStyle'></ngx-echarts-pie>
  <div class='details'>
    <div class='label'>
      {{gaugeOptions.label}}
      <i *ngIf='!printingStyle && gaugeOptions?.tooltip' class='ion-ios-help-circle-outline' placement='top-right'
         [ngbPopover]='gaugeOptions.tooltip'
         container='body' triggers='hover'></i>
    </div>
    <div *ngIf='gaugeOptions.subLabel' class='sub-label'>{{gaugeOptions.subLabel}}</div>
    <div *ngIf='gaugeOptions.noteValue' class='note-label'>
      <span class="font-weight-bold">{{gaugeOptions.noteKey}}</span>
      <span>&nbsp;:&nbsp;{{gaugeOptions.noteValue}}</span>
    </div>
    <div *ngIf='enableColourLevels' class='fx-row fx-cross-center pad-4px'>
      <div *ngIf='!withBorder' class="fx-row fx-cross-center">
        <div class='status' [style.background]='_finalColor'></div>
        <span style='font-size: 80%'>&nbsp;{{gaugeOptions.badgeText}}</span>
      </div>
      <div *ngIf='withBorder' class='status-label-border' [style.color]='_finalColor'>
        <span  class='status-label' [style.border]='_finalColor' style='font-size: 80%'>&nbsp;{{gaugeOptions.badgeText}}</span>
      </div>
    </div>

  </div>
  <div *ngIf='printingStyle && gaugeOptions?.tooltip' class='tip'>
    {{gaugeOptions.tooltip}}
  </div>
</div>
