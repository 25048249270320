<div class="modal-header">
    <span>Load Search</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="privacy">
    <span class="shared" [ngClass]="selectedPrivacyFilter === 'shared' ? 'active' : 'inactive'"
        (click)="switchTab('shared'); menuPopoverContent.hide()">Shared ({{getFiltersCount('shared')}})</span>
    <span class="private" [ngClass]="selectedPrivacyFilter === 'private' ? 'active' : 'inactive'"
        (click)="switchTab('private'); menuPopoverContent.hide()">Private ({{getFiltersCount('private')}})</span>
</div>

<div class="search-bar">
    <input type="text" placeholder="Search saved searches here" class="input-group" [(ngModel)]="filterTerm"
        (ngModelChange)="filterSavedSearch($event)" />
    <span><i class="fa fa-search search-btn" *ngIf="!searching"></i></span>
    <span><i class="fa fa-times close-btn" *ngIf="searching" (click)="clearFilters()"></i></span>
</div>

<div class="modal-body-fixed-height"
    [ngClass]="selectedPrivacyFilter === 'shared' && getFilterList('global').length > 0 ? 'overflow-hidden' : ''">
    <ng-container *ngIf="selectedPrivacyFilter === 'shared'" [ngTemplateOutlet]="filterList"
        [ngTemplateOutletContext]="{filterList: getFilterList('global'), filterPrivacy: 'global'}"></ng-container>
    <ng-container *ngIf="selectedPrivacyFilter === 'shared'" [ngTemplateOutlet]="filterList"
        [ngTemplateOutletContext]="{filterList: getFilterList('org'), filterPrivacy: 'org'}"></ng-container>
    <ng-container *ngIf="selectedPrivacyFilter === 'private'" [ngTemplateOutlet]="filterList"
        [ngTemplateOutletContext]="{filterList: getFilterList('private'), filterPrivacy: 'private'}"></ng-container>
</div>

<popper-content #menuPopoverContent>
    <div (click)="menuItem.onClick && menuItem.onClick()" *ngFor="let menuItem of contextMenu"
        [hidden]="!menuItem.visible">
        <span class="context-menu-item">{{menuItem.title}}</span>
    </div>
</popper-content>

<ng-template #filterList let-filterList='filterList' let-filterPrivacy='filterPrivacy'>
    <span class="count-desc" *ngIf="filterPrivacy === 'global' && filterList.length > 0">Suggested searches by Affable
        ({{filterList.length}})</span>
    <span class="count-desc" *ngIf="filterPrivacy === 'org'">Shared by team members
        ({{filterList.length}})</span>
    <nb-list *ngIf="filterList.length > 0">
        <nb-list-item *ngFor="let filter of filterList" class="nb-list-custom">
            <nb-user size="small" [picture]="getUserPicture(filter)" [name]="filter.filterName"
                class="left pointer detail" (click)="loadFilter(filter)">
            </nb-user>
            <div style="display: inline-flex">
                <div class="default-search-label" *ngIf="filter.isDefault">Default Search</div>
                <button class="context-menu-btn right" [popper]="menuPopoverContent" [popperTrigger]="'click'"
                    popperApplyClass="context-menu-md" [popperPlacement]="'bottom-end'"
                    [popperModifiers]="popperModifiers" (popperOnShown)="onContextMenuDisplay($event, filter)"
                    [popperHideOnClickOutside]="true">
                    <i class="fas fa-ellipsis-v"></i>
                </button>
            </div>
        </nb-list-item>
    </nb-list>
    <div class="no-filters center" *ngIf="filterPrivacy === 'org' && filterList.length === 0">
        <div><img src="../../../../assets/images/Illus-no-shared-search.svg" alt="" class="no-filters-image"></div>
        <div class="center">
            <p>It looks like you or your team haven’t yet shared any saved searches.</p>
        </div>
    </div>
</ng-template>