import { Injectable } from '@angular/core';
import { NbAuthToken, NbAuthSimpleToken, NbTokenStorage } from '@nebular/auth';
import { nbAuthCreateToken } from '@nebular/auth';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class TokenStorage extends NbTokenStorage {
  protected key = 'auth_app_token';

  constructor(private localStorageService: LocalStorageService) {
    super();
  }

  clear(): void {
    this.localStorageService.removeAllItems();
  }

  get(): NbAuthToken {
    const raw = this.localStorageService.getItem(this.key);
    if (raw) {
      return nbAuthCreateToken(NbAuthSimpleToken, raw, 'AffableAuthStrategy');
    } else {
      return null;
    }
  }

  set(token: NbAuthToken): void {
    this.localStorageService.setItem(this.key, token.toString());
  }
}
