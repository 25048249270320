import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BrandLookup, CategoryInfo, DetailedCountry } from '../../interfaces';
import { AuthService } from '../auth/auth.service';
import { CountryService } from '../utils/country.service';
import { LanguageService } from '../utils/language.service';
import { Platforms, PlatformsV2 } from '../../enums';

@Injectable()
export class CategoryService {
  private interests: CategoryInfo[];
  private interestsWithoutLabels: CategoryInfo[];
  private countries: Promise<string[]>;
  private countriesLookup: string[];
  private detailedCountriesList: Promise<DetailedCountry[]>;
  private dashboardEnabledCountries: Promise<DetailedCountry[]>;
  private languages: Promise<string[]>;

  constructor(
    private http: HttpClient,
    private countryService: CountryService,
    private authService: AuthService,
    private languageService: LanguageService,
  ) {
    this.countries = this.fetchCountries();
    this.countriesLookup = this.lookupCountries();
    this.detailedCountriesList = this.fetchDetailedCountriesList(authService);
    this.dashboardEnabledCountries = this.fetchCountriesListForDashboard(authService);
    this.languages = this.fetchLanguages();
  }

  private fetchAllInterests(): Promise<CategoryInfo[]> {
    return this.http.get<CategoryInfo[]>(`${environment.api}/api/categories/interests/all`).toPromise();
  }

  private fetchInterestsWithoutLabels(): Promise<CategoryInfo[]> {
    return this.http.get<CategoryInfo[]>(`${environment.api}/api/categories/interests/audience`).toPromise();
  }

  private fetchCountries(): Promise<string[]> {
    return Promise.resolve(this.countryService.countriesList());
  }

  private fetchLanguages(): Promise<string[]> {
    return Promise.resolve(this.languageService.languagesList());
  }

  private lookupCountries(): string[] {
    const allCountries = this.countryService.countriesList();
    allCountries.unshift('ALL_REGIONS');
    return allCountries;
  }

  private fetchDetailedCountriesList(authService: AuthService): Promise<DetailedCountry[]> {
    return Promise.resolve(this.countryService.getDetailedCountries(authService.getRegionsEnabled()));
  }

  private fetchCountriesListForDashboard(authService: AuthService): Promise<DetailedCountry[]> {
    return Promise.resolve(this.countryService.getDashboardEnabledCountries(authService.getRegionsEnabled()));
  }

  fetchClientUserCountry(): Promise<string> {
    return Promise.resolve(this.authService.getEnabledClientLocation());
  }

  fetchClientUserCountryCode(): Promise<string> {
    return Promise.resolve(this.authService.getCountryCode());
  }

  getCountries(): Promise<string[]> {
    return this.countries;
  }

  getLanguages(): Promise<string[]> {
    return this.languages;
  }

  getCountriesObservable(term = ''): Observable<string[]> {
    return new Observable((observer) => {
      observer.next(this.countriesLookup.filter((item) => item.toLowerCase().includes(term.toLowerCase())));
    });
  }

  getDetailedCountries(): Promise<DetailedCountry[]> {
    return this.detailedCountriesList;
  }

  getDashboardEnabledCountries(): Promise<DetailedCountry[]> {
    return this.dashboardEnabledCountries;
  }

  async getInterests(): Promise<CategoryInfo[]> {
    if (this.interests) {
      return this.interests;
    }
    this.interests = await this.fetchAllInterests();
    return this.interests;
  }

  async getInterestsWithoutLables(): Promise<CategoryInfo[]> {
    if (this.interestsWithoutLabels) {
      return this.interestsWithoutLabels;
    }
    this.interestsWithoutLabels = await this.fetchInterestsWithoutLabels();
    return this.interestsWithoutLabels;
  }

  lookupBrands(term: string, platform: PlatformsV2 = Platforms.instagram): Observable<BrandLookup[]> {
    if (!term) {
      return of([]);
    }
    term = term.startsWith('@', 0) ? term.slice(1) : term;

    const endpoint = platform === Platforms.instagram ? 'categories/brands' : 'instagram/brands/lookup';
    return this.http.get<BrandLookup[]>(`${environment.api}/api/${endpoint}`, {
      params: { term },
    });
  }
}
