<div class="modal-header">
  <span>Add to Campaign</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-4" *ngIf="singlePlatformSelectionEnabled">
    <label class="af-sub-heading-h2  p-0">Select Platform <span class="required">*</span></label>
    <div class="d-flex" style="gap: 20px;">
      <div class="af-platform-radio-group" *ngFor="let account of availablePlatforms">
        <label class="af-radio-group" [ngClass]="!enabledPlatform.includes(account) ? 'disabled': null">{{account |
          titlecase}}
          <input [disabled]="!enabledPlatform.includes(account)" type="radio" [value]="account"
            [checked]="account === platform?.toLowerCase()" (change)="onPlatformSelectionChange($event.target.value)"
            name="radio">
          <span class="af-radio"></span>
        </label>
      </div>

    </div>
  </div>
  <div class="form-group">
    <input [disabled]="!platform" class="input-group input-group-sm" type="text" placeholder="Search a campaign"
      [(ngModel)]="searchText" keyupDebounce (onEvent)="searchCampaigns()">
  </div>

  <div class="campaigns-list" infinite-scroll [infiniteScrollDistance]="15" [infiniteScrollThrottle]="150"
    [scrollWindow]="false" (scrolled)="onScroll()" fromRoot="true">
    <div class="campaign" [class.selected]="selectedCampaigns.includes(campaign)" *ngFor="let campaign of campaigns"
      (click)="toggleSelectCampaign(campaign)">
      <div class="campaign-name" data-cy="add-to-existing-campaign-select">{{campaign.campaignName}}
      </div>
      <i class="far fa-check" *ngIf="selectedCampaigns.includes(campaign)"></i>
    </div>
  </div>
  <div class="loader">
    <div class="message">{{campaignsNotFoundMessage}}</div>
    <ngx-loader class="modal-loader" *ngIf="loading"></ngx-loader>
  </div>
</div>
<div class="modal-footer">
  <ai-button typography="body-3" class="mr-2" (click)="addToCampaign()" [disabled]="selectedCampaigns.length === 0"
    label="ADD TO
    CAMPAIGNS" data-cy="add-to-campaigns-btn"></ai-button>
</div>