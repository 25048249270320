<div class="modal-header">
  <span>Create Group</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-3" *ngIf="singlePlatformSelectionEnabled">
    <label class="af-sub-heading-h2  p-0">Select Platform <span class="required">*</span></label>
    <div class="d-flex" style="gap: 20px;">
      <div class="af-platform-radio-group" *ngFor="let account of availablePlatforms">
        <label class="af-radio-group" [ngClass]="!enabledPlatform.includes(account) ? 'disabled': null">{{account |
          titlecase}}
          <input [disabled]="!enabledPlatform.includes(account)" type="radio" [value]="account"
            [checked]="account === platform?.toLowerCase()" (change)="onPlatformSelectionChange($event.target.value)"
            name="radio">
          <span class="af-radio"></span>
        </label>
      </div>

    </div>
  </div>
  <div class="">
    <div class="form-group">
      <label class="af-sub-heading-h2  p-0">Group Name<span class="required">*</span></label>
      <input [disabled]="!platform" type="text" trim placeholder="Enter group name" class="input-group"
        [(ngModel)]="groupName" #groupnameinput data-cy="create-group-name-input" />
    </div>

    <div class="form-group" *ngIf="isAgencyBrandGroupEnabled">
      <label class="af-sub-heading-h2 p-0">Tags</label>
      <ng-select #groupTagSelect placeholder="Add tags, maximum 5 tags (Ex: Macro influencers, Fitness, Nike, etc.)"
        [addTag]="true" [selectableGroup]="true" [multiple]="true" [markFirst]="false" [clearOnBackspace]="false"
        [isOpen]="false" [(ngModel)]="groupTags" [items]="groupTags" (keydown)="groupTagKeyDown($event)"
        [maxSelectedItems]="5">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value chip" *ngFor="let item of items">
            <span class="ng-value-label">{{item}}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <small class="form-text">{{errorMessage}}</small>
  </div>

</div>
<div class="loader-container" *ngIf="loading">
  <ngx-loader class="modal-loader"></ngx-loader>
</div>
<div class="modal-footer">
  <button class="btn btn-xs btn-primary" (click)="createGroup()" [disabled]="!groupName || loading"
    data-cy="create-group-button">CREATE
    GROUP</button>
</div>