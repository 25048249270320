/* eslint-disable no-prototype-builtins,@typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { of, Subject, Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { AddOns, GTMUser, Organization, OrganizationSettings, PublicAppSubscriptionPlan } from '../../interfaces';
import { Platforms, VeraPlatforms } from '../../enums';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../feature-flag/flags';

@Injectable()
// we should only keep a single instance of this service, therefore we should only load it from Auth.module
export class AuthService {
  refreshSubscription: Subscription;
  private unauthorizedNotifier$: Subject<boolean> = new Subject();
  unauthroizedNotifier = this.unauthorizedNotifier$.asObservable();
  private cachedSelectedPlatform?: Platforms | VeraPlatforms;
  private selectedOrganizationId: string;
  private selectedOrgSettings: Partial<Organization>;

  constructor(
    private jwtHelperService: JwtHelperService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {
    this.selectedOrganizationId = this.getOrganizationId(true);
    this.selectedOrgSettings = this.getOrganizationSettings(true);
    // initialize in memory properties from local storage
  }

  checkIfRefreshTokenExists(): boolean {
    const token = this.localStorageService.getItem('refresh_token');
    return token ? true : false;
  }

  setCachedSelectedPlatform(platform: Platforms | VeraPlatforms): void {
    this.cachedSelectedPlatform = platform;
  }

  getCachedSelectedPlatform(): Platforms | VeraPlatforms {
    return this.cachedSelectedPlatform;
  }

  async refreshToken(): Promise<void> {
    try {
      const result = await this.http
        .post(`https://${environment.authConfig.domain}/oauth/token`, {
          grant_type: 'refresh_token',
          scope: 'openid offline_access',
          audience: environment.authConfig.audience,
          client_id: environment.authConfig.clientID,
          refresh_token: this.localStorageService.getItem('refresh_token'),
        })
        .toPromise();
      if (result) {
        this.setSession(result);
        await this.setOrganizationSettings();
      }
    } catch (err) {
      this.notifyUnauthorized();
      window.location.reload();
      // After notify Unauthorized, the reload will direct Samantha to login
    }
  }

  public getAccessTokenFromCache(): string {
    return this.localStorageService.getItem('access_token');
  }

  public scheduleRenewal(): void {
    if (!this.isAuthenticated()) {
      return;
    }
    this.unscheduleRenewal();

    const expiresAt = JSON.parse(this.localStorageService.getItem('expires_at'));

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap((expiresAtTime) => {
        const now = Date.now();
        // Use timer to track delay until expiration
        // to run the refresh at the proper time
        return timer(Math.max(1, expiresAtTime - now));
      }),
    );
    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = expiresIn$.subscribe(() => {
      this.refreshToken();
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  public async login(username: string, password: string, shopURL?: string): Promise<void> {
    const result = await this.http
      .post(`https://${environment.authConfig.domain}/oauth/token`, {
        username,
        password,
        scope: 'openid offline_access',
        audience: environment.authConfig.audience,
        realm: 'Username-Password-Authentication',
        client_id: environment.authConfig.clientID,
        grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
      })
      .toPromise()
      .catch(({ status, error }) => {
        let errorCode: LoginErrorCode = 'unknown';
        if (status === 403) {
          errorCode = error.error_description === 'user is blocked' ? 'blocked' : 'invalid-creds';
        } else if (status === 429) {
          errorCode = 'blocked';
        } else if (status === 401 && error.error_description === 'Please verify your email before logging in.') {
          errorCode = 'verification-pending';
        } else {
          console.error(error);
        }

        throw new Error(errorCode);
      });

    if (result) {
      this.setSession(result);
      if (!(await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.ORGANIZATION_SETTINGS))) {
        await this.setOrganizationSettings();
      }
      if (shopURL) this.setPublicAppStoreAccessToken(shopURL);
    }
  }

  async setOrganizationSettings(): Promise<void> {
    const addOns = (await this.fetchOrganization())?.addOns;
    this.selectedOrgSettings = { addOns };
    this.localStorageService.setItem(
      'organization_settings',
      JSON.stringify({
        addOns,
      }),
    );
  }

  getOrganizationSettings(onlyFromPersistentSource = false): OrganizationSettings {
    if (onlyFromPersistentSource) return JSON.parse(this.localStorageService.getItem('organization_settings'));
    return this.selectedOrgSettings ?? JSON.parse(this.localStorageService.getItem('organization_settings'));
  }

  saveOrganizationId(orgId: string): void {
    this.selectedOrganizationId = orgId;
    this.localStorageService.setItem('organization_id', orgId);
  }

  getOrganizationId(onlyFromPersistentSource = false): string {
    if (onlyFromPersistentSource) return this.localStorageService.getItem('organization_id');
    return this.selectedOrganizationId ?? this.localStorageService.getItem('organization_id');
  }

  // this function triggers the event `identifyUser` on Google Tag Manager.
  setGoogleTagManagerUser(user: GTMUser): void {
    if (window['dataLayer']) {
      window['dataLayer'].push({
        event: 'identifyUser',
        user,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async resetPassword(email: string, additionalData?: any): Promise<string> {
    return this.http
      .post(`https://${environment.authConfig.domain}/dbconnections/change_password`, {
        email,
        client_id: environment.authConfig.clientID,
        connection: 'Username-Password-Authentication',
      })
      .toPromise()
      .catch(({ status, error }) => {
        if (status === 200) return error.text;
        throw new Error(error.text);
      })
      .then((data) => {
        additionalData &&
          this.http.post(`${environment.api}/api/client/password-rest`, { email, ...additionalData }).toPromise();

        return data;
      });
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(this.localStorageService.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public notifyUnauthorized(): void {
    this.unauthorizedNotifier$.next(true);
    this.removeInMemoryOrgDetails();
    this.localStorageService.removeAllItems();
  }

  private removeInMemoryOrgDetails() {
    this.selectedOrganizationId = undefined;
    this.selectedOrgSettings = undefined;
  }

  public isAdmin(): boolean {
    // check if isAdmin is present on the app namespace on idtoken set by Auth0 Rules
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      if (decodedToken.hasOwnProperty(namespace + 'isAdmin')) {
        return decodedToken[namespace + 'isAdmin'];
      }
    }
    return false;
  }

  public isLiteUser(): boolean {
    // check if isLite is present on the app namespace on idtoken set by Auth0 Rules
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      if (decodedToken.hasOwnProperty(namespace + 'isLiteUser')) {
        return decodedToken[namespace + 'isLiteUser'];
      }
    }
    return false;
  }

  getCountryCode(): string {
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const addons = this.getAddons();
      if (addons['overriddenLocation']) {
        return addons['overriddenLocation']['country_code'];
      }
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      return decodedToken[namespace + 'location']['country_code'];
    }
    return 'SG';
  }

  getCreatedAt(): string | undefined {
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      if (decodedToken.hasOwnProperty(namespace + 'createdAt')) {
        return decodedToken[namespace + 'createdAt'];
      }
    }
  }

  public isMessagingEnabled(): boolean {
    return this.hasAddOn('isMessagingEnabled');
  }

  public isCreatorsPortalEnabled(): boolean {
    return this.hasAddOn('creatorsPortal');
  }

  public isStoriesReportingEnabled(): boolean {
    return this.hasAddOn('isStoriesReportingEnabled');
  }

  public isReportingEnabled(): boolean {
    return this.hasAddOn('campaignReports');
  }

  public isExportsEnabled(): boolean {
    return this.hasAddOn('exports');
  }

  public isPdfExportsEnabled(): boolean {
    return this.hasAddOn('pdfExports');
  }

  public isPdfExportsWithLogoEnabled(): boolean {
    return this.hasAddOn('pdfExportsLogo');
  }

  public isContentExportEnabled(): boolean {
    return this.hasAddOn('contentExports');
  }

  public isInsightsEnabled(): boolean {
    return this.hasAddOn('creatorCampaigns');
  }

  public isDashboardEnabled(): boolean {
    return this.hasAddOn('contentTrends');
  }

  public isCampaignsEnabled(): boolean {
    return this.hasAddOn('campaigns');
  }

  public isPartnerizeEnabled(): boolean {
    return this.hasAddOn('partnerizeId');
  }

  public isGmailLinkEnabled(): boolean {
    return this.hasAddOn('isGmailLinkEnabled');
  }

  public isInstagramEnabled(): boolean {
    const platformsEnabled = this.getAddons()['platformsEnabled'] || [];
    return platformsEnabled.indexOf('instagram') > -1;
  }

  public isFacebookEnabled(): boolean {
    const platformsEnabled = this.getAddons()['platformsEnabled'] || [];
    return platformsEnabled.indexOf('facebook') > -1;
  }

  public isYoutubeEnabled(): boolean {
    const platformsEnabled = this.getAddons()['platformsEnabled'] || [];
    return platformsEnabled.indexOf('youtube') > -1;
  }

  public isTiktokEnabled(): boolean {
    const platformsEnabled = this.getAddons()['platformsEnabled'] || [];
    return platformsEnabled.indexOf('tiktok') > -1;
  }

  public isTwitterEnabled(): boolean {
    const platformsEnabled = this.getAddons()['platformsEnabled'] || [];
    return platformsEnabled.indexOf('twitter') > -1;
  }

  public isShopifySubscribed(): boolean {
    return this.getStripePlan()?.toUpperCase().includes('SHOPIFY-');
  }

  public isCommunityEnabled(): boolean {
    return this.hasAddOn('community');
  }

  public isContentDiscoveryEnabled(): boolean {
    // * Content Discovery is enabled for all users except explicitly set to false in the client's settings and have the console enabled
    return this.getAddons()?.contentDiscovery !== false;
  }

  public isInfluencerDiscoveryEnabled(): boolean {
    // * Influencer Discovery is enabled for all users except explicitly set to false in the client's settings and have the console enabled
    return this.getAddons()?.influencerDiscovery !== false;
  }

  public isConsoleEnabled(): boolean {
    return !!this.getOrganizationSettings()?.addOns?.console || this.hasAddOn('console');
  }

  public isCollaborationPortalEnabled(): boolean {
    return !!this.getOrganizationSettings()?.addOns?.collaborationPortal;
  }

  public isSkyeSearchEnabled(): boolean {
    return this.hasAddOn('skyeSearch');
  }

  public isEmailSequenceEnabled(): boolean {
    const addOns = this.getAddons();
    return addOns.hasOwnProperty('emailSequence') ? addOns['emailSequence'] : true;
  }

  public async getDefaultHomeUrl(): Promise<string> {
    if (this.isShopifySubscribed()) {
      return '/pages/public';
    }

    if (await this.isConsoleEnabled()) {
      return '/pages/console';
    }

    const enabledPlatforms = this.getAddons()?.platformsEnabled || [];
    // TODO: remove below condition once facebook is enabled
    if (enabledPlatforms[0]?.toLowerCase() === Platforms.facebook?.toLowerCase()) {
      const first = enabledPlatforms.shift();
      enabledPlatforms.push(first);
    }
    let selectedPlatform = (
      this.cachedSelectedPlatform ? this.cachedSelectedPlatform : enabledPlatforms[0]
    ).toLowerCase();
    if (selectedPlatform.includes('instagram')) {
      selectedPlatform = 'veraInstagram';
    }
    const platformUrl = selectedPlatform === 'instagram' ? '' : `${selectedPlatform}/`;
    return `/pages/${platformUrl}discovery/${this.isInfluencerDiscoveryEnabled() ? 'influencers' : 'content'}`;
  }

  public queryUrlAllowed(url: string): boolean {
    switch (url) {
      case '/pages/discovery/influencers':
        return this.isInfluencerDiscoveryEnabled() && this.isInstagramEnabled();
      case '/pages/facebook/discovery/influencers':
        return this.isInfluencerDiscoveryEnabled() && this.isFacebookEnabled();
      case '/pages/youtube/discovery/influencers':
        return this.isInfluencerDiscoveryEnabled() && this.isYoutubeEnabled();
      case '/pages/twitter/discovery/influencers':
        return this.isInfluencerDiscoveryEnabled() && this.isTwitterEnabled();
      case '/pages/tiktok/discovery/influencers':
        return this.isInfluencerDiscoveryEnabled() && this.isTiktokEnabled();
      default:
        return false;
    }
  }

  public getRegionsEnabled(): string[] {
    const addOns = this.getAddons();
    return addOns.hasOwnProperty('regions') ? addOns['regions'] : ['ALL_REGIONS'];
  }

  public getCurrency(): string {
    const addOns = this.getAddons();
    return addOns.hasOwnProperty('currency')
      ? addOns['currency'] === 'USD'
        ? 'USD'
        : addOns['currency'] + ' '
      : 'USD';
  }

  public getEnabledClientLocation(): string {
    const auth0Location = this.getLocation();
    const regionsEnabled = this.getRegionsEnabled();
    const firstEnabledRegion = regionsEnabled.filter((region) => region !== 'ALL_REGIONS')[0];

    if (auth0Location === undefined) {
      return firstEnabledRegion || 'Singapore';
    }
    if (regionsEnabled.includes('ALL_REGIONS') || regionsEnabled.includes(auth0Location)) {
      return auth0Location;
    }

    return firstEnabledRegion || 'Singapore';
  }

  public getMaxInfluencerSearchResults(): number {
    const IFS_RESULTS_LIMIT_DEFAULT = 100;
    const IFS_RESULTS_UPPER_LIMIT = 500;
    const addons = this.getAddons();
    const result = addons.hasOwnProperty('maxInfluencerSearchResults')
      ? addons['maxInfluencerSearchResults']
      : IFS_RESULTS_LIMIT_DEFAULT;

    return Math.min(result, IFS_RESULTS_UPPER_LIMIT);
  }

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(authResult.expires_in * 1000 + new Date().getTime());
    this.localStorageService.setItem('access_token', authResult.access_token);
    this.localStorageService.setItem('id_token', authResult.id_token);
    this.localStorageService.setItem('expires_at', expiresAt);
    if (authResult.refresh_token) {
      this.localStorageService.setItem('refresh_token', authResult.refresh_token);
    }
    this.scheduleRenewal();
  }

  private getAddons(): Partial<AddOns> {
    const organizationAddons = this.getOrganizationSettings()?.addOns ?? {};
    let clientAddons = {};
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      if (decodedToken.hasOwnProperty(namespace + 'addOns')) {
        clientAddons = decodedToken[namespace + 'addOns'] ?? {};
      }
    }
    return { ...clientAddons, ...organizationAddons };
  }

  private getStripePlan(): string {
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      if (decodedToken.hasOwnProperty(namespace + 'stripePlan')) {
        return decodedToken[namespace + 'stripePlan'];
      }
    }
    return undefined;
  }

  private getLocation(): string {
    const accessToken = this.localStorageService.getItem('access_token');
    const namespace = 'https://affable.ai/';
    if (accessToken) {
      const decodedToken = this.jwtHelperService.decodeToken(accessToken);
      return decodedToken[namespace + 'location']['country_name'];
    }
    return undefined;
  }

  private hasAddOn(addOn): boolean {
    const addOns = this.getAddons();
    return addOns.hasOwnProperty(addOn) ? addOns[addOn] : false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setPublicAppStoreAccessToken(shop: string): Promise<any> {
    return this.http.put(`${environment.api}/api/shopify/public/app/set-access-token`, { shop }).toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createSubscription(planName: string, isUpdatingPlan: boolean): Promise<any> {
    return this.http
      .post(`${environment.api}/api/shopify/public/app/create-subscription`, { planName, isUpdatingPlan })
      .toPromise();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchSubscriptionPlans(): Promise<any> {
    return this.http.get(`${environment.api}/api/ecommerce/public/app/present-subscription-plans`).toPromise();
  }

  fetchAllSubscriptionPlans(): Promise<PublicAppSubscriptionPlan[]> {
    return this.http
      .get<PublicAppSubscriptionPlan[]>(`${environment.api}/api/ecommerce/public/app/subscription-plans`)
      .toPromise();
  }

  fetchOrganization(): Promise<Organization> {
    return this.http.get<Organization>(`${environment.api}/api/organization/`).toPromise();
  }
}

export declare type LoginErrorCode = 'invalid-creds' | 'blocked' | 'unknown' | 'verification-pending';
