import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../@core/data/user.service';
import { Tweet, TweetContent, TwitterProfile } from '../../interfaces';
import { TweetDetailsModalComponent } from '../tweet-details-modal/tweet-details-modal.component';
@Component({
  selector: 'ngx-tweets-card',
  templateUrl: './tweets-card.component.html',
  styleUrls: ['./tweets-card.component.scss'],
})
export class TweetsCardComponent {
  @Input() tweets: Tweet[] | TweetContent[];

  @Input() profile: TwitterProfile;

  @Input() isContentTweets = false;

  @Input() maxCheck: number;

  @Input() size: string;

  @Input() hideStats = false;

  constructor(private modalService: NgbModal, private userService: UserService) {}

  async openTweetDetailsModal(tweet: Tweet | TweetContent): Promise<void> {
    const isMediaPost = tweet.media && tweet.media?.length > 0;
    const tweetDetailsModal = this.modalService.open(TweetDetailsModalComponent, {
      centered: true,
      windowClass: isMediaPost ? 'content-details-modal' : 'text-post-details-modal',
    });
    const modalContent: TweetDetailsModalComponent = tweetDetailsModal.componentInstance;
    modalContent.tweet = tweet;
    modalContent.isMediaPost = isMediaPost;
    modalContent.profile = this.isContentTweets ? (tweet as TweetContent).author : this.profile;
    modalContent.currency = tweet.currency || (await this.userService.getLinkedCurrency());
    modalContent.hideStats = this.hideStats;
  }

  checkedCount(): number | undefined {
    return (this.tweets as TweetContent[]).filter((tweet) => tweet.checked)?.length;
  }
}
