import { Component, Input, OnInit } from '@angular/core';
import { ResourceType } from '../../@core/data/resource-access-control.service';
import { CollaborationInfluencerService } from '../../collaborator/influencer-profile-page/collaboration-influencer.service';
import { BrandWithMediaItems } from '../../@core/data/influencer.service';

@Component({
  selector: 'ngx-profile-brand-affinity',
  templateUrl: './profile-brand-affinity.component.html',
  styleUrls: ['./profile-brand-affinity.component.scss'],
})
export class ProfileBrandAffinityComponent implements OnInit {
  public influencerBrandAffinityWithMedia: BrandWithMediaItems[];
  public filteredInfluencerBrandAffinityWithMedia: BrandWithMediaItems[];
  public sortOptions = [
    { value: 'recency', label: 'Most Recent' },
    { value: 'alphabetical', label: 'Alphabetical' },
    { value: 'frequency', label: 'Most Frequent' },
  ];
  public loading = true;
  public searchText = '';
  public showOnlySponsors = false;
  public sortByField = this.sortOptions[0];

  @Input() resourceId: string;
  @Input() resourceType: ResourceType;
  @Input() influencerUsername: string;
  @Input() tooltipMessages: Map<string, string>;

  constructor(private collaborationInfluencerService: CollaborationInfluencerService) {}

  ngOnInit(): void {
    this.getInfluencerBrandAffinityWithMedia();
  }

  async getInfluencerBrandAffinityWithMedia(): Promise<void> {
    this.loading = true;

    this.influencerBrandAffinityWithMedia =
      (await this.collaborationInfluencerService.fetchInfluencerBrandAffinityWithMedia(
        this.influencerUsername,
        this.resourceId,
        this.resourceType,
        this.sortByField.value,
        this.showOnlySponsors,
      )) ?? [];

    this.filteredInfluencerBrandAffinityWithMedia = this.influencerBrandAffinityWithMedia;
    this.searchText = '';
    this.loading = false;
  }

  onSearch(text: string): void {
    if (!text?.trim()) {
      this.filteredInfluencerBrandAffinityWithMedia = this.influencerBrandAffinityWithMedia;
      return;
    }

    this.filteredInfluencerBrandAffinityWithMedia = this.influencerBrandAffinityWithMedia.filter((brand) => {
      return [brand.fullName.toLowerCase(), brand.username.toLowerCase()].some((tag) =>
        tag.includes(text.toLowerCase()),
      );
    });
  }

  onFilterChange(filter?: { value: string; label: string }): void {
    if (filter?.value && JSON.stringify(filter) !== JSON.stringify(this.sortByField)) {
      this.sortByField = filter;
      this.getInfluencerBrandAffinityWithMedia();
      return;
    }

    this.filteredInfluencerBrandAffinityWithMedia = this.showOnlySponsors
      ? this.influencerBrandAffinityWithMedia.filter(({ mediaItems }) => mediaItems.some(({ sponsored }) => sponsored))
      : this.influencerBrandAffinityWithMedia;
  }

  toggleBrand(brand: BrandWithMediaItems): void {
    brand.expanded = !brand.expanded;
  }
}
