import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LastestOldestKey } from '../../enums';
import { SortOption } from '../../interfaces';

@Component({
  selector: 'ngx-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent {
  @Input() exportDisabled: boolean;
  @Input() loading: boolean;
  @Input() sortKey: LastestOldestKey;
  @Input() sortOptions: SortOption[];
  @Output() exportTableData: EventEmitter<any> = new EventEmitter();
  @Output() sortFilterChangedEvent: EventEmitter<LastestOldestKey> = new EventEmitter();
  @Input() search: string;
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  @Output() searchEvent: EventEmitter<string> = new EventEmitter();

  clearSearch(): void {
    if (!this.search.trim().length) {
      return;
    }
    this.search = '';
    this.searchChange.emit('');
    this.searchEvent.emit();
  }
}
