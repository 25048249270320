<nb-card-body class="notes-card">
  <!-- Will only appear for Groups where the influencer is defined -->
  <div *ngIf="note.influencerPicture" class="influencer-info">
    <nb-user [picture]="note.influencerPicture" [name]="note.influencerName" [title]="note.influencerUsername"
      size="small"></nb-user>
  </div>

  <!-- Note owner information is only needed for multi-user organizations and Shared Notes. Private Notes  will always be the users own notes and will not need owner information. -->
  <div class="owner-container" *ngIf="!isSingleUserOrg" [class.owner-non-group]="!note.influencerPicture"
    [class.owner-private]="note.isPrivate">
    <div *ngIf="!note.isPrivate" class="profile-info-container">
      <nb-user [picture]="note.creatorPicture" [name]="note.creatorName" class="owner-info" size="small"></nb-user>
      <div *ngIf="note.teamName||note.orgName" [class]="'name-chip af-body-4'+(note.teamName?' team-chip':' org-chip')">
        {{note.teamName||note.orgName}}
      </div>
    </div>
    <!-- User should only have access to context menu for note modifications if they are the owner. For Shared Notes, context menu position is within the owner container -->
    <div *ngIf="!editingNote && ownNote && !note.isPrivate">
      <button class="context-menu" [popper]="notesContextMenu" [popperTrigger]="'click'" [popperHideOnScroll]="true"
        popperApplyClass=" context-menu-md" [popperPlacement]="'bottom-end'" [popperModifiers]="popperModifiers">
        <i class="fas fa-ellipsis-v"></i>
      </button>
    </div>
    <!-- Privacy toggle is only needed for multi-user organizations -->
    <nb-checkbox [class.private]="note.isPrivate" *ngIf="editingNote && !isSingleUserOrg && !isCollabPortal" [(ngModel)]="changedPrivacy">
      Make it {{ note.isPrivate ?
      'Shared' : 'Private' }}</nb-checkbox>
  </div>

  <div class="timestamp-container">
    <div>
      {{ note.updatedAt === note.createdAt ? 'Added: ' : 'Edited: '
      }}{{ note.updatedAt | date: 'dd MMM, yyyy | hh:mm a' }}
    </div>
    <!-- For private notes or single user organizations, owner container is not needed so context-menu position is within timestamp container. -->
    <div *ngIf="(!editingNote && isSingleUserOrg) || (ownNote && note.isPrivate)">
      <button class="context-menu" [popper]="notesContextMenu" [popperTrigger]="'click'" [popperHideOnScroll]="true"
        popperApplyClass=" context-menu-md" [popperPlacement]="'bottom-end'" [popperModifiers]="popperModifiers">
        <i class="fas fa-ellipsis-v"></i>
      </button>
    </div>
  </div>

  <!-- Note body -->
  <div *ngIf="editingNote" class="note-item">
    <textarea id="{{ note.id }}" rows="3" cols="30" type="text" [(ngModel)]="editedNote"> </textarea>
  </div>
  <div *ngIf="!editingNote" class="note-item">
    <div class="note-text" [innerHTML]="note.note"></div>
  </div>

  <!-- Attachment if any -->
  <div class="note-file" *ngIf="note.file?.path && !deletedFile">
    <button class="file-name" title="download file" (click)="downloadFile()">
      <i class="attach-btn fas fa-paperclip"></i>
      <div class="name">
        {{ note.file.name }}
      </div>
      <button class="delete-file-button" *ngIf="editingNote" title="delete file" (click)="deleteFile()">
        <i class="fas fa-close"></i>
      </button>
    </button>
  </div>

  <!-- Note editing confirmations -->
  <div class="edit-controls" *ngIf="editingNote">
    <button (click)="disableEditing()" title="Cancel" class="btn btn-xs btn-secondary list-action-button">
      Cancel
    </button>
    <button [disabled]="!editedNote || editedNote.trim() === ''" (click)="editNote()" title="Save"
      class="btn btn-xs btn-primary list-action-button">Save Changes</button>
  </div>

  <!-- Note deletion confirmations -->
  <div class="confirm-delete" *ngIf="deletingNote">
    <div class="delete-confirmation">Are you sure?</div>
    <button (click)="disableDeleting()" title="Cancel" class="btn btn-xs btn-secondary list-action-button">
      Cancel
    </button>
    <button (click)="deleteNote()" title="Save" class="btn btn-xs btn-primary list-action-button">Delete</button>
  </div>
</nb-card-body>

<!-- Note edit/delete menu -->
<popper-content #notesContextMenu>
  <div class="list-group">
    <div *ngIf="!editingNote" class="list-group-item list-group-item-action"
      (click)="notesContextMenu.hide(); enableEditing()">
      <i class="fa fa-edit"></i>
      <div class="item-text">Edit Note</div>
    </div>
    <div class="list-group-item list-group-item-action" (click)="notesContextMenu.hide(); enableDeleting()">
      <i class="fa fa-trash"></i>
      <div class="item-text">Delete Note</div>
    </div>
  </div>
</popper-content>
