import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../@core/auth/local-storage.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorService {
  constructor(
    private _http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private jwtHelperService: JwtHelperService,
  ) {}

  validateAccessTokenReq(token: string): Promise<{ access_token: string; expires_at: string }> {
    return this._http
      .post<{ access_token: string; expires_at: string }>(`${environment.api}/api/collabs/validate-token`, {
        token,
      })
      .toPromise();
  }

  get isLoggedIn(): boolean {
    return !!this.localStorageService.getItem(this.localStorageService.collaboratorAccessTokenKey);
  }

  getExternalUserId(): string | undefined {
    const collabToken = this.localStorageService.getItem(this.localStorageService.collaboratorAccessTokenKey);
    if (!collabToken) return undefined;
    const decodedToken = this.jwtHelperService.decodeToken(collabToken);
    return decodedToken['id'];
  }

  async getExternalUser(): Promise<{ email: string }> {
    return this._http.get<{ email: string }>(`${environment.api}/api/collabs/external-user`).toPromise();
  }

  login(email: string): Promise<{ status: string }> {
    return this._http.post<{ status: string }>(`${environment.api}/api/collabs/login`, { email }).toPromise();
  }

  navigateToHomePage() {
    this.router.navigate(['/collabs']);
  }

  async validateAccessToken(token: string) {
    const { access_token, expires_at } = await this.validateAccessTokenReq(token);
    this.localStorageService.setItem(this.localStorageService.collaboratorAccessTokenKey, access_token);
    this.localStorageService.setItem('collaborator_token_expires_at', expires_at);
    this.navigateToHomePage();
  }
}
