<div class="public-app-login">
  <div class="public-app-login-details">
    <h5>Welcome Back!</h5>
    <p>Let's get some of the right influencers for you!</p>
    <img src="../../../../../../../assets/images/Illustration_Sign in.svg" alt="" style="width: 500px;">
  </div>

  <div class="vertical-line"></div>

  <div>
    <div class="public-app-login-title">
      <img style="height: 72px;" alt="" affLogo>

      <h6>Log in with Affable account</h6>
    </div>

    <form #form="ngForm" autocomplete="off">
      <div class="form-group">
        <label for="input-email">Email</label>
        <input class="form-control" id="input-email" name="email" type="email" email #email="ngModel"
          [(ngModel)]="credentials.email" (keydown.space)="$event.preventDefault();"
          [class.form-control-danger]="email.invalid && email.touched" [required]="true">
        <small class="form-text error" *ngIf="email.invalid && email.touched">
          Valid email is required!
        </small>
      </div>

      <br>

      <div class="form-group">
        <label for="input-password">Password</label>
        <div class="password-input">
          <input class="form-control" id="input-password" name="password" [type]="showPassword ? 'text' : 'password'"
            #password="ngModel" [(ngModel)]="credentials.password"
            [class.form-control-danger]="password.invalid && password.touched" [required]="true">
          <span (click)="showPassword = !showPassword">
            {{showPassword ? 'Hide' : 'Show'}}
          </span>
        </div>
        <small class="form-text error" *ngIf="password.invalid && password.touched">
          Password is required!
        </small>
      </div>

      <a (click)="redirectToResetPassword()">Forgot password?</a>

      <button class="btn btn-block btn-primary" (click)="login()"
        [disabled]="!credentials.email || !credentials.password || !form.valid && (email.touched || password.touched)">
        LOG IN
      </button>

      <div class="signup">
        <p>Don’t have an account? <a (click)="redirectToSignup()">Create Account</a></p>
      </div>

    </form>
  </div>
</div>

<nb-spinner *ngIf="loading" class="large-spinner"></nb-spinner>
