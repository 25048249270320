import { Directive, ElementRef } from '@angular/core';
import { WhiteLabelService } from '../../@core/data/whitelabel.service';

@Directive({
  selector: '[affLogo]',
})
export class AffableLogoDirective {
  constructor(el: ElementRef, private whiteLabelService: WhiteLabelService) {
    const logo = this.getLogo();
    if (el?.nativeElement.tagName.toUpperCase() === 'I') {
      el.nativeElement.style.cssText = `content: url(${logo.path}); width: ${logo.headerPageWidth}px !important;`;
    } else {
      el.nativeElement.src = logo.path;
    }
  }

  getLogo() {
    return this.whiteLabelService.getConfig().logo;
  }
}
