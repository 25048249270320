import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import utils from '../../@core/utils/utils';
@Directive({
  selector: 'img[ngxHandleBlockedImage]',
})
export class ImagePreloadDirective {
  @HostBinding('attr.src')
  @Input()
  src: string;

  @HostListener('error') onError(): void {
    /**
     * Only for Instagram
     * Used in instagram influencer search list to avoid blocking of image
     */
    if (this.src && this.ngxHandleBlockedImage) {
      const url = new URL(this.src);
      if (this.src && url.origin !== environment.api) {
        this.src = utils.getInstagramCDNUrl(this.src);
      }
    }
  }

  @Input() ngxHandleBlockedImage: boolean;
}
