import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class InfluencerDiscoveryGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isInfluencerDiscoveryEnabled = this.auth.isInfluencerDiscoveryEnabled();
    if (!isInfluencerDiscoveryEnabled) {
      console.error(`Discovery guard rejected access to ${state.url}`);
      this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return isInfluencerDiscoveryEnabled;
  }
}

@Injectable()
export class ContentDiscoveryGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isContentDiscoveryEnabled = this.auth.isContentDiscoveryEnabled();
    if (!isContentDiscoveryEnabled) {
      console.error(`Discovery guard rejected access to ${state.url}`);
      this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return isContentDiscoveryEnabled;
  }
}
