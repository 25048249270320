import { Component, Input } from '@angular/core';
import { Typography } from '../../common.enum';

@Component({
  selector: 'ai-card-body',
  templateUrl: './card-body.control.html',
  styleUrls: ['./card-body.control.scss'],
})
export class CardBodyControl {
  @Input() size: 'medium' | 'small' | 'large' = 'medium';

  get textClass(): Typography {
    switch (this.size) {
      case 'small':
        return 'body-3';
      case 'large':
        return 'body-1';
      default:
        return 'body-2';
    }
  }
}
