/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { CommentAnalysis } from '../../interfaces';

@Component({ template: '' })
export class CommentAnalysisComponentAbstract implements OnInit {
  @Input()
  commentAnalysis: CommentAnalysis;

  positivePercentage: number;
  neutralPercentage: number;
  negativePercentage: number;
  emojiOnlyPercentage: number;
  hasMentionPercentage: number;

  sentimentChartOption: any;
  echartsInstance: any;

  emotionClass: string;

  wrapperClassName: string = null;
  isGeneralIconShow = false;

  private calculatePercentage(n: number, total: number): number {
    return parseFloat(((n / total) * 100).toFixed(1));
  }

  private getLargestPortionName(positive: number, neutral: number, negative: number): string {
    let max = positive;
    let result = 'positive';
    if (neutral > max) {
      max = neutral;
      result = 'neutral';
    }
    if (negative > max) {
      result = 'negative';
    }

    return result;
  }

  ngOnInit(): void {
    this.positivePercentage = this.calculatePercentage(this.commentAnalysis.positiveCount, this.commentAnalysis.total);
    this.neutralPercentage = this.calculatePercentage(this.commentAnalysis.neutralCount, this.commentAnalysis.total);
    this.negativePercentage = this.calculatePercentage(this.commentAnalysis.negativeCount, this.commentAnalysis.total);

    this.emotionClass = this.getLargestPortionName(
      this.commentAnalysis.positiveCount,
      this.commentAnalysis.neutralCount,
      this.commentAnalysis.negativeCount,
    );

    this.emojiOnlyPercentage = this.calculatePercentage(
      this.commentAnalysis.emojiOnlyCount,
      this.commentAnalysis.total,
    );
    this.hasMentionPercentage = this.calculatePercentage(
      this.commentAnalysis.hasMentionCount,
      this.commentAnalysis.total,
    );
    this.sentimentChartOption = {
      tooltip: {
        trigger: 'item',
        formatter: '',
      },
      series: [
        {
          name: '',
          clockWise: true,
          hoverAnimation: false,
          type: 'pie',
          center: ['50%', '40%'],
          radius: ['90%', '100%'],
          color: ['#cccccc', '#dc3545', '#72d887'],
          data: [
            {
              value: this.neutralPercentage,
              name: 'neutral',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                hoverAnimation: false,
              },
            },
            {
              value: this.negativePercentage,
              name: 'negative',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                hoverAnimation: false,
              },
            },
            {
              value: this.positivePercentage,
              name: 'positive',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                hoverAnimation: false,
              },
            },
          ],
        },
      ],
    };
  }

  getWrapperClassName(): string {
    return this.wrapperClassName;
  }

  onChartInit(echarts) {
    this.echartsInstance = echarts;
  }
}
