import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { PrintService } from '../print-layout/print.service';
import { PROFILE_TOOLTIPS } from '../../../enums';
import {
  FacebookPrintableProfile,
  PagedCards,
  PrintableProfile,
  ReportPrintableProfile,
  TiktokPrintableProfile,
  YoutubePrintableProfile,
  InstagramPrintableProfile,
} from '../../../interfaces/print.interface';

@Component({
  selector: 'printable-profile',
  templateUrl: './printable-profile.component.html',
  styleUrls: ['./printable-profile.component.scss'],
})
export class PrintableProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  // printable profile states variables
  readonly ADULT_AUDIENCE_AGE = 21;
  printableProfile:
    | PrintableProfile
    | FacebookPrintableProfile
    | ReportPrintableProfile
    | TiktokPrintableProfile
    | YoutubePrintableProfile
    | InstagramPrintableProfile;
  tooltipMessages: Map<string, string>;
  selectedCards: string[] | PagedCards[];
  canPrint = true;

  constructor(private route: ActivatedRoute, private printService: PrintService, private router: Router) {
    this.printableProfile = this.printService.dataToPrint;
    this.tooltipMessages = new Map<string, string>();
    Object.keys(PROFILE_TOOLTIPS).forEach((key) => this.tooltipMessages.set(key, PROFILE_TOOLTIPS[key]));
    this.selectedCards = this.printService.selectedCardsCfg;
  }

  ngOnInit(): void {
    // TODO: specific logic for the feature +
    // Manage diff exceptions
    this.canPrint = !!this.printService.dataToPrint;
    if (!this.canPrint) {
      this.router.navigate(['404-not-found']).then(() => {
        throw new Error(
          '[ PRINT EXCEPTION ]: This error can occur when no data is supplied for export or when the print link has expired!',
        );
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.canPrint) {
      // if this charts is included ! [branded-content-distribution], awaiting api call before export
      if (this.printService.selectedCards.includes('branded-content-distribution')) {
        // unsubscribe will be in the parent component {ProfileComponent}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.printService.chartsFullyLoaded$.pipe(first()).subscribe((_) => {
          this.printService.onDataReady();
        });
      } else {
        setTimeout(() => {
          this.printService.onDataReady();
        }, 1000);
      }
    }
  }

  ngOnDestroy(): void {
    // popup message after printing done/closing the print window !
    // if (this.canPrint)
    //   this.printService.toast.info(`printing done !`);
  }
}
