<nb-layout windowMode>
  <nb-layout-column>

    <div class="subscription-success">
      <div class="subscription-success-container">
        <h4>Congratulations</h4>
        <img src="/assets/images/Illustration Payment Successful.svg" alt="success">

        <p *ngIf="trialDays > 0; else free">
          You have successfully started<br>your <span>{{trialDays}} day free trial</span> for the plan
        </p>

        <ng-template #free>
          <p>You have successfully chosen the free plan</p>
        </ng-template>

        <h4 class="stretched">{{subscriptionPlanName.replace('SHOPIFY-', '')}}</h4>

        <p>Your account setup is complete</p>

        <button class="btn btn-primary" (click)="getStart()">Get STARTED</button>

        <strong>You’ll be automatically taken to the home screen in <span>{{countDown}}s</span></strong>

      </div>
    </div>

  </nb-layout-column>
</nb-layout>
