<div class="modal-body">

  <div *ngIf="post.mediaType=='photo'" class="content-display" [style.height]="loaded ? 'auto' : '0px'">
    <div *ngIf="!post.hasExpiredThumbnail">
      <img [src]="post?.media.url" (load)="loaded = true; progress.ref().complete();"
        (error)="post.hasExpiredThumbnail = true;">
    </div>
    <div *ngIf="post.hasExpiredThumbnail" style="height: 100%; width: 100%">
      <iframe [src]="post.safePostUrl" style="border:none; overflow:hidden; width: 100%; height: 100%"
        allowTransparency="true" allow="encrypted-media"></iframe>
    </div>
  </div>
  <div *ngIf="post.mediaType=='video'" class="content-display video" [style.height]="loaded ? 'auto' : '0px'">
    <div *ngIf="!post.hasExpiredThumbnail">
      <i class="far fa-play"></i>
      <img [src]="post?.media.thumbnailUrl" (load)="loaded = true; progress.ref().complete();"
        (click)="openFBVideo(post?.media.url)" (error)="post.hasExpiredThumbnail = true;">
    </div>
    <div *ngIf="post.hasExpiredThumbnail" style="height: 100%; width: 100%">
      <iframe [src]="post.safePostUrl" style="border:none; overflow:hidden; width: 100%; height: 100%"
        allowTransparency="true" allow="encrypted-media"></iframe>
    </div>
  </div>
  <div *ngIf="post.mediaType=='shared_post' && (getSharedPostMediaType() === 'photo')" class="content-display"
    [style.height]="loaded ? 'auto' : '0px'">
    <div *ngIf="post.hasExpiredThumbnail">
      <img [src]="post.media.thumbnailUrl" (load)="loaded = true; progress.ref().complete();"
        (error)="post.hasExpiredThumbnail = true;">
    </div>
    <div *ngIf="post.hasExpiredThumbnail">
      <iframe [src]="post.safePostUrl" allowTransparency="true" allow="encrypted-media"></iframe>
    </div>
  </div>
  <div *ngIf="post.mediaType=='shared_post' && getSharedPostMediaType() ==='video'" class="content-display video"
    [style.height]="loaded ? 'auto' : '0px'">
    <div *ngIf="post.hasExpiredThumbnail">
      <i class="far fa-play"></i>
      <img [src]="post?.media.thumbnailUrl" (load)="loaded = true; progress.ref().complete();"
        (error)="post.hasExpiredThumbnail = true;" (click)="openFBVideo(post?.media.fbUrl)">
    </div>
    <div *ngIf="post.hasExpiredThumbnail">
      <iframe [src]="post.safePostUrl" style="border:none; overflow:hidden; width: 100%; height: 100%"
        allowTransparency="true" allow="encrypted-media"></iframe>
    </div>
  </div>


  <div class="content-information" [class.text-post]="!isMediaPost">
    <div class="content-header">
      <a href="/pages/facebook/profile/{{getEncodedUsername(profile?.username)}}" class="content-author"
        target="_blank">
        <nb-user [name]="profile?.name" [picture]="profile?.profilePictureUrl" [title]="profile?.username" size="large">
        </nb-user>
      </a>
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="content-body">
      <div class="content-stats">
        <div class="stat" *ngIf="!hideStats && post?.estimatedReach">
          <label>Est. Reach</label> {{post.estimatedReach | formatNumber:2}}
        </div>
        <div class="stat" *ngIf="!hideStats && post?.estimatedImpressions">
          <label>Est. Impressions</label> {{post.estimatedImpressions | formatNumber: 2}}
        </div>
        <div class="stat" *ngIf="!hideStats && post?.estimatedMediaValue">
          <label>EMV</label> {{post.estimatedMediaValue | currency:currency}}
        </div>
        <div class="h-space" *ngIf="!hideStats"></div>
        <div class="stat">
          <i class="far fa-calendar"></i>
          {{post?.postedOn | date: 'mediumDate'}}
        </div>
        <ng-container *ngIf="!hideStats && post?.statistics">
          <div *ngIf="post.statistics.commentsCount >= 0" class="stat">
            <i class="far fa-comment"></i>
            {{post.statistics.commentsCount | number}} Comments
          </div>
          <div *ngIf="post.statistics.sharesCount >= 0" class="stat">
            <i class="far fa-share"></i>
            {{post.statistics.sharesCount | number}} Shares
          </div>
          <div *ngIf="post.statistics.reactionsCount >= 0" class="stat">
            <i class="far fa-thumbs-up"></i>
            {{post.statistics.reactionsCount | number}} Reactions
          </div>
          <div *ngIf="post.statistics.reactions" class="stat-reactions" [class.text-post]="!isMediaPost">
            <div class="reaction">
              <i class="far fa-thumbs-up"></i>
              {{post.statistics.reactions.like | number}}
            </div>
            <div class="reaction">
              <i class="far fa-heart"></i>
              {{post.statistics.reactions.love | number}}
            </div>
            <div class="reaction">
              <i class="far fa-laugh-squint"></i>
              {{post.statistics.reactions.haha | number}}
            </div>
            <div class="reaction">
              <i class="far fa-surprise"></i>
              {{post.statistics.reactions.wow | number}}
            </div>
            <div class="reaction">
              <i class="far fa-thumbs-up"></i>
              {{post.statistics.reactions.sad | number}}
            </div>
            <div class="reaction">
              <i class="far fa-angry"></i>
              {{post.statistics.reactions.angry | number}}
            </div>
          </div>
          <div *ngIf="post?.mediaType == 'video' && post?.statistics?.viewsCount >=0 " class="stat">
            <i class="far fa-eye"></i>
            {{post?.statistics.viewsCount | number}} Views
          </div>
          <div class="stat" *ngIf="post?.sponsorship?.sponsored">
            <i class="far fa-usd"></i>
            This is a sponsored post
          </div>

        </ng-container>
      </div>
      <div *ngIf="post?.description" class="content-caption">
        {{post.description}}
      </div>
      <div *ngIf="!post?.description && getSharedPostMediaDescription()" class="content-caption">
        {{getSharedPostMediaDescription()}}
      </div>
      <div *ngIf="!post?.description && !getSharedPostMediaDescription()" class="content-caption not-found">
        No description found!
      </div>
      <div *ngIf="post.mediaType === 'article'" class="article-url">

        <i class="icon ion-ios-link"></i> <a [href]="getArticleUrl()">{{getArticleUrl()}}</a>
      </div>
    </div>
    <div class="content-footer">
      <a class="btn btn-xs btn-primary btn-block" [attr.href]="post.fbUrl" target="_blank">
        <i class="fab fa-facebook"></i> GO TO FACEBOOK
      </a>
    </div>
  </div>
</div>