import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { DropDownSize } from '../common.enum';
import { CdkOverlayOrigin, ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DropDownOverlayBaseComponent } from './base-component/drop-down-overlay-base.component';
import { OverlayComponent } from './overlay-component/overlay.component';
import { ButtonControlOptions } from '../common.interface';

@Component({
  selector: 'ai-dropdown',
  templateUrl: './dropdown.control.html',
  styleUrls: ['./dropdown.control.scss'],
})
export class DropdownControl extends DropDownOverlayBaseComponent {
  @Input() buttonOptions: ButtonControlOptions;
  @Input() type: 'sort' | 'split' | 'dropdown' | 'modern' = 'dropdown';
  @ViewChild('trigger', { static: true }) trigger: ElementRef;
  @ViewChild(CdkOverlayOrigin, { static: true }) origin: CdkOverlayOrigin;
  private isOverlayAttached = false;

  @HostBinding('class')
  get hostClass(): string {
    return this.buttonOptions?.disabled ? 'disabled' : 'enabled';
  }

  constructor(private overlay: Overlay) {
    super();
  }

  private positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  get prefix(): string {
    if (this.buttonOptions?.type) {
      return this.buttonOptions?.prefix;
    }
    return this.buttonOptions?.prefix || 'far fa-bars';
  }

  get suffix(): string {
    if (this.type == 'sort') {
      return this.isOverlayAttached ? `${this.buttonOptions.suffix}-up` : `${this.buttonOptions.suffix}-down`;
    }
    return this.buttonOptions?.suffix;
  }

  displayOverlay(): void {
    const itemLength = this.options.groupOptions.reduce((count, val) => {
      return (count += val.items.length);
    }, 0);
    if (itemLength === 0) return;
    this.initOverlayRef();
    this.isOverlayAttached = !this.isOverlayAttached;
    this.setupOverlayComponent();
    this.overlayRef.backdropClick().subscribe(() => {
      this.isOverlayAttached = false;
      this.overlayRef.detach();
    });
    this.overlayRef.detachments().subscribe(() => {
      this.isOverlayAttached = false;
    });
  }

  private setupOverlayComponent() {
    const component = new ComponentPortal(OverlayComponent);
    const componentRef = this.overlayRef.attach(component);
    componentRef.instance.options = this.options;
    componentRef.instance.action = this.action;
    componentRef.instance.multi = this.multi;
    componentRef.instance.closeOnSelect = this.closeOnSelect;
    componentRef.instance.overlayRef = this.overlayRef;
    componentRef.instance.size = this.size;
    componentRef.instance.stateless = this.stateless;
    componentRef.instance.emptyDisplay = this.emptyDisplay;
    componentRef.instance.customWidth =
      this.type === 'modern' ? document.getElementById('modern-drop-down').offsetWidth : this.customWidth;
  }

  private initOverlayRef() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.trigger.nativeElement)
      .withPositions(this.positions);
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy:
        this.type === 'modern' ? positionStrategy.withDefaultOffsetY(6) : positionStrategy.withViewportMargin(10),
    });
  }
}
