import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlAbstractDirective } from '../control';
import { AutoComplete } from '../control.enum';

@Component({
  selector: 'ai-input',
  templateUrl: './input.control.html',
  styleUrls: ['./input.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputControl),
      multi: true,
    },
  ],
})
export class InputControl extends ControlAbstractDirective implements OnInit {
  @Input() autoComplete: AutoComplete = 'off';
  @Input() telCode: string;
  @Input() spellCheck = false;
  @Input() readonly = false;
  @Input() size: 'small' | 'large' = 'large';
  @Input() set disable(value: boolean) {
    if (value && this.control.enabled) {
      this.control.disable();
    }
    if (!value && this.control.disabled) {
      this.control.enable();
    }
  }
  @Output() clear: EventEmitter<PointerEvent> = new EventEmitter();
  /**
   * @ignore
   */
  hide = false;

  /**
   * @ignore
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.hide = this.inputType === 'password';
  }
  clearClicked(event: PointerEvent) {
    this.control.setValue('');
    this.clear.emit(event);
  }
}
