import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IgEmbedService {
  public detectEmbed = new Subject<true>();
  private minDelay = 300;

  constructor() {
    this.detectEmbed.pipe(debounceTime(this.minDelay)).subscribe(this.detectEmbeds);
  }

  private detectEmbeds(): void {
    (window as any).instgrm.Embeds.process();
  }
}
