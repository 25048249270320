import { Component, Input } from '@angular/core';
import { PlatformsV2, VeraPlatforms } from '../../../enums';

@Component({
  selector: 'user-tile',
  templateUrl: './user-tile.component.html',
  styleUrls: ['./user-tile.component.scss'],
})
export class UserTileComponent {
  @Input() userPicture: string;
  @Input() name: string;
  @Input() description: string;
  @Input() isRegistered: false;
  @Input() badgeOverClass: string;
  @Input() badgeOver = 'smallBadgeOver2';
  @Input() platform: PlatformsV2;
  isVeraPlatform(): boolean {
    return this.platform.toLowerCase() === VeraPlatforms.instagram.toLowerCase();
  }
}
