import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { DropdownPanel } from './dropdown-panel';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { merge, Observable, Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[aiDropdownTriggerFor]',
  host: { '(click)': 'toggleDropdown()' },
})
export class DropdownTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input('aiDropdownTriggerFor') public dropdownPanel: DropdownPanel;
  @Input() closeOnClick = false;
  @Input() position: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: 6,
    },
  ];

  @Output() onOpen = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(this.position),
    });

    const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);
    this.onOpen.emit(this.overlayRef);
    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() => this.destroyDropdown());
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const closeEmitters = [backdropClick$, detachment$];
    if (this.closeOnClick) {
      const dropdownClosed$ = this.dropdownPanel.closed;
      closeEmitters.push(dropdownClosed$);
    }
    return merge(...closeEmitters);
  }

  destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
    this.onClose.emit();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
