<div class="modal-body">
  <div class="content-display" [ngClass]="content?.mediaType === 'carousel' ? 'carousel-media': 'video-media'">
    <ng-container *ngIf="content">
      <ngx-ig-embed class="instagram-media" [content]="content"></ngx-ig-embed>
    </ng-container>
    <ng-container [ngSwitch]="content?.mediaType">
      <i *ngSwitchCase="'video'" class="far fa-video media-type"></i>
      <i *ngSwitchCase="'photo'" class="far fa-camera media-type"></i>
      <i *ngSwitchCase="'carousel'" class="far fa-clone media-type"></i>
      <i *ngSwitchCase="'igtv'" class="far fa-tv media-type"></i>
    </ng-container>
  </div>
  <div class="content-information">
    <div class="content-header">
      <a [attr.href]="content?.author.isProfiled? '/pages/profile/'+content?.author.username : null" target="_blank"
        [ngClass]="{'content-author': true, 'content-profiled-author': content?.author.isProfiled, 'content-unprofiled-author' : !content?.author.isProfiled}">
        <nb-user [name]="content?.author.fullName" [picture]="content?.author.picture"
          [title]="content?.author.username" size="large">
          <img id="hidden_image" src="{{content?.author.picture}}" (error)="checkError(content?.author.picture)"
            style="display: none;" />
        </nb-user>
      </a>
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="content-body">
      <div class="content-stats">
        <div class="stat" *ngIf="inCampaign && content?.estimatedReach">
          <label>{{ content?.areInsightsVerified ? 'Reach' : 'Est. Reach' }}</label> {{content.estimatedReach |
          formatNumber:2}}
        </div>
        <div class="stat" *ngIf="inCampaign && content?.estimatedImpressions">
          <label>{{ content?.areInsightsVerified ? 'Impressions' : 'Est. Impressions' }}</label>
          {{content.estimatedImpressions | formatNumber: 2}}
        </div>
        <div class="stat" *ngIf="inCampaign && content?.estimatedMediaValue">
          <label>EMV</label> {{content.estimatedMediaValue | currency:currency}}
        </div>
        <div class="h-space" *ngIf="inCampaign"></div>
        <div class="stat">
          <i class="far fa-calendar"></i>
          {{content?.takenAt | ngxCapitalize}}
        </div>
        <div class="stat" *ngIf="inCampaign">
          <i class="far fa-heart"></i>
          {{content?.likeCount | formatNumber:0}} Likes
        </div>
        <div class="stat" *ngIf="inCampaign">
          <i class="far fa-comment"></i>
          {{content?.commentCount | formatNumber:0}} Comments
        </div>
        <div class="stat" *ngIf="inCampaign && !lodashIsNil(content?.viewCount)">
          <i class="far fa-eye"></i>
          {{content.viewCount | formatNumber:0}} Views
        </div>
        <div class="stat" *ngIf="content?.sponsored">
          <i class="far fa-usd"></i>
          This is a sponsored post
        </div>

      </div>
      <div class="content-list" *ngIf="content?.brands?.length > 0">
        <div class="content-list-header">Brands</div>
        <span *ngFor="let brand of content?.brands">
          @{{brand}}
        </span>
      </div>
      <div class="content-list" *ngIf="content?.influencers?.length > 0">
        <div class="content-list-header">Influencers</div>
        <span *ngFor="let influencer of content?.influencers">
          @{{influencer}}
        </span>
      </div>
      <div class="content-caption">{{content?.caption}}</div>
    </div>
    <div class="content-footer">
      <a class="btn btn-xs btn-primary btn-block" [attr.href]="content?.instagramUrl" target="_blank">
        <i class="fab fa-instagram"></i> GO TO INSTAGRAM
      </a>
    </div>
  </div>
</div>