<nb-card size="large">
  <nb-card-header>
    <span>
      Influencer Brand Affinity
    </span>
    <ngx-tooltip [tooltip]="tooltipMessages.get('influencer-brand-affinity')"></ngx-tooltip>
  </nb-card-header>

  <nb-card-body class="profile-brand-affinity-body with-popper" style="height: 513px;">
    <div *ngIf="!loading">
      <div class="list-utility-bar">
        <input type="text" name="keyword" placeholder="Filter keyword" class="form-control"
          (input)="onSearch($event.target.value)" [(ngModel)]="searchText" keyupDebounce />

        <ng-select dropdownPosition="bottom" [items]="sortOptions" [ngModel]="sortByField" [searchable]="false"
          [clearable]="false" (change)="onFilterChange($event)">
          <ng-template ng-label-tmp let-item="item">
            <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>
          </ng-template>
          <ng-template ng-footer-tmp>
            <nb-checkbox status="success" [(ngModel)]="showOnlySponsors" class="checkbox-input"
              (change)="onFilterChange()">
              Sponsors
            </nb-checkbox>
          </ng-template>
        </ng-select>
      </div>

      <div class="brands-list">
        <div class="brand" *ngFor="let brand of filteredInfluencerBrandAffinityWithMedia;">
          <ngx-brand-user (toggleBrand)="toggleBrand($event)" [brand]="brand"
            [sponsoredTooltip]="tooltipMessages.get('sponsored-post')"></ngx-brand-user>
        </div>
      </div>
    </div>

    <ngx-loader *ngIf="loading" class="profile-card-placeholder"></ngx-loader>

    <div *ngIf="!loading && !influencerBrandAffinityWithMedia?.length" class="empty-list-placeholder">
      Influencer brand affinity not found for {{influencerUsername}}
    </div>
  </nb-card-body>
</nb-card>