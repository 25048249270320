<div class="profile-card">
  <ng-container *ngTemplateOutlet="hyperlinkCard"></ng-container>
  <ng-template #hyperlinkCard>
    <a (click)="hyperlinkCardClicked()" [class.disabled]="disableProfileNavigation">
      <ng-container *ngTemplateOutlet="profileCard"></ng-container>
    </a>
  </ng-template>
  <ng-template #profileCard>
    <ng-container *ngIf="!loadProfileImage">
      <img [src]="profilePictureUrl" (error)='handleProfileCoverImgError($event)' style="display: none;" />

      <ng-container *ngIf="islegacyTileLegacy; else modernTile">
        <nb-user *ngIf="!profileInfo?.isRegistered" [name]="profileInfo?.name" [picture]="profilePictureUrl"
          [title]="getUserTitle" [size]="profilePictureSize">
        </nb-user>
      </ng-container>

      <ng-template #modernTile>
        <ngx-user-tile *ngIf="!profileInfo?.isRegistered" type="small" [picture]="profilePictureUrl"
          [name]="profileInfo?.name" [title]="getUserTitle" [platform]="profileInfo.platform"
          [badgeSize]="profilePictureSize" [isRegistered]="profileInfo?.isRegistered"></ngx-user-tile>
      </ng-template>
    </ng-container>
    <ngx-user-badge *ngIf="profileInfo?.isRegistered" imgSrc="assets/images/verified-badge.png" [title]="getUserTitle"
      showName="true" [name]="profileInfo?.name" [picture]="profilePictureUrl" [size]="profilePictureSize"
      tooltip="Affable has verified the authenticity of this influencer and their statistics.">
    </ngx-user-badge>
  </ng-template>
</div>

<ng-template #fullProfileModal let-modal>
  <div>
    <div class="modal-header">
      <span>{{title}}</span>
      <button class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="profile-info">
        <nb-user [name]="profileInfo?.name" [picture]="profileInfo?.profilePictureUrl" [size]="'large'">
        </nb-user>

        <ng-container
          *ngTemplateOutlet="content; context:{icon:getGenderIcon(profile.gender),value:profile.gender,type:'title'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="content; context:{icon:'fa-cake-candles',value:profile.birthdate,type:'date'}"></ng-container>
        <hr *ngIf="profile.gender || profile.birthdate">
        <ng-container
          *ngTemplateOutlet="content; context:{icon:'fa-envelope',value:profile.email,type:'text'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="content; context:{icon:'fa-phone',value:profile.phone,type:'text'}"></ng-container>

        <div class="d-flex mb-3" *ngIf="profile.shippingAddress?.address">
          <div class="mx-1 ml-0 icon"><i class="far fa-location-dot"></i></div>
          <div class="shippingAddress">
            #{{profile.shippingAddress.address}}<br>
            {{profile.shippingAddress.city}}<br>
            {{profile.shippingAddress.country}} <br>
            P.O.Box - {{profile.shippingAddress.zip}}
          </div>
        </div>
        <hr>
        <ng-container
          *ngTemplateOutlet="social;context:{username:socialMediaInfo?.instagramUsername,platform:'instagram'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="social;context:{username:socialMediaInfo?.facebookUsername,platform:'facebook'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="social;context:{username:socialMediaInfo?.youtubeUsername,platform:'youtube'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="social;context:{username:socialMediaInfo?.tiktokUsername,platform:'tiktok'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="social;context:{username:socialMediaInfo?.twitterUsername,platform:'twitter'}"></ng-container>

        <hr class="mt-2" *ngIf="profile.paypalEmail">
        <ng-container
          *ngTemplateOutlet="content; context:{icon:'fab fa-paypal',value:profile.paypalEmail,type:'text'}"></ng-container>

        <div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #content let-icon="icon" let-data="value" let-type="type">
  <div class="d-flex icon mb-3" *ngIf="data">
    <div class="mx-1 "><strong><i class="far" [ngClass]="icon"></i></strong></div>
    <div *ngIf="type === 'title'" class="ml-2">{{data | titlecase}}</div>
    <div *ngIf="type === 'date'" class="ml-2">{{data | date:'MMMM dd, yyyy'}}</div>
    <div *ngIf="type === 'text'" class="ml-2">{{data}}</div>
  </div>
</ng-template>

<ng-template #social let-username="username" let-platform="platform">
  <span *ngIf="username" class="platform-link" (click)="navigateToProfile(username, platform)">
    <img [src]="getPlatformImage(platform)">
    <span class="username af-link-text">{{username}}</span>
  </span>
</ng-template>