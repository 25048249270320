import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone } from 'lodash';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  Interest,
  YoutubeInfluencerLookup,
  YoutubeInfluencerProfile,
  YoutubeInfluencerProfileAPIResponse,
  YoutubeInfluencersSearchResult,
} from '../../../interfaces';
import { YoutubeInfluencerSearchFilters } from '../../../pages/youtube-discovery/youtube-influencers-filter.service';

@Injectable()
export class YoutubeService {
  interestsList: Interest[];

  constructor(private http: HttpClient) {}

  getTopInfluencers(location: string): Promise<YoutubeInfluencersSearchResult> {
    return this.http
      .get<YoutubeInfluencersSearchResult>(environment.api + `/api/youtube/top`, {
        params: { location },
      })
      .toPromise();
  }

  getInfluencers(searchFilters: YoutubeInfluencerSearchFilters, page: number): Promise<YoutubeInfluencersSearchResult> {
    const influencerFilters = clone(searchFilters.influencerFilters);
    if (influencerFilters.username) {
      influencerFilters.username = influencerFilters.username.trim();
    }
    return this.http
      .post(
        `${environment.api}/api/youtube/`,
        {
          influencerFilters: influencerFilters,
          audienceFilters: searchFilters.audienceFilters,
        },
        {
          params: {
            page: page.toString(),
            sortKey: searchFilters.sortKey,
          },
        },
      )
      .toPromise()
      .then(async (json: YoutubeInfluencersSearchResult) => json);
  }

  lookupInfluencers(term: string): Observable<YoutubeInfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    return this.http.get<YoutubeInfluencerLookup[]>(`${environment.api}/api/youtube`, {
      params: { term: term.trim() },
    });
  }

  getYoutubeProfile(username: string): Promise<YoutubeInfluencerProfile> {
    return this.http
      .get(environment.api + `/api/youtube/${username}`)
      .toPromise()
      .then((json: YoutubeInfluencerProfileAPIResponse) => {
        if (json) {
          return json.profile;
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  getInterests(): Promise<Interest[]> {
    if (this.interestsList) {
      return Promise.resolve(this.interestsList);
    }
    return this.http
      .get<Interest[]>(`${environment.api}/api/youtube/interests/all`)
      .toPromise()
      .then((response) => {
        this.interestsList = response;
        return response;
      });
  }

  exportInfluencersFromSearch(
    usernames: string[],
    campaignSlugName?: string,
    groupSlugName?: string,
    allUsers = false,
    context: 'INVITATION' | 'PROPOSAL' = 'INVITATION',
  ): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/youtube/export/search`,
        {
          usernames,
          context,
          ...(campaignSlugName && { campaignSlugName }),
        },
        {
          responseType: 'blob',
          params: {
            ...(groupSlugName && { groupSlugName }),
            allUsers: allUsers.toString(),
          },
        },
      )
      .toPromise();
  }

  exportInfluencerProfile(influencerUsername: string): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/youtube/export/profile`,
        {
          username: influencerUsername,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }

  profileInfluencersList(
    usernames: string[],
    groupName: string | undefined,
    groupSlugName: string | undefined,
  ): Promise<any> {
    return this.http
      .post(`${environment.api}/api/jobs/youtube/influencers/profileList`, {
        usernames: usernames,
        groupName: groupName,
        groupSlugName: groupSlugName,
      })
      .toPromise()
      .then((response: any) => response);
  }

  getAudiencePercentageByAge(
    username: string,
    minAge: number,
    maxAge?: number,
  ): Promise<{
    count: number;
    percentage: number;
  }> {
    let url = environment.api + `/api/youtube/${username}/stats/audience/count/age?min_age=${minAge}`;
    if (maxAge) {
      url = `${url}&max_age=${maxAge}`;
    }
    return this.http
      .get(url)
      .toPromise()
      .catch((err) => {
        console.log(`[Youtube] Error while geting audience count by age range for ${username}: ${minAge} to ${maxAge}`);
        return err;
      });
  }

  fetchInfluencerEmail(influencerChannel: string): Promise<any> {
    return this.http
      .post<void>(`${environment.api}/api/jobs/youtube/influencer/fetchEmail/${influencerChannel}`, {})
      .toPromise();
  }
}
