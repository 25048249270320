import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { merge } from 'lodash';
import { PrintService } from '../../pages/print/print-layout/print.service';
import { BaseEchartsPrintedComponent } from './echarts-printed.component';
import { Platforms, PlatformsV2, VeraPlatforms } from '../../enums';

@Component({
  selector: 'ngx-echarts-area-stack',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss'],
})
export class EchartsAreaStackComponent extends BaseEchartsPrintedComponent implements AfterViewInit, OnDestroy {
  options: any = {};
  themeSubscription: any;

  @Input() dataOptions: any = {};
  @Input() openNewTabURL: boolean;
  @Input() platform?: PlatformsV2;
  printedChart: boolean;

  constructor(private theme: NbThemeService, private print: PrintService) {
    super();
    this.printedChart = print.isPrinting;
  }

  ngAfterViewInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      const echarts: any = config.variables.echarts;

      this.options = merge(
        {
          backgroundColor: echarts.bg,
          color: [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight],
          tooltip: {
            trigger: 'item',
            formatter: '{a} {b} : {c}',
          },
          legend: {
            data: ['Instagram Followers'],
            textStyle: {
              color: echarts.textColor,
            },
          },
          grid: {
            left: '5%',
            right: '4%',
            bottom: '10%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              scale: true,
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              splitLine: {
                lineStyle: {
                  color: echarts.splitLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
              },
            },
          ],
        },
        this.dataOptions,
      );
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  onChartEvent(event: any) {
    let url = undefined;
    switch (this.platform) {
      case Platforms.youtube:
        url = event.value[5];
        break;
      case Platforms.instagram:
      case VeraPlatforms.instagram:
        url = event.value[3].split('media')[0];
        break;
      case Platforms.facebook:
        url = event.value[3];
        break;
      case Platforms.tiktok:
        url = event.value[6];
        break;
      case Platforms.twitter:
        url = event.value[3];
        break;
    }
    if (this.openNewTabURL && url) {
      try {
        window.open(url, '_blank');
      } catch {
        return;
      }
    }
  }
}
