import { Component, ElementRef, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupInfo } from '../../../@core/data/group.service';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../@core/feature-flag/flags';

@Component({
  selector: 'edit-group-modal',
  templateUrl: './edit-group-modal.component.html',
  styleUrls: ['./edit-group-modal.component.scss'],
})
export class EditGroupModalComponent implements OnInit {
  @Input() group: GroupInfo;

  @ViewChildren('groupTagSelect') groupTagSelect: any[];

  groupName: string;
  groupTags?: string[] = [];
  isAgencyBrandGroupEnabled = false;

  @ViewChild('groupnameinput', { static: true }) groupNameInput: ElementRef;

  constructor(private activeModal: NgbActiveModal, private featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this.groupName = this.group.name;
    this.groupTags = this.group.tags;
    setTimeout(() => this.groupNameInput?.nativeElement.focus(), 0);
    this.featureFlagService
      .isFeatureReleased(RELEASE_FLAGS.AGENCY_X_BRAND_GROUP)
      .then((result) => (this.isAgencyBrandGroupEnabled = result));
  }

  EditGroupName(): void {
    this.activeModal.close({
      groupName: this.groupName,
      groupTags: this.groupTags,
    });
  }

  groupTagKeyDown(event): void {
    if (event.keyCode === 32 || event.keyCode === 13) {
      // * `Space` or `Enter` keys pressed
      this.groupTagSelect.forEach((element) => {
        const tag = element['searchTerm']?.trim();
        if (tag) {
          this.groupTags = (this.groupTags || []).concat(tag).slice(0, 5);
          element['searchTerm'] = '';
        }
      });
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
