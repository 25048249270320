import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { PlatformsUpperEnum } from '../../../enums';
import { InfluencerCount } from '../../../@core/data/group.service';
import { environment } from '../../../../environments/environment';
import { first } from 'lodash';

export interface CollabGroupInfluencersRes {
  id: string;
  username: string;
  followersCount: number;
  engagementRate: number;
  countries?: string[];
  latestNote?: string;
  notesCount?: number;
  notesCreators?: {
    creatorId: string;
    creatorName: string;
    creatorPicture?: string;
  }[];
  teamName?: string;
  orgName?: string;
}
@Injectable({ providedIn: 'root' })
export class CollabsGroupInfoService {
  onStatusChangeSubject = new Subject<'PENDING' | 'APPROVED' | 'REJECTED'>();
  onStatusChange = this.onStatusChangeSubject.asObservable();

  pendingInfluencerCount = 0;
  approvedInfluencerCount = 0;
  rejectedInfluencerCount = 0;

  constructor(private _http: HttpClient) {}

  resetCounts(): void {
    this.pendingInfluencerCount = 0;
    this.approvedInfluencerCount = 0;
    this.rejectedInfluencerCount = 0;
  }

  updateCount(status: 'PENDING' | 'APPROVED' | 'REJECTED', count: number): void {
    switch (status) {
      case 'PENDING':
        this.pendingInfluencerCount = count;
        break;
      case 'APPROVED':
        this.approvedInfluencerCount = count;
        break;
      case 'REJECTED':
        this.rejectedInfluencerCount = count;
        break;
    }
  }

  async fetchGroupStatistics(
    slugName: string,
    platform: PlatformsUpperEnum,
    reviewedStatus: 'PENDING' | 'APPROVED' | 'REJECTED',
  ): Promise<InfluencerCount> {
    const stats = await this._http
      .post<InfluencerCount>(environment.api + `/api/collabs/groups/${slugName}/statistics`, {
        reviewedStatus,
        platform,
      })
      .toPromise();

    this.updateCount(reviewedStatus, stats.totalInfluencers);

    return stats;
  }

  async fetchGroupInfluencers(
    slugName: string,
    platform: string,
    params: Record<string, any>,
  ): Promise<{ data: CollabGroupInfluencersRes[]; totalCount: number }> {
    return this._http
      .get<{ data: CollabGroupInfluencersRes[]; totalCount: number }>(
        `${environment.api}/api/collabs/groups/${slugName}/influencers/${platform}`,
        {
          params,
        },
      )
      .toPromise();
  }

  async updateGroupInfluencerStatus(
    slugName: string,
    influencers: { influencerPk: string; status: 'APPROVED' | 'REJECTED' }[],
  ): Promise<{ message: string }> {
    const result = await this._http
      .patch<{ message: string }>(`${environment.api}/api/collabs/groups/${slugName}/review-influencers`, {
        influencers,
      })
      .toPromise();

    this.onStatusChangeSubject.next(first(influencers)?.status);
    return result;
  }
}
