import { Component, Input, OnInit } from '@angular/core';
import { ResourceType } from '../../@core/data/resource-access-control.service';
import { CollaborationInfluencerService } from '../../collaborator/influencer-profile-page/collaboration-influencer.service';

@Component({
  selector: 'ngx-profile-branded-content-distribution',
  templateUrl: './profile-branded-content-distribution.component.html',
  styleUrls: ['./profile-branded-content-distribution.component.scss'],
})
export class ProfileBrandedContentDistributionComponent implements OnInit {
  filterOptions: { label: string; value: number }[] = [
    { label: 'Last Week', value: 7 },
    { label: 'Last Month', value: 30 },
    { label: 'Last 3 Months', value: 90 },
  ];
  filterKey = this.filterOptions[2];
  brandedContentPercentage: number;
  totalContents: number;
  chartData: any;
  loading = true;

  @Input() resourceId: string;
  @Input() resourceType: ResourceType;
  @Input() influencerUsername: string;
  @Input() tooltipMessages: Map<string, string>;

  constructor(private collaborationInfluencerService: CollaborationInfluencerService) {}

  ngOnInit(): void {
    this.fetchBrandedContentDistribution();
  }

  async fetchBrandedContentDistribution(): Promise<void> {
    this.loading = true;

    const influencerBrandedContentDistribution =
      await this.collaborationInfluencerService.fetchInfluencerBrandedContentDistribution(
        this.influencerUsername,
        this.resourceId,
        this.resourceType,
        this.filterKey.value, // * Days
      );

    this.brandedContentPercentage = (influencerBrandedContentDistribution?.branded ?? 0) * 100;
    this.totalContents = influencerBrandedContentDistribution?.total ?? 0;
    this.setChartData();

    this.loading = false;
  }

  setChartData(): void {
    if (!this.brandedContentPercentage) {
      this.chartData = undefined;
      return;
    }

    this.chartData = {
      tooltip: {
        trigger: 'item',
        formatter: '',
      },
      series: [
        {
          name: ' ',
          clockWise: true,
          hoverAnimation: false,
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['70%', '90%'],
          data: [
            {
              value: 100 - this.brandedContentPercentage,
              name: ' ',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                  textStyle: {
                    fontSize: '22',
                    fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                    fontWeight: '600',
                    color: '#2a2a2a',
                  },
                },
              },
              tooltip: { show: false },
              itemStyle: {
                normal: {
                  color: {
                    colorStops: [
                      { offset: 0, color: '#8defbb' },
                      { offset: 1, color: '#8defbb' },
                    ],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    type: 'linear',
                    global: false,
                  },
                  shadowColor: 'rgba(0, 0, 0, 0)',
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowOffsetY: 3,
                },
              },
              hoverAnimation: false,
            },
            {
              value: this.brandedContentPercentage,
              name: ' ',
              tooltip: { show: false },
              label: { normal: { position: 'inner' } },
              itemStyle: { normal: { color: '#ebeff5' } },
            },
          ],
        },
        {
          name: ' ',
          clockWise: true,
          hoverAnimation: false,
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['60%', '97%'],
          data: [
            {
              value: 100 - this.brandedContentPercentage,
              name: ' ',
              label: {
                normal: {
                  position: 'center',
                  formatter: '',
                  textStyle: {
                    fontSize: '22',
                    fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                    fontWeight: '600',
                    color: '#2a2a2a',
                  },
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                normal: {
                  color: {
                    colorStops: [],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    type: 'linear',
                    global: false,
                  },
                },
              },
              hoverAnimation: false,
            },
            {
              value: this.brandedContentPercentage,
              name: ' ',
              tooltip: {
                show: false,
              },
              label: {
                normal: {
                  position: 'inner',
                },
              },
              itemStyle: {
                normal: {
                  color: {
                    colorStops: [
                      {
                        offset: 0,
                        color: '#ff894a',
                      },
                      {
                        offset: 1,
                        color: '#ffcc10',
                      },
                    ],
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    type: 'linear',
                    global: false,
                  },
                  shadowColor: 'rgba(0, 0, 0, 0)',
                  shadowBlur: 0,
                  shadowOffsetX: '0',
                  shadowOffsetY: '0',
                },
              },
            },
          ],
        },
      ],
    };
  }

  filterKeyChangeHandler(selectedFilter: { label: string; value: number }): void {
    this.filterKey = selectedFilter;
    this.fetchBrandedContentDistribution();
  }
}
