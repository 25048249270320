<div class="d-flex user-title ">
  <ngx-user-badge *ngIf="isRegistered && isVeraPlatform" imgSrc="assets/images/verified-badge.png" size="small"
                  showName="false" [picture]="userPicture"
                  tooltip="Affable has verified the authenticity of this influencer and their statistics." [badgeOverClass]='badgeOverClass'>
  </ngx-user-badge>
  <div *ngIf='!isRegistered || !isVeraPlatform'>
    <div class="user-picture" style="background-image: url({{userPicture}});"></div>
  </div>
  <div class="text-left">
    <div class="influencerName" [innerHTML]="name"></div>
    <div class="message" [innerHTML]="description"></div>
  </div>
</div>
