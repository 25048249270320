<div class="aiControl">
  <div class="toggle-switch"  [ngClass]="{'disabled': disabled === true}">
    <input
      #aiControl
      [id]="'cb-' + key"
      type="checkbox"
      [checked]="checked"
      (change)="handleChange($event)"
      [disabled]="disabled"
    />
      <label [for]="'cb-' + key"></label>
  </div>
</div>
