<div class="aiControl">
  <label class="sub-heading-h2" *ngIf="label" [innerHTML]="label" [ngClass]="{'required-field': required}">
  </label>
  <ngx-intl-tel-input #aiControl (focus)="focused$.next(true)" (blur)="focused$.next(false)" [formControl]="control"
    [enablePlaceholder]="enablePlaceholder" [searchCountryFlag]="searchCountryFlag" [maxLength]="maxLength"
    [phoneValidation]="phoneValidation" name="phone" [separateDialCode]="separateDialCode"
    [selectFirstCountry]="selectFirstCountry" [selectedCountryISO]="selectedCountryISO">
  </ngx-intl-tel-input>
  <div *ngIf="hint || error" class="body-4 mt-2 d-flex">
    <ng-container *ngIf="control.touched && error && !focused && !hideError; else hintTemplate">
      <span *ngIf="!hideError" [innerHTML]="error" class="error text-left"></span>
    </ng-container>
    <ng-template #hintTemplate>
      <span *ngIf="hint" [innerHTML]="hint" class="hint text-left"></span>
    </ng-template>
  </div>
</div>
