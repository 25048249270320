import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentSortKey, InfluencerSortKey, LastestOldestKey } from '../../../enums';
import { ContentSortOption, InfluencerSortOption, LatestOldestSortOption, SortOption } from '../../../interfaces';
import { DropDownOption, GroupItem } from '../../../../../../controls/src';

@Component({
  selector: 'ngx-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss'],
})
export class SortFilterComponent implements OnInit {
  @Input() sortKey: InfluencerSortKey | ContentSortKey | LastestOldestKey;
  @Input() sortOptions: InfluencerSortOption[] | ContentSortOption[] | LatestOldestSortOption[] | SortOption[];
  @Input() disabled = false;
  @Input() activeSort: string;
  @Output() sortFilterChangedEvent: EventEmitter<InfluencerSortKey | ContentSortKey | LastestOldestKey> =
    new EventEmitter();
  @Input() label = 'Sort By:';

  public dropDownOptions: DropDownOption;
  private mapKeys = {};

  ngOnInit() {
    const items: GroupItem[] = [];
    this.sortOptions?.forEach((item: InfluencerSortOption | ContentSortOption | LatestOldestSortOption, index) => {
      if (item.value === this.sortKey) {
        items.push({
          key: item.label,
          value: true,
          disabled: true,
        });
        this.activeSort = item.label;
      } else {
        items.push({
          key: item.label,
          value: false,
        });
      }
      return (this.mapKeys[item.label] = item.value);
    });

    this.dropDownOptions = {
      groupOptions: [
        {
          items,
          selector: 'checkmark',
        },
      ],
    };
  }

  sortFilterChanged(event): void {
    this.disabled = true;
    this.sortKey = this.mapKeys[event.lastAction.key];
    this.activeSort = event.lastAction.key;
    this.dropDownOptions.groupOptions = event.groupOptions;
    this.sortFilterChangedEvent.emit(this.sortKey);
  }

  updateSortFilter(sortKey: InfluencerSortKey | ContentSortKey | LastestOldestKey): void {
    this.sortKey = sortKey;
    this.activeSort = this.sortOptions.find((item) => item.value === sortKey)?.label ?? this.sortOptions[0].label;
    this.disabled = false;
    this.dropDownOptions.groupOptions[0].items.forEach((item) => {
      item.value = item.key === this.activeSort;
      item.disabled = item.key === this.activeSort;
    });
  }
}
