<div class="d-flex user-title {{type}}" >
  <div *ngIf='isVeraInstagram() && isRegistered'>
    <ngx-user-badge *ngIf="isRegistered" imgSrc="assets/images/verified-badge.png" size={{badgeSize}} showName="false"
      [picture]="picture" tooltip="Affable has verified the authenticity of this influencer and their statistics.">
    </ngx-user-badge>
  </div>
  <div *ngIf='!isVeraInstagram() || !isRegistered'>
    <img class="user-picture" src="{{picture}}" loading="lazy" />
  </div>
  <div class="text-left">
    <div class="influencerName sub-heading-h1" [innerHTML]="name"></div>
    <div class="caption" [innerHTML]="title"></div>
  </div>
</div>