<div class="modal-header">
  <span>Login Failed</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="errorCode == 'blocked'">
   Your account has been deactivated. Please contact
   <a href="mailto:dev@affable.ai">our team</a> for assistance.
</div>
<div class="modal-body" *ngIf="errorCode == 'invalid-creds'">
  Your email address or password is incorrect.
</div>
<div class="modal-body" *ngIf="errorCode == 'verification-pending'">
  Please verify your email before logging in. Link sent to your email.
</div>
<div class="modal-body" *ngIf="errorCode == 'unknown'">
  Sorry, an unexpected error occurred. Please try again later.
</div>
<div class="modal-footer">
  <button class="btn btn-md btn-primary" (click)="closeModal()">OK</button>
</div>
