import { AutoWidthDirective } from './../../fluence/src/app/shared/directives/auto-width.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { InputControl } from './input/input.control';
import { NumInputControl } from './num-input/num-input.control';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxControl } from './checkbox/checkbox.control';
import { ButtonControl } from './button/button.control';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import TabComponent from './tabs/tab/tab.control';
import TabsetComponent from './tabs/tabs.control';
import { TextareaControl } from './textarea/textarea.control';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TelInputControl } from './tel-input/tel-input.control';
import { SelectControl } from './select/select.control';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { CkeditorControl } from './ckeditor/ckeditor.control';
import { OverlayModule } from '@angular/cdk/overlay';
import { DropdownControl, DropdownItemControl, OverlayComponent, SearchDropdownControl } from './dropdown';
import { UploadStatusService } from './ckeditor/upload-status.service';
import { CardControl } from './card/card.control';
import { CardBodyControl } from './card/card-body/card-body.control';
import { ToggleSwitchControl } from './toggle-switch/toggle-switch.control';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { TempplateDropdownComponent } from './template-dropdown/template-dropdown.component';
import { DropdownTriggerForDirective } from './template-dropdown/trigger.directive';
import { AiTrimDirective } from './directives/trim.directive';
import { TableControl } from './table/table.control';
import { TableCellControl } from './table/table-cell/table-cell.control';
import { NumberQuantityPipe } from './pipes/quantity.pipe';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableCustomizerControl } from './table/table-customizer/table-customizer.control';
import { CallbackPipe } from './pipes/callback.pipe';
import { TooltipControl } from './tooltip/tooltip.control';

const declareAndExport = [
  InputControl,
  NumInputControl,
  CheckboxControl,
  TelInputControl,
  TextareaControl,
  ButtonControl,
  TabComponent,
  TabsetComponent,
  SelectControl,
  TableControl,
  TableCustomizerControl,
  TableCellControl,
  CkeditorControl,
  OverlayComponent,
  VideoPlayerComponent,
  DropdownControl,
  DropdownItemControl,
  ToggleSwitchControl,
  CardControl,
  CardBodyControl,
  SearchDropdownControl,
  TempplateDropdownComponent,
  DropdownTriggerForDirective,
  NumberQuantityPipe,
  CallbackPipe,
  TooltipControl,
];
const importAndExport = [
  FormsModule,
  ReactiveFormsModule,
  NgxIntlTelInputModule,
  NgSelectModule,
  NgOptionHighlightModule,
  CKEditorModule,
  OverlayModule,
  PortalModule,
  CdkTableModule,
  DragDropModule,
];

const directives = [AutoWidthDirective, AiTrimDirective];

@NgModule({
  declarations: [...declareAndExport, VideoPlayerComponent, ...directives],
  imports: [CommonModule, ...importAndExport],
  exports: [...importAndExport, ...declareAndExport],
  providers: [UploadStatusService],
})
export class ControlsModule {}
