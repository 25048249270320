import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import utils from '../../../@core/utils/utils';
import { Platforms, VeraPlatforms } from '../../../enums';
import { InfluencerLookup } from '../../../interfaces';

@Component({
  selector: 'ngx-similar-influencers',
  templateUrl: './similar-influencers.component.html',
  styleUrls: ['./similar-influencers.component.scss'],
})
export class SimilarInfluencersComponent {
  @Input() similarInfluencerList: InfluencerLookup[];
  @Input() tooltip: string;
  @Input() platform: string;

  next = false;

  goToSimilarInfluencer(username: string) {
    if (Platforms.facebook === this.platform) {
      window.open('/pages/facebook/profile/' + username, '_blank');
    } else if (VeraPlatforms.instagram === this.platform) {
      window.open('/pages/veraInstagram/profile/' + username, '_blank');
    } else {
      window.open('/pages/profile/' + username, '_blank');
    }
  }

  /**
   * If unable to load profile image, get downloadable image from fluence api
   */
  checkError(imgUrl: string, index: number) {
    if (imgUrl && Platforms.instagram === this.platform) {
      const url = new URL(imgUrl);
      if (imgUrl && url.origin !== environment.api && this.similarInfluencerList[index].picture) {
        this.similarInfluencerList[index].picture = utils.getInstagramCDNUrl(imgUrl);
      }
    }
  }
}
