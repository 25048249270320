import { AudienceStatistics } from './social-profile.interface';
import { SearchModifiers } from '../@core/data/content-filter.service';
import { GroupInfo } from '../@core/data/group.service';
import { FollowersGrowth } from '../@core/data/influencer-filter.service';
import { Location } from '../@core/data/location.service';
import { Followers, Gender } from '../@core/data/tiktok/tiktok.service';
import { AgeGroup } from '../enums';
import { InfluencerWithLogic } from './content.interface';

export interface OembedTiktokContent {
  author_name: string;
  author_url: string;
  height: string;
  html: string;
  provider_name: string;
  provider_url: string;
  thumbnail_height: number;
  thumbnail_url: string;
  thumbnail_width: number;
  title: string;
  type: string;
  version: string;
  width: string;
}

// Content result types
export interface TiktokContentSearchResult {
  total: number;
  content: TiktokVideo[];
}

export interface TiktokVideoURL {
  urls: string;
}

// Lookup tiktok influencer
export interface TiktokBasicInfluencerInfo {
  username: string;
  name: string;
  profilePictureUrl: string;
  picture?: string;
}

// Tiktok profile types
export interface TiktokInfluencerProfile {
  influencer: TiktokProfile;
  videos: TiktokVideo[];
  engagementTimelineData: TiktokMediaEngagementStat[];
  followerTimelineData: TiktokUserFollowersTimeline[];
  audienceStatistics: AudienceStatistics;
  engagement: TiktokEngagement;
  audienceLocation: AudienceLocation[];
  fakeFollowersRatio: number;
}

interface AudienceLocation {
  code: string;
  count: number;
}

export interface TiktokProfile {
  id: string;
  name: string;
  biography: string;
  email: string;
  username: string;
  countries: string[];
  profilePictureUrl: string;
  profileStatistics: ProfileStatistics;
}

export interface TiktokVideo {
  id: string;
  description: string;
  createdAt: string;
  statistics: VideoStatistics;
  video: Video;
  hashtags: string[];
  taggedUsers: string[];
  author: TiktokBasicInfluencerInfo;
  checked?: boolean;
  estimatedReach: number;
  estimatedImpressions: number;
  estimatedMediaValue: number;
  missing?: boolean;
  currency?: string;
}

export interface Video {
  id: string;
  cover: string;
  downloadUrl: string;
}

export interface TiktokEngagement {
  avgDiggsRatio: number;
  avgSharesRatio: number;
  avgCommentsRatio: number;
  avgPlaysRatio: number;
}

interface ProfileStatistics {
  diggCount: number;
  followingCount: number;
  followerCount: number;
  videoCount: number;
  heartCount: number;
  heart: number;
}

interface VideoStatistics {
  diggCount: number;
  shareCount: number;
  commentCount: number;
  playCount: number;
}

export interface TiktokMediaEngagementStat {
  likeCount: number;
  commentCount: number;
  shareCount: number;
  playCount: number;
  takenAt: Date;
  urlThumbnail: string;
  id: string;
  username: string;
}

export interface TiktokUserFollowersTimeline {
  followers: number;
  date: Date;
}

// Tiktok Influencer Lookup
export interface TiktokInfluencerLookup {
  username: string;
  fullName: string;
  picture: string;
  email?: string;
  category?: string;
  editable?: boolean;
}

// Tiktok Influencer search types
export interface TiktokInfluencersSearchResult {
  total: number;
  influencers: TiktokInfluencer[];
}

export interface TiktokInfluencer {
  id?: string;
  username: string;
  name: string;
  profilePictureUrl: string;
  biography: string;
  subscribers: number;
  selected: boolean;
  engagementScore: number;
  email?: string;
}

export interface TiktokHashtagForm {
  hashtag: string;
  limit: number;
}

export class TiktokInfluencerFilters {
  username?: string = undefined;
  influencers?: InfluencerWithLogic[] = [];
  gender = new Gender();
  followers = new Followers();
  countries: Location[] = [];
  keywords: KeywordWithLogic[] = [];
  bioKeywords: KeywordWithLogic[] = [];
  searchModifiers: SearchModifiers = new SearchModifiers();
  searchGroup = false;
  groupName: string = undefined;
  groupNames: string[] = [];
  excludeGroupInfluencers? = false;
  followersGrowth: FollowersGrowth = new FollowersGrowth();
  ageGroups: AgeGroup[] = [];
  contactInfoAvailable: boolean = undefined;
}

// TODO eventually this will change to be the same as Instagram's implementation using CategoryInfo instead
export interface KeywordWithLogic {
  label: string;
  isIncluded: boolean;
}
export interface TiktokContentFilterInfluencerLookup extends TiktokInfluencerLookup {
  category: string;
  isIncluded: boolean;
}

export interface TiktokContentFilterInfluencerGroup extends GroupInfo {
  username?: any;
  category?: string;
  isIncluded?: boolean;
}
