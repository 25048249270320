import { Component, Input } from '@angular/core';
import { CommentAnalysisComponentAbstract } from './comment-analysis-abstract';

@Component({
  selector: 'ngx-report-media-comment-analysis',
  templateUrl: './comment-analysis.component.html',
  styleUrls: ['./report-media-comment-analysis.component.scss', './comment-analysis-shared.scss'],
})
export class ReportMediaCommentAnalysisComponent extends CommentAnalysisComponentAbstract {
  wrapperClassName = 'media-comment-analysis';
  @Input()
  printed: true;
  constructor() {
    super();
  }
}
