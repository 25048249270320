import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { TwitterService } from '../../../../@core/data/twitter/twitter.service';
import { AuthService } from '../../../../@core/auth/auth.service';
import { Campaign, CampaignsService } from '../../../../@core/data/campaigns.service';
import { FacebookService } from '../../../../@core/data/facebook/facebook.service';
import { TiktokService } from '../../../../@core/data/tiktok/tiktok.service';
import { YoutubeService } from '../../../../@core/data/youtube/youtube.service';
import { MailType, Platforms, PlatformsV2, VeraPlatforms } from '../../../../enums';
import { CampaignInfluencer } from '../../../../interfaces';
import { MessageModalComponent } from '../../../../shared/message-modal/message-modal.component';
import utils from '../../../../@core/utils/utils';
import { Subject } from 'rxjs';
import { InstagramDiscoveryService } from '../../../../@core/data/instagram/instagram-discovery.service';

export enum CampaignInfluencerActions {
  updatedProductGift = 'updatedProductGift',
  updatedProposal = 'updatedProposal',
}

@Injectable()
export class CampaignInfluencerService {
  private influencers: CampaignInfluencer[];
  constructor(
    private campaignsService: CampaignsService,
    private authService: AuthService,
    private modalService: NgbModal,
    private facebookService: FacebookService,
    private youtubeService: YoutubeService,
    private tiktokService: TiktokService,
    private toastrService: ToastrService,
    private twitterService: TwitterService,
    private instagramDiscoveryService: InstagramDiscoveryService,
  ) {}

  tabChangedSource = new Subject<{ action: CampaignInfluencerActions }>();

  getInfluencers_(): CampaignInfluencer[] {
    return this.influencers;
  }

  setInfluencers_(value: CampaignInfluencer[]) {
    this.influencers = value;
  }
  getSelectedInfluencers(influencers: CampaignInfluencer[]): CampaignInfluencer[] {
    return influencers.filter((influencer) => influencer.selected);
  }

  startChatWithInfluencers(campaignInfluencers: CampaignInfluencer[], mailType: MailType = MailType.default) {
    const selectedInfluencers = this.getSelectedInfluencers(campaignInfluencers);
    if (selectedInfluencers?.length > 0) {
      if (this.authService.isMessagingEnabled()) {
        const influencers = selectedInfluencers.map((influencer) => {
          // Accomodated for last minute email changes
          return { ...influencer.influencer, email: influencer.influencerMetadata.email };
        });
        this.campaignsService.sendInfluencersEmailEvent(influencers, mailType);
      } else {
        this.openMessagesNotEnabledModal();
      }
    }
  }

  openMessagesNotEnabledModal(): void {
    const messagesNotEnabledModal = this.modalService.open(MessageModalComponent, {
      size: 'lg',
      windowClass: '',
      centered: true,
      backdrop: 'static',
    });

    const modalContent = messagesNotEnabledModal.componentInstance as MessageModalComponent;
    modalContent.headerText = 'Messaging feature is available as an add on only for PRO users.';
    modalContent.contentText = 'Reach out to us to enable messaging and we can work it out :)';
    modalContent.imageURL = `../../../../assets/images/demo-messaging.gif`;
    modalContent.imageClass = 'messaging-demo';
    modalContent.buttonText = 'CONTACT US';
    messagesNotEnabledModal.result
      .then(async (res) => {
        if (res) {
          window.location.href = 'mailto:help@affable.ai?subject=Enabling Messaging Feature on Affable';
        }
      })
      .catch(() => ({}));
  }

  async exportInfluencers(
    campaign: Campaign,
    influencers: CampaignInfluencer[],
    platform: PlatformsV2,
    context: 'INVITATION' | 'PROPOSAL' = 'INVITATION',
  ): Promise<any> {
    const campaignSlug = campaign.campaignSlugName;
    const usernames = this.getSelectedInfluencers(influencers).map((influencer) => influencer.influencer.username);
    // TODO support Partnerize exports for FB, YT, TT
    switch (platform) {
      case Platforms.instagram:
        await this.campaignsService
          .exportCampaignInfluencers(campaignSlug, usernames, platform)
          .then((res) => this.handleExportSuccess(res, campaign.campaignName))
          .catch((err: HttpErrorResponse) => this.handleExportError(err));
        break;
      case Platforms.facebook:
        await this.facebookService
          .exportInfluencersFromSearch(usernames, campaignSlug)
          .then((res) => this.handleExportSuccess(res, campaign.campaignName))
          .catch((err: HttpErrorResponse) => this.handleExportError(err));
        break;
      case Platforms.youtube:
        await this.youtubeService
          .exportInfluencersFromSearch(usernames, campaignSlug, undefined, false, context)
          .then((res) => this.handleExportSuccess(res, campaign.campaignName))
          .catch((err: HttpErrorResponse) => this.handleExportError(err));
        break;
      case Platforms.tiktok:
        await this.tiktokService
          .exportInfluencersFromSearch(usernames, campaignSlug, undefined, false, context)
          .then((res) => this.handleExportSuccess(res, campaign.campaignName))
          .catch((err: HttpErrorResponse) => this.handleExportError(err));
        break;
      case Platforms.twitter:
        await this.twitterService
          .exportInfluencersFromSearch(usernames, campaignSlug, undefined, false, context)
          .then((res) => this.handleExportSuccess(res, campaign.campaignName))
          .catch((err: HttpErrorResponse) => this.handleExportError(err));
        break;
      case VeraPlatforms.instagram:
        await this.instagramDiscoveryService
          .exportInfluencers(usernames, campaignSlug, undefined, false, context)
          .then((blob) => this.saveExportAndShowSuccessMessage(blob, campaign.campaignName))
          .catch((err: HttpErrorResponse) => this.handleExportError(err));
        break;
    }
    return Promise.resolve();
  }

  private handleExportSuccess(blobPart: BlobPart, campaignName: string) {
    const blob = utils.getCSVBlob(blobPart);
    this.saveExportAndShowSuccessMessage(blob, campaignName);
  }

  private saveExportAndShowSuccessMessage(blob: Blob, campaignName: string) {
    saveAs(blob, `${campaignName.substring(0, 30)}-influencers-results.csv`);
    this.toastrService.success(`Successfully downloaded influencers details`);
  }

  private handleExportError(error: any) {
    if (error.status === 429) {
      this.toastrService.error(error?.error?.message || `Export limit reached for profile exports!`);
    } else {
      this.toastrService.error(`Something went wrong while exporting!`);
    }
  }

  async removeInfluencers(
    platform: PlatformsV2,
    campaign: Campaign,
    existingInfluencers: CampaignInfluencer[],
    influencerToRemove: CampaignInfluencer,
  ): Promise<CampaignInfluencer[]> {
    let influencers = [...existingInfluencers];
    const selectedUsernames = (
      influencerToRemove ? [influencerToRemove] : this.getSelectedInfluencers(existingInfluencers)
    ).map(({ influencer }) => influencer.username);

    if (selectedUsernames?.length === 0) {
      return influencers;
    }

    await this.campaignsService
      .removeInfluencersfromCampaign(campaign.campaignSlugName, selectedUsernames, platform)
      .then(() => {
        influencers = influencers.filter(({ influencer }) => !selectedUsernames.includes(influencer.username));
        influencers.forEach((influencer) => {
          influencer.influencer.locations = influencer.influencer.locations?.filter((location) => !!location);
        });
        this.campaignsService.sendInfluencersChangedEvent(
          campaign.campaignSlugName,
          this.campaignsService.influencersChanges.influencersRemoved,
        );
        this.toastrService.success('Influencer removed successfully');
      })
      .catch(() => {
        this.toastrService.error('Failed to remove influencer!');
      });
    return influencers;
  }

  async updateInfluencersList(
    newInfluencers,
    existedInfluencers,
    privateInfluencersList: string[],
    isDirectAppend: boolean,
  ) {
    const privateInfluencers = [];
    const nonPrivateInfluencers = [];
    const existedInfluencersUsernamesMap = {};
    const privateInfluencersUsernamesMap = {};

    privateInfluencersList.forEach((username) => {
      privateInfluencersUsernamesMap[username] = true;
    });

    if (!isDirectAppend) {
      existedInfluencers.forEach((existedInfluencer) => {
        existedInfluencer.influencer
          ? (existedInfluencersUsernamesMap[existedInfluencer.influencer.username] = true)
          : (existedInfluencersUsernamesMap[existedInfluencer.username] = true);
        if (existedInfluencer.influencer?.private || existedInfluencer.isPrivate) {
          privateInfluencers.push(existedInfluencer);
        } else {
          nonPrivateInfluencers.push(existedInfluencer);
        }
      });
    }

    newInfluencers.forEach((newInfluencer) => {
      if (
        existedInfluencersUsernamesMap[newInfluencer.influencer?.username] ||
        existedInfluencersUsernamesMap[newInfluencer.username]
      ) {
        return;
      }
      if (
        privateInfluencersUsernamesMap[newInfluencer.influencer?.username] ||
        privateInfluencersUsernamesMap[newInfluencer.username]
      ) {
        if (newInfluencer.influencer) {
          privateInfluencers.push({
            ...newInfluencer,
            influencer: { ...newInfluencer.influencer, private: true },
          });
        } else {
          privateInfluencers.push({
            ...newInfluencer,
            isPrivate: true,
          });
        }
      } else {
        nonPrivateInfluencers.push(newInfluencer);
      }
    });

    return [...nonPrivateInfluencers, ...privateInfluencers];
  }

  async updateContentInfluencersList(
    newInfluencers,
    existedInfluencers,
    privateInfluencersList: string[],
    isDirectAppend: boolean,
  ) {
    const privateInfluencers = [];
    const nonPrivateInfluencers = [];
    const existedInfluencersUsernamesMap = {};
    const privateInfluencersUsernamesMap = {};

    privateInfluencersList.forEach((username) => {
      privateInfluencersUsernamesMap[username] = true;
    });

    if (!isDirectAppend) {
      existedInfluencers.forEach((existedInfluencer) => {
        existedInfluencersUsernamesMap[existedInfluencer.username] = true;
        if (existedInfluencer.isPrivate) {
          privateInfluencers.push(existedInfluencer);
        } else {
          nonPrivateInfluencers.push(existedInfluencer);
        }
      });
    }

    newInfluencers.forEach((newInfluencer) => {
      if (existedInfluencersUsernamesMap[newInfluencer.username]) {
        return;
      }
      if (privateInfluencersUsernamesMap[newInfluencer.username]) {
        privateInfluencers.push({
          ...newInfluencer,
          isPrivate: true,
        });
      } else {
        nonPrivateInfluencers.push(newInfluencer);
      }
    });

    return [...nonPrivateInfluencers, ...privateInfluencers];
  }
}
