import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TwitterInfluencerRoot } from '../../../interfaces';

@Injectable()
export class TwitterProfileService {
  constructor(private http: HttpClient) {}

  // Get twitter profile
  getTwitterProfile(username: string): Promise<TwitterInfluencerRoot> {
    return this.http
      .get(environment.api + `/api/twitter/${username}`)
      .toPromise()
      .then((json: any) => {
        if (json) {
          return json;
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  exportInfluencersProfile(influencerUsername: string): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/twitter/export/profile`,
        {
          username: influencerUsername,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }

  getAudiencePercentageByAge(
    username: string,
    minAge: number,
    maxAge?: number,
  ): Promise<{
    count: number;
    percentage: number;
  }> {
    let url = environment.api + `/api/twitter/${username}/stats/audience/count/age?min_age=${minAge}`;
    if (maxAge) {
      url = `${url}&max_age=${maxAge}`;
    }
    return this.http
      .get(url)
      .toPromise()
      .catch((err) => {
        console.log(`[Twitter] Error while geting audience count by age range for ${username}: ${minAge} to ${maxAge}`);
        return err;
      });
  }
}
