import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  ControlValueAccessor,
  ControlContainer,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeyValue, LibError } from './common.enum';
import { ControlError, InputType } from './control.enum';
import { v4 as uuidv4 } from 'uuid';

@Directive()
export abstract class ControlAbstractDirective implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * @ignore
   */
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;
  /**
   * @ignore
   */
  @ViewChild('aiControl') htmlElementRef: ElementRef;

  /**
   * key is a unique identifier within a form group that points to the formControlName
   * Either key or formControl is mandatory
   */
  @Input() key: string;
  /**
   * Reactive formControl
   * Either key or formControl is mandatory
   */
  @Input() formControl: FormControl;
  /**
   * Initial Value
   */
  @Input() value: string | number | boolean;
  /**
   * Placeholder to be used within input field
   */
  @Input() placeholder: string = '';
  /**
   * Choose from various input types like number, string, datetime
   */
  @Input() inputType: InputType = 'text';

  // Optional
  @Input() clearable = false;
  @Input() silent = false;
  @Input() hideError = false;
  @Input() label: boolean | string = true;
  @Input() position: 'fixed' | 'stacked' | 'floating' = 'stacked';
  @Input() prefix: boolean | string = false;
  @Input() suffix: boolean | string = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() hint: string;
  @Input() length: number;
  @Input() errorMessages: KeyValue = ControlError;

  /**
   * @ignore
   */
  @Output() action: EventEmitter<{
    key: string;
    value: string | number | boolean;
  }> = new EventEmitter();

  /**
   * @ignore
   */
  controlId: string;
  /**
   * @ignore
   */
  error: string;
  /**
   * @ignore
   */
  error$: Subscription;
  /**
   * @ignore
   */
  hasValidators: boolean;
  /**
   * @ignore
   */
  focused: boolean;
  /**
   * @ignore
   */
  focused$ = new BehaviorSubject(false);

  /**
   * @ignore
   */
  private unsubscribe$: Subject<unknown> = new Subject<unknown>();

  /**
   * @ignore
   */
  get control(): FormControl {
    return (this.formControl || this.controlContainer.control?.get(this.key)) as FormControl;
  }

  constructor(private controlContainer: ControlContainer, private renderer: Renderer2) {}

  /**
   * @ignore
   */
  addClass(className: string, element: HTMLElement = this.htmlElementRef?.nativeElement): void {
    if (element && className.length) {
      this.renderer.addClass(element, className);
    }
  }

  /**
   * @ignore
   */
  removeClass(className: string, element: HTMLElement = this.htmlElementRef?.nativeElement): void {
    if (element && className.length) {
      this.renderer.removeClass(element, className);
    }
  }

  /**
   * @ignore
   */
  clearInput(): void {
    this.control ? this.control.reset() : console.warn(LibError.noControl);
  }

  /**
   * @ignore
   */
  registerOnTouched(fn: unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  /**
   * @ignore
   */
  registerOnChange(fn: unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  /**
   * @ignore
   */
  writeValue(obj: unknown): void {
    this.formControlDirective?.valueAccessor?.writeValue(obj);
  }

  /**
   * @ignore
   */
  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState(isDisabled);
  }

  /**
   * @ignore
   */
  getErrorMessage(control: AbstractControl): string {
    const errorKey = Object.keys(control.errors || {})[0] || 'default';
    return this.errorMessages[errorKey] || ControlError[errorKey] || 'Control Error';
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.controlId = uuidv4().substring(0, 8);
    if (this.label) {
      this.label = typeof this.label == 'string' ? this.label : this.key?.replace(/([A-Z])/g, ' $1');
    }
    if (!this.control) {
      this.formControl = new FormControl(
        { value: this.value, disabled: this.disabled },
        this.required ? [Validators.required] : [],
      );
    }
    if (this.value) {
      this.control.setValue(this.value);
    }
    if (this.silent) {
      this.control.clearValidators();
      this.control.clearAsyncValidators();
      this.control.updateValueAndValidity();
    } else {
      this.hasValidators = Boolean(this.control.validator?.length) || Boolean(this.control.asyncValidator?.length);
      this.error$ = this.control.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
        if (v === 'INVALID') {
          this.error = this.getErrorMessage(this.control);
        } else {
          this.error = null;
        }
      });
    }
    this.focused$.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => {
      this.focused = v;
    });
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
