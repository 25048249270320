import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Platforms } from '../../enums';
import { InfluencerSavedSearchFilters } from '../../interfaces/influencer-filters.interface';
import { FollowersGrowth } from './influencer-filter.service';

@Injectable()
export class InfluencerSearchFilterService {
  private savedSearchFiltersSource = new BehaviorSubject<InfluencerSavedSearchFilters[]>([]);
  influencerSavedSearchFiltersChanged$ = this.savedSearchFiltersSource.asObservable();

  private selectedSavedSearchFiltersSource = new BehaviorSubject<InfluencerSavedSearchFilters>(null);
  selectedSavedSearchFiltersChanged$ = this.selectedSavedSearchFiltersSource.asObservable();

  constructor(public http: HttpClient) {}

  saveSearchFilters(searchFilters: InfluencerSavedSearchFilters): Promise<InfluencerSavedSearchFilters> {
    return this.http
      .post<InfluencerSavedSearchFilters>(environment.api + `/api/users/searchFilters/save`, {
        searchFilters,
      })
      .toPromise();
  }

  getInfluencerSavedSearchFiltersList(): InfluencerSavedSearchFilters[] {
    return this.savedSearchFiltersSource.getValue() || [];
  }

  getSelectedInfluencerSavedSearchFilters(): InfluencerSavedSearchFilters {
    return this.selectedSavedSearchFiltersSource.getValue() || null;
  }

  setSelectedInfluencerSavedSearchFilters(searchFilters: InfluencerSavedSearchFilters) {
    this.selectedSavedSearchFiltersSource.next(searchFilters);
  }

  getFiltersByGroupName(groupName: string): InfluencerSavedSearchFilters[] {
    return this.getInfluencerSavedSearchFiltersList().filter(
      (filter) => filter.filter.influencerFilters.groupName === groupName,
    );
  }

  fetchSearchFilters(platform: Platforms, setDefaultSearch?: boolean): Promise<void> {
    return this.http
      .get<InfluencerSavedSearchFilters[]>(environment.api + `/api/users/searchFilters`, {
        params: {
          platform: platform,
        },
      })
      .toPromise()
      .then((response) => {
        response.map((filter) => {
          // for backward compatibility of saved filters without followers growth
          if (filter && filter.filter && filter.filter.influencerFilters) {
            if (isEmpty(filter.filter.influencerFilters['followersGrowth'])) {
              filter.filter.influencerFilters['followersGrowth'] = new FollowersGrowth();
            }

            if (isEmpty(filter.filter.influencerFilters['subscribersGrowth'])) {
              filter.filter.influencerFilters['subscribersGrowth'] = new FollowersGrowth();
            }
          }
        });
        this.savedSearchFiltersSource.next(response as InfluencerSavedSearchFilters[]);
        const defaultFilter = response.find((r) => r.isDefault);
        if (defaultFilter && setDefaultSearch) {
          this.selectedSavedSearchFiltersSource.next(defaultFilter);
        }
      });
  }

  fetchSearchFilterBySlugName(platform: Platforms, filterSlugName: string): Promise<InfluencerSavedSearchFilters> {
    return this.http
      .get<InfluencerSavedSearchFilters>(environment.api + `/api/users/searchFilters/${filterSlugName}`, {
        params: { platform },
      })
      .toPromise()
      .then((response) => {
        // for backward compatibility of saved filters without followers growth
        if (response && response.filter && response.filter.influencerFilters) {
          if (isEmpty(response.filter.influencerFilters['followersGrowth'])) {
            response.filter.influencerFilters['followersGrowth'] = new FollowersGrowth();
          }

          if (isEmpty(response.filter.influencerFilters['subscribersGrowth'])) {
            response.filter.influencerFilters['subscribersGrowth'] = new FollowersGrowth();
          }
        }
        return response;
      });
  }

  updateSearchFilters(searchFilters: Partial<InfluencerSavedSearchFilters>): Promise<InfluencerSavedSearchFilters> {
    return this.http
      .put<InfluencerSavedSearchFilters>(environment.api + `/api/users/searchFilters/update`, {
        searchFilters,
      })
      .toPromise();
  }

  deleteSearchFilters(platform: Platforms, filterSlugName: string): Promise<string> {
    return this.http
      .delete<string>(environment.api + `/api/users/searchFilters/${filterSlugName}`, {
        params: { platform },
      })
      .toPromise();
  }

  updateDefaultSearch(
    platform: Platforms,
    filterSlugName: string,
    remove = false,
  ): Promise<InfluencerSavedSearchFilters> {
    return this.http
      .put<InfluencerSavedSearchFilters>(environment.api + `/api/users/searchFilters/updateDefault/${filterSlugName}`, {
        platform,
        remove,
      })
      .toPromise();
  }
}
