import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbLayoutModule,
  NbMenuModule,
  NbPopoverModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  FooterComponent,
  LoginErrorModalComponent,
  NgxAuthBlockComponent,
  NgxInsufficientPermissionComponent,
  ServerErrorComponent,
  NgxLoginComponent,
  PublicAppLoginComponent,
  NgxNotFoundComponent,
  NgxRequestPasswordComponent,
  NgxServerUnavailableErrorComponent,
  SignupComponent,
  PublicAppSubscriptionPlans,
  PublicAppSubscriptionSuccess,
  NgxPublicRequestPasswordComponent,
  NgxPublicResetPasswordLinkComponent,
  PublicAppLandingComponent,
  RedirectToDefaultComponent,
} from './components';
import { AffableLogoDirective, InsufficientItemsDirective } from './directives';
import { CapitalizePipe, KeywordSearchPipe, PluralPipe, RoundPipe, TimingPipe, FilenameTruncatePipe } from './pipes';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { DEFAULT_THEME } from './styles/theme.default';
import { NgOtpInputModule } from 'ng-otp-input';
import { ControlsModule } from '../../../../controls/src';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule];

const NB_MODULES = [
  ControlsModule,
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbSelectModule,
  NgbModule,
  NbAccordionModule,
  NbAlertModule,
  NgOtpInputModule,
  NbSpinnerModule,
];

const DIRECTIVES = [InsufficientItemsDirective, AffableLogoDirective];

const COMPONENTS = [
  FooterComponent,
  NgxLoginComponent,
  PublicAppLoginComponent,
  NgxRequestPasswordComponent,
  LoginErrorModalComponent,
  NgxAuthBlockComponent,
  ServerErrorComponent,
  NgxInsufficientPermissionComponent,
  NgxServerUnavailableErrorComponent,
  NgxNotFoundComponent,
  SignupComponent,
  PublicAppSubscriptionPlans,
  PublicAppSubscriptionSuccess,
  NgxPublicRequestPasswordComponent,
  NgxPublicResetPasswordLinkComponent,
  PublicAppLandingComponent,
  RedirectToDefaultComponent,
];

const PIPES = [CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, KeywordSearchPipe, FilenameTruncatePipe];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [DEFAULT_THEME, COSMIC_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  entryComponents: [LoginErrorModalComponent],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
  providers: [...NB_THEME_PROVIDERS],
})
export class ThemeModule {}
