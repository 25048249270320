import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'list-view-card-modal',
  templateUrl: './list-view-card-modal.component.html',
  styleUrls: ['./list-view-card-modal.component.scss'],
})
export class ListViewCardModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  title: string;
  contents: ListViewCardContent[];
  totalCount: number;
  defaultDisplayLength = 4;

  getTotalCount(): number {
    return this.contents.reduce((count, content) => {
      const len = content.data ? content.data.length : 0;
      return count + len;
    }, 0);
  }

  closeModal(): void {
    this.activeModal.close();
  }

  removeOne(content: ListViewCardContent, index: number): void {
    content.data?.splice(index, 1);
  }

  removeAllDataInContent(content: ListViewCardContent): void {
    this.contents = this.contents.filter((c) => c !== content);
  }

  removeAllContents(): void {
    this.contents = [];
  }

  onSave(): void {
    this.activeModal.close(this.contents);
  }

  showMore(content: ListViewCardContent): void {
    content.showAll = !content.showAll;
  }
}

export class ListViewCardContent {
  name: string;
  data: string[] | undefined;
  tooltip?: string[];
  showAll?: boolean = false;
}
