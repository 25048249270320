<div class="public-app-request-password">

  <div class="public-app-request-password-details">
    <p><b>Forgot passsword? </b> Don't worry, <br /> we can help you get back on track in a few steps.</p>
    <img src="/assets/images/illustration_request_password.svg" alt="" style="width: 500px;">
  </div>

  <div class="vertical-line"></div>

  <div>
    <div class="public-app-request-password-title">
      <img style="height: 72px;" alt="" affLogo>

      <h6>Forgot password</h6>
    </div>

    <form (ngSubmit)="resetPassword()" #resetPassForm="ngForm" autocomplete="off">
      <div class="form-group">
        <label for="input-email">Email</label>

        <input name="email" [(ngModel)]="user.email" id="input-email" class="form-control" #email="ngModel" type="email"
          email [required]="true" (keydown.space)="$event.preventDefault();"
          [class.form-control-danger]="email.invalid && email.touched" autofocus>
        <small class="form-text error" *ngIf="email.invalid && email.touched">
          Valid email is required!
        </small>
        <div *ngIf="showMessages.error && errors && errors?.length> 0 && submitted" class="alert alert-danger error"
          role="alert">
          <i class="fa fa-exclamation-triangle "></i>
          <div>
            <div *ngFor="let error of errors">{{ error }}
            </div>
          </div>
        </div>
      </div>

      <button [disabled]="submitted || !resetPassForm.form.valid" class="btn btn-block btn-primary"
        [class.btn-pulse]="submitted"> send reset link
      </button>

      <div class="login">
        <p>Oh! you remember the password? <a (click)="navigateToLogin()">Login</a></p>
      </div>

    </form>
  </div>
</div>
