<div class="modal-header">
  <span>Connect Account</span>
  <button class='close' aria-label='Close' (click)='closeModal()'>
    <span aria-hidden='true'>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="frame-container">
    <iframe [src]="connectionUrl" frameborder="0"></iframe>
  </div>
  <div class="footer">
    <ai-button label="CANCEL" type="ghost" (click)="closeModal()"></ai-button>
    <ai-button label="FINISH" size="large" type="fill" (click)="onAuthenticate()" [disabled]="!displaySocialAccountId">
    </ai-button>
  </div>
</div>