<div class="modal-header">
    <span>Remove Group Access</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="title">Users to remove</label>
                <ngx-tooltip [tooltip]="'Remove group access for user'">
                </ngx-tooltip>
                <ng-select placeholder="Remove group access for "
                    notFoundText="This group does not seem to be shared yet" bindLabel="email" [addTag]=false
                    [items]="sharedWith" [multiple]="true" [hideSelected]="true" [virtualScroll]="true"
                    [markFirst]="false" [(ngModel)]="membersToRemove">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items">
                            <span class="ng-value-label"><b> {{item}}</b></span>
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span [ngOptionHighlight]="search" class="option-label">{{item}} </span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="secondary-text" (click)="closeModal()">CANCEL</button>
    <button class="primary" (click)="unshareGroup()" [disabled]="membersToRemove?.length === 0">REMOVE ACCESS</button>
</div>