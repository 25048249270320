<div class="content {{col.property}}" [ngSwitch]="col.property" [style.justifyContent]="col.justifyContent"
    *ngIf="row[col?.dataKey]!==undefined && row[col?.dataKey]!==null && row[col?.dataKey]!=='';else defaultValue">
    <ng-container *ngSwitchCase="'number'">
        <ng-container [ngSwitch]="col.subProperty">
            <ng-container *ngSwitchCase="'quantity'">
                {{row[col?.dataKey] | numberQuantity}}
            </ng-container>
            <ng-container *ngSwitchCase="'percent'">
                {{row[col?.dataKey] | number:'1.0-2'}}%
            </ng-container>
            <ng-container *ngSwitchDefault>{{row[col?.dataKey]}}</ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'influencer'">
        <img [src]="row?.profilePictureUrl">
        <span class="body-3">{{row[col?.dataKey]}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'platforms'">
        <ng-container *ngFor="let platform of (row[col?.dataKey]||{})|keyvalue">
            <i class="fa-brands fa-{{platform.key}}" *ngIf="platform.value"></i>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'contact'">
        <i class="fa-regular fa-envelope"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'shipping'">
        <i class="fa-regular fa-address-card"></i>
    </ng-container>
    <ng-container *ngSwitchDefault>
        {{row[col?.dataKey]}}
    </ng-container>
</div>

<ng-template #defaultValue>
    <span class="content {{col.property}}" [style.justifyContent]="col.justifyContent">{{col?.defaultValue}}</span>
</ng-template>