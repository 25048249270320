import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class LocationService {
  constructor(private http: HttpClient) {}

  lookupLocations(term: string): Observable<Location[]> {
    if (!term) {
      return of([]);
    }
    return this.http
      .get(`${environment.api}/api/locations`, {
        params: { term: term.trim(), regionSupport: 'true' },
      })
      .pipe(map((locations: any[]) => locations.map((l) => new Location(l.name, l.disabled))));
  }
}

export class Location {
  name: string;
  disabled?: boolean;
  isIncluded: boolean;

  constructor(name: string, disabled = false) {
    this.name = name;
    this.disabled = disabled;
    this.isIncluded = true;
  }
}
