const heights = {};
heights['bio'] = 415;
heights['audience-age-distribution'] = 442;
heights['audience-gender-distribution'] = 406;
heights['audience-location-distribution'] = 496;
heights['engagement'] = 408;
heights['Facebookengagement'] = 485;
heights['Tiktokengagement'] = 615;
heights['Youtubeengagement'] = 615;
heights['follower-timeline'] = 400;
heights['engagement-timeline'] = 442;
heights['branded-content-distribution'] = 406;
heights['audience-interest-distribution'] = 509;

export const HEIGHTS = heights;

// A4 Height in 140 DPI Scale/Resolution,  - counting the Header footer of the print layout
// ref to https://pixelcalculator.com/en
export const A4 = { height: 1284.333333 };
