import { AfterViewChecked, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[ngxInsufficientItems]',
  exportAs: 'insufficientItems',
})
export class InsufficientItemsDirective implements AfterViewChecked {
  @Input() existingItems: number;
  @Output() loadMoreItems: EventEmitter<void> = new EventEmitter();

  previousExistingItems: number;

  constructor(private element: ElementRef) {}

  ngAfterViewChecked(): void {
    const htmlElement = this.element?.nativeElement as HTMLElement;
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const elementHeight = htmlElement.offsetTop + htmlElement.offsetHeight;
    if (
      this.existingItems &&
      this.existingItems !== this.previousExistingItems &&
      elementHeight <= viewportHeight &&
      elementHeight > 0
    ) {
      this.previousExistingItems = this.existingItems;
      this.loadMoreItems.emit();
    }
  }
}
