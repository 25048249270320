<div class="modal-header">
    <span>{{editMode ? 'Edit Search': 'Save Search'}}</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #form="ngForm">
        <div *ngIf="dropdownSearchList?.length === 0" class="form-group">
            <label>Search Name</label>
            <input #searchNm="ngModel" name="searchNm" type="text" placeholder="Give your search a name here"
                [(ngModel)]="searchName" [required]="true" class="input-group input-group-sm" />
            <small class="form-text error" *ngIf="searchNm.invalid && searchNm.touched">Name is required!</small>
        </div>
        <div *ngIf="dropdownSearchList?.length" class="form-group">
            <div ngbRadioGroup name='saveAsNew' class="btn-group btn-group-toggle flex-col" style="gap: 10%;">
                <div>
                    <div class="flex-row">
                        <input type="radio" [(ngModel)]="saveAsNew" [value]="true" name="saveAsNew"
                            class="radio-btn mr-2" />
                        <label>{{editMode ? 'Search Name':'Save as new search'}}</label>
                    </div>
                    <input #searchNm="ngModel" name="searchNm" type="text" placeholder="Give your search a name here"
                        [(ngModel)]="searchName" [required]="true" class="input-group input-group-sm"
                        (input)="searchNameChanged()" [disabled]="!saveAsNew"/>
                    <small class="form-text error" *ngIf="saveAsNew && searchNm.invalid && searchNm.touched">Name is
                        required!</small>
                </div>
                <div class="mt-3 mb-3">Or</div>
                <div>
                    <div class="flex-row">
                        <input type="radio" [(ngModel)]="saveAsNew" [value]="false" name="saveAsNew"
                            class="radio-btn mr-2" />
                        <label>Replace a saved search</label>
                    </div>
                    <ng-select name="filterList" class="filter-select" bindLabel="filterName"
                        [(ngModel)]="selectedFilter" placeholder="Select from the list" [items]="dropdownSearchList"
                        [virtualScroll]="true" [markFirst]="false" (change)="selectSavedSearch()" [disabled]="saveAsNew">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>How do you want your search to be saved?</label>
            <div ngbRadioGroup name='searchType' class="btn-group btn-group-toggle" style="gap: 10%;">
                <label class="label" title="Private">
                    <input type="radio" [(ngModel)]="filterPrivacy" [value]="'private'" name="searchType"
                        class="radio-btn mr-2" />
                    Private
                </label>
                <label class="label" title="Shared">
                    <input type="radio" [(ngModel)]="filterPrivacy" [value]="'org'" name="searchType"
                        class="radio-btn mr-2" />
                    Shared
                </label>
                <label class="label" title="Global" *ngIf="user?.isAdminUser">
                    <input type="radio" [(ngModel)]="filterPrivacy" [value]="'global'" name="searchType"
                        class="radio-btn mr-2" />
                    Multiple organizations
                </label>
            </div>
        </div>
        <div class="form-group" *ngIf="filterPrivacy === 'global'">
            <ng-select class="filter-select" dropdownPosition="bottom"
                placeholder="Choose organizations to share search with" [items]="organizations" [markFirst]="false"
                [searchable]="true" [virtualScroll]="true" [(ngModel)]="selectedOrganizations" [multiple]="true"
                [hideSelected]="true" name="sharedOrgs">
            </ng-select>
        </div>
        <div class="form-group">
            <nb-checkbox status="success" name="defaultSearch" [(ngModel)]="isDefault">
                <label>Make this the default search.</label>
            </nb-checkbox>
        </div>
        <div>
            <button class="btn btn-xs btn-primary"
                [disabled]="saveAsNew && (!searchName || searchName.trim().length === 0 || !form.valid) || (!saveAsNew && !selectedFilter) || (filterPrivacy === 'global' && selectedOrganizations.length === 0)"
                (click)="saveSearch()">SAVE</button>
            <button class="btn-transparent" (click)="closeModal()">CANCEL</button>
        </div>
    </form>
</div>
