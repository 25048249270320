export type CampaignStatus =
  | 'pending'
  | 'completed'
  | 'updating'
  | 'refreshing'
  | 'deleted'
  | 'update'
  | 'complete'
  | 'refresh';

export enum SortStatus {
  no_sort = 0,
  descending = 1,
  ascending = 2,
}

export enum Status {
  ready = 'Ready to be Invited',
  invited = 'Invited',
  confirmed = 'Confirmed',
  published = 'Published',
  paid = 'Paid',
  rejected = 'Rejected',
  reinvited = 'Reinvited',
}

export enum InfluencerType {
  registered = 'Registered Influencer',
  nonregistered = 'Non-Registered Influencer',
}

// influencersEdited is used when influencer properties change (cost, email, etc..)
export enum InfluencerChanges {
  influencersAdded,
  influencersRemoved,
  influencersEdited,
  influencersPartnerizeEdited,
  // new event for updating the
  // influencer email inside the new mail modal, to keep the selected influencers state as it is and update the email*
  influencersEmailChipEdited,
  influencersProposalUpdated,
  influencersEmailUpdated,
  influencerPaypalIdUpdated,
  influencerCostUpdated,
}

export enum ProposalStatus {
  received = 'Received',
  declined = 'Declined',
  approved = 'Approved',
  seen = 'Seen',
  reinvited = 'Reinvited',
}

export enum ProposalStatusAPI {
  submitted = 'submitted',
  rejected = 'rejected',
  approved = 'approved',
  seen = 'seen',
  reinvited = 'reinvited',
}

export type WorkflowCompletionStatus = 'uninitialized' | 'pending' | 'completed';

export const SubmittedContentStatuses = [
  'submitted',
  'approved',
  'pending-changes',
  'updated',
  'rejected',
  'outdated',
] as const;
export type SubmittedContentStatus = typeof SubmittedContentStatuses[number];

export const SubmittedContentTypes = ['post', 'story', 'reel', 'video', 'short', 'tweet'] as const;
export type SubmittedContentType = typeof SubmittedContentTypes[number];
