<nb-card *ngIf="similarInfluencerList?.length > 0" class="similar-influencers-card">
  <nb-card-header>
    <span>Similar Influencers</span>
    <ngx-tooltip [tooltip]="tooltip"></ngx-tooltip>
  </nb-card-header>
  <nb-card-body class="similar-influencers-container">
    <button [class.show]="similarInfluencerList?.length > 4 && next">
      <i class='fa fa-angle-left' (click)='next = false'></i></button>
    <div class="similar-influencer-list">

       <div *ngFor="let influencer of similarInfluencerList | slice:0:4; let i = index" [hidden]="next"
        class="similar-influencer" [class.limited-width]="similarInfluencerList?.length >= 4"
        (click)="goToSimilarInfluencer(influencer.username)">
        <nb-user showName="false" [picture]="influencer.picture">
          <img id="hidden_image" src="{{influencer.picture}}" (error)="checkError(influencer.picture, i)" style="display: none;"/>
        </nb-user>
        <label class="influencer-name"> {{ influencer.username }} </label>
      </div>

      <div *ngFor="let influencer of similarInfluencerList | slice:4:8; let i = index"  [hidden]="!next"
        class="similar-influencer" [class.limited-width]="similarInfluencerList?.length == 8"
        (click)="goToSimilarInfluencer(influencer.username)">
        <nb-user showName="false" [picture]="influencer.picture">
        </nb-user>
        <img id="hidden_image" src="{{influencer.picture}}" (error)="checkError(influencer.picture, i+4)" style="display: none;"/>
        <label class="influencer-name"> {{ influencer.username }} </label>
      </div>
    </div>
    <button [class.show]="similarInfluencerList?.length > 4 && !next">
      <i class='fa fa-angle-right' (click)='next = true'></i></button>
  </nb-card-body>
</nb-card>