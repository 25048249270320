import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-tooltipSimple',
  styleUrls: ['./tooltipSimple.component.scss'],
  templateUrl: 'tooltipSimple.component.html',
})
export class TooltipSimpleComponent {
  @Input() tooltips: string[];
  @Input() baseText: string;
  @Input() placement = 'bottom-right';
}
