import { GroupOption } from './control.interface';

export type KeyValue = { [key: string]: string | number };

export type Value = string | number | boolean;

export type HTMLTags = keyof HTMLElementTagNameMap;

export type HTMLTypes = { [key in HTMLTags]?: string };

export enum LibError {
  noControl = 'No Form Control detected',
  default = 'Unknown Error',
}

export type DBOmit = '_id' | '__v' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy';

export type Typography =
  | 'main-title'
  | 'display'
  | 'heading-h1'
  | 'heading-h2'
  | 'sub-heading-h1'
  | 'sub-heading-h2'
  | 'body-1'
  | 'body-2'
  | 'body-3'
  | 'body-4'
  | 'label'
  | 'caption'
  | 'caption-2'
  | 'placeholder-text'
  | 'link-text';

export interface DropDownAction {
  lastAction: Action;
  groupOptions: GroupOption[];
}

export interface Action {
  key: Value;
  value: Value;
  displayName?: string;
  image?: string;
  imageStyle?: { [a: string]: string };
  subName?: string;
  displaySubName?: string;
  suffix?: string;
  isRegistered?: boolean;
}

export type DropDownSize = 'medium' | 'small' | 'large';

export type CheckBoxType = 'checkmark' | 'none' | 'checkbox';
