import { PlatformsV2 } from './../../../enums/social.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbTabComponent, NbTabsetComponent } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignsService } from '../../../../../src/app/@core/data/campaigns.service';
import { EmailTemplate } from '../../../../../src/app/interfaces';
import { AutoReplyComponent } from './auto-reply-tab/auto-reply/auto-reply';
import { AutoReplyTabComponent } from './auto-reply-tab/auto-reply-tab';
import { InvitationsComponent } from './invitations/invitations';

enum ProposalAutoReplyTab {
  approval = 0,
  rejected = 1,
}

enum ContentApprovalAutoReplyTab {
  approval = 0,
  reviewed = 1,
}

@Component({
  selector: 'ngx-email-templates-modal',
  templateUrl: './email-templates.modal.html',
  styleUrls: ['./email-templates.modal.scss'],
  animations: [
    trigger('fadeIn', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void => *', animate(350)),
    ]),
  ],
})
export class EmailTemplatesModal {
  @Input() campaignSlugName: string;
  @Input() platform: PlatformsV2;
  @Input() isSharedCampaign = false;
  @ViewChild('invitationsComp', { static: false }) invitationsComp: InvitationsComponent;
  @ViewChild('autoReplyComp', { static: false }) autoReplyComp: AutoReplyComponent;
  @ViewChild('autoReplyTabComp', { static: false }) autoReplyTabComp: AutoReplyTabComponent;
  types: { type: string; templates: EmailTemplate[] }[] = [];
  contentApprovalTypes: { type: string; templates: EmailTemplate[] }[] = [];
  proposalTypes: { type: string; templates: EmailTemplate[] }[] = [];
  invitationTypes: { type: string; templates: EmailTemplate[] }[] = [];
  supportedTemplatingVariables: string[] = [];
  @ViewChild('tabset', { static: false }) tabsetEl: NbTabsetComponent;
  @ViewChild('invitationsTab', { static: false }) invitationsTab: NbTabComponent;
  @ViewChild('autoReplyTab', { static: false }) autoReplyTab: NbTabComponent;
  @ViewChild('emailSequencesTab', { static: false }) emailSequencesTab: NbTabComponent;
  searchText = new FormControl('');
  @Input() isEmailSequencingEnabled = false;
  @Input() isEmailIntegrated: boolean;
  viewInfo = true;

  constructor(private activeModal: NgbActiveModal, private campaignsService: CampaignsService) {}

  async ngOnInit(): Promise<void> {
    if (this.isEmailSequencingEnabled) {
      this.tabsetEl?.selectTab(this.emailSequencesTab);
    }
    const response = await this.campaignsService.getTemplates(this.campaignSlugName);
    this.types = response?.types?.map((v) => ({
      ...v,
      templates: v.templates?.map((template, _, [_generic, ...arr]) => ({
        ...template,
        conditions: template?.conditions?.generic
          ? { ...template.conditions, default: !arr.filter(({ conditions }) => conditions?.default).length }
          : template?.conditions,
      })),
    }));
    this.invitationTypes = response.invitationTypes?.map((v) => ({
      ...v,
      templates: v.templates?.map((template, i) => ({ ...template, i })),
    }));
    const contentApprovalTemplates: EmailTemplate[] = [];
    const proposalApprovalTemplates: EmailTemplate[] = [];
    this.types[0].templates.forEach((template) => {
      if (template.conditions?.contentApproval) {
        contentApprovalTemplates.push(template);
      } else {
        proposalApprovalTemplates.push(template);
      }
    });
    this.contentApprovalTypes = [{ type: 'approval', templates: contentApprovalTemplates }, this.types[2]];
    this.proposalTypes = [{ type: 'approval', templates: proposalApprovalTemplates }, this.types[1]];
    this.supportedTemplatingVariables = response.supportedVariables;
  }

  get isEditModeOn(): boolean {
    return (
      this.invitationsComp?.isEditMode ||
      this.autoReplyComp?.isEditMode ||
      this.autoReplyComp?.isDeleteMode ||
      this.invitationsComp?.isDeleteMode
    );
  }

  // createNewTemplate(): void {
  //   this.tabsetEl.selectTab(this.invitationsTab);
  //   this.invitationsComp.createNew();
  // }

  createNewTemplate(): void {
    this.tabsetEl.selectTab(this.invitationsTab);
    this.invitationsComp.createNew();
  }

  createAutoReplyTemplate(index: ProposalAutoReplyTab | ContentApprovalAutoReplyTab, scope: string): void {
    this.tabsetEl.selectTab(this.autoReplyTab);
    this.autoReplyTabComp.createNew(index, scope);
  }

  closeInfo(): void {
    this.viewInfo = false;
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
