import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Location } from '../../../@core/data/location.service';

@Component({
  selector: 'ngx-locations-filter',
  templateUrl: './locations-filter.component.html',
  styleUrls: ['./locations-filter.component.scss', '../styles.scss'],
})
export class LocationsFilterComponent {
  @Input() locations: Observable<Location[]> | Location[];
  @Input() selectedLocations: Location[];
  @Input() loadingLocations: boolean;
  @Input() locationInput: Subject<string>;
  @Input() asyncLocations = false;
  @Input() isDisabled = false;
  @Input() displayLabel = true;
  @Input() allowToggle = true;

  @Output() triggerUpdate: EventEmitter<Location[]> = new EventEmitter();

  triggerUpdateEvent(): void {
    this.triggerUpdate.emit(this.selectedLocations);
  }

  toggleLocationLogic(location: Location): void {
    location.isIncluded = !location.isIncluded;
    this.triggerUpdateEvent();
  }
}
