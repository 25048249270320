import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AudienceAgeGroup, TwitterInfluencerSortKey } from '../../../enums';
import {
  HashtagWithLogic,
  InfluencerWithLogic,
  TwitterInfluencerLookup,
  TwitterInfluencersSearchResult,
} from '../../../interfaces';
import { SearchModifiers } from '../content-filter.service';
import { FollowersGrowth } from '../influencer-filter.service';
import { Location } from '../location.service';

@Injectable()
export class TwitterService {
  private filtersChangedSource = new Subject<TwitterInfluencerSearchFilters>();
  filtersChanged$ = this.filtersChangedSource.asObservable();
  constructor(private http: HttpClient) {}

  sendInfluencerFiltersChangedEvent(
    influencerFilters: TwitterInfluencerFilters,
    audienceFilters: TwitterAudienceFilters,
    onlyTopInfluencers: boolean,
    sortKey: TwitterInfluencerSortKey,
  ) {
    this.filtersChangedSource.next({ influencerFilters, audienceFilters, onlyTopInfluencers, sortKey });
  }

  // Get top twitter influencers
  getTopInfluencers(location: string): Promise<TwitterInfluencersSearchResult> {
    return this.http
      .get<TwitterInfluencersSearchResult>(environment.api + `/api/twitter/top`, {
        params: { location },
      })
      .toPromise();
  }

  // search twitter influencers
  getInfluencers(searchFilters: TwitterInfluencerSearchFilters, page: number): Promise<TwitterInfluencersSearchResult> {
    const influencerFilters = cloneDeep(searchFilters);
    if (influencerFilters.influencerFilters.username) {
      influencerFilters.influencerFilters.username = searchFilters.influencerFilters.username.trim();
    }
    return this.http
      .post(`${environment.api}/api/twitter/`, influencerFilters, {
        params: {
          page: page.toString(),
          sortKey: searchFilters.sortKey,
        },
      })
      .toPromise()
      .then(async (json: TwitterInfluencersSearchResult) => json);
  }

  // Get twitter influencer look up by term
  lookupInfluencers(term: string): Observable<TwitterInfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    return this.http.get<TwitterInfluencerLookup[]>(`${environment.api}/api/twitter`, {
      params: { term: term.trim() },
    });
  }

  getInterests(): Promise<HashtagWithLogic[]> {
    return this.http
      .get<string[]>(`${environment.api}/api/twitter/interests/all`)
      .toPromise()
      .then((response) => {
        return response.map((label) => ({
          label,
          isIncluded: true,
        }));
      });
  }

  // Profile influencer and add to group
  profileInfluencersList(
    usernames: string[],
    groupName: string | undefined,
    groupSlugName: string | undefined,
  ): Promise<string[]> {
    return this.http
      .post(`${environment.api}/api/jobs/twitter/influencers/profileList`, {
        usernames: usernames,
        groupName: groupName,
        groupSlugName: groupSlugName,
      })
      .toPromise()
      .then((response: any) => response.profiledUsernames);
  }

  exportInfluencersFromSearch(
    usernames: string[],
    campaignSlugName?: string,
    groupSlugName?: string,
    allUsers = false,
    context: 'INVITATION' | 'PROPOSAL' = 'INVITATION',
  ): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/twitter/export/search`,
        {
          usernames,
          context,
          ...(campaignSlugName && { campaignSlugName }),
        },
        {
          responseType: 'blob',
          params: {
            ...(groupSlugName && { groupSlugName }),
            allUsers: allUsers.toString(),
          },
        },
      )
      .toPromise();
  }
}

export class TwitterInfluencerFilters {
  username: string = undefined;
  influencers?: InfluencerWithLogic[] = [];
  gender = new Gender();
  followers = new Followers();
  countries: Location[] = [];
  keywords: Keyword[] = [];
  bioKeywords: Keyword[] = [];
  searchModifiers: SearchModifiers = new SearchModifiers();
  searchGroup = false;
  groupName: string = undefined;
  groupNames: string[] = [];
  excludeGroupInfluencers? = false;
  followersGrowth: FollowersGrowth = new FollowersGrowth();
  contactInfoAvailable: boolean = undefined;
}

export class TwitterAudienceFilters {
  ageGroups: AudienceAgeGroup[] = [];
  gender = new Gender();
  locations: string[] = []; // These are country codes, not names
}

// Twitter influencers search filters classes
export class TwitterInfluencerSearchFilters {
  influencerFilters: TwitterInfluencerFilters;
  audienceFilters: TwitterAudienceFilters;
  onlyTopInfluencers? = false;
  sortKey: TwitterInfluencerSortKey;
}

export class Gender {
  male = false;
  female = false;
}

export class Followers {
  min = 5000;
  max = 1000000;
}

export class Keyword {
  label: string;
  isIncluded?: boolean;
}
