import { Injectable } from '@angular/core';
import { includes, startCase } from 'lodash';
import { CountryTimezone, DetailedCountry, Location } from '../../interfaces';

@Injectable()
export class CountryService {
  private countryCodeMap: { [key: string]: string } = {
    afghanistan: 'AF',
    'aland islands': 'AX',
    albania: 'AL',
    algeria: 'DZ',
    'american samoa': 'AS',
    andorra: 'AD',
    angola: 'AO',
    anguilla: 'AI',
    antarctica: 'AQ',
    'antigua and barbuda': 'AG',
    argentina: 'AR',
    armenia: 'AM',
    aruba: 'AW',
    australia: 'AU',
    austria: 'AT',
    azerbaijan: 'AZ',
    bahamas: 'BS',
    bahrain: 'BH',
    bangladesh: 'BD',
    barbados: 'BB',
    belarus: 'BY',
    belgium: 'BE',
    belize: 'BZ',
    benin: 'BJ',
    bermuda: 'BM',
    bhutan: 'BT',
    bolivia: 'BO',
    'bonaire, saint eustatius and saba': 'BQ',
    'bosnia and herzegovina': 'BA',
    botswana: 'BW',
    'bouvet island': 'BV',
    brazil: 'BR',
    'british indian ocean territory': 'IO',
    'british virgin islands': 'VG',
    brunei: 'BN',
    bulgaria: 'BG',
    'burkina faso': 'BF',
    burundi: 'BI',
    cambodia: 'KH',
    cameroon: 'CM',
    canada: 'CA',
    'cape verde': 'CV',
    'cayman islands': 'KY',
    'central african republic': 'CF',
    chad: 'TD',
    chile: 'CL',
    china: 'CN',
    'christmas island': 'CX',
    'cocos islands': 'CC',
    colombia: 'CO',
    comoros: 'KM',
    'cook islands': 'CK',
    'costa rica': 'CR',
    croatia: 'HR',
    cuba: 'CU',
    curacao: 'CW',
    cyprus: 'CY',
    czechia: 'CZ',
    'democratic republic of the congo': 'CD',
    denmark: 'DK',
    djibouti: 'DJ',
    dominica: 'DM',
    'dominican republic': 'DO',
    'east timor': 'TL',
    ecuador: 'EC',
    egypt: 'EG',
    'el salvador': 'SV',
    'equatorial guinea': 'GQ',
    eritrea: 'ER',
    estonia: 'EE',
    ethiopia: 'ET',
    'falkland islands': 'FK',
    'faroe islands': 'FO',
    fiji: 'FJ',
    finland: 'FI',
    france: 'FR',
    'french guiana': 'GF',
    'french polynesia': 'PF',
    'french southern territories': 'TF',
    gabon: 'GA',
    gambia: 'GM',
    georgia: 'GE',
    germany: 'DE',
    ghana: 'GH',
    gibraltar: 'GI',
    greece: 'GR',
    greenland: 'GL',
    grenada: 'GD',
    guadeloupe: 'GP',
    guam: 'GU',
    guatemala: 'GT',
    guernsey: 'GG',
    guinea: 'GN',
    'guinea-bissau': 'GW',
    guyana: 'GY',
    haiti: 'HT',
    'heard island and mcdonald islands': 'HM',
    honduras: 'HN',
    'hong kong': 'HK',
    hungary: 'HU',
    iceland: 'IS',
    india: 'IN',
    indonesia: 'ID',
    iran: 'IR',
    iraq: 'IQ',
    ireland: 'IE',
    'isle of man': 'IM',
    israel: 'IL',
    italy: 'IT',
    'ivory coast': 'CI',
    jamaica: 'JM',
    japan: 'JP',
    jersey: 'JE',
    jordan: 'JO',
    kazakhstan: 'KZ',
    kenya: 'KE',
    kiribati: 'KI',
    kosovo: 'XK',
    kuwait: 'KW',
    kyrgyzstan: 'KG',
    laos: 'LA',
    latvia: 'LV',
    lebanon: 'LB',
    lesotho: 'LS',
    liberia: 'LR',
    libya: 'LY',
    liechtenstein: 'LI',
    lithuania: 'LT',
    luxembourg: 'LU',
    macao: 'MO',
    macedonia: 'MK',
    madagascar: 'MG',
    malawi: 'MW',
    malaysia: 'MY',
    maldives: 'MV',
    mali: 'ML',
    malta: 'MT',
    'marshall islands': 'MH',
    martinique: 'MQ',
    mauritania: 'MR',
    mauritius: 'MU',
    mayotte: 'YT',
    mexico: 'MX',
    micronesia: 'FM',
    moldova: 'MD',
    monaco: 'MC',
    mongolia: 'MN',
    montenegro: 'ME',
    montserrat: 'MS',
    morocco: 'MA',
    mozambique: 'MZ',
    myanmar: 'MM',
    namibia: 'NA',
    nauru: 'NR',
    nepal: 'NP',
    netherlands: 'NL',
    'netherlands antilles': 'AN',
    'new caledonia': 'NC',
    'new zealand': 'NZ',
    nicaragua: 'NI',
    niger: 'NE',
    nigeria: 'NG',
    niue: 'NU',
    'norfolk island': 'NF',
    'north korea': 'KP',
    'northern mariana islands': 'MP',
    norway: 'NO',
    oman: 'OM',
    pakistan: 'PK',
    palau: 'PW',
    'palestinian territory': 'PS',
    panama: 'PA',
    'papua new guinea': 'PG',
    paraguay: 'PY',
    peru: 'PE',
    philippines: 'PH',
    pitcairn: 'PN',
    poland: 'PL',
    portugal: 'PT',
    'puerto rico': 'PR',
    qatar: 'QA',
    'republic of the congo': 'CG',
    reunion: 'RE',
    romania: 'RO',
    russia: 'RU',
    rwanda: 'RW',
    'saint barthelemy': 'BL',
    'saint helena': 'SH',
    'saint kitts and nevis': 'KN',
    'saint lucia': 'LC',
    'saint martin': 'MF',
    'saint pierre and miquelon': 'PM',
    'saint vincent and the grenadines': 'VC',
    samoa: 'WS',
    'san marino': 'SM',
    'sao tome and principe': 'ST',
    'saudi arabia': 'SA',
    senegal: 'SN',
    serbia: 'RS',
    'serbia and montenegro': 'CS',
    seychelles: 'SC',
    'sierra leone': 'SL',
    singapore: 'SG',
    'sint maarten': 'SX',
    slovakia: 'SK',
    slovenia: 'SI',
    'solomon islands': 'SB',
    somalia: 'SO',
    'south africa': 'ZA',
    'south georgia and the south sandwich islands': 'GS',
    'south korea': 'KR',
    'south sudan': 'SS',
    spain: 'ES',
    'sri lanka': 'LK',
    sudan: 'SD',
    suriname: 'SR',
    'svalbard and jan mayen': 'SJ',
    swaziland: 'SZ',
    sweden: 'SE',
    switzerland: 'CH',
    syria: 'SY',
    taiwan: 'TW',
    tajikistan: 'TJ',
    tanzania: 'TZ',
    thailand: 'TH',
    togo: 'TG',
    tokelau: 'TK',
    tonga: 'TO',
    'trinidad and tobago': 'TT',
    tunisia: 'TN',
    turkey: 'TR',
    turkmenistan: 'TM',
    'turks and caicos islands': 'TC',
    tuvalu: 'TV',
    'u.s. virgin islands': 'VI',
    uganda: 'UG',
    ukraine: 'UA',
    'united arab emirates': 'AE',
    'united kingdom': 'GB',
    'united states': 'US',
    'united states minor outlying islands': 'UM',
    uruguay: 'UY',
    uzbekistan: 'UZ',
    vanuatu: 'VU',
    vatican: 'VA',
    venezuela: 'VE',
    vietnam: 'VN',
    'wallis and futuna': 'WF',
    'western sahara': 'EH',
    yemen: 'YE',
    zambia: 'ZM',
    zimbabwe: 'ZW',
  };

  private reverseCountryCodeMap: { [key: string]: string } = {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BQ: 'Bonaire, Saint Eustatius and Saba',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos Islands',
    CD: 'Democratic Republic of the Congo',
    CF: 'Central African Republic',
    CG: 'Republic of the Congo',
    CH: 'Switzerland',
    CI: 'Ivory Coast',
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CS: 'Serbia and Montenegro',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Curacao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands',
    FM: 'Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and Mcdonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territory',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Syria',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'East Timor',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'United States Minor Outlying Islands',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'U.S. Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  };

  // from https://developers.google.com/public-data/docs/canonical/countries_csv
  private countryLocationMap: { [key: string]: Location } = {
    AD: { latitude: 42.546245, longitude: 1.601554 },
    AE: { latitude: 23.424076, longitude: 53.847818 },
    AF: { latitude: 33.93911, longitude: 67.709953 },
    AG: { latitude: 17.060816, longitude: -61.796428 },
    AI: { latitude: 18.220554, longitude: -63.068615 },
    AL: { latitude: 41.153332, longitude: 20.168331 },
    AM: { latitude: 40.069099, longitude: 45.038189 },
    AN: { latitude: 12.226079, longitude: -69.060087 },
    AO: { latitude: -11.202692, longitude: 17.873887 },
    AQ: { latitude: -75.250973, longitude: -0.071389 },
    AR: { latitude: -38.416097, longitude: -63.616672 },
    AS: { latitude: -14.270972, longitude: -170.132217 },
    AT: { latitude: 47.516231, longitude: 14.550072 },
    AU: { latitude: -25.274398, longitude: 133.775136 },
    AW: { latitude: 12.52111, longitude: -69.968338 },
    AZ: { latitude: 40.143105, longitude: 47.576927 },
    BA: { latitude: 43.915886, longitude: 17.679076 },
    BB: { latitude: 13.193887, longitude: -59.543198 },
    BD: { latitude: 23.684994, longitude: 90.356331 },
    BE: { latitude: 50.503887, longitude: 4.469936 },
    BF: { latitude: 12.238333, longitude: -1.561593 },
    BG: { latitude: 42.733883, longitude: 25.48583 },
    BH: { latitude: 25.930414, longitude: 50.637772 },
    BI: { latitude: -3.373056, longitude: 29.918886 },
    BJ: { latitude: 9.30769, longitude: 2.315834 },
    BM: { latitude: 32.321384, longitude: -64.75737 },
    BN: { latitude: 4.535277, longitude: 114.727669 },
    BO: { latitude: -16.290154, longitude: -63.588653 },
    BR: { latitude: -14.235004, longitude: -51.92528 },
    BS: { latitude: 25.03428, longitude: -77.39628 },
    BT: { latitude: 27.514162, longitude: 90.433601 },
    BV: { latitude: -54.423199, longitude: 3.413194 },
    BW: { latitude: -22.328474, longitude: 24.684866 },
    BY: { latitude: 53.709807, longitude: 27.953389 },
    BZ: { latitude: 17.189877, longitude: -88.49765 },
    CA: { latitude: 56.130366, longitude: -106.346771 },
    CC: { latitude: -12.164165, longitude: 96.870956 },
    CD: { latitude: -4.038333, longitude: 21.758664 },
    CF: { latitude: 6.611111, longitude: 20.939444 },
    CG: { latitude: -0.228021, longitude: 15.827659 },
    CH: { latitude: 46.818188, longitude: 8.227512 },
    CI: { latitude: 7.539989, longitude: -5.54708 },
    CK: { latitude: -21.236736, longitude: -159.777671 },
    CL: { latitude: -35.675147, longitude: -71.542969 },
    CM: { latitude: 7.369722, longitude: 12.354722 },
    CN: { latitude: 35.86166, longitude: 104.195397 },
    CO: { latitude: 4.570868, longitude: -74.297333 },
    CR: { latitude: 9.748917, longitude: -83.753428 },
    CU: { latitude: 21.521757, longitude: -77.781167 },
    CV: { latitude: 16.002082, longitude: -24.013197 },
    CX: { latitude: -10.447525, longitude: 105.690449 },
    CY: { latitude: 35.126413, longitude: 33.429859 },
    CZ: { latitude: 49.817492, longitude: 15.472962 },
    DE: { latitude: 51.165691, longitude: 10.451526 },
    DJ: { latitude: 11.825138, longitude: 42.590275 },
    DK: { latitude: 56.26392, longitude: 9.501785 },
    DM: { latitude: 15.414999, longitude: -61.370976 },
    DO: { latitude: 18.735693, longitude: -70.162651 },
    DZ: { latitude: 28.033886, longitude: 1.659626 },
    EC: { latitude: -1.831239, longitude: -78.183406 },
    EE: { latitude: 58.595272, longitude: 25.013607 },
    EG: { latitude: 26.820553, longitude: 30.802498 },
    EH: { latitude: 24.215527, longitude: -12.885834 },
    ER: { latitude: 15.179384, longitude: 39.782334 },
    ES: { latitude: 40.463667, longitude: -3.74922 },
    ET: { latitude: 9.145, longitude: 40.489673 },
    FI: { latitude: 61.92411, longitude: 25.748151 },
    FJ: { latitude: -16.578193, longitude: 179.414413 },
    FK: { latitude: -51.796253, longitude: -59.523613 },
    FM: { latitude: 7.425554, longitude: 150.550812 },
    FO: { latitude: 61.892635, longitude: -6.911806 },
    FR: { latitude: 46.227638, longitude: 2.213749 },
    GA: { latitude: -0.803689, longitude: 11.609444 },
    GB: { latitude: 55.378051, longitude: -3.435973 },
    GD: { latitude: 12.262776, longitude: -61.604171 },
    GE: { latitude: 42.315407, longitude: 43.356892 },
    GF: { latitude: 3.933889, longitude: -53.125782 },
    GG: { latitude: 49.465691, longitude: -2.585278 },
    GH: { latitude: 7.946527, longitude: -1.023194 },
    GI: { latitude: 36.137741, longitude: -5.345374 },
    GL: { latitude: 71.706936, longitude: -42.604303 },
    GM: { latitude: 13.443182, longitude: -15.310139 },
    GN: { latitude: 9.945587, longitude: -9.696645 },
    GP: { latitude: 16.995971, longitude: -62.067641 },
    GQ: { latitude: 1.650801, longitude: 10.267895 },
    GR: { latitude: 39.074208, longitude: 21.824312 },
    GS: { latitude: -54.429579, longitude: -36.587909 },
    GT: { latitude: 15.783471, longitude: -90.230759 },
    GU: { latitude: 13.444304, longitude: 144.793731 },
    GW: { latitude: 11.803749, longitude: -15.180413 },
    GY: { latitude: 4.860416, longitude: -58.93018 },
    GZ: { latitude: 31.354676, longitude: 34.308825 },
    HK: { latitude: 22.396428, longitude: 114.109497 },
    HM: { latitude: -53.08181, longitude: 73.504158 },
    HN: { latitude: 15.199999, longitude: -86.241905 },
    HR: { latitude: 45.1, longitude: 15.2 },
    HT: { latitude: 18.971187, longitude: -72.285215 },
    HU: { latitude: 47.162494, longitude: 19.503304 },
    ID: { latitude: -0.789275, longitude: 113.921327 },
    IE: { latitude: 53.41291, longitude: -8.24389 },
    IL: { latitude: 31.046051, longitude: 34.851612 },
    IM: { latitude: 54.236107, longitude: -4.548056 },
    IN: { latitude: 20.593684, longitude: 78.96288 },
    IO: { latitude: -6.343194, longitude: 71.876519 },
    IQ: { latitude: 33.223191, longitude: 43.679291 },
    IR: { latitude: 32.427908, longitude: 53.688046 },
    IS: { latitude: 64.963051, longitude: -19.020835 },
    IT: { latitude: 41.87194, longitude: 12.56738 },
    JE: { latitude: 49.214439, longitude: -2.13125 },
    JM: { latitude: 18.109581, longitude: -77.297508 },
    JO: { latitude: 30.585164, longitude: 36.238414 },
    JP: { latitude: 36.204824, longitude: 138.252924 },
    KE: { latitude: -0.023559, longitude: 37.906193 },
    KG: { latitude: 41.20438, longitude: 74.766098 },
    KH: { latitude: 12.565679, longitude: 104.990963 },
    KI: { latitude: -3.370417, longitude: -168.734039 },
    KM: { latitude: -11.875001, longitude: 43.872219 },
    KN: { latitude: 17.357822, longitude: -62.782998 },
    KP: { latitude: 40.339852, longitude: 127.510093 },
    KR: { latitude: 35.907757, longitude: 127.766922 },
    KW: { latitude: 29.31166, longitude: 47.481766 },
    KY: { latitude: 19.513469, longitude: -80.566956 },
    KZ: { latitude: 48.019573, longitude: 66.923684 },
    LA: { latitude: 19.85627, longitude: 102.495496 },
    LB: { latitude: 33.854721, longitude: 35.862285 },
    LC: { latitude: 13.909444, longitude: -60.978893 },
    LI: { latitude: 47.166, longitude: 9.555373 },
    LK: { latitude: 7.873054, longitude: 80.771797 },
    LR: { latitude: 6.428055, longitude: -9.429499 },
    LS: { latitude: -29.609988, longitude: 28.233608 },
    LT: { latitude: 55.169438, longitude: 23.881275 },
    LU: { latitude: 49.815273, longitude: 6.129583 },
    LV: { latitude: 56.879635, longitude: 24.603189 },
    LY: { latitude: 26.3351, longitude: 17.228331 },
    MA: { latitude: 31.791702, longitude: -7.09262 },
    MC: { latitude: 43.750298, longitude: 7.412841 },
    MD: { latitude: 47.411631, longitude: 28.369885 },
    ME: { latitude: 42.708678, longitude: 19.37439 },
    MG: { latitude: -18.766947, longitude: 46.869107 },
    MH: { latitude: 7.131474, longitude: 171.184478 },
    MK: { latitude: 41.608635, longitude: 21.745275 },
    ML: { latitude: 17.570692, longitude: -3.996166 },
    MM: { latitude: 21.913965, longitude: 95.956223 },
    MN: { latitude: 46.862496, longitude: 103.846656 },
    MO: { latitude: 22.198745, longitude: 113.543873 },
    MP: { latitude: 17.33083, longitude: 145.38469 },
    MQ: { latitude: 14.641528, longitude: -61.024174 },
    MR: { latitude: 21.00789, longitude: -10.940835 },
    MS: { latitude: 16.742498, longitude: -62.187366 },
    MT: { latitude: 35.937496, longitude: 14.375416 },
    MU: { latitude: -20.348404, longitude: 57.552152 },
    MV: { latitude: 3.202778, longitude: 73.22068 },
    MW: { latitude: -13.254308, longitude: 34.301525 },
    MX: { latitude: 23.634501, longitude: -102.552784 },
    MY: { latitude: 4.210484, longitude: 101.975766 },
    MZ: { latitude: -18.665695, longitude: 35.529562 },
    NA: { latitude: -22.95764, longitude: 18.49041 },
    NC: { latitude: -20.904305, longitude: 165.618042 },
    NE: { latitude: 17.607789, longitude: 8.081666 },
    NF: { latitude: -29.040835, longitude: 167.954712 },
    NG: { latitude: 9.081999, longitude: 8.675277 },
    NI: { latitude: 12.865416, longitude: -85.207229 },
    NL: { latitude: 52.132633, longitude: 5.291266 },
    NO: { latitude: 60.472024, longitude: 8.468946 },
    NP: { latitude: 28.394857, longitude: 84.124008 },
    NR: { latitude: -0.522778, longitude: 166.931503 },
    NU: { latitude: -19.054445, longitude: -169.867233 },
    NZ: { latitude: -40.900557, longitude: 174.885971 },
    OM: { latitude: 21.512583, longitude: 55.923255 },
    PA: { latitude: 8.537981, longitude: -80.782127 },
    PE: { latitude: -9.189967, longitude: -75.015152 },
    PF: { latitude: -17.679742, longitude: -149.406843 },
    PG: { latitude: -6.314993, longitude: 143.95555 },
    PH: { latitude: 12.879721, longitude: 121.774017 },
    PK: { latitude: 30.375321, longitude: 69.345116 },
    PL: { latitude: 51.919438, longitude: 19.145136 },
    PM: { latitude: 46.941936, longitude: -56.27111 },
    PN: { latitude: -24.703615, longitude: -127.439308 },
    PR: { latitude: 18.220833, longitude: -66.590149 },
    PS: { latitude: 31.952162, longitude: 35.233154 },
    PT: { latitude: 39.399872, longitude: -8.224454 },
    PW: { latitude: 7.51498, longitude: 134.58252 },
    PY: { latitude: -23.442503, longitude: -58.443832 },
    QA: { latitude: 25.354826, longitude: 51.183884 },
    RE: { latitude: -21.115141, longitude: 55.536384 },
    RO: { latitude: 45.943161, longitude: 24.96676 },
    RS: { latitude: 44.016521, longitude: 21.005859 },
    RU: { latitude: 61.52401, longitude: 105.318756 },
    RW: { latitude: -1.940278, longitude: 29.873888 },
    SA: { latitude: 23.885942, longitude: 45.079162 },
    SB: { latitude: -9.64571, longitude: 160.156194 },
    SC: { latitude: -4.679574, longitude: 55.491977 },
    SD: { latitude: 12.862807, longitude: 30.217636 },
    SE: { latitude: 60.128161, longitude: 18.643501 },
    SG: { latitude: 1.352083, longitude: 103.819836 },
    SH: { latitude: -24.143474, longitude: -10.030696 },
    SI: { latitude: 46.151241, longitude: 14.995463 },
    SJ: { latitude: 77.553604, longitude: 23.670272 },
    SK: { latitude: 48.669026, longitude: 19.699024 },
    SL: { latitude: 8.460555, longitude: -11.779889 },
    SM: { latitude: 43.94236, longitude: 12.457777 },
    SN: { latitude: 14.497401, longitude: -14.452362 },
    SO: { latitude: 5.152149, longitude: 46.199616 },
    SR: { latitude: 3.919305, longitude: -56.027783 },
    SS: { latitude: 7.862178, longitude: 27.4511832 },
    ST: { latitude: 0.18636, longitude: 6.613081 },
    SV: { latitude: 13.794185, longitude: -88.89653 },
    SY: { latitude: 34.802075, longitude: 38.996815 },
    SZ: { latitude: -26.522503, longitude: 31.465866 },
    TC: { latitude: 21.694025, longitude: -71.797928 },
    TD: { latitude: 15.454166, longitude: 18.732207 },
    TF: { latitude: -49.280366, longitude: 69.348557 },
    TG: { latitude: 8.619543, longitude: 0.824782 },
    TH: { latitude: 15.870032, longitude: 100.992541 },
    TJ: { latitude: 38.861034, longitude: 71.276093 },
    TK: { latitude: -8.967363, longitude: -171.855881 },
    TL: { latitude: -8.874217, longitude: 125.727539 },
    TM: { latitude: 38.969719, longitude: 59.556278 },
    TN: { latitude: 33.886917, longitude: 9.537499 },
    TO: { latitude: -21.178986, longitude: -175.198242 },
    TR: { latitude: 38.963745, longitude: 35.243322 },
    TT: { latitude: 10.691803, longitude: -61.222503 },
    TV: { latitude: -7.109535, longitude: 177.64933 },
    TW: { latitude: 23.69781, longitude: 120.960515 },
    TZ: { latitude: -6.369028, longitude: 34.888822 },
    UA: { latitude: 48.379433, longitude: 31.16558 },
    UG: { latitude: 1.373333, longitude: 32.290275 },
    US: { latitude: 37.09024, longitude: -95.712891 },
    UY: { latitude: -32.522779, longitude: -55.765835 },
    UZ: { latitude: 41.377491, longitude: 64.585262 },
    VA: { latitude: 41.902916, longitude: 12.453389 },
    VC: { latitude: 12.984305, longitude: -61.287228 },
    VE: { latitude: 6.42375, longitude: -66.58973 },
    VG: { latitude: 18.420695, longitude: -64.639968 },
    VI: { latitude: 18.335765, longitude: -64.896335 },
    VN: { latitude: 14.058324, longitude: 108.277199 },
    VU: { latitude: -15.376706, longitude: 166.959158 },
    WF: { latitude: -13.768752, longitude: -177.156097 },
    WS: { latitude: -13.759029, longitude: -172.104629 },
    XK: { latitude: 42.602636, longitude: 20.902977 },
    YE: { latitude: 15.552727, longitude: 48.516388 },
    YT: { latitude: -12.8275, longitude: 45.166244 },
    ZA: { latitude: -30.559482, longitude: 22.937506 },
    ZM: { latitude: -13.133897, longitude: 27.849332 },
    ZW: { latitude: -19.015438, longitude: 29.154857 },
  };

  private countriesEnabledForDashboard = [
    'Argentina',
    'Australia',
    'Bahrain',
    'Bolivia',
    'Brazil',
    'Cambodia',
    'Canada',
    'Chile',
    'Colombia',
    'Ecuador',
    'Egypt',
    'France',
    'Germany',
    'Hong Kong',
    'Italy',
    'India',
    'Indonesia',
    'Iran',
    'Japan',
    'Kuwait',
    'Lithuania',
    'Malaysia',
    'Mexico',
    'Myanmar',
    'Netherlands',
    'New Zealand',
    'Oman',
    'Pakistan',
    'Panama',
    'Paraguay',
    'Peru',
    'Philippines',
    'Puerto Rico',
    'Qatar',
    'Saudi Arabia',
    'Singapore',
    'South Africa',
    'South Korea',
    'Spain',
    'Thailand',
    'Turkey',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Vietnam',
  ];

  private countriesTimezonesStrings = [
    { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
    { label: '(GMT-09:00) Alaska', value: 'America/Anchorage' },
    {
      label: '(GMT-08:00) Pacific Time (US & Canada)',
      value: 'America/Los_Angeles',
    },
    { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
    { label: '(GMT-07:00) Arizona', value: 'America/Phoenix' },
    {
      label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
      value: 'America/Chihuahua',
    },
    {
      label: '(GMT-07:00) Mountain Time (US & Canada)',
      value: 'America/Denver',
    },
    { label: '(GMT-06:00) Central America', value: 'America/Managua' },
    {
      label: '(GMT-06:00) Central Time (US & Canada)',
      value: 'America/Chicago',
    },
    {
      label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
      value: 'America/Mexico_City',
    },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    {
      label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
      value: 'America/Bogota',
    },
    {
      label: '(GMT-05:00) Eastern Time (US & Canada)',
      value: 'America/New_York',
    },
    {
      label: '(GMT-05:00) Indiana (East)',
      value: 'America/Indiana/Indianapolis',
    },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
    {
      label: '(GMT-03:00) Buenos Aires, Georgetown',
      value: 'America/Argentina/Buenos_Aires',
    },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    {
      label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
      value: 'Africa/Casablanca',
    },
    {
      label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      value: 'Etc/Greenwich',
    },
    {
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: 'Europe/Amsterdam',
    },
    {
      label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: 'Europe/Belgrade',
    },
    {
      label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
      value: 'Europe/Brussels',
    },
    {
      label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
      value: 'Europe/Sarajevo',
    },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    {
      label: '(GMT+02:00) Athens, Bucharest, Istanbul',
      value: 'Europe/Athens',
    },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
    {
      label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: 'Europe/Helsinki',
    },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
    {
      label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
      value: 'Europe/Moscow',
    },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
    {
      label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
      value: 'Asia/Karachi',
    },
    {
      label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      value: 'Asia/Calcutta',
    },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    {
      label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
      value: 'Asia/Hong_Kong',
    },
    {
      label: '(GMT+08:00) Kuala Lumpur, Singapore',
      value: 'Asia/Kuala_Lumpur',
    },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
    {
      label: '(GMT+10:00) Canberra, Melbourne, Sydney',
      value: 'Australia/Canberra',
    },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
    {
      label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
      value: 'Asia/Magadan',
    },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
    {
      label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
      value: 'Pacific/Fiji',
    },
    { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  ];

  private countriesTimezonesInts = [
    { label: '(GMT-12:00) International Date Line West', value: '-12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: '-11' },
    { label: '(GMT-10:00) Hawaii', value: '-10' },
    { label: '(GMT-09:00) Alaska', value: '-9' },
    { label: '(GMT-08:00) Pacific Time (US & Canada)', value: '-8' },
    { label: '(GMT-08:00) Tijuana, Baja California', value: '-8' },
    { label: '(GMT-07:00) Arizona', value: '-7' },
    { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: '-7' },
    { label: '(GMT-07:00) Mountain Time (US & Canada)', value: '-7' },
    { label: '(GMT-06:00) Central America', value: '-6' },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: '-6' },
    { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: '-5' },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: '-5' },
    { label: '(GMT-05:00) Indiana (East)', value: '-5' },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: '-4' },
    { label: '(GMT-04:00) Caracas, La Paz', value: '-4' },
    { label: '(GMT-04:00) Manaus', value: '-4' },
    { label: '(GMT-04:00) Santiago', value: '-4' },
    { label: '(GMT-03:30) Newfoundland', value: '-3.5' },
    { label: '(GMT-03:00) Brasilia', value: '-3' },
    { label: '(GMT-03:00) Buenos Aires, Georgetown', value: '-3' },
    { label: '(GMT-03:00) Greenland', value: '-3' },
    { label: '(GMT-03:00) Montevideo', value: '-3' },
    { label: '(GMT-02:00) Mid-Atlantic', value: '-2' },
    { label: '(GMT-01:00) Cape Verde Is.', value: '-1' },
    { label: '(GMT-01:00) Azores', value: '-1' },
    { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: '0' },
    {
      label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
      value: '0',
    },
    {
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      value: '1',
    },
    {
      label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      value: '1',
    },
    { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: '1' },
    { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: '1' },
    { label: '(GMT+01:00) West Central Africa', value: '1' },
    { label: '(GMT+02:00) Amman', value: '2' },
    { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: '2' },
    { label: '(GMT+02:00) Beirut', value: '2' },
    { label: '(GMT+02:00) Cairo', value: '2' },
    { label: '(GMT+02:00) Harare, Pretoria', value: '2' },
    {
      label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      value: '2',
    },
    { label: '(GMT+02:00) Jerusalem', value: '2' },
    { label: '(GMT+02:00) Minsk', value: '2' },
    { label: '(GMT+02:00) Windhoek', value: '2' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: '3' },
    { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: '3' },
    { label: '(GMT+03:00) Nairobi', value: '3' },
    { label: '(GMT+03:00) Tbilisi', value: '3' },
    { label: '(GMT+03:30) Tehran', value: '3.5' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: '4' },
    { label: '(GMT+04:00) Baku', value: '4' },
    { label: '(GMT+04:00) Yerevan', value: '4' },
    { label: '(GMT+04:30) Kabul', value: '4.5' },
    { label: '(GMT+05:00) Yekaterinburg', value: '5' },
    { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: '5' },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: '5.5' },
    { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: '5.5' },
    { label: '(GMT+05:45) Kathmandu', value: '5.75' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: '6' },
    { label: '(GMT+06:00) Astana, Dhaka', value: '6' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: '6.5' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: '7' },
    { label: '(GMT+07:00) Krasnoyarsk', value: '7' },
    { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: '8' },
    { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: '8' },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: '8' },
    { label: '(GMT+08:00) Perth', value: '8' },
    { label: '(GMT+08:00) Taipei', value: '8' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: '9' },
    { label: '(GMT+09:00) Seoul', value: '9' },
    { label: '(GMT+09:00) Yakutsk', value: '9' },
    { label: '(GMT+09:30) Adelaide', value: '9.5' },
    { label: '(GMT+09:30) Darwin', value: '9.5' },
    { label: '(GMT+10:00) Brisbane', value: '10' },
    { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: '10' },
    { label: '(GMT+10:00) Hobart', value: '10' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: '10' },
    { label: '(GMT+10:00) Vladivostok', value: '10' },
    { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: '11' },
    { label: '(GMT+12:00) Auckland, Wellington', value: '12' },
    { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: '12' },
    { label: "(GMT+13:00) Nuku'alofa", value: '13' },
  ];

  countryCode(country: string): string {
    return this.countryCodeMap[country.toLowerCase()];
  }

  countryName(countryCode: string): string {
    return this.reverseCountryCodeMap[countryCode.toUpperCase()];
  }

  location(countryCode: string): Location {
    return this.countryLocationMap[countryCode];
  }

  countriesList(): string[] {
    return Object.keys(this.countryCodeMap).map((country) => startCase(country));
  }

  countriesListWithCode(): { value: string; name: string }[] {
    return Object.keys(this.countryCodeMap).map((country) => {
      return {
        value: this.countryCodeMap[country],
        name: startCase(country),
      };
    });
  }

  getTimezoneList(): CountryTimezone[] {
    return this.countriesTimezonesStrings.map((country) => {
      return {
        label: country.label,
        value: country.value,
      };
    });
  }

  getDetailedCountries(regionsEnabled: string[]): DetailedCountry[] {
    regionsEnabled = regionsEnabled.map((region) => region.toLowerCase());
    return Object.keys(this.countryCodeMap).map((country) => {
      return {
        name: startCase(country),
        disabled: !this.isAccessible(country, regionsEnabled),
      };
    });
  }

  getDashboardEnabledCountries(regionsEnabled: string[]): DetailedCountry[] {
    regionsEnabled = regionsEnabled.map((region) => region.toLowerCase());
    return this.countriesEnabledForDashboard.map((country) => {
      return {
        name: startCase(country),
        disabled: !this.isAccessible(country, regionsEnabled),
      };
    });
  }

  private isAccessible(country: string, regionsEnabled: string[]): boolean {
    return includes(regionsEnabled, country.toLowerCase()) || includes(regionsEnabled, 'all_regions');
  }
}
