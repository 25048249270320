import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class StoriesService {
  constructor(private http: HttpClient) {}

  generateStoriesReport(
    reportTitle: string,
    hashtags: string[],
    mentions: string[],
    usernames: string[],
  ): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/stories/generate`, {
        reportTitle,
        hashtags,
        mentions,
        usernames,
        timezone: Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
      })
      .toPromise();
  }

  scheduleStoriesReport(
    reportTitle: string,
    hashtags: string[],
    mentions: string[],
    usernames: string[],
    scheduleDetails: ReportScheduleDetails,
  ): Promise<ScheduledReport> {
    return this.http
      .post<ScheduledReport>(`${environment.api}/api/stories/schedule`, {
        reportTitle,
        hashtags,
        mentions,
        usernames,
        scheduleDetails,
      })
      .toPromise();
  }

  getScheduledStoriesReports(): Promise<ScheduledReport[]> {
    return this.http.get<ScheduledReport[]>(`${environment.api}/api/stories/schedule`).toPromise();
  }

  deleteScheduledStoriesReport(reportId: string): Promise<any> {
    return this.http.delete(`${environment.api}/api/stories/schedule/${reportId}`).toPromise();
  }
}

export class ScheduledReport {
  id: string;
  reportTitle: string;
  hashtags: string[];
  usernames: string[];
  mentions: string[];
  scheduleDetails: ReportScheduleDetails;
}

export class ReportScheduleDetails {
  date: DateFilter = new DateFilter();
  time: TimeFilter = new TimeFilter();
  timezone: string;
}

export class DateFilter {
  to: string;
  from: string;
}

export class TimeFilter {
  hour: number;
  minute: number;

  constructor() {
    const time = new Date();
    this.hour = time.getHours();
    this.minute = time.getMinutes();
  }
}
