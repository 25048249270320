import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GroupInfo, GroupService } from '../../../@core/data/group.service';
import { PlatformsUpperEnum } from '../../../enums';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import utils from '../../../@core/utils/utils';
import { CreateGroupModalComponent } from '../../group-modals/create-group-modal/create-group-modal.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-influencer-group-tab',
  templateUrl: './influencer-group-tab.component.html',
  styleUrls: ['./influencer-group-tab.component.scss'],
})
export class InfluencerGroupTabComponent implements OnInit, OnDestroy {
  @Input() platform: PlatformsUpperEnum;

  influencerGroups: GroupInfo[] = [];
  activeGroupSlugName: string;
  search = new FormControl('');

  groupCreatedSubscription: Subscription;
  groupUpdatedSubscription: Subscription;
  groupDeletedNewSubscription: Subscription;
  activeGroupSlugNameSubscription: Subscription;
  loading = false;

  constructor(
    private groupService: GroupService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.subscribeToQueryParams();
    this.subscribeToGroupCreate();
    this.subscribeToGroupDelete();
    this.subscribeToGroupUpdate();
    this.fetchInfluencerGroups();
  }

  ngOnDestroy(): void {
    this.groupCreatedSubscription?.unsubscribe();
    this.groupUpdatedSubscription?.unsubscribe();
    this.groupDeletedNewSubscription?.unsubscribe();
    this.activeGroupSlugNameSubscription?.unsubscribe();
  }

  private subscribeToQueryParams(): void {
    this.activeGroupSlugNameSubscription = this.route.queryParams.subscribe((params) => {
      this.activeGroupSlugName = params['group'] || '';
    });
  }

  private subscribeToGroupCreate(): void {
    this.groupCreatedSubscription = this.groupService.groupCreatedNew$.subscribe((createdGroup) => {
      this.influencerGroups.unshift(createdGroup);
      this.appendUrlGroupNameToParams(createdGroup.slugName);
    });
  }

  private subscribeToGroupDelete(): void {
    this.groupDeletedNewSubscription = this.groupService.groupDeletedNew$.subscribe((deletedGroup) => {
      const index = this.influencerGroups.findIndex((group) => group.slugName === deletedGroup.slugName);
      if (index === -1) {
        return;
      }
      this.influencerGroups.splice(index, 1);
      this.appendUrlGroupNameToParams(this.influencerGroups[0].slugName);
    });
  }

  private subscribeToGroupUpdate(): void {
    this.groupUpdatedSubscription = this.groupService.groupUpdated$.subscribe((updatedGroup) => {
      const index = this.influencerGroups.findIndex((group) => group.slugName === updatedGroup.slugName);
      if (index === -1) {
        return;
      }
      this.influencerGroups[index].name =
        (updatedGroup['groupName'] || updatedGroup.name) ?? this.influencerGroups[index].name;
      this.influencerGroups[index].tags = updatedGroup.tags ?? this.influencerGroups[index].tags;
    });
  }

  private async fetchInfluencerGroups(): Promise<void> {
    this.loading = true;
    this.influencerGroups = await this.groupService.getAll(utils.findMatchedPlatform(this.platform));
    if (!this.activeGroupSlugName && this.influencerGroups[0]?.slugName) {
      this.appendUrlGroupNameToParams(this.influencerGroups[0].slugName);
    }
    this.loading = false;
  }

  public appendUrlGroupNameToParams(group: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { group },
      queryParamsHandling: 'merge',
    });
  }

  public createNewGroup(): void {
    const createGroupModal = this.modalService.open(CreateGroupModalComponent, {
      centered: true,
      windowClass: 'modal-md',
      backdrop: 'static',
    });

    const modalContent = createGroupModal.componentInstance;
    modalContent.platform = utils.convertToPlatformV2(this.platform);

    createGroupModal.result.then((result) => {
      if (result?.createdGroup?.slugName) {
        this.appendUrlGroupNameToParams(result.createdGroup.slugName);
      }
    });
  }

  public getGroupExtendedTags(tags: string[] = []): string {
    return [...tags].splice(2, 3).join(', ');
  }
}
