import { ChangeDetectorRef, Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { UserService } from '../../../@core/data/user.service';
import { AuthService } from '../../../@core/auth/auth.service';
import { SubscribedClientUser } from '../../../enums';

@Component({
  selector: 'ngx-email-introduction-modal',
  templateUrl: './email-introduction.component.html',
  styleUrls: ['./email-introduction.component.scss'],
})
export class EmailIntroductionModalComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
  ) {}
  @ViewChild('connect') public connectRef: TemplateRef<any>;
  @ViewChild('nextConnectStep') public nextConnectStepRef: TemplateRef<any>;
  @ViewChild('syncedAndRevokeRef') public syncedAndRevokeRef: TemplateRef<any>;
  @ViewChild('requestedGmailLinkRef') public requestedGmailLinkRef: TemplateRef<any>;
  @ViewChild('confirm') public confirmRef: TemplateRef<any>;

  public templateRefExp: TemplateRef<any>;

  public connectWith: 'gmail' | 'outlook' = 'gmail';

  public isManagedAccountScreen = false;

  public confirmModalStatus: 'revoke' | 'neverAskEmail' = 'neverAskEmail';

  public status: 'synced' | 'revoke';

  public loading = false;

  public email: string;

  private timer$: Subscription;

  public count = 10;

  public currentClient: SubscribedClientUser;

  public privacyPolicyHref = 'https://beaffable.notion.site/Privacy-Policy-Affable-d27d9c12e6054a729c418504c9631549';

  public googleManageAccessHref = 'https://support.google.com/accounts/answer/3466521';

  public isGmailLinkEnabled = this.authService.isGmailLinkEnabled();

  public emailData = {
    gmail: {
      icons: '../../../../assets/images/google-logo.png',
      name: 'Google Mail',
      connect_name: 'Google',
      disabled: false,
      connect_image: '../../../../assets/images/connect-gmail-account.png',
      info: `When you integrate your Google account with the Affable platform, you connect your gmail with our platform. By doing so, Affable will have access to some information about your emails, like the email address you’re sending them from, the email addresses of your recipients, what’s in the subject line, and what’s in the body of the email. We don’t store any of your data, only use the access to power our email integration and make life easier for you, never for any nefarious purposes of our own. It's your data.

And, you can always remove the integration from the Affable app by navigating to Manage Account  > Connect your email account with Affable and clicking ‘REVOKE CONNECTION’. You can also revoke Affable’s access from within your Google account by following the instructions listed <a target='_blank' href=${this.googleManageAccessHref}>here</a>.`,
    },
    outlook: {
      icons: '../../../../assets/images/office-365-logo.png',
      name: 'Microsoft Office 365',
      connect_name: 'Office 365',
      disabled: false,
      connect_image: '../../../../assets/images/connect-office-account.png',
      info: `When you integrate your Microsoft account with the Affable platform, you connect your Outlook with our platform.  By doing so, Affable will have access to some information about your emails, like the email address you’re sending them from, the email addresses of your recipients, what’s in the subject line, and what’s in the body of the email. We don’t store any of your data, only use the access to power our email integration and make life easier for you, never for any nefarious purposes of our own. It's your data.

And, you can always remove the integration from the Affable app by navigating to Manage Account  > Connect your email account with Affable and clicking ‘REVOKE CONNECTION’.`,
    },
  };

  public syncedAndRevoke = {
    synced: {
      title: 'Successfully Synced',
      image: () => '../../../../assets/images/successful.svg',
      msg: (provider) => `Affable has successfully synced with your ${this.platformName(provider)}`,
      second_msg: 'The given access can be revoked anytime',
      btn: 'GO TO CAMPAIGN DASHBOARD',
      redirect_msg: 'You’ll be taken to the Campaign Dashboard page automatically in',
      route: '/pages/campaign',
    },
    revoke: {
      title: 'Revoked Email Sync',
      image: (provider) => {
        switch (provider) {
          case 'gmail':
            return '../../../../assets/images/revoke-gmail.png';
          case 'outlook':
            return '../../../../assets/images/revoke-office.png';
        }
      },
      msg: (provider) => `Successfully revoked the connection of your ${this.platformName(provider)}`,
      second_msg: 'You can connect your email account anytime',
      btn: 'GO TO MANAGE ACCOUNT',
      redirect_msg: 'You’ll be taken to the Manage Account page automatically in',
      route: '/pages/account',
    },
  };

  public confirmModal = {
    neverAskEmail: {
      line1:
        'If you have clicked <strong>“Never ask me again”</strong> by mistake, click <strong>“Go back”</strong> to enter the email ID.',
      line2:
        ' Otherwise, you can click <strong>“Confirm”</strong>. And, you can always go to <strong>manage account</strong> to give access.',
    },
    revoke: {
      line1: 'If you have clicked <strong>“Revoke Connect”</strong> by mistake, click <strong>“Go back”</strong>.',
      line2: 'Or if you want to revoke the connect for sure, click <strong>“Confirm”</strong>.',
    },
  };

  async ngOnInit(): Promise<void> {
    this.currentClient = await this.userService.getClientUser();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    if (this.status === 'synced') {
      this.templateRefExp = this.syncedAndRevokeRef;
      this.observableTimer();
      return;
    }
    if (this.status === 'revoke') {
      this.templateRefExp = this.confirmRef;
      return;
    }
    this.templateRefExp = this.connectRef;
  }

  closeModal(): void {
    this.activeModal.close();
  }
  platformName(provider) {
    return this.emailData[provider]?.name;
  }

  public connectEmailPoints = [
    'Improve deliverability of your emails',
    'Establish authenticity by sending emails from your personal or brand email',
    'Build stronger relationships with influencers',
  ];

  navigate(): void {
    this.navigateToCampaignDashboard();
    this.closeModal();
  }

  navigateToCampaignDashboard(): void {
    if (this.status === 'synced' && this.router.url === '/pages/account') {
      this.router.navigate(['/pages/campaigns']);
    }
  }

  integrate(): void {
    this.loading = true;
    this.userService.clientEmailIntegration(this.connectWith).then(({ redirectUrl }) => {
      window.location.href = redirectUrl;
    });
  }

  submitConfirm(confirmModalStatus: 'revoke' | 'neverAskEmail') {
    if (confirmModalStatus === 'neverAskEmail') {
      return this.neverIntegrate();
    }
    if (confirmModalStatus === 'revoke') {
      this.revokeEmail();
    }
  }

  goBack(confirmModalStatus: 'revoke' | 'neverAskEmail') {
    if (confirmModalStatus === 'neverAskEmail') {
      return (this.templateRefExp = this.connectRef);
    }
    if (confirmModalStatus === 'revoke') {
      this.closeModal();
    }
  }

  neverIntegrate(): void {
    this.neverAskEmail();
    this.closeModal();
  }

  async revokeEmail(): Promise<void> {
    this.loading = true;
    try {
      const { email, provider } = await this.userService.revokeClientEmailIntegration(
        this.currentClient.integratedEmails[0].email,
      );
      this.currentClient.integratedEmails = [];
      this.email = email;
      this.connectWith = provider;
      this.observableTimer();
      this.templateRefExp = this.syncedAndRevokeRef;
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async requestGmailLink(): Promise<void> {
    this.loading = true;
    const { gmailLinkRequest } = await this.userService.requestClientGmailIntegration();
    this.currentClient.gmailLinkRequest = gmailLinkRequest;

    try {
      this.observableTimer();
      this.templateRefExp = this.requestedGmailLinkRef;
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  observableTimer(): void {
    const source = timer(0, 1000);
    this.timer$ = source.subscribe(() => {
      if (this.count < 1) {
        this.timer$.unsubscribe();
        this.navigateToCampaignDashboard();
        this.closeModal();
      }
      this.count--;
    });
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }

  neverAskEmail(): void {
    localStorage.setItem('neverAskEmail', 'true');
  }
}
