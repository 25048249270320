import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PlatformsV2 } from '../../enums';
import { InfluencerWithNotes } from '../../interfaces';

@Injectable()
export class NotesService {
  constructor(public http: HttpClient) {}

  fetchNotesForInfluencers(
    influencersUsernames: string[],
    platform: PlatformsV2,
    isGroup = false,
  ): Promise<InfluencerWithNotes[]> {
    return this.http
      .post<InfluencerWithNotes[]>(
        environment.api + `/api/notes/influencers`,
        { influencersUsernames },
        { params: { platform, isGroup: isGroup.toString() } },
      )
      .toPromise();
  }

  fetchNotesForInfluencer(influencerUsername: string, platform: PlatformsV2, isGroup: boolean): Promise<any> {
    return this.http
      .get<any>(environment.api + `/api/notes/influencer/${influencerUsername}`, {
        params: { platform, isGroup: isGroup.toString() },
      })
      .toPromise();
  }

  fetchNotesForInfluencerInCollabGroup(
    influencerUsername: string,
    platform: PlatformsV2,
    groupSlug: string,
  ): Promise<any> {
    return this.http
      .get<any>(environment.api + `/api/collabs/groups/${groupSlug}/notes/influencer/${influencerUsername}`, {
        params: { platform },
      })
      .toPromise();
  }

  addNote(
    influencerUsername: string,
    note: string,
    platform: PlatformsV2,
    file: File,
    isPrivate: boolean,
    isCollabPortal: boolean,
    campaignSlug?: string,
    groupSlug?: string,
  ): Promise<any> {
    const body = {
      note,
      isPrivate,
      campaignSlug,
      platform,
    };
    const formData = new FormData();
    formData.append('body', JSON.stringify(body));
    formData.append('files', file);
    if (isCollabPortal) {
      return this.http
        .post(environment.api + `/api/collabs/groups/${groupSlug}/notes/influencer/${influencerUsername}`, formData)
        .toPromise();
    } else {
      return this.http.post(environment.api + `/api/notes/influencer/${influencerUsername}`, formData).toPromise();
    }
  }

  editNote(
    noteId: string,
    updatedNote: string,
    platform: string,
    updatedFile: string,
    isPrivate: boolean,
    isCollabPortal: boolean,
    groupSlug?: string,
  ): Promise<any> {
    if (isCollabPortal) {
      return this.http
        .patch(environment.api + `/api/collabs/groups/${groupSlug}/notes/${noteId}/influencer`, {
          note: updatedNote,
          deletedFile: updatedFile.length > 0 ? updatedFile : undefined,
          isPrivate,
        })
        .toPromise();
    }
    return this.http
      .patch(environment.api + `/api/notes/${noteId}/influencer`, {
        note: updatedNote,
        deletedFile: updatedFile.length > 0 ? updatedFile : undefined,
        isPrivate,
      })
      .toPromise();
  }

  deleteNote(noteId: string, isCollabPortal: boolean, groupSlug?: string): Promise<any> {
    if (isCollabPortal) {
      return this.http
        .delete(`${environment.api}/api/collabs/groups/${groupSlug}/notes/${noteId}/influencer`)
        .toPromise();
    }
    return this.http.delete(`${environment.api}/api/notes/${noteId}/influencer`).toPromise();
  }

  downloadNoteAttachement(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }
}
