import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class InsightsGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasInsightsAddOns = this.auth.isInsightsEnabled();
    if (!hasInsightsAddOns) {
      console.error('Insights guard rejected access to ' + state.url);
      this.router.navigate(['pages', 'discovery', 'influencers']);
    }
    return hasInsightsAddOns;
  }
}
