import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-locked-card',
  templateUrl: './locked-card.component.html',
  styleUrls: ['./locked-card.component.scss'],
})
export class LockedCardComponent {
  @Input() image: string;
}
