import { ClientUser, Integrations } from '../interfaces';

export type SubscribedClientUser = ClientUser & {
  isBasicUser: boolean;
  isProUser: boolean;
  isFreeUser: boolean;
  isSubscriptionUser: boolean;
  isLiteUser: boolean;
} & {
  integrations: Integrations;
  storeDetails: {
    meta?: {
      orderSyncType?: 'SYNC' | 'UPLOAD';
    };
  };
} & { veraInvitationLink: string };

export class UserCredentials {
  email: string;
  password: string;

  constructor(email?: string) {
    this.email = email;
  }
}
