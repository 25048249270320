<div @fadeInOut *ngIf="showGroupStatistics">
  <div class="group-info-value">
    <span nbTooltip="{{ +uniqueReach | number: '1.0-0' }}" nbTooltipPlacement="right">
      {{ +uniqueReach | formatNumber: 1 }} ({{ +uniqueReachPercentage | number: '1.2-2' }}%)
    </span>
  </div>
</div>
<div @fadeInOut *ngIf="showComputePendingStatus">
  <div class="group-info-value">
    <div class="warning pending-message">Computing audience overlaps for {{ totalInfluencers }} influencers</div>
  </div>
  <ngx-loader></ngx-loader>
</div>
<div class="group-info-button" *ngIf="showComputeGroupStatisticsButton">
  <ai-button (click)="action()" [disabled]="totalInfluencers < 2 || totalInfluencers > 50" label="CALCULATE">
  </ai-button>
</div>
<div class="group-info-title ai-mt4">Total Unique Followers</div>