import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNumber' })
export class NumberFormatPipe implements PipeTransform {
  transform(number: number, args?: number): string | number {
    const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];

    if (Number.isNaN(number) || number < 0) {
      return 'NA';
    }

    if (number < 1000) {
      // Fix decimal to args decimal points only if already has decimal points
      return number % 1 === 0 ? number : number.toFixed(args);
    }

    const exp = Math.floor(Math.log(number) / Math.log(1000));
    return (number / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  }
}
