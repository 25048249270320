<div class="comment-snapshots"  [ngClass]="{'overall':!media }" *ngIf="mediaComments.length > 0">
  <div class="section snapshots"  [ngClass]="{'section-media':media }">
    <div [ngClass]="[media ?  'section-title-media' : 'section-title' ]" >
      <div class="fa fa-chevron-circle-right" ></div>
        <span class="af-label">Snapshots</span>
    </div>

    <div class="description" [ngClass]="[media ?  'media' : 'overall' ]">
      <div class="each">
          <div class="comments-title" [ngClass]="{'media':media }">
            <img src="assets/images/emotions/02_sentiment_dissatisfied.svg" alt="">
            <label>Negative Comments </label>
          </div>
          <div *ngFor="let item of commentSnapshots.negative | slice:0:maxComments">
            <p class="comment-wrapper" [ngClass]="{'media':media }">
              <span class="comment" >
                {{item.content}}
              </span>
            </p>
          </div>
          <div class="more-comments" (click)="loadMore('negative', name, picture)" *ngIf="commentSnapshots.negative.length > maxComments">
            + {{commentSnapshots.negative.length - maxComments}} more
          </div>
          <div class="more-comments" (click)="loadMore('negative', name, picture)" *ngIf="commentSnapshots.negative.length > 0 && commentSnapshots.negative.length<=maxComments">
            View comment
          </div>
      </div>

      <div class="each">
        <div class="comments-title" [ngClass]="{'media':media }">
          <img src="assets/images/emotions/01_sentiment_satisfied.svg"  alt="">
           <label>Positive Comments </label>
        </div>
        <div *ngFor="let item of commentSnapshots.positive | slice:0:maxComments">
          <p class="comment-wrapper" [ngClass]="{'media':media }">
            <span class="comment" >
              {{item.content}}
            </span>
          </p>
        </div>
        <div class="more-comments" (click)="loadMore('positive', name, picture)" *ngIf="commentSnapshots.positive.length > maxComments">
          + {{commentSnapshots.positive.length - maxComments}} more
        </div>
        <div class="more-comments" (click)="loadMore('positive', name, picture)" *ngIf="commentSnapshots.positive.length > 0 && commentSnapshots.positive.length<=maxComments">
          View comment
        </div>
      </div>

      <div class="each">
        <div class="comments-title" [ngClass]="{'media':media }">
          <img src="assets/images/emotions/03_sentiment_neutral.svg"  alt="">
          <label>
            Neutral Comments
          </label>
        </div>
        <div *ngFor="let item of commentSnapshots.neutral | slice:0:maxComments">
          <p class="comment-wrapper" [ngClass]="{'media':media }">
            <span class="comment" >
              {{item.content}}
            </span>
          </p>
        </div>
        <div class="more-comments" (click)="loadMore('neutral', name, picture)" *ngIf="commentSnapshots.neutral.length > maxComments">
          + {{commentSnapshots.neutral.length - maxComments}} more
        </div>
        <div class="more-comments" (click)="loadMore('neutral', name, picture)" *ngIf="commentSnapshots.neutral.length > 0 && commentSnapshots.neutral.length<=maxComments">
          View comment
        </div>
      </div>
    </div>
  </div>
</div>
