import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../@core/data/user.service';
import { ContentPost, PostWithSafeUrl, ProfileAbout, SharedPost } from '../../interfaces';
import { FacebookPostDetailsModalComponent } from '../facebook-post-details-modal/facebook-post-details-modal.component';
@Component({
  selector: 'ngx-posts-card',
  templateUrl: './posts-card.component.html',
  styleUrls: ['./posts-card.component.scss'],
})
export class PostsCardComponent {
  @Input() posts: PostWithSafeUrl[] | ContentPost[];
  @Input() profile: ProfileAbout;
  @Input() tooltip: string;
  @Input() isContentPosts: boolean[];
  @Input() checked: boolean[];
  @Input() maxCheck: number;
  @Input() extraLongCard: boolean;
  @Input() hideStats = false;

  constructor(private modalService: NgbModal, private userService: UserService) {}

  getEncodedUsername(username: string): string {
    return encodeURIComponent(username);
  }

  async openPostDetailsModal(post: PostWithSafeUrl): Promise<void> {
    let isMediaPost = post.mediaType === 'photo' || post.mediaType === 'video';
    if (post.mediaType === 'shared_post') {
      const postMedia = post.media as SharedPost;
      isMediaPost =
        postMedia.mediaType === 'photo' ||
        postMedia.mediaType === 'video' ||
        (!postMedia.mediaType && postMedia.thumbnailUrl !== null);
    }

    const facebookPostModal = this.modalService.open(FacebookPostDetailsModalComponent, {
      centered: true,
      windowClass: isMediaPost ? 'content-details-modal' : 'text-post-details-modal',
    });
    const modalContent: FacebookPostDetailsModalComponent = facebookPostModal.componentInstance;
    modalContent.post = post;
    modalContent.isMediaPost = isMediaPost;
    modalContent.currency = post.currency || (await this.userService.getLinkedCurrency());
    modalContent.hideStats = this.hideStats;
    if (this.isContentPosts) {
      const contentPost = post as ContentPost;
      modalContent.profile = contentPost.author;
    } else {
      modalContent.profile = this.profile;
    }
  }

  checkedCount(): number | undefined {
    return (this.posts as ContentPost[]).filter((post) => post.checked)?.length;
  }

  handleExpiredImage(post: PostWithSafeUrl): void {
    post.hasExpiredThumbnail = true;
  }
}
