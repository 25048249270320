<nb-card size="large">
    <nb-card-header>
      <span>Audience Location Distribution</span>
      <ngx-tooltip [tooltip]="tooltipMessage"></ngx-tooltip>
    </nb-card-header>
    <nb-card-body>
      <ngx-loader *ngIf="!locationDistributionData" class="profile-card-placeholder"></ngx-loader>
      <div *ngIf="locationDistributionData?.length == 0" class="profile-card-placeholder"> Audience location
        distribution data not found for {{username}}
      </div>
      <div *ngIf="locationDistributionData?.length > 0" class="audience-location-container">
        <button class="filter-btn" [popper]="audienceLocationView" [popperTrigger]="'click'"
          popperApplyClass="context-menu-md" [popperPlacement]="'bottom-end'">
          {{selectedAudienceLocationView}} <i class="fas fa-chevron-down"></i>
        </button>
        <div class="audience-table" *ngIf="selectedAudienceLocationView === 'Country'">
          <table>
            <tr>
              <th>Country</th>
              <th>Audience Percentage</th>
            </tr>
            <tr *ngFor="let data of locationDistributionData">
              <td>{{data.label}}</td>
              <td>{{data.value | number}} %</td>
            </tr>
          </table>
        </div>
        <div class="audience-table" *ngIf="selectedAudienceLocationView === 'City'">
          <table>
            <tr>
              <th>City</th>
              <th>Audience Percentage</th>
            </tr>
            <tr *ngFor="let data of cityDistributionData">
              <td>{{data.name}}</td>
              <td>{{data.value | number}} %</td>
            </tr>
          </table>
        </div>
        <ngx-echarts-bubble-map *ngIf="selectedAudienceLocationView === 'Map'" [mapData]="locationDistributionData">
        </ngx-echarts-bubble-map>
      </div>
    </nb-card-body>
    <nb-card-footer class="stats-footer">
    </nb-card-footer>
  </nb-card>

  <popper-content #audienceLocationView>
    <div class="list-group">
      <div class="list-group-item list-group-item-action cursor-pointer" *ngFor="let option of audienceLocationViewOptions"
        (click)="selectedAudienceLocationView = option; audienceLocationView.hide()">
        <div class="item-text">{{option}}</div>
        <i class="ion-md-checkmark" *ngIf="option === selectedAudienceLocationView"></i>
      </div>
    </div>
  </popper-content>