import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EmailTemplate } from '../../../../../interfaces';
import { environment } from '../../../../../../environments/environment';
import { ChatService } from '../../../../../@core/data/chat.service';
import { CkeditorControl } from '../../../../../../../../controls/src/ckeditor/ckeditor.control';
import { UploadStatusService } from '../../../../../../../../controls/src/ckeditor/upload-status.service';

@Component({
  selector: 'ngx-auto-reply',
  templateUrl: './auto-reply.html',
  styleUrls: ['./auto-reply.scss'],
  animations: [
    trigger('fadeIn', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void => *', animate(350)),
    ]),
  ],
})
export class AutoReplyComponent implements OnInit {
  @Input() campaignSlugName: string;
  @Input() types: { type: string; templates: EmailTemplate[] }[] = [];
  @Input() supportedTemplatingVariables = [];
  @Input() scope: string;
  @ViewChild('ckeditorRef', { static: false }) ckeditorRef: CkeditorControl;
  currentIndex = 0;
  currentTypeIndex = 0;
  isEditMode = false;
  tempIndex = null;
  isDefault = false;
  name = '';
  subject = '';
  template = '';
  isDeleteMode = false;
  deleteConfirmText = 'Are you sure that you want to delete the template?';
  editorUploading = false;

  isTemplateLimitExceed(index: number): boolean {
    return this.types[index]?.templates?.length >= 3;
  }

  get current() {
    return this.types[this.currentTypeIndex]?.templates?.[this.currentIndex];
  }

  set current(value: Partial<EmailTemplate>) {
    if (this.current) {
      Object.keys(value).forEach((key) => {
        this.types[this.currentTypeIndex].templates[this.currentIndex][key] = value[key];
      });
    }
  }

  get allowDelete(): boolean {
    return !this.current?.conditions?.default && this.notGeneric;
  }

  get notGeneric(): boolean {
    return !this.current?.conditions?.generic;
  }

  emptyArray(length = 0) {
    return Array.from({ length: 3 - length });
  }

  constructor(
    private chatService: ChatService,
    private uploadStatusService: UploadStatusService,
    private toastrService: ToastrService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentIndex = this.types[this.currentTypeIndex].templates.findIndex(({ conditions }) => conditions?.default);
    this.select(this.currentTypeIndex);
    this.uploadStatusService.getUploadStatus().subscribe((uploadState) => {
      this.editorUploading = !(uploadState.status === 'completed' && uploadState.count === 0);
    });
  }

  select(typeIndex: number, index = this.currentIndex) {
    this.isEditMode = false;
    this.current = { selected: false };
    if (this.tempIndex && this.tempIndex !== index) {
      this.types[this.currentTypeIndex].templates.pop();
      this.tempIndex = null;
    }
    this.currentIndex = index;
    this.currentTypeIndex = typeIndex;
    if (!this.current) {
      return;
    }
    const { name, subject, template } = this.current;
    this.name = name;
    this.subject = subject;
    this.template = template;
    if (this.ckeditorRef?.editorConfig) {
      this.ckeditorRef.editorConfig.resetProps.defaultTemplate = this.template;
    }
    this.current = { selected: true };
  }

  get apiPath(): string {
    return environment.api;
  }
  get isFormInValid(): boolean {
    return !(this.name.trim().length && this.subject.trim().length && this.template.trim().length);
  }
  createNew(typeIndex: number, scope: string) {
    const { length } = this.types[typeIndex]?.templates || [];
    let subject, name, conditions;
    if (scope === 'proposal') {
      subject = `Proposal ${typeIndex ? 'Rejected' : 'Approved'}`;
      name = `${typeIndex ? 'Rejection' : 'Approval'} 0${length + 1}`;
      conditions = {
        ...(typeIndex ? { rejection: true } : { approval: true }),
        campaignSlugName: this.campaignSlugName,
      };
    } else {
      subject = `Content ${typeIndex ? 'Reviewed' : 'Approved'}`;
      name = `${typeIndex ? 'Review' : 'Approval'} 0${length + 1}`;
      conditions = {
        ...(typeIndex ? { review: true } : { approval: true }),
        contentApproval: true,
        campaignSlugName: this.campaignSlugName,
      };
    }

    this.types[typeIndex].templates.push({
      template: "<p>Hi <span class='placeholder'>{{username}}</span>,</p>\n",
      category: 'campaigns',
      conditions: conditions,
      subject,
      name,
    });
    this.tempIndex = length;
    this.select(typeIndex, this.tempIndex);
    this.isEditMode = true;
  }

  cancel(): void {
    this.isEditMode = false;
    const { _id, template, subject, name } = this.current;
    if (_id) {
      this.current = { template, subject, name };
      this.select(this.currentTypeIndex);
    } else {
      this.select(this.currentTypeIndex, this.currentIndex - 1);
    }
  }

  async save(): Promise<void> {
    this.isEditMode = false;
    const { _id, conditions } = this.current;
    const payload = {
      subject: this.subject,
      template: this.template,
      name: this.name,
      conditions: this.isDefault ? { ...conditions, default: true } : conditions,
    };
    try {
      const newObject = await (_id
        ? this.chatService.updateTemplate(_id, payload)
        : this.chatService.createTemplate({
            ...this.current,
            ...payload,
          }));
      if (this.isDefault) {
        this.toggleDefault();
      }
      this.current = { ...newObject };
      this.tempIndex = null;
    } catch (error) {
      this.cancel();
      this.toastrService.error(error?.error?.message);
    }
  }

  async delete(): Promise<void> {
    const { _id } = this.current;
    if (_id) {
      try {
        await this.chatService.deleteTemplate(_id, this.campaignSlugName);
        this.types[this.currentTypeIndex].templates.splice(this.currentIndex, 1);
        this.select(this.currentTypeIndex, this.types[this.currentTypeIndex]?.templates?.length - 1);
      } catch (error) {
        this.toastrService.error(error?.error?.message);
      }
    }
  }

  toggleDefault(): void {
    const index = this.types[this.currentTypeIndex].templates.findIndex(({ conditions }) => conditions?.default);
    if (index >= 0) {
      const { _id } = this.types[this.currentTypeIndex].templates[index];
      this.types[this.currentTypeIndex].templates[index] = {
        ...this.types[this.currentTypeIndex].templates[index],
        conditions: { ...this.types[this.currentTypeIndex].templates[index].conditions, default: false },
      };
    }
  }

  async makeDefault(): Promise<void> {
    const { _id } = this.current;
    this.toggleDefault(); // awaiting to have sequential operation (avoid race condition to update same array in document)
    this.current = await this.updateDefault(this.campaignSlugName, _id ?? '', true);
  }

  updateDefault(campaignSlugName: string, id: string, _default: boolean): Promise<EmailTemplate> {
    return this.chatService.updateDefaultTemplate(campaignSlugName, id, _default);
  }

  get isSavingEnabled() {
    return this.isFormInValid || this.editorUploading;
  }
}
