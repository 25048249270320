import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { GroupInfo, GroupService, InfluencerCount } from '../../../@core/data/group.service';
import { environment } from '../../../../environments/environment';
import { PlatformsUpperEnum } from '../../../enums';
import utils from '../../../@core/utils/utils';
import { GroupSubset } from '../../../collaborator/collaborator-groups/collaborator-group.service';
import { CollabsGroupInfoService } from '../../../collaborator/collaborator-groups/collaborator-groups-content/collabs-group-info.service';

@Component({
  selector: 'ngx-group-stats',
  templateUrl: './group-stats.component.html',
  styleUrls: ['./group-stats.component.scss'],
})
export class GroupStatsComponent implements OnInit {
  private groupSlugName: string;
  sainsburyWeight: number;
  overlapWeight: number;
  statsLoading = true;
  averageEngagement = 0;
  totalInfluencers = 0;
  totalFollowers = 0;

  @Input() groupInfo: GroupInfo | GroupSubset | null;
  @Input() platform: PlatformsUpperEnum;
  @Input() status: 'PENDING' | 'APPROVED' | 'REJECTED';
  @Input() isCollaborationPortal = false;

  @Input() set slugName(value: string) {
    if (this.groupSlugName === value) {
      return;
    }
    this.groupSlugName = value;
  }

  get isAudienceOverlapEnabled(): boolean {
    return ![PlatformsUpperEnum.INSTAGRAM, PlatformsUpperEnum.VERAINSTAGRAM].includes(this.platform);
  }

  get uniqueReach(): number {
    const statistics = this.groupInfo?.['statistics'] ?? {};
    const sainsburyReach = (statistics?.sainsburyData?.uniqueReachPercentage ?? 0) * this.sainsburyWeight;
    const overlapReach = (statistics?.overlapData?.reachPercentage ?? 0) * this.overlapWeight;
    const totalWeight = this.sainsburyWeight + this.overlapWeight;

    return ((sainsburyReach + overlapReach) / totalWeight / 100) * this.totalInfluencers;
  }

  constructor(
    private groupService: GroupService,
    private toasterService: ToastrService,
    private collabsGroupInfoService: CollabsGroupInfoService,
  ) {}

  ngOnInit(): void {
    ({ sainsburyWeight: this.sainsburyWeight, overlapWeight: this.overlapWeight } = environment.overlapConfig);
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  init(): void {
    this.averageEngagement = 0;
    this.totalInfluencers = 0;
    this.totalFollowers = 0;

    this.fetchStats();

    if (this.groupSlugName !== this.groupInfo?.slugName) {
      this.groupInfo = null;
    }
  }

  async fetchStats(): Promise<void> {
    if (!this.groupSlugName) {
      return;
    }

    this.statsLoading = true;

    try {
      const groupStats = await (this.isCollaborationPortal
        ? this.fetchStatsFromCollabService()
        : this.fetchStatsFromGroupService());

      this.updateStats(groupStats);
    } finally {
      this.statsLoading = false;
    }
  }

  private async fetchStatsFromGroupService(): Promise<InfluencerCount> {
    return this.groupService.getGroupInfluencerStats(this.groupSlugName, utils.convertToPlatformV2(this.platform));
  }

  private async fetchStatsFromCollabService(): Promise<InfluencerCount> {
    if (!this.status) {
      return {
        totalInfluencers: 0,
        totalReach: 0,
        averageEngagement: 0,
      };
    }
    return this.collabsGroupInfoService.fetchGroupStatistics(this.groupSlugName, this.platform, this.status);
  }

  private updateStats(stats: InfluencerCount): void {
    this.averageEngagement = stats.averageEngagement;
    this.totalInfluencers = stats.totalInfluencers;
    this.totalFollowers = stats.totalReach;
  }

  computeGroupStatistics(): void {
    if (!this.groupInfo || this.groupInfo?.['statistics']?.status !== 'invalid') {
      return;
    }

    this.groupInfo['statistics'].status = 'pending';
    this.groupService
      .computeOverlap({ slugName: this.groupSlugName } as GroupInfo, utils.convertToPlatformV2(this.platform))
      .then(() => {
        this.toasterService.success(`The calculation of total unique followers has been successfully requested!`);
      })
      .catch((res) => {
        if (res.status === 400) {
          this.toasterService.error(res.error?.message);
        } else {
          this.toasterService.error(`Failed to compute audience overlap`);
        }
      });
  }
}
