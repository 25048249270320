<div class="form-group">
    <label class="filter-label">{{filterLabel}}</label>
    <div class="input-group input-group-sm">
        <input type="number" name="minFollowers" placeholder="min" [(ngModel)]="followers.min" class="form-control"
            (input)="checkValue($event, 'min'); triggerUpdateEvent()" [min]="minFollowersLimit" [max]="maxFollowersLimit" [disabled]="isDisabled" />
        <div class="separator">-</div>
        <input type="number" name="maxFollowers" placeholder="max" [(ngModel)]="followers.max" class="form-control"
            (input)="checkValue($event, 'max'); triggerUpdateEvent()" [min]="minFollowersLimit" [max]="maxFollowersLimit" [disabled]="isDisabled"/>
    </div>
</div>
