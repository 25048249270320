import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Action, CheckBoxType, Typography, Value } from '../../common.enum';

@Component({
  selector: 'ai-dropdown-item',
  templateUrl: './dropdown-item.control.html',
  styleUrls: ['../dropdown.control.scss'],
})
export class DropdownItemControl {
  @Input() label;
  @Input() labelSize: Typography;
  @Input() labelWidth: number | '';
  @Input() type: CheckBoxType = 'checkbox';
  @Input() key: Value;
  @Input() value: Value;
  @Input() image: String;
  @Input() imageStyle: { [a: string]: string };
  @Input() selected = false;
  @Input() disabled = false;
  @Input() subLabel: String;
  @Input() suffix: String;
  @Input() isRegistered = false;
  @Input() dropdownItemType: 'singleLine' | 'doubleLine' = 'singleLine';
  @Output() action: EventEmitter<Action> = new EventEmitter();

  @HostBinding('style')
  get style_binding() {
    if (this.disabled && this.selected) {
      return {
        'pointer-events': 'none',
      };
    }
    if (this.disabled && !this.selected) {
      return {
        'pointer-events': 'none',
        'border-left': '1px solid var(--warning)',
        opacity: '0.4',
      };
    }
  }
  required = [Validators.required];
  dropDownItem: FormGroup = this.fb.group({
    dropdown: [{ value: this.selected, disabled: this.disabled }, this.required],
  });

  constructor(private fb: FormBuilder) {}

  select(action) {
    this.action.emit({ key: this.value, value: this.dropDownItem.value['dropdown'] });
    if (this.type === 'none') {
      this.selected = !this.selected;
    }
  }
}
