<nb-card size="large" class="audience-age-distribution">
    <nb-card-header>
      <span>Audience Age Distribution
      </span>
      <ngx-tooltip [tooltip]="tooltipMessage"></ngx-tooltip>
    </nb-card-header>
    <nb-card-body>
      <div>
        <span class="info-chip" *ngIf="adultAudiencePercentage"><strong>{{adultAudienceAge}}+ Audience</strong>:
          {{adultAudiencePercentage}}%</span>
        <span class="gender-overlay-toggle">
          <ai-button type="toggle" prefix="fa-regular fa-venus-mars" nbTooltip="Toggle Gender Overlay"
            (click)="toogleGenderOverlay()" [active]="showAgeGenderChart"></ai-button>
        </span>
      </div>
      <ngx-echarts-bar [isAgeGender]=true *ngIf="showAgeGenderDistributionChart()"
        [dataOptions]="ageGenderDataOptions"></ngx-echarts-bar>
      <ngx-echarts-bar *ngIf="showAgeDistributionChart()" [dataOptions]="ageDataOptions">
      </ngx-echarts-bar>
      <ngx-loader class="profile-card-placeholder" *ngIf="showAudienceAgeLoader()"></ngx-loader>
      <div *ngIf="showAgeDistributionIsNotAvailable()" class="profile-card-placeholder">Age
        distribution data not available for
        {{influencerUsername}}
      </div>
      <div *ngIf="showAgeGenderDistributionIsNotAvailable()" class="profile-card-placeholder">
        Gender breakdown per age bracket data not available for
        {{influencerUsername}}
      </div>
    </nb-card-body>
    <nb-card-footer class="stats-footer">
    </nb-card-footer>
  </nb-card>