
<div class="video-container">
  <div class="close-button" (click)="close.emit()">
    <i class="fa fa-close"></i>
  </div>
  <video #videoPlayer controls>
    <source src="{{ videoSrc }}" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>
