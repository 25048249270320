<div class="row results-header">
  <div class="title">
    Influencer Discovery
  </div>
  <div class="controls">
    <button data-cy="upload-influencer-button" *ngIf="isSubscriptionUser" type="button" class="secondary list-action-button" (click)="onUploadClick.emit()"
      [disabled]="disabledUploadButton">
      Upload Influencers
    </button>
    <button type="button" class="primary list-action-button" data-cy="add-to-contextmenu" [popper]="addToContextMenu" [popperTrigger]="'click'"
      popperApplyClass="context-menu" [popperPlacement]="'bottom-end'" (popperOnShown)="popperOnShown.emit()"
      [disabled]="!selectedInfluencers?.length">
      <strong>Add To</strong>
      <i class="far fa-chevron-down left-border"></i>
    </button>
  </div>
</div>

<popper-content #addToContextMenu>
  <div class="list-group">
    <ng-container *ngIf="!filterInfluencerGroup">
      <label>Group</label>
      <div class="list-group-item list-group-item-action"
        (click)="openCreateGroupModal.emit(); addToContextMenu.hide()">
        A New Group
      </div>
      <div class="list-group-item list-group-item-action" [class.disabled]="!isGroupsExist"
        (click)="openAddToGroupModal.emit(); addToContextMenu.hide()">
        Existing Groups
      </div>
      <div class="h-separator"></div>
    </ng-container>
    <ng-container *ngIf="isCampaignsEnabled">
      <label>Campaign</label>
      <div class="list-group-item list-group-item-action"
        (click)="openCreateCampaignModal.emit(); addToContextMenu.hide()">
        A New Campaign
      </div>
      <div class="list-group-item list-group-item-action"
        (click)="openAddToCampaignModal.emit(); addToContextMenu.hide()">
        Existing Campaigns
      </div>
    </ng-container>
  </div>
</popper-content>