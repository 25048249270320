import { Injectable } from '@angular/core';
import { DomainKey } from '../../enums';
import { WhiteLabelConfig } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  getDomain(): DomainKey {
    switch (window.location.hostname) {
      case 'app.affable.ai':
        return 'affable';
      case 'app.staging.affable.ai':
        return 'affable';
      case 'stellasocial.com':
      case 'dentsu.affable.ai':
      case 'prism.dentsu.com':
      case 'influence.dentsu.com':
        return 'dentsu';
      case 'platform.betagged.co':
        return 'betagged';
      default:
        return 'affable';
    }
  }

  getConfig(): WhiteLabelConfig {
    const key = this.getDomain();
    return configurations[key];
  }
}

const configurations: { [key in DomainKey]: WhiteLabelConfig } = {
  affable: {
    appTitle: 'Affable',
    appIconPath: 'favicon.ico',
    logo: {
      path: '/assets/images/affable-glyph-w-container-no-txt.png',
      headerPageWidth: 30,
    },
    footer: {
      showPoweredByAffable: false,
    },
  },
  betagged: {
    appTitle: 'Betagged',
    appIconPath: '/assets/images/betagged-favico.ico',
    logo: {
      path: '/assets/images/betagged-logo.png',
      headerPageWidth: 150,
    },
    footer: {
      showPoweredByAffable: false,
    },
  },
  dentsu: {
    appTitle: 'Dentsu Influence',
    appIconPath: '/assets/images/favicon-dentsu-influence.ico',
    logo: {
      path: '/assets/images/dentsu-influence-black.png',
      headerPageWidth: 120,
    },
    footer: {
      showPoweredByAffable: true,
    },
  },
};
