import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabeledAgeGroup } from '../../../interfaces';

@Component({
  selector: 'ngx-age-range-filter',
  templateUrl: './age-range-filter.component.html',
  styleUrls: ['./age-range-filter.component.scss', '../styles.scss'],
})
export class AgeRangeFilterComponent {
  @Input() labeledAgeGroups: LabeledAgeGroup[];

  // used in IG,FB,YT to represent the age groups a boolean[]
  @Input() ageGroups: boolean[];

  @Output() triggerUpdate: EventEmitter<void> = new EventEmitter();

  triggerUpdateEvent(): void {
    this.triggerUpdate.emit();
  }
}
