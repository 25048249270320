import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-audience-age-distribution-card',
  templateUrl: './audience-age-distribution-card.component.html',
  styleUrls: ['./audience-age-distribution-card.component.scss'],
})
export class AudienceAgeDistributionCardComponent {
  @Input() ageGenderDataOptions;
  @Input() ageDataOptions;
  @Input() adultAudienceAge = 21;
  @Input() adultAudiencePercentage: string;
  @Input() tooltipMessage: string;
  @Input() influencerUsername: string;

  showAgeGenderChart = false;

  toogleGenderOverlay() {
    this.showAgeGenderChart = !this.showAgeGenderChart;
  }

  showAudienceAgeLoader(): boolean {
    return !this.ageDataOptions;
  }

  showAgeDistributionChart(): boolean {
    return !this.showAgeGenderChart && this.ageDataOptions?.series?.length > 0;
  }

  showAgeGenderDistributionChart(): boolean {
    return this.showAgeGenderChart && this.ageGenderDataOptions?.series?.length > 0;
  }

  showAgeDistributionIsNotAvailable(): boolean {
    return !this.showAgeGenderChart && this.ageDataOptions?.series?.length == 0;
  }

  showAgeGenderDistributionIsNotAvailable(): boolean {
    return this.showAgeGenderChart && this.ageGenderDataOptions?.series?.length == 0;
  }
}
