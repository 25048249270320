<div class="row mt-2">
  <label *ngIf="showLabel" class="col-sm-3 influencers-label">Influencers<sup *ngIf="isRequired">*</sup></label>
  <div class="remove-left-padding" [ngClass]="{'col-sm-9': showLabel,
    'col-sm-12': !showLabel,
    'scrollable-area': isScrollable}">
    <ng-select disabled="{isDisabled}" placeholder="{{isDisabled? '': 'Enter username or name'}}" [multiple]="true"
      class="content-filter-influencer-search" [ngModelOptions]="{standalone: true}" [clearable]="!isChipOnEditMode"
      searchable="true" addTagText="Entered Name" typeToSearchText="Type to search for influencer"
      [items]="influencers | async" [addTag]="addInfluencerToFilters" [typeahead]="influencerNameInput"
      [loading]="loadingInfluencers" [virtualScroll]="true" [markFirst]="false" (change)="updateInfluencersSelection()"
      [(ngModel)]="selectedInfluencers" groupBy="category" required>


      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value chip"
          [ngClass]="{'email-warn-background': !isEmailValid(item.email), 'warn-background': item.willBeTerminatedInSequence }"
          *ngFor="let item of filterInfluencersToShow(items)" (dblclick)="handleChipEdit(item, true)">
          <span *ngIf="!item.editable" class="ng-value-label" (mouseover)="hoverInfluencer = item" [popper]="fullEmail"
            [popperTrigger]="'hover'" [popperHideOnClickOutside]="true" popperApplyClass="context-menu"><b>
              {{item.fullName}}</b>
            <ng-container *ngIf="!isEmailValid(item.email)"> @{{item.username}}</ng-container>
            <ng-container *ngIf="isEmailValid(item.email)"> ({{item.email.split("@")[1]}})
            </ng-container>
          </span>
          <span *ngIf="!item.editable && !loadingInfluencers && (singleItemClearable || selectedInfluencers.length > 1)"
            class="ng-value-icon right" (click)="clear(item);" aria-hidden="true">×</span>
          <div *ngIf="item.editable && !loadingInfluencers" class="actions">
            <input type="email" [ngModelOptions]="{standalone: true}" class="form-control edit-tag"
              [ngModel]="item.email" autofocus #emailInput="ngModel" customEmailValidator
              [ngClass]="{'warn-background': !emailInput.valid}">
            <button title="Save" (click)="handleSaveChip(emailInput, item)" [disabled]="!emailInput.valid"><i
                class="fa fa-check"></i></button>
            <button title="Cancel" (click)="handleChipEdit(item, false)"><i class="fa fa-close"></i></button>
          </div>
        </div>
        <span class="ng-value chip more" (click)=showNextInfluencers() *ngIf="showMoreBtnVisible">+
          {{remainingInfluencers}} MORE</span>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="row">
          <div class="col-md-1">
            <img src={{item.picture}} class="user-round">
          </div>
          <div class="col-md-11">
            <span [ngOptionHighlight]="search" class="brand-name">{{item.fullName}}</span><br />
            <span [ngOptionHighlight]="search" class="brand-username">@{{item.username}}</span>
          </div>
        </div>
      </ng-template>

    </ng-select>
    <div *ngIf="numberOfInfluencersWithInvalidOrMissingEmail()" class="fx-row email-warn">
      <p>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{getMessageForInfluencersWithNoValidEmail()}}
      </p>
      <ai-button type="text" typography="caption" (click)="removeInfluencersWithNoValidEmail()"
        label="REMOVE FROM LIST">
      </ai-button>
    </div>

    <div *ngIf="!!numberOfInfluencersWithProposalSubmitted()" class="fx-row proposal-submitted-warn">
      <p>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{getMessageForInfluencersWithProposalsSubmitted()}}
      </p>
      <ai-button type="text" typography="caption" (click)="removeSubmittedProposalInfluencers()"
        label="REMOVE FROM LIST">
      </ai-button>
    </div>
  </div>
</div>


<popper-content #fullEmail>
  <div *ngIf="hoverInfluencer?.email" class="list-group" (dblclick)="handleChipEdit(hoverInfluencer, true)">
    <div class="list-group-item">
      <p>{{hoverInfluencer.email}}</p>
    </div>
  </div>
</popper-content>