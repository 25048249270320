import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum ResourceType {
  group = 'group',
}

@Injectable({
  providedIn: 'root',
})
export class ResourceAccessControlService {
  constructor(private _http: HttpClient) {}

  getAccessControlList(params: { resourceId: string; resourceType: ResourceType }): Promise<any> {
    return this._http.get<any>(`${environment.api}/api/rac`, { params }).toPromise();
  }
}
