<main>
  <header>
    <b>{{ headerText }}</b>
    <button class="close" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </header>
  <div class="info-container" *ngIf="!!alertText">
    <i class="far fa-triangle-exclamation"></i>
    <span>
      {{alertText}}
    </span>
  </div>
  <p *ngIf="contentText" [innerHtml]="contentText"></p>
  <footer>
    <ai-button label="CANCEL" (click)="activeModal.close()" type="ghost"></ai-button>
    <ai-button [label]="buttonText" type="fill" (click)="activeModal.close(true)"></ai-button>

  </footer>
</main>