    <div class="modal-header">
        <div class="af-heading-h2">{{title}} ({{getTotalCount()}})</div>
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="body-container">
            <div class="list-container" *ngFor="let content of contents; index as i">
                <div class="list-header">
                    <div class="title">
                        <span class="af-sub-heading-h2">
                            {{i+1}}. {{content.name}} ({{content.data?.length}})
                        </span>
                        <div class="info">
                            <ai-tooltip *ngIf="content.tooltip" placement="top" [tooltips]="content.tooltip">
                                <i class="fa-regular fa-circle-info"></i>
                            </ai-tooltip>
                        </div>
                    </div>
                    <ai-button class="remove" type="ghost" label="REMOVE ALL" (click)="removeAllDataInContent(content)"></ai-button>
                </div>
                <div class="list-body">
                    <ng-container *ngFor="let item of content.data; index as idx">
                        <div class="content-item af-body-2" *ngIf="(idx<defaultDisplayLength && !content.showAll)||content.showAll">
                            <span>{{item}}</span>
                            <i class="fa-regular fa-xmark" (click)="removeOne(content,idx)"></i>
                        </div>
                    </ng-container>
                </div>
                <div class="list-footer">
                    <ai-button 
                    *ngIf="content.data.length>defaultDisplayLength" 
                    type="text" 
                    [label]="content.showAll?'Show Less':('+'+(content.data.length-defaultDisplayLength)+' More')"
                    (click)="showMore(content)">
                </ai-button>
                </div>
            <hr *ngIf="i<contents.length-1">
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="action">
            <ai-button type="ghost" [label]="'REMOVE ALL '+title.toUpperCase()" (click)="removeAllContents()"></ai-button>
            <ai-button size="large" label="SAVE CHANGES" (click)="onSave()"></ai-button>
        </div>
    </div>