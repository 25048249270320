import { SequenceMinimalInfo } from './../../../../../interfaces/email-sequence.interface';
import { ConfirmModalComponent } from './../../../../../shared/confirm-modal/confirm-modal.component';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { PlatformsV2 } from '../../../../../enums';
import { EmailSequenceFilters, InfluencerLookup } from '../../../../../interfaces';
import { FormControl } from '@angular/forms';
import { EmailSequenceService } from '../../../../../@core/data/email-sequence.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-existing-email-sequence',
  templateUrl: './existing-email-sequence.modal.html',
  styleUrls: ['./existing-email-sequence.modal.scss'],
})
export class ExistingEmailSequenceModal implements OnInit {
  @Input() platform: PlatformsV2;
  @Input() selectedInfluencers: InfluencerLookup[] = [];
  @Input() campaignSlugName = '';

  filterOptions = [
    { label: 'All', value: EmailSequenceFilters.ALL },
    { label: 'Enabled', value: EmailSequenceFilters.ENABLED },
    { label: 'Disabled', value: EmailSequenceFilters.DISABLED },
  ];

  emailSequences: SequenceMinimalInfo[] = [];
  searchTerm = '';
  pageNumber = 0;
  loading = false;
  paginationLoader = false;
  hasMoreData = false;
  getCampaignSequencesRequest?: Subscription;

  isInfluencerSelectionValid = false;
  copyOfSelectedInfluencers: InfluencerLookup[] = [];

  filterTypeInput = new FormControl(this.filterOptions[0].value);
  selectedSequence: SequenceMinimalInfo;

  addToSequenceButtonLoading = false;

  isGetWillBeTerminatedUsernamesLoading = false;

  get selectedSequenceTerminationRule(): string {
    return this.selectedSequence?.terminationRules?.[0]?.kind;
  }

  get willBeTerminatedUsernames(): string[] {
    return this.selectedInfluencers
      .filter(({ willBeTerminatedInSequence }) => !!willBeTerminatedInSequence)
      .map(({ username }) => username);
  }

  get isAddToSequenceButtonDisabled(): boolean {
    return this.isNextBtnDisabled || !(this.selectedSequence?._id && this.selectedInfluencers?.length);
  }

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sequenceService: EmailSequenceService,
    private toastrService: ToastrService,
  ) {
    this.emailSequences = [];

    this.filterTypeInput.valueChanges.subscribe(() => {
      this.pageNumber = 0;
      this.getEmailSequences();
    });
  }

  ngOnInit(): void {
    this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);
    this.getEmailSequences();
  }

  receiveUpdatedInfluencers(updatedInfluencerInfo: { influencers: InfluencerLookup[]; isValid: boolean }): void {
    this.selectedInfluencers = updatedInfluencerInfo.influencers;
    this.isInfluencerSelectionValid = updatedInfluencerInfo.isValid;
  }

  getEmailSequences(): void {
    this.pageNumber ? (this.paginationLoader = true) : (this.loading = true);
    this.getCampaignSequencesRequest?.unsubscribe();
    this.getCampaignSequencesRequest = this.sequenceService
      .getCampaignSequences(this.campaignSlugName, this.pageNumber, {
        excludeInfluencerIds: true,
        excludeDelivered: true,
        excludeUniqueOpens: true,
        excludeUniqueReplies: true,
        statusFilter: this.filterTypeInput.value,
        searchTerm: this.searchTerm,
      })
      .subscribe({
        next: (response) => {
          this.emailSequences = this.pageNumber ? [...this.emailSequences, ...response.sequences] : response.sequences;
          this.hasMoreData = response.hasMoreSequences;
          this.loading = false;
          this.paginationLoader = false;
        },
        complete: () => {
          this.loading = false;
          this.paginationLoader = false;
        },
        error: (error) => {
          this.loading = false;
          this.paginationLoader = false;
          this.toastrService.error(`Unable to retrieve data. Please refresh and check later`);
          console.error(error);
        },
      });
  }

  searchEmailSequences(clearSearchTerm?: boolean): void {
    if (clearSearchTerm) {
      this.searchTerm = '';
    }
    this.pageNumber = 0;
    this.getEmailSequences();
  }

  loadNextPage(): void {
    if (!this.hasMoreData || this.paginationLoader || this.loading) {
      return;
    }
    this.paginationLoader = true;
    this.pageNumber++;
    this.getEmailSequences();
  }

  closeModal(result?: any): void {
    this.activeModal.close(result);
  }

  get isNextBtnDisabled(): boolean {
    return (
      !this.selectedSequence ||
      !this.isInfluencerSelectionValid ||
      this.isGetWillBeTerminatedUsernamesLoading ||
      !!this.willBeTerminatedUsernames?.length
    );
  }

  onSequenceSelected(_value: SequenceMinimalInfo): void {
    // reset willBeTerminatedInSequence for selected influencers
    this.selectedInfluencers.forEach((inf) => (inf.willBeTerminatedInSequence = false));
    this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);
  }

  async getWillBeTerminatedUsernames(): Promise<void> {
    if (!this.selectedSequenceTerminationRule) return;

    this.isGetWillBeTerminatedUsernamesLoading = true;
    this.selectedInfluencers.forEach((inf) => (inf.willBeTerminatedInSequence = false));
    if (this.selectedSequenceTerminationRule === 'NEVER' || this.selectedSequenceTerminationRule === 'REPLY_RECEIVED') {
      this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);
      this.isGetWillBeTerminatedUsernamesLoading = false;
      return;
    }
    const willBeTerminatedUsernames: string[] = await this.sequenceService
      .getMatchingInfluencersThatWillBeTerminatedByPolicy(
        this.campaignSlugName,
        this.selectedInfluencers.map((influencer) => influencer.username),
        { kind: this.selectedSequenceTerminationRule },
        this.platform,
      )
      .then(({ willBeTerminatedUsernames }) => willBeTerminatedUsernames)
      .catch(() => []);

    willBeTerminatedUsernames.forEach((username) => {
      const foundInfluencer = this.selectedInfluencers.find((inf) => inf.username === username);
      if (foundInfluencer) {
        foundInfluencer.willBeTerminatedInSequence = true;
      }
    });
    this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);
    this.isGetWillBeTerminatedUsernamesLoading = false;
  }

  async addToSequence(): Promise<void> {
    await this.getWillBeTerminatedUsernames();

    if (this.willBeTerminatedUsernames?.length) return;

    let matchingUsernames: string[] = [];
    if (this.selectedSequence?._id && this.selectedSequence?.influencersCount && this.selectedInfluencers?.length) {
      this.addToSequenceButtonLoading = true;
      matchingUsernames = await this.sequenceService
        .getUsernamesForExistingMatchingInfluencers(
          this.selectedSequence._id,
          this.selectedInfluencers.map((influencer) => influencer.username),
          this.platform,
        )
        .then(({ matchingUsernames }) => matchingUsernames)
        .catch(() => []);

      this.addToSequenceButtonLoading = false;
    }
    this.confirmSelection(matchingUsernames).then(async (res) => {
      if (!(res && this.selectedSequence?._id && this.selectedInfluencers?.length)) {
        return;
      }
      try {
        await this.sequenceService.addInfluencersToExistingSequence(
          this.selectedSequence._id,
          this.selectedInfluencers.map((influencer) => influencer.username),
          this.platform,
        );
        this.sequenceService.sendEmailSequenceUpsertedEvent({
          kind: 'INFLUENCERS_ADDED',
          metadata: {
            sequenceId: this.selectedSequence._id,
            sequenceName: this.selectedSequence.name,
            numberOfInfluencers: this.selectedInfluencers?.length,
          },
        });
        this.closeModal({
          sequenceName: this.selectedSequence?.name,
          numberOfInfluencers: this.selectedInfluencers?.length,
        });
      } catch (error) {
        console.error(error);
        this.toastrService.error('Failed to add influencers to sequence, please try again!');
      }
    });
  }

  async confirmSelection(matchingUsernames: string[]): Promise<unknown> {
    const confirmModal = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });

    const modalContent = confirmModal.componentInstance as ConfirmModalComponent;
    modalContent.headerText = 'Confirm Adding to Sequence';
    modalContent.contentText = `Are you sure you want to add ${this.selectedInfluencers.length} influencer(s) to <strong>${this.selectedSequence.name}</strong>`;
    modalContent.buttonText = 'CONFIRM';
    modalContent.cancelButton = true;
    if (matchingUsernames.length) {
      modalContent.alertText =
        matchingUsernames.length !== 1
          ? `${matchingUsernames.length} influencers are already present in ${this.selectedSequence?.name}. Emails won't be re-sent to those influencers.`
          : `${matchingUsernames.length} influencer is already present in ${this.selectedSequence?.name}. Emails won't be re-sent to this influencer.`;
    }

    return confirmModal.result;
  }
}
