<div [class]="['comment-analysis', wrapperClassName].join(' ')" [ngClass]="{'printed-wrapper':printed}">
  <div class="section sentiment">
    <div class="section-title chart-card-title af-label" >
      <div class="fa fa-chevron-circle-right" *ngIf="printed"></div>
      Sentiment
    </div>
    <div class="chart">
      <div [class]="['emotion', emotionClass].join(' ')"></div>
      <div echarts [options]="sentimentChartOption" class="echart" (chartInit)="onChartInit($event)"></div>
    </div>
    <div class="description">
      <div class="each senti">
        <div class="name">
          <i class="far fa-face-meh"></i>
          <span class="af-body-4 count">Neutral ({{ commentAnalysis?.neutralCount }}) </span>
        </div>
        <div class="filler"></div>
        <div class="value">
          <span class="percentage af-sub-heading-h2">{{ neutralPercentage }}%</span>
        </div>
      </div>
      <div class="each senti">
        <div class="name">
          <i class="far fa-face-smile"></i>
          <span class="af-body-4 count">Positive ({{ commentAnalysis?.positiveCount }}) </span>
        </div>
        <div class="filler"></div>
        <div class="value">
          <span class="percentage af-sub-heading-h2">{{ positivePercentage }}%</span>
        </div>
      </div>
      <div class="each senti">
        <div class="name">
          <i class="far fa-face-frown"></i>
          <span class="af-body-4 count">Negative ({{ commentAnalysis?.negativeCount }}) </span>
        </div>
        <div class="filler"></div>
        <div class="value">
          <span class="percentage af-sub-heading-h2">{{ negativePercentage }}%</span>
        </div>
      </div>
    </div>
  </div>
  <div class="section general">
    <div class="section-title af-label" [ngClass]="{'chart-card-title ':printed, 'section-title':!printed}">
      <div class="fa fa-chevron-circle-right" *ngIf="printed"></div>
      Analysis
    </div>
    <div  [ngClass]="{'chart-card-space':printed}"> </div>
    <div class="each">
      <div class="name">
        <div class="icon">
          <i class="icon-bg far fa-comment-smile"></i>
        </div>
        <span class="af-body-4 count">Only Emoji Comments ({{ commentAnalysis?.emojiOnlyCount }}) </span>
      </div>
      <div class="filler"></div>
      <div class="value">
        <span class="percentage af-sub-heading-h2">{{ emojiOnlyPercentage }}%</span>
      </div>
    </div>
    <div class="each">
      <div class="name">
        <div class="icon">
          <i class="icon-bg far fa-user-tag"></i>
        </div>
        <span class="af-body-4 count">User-Tag Comments ({{ commentAnalysis?.hasMentionCount }}) </span>
      </div>
      <div class="filler"></div>
      <div class="value">
        <span class="percentage af-sub-heading-h2">{{ hasMentionPercentage }}%</span>
      </div>
    </div>
    <div class="each">
      <div class="name">
        <div class="icon">
          <i class="icon-bg far fa-comment-lines"></i>
        </div>
        <span class="af-body-4 count">Average Comment length</span>
      </div>
      <div class="filler"></div>
      <div class="value">
        <span class="percentage af-sub-heading-h2">{{ commentAnalysis?.averageLength }}</span>
      </div>
    </div>
  </div>
</div>
