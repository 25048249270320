/* eslint-disable */
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[keyupDebounce]',
})
export class DebounceKeyupDirective {
  timeout: any = null;

  @Output()
  public onEvent: EventEmitter<any>;

  constructor() {
    this.onEvent = new EventEmitter<any>();
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: any): void {
    event.preventDefault();
    this.onKeyupEvent(event);
  }

  private onKeyupEvent(event: any) {
    clearTimeout(this.timeout);
    const $this = this;
    this.timeout = setTimeout(function (): void {
      $this.executeEvent(event);
    }, 1000);
  }

  private executeEvent(event) {
    this.onEvent.emit(event);
  }
}
