import { BaseEnvironment } from '../app/interfaces';

export const sharedEnvironment: BaseEnvironment = Object.freeze({
  filesInputConfig: {
    affiliateOrdersCsv: { validTypes: ['text/csv'], fileMaxSize: 1024 * 1024 * 25 },
    emailAttachments: { totalMaxSize: 1024 * 1024 * 25 },
    brandPortalLogo: { validTypes: ['image/jpg', 'image/jpeg', 'image/png'] },
    storyImage: {
      validTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      fileMaxSize: 1024 * 1024 * 25,
    },
    applicationPageLogo: {
      validTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      fileMaxSize: 1024 * 1024 * 1,
    },
    landingPageImage: {
      validTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      fileMaxSize: 1024 * 1024 * 10,
    },
    campaignImage: {
      validTypes: ['image/gif', 'image/jpeg', 'image/png'],
      fileMaxSize: 1024 * 1024 * 25,
    },
    productImage: { validTypes: ['image/jpg', 'image/jpeg', 'image/png'], fileMaxSize: 1024 * 1024 * 25 },
    taskAttachment: {
      fileMaxSize: 1024 * 1024 * 25,
      totalMaxSize: 1024 * 1024 * 50,
      validTypes: [
        'image/gif',
        'image/jpeg',
        'image/png',
        'video/mp4',
        'video/quicktime',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/pdf',
      ],
    },
    noteAttachment: { fileMaxSize: 1024 * 1024 * 20 },
  },
});

export default sharedEnvironment;
