<div class="metadata" [ngClass]="{ 'extra-bottom-padding': !!campaignSlug }" *ngIf="!loading && influencerMetadata">

  <!-- influencer firstname -->
  <div class="inputs-container">
    <div class="inputs text-input">
      <span> First Name<span class="required_asterisk">*</span>: </span>
      <input class="text-field" [(ngModel)]="firstname" firstname placeholder="Add First Name" #infFirstname="ngModel"
        name="firstname" [readonly]="!editingFirstname" [class.has-border]="editingFirstname" required>
    </div>
    <div class="actions">
      <button *ngIf="!editingFirstname" (click)="editingFirstname = true" title="Edit"><i class="far fa-edit"></i>
      </button>
      <button *ngIf="editingFirstname" (click)="updateFirstname()" [disabled]="!infFirstname.valid" title="Save"><i
          class="far fa-check"></i></button>
      <button *ngIf="editingFirstname" (click)="cancelFirstnameChanges()" title="Cancel"><i
          class="far fa-close"></i></button>
    </div>
  </div>
  <div class="validation-msg">
    <div *ngIf="!infFirstname.valid" class="error-msg">First Name is not valid
    </div>
  </div>

  <!-- influencer lastname -->
  <div class="inputs-container">
    <div class="inputs text-input">
      <span> Last Name: </span>
      <input class="text-field" [(ngModel)]="lastname" lastname placeholder="Add Last Name" #infLastname="ngModel"
        name="lastname" [readonly]="!editingLastname" [class.has-border]="editingLastname">
    </div>
    <div class="actions">
      <button *ngIf="!editingLastname" (click)="editingLastname = true" title="Edit"><i class="far fa-edit"></i>
      </button>
      <button *ngIf="editingLastname" (click)="updateLastname()" [disabled]="!infLastname.valid" title="Save"><i
          class="far fa-check"></i></button>
      <button *ngIf="editingLastname" (click)="cancelLastnameChanges()" title="Cancel"><i
          class="far fa-close"></i></button>
    </div>
  </div>
  <div class="validation-msg">
    <div *ngIf="!infLastname.valid" class="error-msg">Cannot be left blank
    </div>
  </div>

  <!-- influencer email -->
  <div class="inputs-container">
    <div class="inputs text-input">
      <span> Email: </span>
      <input type="email" class="text-field" [(ngModel)]="email" (keydown.space)="$event.preventDefault();"
        customEmailValidator placeholder="Add Influencer email" #infEmail="ngModel" name="email"
        [readonly]="!editingEmail" [class.has-border]="editingEmail">
    </div>
    <div class="actions">
      <button *ngIf="!editingEmail" (click)="editingEmail = true" title="Edit"><i class="far fa-edit"></i>
      </button>
      <button *ngIf="editingEmail" (click)="editingEmail = false; updateEmail()" [disabled]="!infEmail.valid"
        title="Save"><i class="far fa-check"></i></button>
      <button *ngIf="editingEmail" (click)="setToFallBackEmailValue(); editingEmail = false" title="Cancel"><i
          class="far fa-close"></i></button>
    </div>
  </div>
  <div class="validation-msg">
    <div *ngIf="!infEmail.valid" class="error-msg">Invalid email
    </div>
  </div>

  <!-- influencer phone number -->
  <div class="inputs-container">
    <div [class.read-only]="!editingPhone" class="inputs phone-number-container text-input">
      <span> Phone:</span>
      <ngx-intl-tel-input [ngModel]="phone" (ngModelChange)="setPhone($event)" [enablePlaceholder]="false"
        [disabled]="!editingPhone" [searchCountryFlag]="true" [maxLength]="'20'" [phoneValidation]="true"
        #phoneNumber="ngModel" [separateDialCode]="true" [selectFirstCountry]="false" [selectedCountryISO]="countryCode"
        [class.read-only]="!editingPhone"></ngx-intl-tel-input>
    </div>
    <div class="actions">
      <button *ngIf="!editingPhone" (click)="editingPhone = true" title="Edit"><i class="far fa-edit"></i>
      </button>
      <button *ngIf="editingPhone" (click)="updatePhone(phoneNumber.value)" [disabled]="!isPhoneValid" title="Save"><i
          class="far fa-check"></i></button>
      <button *ngIf="editingPhone" (click)="resetPhone()" title="Cancel"><i class="far fa-close"></i></button>
    </div>
  </div>
  <div class="validation-msg">
    <div *ngIf="!isPhoneValid" class="error-msg">Invalid phone number
    </div>
  </div>

  <!-- influencer paypal id -->
  <div class="card">
    <div class="inputs-container">
      <div class="inputs text-input">
        <span class="">
          PayPal ID: <span [ngbTooltip]="paypalId.value?.length > 20 ? paypalId.value : null" placement="top"
            class="ml-2">{{ (editingPaypal ? '' : (paypalId.value ||
            '---')) | truncate:20 }}
          </span></span>
      </div>
      <div class="actions">
        <button *ngIf="!editingPaypal" (click)="editingPaypal = true" title="Edit"><i class="far fa-edit"></i>
        </button>
        <button *ngIf="editingPaypal" [disabled]="!paypalId.valid" (click)="editingPaypal = false; updatePaypalId()"
          title="Save"><i class="far fa-check"></i></button>
        <button *ngIf="editingPaypal" (click)="paypalId.value = influencerMetadata.paypalId; editingPaypal = false"
          title="Cancel"><i class="far fa-close"></i></button>
      </div>
    </div>
    <input *ngIf="editingPaypal" type="email" customEmailValidator class="text-field" [formControl]="paypalId"
      (keydown.space)="$event.preventDefault();" placeholder="Enter PayPal ID here" name="paypalId"
      [readonly]="!editingPaypal">
  </div>
  <div class="validation-msg">
    <div *ngIf="!paypalId.valid && editingPaypal" class="error-msg">Invalid email
    </div>
  </div>

  <!-- infleuncer status -->
  <div class="inputs-container status" *ngIf="influencerMetadata.status">
    <div class="inputs">
      <span> Status: </span>
      <ng-select placeholder="Status" [class.notSelectable]="!editingStatus" dropdownPosition="bottom"
        [items]="statusArray" [multiple]="false" [hideSelected]="true" [markFirst]="false" [virtualScroll]="true"
        [searchable]="false" [clearable]="false" [disabled]="!editingStatus" [(ngModel)]="status">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items">
            <span class="ng-value-label">{{item}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="actions">
      <div class="actions">
        <button *ngIf="!editingStatus" (click)="editingStatus = true" title="Edit"><i class="far fa-edit"></i>
        </button>
        <button *ngIf="editingStatus" (click)="editingStatus = false; updateStatus()" title="Save"><i
            class="far fa-check"></i></button>
        <button *ngIf="editingStatus" (click)="status = influencerMetadata.status; editingStatus = false"
          title="Cancel"><i class="far fa-close"></i></button>
      </div>
    </div>
  </div>
  <div class="validation-msg" *ngIf="influencerMetadata.status"></div>

  <!-- Influencer rating -->
  <div class="inputs-container rating" [formGroup]="ratingForm">
    <div class="inputs">
      <span> Rating: </span>
    </div>
    <div class="rate">
      <input type="radio" formControlName="rating" id="star5" name="rating" [value]="5"
        (click)="editingRating = true" />
      <label for="star5" title="5 starts">5 stars</label>
      <input type="radio" formControlName="rating" id="star4" name="rating" [value]="4"
        (click)="editingRating = true" />
      <label for="star4" title="4 stars">4 stars</label>
      <input type="radio" formControlName="rating" id="star3" name="rating" [value]="3"
        (click)="editingRating = true" />
      <label for="star3" title="3 stars">3 stars</label>
      <input type="radio" formControlName="rating" id="star2" name="rating" [value]="2"
        (click)="editingRating = true" />
      <label for="star2" title="2 stars">2 stars</label>
      <input type="radio" formControlName="rating" id="star1" name="rating" [value]="1"
        (click)="editingRating = true" />
      <label for="star1" title="1 star">1 star</label>
    </div>
    <div class="actions">
      <div class="actions">
        <button *ngIf="editingRating" (click)="editingRating = false; updateRating()" title="Save"><i
            class="far fa-check"></i></button>
        <button *ngIf="editingRating" (click)="cancelRating(); editingRating = false" title="Cancel"><i
            class="far fa-close"></i></button>
      </div>
    </div>
  </div>
  <div class="validation-msg"></div>

  <!-- influencer Publisher -->
  <div *ngIf="partnerizeCampaign?.campaignId" class="inputs-container partnerize mb-3">
    <div class="inputs phone-number-container text-input">
      <span> Publisher: </span>
      <ng-select placeholder="Select Publisher" bindValue="publisherId" dropdownPosition="bottom"
        addTagText="Search For:" addTag="true" [items]="publishers" [multiple]="false" [hideSelected]="true"
        [markFirst]="false" [virtualScroll]="true" [disabled]="!publisherEditable"
        [(ngModel)]="partnerizeCampaignPublisherId">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items">
            <span class="ng-value-label">{{item.publisherName}}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span [ngOptionHighlight]="search" class="option-label">{{item.publisherName}}</span>
        </ng-template>
      </ng-select>
    </div>
    <div *ngIf="publisherEditable" class="actions">
      <button (click)="updatePublisher()" title="Edit"><i class="far fa-check"></i>
      </button>
    </div>
  </div>

  <!-- influencer fixedpay -->
  <div class="inputs-container" *ngIf="campaignSlug">
    <div class="inputs text-input">
      <span> Fixed Pay: </span>
      <div class="mr-auto my-1" *ngIf="!editingFixedPayValue">{{fixedPayValue.value !== null ? fixedPayCurrency:'--'}}
        <strong>{{fixedPayValue.value}}</strong>
      </div>
      <div class="fixed-pay" *ngIf="editingFixedPayValue">
        <div>
          <strong>{{fixedPayCurrency}}</strong>
        </div>
        <input class="fixed-pay-input" type="number" min="1" [formControl]="fixedPayValue" />
      </div>
      <div class="actions">
        <button *ngIf="!editingFixedPayValue" (click)="enableEditingFixPay()" title="Edit"><i class="far fa-edit"></i>
        </button>
        <button *ngIf="editingFixedPayValue" [disabled]="fixedPayValue.invalid"
          (click)="editingFixedPayValue = false; updateFixedPayValue()" title="Save"><i
            class="far fa-check"></i></button>
        <button *ngIf="editingFixedPayValue"
          (click)=" editingFixedPayValue = false;fixedPayValue.patchValue(influencerMetadata?.fixedPay?.value)"
          title="Cancel"><i class="far fa-close"></i></button>
      </div>
    </div>
  </div>
  <div class="validation-msg">
    <div *ngIf="fixedPayValue.invalid && editingFixedPayValue" class="error-msg">Invalid amount</div>
    <div class="warning-msg"
      *ngIf="editingFixedPayValue && fixedPayValue.valid && fixedPayValue.value < fixedPayPaidAmount">
      <i class="far fa-triangle-exclamation"></i> The value entered is less than the paid amount.
    </div>
  </div>

  <!-- influencer affiliate pay -->
  <div class="inputs-container d-block" *ngIf="campaignSlug">
    <div class="inputs text-input">
      <span> Affiliate: </span>
      <div class="actions ml-auto">
        <div class="inline-tooltip hide-small-window"
          [ngbTooltip]="!isAffiliateAllowed ? 'An e-commerce store has to be connected to your organization' : null"
          placement="left">
          <button *ngIf="!editingAffiliatePayValue" [disabled]="!isAffiliateAllowed"
            (click)="isAffiliateAllowed && editingAffiliatePayValue = true" title="Edit"><i class="far fa-edit"></i>
          </button>
        </div>
        <button *ngIf="editingAffiliatePayValue" [disabled]="affiliatePay.invalid "
          (click)="editingAffiliatePayValue = false; updateAffiliateCodeAndCommission();" title="Save"><i
            class="far fa-check"></i></button>
        <button *ngIf="editingAffiliatePayValue" (click)="cancelAffiliatePayChanges()" title="Cancel"><i
            class="far fa-close"></i></button>
      </div>
    </div>
    <div *ngIf="!editingAffiliatePayValue" class="affiliate-pay-values">
      <br>
      <p>Pay: {{getInfluencerCommissionDisplayValue}}</p>
      <p>Code: {{affiliateCodeDisplayValue}}</p>
    </div>
    <form *ngIf="editingAffiliatePayValue" [formGroup]="affiliatePay" class="affiliate-settings-form">
      <div ngbRadioGroup name='commissionType' class="btn-group btn-group-toggle d-flex flex-column mt-3">
        <span class="pay-span"> Pay: </span>
        <label class="label" title="Percentage value per sale">
          <div class="d-flex ">
            <input class="radio-btn mr-1" type="radio" formControlName='commissionType' name='commissionType'
              value="PERCENTAGE" (change)="handleCommissionTypeChange($event)" />
            <span>Percentage value per sale</span>
          </div>
          <div>
            <label class="label mt-2 ml-4 d-flex" style="align-items: center;">
              <input type="number" placeholder="Enter Percentage" class="input-group small-input percentage-input"
                formControlName="percentageCommissionValue" min="1"
                [attr.disabled]="affiliatePay.value.commissionType!=='PERCENTAGE' ? true : null" />
              <span> <i class="far fa-percent percentage-icon ml-2"></i></span>
            </label>
          </div>
        </label>

        <label class="label" title="Percentage value per sale">
          <div class="d-flex">
            <input class="radio-btn mr-1" type="radio" formControlName='commissionType' name='commissionType'
              value='FIXED' (change)="handleCommissionTypeChange($event)" />
            <span>Fixed value per sale</span>
          </div>
          <div class="fixed-pay mt-2 ml-4">
            <div>
              <strong>{{affiliatePay.value.currency}}</strong>
            </div>
            <input [attr.disabled]="affiliatePay.value.commissionType!=='FIXED'? true : null"
              formControlName="fixedCommissionValue" class="fixed-pay-input" type="number" min="1" />
          </div>
        </label>

        <div class="validation-msg" *ngIf="affiliatePay.invalid && editingAffiliatePayValue">
          <div class="error-msg">Invalid amount</div>
        </div>

      </div>
      <hr class="separator-line">
      <div class="affiliate-code">
        <span> Code: </span>
        <input class="percentage-input" [(ngModel)]="affiliateCode" [ngModelOptions]="{standalone: true}"
          [disabled]="isAffiliateCodeDisabled" placeholder=" Enter the code here" />
      </div>
    </form>
  </div>
  <div class="affiliate-info mb-4" *ngIf="campaignSlug">
    <i class="far fa-circle-info"></i> If you change the code, any orders tied to the old code will not be tracked.
    Orders with the updated code will be tracked from the time the code is entered.
  </div>


  <ngx-meta-shipping-details [influencerName]="basicInfo?.name" [influencerUsername]="basicInfo?.username"
    [platform]="platform" [influencerMetadata]="influencerMetadata" [isExpanded]="!!campaignSlug"
    [campaignSlugName]="campaignSlug">
  </ngx-meta-shipping-details>

</div>


<ng-template #pricingRuleModal let-modal>
  <div>
    <div class="modal-header">
      <span>Discount Groups</span>
      <button class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <span class="body-text">Select discount code group for creating the new code</span>
      <div [formGroup]='pricingRuleForm' class="select-pricingrule">
        <ng-select placeholder='Start typing group name' bindLabel='title' [bindValue]="'id'" addTagText='Search For:'
          addTag='true' dropdownPosition='top' [items]='pricingRulesList$ | async' [multiple]='false'
          [hideSelected]='true' [markFirst]='false' [loading]='loadingPricingRulesList' [virtualScroll]='true'
          [searchable]="true" formControlName='pricingRuleId' [typeahead]="pricingRuleInput$">
          <ng-template ng-multi-label-tmp let-items='items' let-clear='clear'>
            <div class='ng-value' *ngFor='let item of items'>
              <span class='ng-value-label'>{{item.title}}</span>
              <span class='ng-value-icon right' (click)='clear(item)' aria-hidden='true'>×</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item='item' let-index='index' let-search='searchTerm'>
            <span [ngOptionHighlight]='search' class='option-label'>{{item.title}}</span><br>
            <span [ngOptionHighlight]='search' class='option-sublabel'>{{getPriceRuleDescription(item)}}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <button class="primary" [disabled]="!pricingRuleForm.value.pricingRuleId"
        (click)="modal.close(true)">CONFIRM</button>
    </div>
  </div>
</ng-template>