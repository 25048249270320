import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Campaign, CampaignsService } from '../../@core/data/campaigns.service';
import { Platform, Platforms, PlatformsV2, VeraPlatforms } from '../../enums';

@Component({
  selector: 'ngx-add-to-existing-campaign-modal',
  templateUrl: './add-to-existing-campaign-modal.component.html',
  styleUrls: ['./add-to-existing-campaign-modal.component.scss'],
})
export class AddToExistingCampaignModalComponent implements OnInit {
  @Input() platform: PlatformsV2;
  @Input() navigateToCampaign = true;
  @Input() singlePlatformSelectionEnabled = false;
  @Input() enabledPlatform: Platform[];
  @Input() fromCommunityAndVeraEnabled: boolean;
  availablePlatforms = Object.keys(Platforms).filter((key) => key !== 'facebook' && key !== 'twitter');

  campaigns: Campaign[] = [];
  selectedCampaigns: Campaign[] = [];
  hasMoreCampaigns = true;
  pageNumber = 0;
  campaignsNotFoundMessage: string;
  loading: boolean;
  searchText: string;

  constructor(private campaignsService: CampaignsService, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.fetchCampaigns();
  }

  async fetchCampaigns(campaignName?: string): Promise<void> {
    this.campaignsNotFoundMessage = '';
    const platformDetail =
      this.fromCommunityAndVeraEnabled && this.platform === Platforms.instagram
        ? VeraPlatforms.instagram
        : this.platform;
    this.campaignsService
      .getCampaignInfos(this.pageNumber, platformDetail, { campaignName })
      .then((response) => {
        const campaignInfos = response.campaigns;
        this.campaigns.push(...campaignInfos);
        this.pageNumber = this.pageNumber + 1;
        this.hasMoreCampaigns = response.hasMoreCampaigns;
        if (campaignInfos?.length === 0 && campaignName) {
          this.campaignsNotFoundMessage = 'No campaigns found with the name ' + campaignName;
        }
        this.loading = false;
      })
      .catch(() => {
        this.campaignsNotFoundMessage = 'Failed to fetch more campaigns';
      });
  }

  async onScroll(): Promise<void> {
    if (this.loading || !this.hasMoreCampaigns) {
      return;
    }

    this.loading = true;
    await this.fetchCampaigns(this.searchText);
  }

  async searchCampaigns(): Promise<void> {
    if (!this.loading) {
      this.campaigns = [];
      this.pageNumber = 0;
      this.hasMoreCampaigns = true;
      this.loading = true;
      await this.fetchCampaigns(this.searchText);
    }
  }

  toggleSelectCampaign(campaign: Campaign): void {
    if (this.selectedCampaigns.includes(campaign)) {
      const index = this.selectedCampaigns.indexOf(campaign);
      this.selectedCampaigns.splice(index, 1);
    } else {
      this.selectedCampaigns.push(campaign);
    }
  }

  addToCampaign(): void {
    this.activeModal.close(this.selectedCampaigns);
  }

  closeModal(): void {
    this.activeModal.close();
  }
  onPlatformSelectionChange(event: Platform): void {
    this.platform = Platforms[event];
    this.selectedCampaigns = [];
    this.campaigns = [];
    this.fetchCampaigns();
  }
}
