import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FacebookService } from '../../../@core/data/facebook/facebook.service';
import { Group, GroupInfo, GroupService } from '../../../@core/data/group.service';
import { InfluencerService } from '../../../@core/data/influencer.service';
import { InstagramProfilingJobService } from '../../../@core/data/instagram/instagram-profiling-job.service';
import { TiktokService } from '../../../@core/data/tiktok/tiktok.service';
import { TwitterService } from '../../../@core/data/twitter/twitter.service';
import { YoutubeService } from '../../../@core/data/youtube/youtube.service';
import utils from '../../../@core/utils/utils';
import { Platforms, PlatformsV2, VeraPlatforms } from '../../../enums';
import { isNil } from 'lodash';

@Component({
  selector: 'ngx-profile-influencers-modal',
  templateUrl: './profile-influencers-modal.component.html',
  styleUrls: ['./profile-influencers-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(500)),
    ]),
  ],
})
export class ProfileInfluencersModalComponent implements OnInit, OnDestroy {
  @Input() public selectedGroupProps: Group;
  @Input() public platform: PlatformsV2;

  collapsed = true;

  influencerUsernames: string;
  loading = false;
  availableProfileUsernames: string[] = [];
  inProgressProfileUsernames: string[] = [];
  resultsPlaceholderText: string;
  inputPlaceholderText: string;
  resultsAvailable = false;
  errorOccured = false;
  Platforms = Platforms;

  addToGroup = false;
  groupName: string;
  selectedGroup: Group;
  influencerGroups: GroupInfo[] = [];
  groupErrorMessage = '';
  groupSuccessMessage = '';

  newGroup = false;
  subscription: Subscription;
  isProfilingComplete = false;

  constructor(
    private activeModal: NgbActiveModal,
    private influencerService: InfluencerService,
    private facebookService: FacebookService,
    private youtubeService: YoutubeService,
    private tiktokService: TiktokService,
    private twitterService: TwitterService,
    private groupService: GroupService,
    private instagramProfilingJobService: InstagramProfilingJobService,
  ) {}

  ngOnInit(): void {
    if (this.selectedGroupProps) {
      this.selectedGroup = this.selectedGroupProps;
      this.collapsed = false;
    }
    this.inputPlaceholderText =
      this.platform === Platforms.youtube
        ? 'Enter Influencers channel ids separated by comma, space, or a new line...'
        : 'Enter Influencers usernames separated by comma, space, or a new line...';
    this.resultsPlaceholderText = 'The status of influencers profiling will be shown here!';

    this.addToGroup = false;

    const groupsObservable =
      this.platform === Platforms.instagram
        ? this.groupService.instagramGroupsChanged$
        : this.platform === Platforms.facebook
        ? this.groupService.facebookGroupsChanged$
        : this.platform === Platforms.tiktok
        ? this.groupService.tiktokGroupsChanged$
        : this.platform === Platforms.twitter
        ? this.groupService.twitterGroupsChanged$
        : this.platform === VeraPlatforms.instagram
        ? this.groupService.veraInstagramGroupsChanged$
        : this.groupService.youtubeGroupsChanged$;

    this.subscription = groupsObservable.subscribe((groups) => {
      this.influencerGroups =
        this.platform !== Platforms.instagram
          ? groups
          : groups.filter((influencerGroup) => influencerGroup.name !== 'Notes');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUsernameFormats(): string {
    const infoText = 'Supported formats: ';
    switch (this.platform) {
      case Platforms.facebook:
        return infoText + 'username, @username, "username", www.facebook.com/username';
      case Platforms.tiktok:
        return infoText + 'username, @username, "username", https://www.tiktok.com/@username';
      case Platforms.twitter:
        return infoText + 'username, @username, "username", https://twitter.com/username';
      case Platforms.youtube:
        return (
          infoText +
          'username, channel-id, https://www.youtube.com/channel/channel-id, https://www.youtube.com/user/username, https://www.youtube.com/@handle, https://www.youtube.com/c/customUrl'
        );
      default:
        return (
          infoText +
          'username, @username, "username", https://www.instagram.com/username/?hl=en , instagram.com/username'
        );
    }
  }

  get isButtonDisabled(): boolean {
    return (
      this.resultsAvailable ||
      this.loading ||
      !this.influencerUsernames?.length ||
      (this.addToGroup && !(this.newGroup ? this.groupName : this.selectedGroup))
    );
  }

  profileInfluencers(): void {
    if (!this.influencerUsernames?.trim()?.length) return;

    if (this.platform !== Platforms.youtube) {
      this.influencerUsernames = this.influencerUsernames.toLowerCase();
    }

    if (!this.validateUsernames(this.influencerUsernames.trim().split(/[\s,\n]+/))) {
      return;
    }

    const usernames = utils.formatUsernames(this.influencerUsernames.trim().split(/[\s,\n]+/));

    if (!usernames.length) {
      this.influencerUsernames = '';
      return;
    }

    this.loading = true;

    if (usernames?.length > 500) {
      this.errorOccured = true;
      this.resultsPlaceholderText = `Profiling limit exceeded! You can profile 500 influencers at a time.`;
      this.loading = false;
      return;
    }

    if (this.addToGroup) {
      this.groupErrorMessage = '';
      if (!this.validateGroup()) {
        return;
      }
    }
    if (this.addToGroup && !this.newGroup && !this.selectedGroup) this.addToGroup = false;
    this.profileInfluencersCall(usernames);
  }

  profileInfluencersCall(usernames: string[]): void {
    const addToGroupName = this.addToGroup ? (this.newGroup ? this.groupName : this.selectedGroup?.name) : undefined;
    const addToGroupSlug = this.addToGroup ? (this.newGroup ? undefined : this.selectedGroup?.slugName) : undefined;
    let response;
    if (isNil(this.platform)) {
      return this.handleError();
    }
    if (this.platform === Platforms.instagram) {
      response = this.influencerService.profileInfluencersList(usernames, addToGroupName, addToGroupSlug);
    } else if (this.platform === Platforms.facebook) {
      response = this.facebookService.profileInfluencersList(usernames, addToGroupName, addToGroupSlug);
    } else if (this.platform === Platforms.tiktok) {
      response = this.tiktokService.profileInfluencersList(usernames, addToGroupName, addToGroupSlug);
    } else if (this.platform === Platforms.twitter) {
      response = this.twitterService.profileInfluencersList(usernames, addToGroupName, addToGroupSlug);
    } else if (this.platform === VeraPlatforms.instagram) {
      response = this.instagramProfilingJobService.profileListOfInfluencers(usernames, addToGroupName, addToGroupSlug);
    } else if (this.platform === Platforms.youtube) {
      this.youtubeService.profileInfluencersList(usernames, addToGroupName, addToGroupSlug).then((result) => {
        this.availableProfileUsernames = result.profiledUsernames;
        this.inProgressProfileUsernames = result.idsToProfile;
        this.resultsAvailable = true;
        this.errorOccured = false;
        this.loading = false;
        this.groupSuccessMessage = this.addToGroup
          ? this.newGroup
            ? 'Influencers added to the new group: ' + addToGroupName
            : 'Influencers added to the existing group: ' + addToGroupName
          : '';
      });
      return;
    }
    if (isNil(response)) {
      return this.handleError();
    }
    response
      .then((result) => {
        this.handleResult(result, usernames, addToGroupName);
      })
      .catch(() => {
        this.handleError();
      });
  }

  handleResult(result: string[], usernames: string[], addToGroupName?: string): void {
    this.availableProfileUsernames = result;
    this.inProgressProfileUsernames = usernames.filter((username) => !result.includes(username));
    this.resultsAvailable = true;
    this.errorOccured = false;
    this.loading = false;
    this.groupSuccessMessage = this.addToGroup
      ? this.newGroup
        ? 'Influencers added to the new group: ' + addToGroupName
        : 'Influencers added to the existing group: ' + addToGroupName
      : '';
    this.isProfilingComplete = true;
    this.groupService.fetchInfluencerGroups(this.platform);
    if (!this.newGroup) this.groupService.sendGroupFilterChangedEvent(this.selectedGroup);
  }

  handleError(): void {
    this.resultsPlaceholderText = `Failed to run profile influencers job! Try again later...`;
    this.errorOccured = true;
    this.resultsAvailable = false;
    this.loading = false;
  }

  validateGroup(): boolean {
    if (this.newGroup) {
      if (!this.groupName || this.groupName === '') {
        this.groupErrorMessage = 'Group name cannot be empty';
        this.loading = false;
        return false;
      }
      const newGroupSlug = utils.slugify(this.groupName);
      if (this.influencerGroups.filter((influencerGroup) => influencerGroup.slugName === newGroupSlug)?.length > 0) {
        this.groupErrorMessage = 'Group name already exists, please use another name';
        this.loading = false;
        return false;
      }
    } else {
      if (this.selectedGroup === undefined) {
        this.groupErrorMessage = 'Please select a group or create a new group';
        this.loading = false;
        return false;
      }
    }
    return true;
  }

  validateUsernames(usernames: string[]): boolean {
    if (this.platform === Platforms.youtube) {
      const youtubeRegex = new RegExp('^(https?://)?((www.)?youtube.com)/(((c|channel|user)/[^/]+$)|@[^/]+$)');
      for (const username of usernames) {
        if (youtubeRegex.test(username) ? false : username.includes('/')) {
          this.resultsPlaceholderText = 'Invalid YouTube username/URL';
          this.errorOccured = true;
          this.loading = false;
          return false;
        }
      }
    } else {
      const platformValue = this.platform === 'VeraInstagram' ? 'instagram' : this.platform.toLowerCase();
      const usernameRegex = new RegExp(
        `^(https?://)?((www.)?${platformValue}.com)/${this.platform === Platforms.tiktok ? '@' : ''}.+$`,
      );
      for (const username of usernames) {
        if (usernameRegex.test(username) ? false : username.includes('/')) {
          this.resultsPlaceholderText = `Invalid ${platformValue} username/URL`;
          this.errorOccured = true;
          this.loading = false;
          return false;
        }
      }
    }

    this.errorOccured = false;
    return true;
  }

  collapseChanged(event: boolean): void {
    this.addToGroup = !event;
    /// TODO@SAHRAGTY: REMOVE THIS ONCE AGENCY IS LUNCHED
    if (this.addToGroup) {
      this.groupService.fetchInfluencerGroups(this.platform);
    }
  }

  closeModal(): void {
    /**
     * if available profiles are added to an existing group, send slugname back
     * needed in groups page to refresh group influencers
     */
    if (this.addToGroup && this.selectedGroup && this.availableProfileUsernames.length > 0) {
      this.activeModal.close(this.selectedGroup.slugName);
    } else {
      this.activeModal.close();
    }
  }
}
