import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TrendsAggregation } from '../../../interfaces';
import { DashboardFilters } from '../../../pages/facebook-trends/facebook-dashboard/facebook-dashboard.component';

@Injectable()
export class FacebookTrendsService {
  private dashboardFilters = new Subject<DashboardFilters>();
  dashboardFilters$ = this.dashboardFilters.asObservable();

  setDashboardFilters(filters: DashboardFilters): void {
    this.dashboardFilters.next(filters);
  }

  constructor(private http: HttpClient) {}

  createTrendsDashboard(filters: DashboardFilters): Promise<TrendsAggregation[]> {
    return this.http
      .post(environment.api + `/api/facebook/reports/content-trends/`, filters)
      .toPromise()
      .then((result: TrendsAggregation[]) => {
        return result;
      });
  }

  exportTrends(filters: DashboardFilters): Promise<Blob> {
    return this.http
      .post(`${environment.api}/api/facebook/reports/content-trends/export/`, filters, { responseType: 'blob' })
      .toPromise();
  }
}
