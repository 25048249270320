import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { SharedModule } from '../shared/shared.module';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AuthModule } from './auth/auth.module';
import { LocalStorageService } from './auth/local-storage.service';
import { DataModule } from './data/data.module';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { AnalyticsService } from './utils/analytics.service';
import { CountryService } from './utils/country.service';
import { RavenService } from './utils/raven.service';
import { FilesService } from './utils/files.service';
import { LanguageService } from './utils/language.service';

const NB_CORE_PROVIDERS = [
  ...AuthModule.forRoot().providers,
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({
    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
        // login: {
        //   rememberMe: true,
        // }
      }),
    ],
  }).providers,
  AnalyticsService,
  CountryService,
  RavenService,
  FilesService,
  LanguageService,
  LocalStorageService,
];

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [NbAuthModule],
  providers: [
    ...NB_CORE_PROVIDERS,
    FeatureFlagService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
