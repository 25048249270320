import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Platforms, VeraPlatforms } from '../../enums';
import { FeatureFlagService } from '../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../@core/feature-flag/flags';
@Component({
  selector: 'ngx-influencer-discovery-card',
  templateUrl: './influencer-discovery-card.component.html',
  styleUrls: ['./influencer-discovery-card.component.scss'],
})
export class InfluencerDiscoveryCardComponent implements OnInit {
  isRestricted = false;

  @Output() onSelect = new EventEmitter();
  @Input() showTargetedFollowersCount;
  @Input() markLocked;
  @Output() imageError = new EventEmitter();

  @Input() followers: number;
  @Input() engagementRate: number;
  @Input() biography: string;
  @Input() profilePictureUrl: string;
  @Input() name: string;
  @Input() username: string;
  @Input() selected: boolean;
  @Input() isPotentialInfluencer: boolean;
  @Input() isProfiling: boolean;
  @Input() profileUrlPrefix: string;
  @Input() matchRatio: number;
  @Input() sortInfluencersKey: string;
  @Input() followersGrowth: number;
  @Input() audienceCount: number;
  @Input() isSubscribers = false;
  @Input() isAffableVerified = false;
  @Input() badgeSize = 'large';
  @Input() badgeOver = 'mediumBadgeOver';
  @Input() platform: Platforms | VeraPlatforms;

  constructor(private featureFlagService: FeatureFlagService) {}

  get profileUrl(): string {
    return `${this.profileUrlPrefix}/${encodeURIComponent(this.username)}`;
  }

  async ngOnInit(): Promise<void> {
    this.isRestricted = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.APP_UPDATES);
  }

  isInstagram(): boolean {
    const platformToLowerCase = this.platform.toLowerCase();
    return platformToLowerCase === 'instagram' || platformToLowerCase === 'verainstagram';
  }
  profileNavigationLinkClass() {
    let classes = 'influencer-card-container';
    if (!this.isProfiling && this.isPotentialInfluencer) {
      classes += ' disable-profile-navigation';
    }

    return classes;
  }
  get isYoutube(): boolean {
    const platformToLowerCase = this.platform.toLowerCase();
    return platformToLowerCase === Platforms.youtube.toLowerCase();
  }
}
