import { sharedEnvironment } from './base';
import { Environment } from '../app/interfaces';

export const environment: Environment = Object.freeze({
  ...sharedEnvironment,
  production: false,
  api: 'https://api.staging.affable.ai',
  sequenceApi: 'https://api.staging.affable.ai/sequence-api',
  host: 'api.staging.affable.ai',
  sequenceApiHost: 'api.staging.affable.ai',
  auth0Api: 'https://beaffable-dev.auth0.com/',
  auth0ApiHost: 'beaffable-dev.auth0.com',
  sentryDSN: 'https://72480633e96d4908a0aeda1c9c6b618b@o138279.ingest.sentry.io/305895',
  baseUrl: 'https://app.staging.affable.ai',
  authConfig: {
    clientID: 'pe7jlCDz7LIug9qM6Fi95F7en71uQYlJ',
    domain: 'beaffable-dev.auth0.com',
    callbackURL: 'http://localhost:4200/callback',
    connection: 'Username-Password-Authentication',
    logoutURL: 'https://beaffable-dev.auth0.com/v2/logout',
    audience: 'https://dev.app.affable.xyz/',
  },
  paypalID: 'AaOd3EHxXnl7adV7usxCYw9tJbrBUD2gthDxfQ7N35OjI3LbZYS8Y4MQQPZePwLAeZJmdFkDpHGxf5Zz',
  intercomAppID: 'd8h21aka',
  creatorsAppURL: 'https://creators.staging.affable.ai/',
  launchDarklyKey: '632965b2b91958119d9ff2f3',
  overlapConfig: { overlapWeight: 2, sainsburyWeight: 8 },
  avenueAppURL: 'https://influencers.staging.affable.ai/',
  veraReleased: true,
});
