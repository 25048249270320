<div class="form-group">
  <label class="filter-label">{{filterLabel}}</label>
  <div class="input-group input-group-sm" id="input-group">
    <div *ngIf="!this.bucketLoading">
      <label class="filter-label">During</label>
      <ng-select dropdownPosition="bottom" placeholder="Duration" [items]="bucketModes" [hideSelected]="true"
        [searchable]="false" [clearable]="false" [(ngModel)]="selectedFollowersGrowth.bucket"
        (change)="triggerUpdateEvent($event)">
      </ng-select>
    </div>

    <div>
      <label class="filter-label">Within the range of</label>
      <ng-select dropdownPosition="bottom" placeholder="Range" [items]="valueRangeModes" [hideSelected]="true"
        [searchable]="false" [clearable]="false" [(ngModel)]="selectedFollowersGrowth.valueRange"
        (change)="triggerUpdateEvent($event)">
      </ng-select>
    </div>
  </div>
</div>
