/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Platforms, PlatformsV2 } from '../../enums';
import {
  AffiliateCodeExistsEnum,
  DiscountCode,
  EcommerceProducts,
  EcommerceSearchProducts,
  InfluencerLookup,
  InfluencersCommission,
  PricingRule,
} from '../../interfaces';
import { AffiliateCode } from './metadata.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, of, throwError } from 'rxjs';

@Injectable()
export class EcommerceService {
  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  getCampaignDiscountCodes(campaignSlug: string, platform: Platforms): Promise<DiscountCode[]> {
    return this.http
      .get<DiscountCode[]>(`${environment.api}/api/ecommerce/campaigns/${campaignSlug}/discounts`, {
        params: { platform },
      })
      .toPromise();
  }

  addCampaignDiscountCode(
    username: string,
    campaignSlug: string,
    platform: PlatformsV2,
    event?: string,
  ): Promise<DiscountCode> {
    const params = event ? { platform, event } : { platform };
    return this.http
      .post<DiscountCode>(
        environment.api + `/api/ecommerce/campaigns/${campaignSlug}/influencer/${username}/discounts`,
        {},
        { params },
      )
      .toPromise();
  }

  async generateDiscountCode(
    influencerUsername: string,
    campaignSlugName: string,
    platform: PlatformsV2,
  ): Promise<AffiliateCode> {
    return this.addCampaignDiscountCode(influencerUsername, campaignSlugName, platform)
      .then((res) => {
        return res['affiliateCode'];
      })
      .catch((error) => {
        this.toastrService.error(error.error?.message);
      });
  }

  getPricingRulesList(platform: Platforms, searchString?: string): Promise<PricingRule[]> {
    return this.http
      .get<PricingRule[]>(`${environment.api}/api/ecommerce/priceRules`, {
        params: { platform, searchString },
      })
      .toPromise();
  }

  getProducts(pageInfo = ''): Promise<EcommerceProducts> {
    return this.http
      .get<EcommerceProducts>(`${environment.api}/api/ecommerce/products`, {
        params: { pageInfo },
      })
      .pipe(
        map((result) => {
          result.products.forEach(
            (product) => (product.variants = product.variants.map((ele) => ({ ...ele, quantity: 1 }))),
          );
          return result;
        }),
      )
      .toPromise();
  }

  getStoreShop(): Promise<any> {
    return this.http.get<any>(`${environment.api}/api/ecommerce/storeShop`).toPromise();
  }

  searchProducts(input: string, cursor: string, page: string): Promise<EcommerceSearchProducts> {
    return this.http
      .get<EcommerceSearchProducts>(`${environment.api}/api/ecommerce/search-products`, {
        params: { input, cursor, page },
      })
      .pipe(
        map((result) => {
          result.products.forEach(
            (product) => (product.variants = product.variants.map((ele) => ({ ...ele, quantity: 1 }))),
          );
          return result;
        }),
        catchError((error) => {
          if (error.status === 502 && error.error?.message?.includes('Shopify Error')) {
            this.toastrService.error('Unable to lookup product. Temporary issue with Shopify. Please try again later.');
          }
          return throwError(error);
        }),
      )
      .toPromise();
  }

  updateAffiliateCodeAndCommission(
    username: string,
    campaignSlugName: string,
    affiliateCode: string,
    platform: Platforms,
    influencersCommission: InfluencersCommission,
    pricingRuleId: string | undefined,
  ): Promise<{ affiliateCode: AffiliateCode; influencersCommission: InfluencersCommission }> {
    return this.http
      .post<{ affiliateCode: AffiliateCode; influencersCommission: InfluencersCommission }>(
        environment.api + `/api/ecommerce/campaigns/affiliate-code-commission`,
        {
          username,
          campaignSlugName,
          affiliateCode,
          platform: platform.toUpperCase(),
          influencersCommission,
          pricingRuleId,
        },
      )
      .toPromise();
  }

  checkIsAffiliateCodeAvailable(
    affiliateCode: string,
    platform: Platforms,
    campaignSlugName: string,
  ): Promise<{
    exists: AffiliateCodeExistsEnum;
    pricingRuleId: string;
    records: { campaignSlugName: string; campaignName: string; influencer: InfluencerLookup }[];
  }> {
    return this.http
      .get<any>(environment.api + `/api/ecommerce/campaigns/existing-code`, {
        params: { affiliateCode, platform, campaignSlugName },
      })
      .toPromise();
  }
}

export enum PricingRuleValueType {
  percentage = 'percentage',
  fixed = 'fixed',
}
