<div class="modal-header">
  <b>Profile Influencers</b>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div *ngIf="!resultsAvailable" class="form-group">
    <textarea data-cy="influencer-usernae-field" class="form-control" [(ngModel)]="influencerUsernames" trim
      [disabled]="loading" [placeholder]="inputPlaceholderText" required></textarea>
  </div>
  <div class="results">
    <ngx-loader class="modal-loader" *ngIf="loading"></ngx-loader>
    <div class="group-container" *ngIf="!loading && !resultsAvailable">
      <nb-accordion>
        <nb-accordion-item (collapsedChange)="collapseChanged($event)" [collapsed]="collapsed">
          <nb-accordion-item-header>
            Add Influencer to a group
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <nb-radio-group [(value)]="newGroup">
              <nb-radio [value]="false">
                <div class="form-group">
                  <label>Existing group </label>
                  <div class="input-group input-group-sm">
                    <ng-select placeholder="Select group" dropdownPosition="bottom" bindLabel="name"
                      editableSearchTerm=true (change)="newGroup = false" [items]="influencerGroups" [multiple]="false"
                      [hideSelected]="true" [loading]="loadingGroups" [markFirst]="false" [(ngModel)]="selectedGroup">
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <div class="ng-value">
                          <span class="ng-value-label">{{item.name}}</span>
                          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span [ngOptionHighlight]="search">{{item.name}}</span><br>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </nb-radio>
              <nb-radio [value]="true">
                <div class="form-group">
                  <label> New group</label>
                  <input class="group-name" type="text" [(ngModel)]="groupName" (input)="newGroup = true"
                    placeholder="Enter group name" required />
                </div>
              </nb-radio>
            </nb-radio-group>
            <div class="group-error-msg">{{groupErrorMessage}}</div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>

    <div class="results-placeholder" *ngIf="!loading && !resultsAvailable">
      <p class="placeholder-text" [class.error]="errorOccured"> {{resultsPlaceholderText}}</p>
      <p class="username-formats" [class.error]="errorOccured && platform !== Platforms.youtube ">
        {{getUsernameFormats()}}</p>
    </div>
    <div *ngIf="!loading && resultsAvailable">
      <p class="list-title">Profiles Available:</p>
      <div class="usernames-list">
        <ng-container *ngIf="platform === Platforms.instagram">
          <a class="username" *ngFor="let username of availableProfileUsernames"
            href="/pages/profile/{{username}}">{{username}}</a>
        </ng-container>
        <ng-container *ngIf="platform !== Platforms.instagram">
          <a class="username" *ngFor="let username of availableProfileUsernames"
            href="/pages/{{platform.toLowerCase()}}/profile/{{username}}">{{username}}</a>
        </ng-container>
      </div>
      <p class="list-title">Profiling in progress:</p>
      <div class="usernames-list">
        <a class="username" *ngFor="let username of inProgressProfileUsernames">{{username}}</a>
      </div>
      <div data-cy="successfully-submitted-message" class="success-message">Profile influencers request is successfully
        submitted.</div>
      <div *ngIf="platform === Platforms.instagram" class="group-message">{{groupSuccessMessage}}</div>
    </div>

  </div>
</div>
<div class="modal-footer">
  <div *ngIf="!isProfilingComplete" class="d-flex">
    <ai-button class="mr-2" [disabled]="loading" type="ghost" (click)="closeModal()" label="CANCEL"></ai-button>
    <ai-button [disabled]="isButtonDisabled" type="fill" size="large" (click)="profileInfluencers()"
      data-cy="profile-button" label="PROFILE"></ai-button>
  </div>
  <div *ngIf="isProfilingComplete">
    <ai-button type="fill" size="large" (click)="closeModal()" data-cy="done-button" label="DONE"></ai-button>
  </div>
</div>