import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { rotationConfig, SortDirection } from '../../enums';
import { SortEvent } from '../../interfaces';

@Directive({
  selector: 'th[ngxSortable]',
})
export class SortableHeaderDirective {
  @Input() ngxSortable = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  @HostBinding('class.desc')
  get desc(): boolean {
    return this.direction === 'desc';
  }
  @HostBinding('class.asc')
  get asc(): boolean {
    return this.direction === 'asc';
  }

  @HostBinding('class.default')
  get default(): boolean {
    return this.direction === '';
  }
  @HostBinding('class.sortable') private sortable = true;
  @HostListener('click') onClick(): void {
    this.rotate();
  }

  rotate(): void {
    this.direction = rotationConfig[this.direction];
    this.sort.emit({ column: this.ngxSortable, direction: this.direction });
  }
}
