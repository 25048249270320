export const getUsernameValue = (username: string, platform: string): string | undefined => {
  if (!username?.trim()) {
    return '';
  }
  if (platform === 'youtube') {
    return username.replace(new RegExp('^(?:https?://)?(?:www.)?(?:youtube.com/)?(?:user/|channel/|c/|@)?'), '');
  }
  return username.split('/')?.pop()?.replace('@', '');
};

export const getUsernamePattern = (platform: string): RegExp => {
  if (platform === 'youtube') {
    return new RegExp('^(?:https?://)?(?:www.)?(?:youtube.com/)?(?:user/|channel/|c/|@)?[a-zA-Z0-9]+[a-zA-Z0-9_.-]*$');
  }

  return new RegExp(
    `(^(https?://)?((www.)?${platform.toLowerCase()}.com)/${platform === 'tiktok' ? '@' : ''}.+$)|^[a-zA-Z0-9-_.@]+$`,
  );
};
