import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.auth.isAuthenticated()) {
      if (this.auth.checkIfRefreshTokenExists()) {
        // If token exists then its probably expired so refresh it
        await this.auth.refreshToken();

        // Retry the same request with the modified access token
        const token = this.auth.getAccessTokenFromCache();
        if (token) {
          return true;
        }
      }
      this.router.navigate(['auth', 'login'], {
        queryParams: { redirectUrl: state.url },
      });
      return false;
    }
    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.canActivate(route, state);
  }
}
