<div [class]="classes" [style]="getCustomWidthStyle()">
  <div *ngIf="optionsToDisplay?.header">
    <div class="af-label dp-title body-3 ai-pl12 ai-pr6">{{ optionsToDisplay?.header }}</div>
    <div class="divider-x"></div>
  </div>
  <div class="drop-down-wrapper ai-px0 ai-py1" data-cy="sendmail-dropdown-menu" cdkScrollable>
    <div *ngIf="getEntriesLength()===0 && emptyDisplay" class="ai-pl12 body-4 placeholder-text">{{emptyDisplay}}</div>
    <ng-container *ngIf="getEntriesLength()>0||!emptyDisplay">
      <ng-container *ngFor="let group of optionsToDisplay.groupOptions; let i = index">
        <ng-container [ngSwitch]="group.selector">
          <ng-container
            *ngSwitchCase="'none'"
            [ngTemplateOutlet]="noneGroup"
            [ngTemplateOutletContext]="{ group: group, index: i }"
          >
          </ng-container>

          <ng-container
            *ngSwitchCase="'checkmark'"
            [ngTemplateOutlet]="checkmarkGroup"
            [ngTemplateOutletContext]="{ group: group, index: i }"
          >
          </ng-container>

          <ng-container
            *ngSwitchCase="'checkbox'"
            [ngTemplateOutlet]="checkboxGroup"
            [ngTemplateOutletContext]="{ group: group, index: i }"
          >
          </ng-container>
        </ng-container>
      </ng-container>
  </ng-container>
  </div>
</div>

<ng-template #noneGroup let-group="group" let-index="index">
  <ng-container [ngTemplateOutlet]="divider" [ngTemplateOutletContext]="{ index: index }"></ng-container>
  <ng-container [ngTemplateOutlet]="group?.type === 'accordion' ? accordianGroup : categoryGroup" [ngTemplateOutletContext]="{ group: group }"></ng-container>
  <ng-container *ngIf="group?.isCollapsed;else checkboxOne"></ng-container>
  <ng-template #checkboxOne>
    <ng-container *ngIf="!shiftSelectedToTop;else showSelectedAtTopCheckboxGroup">
    <ai-dropdown-item
    *ngFor="let item of group.items"
    [label]="item.displayName || item.key"
    [value]="item.key"
    [selected]="item.value"
    [disabled]="item.disabled"
    labelSize="body-2"
    [labelWidth]="90"
    [type]="'none'"
    (action)="getDropDownValue($event)"
    [image]="item.image"
    [imageStyle]="item.imageStyle"
    [subLabel]="item.displaySubName||item.subName"
    [suffix]="item.suffix"
    [dropdownItemType]="dropdownItemType"
    [isRegistered]="item.isRegistered"
  ></ai-dropdown-item>
  </ng-container>
  </ng-template>
  <ng-template #showSelectedAtTopNoneGroup>
  <ng-container *ngFor="let item of group.items">
    <ai-dropdown-item
    *ngIf="item.value"
    [label]="item.displayName || item.key"
    [value]="item.key"
    [selected]="item.value"
    [disabled]="item.disabled"
    labelSize="body-2"
    [labelWidth]="90"
    [type]="'none'"
    (action)="getDropDownValue($event)"
    [image]="item.image"
    [imageStyle]="item.imageStyle"
    [subLabel]="item.displaySubName||item.subName"
    [suffix]="item.suffix"
    [dropdownItemType]="dropdownItemType"
    [isRegistered]="item.isRegistered"
  ></ai-dropdown-item>
  </ng-container>
  <ng-container *ngFor="let item of group.items">
    <ai-dropdown-item
    *ngIf="!item.value"
    [label]="item.displayName || item.key"
    [value]="item.key"
    [selected]="item.value"
    [disabled]="item.disabled"
    labelSize="body-2"
    [labelWidth]="90"
    [type]="'none'"
    (action)="getDropDownValue($event)"
    [image]="item.image"
    [imageStyle]="item.imageStyle"
    [subLabel]="item.displaySubName||item.subName"
    [suffix]="item.suffix"
    [dropdownItemType]="dropdownItemType"
    [isRegistered]="item.isRegistered"
  ></ai-dropdown-item>
  </ng-container>
</ng-template>
</ng-template>

<ng-template #checkmarkGroup let-group="group" let-index="index">
  <ng-container [ngTemplateOutlet]="divider" [ngTemplateOutletContext]="{ index: index }"></ng-container>
  <ng-container [ngTemplateOutlet]="group?.type === 'accordion' ? accordianGroup : categoryGroup" [ngTemplateOutletContext]="{ group: group}"></ng-container>
  <ng-container *ngIf="group?.isCollapsed;else checkboxOne"></ng-container>
  <ng-template #checkboxOne>
    <ng-container *ngIf="!shiftSelectedToTop;else showSelectedAtTopCheckboxGroup">
    <ai-dropdown-item
    *ngFor="let item of group.items"
    [label]="item.displayName || item.key"
    [value]="item.key"
    [selected]="item.value"
    [disabled]="item.disabled"
    labelSize="body-2"
    [labelWidth]="90"
    [type]="'checkmark'"
    (action)="getDropDownValue($event)"
    [image]="item.image"
    [imageStyle]="item.imageStyle"
    [subLabel]="item.displaySubName||item.subName"
    [suffix]="item.suffix"
    [dropdownItemType]="dropdownItemType"
    [isRegistered]="item.isRegistered"
  ></ai-dropdown-item>
  </ng-container>
  </ng-template>
  <ng-template #showSelectedAtTopCheckmarkGroup>
    <ng-container *ngFor="let item of group.items">
      <ai-dropdown-item
      *ngIf="item.value"
      [label]="item.displayName || item.key"
      [value]="item.key"
      [selected]="item.value"
      [disabled]="item.disabled"
      labelSize="body-2"
      [labelWidth]="90"
      [type]="'checkmark'"
      (action)="getDropDownValue($event)"
      [image]="item.image"
      [imageStyle]="item.imageStyle"
      [subLabel]="item.displaySubName||item.subName"
      [suffix]="item.suffix"
      [dropdownItemType]="dropdownItemType"
      [isRegistered]="item.isRegistered"
    ></ai-dropdown-item>
    </ng-container>
    <ng-container *ngFor="let item of group.items">
      <ai-dropdown-item
      *ngIf="!item.value"
      [label]="item.displayName || item.key"
      [value]="item.key"
      [selected]="item.value"
      [disabled]="item.disabled"
      labelSize="body-2"
      [labelWidth]="90"
      [type]="'checkmark'"
      (action)="getDropDownValue($event)"
      [image]="item.image"
      [imageStyle]="item.imageStyle"
      [subLabel]="item.displaySubName||item.subName"
      [suffix]="item.suffix"
      [dropdownItemType]="dropdownItemType"
      [isRegistered]="item.isRegistered"
    ></ai-dropdown-item>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #checkboxGroup let-group="group" let-index="index">
  <ng-container [ngTemplateOutlet]="divider" [ngTemplateOutletContext]="{ index: index }"></ng-container>
  <ng-container [ngTemplateOutlet]="group?.type === 'accordion' ? accordianGroup : categoryGroup" [ngTemplateOutletContext]="{ group: group}"></ng-container>
  <ng-container *ngIf="group?.isCollapsed;else checkboxOne"></ng-container>
  <ng-template #checkboxOne>
    <ng-container *ngIf="!shiftSelectedToTop;else showSelectedAtTopCheckboxGroup">
    <ai-dropdown-item
    *ngFor="let item of group.items"
    [label]="item.displayName || item.key"
    [value]="item.key"
    [selected]="item.value"
    [disabled]="item.disabled"
    [labelSize]="'body-2'"
    [labelWidth]="100"
    [type]="'checkbox'"
    (action)="getDropDownValue($event)"
    [image]="item.image"
    [imageStyle]="item.imageStyle"
    [subLabel]="item.displaySubName||item.subName"
    [suffix]="item.suffix"
    [dropdownItemType]="dropdownItemType"
    [isRegistered]="item.isRegistered"
  ></ai-dropdown-item>
  </ng-container>
  </ng-template>
  <ng-template #showSelectedAtTopCheckboxGroup>
    <ng-container *ngFor="let item of group.items">
      <ai-dropdown-item
      *ngIf="item.value"
      [label]="item.displayName || item.key"
      [value]="item.key"
      [selected]="item.value"
      [disabled]="item.disabled"
      [labelSize]="'body-2'"
      [labelWidth]="100"
      [type]="'checkbox'"
      (action)="getDropDownValue($event)"
      [image]="item.image"
      [imageStyle]="item.imageStyle"
      [subLabel]="item.displaySubName||item.subName"
      [suffix]="item.suffix"
      [dropdownItemType]="dropdownItemType"
      [isRegistered]="item.isRegistered"
    ></ai-dropdown-item>
    </ng-container>
    <ng-container *ngFor="let item of group.items">
      <ai-dropdown-item
      *ngIf="!item.value"
      [label]="item.displayName || item.key"
      [value]="item.key"
      [selected]="item.value"
      [disabled]="item.disabled"
      [labelSize]="'body-2'"
      [labelWidth]="100"
      [type]="'checkbox'"
      (action)="getDropDownValue($event)"
      [image]="item.image"
      [imageStyle]="item.imageStyle"
      [subLabel]="item.displaySubName||item.subName"
      [suffix]="item.suffix"
      [dropdownItemType]="dropdownItemType"
      [isRegistered]="item.isRegistered"
    ></ai-dropdown-item>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #categoryGroup let-group="group">
  <div *ngIf="group.groupName" class="ai-py4 ai-pl12 ai-pr12 group-header-wrapper">
    <label [class]="'group-title '+ groupNameTypo">{{ group.groupName }}</label>
  </div>
</ng-template>

<ng-template #accordianGroup let-group="group">
  <div *ngIf="group.groupName" class="ai-py4 ai-pl12 ai-pr12 group-header-wrapper">
    <label [class]="'sub-heading-h2 group-title-sentence-case'">{{ group.groupName }}</label>
    <div *ngIf="optionsToDisplay.groupOptions.length > 1" class="ai-pl12" style="cursor: pointer" (click)="toggleAccordion(group)">
    <i [ngClass]="group.isCollapsed? 'far fa-angle-down' : 'far fa-angle-up'"> </i>
    </div>
  </div>
</ng-template>

<ng-template #divider let-index="index">
  <div class="divider-x" *ngIf="index > 0"></div>
</ng-template>
<!-- This template  ☝️displays the overlay content and is connected to the origin button  -->
