import { LanguageInfo } from './../../interfaces/language.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { InfluencerSortKey, LogicalFilterType, PlatformsV2 } from '../../enums';
import { Location } from './location.service';

@Injectable()
export class MagicSearchService {
  constructor(private http: HttpClient) {}

  fetchSemanticSearchResults(params: QuerySearchParams, platform: PlatformsV2): Promise<QueryAPIResponse> {
    const { queryString, image, uploadedImageUrl, filters, offset, size } = params;
    const formData = new FormData();
    formData.append('queryString', JSON.stringify(queryString || null));
    if (image) {
      formData.append('image', image);
    }
    formData.append('uploadedImageUrls', JSON.stringify([uploadedImageUrl].filter(Boolean)));
    formData.append('existingFilters', JSON.stringify(filters || null));

    return this.http
      .post<QueryAPIResponse>(
        environment.api + `/api/instagram/semantic-search/query?offset=${offset}&size=${size}&platform=${platform}`,
        formData,
      )
      .toPromise();
  }

  fetchInfluencerSearchResults(
    filters: SemSearchFilters,
    imageUrl: string | null,
    offset: number,
    size: number,
    image?: File | null,
    platform?: PlatformsV2,
  ): Promise<SemanticSearchFilterAPIResponse> {
    const formData = new FormData();
    formData.append('filters', JSON.stringify(filters || null));
    formData.append('imageUrl', JSON.stringify(imageUrl || null));

    if (image) {
      formData.append('image', image);
    }
    return this.http
      .post<SemanticSearchFilterAPIResponse>(
        environment.api + `/api/instagram/semantic-search/filter?offset=${offset}&size=${size}&platform=${platform}`,
        formData,
      )
      .toPromise();
  }
}

interface QueryAPIResponse {
  influencers: MagicSearchInfluencer[];
  filters: SemSearchFilters;
  imageUrl: string;
  hasMore: boolean;
}

export interface SemanticSearchFilterAPIResponse {
  influencers: MagicSearchInfluencer[];
  hasMore: boolean;
  imageUrl: string;
}

interface SimilarInfluencersResponse {
  targetInfluencer: MagicSearchInfluencer;
  similarInfluencers: SimilarInfluencer[];
  hasMore: boolean;
}

interface Gender {
  female: boolean;
  male: boolean;
}

export interface LabelFilter {
  name: string;
  isIncluded: boolean;
}

export interface BioKeywordFilter {
  name: string;
  isIncluded: boolean;
}
export interface MagicSearchInfluencer {
  username: string;
  pk: string;
  fullName: string;
  followerCount: string;
  biography: string;
  email: string;
  phone: string;
  engagement: Engagement;
  picture: string;
  isAffableVerified: boolean;
  selected: boolean;
}

interface SimilarInfluencer extends MagicSearchInfluencer {
  similatiry: number;
}

interface Engagement {
  avgLikesRatio: number;
  avgCommentsRatio: number;
  score: number;
}

export interface FilterRange {
  min: number;
  max: number;
}

interface SemSearchFiltersEngagement {
  min: number;
}
export class SemSearchFilters {
  gender?: Gender;
  brands?: LabelFilter[];
  locations?: Location[] = [];
  biography?: BioKeywordFilter[];
  followerCount?: FilterRange;
  engagementRate?: SemSearchFiltersEngagement;
  sortKey?: InfluencerSortKey;
  ageRange?: FilterRange;
  lookalikes?: {
    name: string;
    isGroup: boolean;
    isIncluded?: boolean;
  };
  semanticQueries?: LabelFilter[];
  languages?: {
    filterType: LogicalFilterType;
    values: LanguageInfo[];
  };
}

interface QuerySearchParams {
  queryString: string;
  image: File | null;
  uploadedImageUrl: string | null;
  filters: SemSearchFilters;
  offset: number;
  size: number;
}

export interface FilterLabel {
  type: string;
  value: string;
}
