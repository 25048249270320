<ng-container *ngIf="types.length">
  <div class="selector">
    <div class="sections">
      <section *ngFor="let type of types;let i=index">
        <ng-template *ngTemplateOutlet="templateList; context: {$implicit: type,i:i}">
        </ng-template>
      </section>
    </div>
  </div>
  <div class="template">
    <div class="detail">
      <input type="text" class="bold" [(ngModel)]=" isDeleteMode ?deleteConfirmText:name"
        [readonly]="isDeleteMode || !isEditMode">
      <div class="action">
        <ng-container
          *ngTemplateOutlet="(isEditMode && editModeTemp) || (isDeleteMode && deleteModeTemp)|| nonEditingTemp">
        </ng-container>

        <ng-template #editModeTemp>
          <nb-checkbox status="primary" [(ngModel)]="isDefault" *ngIf="allowDelete">Make this as default
            auto-reply
          </nb-checkbox>
          <ai-button size="small" type="text" label="CANCEL" (click)="cancel()"></ai-button>
          <ai-button size="small" [disabled]="isSavingEnabled" label="SAVE" (click)="save()"></ai-button>
        </ng-template>
        <ng-template #deleteModeTemp>
          <ai-button size="small" type="text" label="NO" (click)="isDeleteMode = false"></ai-button>
          <ai-button size="small" label="YES" (click)="delete()"></ai-button>
        </ng-template>
        <ng-template #nonEditingTemp>
          <ai-button size="small" type="text" label="DELETE" *ngIf="allowDelete" (click)="isDeleteMode=true">
          </ai-button>
          <ai-button size="small" type="text" label="EDIT" *ngIf="notGeneric" (click)="isEditMode = true">
          </ai-button>
        </ng-template>
      </div>
    </div>
    <div class="subject">
      Sub: <input type="text" [(ngModel)]="subject" [readonly]="!isEditMode">
    </div>
    <ng-container *ngIf="isEditMode; else viewMessage">
      <ai-ckeditor
        #ckeditorRef
        [supportedTemplatingVariables]="supportedTemplatingVariables"
        [defaultTemplate]="template"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="template"
        [apiPath]="apiPath">
      </ai-ckeditor>
    </ng-container>
    <ng-template #viewMessage>
      <div class="message-body" [innerHTML]="template | highlightVariable"></div>
    </ng-template>
  </div>
</ng-container>

<ng-template #templateList let-section let-index="i">
  <div class="header">
    <h3>{{section.type}} Auto-reply ({{section.templates?.length}})</h3>
  </div>
  <nb-list>
    <nb-list-item *ngFor="let item of section.templates;let i = index;" [class.selected]="item.selected"
      [class.not-edited]="isEditMode||isDeleteMode" (click)="select(index,i)">{{item.name}}
      <div class="chips">
        <span *ngIf="item.conditions?.generic;">Generic</span>
        <ng-container *ngIf="item.conditions?.default; else optionsIcon">
          <span>Default</span>
        </ng-container>
      </div>
      <ng-template #optionsIcon>
        <button *ngIf="!isEditMode" class="context-menu" [popper]="templateMenu" [popperTrigger]="'click'"
          popperApplyClass="context-menu-l" [popperPlacement]="'bottom-end'">
          <i class="far fa-ellipsis-v"></i>
        </button>
      </ng-template>
    </nb-list-item>
    <nb-list-item *ngFor="let item of emptyArray(section.templates?.length)" class="empty">Empty</nb-list-item>
  </nb-list>
</ng-template>


<popper-content #templateMenu>
  <div class="list-group" *ngIf="current?._id">
    <div class="list-group-item list-group-item-action" (click)="templateMenu.hide();makeDefault()">
      Make this the default
    </div>
  </div>
  <div class="list-group" *ngIf="notGeneric">
    <div class="list-group-item list-group-item-action" (click)="templateMenu.hide();isEditMode = true">
      Edit Auto-reply
    </div>
  </div>
  <div class="list-group" *ngIf="allowDelete">
    <div class="list-group-item list-group-item-action" (click)="templateMenu.hide();delete()">
      Delete Auto-reply
    </div>
  </div>
</popper-content>
