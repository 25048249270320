import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class InstagramGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const hasInstagramEnabled = this.auth.isInstagramEnabled();
    if (!hasInstagramEnabled) {
      console.error('Instagram guard rejected access to ' + state.url);
      this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return hasInstagramEnabled;
  }
}
