<div>
    <nb-spinner *ngIf="isLoading" [message]="Loding"></nb-spinner>
    <div class="modal-header">
        <span class="heading-h2">{{isNewSequence? 'New Email Sequence': 'Edit Email Sequence'}}</span>
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-inline-flex justify-content-between mb-2 ml-2">
            <div class="d-inline-flex align-items-center">
                <span class="heading-h2 mr-3">{{sequenceName}}</span>
                <ng-container *ngTemplateOutlet="isSequenceEnabled ? enabled : disabled"></ng-container>
                <ng-template #enabled>
                    <div class="status-chip enabled">Enabled</div>
                </ng-template>
                <ng-template #disabled>
                    <div class="status-chip disabled">Disabled</div>
                </ng-template>
            </div>
            <div class="d-inline-flex justify-content-center align-items-center">
                <span class="sub-heading-h2 mr-3">{{totalEmailsInSequence}} email(s) in sequence</span>
                <div class="line-separator mr-3"></div>
                <ai-button size="small" prefix="far fa-arrow-left" label="EDIT SEQUENCE DETAILS" type="outline"
                    (click)="editSequence()">
                </ai-button>
            </div>
        </div>

        <div class="d-flex flex-row">
            <div class="sidebar">
                <div #scrollableContainer class="scrollable-container">
                    <div *ngFor="let email of sequenceEmails; let i=index">
                        <div *ngIf="!email.isDeleted">
                            <div class="delay-container" *ngIf="i !== 0">
                                <div class="connector-vertical"></div>
                                <div class="connector-horizontal"></div>
                                <div class="delay" [ngClass]="{'selected' : email.selected}">
                                    <span class="caption mr-1">DELAY:</span>
                                    <ai-num-input class="delay-input" [clearable]="false" [(ngModel)]="email.delay"
                                        name="delay" [minValue]=1>
                                    </ai-num-input>
                                    <span class="caption ml-1">DAY(S)</span>
                                </div>
                            </div>
                            <div class="follow-up">
                                <div class="active-follow-up" [ngClass]="{'selected' : email.selected}"
                                    (click)="selectEmail(i)">
                                    <div class="email-counter-container">
                                        <div class="index-circle" [ngClass]="{'selected' : email.selected}"><span>{{i +
                                                1}}</span>
                                        </div>
                                        <div>Email {{ i + 1 }}</div>
                                    </div>
                                    <i *ngIf="i > 0 && email.selected" class="fa-regular fa-trash-can-xmark"
                                        (click)="deleteEmail(i)"></i>
                                </div>
                                <div class="connector" *ngIf="email.selected"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add-follow-up-btn">
                    <ai-button [disabled]="!isAddingFollowUpEmailEnabled" prefix="far fa-plus" label="ADD EMAIL"
                        type="dash" (click)="addFollowUpEmail(scrollableContainer)">
                    </ai-button>
                </div>
            </div>
            <form [formGroup]="emailForm" class="follow-up-form">
                <div class="templete-select">
                    <div class="sub-heading-h2 text-nowrap optional mr-2">Select Template</div>
                    <ng-select class="show-arrow" dropdownPosition="bottom" placeholder="Select Email Template"
                        [items]="emailTemplates" [formControl]="emailTemplate" bindLabel="name" bindValue="_id"
                        [markFirst]="false" (change)="loadTemplate($event)">
                    </ng-select>
                </div>
                <div class="mt-3">
                    <div class="sub-heading-h2 mb-2 required">Subject</div>
                    <ai-input [label]="false" [required]="true" key="subject" name="subject"
                        placeholder="Enter Subject Here">
                    </ai-input>
                </div>
                <div class="mt-3">
                    <div class="sub-heading-h2 mb-2 required">Message</div>
                    <!-- ngif is required for the ai-ckeditor component to reload when we get the templates -->
                    <ai-ckeditor *ngIf="!!supportedTemplatingVariables"
                        [supportedTemplatingVariables]="supportedTemplatingVariables" formControlName="message"
                        [apiPath]="apiPath"></ai-ckeditor>
                </div>
            </form>
        </div>

    </div>
    <div class="modal-footer">
        <span *ngIf="restrictedKeywordsUsed" class="warn-font">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span>Some of the template keywords are not allowed.</span>
        </span>
        <span>
            <ai-button label="DISCARD" type="ghost" (click)="closeModal()"></ai-button>
            <ai-button [disabled]="isAnyEmailInvalid" label="SAVE SEQUENCE" (click)="saveSequence()"></ai-button>
        </span>
    </div>
</div>