import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Link } from '../../../../@core/data/campaigns.service';
import { LinksGroupedByDates } from '../../../../interfaces';

@Component({
  selector: 'ngx-date-grouped-links',
  templateUrl: './date-grouped-link.component.html',
  styleUrls: ['./date-grouped-link.component.scss'],
})
export class DateGroupedLinkComponent {
  @Input()
  groupIndex: number;
  dateValue: Date;
  links: Link[];

  @Input()
  set linksGroupedByDate(value: LinksGroupedByDates) {
    this.dateValue = value.date;
    this.links = value.links;
  }

  @Output() editedLinkEventEmitter = new EventEmitter();
  @Output() deletedLinkEventEmitter = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureEditLink($event): void {
    const groupIndex = this.groupIndex;
    const linkIndex = $event.linkIndex;
    const linkTitle = $event.linkTitle;
    this.editedLinkEventEmitter.emit({
      linkIndex,
      groupIndex,
      linkTitle,
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureDeleteLink($event): void {
    const groupIndex = this.groupIndex;
    const linkIndex = $event.linkIndex;
    this.deletedLinkEventEmitter.emit({
      linkIndex,
      groupIndex,
    });
  }
}
