import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';
import * as moment from 'moment';
import { NgProgress } from 'ngx-progressbar';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { Content, PartialContent, ContentService } from '../../../@core/data/content.service';
import utils from '../../../@core/utils/utils';

@Component({
  selector: 'ngx-content-details',
  styleUrls: ['./content-details.component.scss'],
  templateUrl: './content-details.component.html',
})
export class ContentDetailsComponent implements OnInit {
  lodashIsNil = isNil;

  @Input()
  contentPk: number;

  @Input()
  content: Content = undefined;

  @Input()
  partialContent: PartialContent = undefined;

  @Input()
  inCampaign: boolean;

  loaded = false;
  @Input() currency: string;
  constructor(
    private activeModal: NgbActiveModal,
    private contentService: ContentService,
    public progress: NgProgress,
    @Inject(DOCUMENT) private document,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.progress.ref().start();
    this.contentService.getContent(this.contentPk, this.inCampaign).then(
      (content) => {
        this.content = content;
        this.currency = content.currency;
        this.content['author']['isProfiled'] = true;
        this.loaded = false;
        this.progress.ref().complete();
        this.partialContent = undefined;
      },
      (reason) => {
        // If the result has a 404 status that means the influencer is not profiled
        // In this case the PartialContent is cast as Content
        if (reason?.status === 404) {
          this.content = this.partialContent as Content;
          this.content['author']['isProfiled'] = false;
          this.content.takenAt = this.partialContent.takenAtTimestamp
            ? moment(this.partialContent.takenAtTimestamp).fromNow()
            : '';
          this.loaded = true;
          this.progress.ref().complete();
          this.partialContent = undefined;
        } else {
          this.partialContent = undefined;
          this.closeModal();
        }
      },
    );
  }

  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * If unable to load profile image, get downloadable image from fluence api
   */
  checkError(imgUrl: string) {
    if (imgUrl) {
      const url = new URL(imgUrl);
      if (imgUrl && url.origin !== environment.api && this.content && this.content.author.picture) {
        this.content.author.picture = utils.getInstagramCDNUrl(imgUrl);
      }
    }
  }
}
