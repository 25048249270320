export type SubscriptionPlan =
  | 'AFFABLE_FREE'
  | 'AFFABLE_OFFLINE'
  | 'AFFABLE_BASIC_MONTHLY'
  | 'AFFABLE_PRO_MONTHLY'
  | 'AFFABLE_PRO_OFFLINE'
  | 'AFFABLE_ENTERPRISE_MONTHLY'
  | 'AFFABLE_ENTERPRISE_OFFLINE';
export type SubscriptionStatus = 'NONE' | 'SUBSCRIBED' | 'CANCEL_ON_PERIOD_END';
export type CostType = 'Post' | 'Story' | 'Event' | 'Video' | 'Custom';

export type AgeGroup = 0 | 1 | 2 | 3 | 4 | 5;

export type DashboardMetric = 'NUMBER_OF_POSTS' | 'ABSOLUTE_ENGAGEMENT' | 'NUMBER_OF_INFLUENCERS' | 'ESTIMATED_REACH';

export type TimeGranularity = 'AUTO' | 'MONTH' | 'WEEK' | 'DAY';

export enum SubscriptionPlanNames {
  'AFFABLE_FREE' = 'Free Subscription',
  'AFFABLE_OFFLINE' = 'Basic Monthly Subscription',
  'AFFABLE_BASIC_MONTHLY' = 'Basic Monthly Subscription',
  'AFFABLE_PRO_MONTHLY' = 'PRO Monthly Subscription',
  'AFFABLE_PRO_OFFLINE' = 'PRO Monthly Subscription',
  'AFFABLE_ENTERPRISE_MONTHLY' = 'Enterprise Monthly Subscription',
  'AFFABLE_ENTERPRISE_OFFLINE' = 'Enterprise Monthly Subscription',
}
