import { MomentModule } from 'ngx-moment';
import { ResultModalComponent } from './result-modal/result-modal.component';
import { InfluencerSelectControl } from './influencer-select/influencer-select.control';
import { NgModule } from '@angular/core';
import { NbListModule, NbProgressBarModule, NbRadioModule, NbBadgeModule, NbTooltipModule } from '@nebular/theme';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ThemeModule } from '../@theme/theme.module';
import { SimilarInfluencersComponent } from '../pages/profile/similar-influencers/similar-influencers.component';
import { ReplaceUnderscorePipe } from '../pipes/replace-underscore.pipe';
import { BarChartWithInfoComponent } from './bar-chart-with-info/bar-chart-with-info.component';
import { ReportAggregatedCommentAnalysisComponent } from './comment-analysis/report-aggregated-comment-analysis.component';
import { ReportMediaCommentAnalysisComponent } from './comment-analysis/report-media-comment-analysis.component';
import { ContentVideoDetailsComponent } from './content-video-details/content-video-details.component';
import { CreateCampaignModalComponent } from './create-campaign-modal/create-campaign-modal.component';
import { CreateGroupModalComponent } from './group-modals/create-group-modal/create-group-modal.component';
import { EchartsDateStatComponent } from './custom-echarts/date-stat-echart.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { DebounceKeyupDirective } from './directives/keyup-debounce.directive';
import {
  EchartsAreaStackComponent,
  EchartsBarComponent,
  EchartsBubbleMapComponent,
  EchartsHorizontalBarComponent,
  EchartsLineComponent,
  EchartsMultipleXaxisComponent,
  EchartsPieComponent,
  EchartsRadarComponent,
} from './echarts';
import { IgEmbedComponent } from './embed/igembed/igembed.component';
import { FacebookPostDetailsModalComponent } from './facebook-post-details-modal/facebook-post-details-modal.component';
import { FollowersTimelineCardComponent } from './followers-timeline-card/followers-timeline-card.component';
import { GaugeComponent } from './gauge/gauge.component';
import { ImagePreloadDirective } from './image-preload/image-preload.directive';
import { InfoComponent } from './info-with-icon/info.component';
import { InputSliderComponent } from './input-slider/input-slider.component';
import { AddLinkComponent } from './links/add-link/add-link.component';
import { CampaignLinksComponent } from './links/campaign-links/campaign-links.component';
import { DateGroupedLinkComponent } from './links/links-list/date-grouped-link/date-grouped-link.component';
import { LinkComponent } from './links/links-list/date-grouped-link/link/link.component';
import { LinksListComponent } from './links/links-list/links-list.component';
import { LoaderComponent } from './loader/loader.component';
import { LoadingBoxComponent } from './loading-box/loading-box.component';
import { LocationComponent } from './location/location.component';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { AddNoteComponent } from './notes/add-note/add-note.component';
import { InfluencerMetadataComponent } from './notes/influencer-metadata/influencer-metadata.component';
import { NoteComponent } from './notes/note/note.component';
import { NotesSidebarComponent } from './notes/notes-sidebar/notes-sidebar.component';
import { NotesListComponent } from './notes/notes-list/notes-list.component';
import { InstagramThumbnailPipe } from './pipes/instagram-thumbnail-pipe';
import { NumberFormatPipe } from './pipes/number-format-pipe';
import { SafeUrlPipe } from './pipes/safe-url-pipe';
import { PostsCardComponent } from './posts-card/posts-card.component';
import { ProfileCampaignEmailModalComponent } from './profile-campaign-email-modal/profile-campaign-email-modal.component';
import { ShareGroupModalComponent } from './group-modals/share-group-modal/share-group-modal.component';
import { SocialProfilesComponent } from './social-profiles/social-profiles.component';
import { SortableHeaderDirective } from './sortable/sortable.directive';
import { SpinnerComponent } from './spinner/spinner.component';
import { StatCardComponent } from './stat-card/stat-card.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TweetDetailsModalComponent } from './tweet-details-modal/tweet-details-modal.component';
import { MetaShippingDetailsComponent } from './notes/shipping-details/meta-shipping-details.component';
import { TweetsCardComponent } from './tweets-card/tweets-card.component';
import { UnshareGroupModalComponent } from './group-modals/unshare-group-modal/unshare-group-modal.component';
import { NgxPopperModule } from 'ngx-popper';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { TrimDirective } from './directives/trim.directive';
import { SaveFilterModalComponent } from './save-filter-modal/save-filter-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadFilterModalComponent } from './load-filter-modal/load-filter-modal.component';
import { SaveInfluencersSearchFilterComponent } from './save-influencer-search-filters/save-influencer-search-filters.component';
import { BrandedContentDistributionCardComponent } from '../pages/profile/branded-content-distribution-card/branded-content-distribution-card.component';
import { BRANDED_CONTENT_SERVICE } from '../enums';
import { InfluencerService } from '../@core/data/influencer.service';
import { PdfExportModalComponent } from './pdf-export-modal/pdf-export-modal.component';
import { EmailIntroductionModalComponent } from '../pages/campaigns/email-introduction/email-introduction.component';
import { AgeRangeFilterComponent } from './influencers-discovery-filters/age-range-filter/age-range-filter.component';
import { GenderFilterComponent } from './influencers-discovery-filters/gender-filter/gender-filter.component';
import { CountriesFilterComponent } from './influencers-discovery-filters/countries-filter/countries-filter.component';
import { LocationsFilterComponent } from './influencers-discovery-filters/locations-filter/locations-filter.component';
import { FollowersFilterComponent } from './influencers-discovery-filters/followers-filter/followers-filter.component';
import { FollowersGrowthFilterComponent } from './influencers-discovery-filters/followers-growth-filter/followers-growth-filter.component';
import { SortFilterComponent } from './influencers-discovery-filters/sort-filter/sort-filter.component';
import { AddToExistingGroupModalComponent } from './group-modals/add-to-existing-group-modal/add-to-existing-group-modal.component';
import { AddToExistingCampaignModalComponent } from './add-to-existing-campaign-modal/add-to-existing-campaign-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TruncatePipe } from './pipes/truncate.pipe';
import { EditGroupModalComponent } from './group-modals/edit-group-modal/edit-group-modal.component';
import { HashtagCaptionFilterComponent } from './influencers-discovery-filters/hashtag-caption-filter/hashtag-caption-filter.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { UpdateInfluencerComponent } from './discovery-admin-controls/update-influencer/update-influencer.component';
import { MarkSelectedComponent } from './discovery-admin-controls/mark-selected/mark-selected.component';
import { ReminderBannerComponent } from './reminder-banner/reminder-banner.component';
import { ShowAllInfluencerModalComponent } from './reminder-banner/show-all-influencers-modal/show-all-influencer-modal.component';
import { CallbackPipe } from '../pipes/callback.pipe';
import { TableComponent } from './table/table.component';
import { CommonModule } from '@angular/common';
import { ProfileBasicInfoCardComponent } from './profile-basic-info-card/profile-basic-info-card.component';
import { InfluencerProfileCardComponent } from './influencer-profile-card/influencer-profile-card.component';
import { AiOverlayContainer, ControlsModule } from '../../../../controls/src';
import { InfluencerCampaignListComponent } from './influencer-campaign-list/influencer-campaign-list.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { LanguageFilterComponent } from './influencers-discovery-filters/language-filter/language-filter.component';
import { ParseUrl } from '../pipes/parse-url.pipe';
import { HighlightVariablePipe } from './pipes/highlight-variable.pipe';
import { DebounceClickDirective } from './directives/click-debounce.directive';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { DiscoveryHeaderComponent } from './discovery-header/discovery-header.component';
import { InfluencerDiscoveryCardComponent } from './influencer-discovery-card/influencer-discovery-card.component';
import { UserTileComponent } from './user-tile/user-tile.component';
import { BadgeComponent } from './badge/badge.component';
import { SumPipe } from '../pipes/sum.pipe';
import { CapitalizeFirstLetterPipe } from '../pipes/capitalizeFirstLetter.pipe';
import { TooltipSimpleComponent } from './tooltipSimple/tooltipSimple.component';
import { ExistingEmailSequenceModal } from '../pages/campaigns/campaign/email-sequences/existing-email-sequence/existing-email-sequence.modal';
import { UniqueFollowersComponent } from './unique-followers/unique.followers.component.ts/unique.followers.component.ts.component';
import { UserBadgeComponent } from './user-badge/user-badge.component';
import { LockedCardComponent } from './locked-card/locked-card.component';
import { TooltipBasicComponent } from './tooltip-basic/tooltip-basic.component';
import { InfluencerRegistrationInviteComponent } from './influencer-registration-invite/influencer-registration-invite.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { DateRangeV2Component } from './date-range/date-range-v2/date-range-v2.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ConnectAccountModalComponent } from './connect-account-modal/connect-account-modal.component';
import { AddToComponent } from './add-to/add-to.component';
import { InfluencerGroupComponent } from './influencer-group/influencer-group.component';
import { InfluencerGroupTabComponent } from './influencer-group/influencer-group-tab/influencer-group-tab.component';
import { SearchFilterPipe } from '../pipes/search.pipe';
import { EmptyWidgetGroupComponent } from './influencer-group/empty-widget-group/empty-widget-group.component';
import { EmailValidatorDirective } from './directives/email-validator.directive';
import { GroupStatsComponent } from './influencer-group/group-stats/group-stats.component';
import { InfluencerGroupHelperService } from './influencer-group/influencer-group.helper.service';
import { ListViewCardModalComponent } from './list-view-card-modal/list-view-card-modal.component';
import { EmptyWidgetComponent } from '../pages/console/empty-widget/empty-widget.component';
import { AudienceGenderDistributionCardComponent } from './audience-gender-distribution-card/audience-gender-distribution-card.component';
import { AudienceAgeDistributionCardComponent } from './audience-age-distribution-card/audience-age-distribution-card.component';
import { ProfileEngagementComponent } from './profile-engagement/profile-engagement.component';
import { ProfileBrandedContentDistributionComponent } from './profile-branded-content-distribution/profile-branded-content-distribution.component';
import { AudienceLocationDistributionCardComponent } from './audience-location-distribution-card/audience-location-distribution-card.component';
import { ProfileBrandAffinityComponent } from './profile-brand-affinity/profile-brand-affinity.component';
import { BrandUserComponent } from './brand-user/brand-user.component';

const ECHART_COMPONENTS = [
  EchartsAreaStackComponent,
  EchartsBarComponent,
  EchartsLineComponent,
  EchartsMultipleXaxisComponent,
  EchartsPieComponent,
  EchartsRadarComponent,
  EchartsBubbleMapComponent,
  EchartsHorizontalBarComponent,
];

const CUSTOM_ECHART_COMPONENTS = [EchartsDateStatComponent];

const NOTES_COMPONENTS = [
  NotesListComponent,
  NoteComponent,
  InfoComponent,
  AddNoteComponent,
  InfluencerMetadataComponent,
  NotesSidebarComponent,
  MetaShippingDetailsComponent,
];

const LINKS_COMPONENTS = [
  LinksListComponent,
  AddLinkComponent,
  LinkComponent,
  DateGroupedLinkComponent,
  CampaignLinksComponent,
];

const INFLUENCER_DISCOVERY_COMPONENTS = [
  AgeRangeFilterComponent,
  GenderFilterComponent,
  InfluencerGroupComponent,
  CountriesFilterComponent,
  LocationsFilterComponent,
  FollowersFilterComponent,
  FollowersGrowthFilterComponent,
  SortFilterComponent,
  HashtagCaptionFilterComponent,
  MarkSelectedComponent,
  UpdateInfluencerComponent,
  LanguageFilterComponent,
  EmptyWidgetGroupComponent,
  GroupStatsComponent,
];

const SHARED_COMPONENTS = [
  MessageModalComponent,
  ExistingEmailSequenceModal,
  ConfirmModalComponent,
  ResultModalComponent,
  ShareGroupModalComponent,
  EditGroupModalComponent,
  EmailIntroductionModalComponent,
  UnshareGroupModalComponent,
  CreateGroupModalComponent,
  DiscoveryHeaderComponent,
  LocationComponent,
  SpinnerComponent,
  LoaderComponent,
  LoadingBoxComponent,
  GaugeComponent,
  InfoComponent,
  TooltipComponent,
  TooltipSimpleComponent,
  TooltipBasicComponent,
  MediaGalleryComponent,
  InputSliderComponent,
  BarChartWithInfoComponent,
  NumberFormatPipe,
  SearchFilterPipe,
  NoDataFoundComponent,
  PostsCardComponent,
  ProfileCampaignEmailModalComponent,
  ReminderBannerComponent,
  ShowAllInfluencerModalComponent,
  FacebookPostDetailsModalComponent,
  DateRangeComponent,
  ContentVideoDetailsComponent,
  ToggleSwitchComponent,
  FollowersTimelineCardComponent,
  CreateCampaignModalComponent,
  SimilarInfluencersComponent,
  InstagramThumbnailPipe,
  SocialProfilesComponent,
  TweetsCardComponent,
  TweetDetailsModalComponent,
  InfluencerDiscoveryCardComponent,
  UserTileComponent,
  IgEmbedComponent,
  SafeUrlPipe,
  HighlightVariablePipe,
  TruncatePipe,
  ParseUrl,
  TableHeaderComponent,
  StatCardComponent,
  ReportAggregatedCommentAnalysisComponent,
  ReportMediaCommentAnalysisComponent,
  SaveFilterModalComponent,
  LoadFilterModalComponent,
  SaveInfluencersSearchFilterComponent,
  BrandedContentDistributionCardComponent,
  PdfExportModalComponent,
  AddToExistingGroupModalComponent,
  AddToExistingCampaignModalComponent,
  TableComponent,
  ProfileBasicInfoCardComponent,
  InfluencerProfileCardComponent,
  InfluencerCampaignListComponent,
  BadgeComponent,
  UniqueFollowersComponent,
  DateRangeV2Component,
  InfluencerSelectControl,
  UserBadgeComponent,
  LockedCardComponent,
  InfluencerRegistrationInviteComponent,
  AddToComponent,
  InfluencerGroupTabComponent,
  EmptyWidgetComponent,
  AudienceGenderDistributionCardComponent,
  AudienceAgeDistributionCardComponent,
  ProfileEngagementComponent,
  ProfileBrandedContentDistributionComponent,
  AudienceLocationDistributionCardComponent,
  ProfileBrandAffinityComponent,
  BrandUserComponent,
  ...INFLUENCER_DISCOVERY_COMPONENTS,
];

const SHARED_PIPES = [ReplaceUnderscorePipe, CallbackPipe, ShortNumberPipe, SumPipe, CapitalizeFirstLetterPipe];

const SHARED_DIRECTIVES = [
  SortableHeaderDirective,
  ImagePreloadDirective,
  CopyClipboardDirective,
  DebounceKeyupDirective,
  DebounceClickDirective,
  AutofocusDirective,
  TrimDirective,
  ClickOutsideDirective,
  EmailValidatorDirective,
];

const SHARED_MODULES = [ControlsModule, NgxEchartsModule, NgSelectModule, NgOptionHighlightModule, NgxFilesizeModule];

@NgModule({
  imports: [
    ...SHARED_MODULES,
    MomentModule,
    ThemeModule,
    NbListModule,
    NbProgressBarModule,
    AngularMyDatePickerModule,
    CollapseModule.forRoot(),
    NgxIntlTelInputModule,
    LazyLoadImageModule,
    InfiniteScrollModule,
    NgxPopperModule.forRoot({
      disableAnimation: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NbRadioModule,
    NbBadgeModule,
    OverlayModule,
    NbTooltipModule,
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    ...ECHART_COMPONENTS,
    ...CUSTOM_ECHART_COMPONENTS,
    ...NOTES_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...LINKS_COMPONENTS,
    ...SHARED_PIPES,
    TableHeaderComponent,
    ConnectAccountModalComponent,
    ListViewCardModalComponent,
  ],
  entryComponents: [
    MessageModalComponent,
    ExistingEmailSequenceModal,
    ConfirmModalComponent,
    ResultModalComponent,
    CreateGroupModalComponent,
    FacebookPostDetailsModalComponent,
    ContentVideoDetailsComponent,
    CreateCampaignModalComponent,
    TweetDetailsModalComponent,
    ShareGroupModalComponent,
    UnshareGroupModalComponent,
    SaveFilterModalComponent,
    LoadFilterModalComponent,
    PdfExportModalComponent,
    AddToExistingGroupModalComponent,
    AddToExistingCampaignModalComponent,
    EditGroupModalComponent,
    AddToComponent,
  ],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...ECHART_COMPONENTS,
    ...CUSTOM_ECHART_COMPONENTS,
    ...NOTES_COMPONENTS,
    ...LINKS_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
  ],
  providers: [
    NumberFormatPipe,
    InstagramThumbnailPipe,
    InfluencerGroupHelperService,
    { provide: BRANDED_CONTENT_SERVICE, useExisting: InfluencerService },
    { provide: OverlayContainer, useClass: AiOverlayContainer },
  ],
})
export class SharedModule {}
