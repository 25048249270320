<div class="save-filters">
  <label class="filter-label">Filters</label>
  <div class="action-btns">
    <button (click)="openSaveFilterModal()">Save</button>
    <button [disabled]="savedSearchFilters?.length === 0" (click)="openLoadFilterModal()">Load</button>
    <button class="reset" data-cy="reset-button-filter" *ngIf="resetButtonVisible" (click)="sendRestFiltersEvent()">Reset</button>
  </div>
</div>
<div class="saved-filter-name" *ngIf="filterNameVisible">
  <span class="name">{{selectedSearchFilter.filterName}}</span>
  <span class="close" (click)="loadFilter(null)">&times;</span>
</div>