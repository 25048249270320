<div [nbSpinner]="loading" nbSpinnerSize="small">
  <div class="modal-header">
    <span>Share Group</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="title">Share With</label>
          <ngx-tooltip *ngIf="!featureEnabledWithFlag" [tooltip]="'Share Groups in your organization'">
          </ngx-tooltip>
          <ng-select *ngIf="featureEnabledWithFlag" dropdownPosition="bottom" bindLabel="name" placeholder="Share with"
            (change)="onChangeShareWithIn($event)" [items]="shareWithOptions" [hideSelected]="true" [searchable]="false"
            [clearable]="false" [(ngModel)]="shareWithIn" [loading]="loadingorganizationMembers">
            <ng-template ng-footer-tmp *ngIf="!alreadySharedWithCollaborator">
              <div class="add-collab-group">
                <ai-button type="text" label="Add Collaboration Group" (click)="navigateToCollaborationTeamsSetting()"
                  prefix="far fa-plus"></ai-button>
              </div>
            </ng-template>
          </ng-select>
  
          <div *ngIf="featureEnabledWithFlag" class="divider"></div>
          <ng-select placeholder="Share with" notFoundText="Client's organization does not have any other member"
            [addTag]=false [items]="shareWithIn?.members" [multiple]="true" [hideSelected]="true" [virtualScroll]="false"
            [loading]="loadingorganizationMembers" [markFirst]="false" [(ngModel)]="membersToShareWithEmails">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items">
                <span class="ng-value-label"><b> {{item}}</b></span>
                <span class="ng-value-icon right" (click)="clear(item);cachedRemoveMember(item)"
                  aria-hidden="true">×</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <span [ngOptionHighlight]="search" class="option-label">{{item}} </span>
            </ng-template>
          </ng-select>
          <ai-button type="text" size="small" *ngIf="isCollaborationTeamSelected" label="Add New Email" class="ai-mt6"
            (click)="navigateToCollaborationTeamsSetting('edit')"></ai-button>
          <div class="ai-mt6 af-body-4">
            <strong class="af-body-3">Note:&nbsp;</strong>
            {{ isCollaborationTeamSelected ? 'Adding new members above will trigger an email granting them
            access to the group. Removing members will revoke their access.' : 'Adding new members above will
            grant them access to the group. Removing members will revoke their access.' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal-footer footer-style">
    <ai-button type="text" *ngIf="featureEnabledWithFlag && collaborationTeams?.length === 0"
      label="Add Collaboration Group" (click)="navigateToCollaborationTeamsSetting()" prefix="far fa-plus"></ai-button>
    <div class="action-buttons">
      <button class="secondary-text" *ngIf="!featureEnabledWithFlag" (click)="closeModal()">CANCEL</button>
      <ai-button class="ai-mr6" type="text" *ngIf="isCollaborationTeamSelected" [disabled]="isSharingDisabled"
        label="Copy Invite Link" (click)="copyInviteLink()"></ai-button>
      <button class="primary" (click)="shareGroup()" [disabled]="isSharingDisabled">{{isCollaborationTeamSelected ?
        'UPDATE SHARING ACCESS' :
        'UPDATE GROUP ACCESS'}} </button>
    </div>
  </div>
</div>