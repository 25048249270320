import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by"
      >Powered by <b><a href="https://www.affable.ai" target="_blank">Affable AI</a></b></span
    >
  `,
})
export class FooterComponent {}
