<div class="email-sequences-container">
  <div class="actions-section">
    <form>
      <div class="search-input">
        <input type="text" name="searchTerm" placeholder="Search Sequence" [(ngModel)]="searchTerm"
          (onEvent)="searchEmailSequences()" keyupDebounce trim />
        <i class="far" [ngClass]="{'fa-search': !searchTerm, 'fa-times cursor-pointer': !!searchTerm}"
          (click)="!!searchTerm && searchEmailSequences(true)"></i>
      </div>
    </form>

    <div class="actions">
      <!-- <ai-button label="CREATE NEW SEQUENCE" length="block" color="primary"
        (click)="createEmailSequence(); closeModal();">
      </ai-button>  TODO: Enable this flow after optimizing the influencer search -->
      <!-- <div class="v-divider"></div> -->



      <ngx-sort-filter [label]="'Filter By:'" [sortKey]="filterKey" [sortOptions]="filterOptions" [disabled]="loading"
        (sortFilterChangedEvent)="filterEmailSequences($event)">
      </ngx-sort-filter>
    </div>
  </div>

  <div class="email-sequences-table">
    <div *ngIf="!emailSequences.length && !loading" class="sequences-empty-container">
      <span class="sequences-empty-title">Email Sequences you create will appear here.</span>
      <img src="../../../../assets/images/email-sequences-empty.svg" alt="" class="illustration">
      <h2 class="sequences-empty-h2">Bulk-send a series of scheduled automated emails with Email Sequences.<br>Click on
        Create New Sequence to get started.</h2>
    </div>

    <ngx-table #emailSequencesTable (loadFullTableDataEvent)="loadFullTableDataEvent()" [showCheckbox]="false"
      [data]="emailSequences" [hasMoreData]="hasMoreData" [loading]="loading" [paginationLoader]="paginationLoader"
      infiniteScrollContainer=".email-sequences-table" (loadNextPage)="loadNextPage()"
      *ngIf="(!!emailSequences.length && !loading) || loading">
      <ng-template #headers>
        <th class="status">Status</th>
        <th>Sequence</th>
        <th>Start date</th>
        <th>Influencers</th>
        <th *ngIf="!isEmailIntegrated">Delivered</th>
        <th>Unique opens</th>
        <th>Unique replies</th>
        <!-- <th>Unique clicks</th> -->
        <th class="actions">Actions</th>
      </ng-template>
      <ng-template #rows let-row>
        <td class="status" data-label="Status">
          <div class="status-switch">
            <ngx-loader *ngIf="row.changingStatus" class="inline text-sm"></ngx-loader>
            <ai-toggle-switch *ngIf="!row.changingStatus"
              [checked]="row.isEnabled" [key]="row._id" (toggleSwitchChangedEvent)="changeStatus($event)">
            </ai-toggle-switch>
          </div>
        </td>
        <td class="sequence" data-label="Sequence">
          {{(row.name.length > 50) ? (row.name | slice:0:50)+'...' : (row.name)}}
        </td>
        <td class="start-date" data-label="Start date">
          <div>
            {{row.isEnabled ? (row.eventTimestamp.enabledAt | date:'MMM d, yyyy') : '-'}}
          </div>
        </td>
        <td class="influencers" data-label="Influencers">
          <div>
            {{row.influencersCount}}
          </div>
        </td>
        <td *ngIf="!isEmailIntegrated" class="delivered" data-label="Delivered">
          <div>
            {{row.delivered}}
          </div>
        </td>
        <td class="unique-opens" data-label="Unique opens">
          <div>
            {{row.uniqueOpens}}
          </div>
        </td>
        <td class="unique-replies" data-label="Unique replies">
          <div>
            {{row.uniqueReplies}}
          </div>
        </td>
        <!-- <td class="unique-clicks" data-label="Unique clicks">
            <div>
              {{row.uniqueClicks}}
            </div>
          </td> -->
        <td class="actions" data-label="Actions">
          <div class="inline-tooltip">
            <div placement="left">
              <button (click)="editEmailSequence(row._id)" title="Edit">
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
            </div>
            <!-- <div placement="right">
              <button (click)="removeEmailSequence()" title="Remove">
                <i class="fa-regular fa-trash-can-xmark"></i>
              </button>
            </div> -->
          </div>
        </td>
      </ng-template>
    </ngx-table>
  </div>
</div>
