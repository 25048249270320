import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class InstagramProfilingJobService {
  constructor(private http: HttpClient) {}

  async profileListOfInfluencers(
    usernames: string[],
    groupName?: string | undefined,
    groupSlugName?: string | undefined,
  ): Promise<string[]> {
    return this.http
      .post(`${environment.api}/api/instagram/influencers/profiling-job`, {
        usernames: usernames,
        groupName: groupName,
        groupSlugName: groupSlugName,
      })
      .toPromise()
      .then((response: any) => response.profiledUsernames);
  }

  async profileInfluencer(username: string): Promise<boolean> {
    return this.http
      .post(`${environment.api}/api/instagram/influencers/profiling-job`, {
        usernames: [username],
      })
      .toPromise()
      .then((response: any) => response.idsToProfile?.length > 0);
  }
}
