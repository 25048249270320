export const COUNTRY_DIAL_CODES = {
  '+1': 'US',
  '+1242': 'BS',
  '+1246': 'BB',
  '+1264': 'AI',
  '+1268': 'AG',
  '+1284': 'VG',
  '+1340': 'VI',
  '+1441': 'BM',
  '+1473': 'GD',
  '+1649': 'TC',
  '+1664': 'MS',
  '+1670': 'MP',
  '+1671': 'GU',
  '+1684': 'AS',
  '+1758': 'LC',
  '+1767': 'DM',
  '+1784': 'VC',
  '+1849': 'DO',
  '+1868': 'TT',
  '+1869': 'KN',
  '+1876': 'JM',
  '+1939': 'PR',
  '+20': 'EG',
  '+211': 'SS',
  '+212': 'MA',
  '+213': 'DZ',
  '+216': 'TN',
  '+218': 'LY',
  '+220': 'GM',
  '+221': 'SN',
  '+222': 'MR',
  '+223': 'ML',
  '+224': 'GN',
  '+225': 'CI',
  '+226': 'BF',
  '+227': 'NE',
  '+228': 'TG',
  '+229': 'BJ',
  '+230': 'MU',
  '+231': 'LR',
  '+232': 'SL',
  '+233': 'GH',
  '+234': 'NG',
  '+235': 'TD',
  '+236': 'CF',
  '+237': 'CM',
  '+238': 'CV',
  '+239': 'ST',
  '+240': 'GQ',
  '+241': 'GA',
  '+242': 'CG',
  '+243': 'CD',
  '+244': 'AO',
  '+245': 'GW',
  '+246': 'IO',
  '+248': 'SC',
  '+249': 'SD',
  '+250': 'RW',
  '+251': 'ET',
  '+252': 'SO',
  '+253': 'DJ',
  '+254': 'KE',
  '+255': 'TZ',
  '+256': 'UG',
  '+257': 'BI',
  '+258': 'MZ',
  '+260': 'ZM',
  '+261': 'MG',
  '+262': 'RE',
  '+263': 'ZW',
  '+264': 'NA',
  '+265': 'MW',
  '+266': 'LS',
  '+267': 'BW',
  '+268': 'SZ',
  '+269': 'KM',
  '+27': 'ZA',
  '+290': 'SH',
  '+291': 'ER',
  '+297': 'AW',
  '+298': 'FO',
  '+299': 'GL',
  '+30': 'GR',
  '+31': 'NL',
  '+32': 'BE',
  '+33': 'FR',
  '+34': 'ES',
  '+345': 'KY',
  '+350': 'GI',
  '+351': 'PT',
  '+352': 'LU',
  '+353': 'IE',
  '+354': 'IS',
  '+355': 'AL',
  '+356': 'MT',
  '+357': 'CY',
  '+358': 'FI',
  '+359': 'BG',
  '+36': 'HU',
  '+370': 'LT',
  '+371': 'LV',
  '+372': 'EE',
  '+373': 'MD',
  '+374': 'AM',
  '+375': 'BY',
  '+376': 'AD',
  '+377': 'MC',
  '+378': 'SM',
  '+379': 'VA',
  '+380': 'UA',
  '+381': 'RS',
  '+382': 'ME',
  '+385': 'HR',
  '+386': 'SI',
  '+387': 'BA',
  '+389': 'MK',
  '+39': 'IT',
  '+40': 'RO',
  '+41': 'CH',
  '+420': 'CZ',
  '+421': 'SK',
  '+423': 'LI',
  '+43': 'AT',
  '+44': 'GB',
  '+45': 'DK',
  '+46': 'SE',
  '+47': 'SJ',
  '+48': 'PL',
  '+49': 'DE',
  '+500': 'GS',
  '+501': 'BZ',
  '+502': 'GT',
  '+503': 'SV',
  '+504': 'HN',
  '+505': 'NI',
  '+506': 'CR',
  '+507': 'PA',
  '+508': 'PM',
  '+509': 'HT',
  '+51': 'PE',
  '+52': 'MX',
  '+53': 'CU',
  '+54': 'AR',
  '+55': 'BR',
  '+56': 'CL',
  '+57': 'CO',
  '+58': 'VE',
  '+590': 'MF',
  '+591': 'BO',
  '+593': 'EC',
  '+594': 'GF',
  '+595': 'PY',
  '+596': 'MQ',
  '+597': 'SR',
  '+598': 'UY',
  '+599': 'AN',
  '+60': 'MY',
  '+61': 'CC',
  '+62': 'ID',
  '+63': 'PH',
  '+64': 'NZ',
  '+65': 'SG',
  '+66': 'TH',
  '+670': 'TL',
  '+672': 'NF',
  '+673': 'BN',
  '+674': 'NR',
  '+675': 'PG',
  '+676': 'TO',
  '+677': 'SB',
  '+678': 'VU',
  '+679': 'FJ',
  '+680': 'PW',
  '+681': 'WF',
  '+682': 'CK',
  '+683': 'NU',
  '+685': 'WS',
  '+686': 'KI',
  '+687': 'NC',
  '+688': 'TV',
  '+689': 'PF',
  '+690': 'TK',
  '+691': 'FM',
  '+692': 'MH',
  '+7': 'RU',
  '+77': 'KZ',
  '+81': 'JP',
  '+82': 'KR',
  '+84': 'VN',
  '+850': 'KP',
  '+852': 'HK',
  '+853': 'MO',
  '+855': 'KH',
  '+856': 'LA',
  '+86': 'CN',
  '+872': 'PN',
  '+880': 'BD',
  '+886': 'TW',
  '+90': 'TR',
  '+91': 'IN',
  '+92': 'PK',
  '+93': 'AF',
  '+94': 'LK',
  '+95': 'MM',
  '+960': 'MV',
  '+961': 'LB',
  '+962': 'JO',
  '+963': 'SY',
  '+964': 'IQ',
  '+965': 'KW',
  '+966': 'SA',
  '+967': 'YE',
  '+968': 'OM',
  '+970': 'PS',
  '+971': 'AE',
  '+972': 'IL',
  '+973': 'BH',
  '+974': 'QA',
  '+975': 'BT',
  '+976': 'MN',
  '+977': 'NP',
  '+98': 'IR',
  '+992': 'TJ',
  '+993': 'TM',
  '+994': 'AZ',
  '+995': 'GE',
  '+996': 'KG',
  '+998': 'UZ',
};

export enum Days {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export enum TerminationConditions {
  'REPLY_RECEIVED' = 'Influencer has responded',
  'NEVER' = 'Never',
}

export enum WorkflowBasedTerminationConditions {
  'PROPOSAL_SUBMITTED' = 'Proposal is received',
}

export enum DayDeliveryOptions {
  'ANY' = 'ANY',
  'WEEKDAYS' = 'WEEKDAYS',
  'WEEKENDS' = 'WEEKENDS',
}

export const OPEN_ADD_CUSTOM_PRODUCT_MODAL = 'OPEN_ADD_CUSTOM_PRODUCT_MODAL';

export const TIMEZONES = [
  {
    title: 'GMT',
    key: 'Africa/Abidjan',
  },
  {
    title: 'GMT',
    key: 'Africa/Accra',
  },
  {
    title: 'EAT',
    key: 'Africa/Addis_Ababa',
  },
  {
    title: 'CET',
    key: 'Africa/Algiers',
  },
  {
    title: 'EAT',
    key: 'Africa/Asmara',
  },
  {
    title: 'GMT',
    key: 'Africa/Bamako',
  },
  {
    title: 'WAT',
    key: 'Africa/Bangui',
  },
  {
    title: 'GMT',
    key: 'Africa/Banjul',
  },
  {
    title: 'GMT',
    key: 'Africa/Bissau',
  },
  {
    title: 'CAT',
    key: 'Africa/Blantyre',
  },
  {
    title: 'WAT',
    key: 'Africa/Brazzaville',
  },
  {
    title: 'CAT',
    key: 'Africa/Bujumbura',
  },
  {
    title: 'EET',
    key: 'Africa/Cairo',
  },
  {
    title: 'WEST',
    key: 'Africa/Casablanca',
  },
  {
    title: 'CET',
    key: 'Africa/Ceuta',
  },
  {
    title: 'GMT',
    key: 'Africa/Conakry',
  },
  {
    title: 'GMT',
    key: 'Africa/Dakar',
  },
  {
    title: 'EAT',
    key: 'Africa/Dar_es_Salaam',
  },
  {
    title: 'EAT',
    key: 'Africa/Djibouti',
  },
  {
    title: 'WAT',
    key: 'Africa/Douala',
  },
  {
    title: 'WEST',
    key: 'Africa/El_Aaiun',
  },
  {
    title: 'GMT',
    key: 'Africa/Freetown',
  },
  {
    title: 'CAT',
    key: 'Africa/Gaborone',
  },
  {
    title: 'CAT',
    key: 'Africa/Harare',
  },
  {
    title: 'SAST',
    key: 'Africa/Johannesburg',
  },
  {
    title: 'EAT',
    key: 'Africa/Juba',
  },
  {
    title: 'EAT',
    key: 'Africa/Kampala',
  },
  {
    title: 'EAT',
    key: 'Africa/Khartoum',
  },
  {
    title: 'WAT',
    key: 'Africa/Kinshasa',
  },
  {
    title: 'WAT',
    key: 'Africa/Lagos',
  },
  {
    title: 'WAT',
    key: 'Africa/Libreville',
  },
  {
    title: 'GMT',
    key: 'Africa/Lome',
  },
  {
    title: 'WAT',
    key: 'Africa/Luanda',
  },
  {
    title: 'CAT',
    key: 'Africa/Lubumbashi',
  },
  {
    title: 'CAT',
    key: 'Africa/Lusaka',
  },
  {
    title: 'WAT',
    key: 'Africa/Malabo',
  },
  {
    title: 'CAT',
    key: 'Africa/Maputo',
  },
  {
    title: 'SAST',
    key: 'Africa/Maseru',
  },
  {
    title: 'SAST',
    key: 'Africa/Mbabane',
  },
  {
    title: 'EAT',
    key: 'Africa/Mogadishu',
  },
  {
    title: 'GMT',
    key: 'Africa/Monrovia',
  },
  {
    title: 'EAT',
    key: 'Africa/Nairobi',
  },
  {
    title: 'WAT',
    key: 'Africa/Ndjamena',
  },
  {
    title: 'WAT',
    key: 'Africa/Niamey',
  },
  {
    title: 'GMT',
    key: 'Africa/Nouakchott',
  },
  {
    title: 'GMT',
    key: 'Africa/Ouagadougou',
  },
  {
    title: 'WAT',
    key: 'Africa/Porto-Novo',
  },
  {
    title: 'GMT',
    key: 'Africa/Sao_Tome',
  },
  {
    title: 'EET',
    key: 'Africa/Tripoli',
  },
  {
    title: 'CET',
    key: 'Africa/Tunis',
  },
  {
    title: 'WAT',
    key: 'Africa/Windhoek',
  },
  {
    key: 'America/Adak',
    title: 'HST',
  },
  {
    key: 'America/Anchorage',
    title: 'AKST',
  },
  {
    key: 'America/Anguilla',
    title: 'AST',
  },
  {
    key: 'America/Antigua',
    title: 'AST',
  },
  {
    key: 'America/Araguaina',
    title: 'BRT',
  },
  {
    key: 'America/Argentina/Buenos_Aires',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Catamarca',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Cordoba',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Jujuy',
    title: 'ART',
  },
  {
    key: 'America/Argentina/La_Rioja',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Mendoza',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Rio_Gallegos',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Salta',
    title: 'ART',
  },
  {
    key: 'America/Argentina/San_Juan',
    title: 'ART',
  },
  {
    key: 'America/Argentina/San_Luis',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Tucuman',
    title: 'ART',
  },
  {
    key: 'America/Argentina/Ushuaia',
    title: 'ART',
  },
  {
    key: 'America/Aruba',
    title: 'AST',
  },
  {
    key: 'America/Asuncion',
    title: 'PYT',
  },
  {
    key: 'America/Atikokan',
    title: 'EST',
  },
  {
    key: 'America/Bahia',
    title: 'BRT',
  },
  {
    key: 'America/Bahia_Banderas',
    title: 'CST',
  },
  {
    key: 'America/Barbados',
    title: 'AST',
  },
  {
    key: 'America/Belem',
    title: 'BRT',
  },
  {
    key: 'America/Belize',
    title: 'CST',
  },
  {
    key: 'America/Blanc-Sablon',
    title: 'AST',
  },
  {
    key: 'America/Boa_Vista',
    title: 'AMT',
  },
  {
    key: 'America/Bogota',
    title: 'COT',
  },
  {
    key: 'America/Boise',
    title: 'MST',
  },
  {
    key: 'America/Cambridge_Bay',
    title: 'MST',
  },
  {
    key: 'America/Campo_Grande',
    title: 'AMT',
  },
  {
    key: 'America/Cancun',
    title: 'EST',
  },
  {
    key: 'America/Caracas',
    title: 'VET',
  },
  {
    key: 'America/Cayenne',
    title: 'GFT',
  },
  {
    key: 'America/Cayman',
    title: 'EST',
  },
  {
    key: 'America/Chicago',
    title: 'CST',
  },
  {
    key: 'America/Chihuahua',
    title: 'MST',
  },
  {
    key: 'America/Costa_Rica',
    title: 'CST',
  },
  {
    key: 'America/Creston',
    title: 'MST',
  },
  {
    key: 'America/Cuiaba',
    title: 'AMT',
  },
  {
    key: 'America/Curacao',
    title: 'AST',
  },
  {
    key: 'America/Danmarkshavn',
    title: 'GMT',
  },
  {
    key: 'America/Dawson',
    title: 'PST',
  },
  {
    key: 'America/Dawson_Creek',
    title: 'MST',
  },
  {
    key: 'America/Denver',
    title: 'MST',
  },
  {
    key: 'America/Detroit',
    title: 'EST',
  },
  {
    key: 'America/Dominica',
    title: 'AST',
  },
  {
    key: 'America/Edmonton',
    title: 'MST',
  },
  {
    key: 'America/Eirunepe',
    title: 'AMT',
  },
  {
    key: 'America/El_Salvador',
    title: 'CST',
  },
  {
    key: 'America/Fort_Nelson',
    title: 'MST',
  },
  {
    key: 'America/Fortaleza',
    title: 'BRT',
  },
  {
    key: 'America/Glace_Bay',
    title: 'AST',
  },
  {
    key: 'America/Godthab',
    title: 'WGT',
  },
  {
    key: 'America/Goose_Bay',
    title: 'AST',
  },
  {
    key: 'America/Grand_Turk',
    title: 'EST',
  },
  {
    key: 'America/Grenada',
    title: 'AST',
  },
  {
    key: 'America/Guadeloupe',
    title: 'AST',
  },
  {
    key: 'America/Guatemala',
    title: 'CST',
  },
  {
    key: 'America/Guayaquil',
    title: 'ECT',
  },
  {
    key: 'America/Guyana',
    title: 'GYT',
  },
  {
    key: 'America/Halifax',
    title: 'AST',
  },
  {
    key: 'America/Havana',
    title: 'CST',
  },
  {
    key: 'America/Hermosillo',
    title: 'MST',
  },
  {
    key: 'America/Indiana/Indianapolis',
    title: 'EST',
  },
  {
    key: 'America/Indiana/Knox',
    title: 'CST',
  },
  {
    key: 'America/Indiana/Marengo',
    title: 'EST',
  },
  {
    key: 'America/Indiana/Petersburg',
    title: 'EST',
  },
  {
    key: 'America/Indiana/Tell_City',
    title: 'CST',
  },
  {
    key: 'America/Indiana/Vevay',
    title: 'EST',
  },
  {
    key: 'America/Indiana/Vincennes',
    title: 'EST',
  },
  {
    key: 'America/Indiana/Winamac',
    title: 'EST',
  },
  {
    key: 'America/Inuvik',
    title: 'MST',
  },
  {
    key: 'America/Iqaluit',
    title: 'EST',
  },
  {
    key: 'America/Jamaica',
    title: 'EST',
  },
  {
    key: 'America/Juneau',
    title: 'AKST',
  },
  {
    key: 'America/Kentucky/Louisville',
    title: 'EST',
  },
  {
    key: 'America/Kentucky/Monticello',
    title: 'EST',
  },
  {
    key: 'America/Kralendijk',
    title: 'AST',
  },
  {
    key: 'America/La_Paz',
    title: 'BOT',
  },
  {
    key: 'America/Lima',
    title: 'PET',
  },
  {
    key: 'America/Los_Angeles',
    title: 'PST',
  },
  {
    key: 'America/Lower_Princes',
    title: 'AST',
  },
  {
    key: 'America/Maceio',
    title: 'BRT',
  },
  {
    key: 'America/Managua',
    title: 'CST',
  },
  {
    key: 'America/Manaus',
    title: 'AMT',
  },
  {
    key: 'America/Marigot',
    title: 'AST',
  },
  {
    key: 'America/Martinique',
    title: 'AST',
  },
  {
    key: 'America/Matamoros',
    title: 'CST',
  },
  {
    key: 'America/Mazatlan',
    title: 'MST',
  },
  {
    key: 'America/Menominee',
    title: 'CST',
  },
  {
    key: 'America/Merida',
    title: 'CST',
  },
  {
    key: 'America/Metlakatla',
    title: 'AKST',
  },
  {
    key: 'America/Mexico_City',
    title: 'CST',
  },
  {
    key: 'America/Miquelon',
    title: 'PMST',
  },
  {
    key: 'America/Moncton',
    title: 'AST',
  },
  {
    key: 'America/Monterrey',
    title: 'CST',
  },
  {
    key: 'America/Montevideo',
    title: 'UYT',
  },
  {
    key: 'America/Montserrat',
    title: 'AST',
  },
  {
    key: 'America/Nassau',
    title: 'EST',
  },
  {
    key: 'America/New_York',
    title: 'EST',
  },
  {
    key: 'America/Nipigon',
    title: 'EST',
  },
  {
    key: 'America/Nome',
    title: 'AKST',
  },
  {
    key: 'America/Noronha',
    title: 'FNT',
  },
  {
    key: 'America/North_Dakota/Beulah',
    title: 'CST',
  },
  {
    key: 'America/North_Dakota/Center',
    title: 'CST',
  },
  {
    key: 'America/North_Dakota/New_Salem',
    title: 'CST',
  },
  {
    key: 'America/Nuuk',
    title: 'WGT',
  },
  {
    key: 'America/Ojinaga',
    title: 'MST',
  },
  {
    key: 'America/Panama',
    title: 'EST',
  },
  {
    key: 'America/Pangnirtung',
    title: 'EST',
  },
  {
    key: 'America/Paramaribo',
    title: 'SRT',
  },
  {
    key: 'America/Phoenix',
    title: 'MST',
  },
  {
    key: 'America/Port-au-Prince',
    title: 'EST',
  },
  {
    key: 'America/Port_of_Spain',
    title: 'AST',
  },
  {
    key: 'America/Porto_Velho',
    title: 'AMT',
  },
  {
    key: 'America/Puerto_Rico',
    title: 'AST',
  },
  {
    key: 'America/Punta_Arenas',
    title: 'CLT',
  },
  {
    key: 'America/Rainy_River',
    title: 'CST',
  },
  {
    key: 'America/Rankin_Inlet',
    title: 'CST',
  },
  {
    key: 'America/Recife',
    title: 'BRT',
  },
  {
    key: 'America/Regina',
    title: 'CST',
  },
  {
    key: 'America/Resolute',
    title: 'CST',
  },
  {
    key: 'America/Rio_Branco',
    title: 'AMT',
  },
  {
    key: 'America/Santarem',
    title: 'BRT',
  },
  {
    key: 'America/Santiago',
    title: 'CLT',
  },
  {
    key: 'America/Santo_Domingo',
    title: 'AST',
  },
  {
    key: 'America/Sao_Paulo',
    title: 'BRT',
  },
  {
    key: 'America/Scoresbysund',
    title: 'EGT',
  },
  {
    key: 'America/Sitka',
    title: 'AKST',
  },
  {
    key: 'America/St_Barthelemy',
    title: 'AST',
  },
  {
    key: 'America/St_Johns',
    title: 'NST',
  },
  {
    key: 'America/St_Kitts',
    title: 'AST',
  },
  {
    key: 'America/St_Lucia',
    title: 'AST',
  },
  {
    key: 'America/St_Thomas',
    title: 'AST',
  },
  {
    key: 'America/St_Vincent',
    title: 'AST',
  },
  {
    key: 'America/Swift_Current',
    title: 'CST',
  },
  {
    key: 'America/Tegucigalpa',
    title: 'CST',
  },
  {
    key: 'America/Thule',
    title: 'AST',
  },
  {
    key: 'America/Thunder_Bay',
    title: 'EST',
  },
  {
    key: 'America/Tijuana',
    title: 'PST',
  },
  {
    key: 'America/Toronto',
    title: 'EST',
  },
  {
    key: 'America/Tortola',
    title: 'AST',
  },
  {
    key: 'America/Vancouver',
    title: 'PST',
  },
  {
    key: 'America/Whitehorse',
    title: 'PST',
  },
  {
    key: 'America/Winnipeg',
    title: 'CST',
  },
  {
    key: 'America/Yakutat',
    title: 'AKST',
  },
  {
    key: 'America/Yellowknife',
    title: 'MST',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Casey',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Davis',
  },
  {
    title: 'GMT',
    key: 'Antarctica/DumontDUrville',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Macquarie',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Mawson',
  },
  {
    title: 'GMT',
    key: 'Antarctica/McMurdo',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Palmer',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Rothera',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Syowa',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Troll',
  },
  {
    title: 'GMT',
    key: 'Antarctica/Vostok',
  },
  {
    title: 'GMT',
    key: 'Arctic/Longyearbyen',
  },
  {
    key: 'Asia/Aden',
    title: 'AST',
  },
  {
    key: 'Asia/Almaty',
    title: 'ALMT',
  },
  {
    key: 'Asia/Amman',
    title: 'EET',
  },
  {
    key: 'Asia/Anadyr',
    title: 'ANAT',
  },
  {
    key: 'Asia/Aqtau',
    title: 'AQTT',
  },
  {
    key: 'Asia/Aqtobe',
    title: 'AQTT',
  },
  {
    key: 'Asia/Ashgabat',
    title: 'TMT',
  },
  {
    key: 'Asia/Atyrau',
    title: 'AQTT',
  },
  {
    key: 'Asia/Baghdad',
    title: 'AST',
  },
  {
    key: 'Asia/Bahrain',
    title: 'AST',
  },
  {
    key: 'Asia/Baku',
    title: 'AZT',
  },
  {
    key: 'Asia/Bangkok',
    title: 'ICT',
  },
  {
    key: 'Asia/Barnaul',
    title: 'KRAT',
  },
  {
    key: 'Asia/Beirut',
    title: 'EET',
  },
  {
    key: 'Asia/Bishkek',
    title: 'KGT',
  },
  {
    key: 'Asia/Brunei',
    title: 'BNT',
  },
  {
    key: 'Asia/Calcutta',
    title: 'IST',
  },
  {
    key: 'Asia/Chita',
    title: 'YAKT',
  },
  {
    key: 'Asia/Choibalsan',
    title: 'CHOT',
  },
  {
    key: 'Asia/Colombo',
    title: 'IST',
  },
  {
    key: 'Asia/Damascus',
    title: 'EET',
  },
  {
    key: 'Asia/Dhaka',
    title: 'BDT',
  },
  {
    key: 'Asia/Dili',
    title: 'TLT',
  },
  {
    key: 'Asia/Dubai',
    title: 'GST',
  },
  {
    key: 'Asia/Dushanbe',
    title: 'TJT',
  },
  {
    key: 'Asia/Famagusta',
    title: 'EET',
  },
  {
    key: 'Asia/Gaza',
    title: 'EET',
  },
  {
    key: 'Asia/Hebron',
    title: 'EET',
  },
  {
    key: 'Asia/Hong_Kong',
    title: 'HKT',
  },
  {
    key: 'Asia/Hovd',
    title: 'HOVT',
  },
  {
    key: 'Asia/Irkutsk',
    title: 'IRKT',
  },
  {
    key: 'Asia/Jakarta',
    title: 'WIB',
  },
  {
    key: 'Asia/Jayapura',
    title: 'WIT',
  },
  {
    key: 'Asia/Jerusalem',
    title: 'IST',
  },
  {
    key: 'Asia/Kabul',
    title: 'AFT',
  },
  {
    key: 'Asia/Kamchatka',
    title: 'PETT',
  },
  {
    key: 'Asia/Karachi',
    title: 'PKT',
  },
  {
    key: 'Asia/Katmandu',
    title: 'NPT',
  },
  {
    key: 'Asia/Khandyga',
    title: 'YAKT',
  },
  {
    key: 'Asia/Krasnoyarsk',
    title: 'KRAT',
  },
  {
    key: 'Asia/Kuala_Lumpur',
    title: 'MYT',
  },
  {
    key: 'Asia/Kuching',
    title: 'MYT',
  },
  {
    key: 'Asia/Kuwait',
    title: 'AST',
  },
  {
    key: 'Asia/Macau',
    title: 'CST',
  },
  {
    key: 'Asia/Magadan',
    title: 'MAGT',
  },
  {
    key: 'Asia/Makassar',
    title: 'WITA',
  },
  {
    key: 'Asia/Manila',
    title: 'PHT',
  },
  {
    key: 'Asia/Muscat',
    title: 'GST',
  },
  {
    key: 'Asia/Nicosia',
    title: 'EET',
  },
  {
    key: 'Asia/Novokuznetsk',
    title: 'KRAT',
  },
  {
    key: 'Asia/Novosibirsk',
    title: 'NOVT',
  },
  {
    key: 'Asia/Omsk',
    title: 'OMST',
  },
  {
    key: 'Asia/Oral',
    title: 'ORAT',
  },
  {
    key: 'Asia/Phnom_Penh',
    title: 'ICT',
  },
  {
    key: 'Asia/Pontianak',
    title: 'WIB',
  },
  {
    key: 'Asia/Pyongyang',
    title: 'KST',
  },
  {
    key: 'Asia/Qatar',
    title: 'AST',
  },
  {
    key: 'Asia/Qostanay',
    title: 'QYZT',
  },
  {
    key: 'Asia/Qyzylorda',
    title: 'QYZT',
  },
  {
    key: 'Asia/Rangoon',
    title: 'MMT',
  },
  {
    key: 'Asia/Riyadh',
    title: 'AST',
  },
  {
    key: 'Asia/Saigon',
    title: 'ICT',
  },
  {
    key: 'Asia/Sakhalin',
    title: 'SAKT',
  },
  {
    key: 'Asia/Samarkand',
    title: 'UZT',
  },
  {
    key: 'Asia/Seoul',
    title: 'KST',
  },
  {
    key: 'Asia/Shanghai',
    title: 'CST',
  },
  {
    key: 'Asia/Singapore',
    title: 'SGT',
  },
  {
    key: 'Asia/Srednekolymsk',
    title: 'SRET',
  },
  {
    key: 'Asia/Taipei',
    title: 'CST',
  },
  {
    key: 'Asia/Tashkent',
    title: 'UZT',
  },
  {
    key: 'Asia/Tbilisi',
    title: 'GET',
  },
  {
    key: 'Asia/Tehran',
    title: 'IRST',
  },
  {
    key: 'Asia/Thimphu',
    title: 'BTT',
  },
  {
    key: 'Asia/Tokyo',
    title: 'JST',
  },
  {
    key: 'Asia/Tomsk',
    title: 'MSK',
  },
  {
    key: 'Asia/Ulaanbaatar',
    title: 'ULAT',
  },
  {
    key: 'Asia/Urumqi',
    title: 'XJT',
  },
  {
    key: 'Asia/Ust-Nera',
    title: 'VLAT',
  },
  {
    key: 'Asia/Vientiane',
    title: 'ICT',
  },
  {
    key: 'Asia/Vladivostok',
    title: 'VLAT',
  },
  {
    key: 'Asia/Yakutsk',
    title: 'YAKT',
  },
  {
    key: 'Asia/Yekaterinburg',
    title: 'YEKT',
  },
  {
    key: 'Asia/Yerevan',
    title: 'AMT',
  },
  {
    key: 'Atlantic/Azores',
    title: 'AZOT',
  },
  {
    key: 'Atlantic/Bermuda',
    title: 'AST',
  },
  {
    key: 'Atlantic/Canary',
    title: 'WET',
  },
  {
    key: 'Atlantic/Cape_Verde',
    title: 'CVT',
  },
  {
    key: 'Atlantic/Faeroe',
    title: 'WET',
  },
  {
    key: 'Atlantic/Madeira',
    title: 'WET',
  },
  {
    key: 'Atlantic/Reykjavik',
    title: 'GMT',
  },
  {
    key: 'Atlantic/South_Georgia',
    title: 'GST',
  },
  {
    key: 'Atlantic/St_Helena',
    title: 'GMT',
  },
  {
    key: 'Atlantic/Stanley',
    title: 'FKST',
  },
  {
    key: 'Australia/Adelaide',
    title: 'ACDT',
  },
  {
    key: 'Australia/Brisbane',
    title: 'AEST',
  },
  {
    key: 'Australia/Broken_Hill',
    title: 'ACDT',
  },
  {
    key: 'Australia/Currie',
    title: 'AEDT',
  },
  {
    key: 'Australia/Darwin',
    title: 'ACST',
  },
  {
    key: 'Australia/Eucla',
    title: 'ACWST',
  },
  {
    key: 'Australia/Hobart',
    title: 'AEDT',
  },
  {
    key: 'Australia/Lindeman',
    title: 'AEST',
  },
  {
    key: 'Australia/Lord_Howe',
    title: 'LHDT',
  },
  {
    key: 'Australia/Melbourne',
    title: 'AEDT',
  },
  {
    key: 'Australia/Perth',
    title: 'AWST',
  },
  {
    key: 'Australia/Sydney',
    title: 'AEDT',
  },
  {
    key: 'Europe/Amsterdam',
    title: 'CET',
  },
  {
    key: 'Europe/Andorra',
    title: 'CET',
  },
  {
    key: 'Europe/Astrakhan',
    title: 'MSK',
  },
  {
    key: 'Europe/Athens',
    title: 'EET',
  },
  {
    key: 'Europe/Belgrade',
    title: 'CET',
  },
  {
    key: 'Europe/Berlin',
    title: 'CET',
  },
  {
    key: 'Europe/Bratislava',
    title: 'CET',
  },
  {
    key: 'Europe/Brussels',
    title: 'CET',
  },
  {
    key: 'Europe/Bucharest',
    title: 'EET',
  },
  {
    key: 'Europe/Budapest',
    title: 'CET',
  },
  {
    key: 'Europe/Busingen',
    title: 'CET',
  },
  {
    key: 'Europe/Chisinau',
    title: 'EET',
  },
  {
    key: 'Europe/Copenhagen',
    title: 'CET',
  },
  {
    key: 'Europe/Dublin',
    title: 'GMT',
  },
  {
    key: 'Europe/Gibraltar',
    title: 'CET',
  },
  {
    key: 'Europe/Guernsey',
    title: 'GMT',
  },
  {
    key: 'Europe/Helsinki',
    title: 'EET',
  },
  {
    key: 'Europe/Isle_of_Man',
    title: 'GMT',
  },
  {
    key: 'Europe/Istanbul',
    title: 'TRT',
  },
  {
    key: 'Europe/Jersey',
    title: 'GMT',
  },
  {
    key: 'Europe/Kaliningrad',
    title: 'EET',
  },
  {
    key: 'Europe/Kiev',
    title: 'EET',
  },
  {
    key: 'Europe/Kirov',
    title: 'MSK',
  },
  {
    key: 'Europe/Lisbon',
    title: 'WET',
  },
  {
    key: 'Europe/Ljubljana',
    title: 'CET',
  },
  {
    key: 'Europe/London',
    title: 'GMT',
  },
  {
    key: 'Europe/Luxembourg',
    title: 'CET',
  },
  {
    key: 'Europe/Madrid',
    title: 'CET',
  },
  {
    key: 'Europe/Malta',
    title: 'CET',
  },
  {
    key: 'Europe/Mariehamn',
    title: 'EET',
  },
  {
    key: 'Europe/Minsk',
    title: 'FET',
  },
  {
    key: 'Europe/Monaco',
    title: 'CET',
  },
  {
    key: 'Europe/Moscow',
    title: 'MSK',
  },
  {
    key: 'Europe/Oslo',
    title: 'CET',
  },
  {
    key: 'Europe/Paris',
    title: 'CET',
  },
  {
    key: 'Europe/Podgorica',
    title: 'CET',
  },
  {
    key: 'Europe/Prague',
    title: 'CET',
  },
  {
    key: 'Europe/Riga',
    title: 'EET',
  },
  {
    key: 'Europe/Rome',
    title: 'CET',
  },
  {
    key: 'Europe/Samara',
    title: 'SAMT',
  },
  {
    key: 'Europe/San_Marino',
    title: 'CET',
  },
  {
    key: 'Europe/Sarajevo',
    title: 'CET',
  },
  {
    key: 'Europe/Saratov',
    title: 'MSK',
  },
  {
    key: 'Europe/Simferopol',
    title: 'MSK',
  },
  {
    key: 'Europe/Skopje',
    title: 'CET',
  },
  {
    key: 'Europe/Sofia',
    title: 'EET',
  },
  {
    key: 'Europe/Stockholm',
    title: 'CET',
  },
  {
    key: 'Europe/Tallinn',
    title: 'EET',
  },
  {
    key: 'Europe/Tirane',
    title: 'CET',
  },
  {
    key: 'Europe/Ulyanovsk',
    title: 'MSK',
  },
  {
    key: 'Europe/Uzhgorod',
    title: 'EET',
  },
  {
    key: 'Europe/Vaduz',
    title: 'CET',
  },
  {
    key: 'Europe/Vatican',
    title: 'CET',
  },
  {
    key: 'Europe/Vienna',
    title: 'CET',
  },
  {
    key: 'Europe/Vilnius',
    title: 'EET',
  },
  {
    key: 'Europe/Volgograd',
    title: 'MSK',
  },
  {
    key: 'Europe/Warsaw',
    title: 'CET',
  },
  {
    key: 'Europe/Zagreb',
    title: 'CET',
  },
  {
    key: 'Europe/Zaporozhye',
    title: 'EET',
  },
  {
    key: 'Europe/Zurich',
    title: 'CET',
  },
  {
    key: 'Indian/Antananarivo',
    title: 'EAT',
  },
  {
    key: 'Indian/Chagos',
    title: 'IOT',
  },
  {
    key: 'Indian/Christmas',
    title: 'CXT',
  },
  {
    key: 'Indian/Cocos',
    title: 'CCT',
  },
  {
    key: 'Indian/Comoro',
    title: 'EAT',
  },
  {
    key: 'Indian/Kerguelen',
    title: 'TFT',
  },
  {
    key: 'Indian/Mahe',
    title: 'SCT',
  },
  {
    key: 'Indian/Maldives',
    title: 'MVT',
  },
  {
    key: 'Indian/Mauritius',
    title: 'MUT',
  },
  {
    key: 'Indian/Mayotte',
    title: 'EAT',
  },
  {
    key: 'Indian/Reunion',
    title: 'RET',
  },
  {
    key: 'Pacific/Apia',
    title: 'WST',
  },
  {
    key: 'Pacific/Auckland',
    title: 'NZDT',
  },
  {
    key: 'Pacific/Bougainville',
    title: 'BST',
  },
  {
    key: 'Pacific/Chatham',
    title: 'CHADT',
  },
  {
    key: 'Pacific/Easter',
    title: 'EASST',
  },
  {
    key: 'Pacific/Efate',
    title: 'VUT',
  },
  {
    key: 'Pacific/Enderbury',
    title: 'PHOT',
  },
  {
    key: 'Pacific/Fakaofo',
    title: 'TKT',
  },
  {
    key: 'Pacific/Fiji',
    title: 'FJT',
  },
  {
    key: 'Pacific/Funafuti',
    title: 'TVT',
  },
  {
    key: 'Pacific/Galapagos',
    title: 'GALT',
  },
  {
    key: 'Pacific/Gambier',
    title: 'GAMT',
  },
  {
    key: 'Pacific/Guadalcanal',
    title: 'SBT',
  },
  {
    key: 'Pacific/Guam',
    title: 'ChST',
  },
  {
    key: 'Pacific/Honolulu',
    title: 'HST',
  },
  {
    key: 'Pacific/Johnston',
    title: 'HST',
  },
  {
    key: 'Pacific/Kiritimati',
    title: 'LINT',
  },
  {
    key: 'Pacific/Kosrae',
    title: 'KOST',
  },
  {
    key: 'Pacific/Kwajalein',
    title: 'MHT',
  },
  {
    key: 'Pacific/Majuro',
    title: 'MHT',
  },
  {
    key: 'Pacific/Marquesas',
    title: 'MART',
  },
  {
    key: 'Pacific/Midway',
    title: 'SST',
  },
  {
    key: 'Pacific/Nauru',
    title: 'NRT',
  },
  {
    key: 'Pacific/Niue',
    title: 'NUT',
  },
  {
    key: 'Pacific/Norfolk',
    title: 'NFT',
  },
  {
    key: 'Pacific/Noumea',
    title: 'NCT',
  },
  {
    key: 'Pacific/Pago_Pago',
    title: 'SST',
  },
  {
    key: 'Pacific/Palau',
    title: 'PWT',
  },
  {
    key: 'Pacific/Pitcairn',
    title: 'PST',
  },
  {
    key: 'Pacific/Ponape',
    title: 'PONT',
  },
  {
    key: 'Pacific/Port_Moresby',
    title: 'PGT',
  },
  {
    key: 'Pacific/Rarotonga',
    title: 'CKT',
  },
  {
    key: 'Pacific/Saipan',
    title: 'ChST',
  },
  {
    key: 'Pacific/Tahiti',
    title: 'TAHT',
  },
  {
    key: 'Pacific/Tarawa',
    title: 'GILT',
  },
  {
    key: 'Pacific/Tongatapu',
    title: 'TOT',
  },
  {
    key: 'Pacific/Truk',
    title: 'CHUT',
  },
  {
    key: 'Pacific/Wake',
    title: 'WAKT',
  },
  {
    key: 'Pacific/Wallis',
    title: 'WFT',
  },
];
