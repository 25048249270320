import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CampaignWorkflowSubTabs, Platforms, WorkflowCompletionStatus } from '../../../enums';
import { CampaignWorkflow, CampaignWorkflowStep } from '../../../interfaces';
import { tryGet } from '../../../utils';
import { ProductGiftsService } from './product-gifts.service';
import { TabsService } from './tabs.service';
import { tap } from 'rxjs/operators';
import { UserguidingService } from '../userguiding.service';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../feature-flag/flags';
export const WORKFLOW_PREVIEW_VERSION = 99999;

@Injectable()
export class CampaignWorkflowService {
  public currentCampaignWorkflow$: BehaviorSubject<CampaignWorkflow> = new BehaviorSubject<CampaignWorkflow>(null);
  private memoize = {};
  private refreshIframeSource = new Subject<CampaignWorkflowSubTabs>();
  public refreshIframe$ = this.refreshIframeSource.asObservable();
  disableSave = false;

  public refreshState = new BehaviorSubject<{ state: boolean; tab: CampaignWorkflowSubTabs }>({
    state: false,
    tab: undefined,
  });
  public refreshStateChanged$ = this.refreshState.asObservable();

  constructor(
    private productGiftsService: ProductGiftsService,
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService,
    private tabsService: TabsService,
    private userguiding: UserguidingService,
    private featureFlag: FeatureFlagService,
  ) {
    this.refreshStateChanged$.subscribe((v) => {
      if (!v.state) {
        this.tabsService.completeLoading();
      }
    });
  }

  getCreatorAppBaseUrl(): string {
    return environment.creatorsAppURL;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  refreshIframe(sourceTab): void {
    this.tabsService.currentActiveSubTab = sourceTab;
    this.refreshIframeSource.next(sourceTab);
  }

  setCurrentCampaignWorkflow(campaignWorkflow: CampaignWorkflow): void {
    this.currentCampaignWorkflow$.next(campaignWorkflow);
    this.productGiftsService.changeProductList(tryGet(() => campaignWorkflow?.campaignProductGift?.products, []));
    this.productGiftsService.selectionConstrains = tryGet(
      () => campaignWorkflow?.campaignProductGift?.selectionConstrains,
      { quantity: 1 },
    );
  }

  getCurrentCampaignWorkflow(): CampaignWorkflow {
    return this.currentCampaignWorkflow$.value;
  }

  isCampaignBriefComplete(workflow: CampaignWorkflow): boolean {
    return !!(
      workflow.campaignBrief &&
      workflow.campaignBrief.title &&
      workflow.campaignBrief.description &&
      workflow.campaignImageUrl
    );
  }

  isCampaignProductComplete(workflow: CampaignWorkflow): boolean {
    return (
      workflow.campaignProductGift &&
      (!workflow.campaignProductGift?.productGiftingEnabled ||
        (workflow.campaignProductGift?.products && workflow.campaignProductGift?.products?.length > 0))
    );
  }

  isCampaignContentRightsComplete(workflow: CampaignWorkflow): boolean {
    const contentEnabled = workflow?.contentRights?.enabled ?? true;
    if (!contentEnabled) {
      return true;
    }
    return !!workflow?.contentRights?.description;
  }

  getWorkflowCompletionStatus(workflow: CampaignWorkflow): WorkflowCompletionStatus {
    if (!workflow) {
      return 'uninitialized';
    }

    if (
      !this.isCampaignBriefComplete(workflow) ||
      !this.isCampaignProductComplete(workflow) ||
      !this.isCampaignContentRightsComplete(workflow)
    ) {
      return 'pending';
    }

    return 'completed';
  }

  getCampaignWorkflow(campaignSlugName: string): Promise<CampaignWorkflow> {
    return this.http
      .get<CampaignWorkflow>(environment.api + `/api/campaigns/v2/${campaignSlugName}/workflow`)
      .toPromise();
  }

  async getEditPermission(campaignSlugName: string): Promise<boolean> {
    if (this.memoize[campaignSlugName]) {
      return !!this.memoize[campaignSlugName]?.name;
    }
    const org = await this.checkIfClientBelongToCreatorOrg(campaignSlugName);
    this.memoize[campaignSlugName] = org;
    return !!this.memoize[campaignSlugName]?.name;
  }

  async checkIfClientBelongToCreatorOrg(campaignSlugName: string): Promise<string | undefined> {
    const isIAMSupported = await this.featureFlag.isFeatureReleased(RELEASE_FLAGS.IAM_MULTI_INSTANCE_SUPPORT);
    return this.http
      .get<string | undefined>(
        environment.api +
          `/api/campaigns/v2/${campaignSlugName}/${isIAMSupported ? 'accountOrgMatchCheck' : 'orgMembershipCheck'}`,
      )
      .toPromise();
  }

  createCampaignWorkflow(
    campaignSlugName: string,
    campaignWorkflow: CampaignWorkflow,
    step: CampaignWorkflowStep,
    campaignImage?: File,
    assets?: File[],
  ): Promise<CampaignWorkflow> {
    const formData = new FormData();
    formData.append('campaignWorkflow', JSON.stringify(campaignWorkflow));
    formData.append('step', step);
    if (campaignImage) {
      formData.append('campaignImage', campaignImage, campaignImage.name);
    }

    if (assets?.length) {
      assets.forEach((file) => {
        formData.append('assets', file, file.name);
      });
    }
    const res = this.http.post<CampaignWorkflow>(
      environment.api + `/api/campaigns/v2/${campaignSlugName}/workflow`,
      formData,
    );
    return res.toPromise().finally(() => this.tabsService.completeLoading());
  }

  updateCampaignWorkflow(
    campaignSlugName: string,
    campaignWorkflow: Partial<CampaignWorkflow>,
    step?: CampaignWorkflowStep,
    campaignImage?: File,
    assets?: File[],
  ): Promise<CampaignWorkflow> {
    const formData = new FormData();
    formData.append('campaignWorkflow', JSON.stringify(campaignWorkflow));
    if (step) {
      formData.append('step', step);
    }
    if (campaignImage) {
      formData.append('campaignImage', campaignImage, campaignImage.name);
    }
    if (assets?.length) {
      assets.forEach((file) => {
        formData.append('assets', file);
      });
    }
    const res = this.http
      .put<CampaignWorkflow>(environment.api + `/api/campaigns/v2/${campaignSlugName}/workflow`, formData)
      .pipe(
        tap((response) => {
          if (response.revisionNumber === 1) {
            this.userguiding.track('Configured Creator Portal', { campaignSlugName });
            this.userguiding.identify({ configured_creator_portal: 1 });
          }
        }),
      );
    return res.toPromise().finally(() => this.tabsService.completeLoading());
  }

  saveRevision(platform: Platforms, campaignSlugName: string): void {
    this.updateCampaignWorkflow(campaignSlugName, { revisionUpdated: new Date().toISOString() }, 'revision')
      .then(() => this.toastrService.success('Campaign Workflow updated'))
      .catch(() => this.toastrService.error('Failed to update campaign workflow, please try again!'));
    const campaignBaseUrl =
      platform === Platforms.instagram
        ? '/pages/campaigns/view/'
        : '/pages/' + platform.toLocaleLowerCase() + '/campaigns/view/';
    this.router.navigateByUrl(campaignBaseUrl + campaignSlugName);
  }

  get enabledWorkflowSteps(): string[] {
    const enabledSteps: string[] = [];
    const workflowSteps = Object.keys(CampaignWorkflowSubTabs);
    const workflow = this.getCurrentCampaignWorkflow();
    workflowSteps.forEach((step) => {
      switch (CampaignWorkflowSubTabs[step]) {
        case CampaignWorkflowSubTabs.proposalSettings:
          return null; // no need to check as not a configurable step
        case CampaignWorkflowSubTabs.campaignBrief:
          return null; // no need to check as not a configurable step
        case CampaignWorkflowSubTabs.productGiftings:
          return workflow?.campaignProductGift?.productGiftingEnabled
            ? enabledSteps.push(CampaignWorkflowSubTabs[step])
            : null;
        case CampaignWorkflowSubTabs.fixedPay:
          return workflow?.fixedPay?.enabled ? enabledSteps.push(CampaignWorkflowSubTabs[step]) : null;
        case CampaignWorkflowSubTabs.paymentSettings:
          return workflow?.storeConfigurations?.pricingRule?.enabled
            ? enabledSteps.push(CampaignWorkflowSubTabs[step])
            : null;
        case CampaignWorkflowSubTabs.influencerTasks:
          return workflow?.influencerTasks?.enabled ? enabledSteps.push(CampaignWorkflowSubTabs[step]) : null;
        case CampaignWorkflowSubTabs.contentRights:
          return workflow?.contentRights?.enabled ? enabledSteps.push(CampaignWorkflowSubTabs[step]) : null;
        case CampaignWorkflowSubTabs.brandAssets:
          return null; // no need to check as not a configurable step
        case CampaignWorkflowSubTabs.otherDetails:
          return null; // no need to check as not a configurable step
        case CampaignWorkflowSubTabs.socialAccounts:
          return null;
        default:
          return null; // future proofing for any step getting added to enum
      }
    });
    return enabledSteps;
  }

  checkIfAtleastOneOtherStepIsEnabled(subTab: CampaignWorkflowSubTabs): number {
    return this.enabledWorkflowSteps?.filter((step) => step !== subTab)?.length;
  }

  disableSaveCreatorPortal(): void {
    this.disableSave = true;
  }

  enableSaveCreatorPortal(): void {
    this.disableSave = false;
  }
}
