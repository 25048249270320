import { Product } from './campaign-workflow.interface';

export interface EcommerceProducts {
  products: Product[];
  counts: number;
  nextPageInfo: string;
  prevPageInfo?: string;
}

export interface EcommerceSearchProducts {
  products: Product[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
  endCursor?: string;
}

export interface DiscountCode {
  code: string;
  used: boolean;
}

export interface PricingRule {
  id: string;
  title: string;
  value: string;
  value_type: string;
  targetSelection: string;
  currency: string;
}

export enum AffiliateCodeExistsEnum {
  CAMPAIGN = 'CAMPAIGN',
  STORE = 'STORE',
  NONE = 'NONE',
}
