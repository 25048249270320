/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone } from 'lodash';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  FacebookInfluencersSearchResult,
  FacebookMediaEngagementStat,
  InfluencerLookup,
  PageStatistics,
  Post,
  ProfileAbout,
} from '../../../interfaces';
import { FacebookInfluencerSearchFilters } from '../../../pages/facebook-discovery/facebook-influencers-filter.service';

export class FacebookInfluencerLookup {
  username: string;
  fullName: string;
  picture: string;
  email?: string;
  category?: string;
  editable?: boolean;
}

// Facebook About Page info

export class Age {
  buckets: AgeBucket[];
}

export class AgeBucket {
  min: number;
  max: number;
  count: number;
}
@Injectable()
export class FacebookService {
  constructor(private http: HttpClient) {}

  getProfileAboutInfo(username: string): Promise<ProfileAbout> {
    return this.http.get<ProfileAbout>(environment.api + `/api/facebook/${username}/profile`).toPromise();
  }

  getInfluencerPosts(username: string): Promise<Post[]> {
    return this.http.get<Post[]>(environment.api + `/api/facebook/${username}/posts`).toPromise();
  }

  getPageStatistics(username: string): Promise<PageStatistics> {
    return this.http.get<PageStatistics>(environment.api + `/api/facebook/${username}/statistics`).toPromise();
  }

  getPageMediaEngagement(username: string): Promise<FacebookMediaEngagementStat[]> {
    return this.http
      .get<FacebookMediaEngagementStat[]>(environment.api + `/api/facebook/${username}/mediaEngagementData`)
      .toPromise();
  }

  getTopInfluencers(location: string): Promise<FacebookInfluencersSearchResult> {
    return this.http
      .get<FacebookInfluencersSearchResult>(environment.api + `/api/facebook/top`, {
        params: { location },
      })
      .toPromise();
  }

  getInfluencers(
    searchFilters: FacebookInfluencerSearchFilters,
    page: number,
  ): Promise<FacebookInfluencersSearchResult> {
    const influencerFilters = clone(searchFilters.influencerFilters);
    if (influencerFilters.username) {
      influencerFilters.username = influencerFilters.username.trim();
    }
    return this.http
      .post(
        `${environment.api}/api/facebook/`,
        {
          influencerFilters: influencerFilters,
          audienceFilters: searchFilters.audienceFilters,
        },
        {
          params: {
            page: page.toString(),
            sortKey: searchFilters.sortKey,
          },
        },
      )
      .toPromise()
      .then(async (json: FacebookInfluencersSearchResult) => json);
  }

  lookupInfluencers(term: string): Observable<FacebookInfluencerLookup[]> {
    if (!term) {
      return of([]);
    }
    return this.http.get<FacebookInfluencerLookup[]>(`${environment.api}/api/facebook`, {
      params: { term: term.trim() },
    });
  }

  profileInfluencersList(
    usernames: string[],
    groupName: string | undefined,
    groupSlugName: string | undefined,
  ): Promise<string[]> {
    return this.http
      .post(`${environment.api}/api/jobs/facebook/influencers/profileList`, {
        usernames: usernames,
        groupName: groupName,
        groupSlugName: groupSlugName,
      })
      .toPromise()
      .then((response: any) => response.profiledUsernames);
  }

  exportInfluencersFromSearch(
    influencerUsernames: string[],
    campaignSlugName?: string,
    groupSlugName?: string,
    allUsers = false,
  ): Promise<any> {
    return this.http
      .post(
        `${environment.api}/api/facebook/export/search`,
        {
          usernames: influencerUsernames,
          ...(campaignSlugName && { campaignSlugName }),
        },
        {
          responseType: 'blob',
          params: {
            ...(groupSlugName && { groupSlugName }),
            allUsers: allUsers.toString(),
          },
        },
      )
      .toPromise();
  }

  exportInfluencersForProfile(username: string): Promise<any> {
    return this.http
      .post(`${environment.api}/api/facebook/export/profile`, { username }, { responseType: 'blob' })
      .toPromise();
  }

  getSimilarInfluencers(username: string): Promise<InfluencerLookup[]> {
    return this.http
      .get<InfluencerLookup[]>(environment.api + `/api/facebook/recommendations/users/${username}`)
      .toPromise();
  }

  getAudienceCountByAge(
    username: string,
    minAge: number,
    maxAge?: number,
  ): Promise<{
    count: number;
    percentage: number;
  }> {
    let url = environment.api + `/api/facebook/${username}/statistics/audience/count/age?min_age=${minAge}`;
    if (maxAge) {
      url = `${url}&max_age=${maxAge}`;
    }
    return this.http
      .get(url)
      .toPromise()
      .catch((err) => {
        console.log(
          `[Facebook] Error while geting audience count by age range for ${username}: ${minAge} to ${maxAge}`,
        );
        return err;
      });
  }
}
