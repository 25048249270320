import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'ai-card',
  templateUrl: './card.control.html',
  styleUrls: ['./card.control.scss'],
})
export class CardControl {
  @Input() canActivate = false;
  @Input() activeStyle = '';
  @Input() activeClass = 'active';
  cardIsActive: boolean = false;
  clickedIn = false;

  @HostBinding('style')
  get activateStyle() {
    if (this.cardIsActive && this.canActivate) {
      return this.activeStyle;
    }
    return null;
  }
  @HostBinding('class')
  get activateClass() {
    if (this.cardIsActive && this.canActivate) {
      return this.activeClass;
    }
    return null;
  }
  @HostListener('document:click')
  clickedOutside() {
    if (this.canActivate) {
      if (!this.clickedIn) {
        this.cardIsActive = false;
      }
      this.clickedIn = false;
    }
  }
  onClick() {
    if (this.canActivate) {
      this.clickedIn = true;
      this.cardIsActive = !this.cardIsActive;
    }
  }
}
