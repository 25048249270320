<ng-template [ngIf]="canPrint">
  <!-- ./START IG - PROFILE -->
  <ng-template [ngIf]="printableProfile.platform === 'Instagram'">
    <div *ngFor='let card of selectedCards; let i = index;'>

      <div [ngSwitch]='card.card'>
        <!--  PRINTED PROFILE SUMMARY (BIO)-->
        <ngx-print-chart-container *ngSwitchCase='"bio"' [id]='"bio"' [pageTag]='card.pageTag' [page]='card.page'
          [influencer]='printableProfile.influencer' [influencerStats]='printableProfile.influencerStats'
          [influencerLocationName]='printableProfile.influencerLocationName'
          [influencerPriceRanges]='printableProfile.influencerPriceRanges' [costs]='printableProfile.costs'
          [socialMediaProfiles]='printableProfile.socialMediaProfiles'></ngx-print-chart-container>

        <!--AUDIENCE AGE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-age-distribution"' [id]='"audience-age-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [adultAudiencePercentage]='printableProfile.adultAudiencePercentage' [ADULT_AUDIENCE_AGE]='ADULT_AUDIENCE_AGE'
          [genderOverlay]='printableProfile.genderOverlay'
          [ageGenderDataOptions]='printableProfile.ageGenderDataOptions'
          [ageDataOptions]='printableProfile.ageDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE GENDER DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-gender-distribution"' [id]='"audience-gender-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [genderDataOptions]='printableProfile.genderDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE LOCATION DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-location-distribution"'
          [id]='"audience-location-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [distributionData]='printableProfile.distributionData'>
        </ngx-print-chart-container>

        <!--ENGAGEMENT-->
        <ngx-print-chart-container *ngSwitchCase='"engagement"' [id]='"engagement"' [pageTag]='card.pageTag'
          [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [influencerStats]='printableProfile.influencerStats' [engagementData]='printableProfile.engagementData'>
        </ngx-print-chart-container>

        <!--FOLLOWERS TIMELINE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"follower-timeline"' [id]='"follower-timeline"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='"Followers timeline distribution"'
          [influencerStats]='printableProfile.influencerStats' [platform]='printableProfile.platform'>
        </ngx-print-chart-container>

        <!--FOLLOWERS ENGAGEMENT TIMELINE-->
        <ngx-print-chart-container *ngSwitchCase='"engagement-timeline"' [id]='"engagement-timeline"'
          [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get("instagram-engagement-timeline")'
          [influencerStats]='printableProfile.influencerStats' [engagementOptions]='printableProfile.engagementOptions'>
        </ngx-print-chart-container>

        <!--BRANDED CONTENT DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"branded-content-distribution"' [id]='"branded-content-distribution"'
          [pageTag]='card.pageTag' [page]='card.page'
          [description]='"Ratio of Branded and Non branded content posted by this influencer"'>
        </ngx-print-chart-container>

        <!--AUDIENCE INTEREST DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-interest-distribution"'
          [id]='"audience-interest-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [influencerStats]='printableProfile.influencerStats'
          [audienceInterestsDataOptions]='printableProfile.audienceInterestsDataOptions'></ngx-print-chart-container>

      </div>

    </div>
  </ng-template>
  <!-- ./END IG - PROFILE -->

  <!-- ./START VERA - PROFILE -->
  <ng-template [ngIf]="printableProfile.platform === 'VeraInstagram'">
    <div *ngFor='let card of selectedCards; let i = index;'>

      <div [ngSwitch]='card.card'>
        <!--  PRINTED PROFILE SUMMARY (BIO)-->
        <ngx-print-chart-container *ngSwitchCase='"bio"' [id]='"bio"' [pageTag]='card.pageTag' [page]='card.page'
          [influencer]='printableProfile.influencer' [influencerStats]='printableProfile.instagramStats'
          [influencerLocationName]='printableProfile.influencerLocationName'
          [influencerPriceRanges]='printableProfile.influencerPriceRanges' [costs]='printableProfile.costs'
          [socialMediaProfiles]='printableProfile.socialMediaProfiles'></ngx-print-chart-container>

        <!--AUDIENCE AGE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-age-distribution"' [id]='"audience-age-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [adultAudiencePercentage]='printableProfile.adultAudiencePercentage' [ADULT_AUDIENCE_AGE]='ADULT_AUDIENCE_AGE'
          [genderOverlay]='printableProfile.genderOverlay'
          [ageGenderDataOptions]='printableProfile.ageGenderDataOptions'
          [ageDataOptions]='printableProfile.ageDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE GENDER DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-gender-distribution"' [id]='"audience-gender-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [genderDataOptions]='printableProfile.genderDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE LOCATION DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-location-distribution"'
          [id]='"audience-location-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [distributionData]='printableProfile.distributionData'>
        </ngx-print-chart-container>

        <!--ENGAGEMENT-->
        <ngx-print-chart-container *ngSwitchCase='"engagement"' [id]='"engagement"' [pageTag]='card.pageTag'
          [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [influencerStats]='printableProfile.influencerStats' [engagementData]='printableProfile.engagementData'>
        </ngx-print-chart-container>

        <!--FOLLOWERS TIMELINE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"follower-timeline"' [id]='"follower-timeline"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='"Followers timeline distribution"'
          [influencerStats]='printableProfile.influencerStats' [platform]='printableProfile.platform'>
        </ngx-print-chart-container>

        <!--FOLLOWERS ENGAGEMENT TIMELINE-->
        <ngx-print-chart-container *ngSwitchCase='"engagement-timeline"' [id]='"engagement-timeline"'
          [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get("instagram-engagement-timeline")'
          [influencerStats]='printableProfile.influencerStats' [engagementOptions]='printableProfile.engagementOptions'>
        </ngx-print-chart-container>

        <!--BRANDED CONTENT DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"branded-content-distribution"' [id]='"branded-content-distribution"'
          [pageTag]='card.pageTag' [page]='card.page'
          [description]='"Ratio of Branded and Non branded content posted by this influencer"'>
        </ngx-print-chart-container>

        <!--AUDIENCE INTEREST DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-interest-distribution"'
          [id]='"audience-interest-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [influencerStats]='printableProfile.influencerStats'
          [audienceInterestsDataOptions]='printableProfile.audienceInterestsDataOptions'></ngx-print-chart-container>

      </div>

    </div>
  </ng-template>
  <!-- ./END VERA - PROFILE -->


  <!-- ./START FB - PROFILE -->
  <ng-template [ngIf]="printableProfile.platform === 'Facebook'">
    <div *ngFor='let card of selectedCards; let i = index;'>

      <div [ngSwitch]='card.card'>
        <!--  PRINTED PROFILE SUMMARY (BIO)-->
        <ngx-print-chart-container *ngSwitchCase='"bio"' [id]='"bio"' [pageTag]='card.pageTag' [page]='card.page'
          [profile]='printableProfile.profile' [influencerLocationName]='printableProfile.influencerLocationName'
          [socialMediaProfiles]='printableProfile.socialMediaProfiles' [platform]="printableProfile.platform">
        </ngx-print-chart-container>

        <!--AUDIENCE AGE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-age-distribution"' [id]='"audience-age-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [adultAudiencePercentage]='printableProfile.adultAudiencePercentage' [ADULT_AUDIENCE_AGE]='ADULT_AUDIENCE_AGE'
          [genderOverlay]='printableProfile.genderOverlay'
          [ageGenderDataOptions]='printableProfile.ageGenderDataOptions'
          [ageDataOptions]='printableProfile.ageDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE GENDER DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-gender-distribution"' [id]='"audience-gender-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [genderDataOptions]='printableProfile.genderDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE LOCATION DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-location-distribution"'
          [id]='"audience-location-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [distributionData]='printableProfile.distributionData'>
        </ngx-print-chart-container>

        <!--ENGAGEMENT-->
        <ngx-print-chart-container *ngSwitchCase='"engagement"' [id]='"engagement"' [pageTag]='card.pageTag'
          [page]='card.page' [description]='tooltipMessages.get("facebook-engagement")'
          [profile]='printableProfile.profile' [engagementData]='printableProfile.engagementData'
          [reactionsEngagementData]='printableProfile.reactionsEngagementData'></ngx-print-chart-container>

        <!--FOLLOWERS TIMELINE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"follower-timeline"' [id]='"follower-timeline"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='"Followers timeline distribution"'
          [followersTimeline]='printableProfile.followersTimeline' [platform]='printableProfile.platform'>
        </ngx-print-chart-container>

        <!--FOLLOWERS ENGAGEMENT TIMELINE-->
        <ngx-print-chart-container *ngSwitchCase='"engagement-timeline"' [id]='"engagement-timeline"'
          [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get("facebook-engagement-timeline")'
          [engagementOptions]='printableProfile.engagementOptions'></ngx-print-chart-container>

      </div>

    </div>
  </ng-template>
  <!-- ./END FB - PROFILE -->

  <!-- ./START TT - PROFILE -->
  <ng-template [ngIf]="printableProfile.platform === 'Tiktok'">
    <div *ngFor='let card of selectedCards; let i = index;'>

      <div [ngSwitch]='card.card'>
        <!--  PRINTED PROFILE SUMMARY (BIO)-->
        <ngx-print-chart-container *ngSwitchCase='"bio"' [id]='"bio"' [pageTag]='card.pageTag' [page]='card.page'
          [profile]='printableProfile.profile' [influencerLocationName]='printableProfile.influencerLocationName'
          [socialMediaProfiles]='printableProfile.socialMediaProfiles' [platform]="printableProfile.platform">
        </ngx-print-chart-container>

        <!--AUDIENCE AGE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-age-distribution"' [id]='"audience-age-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [adultAudiencePercentage]='printableProfile.adultAudiencePercentage' [ADULT_AUDIENCE_AGE]='ADULT_AUDIENCE_AGE'
          [genderOverlay]='printableProfile.genderOverlay'
          [ageGenderDataOptions]='printableProfile.ageGenderDataOptions'
          [ageDataOptions]='printableProfile.ageDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE GENDER DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-gender-distribution"' [id]='"audience-gender-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [genderDataOptions]='printableProfile.genderDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE LOCATION DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-location-distribution"'
          [id]='"audience-location-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [distributionData]='printableProfile.distributionData'>
        </ngx-print-chart-container>

        <!--ENGAGEMENT-->
        <ngx-print-chart-container *ngSwitchCase='"engagement"' [id]='"engagement"' [pageTag]='card.pageTag'
          [page]='card.page' [description]='tooltipMessages.get("tiktok-engagement")'
          [profile]='printableProfile.profile' [engagementData]='printableProfile.engagementData'
          [reactionsEngagementData]='printableProfile.reactionsEngagementData'></ngx-print-chart-container>

        <!--FOLLOWERS TIMELINE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"follower-timeline"' [id]='"follower-timeline"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='"Followers timeline distribution"'
          [followersTimeline]='printableProfile.followersTimeline' [platform]='printableProfile.platform'>
        </ngx-print-chart-container>

        <!--FOLLOWERS ENGAGEMENT TIMELINE-->
        <ngx-print-chart-container *ngSwitchCase='"engagement-timeline"' [id]='"engagement-timeline"'
          [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get("facebook-engagement-timeline")'
          [engagementOptions]='printableProfile.engagementOptions'></ngx-print-chart-container>

      </div>

    </div>
  </ng-template>
  <!-- ./END TT - PROFILE -->

  <!-- ./START YT - PROFILE -->
  <ng-template [ngIf]="printableProfile.platform === 'Youtube'">
    <div *ngFor='let card of selectedCards; let i = index;'>

      <div [ngSwitch]='card.card'>
        <!--  PRINTED PROFILE SUMMARY (BIO)-->
        <ngx-print-chart-container *ngSwitchCase='"bio"' [id]='"bio"' [pageTag]='card.pageTag' [page]='card.page'
          [profile]='printableProfile.profile' [influencerLocationName]='printableProfile.influencerLocationName'
          [socialMediaProfiles]='printableProfile.socialMediaProfiles' [platform]="printableProfile.platform">
        </ngx-print-chart-container>

        <!--AUDIENCE AGE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-age-distribution"' [id]='"audience-age-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [adultAudiencePercentage]='printableProfile.adultAudiencePercentage' [ADULT_AUDIENCE_AGE]='ADULT_AUDIENCE_AGE'
          [genderOverlay]='printableProfile.genderOverlay'
          [ageGenderDataOptions]='printableProfile.ageGenderDataOptions'
          [ageDataOptions]='printableProfile.ageDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE GENDER DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-gender-distribution"' [id]='"audience-gender-distribution"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='tooltipMessages.get(card.card)'
          [genderDataOptions]='printableProfile.genderDataOptions'></ngx-print-chart-container>

        <!--AUDIENCE LOCATION DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"audience-location-distribution"'
          [id]='"audience-location-distribution"' [pageTag]='card.pageTag' [page]='card.page'
          [description]='tooltipMessages.get(card.card)' [distributionData]='printableProfile.distributionData'>
        </ngx-print-chart-container>

        <!--ENGAGEMENT-->
        <ngx-print-chart-container *ngSwitchCase='"engagement"' [id]='"engagement"' [pageTag]='card.pageTag'
          [page]='card.page' [description]='tooltipMessages.get("youtube-engagement")'
          [profile]='printableProfile.profile' [engagementData]='printableProfile.engagementData'
          [reactionsEngagementData]='printableProfile.reactionsEngagementData'></ngx-print-chart-container>

        <!--FOLLOWERS TIMELINE DISTRIBUTION-->
        <ngx-print-chart-container *ngSwitchCase='"follower-timeline"' [id]='"follower-timeline"'
          [pageTag]='card.pageTag' [page]='card.page' [description]='"Followers timeline distribution"'
          [followersTimeline]='printableProfile.followersTimeline' [platform]='printableProfile.platform'>
        </ngx-print-chart-container>

      </div>

    </div>
  </ng-template>
  <!-- ./END YT - PROFILE -->
</ng-template>