<div class="attachments">
    <label class="attachment-btn" [class.disabled]="disabled" [for]="id"><i class="fas fa-paperclip"
            title="attach files"></i>Attach
        files</label>
    <label class="attachment-size">
        {{attachmentSizeHint}}
    </label>
    <div class="attachment-files">
        <input #fileInput type="file" [id]="id" multiple="multiple" class="file-uploader"
            (change)="handleFileInput($event.target.files)" [disabled]="disabled" (click)="fileInput.value = null">
        <div class="files-list">
            <div *ngFor="let file of filesToAttach" class="file-name">
                <div class="name">
                    {{file.name}} ({{file.size | filesize }})
                </div>
                <button (click)="removeAttachment(file)" title="remove attachement"><i
                        class="fas fa-close"></i></button>
            </div>
        </div>
        <div class="file-error" *ngIf="attachementError">
            {{attachementError}}
        </div>
    </div>
</div>