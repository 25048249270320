/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Followers, FollowerCountFilterLimitsEnum } from '../../../@core/data/influencer-filter.service';

@Component({
  selector: 'ngx-followers-filter',
  templateUrl: './followers-filter.component.html',
  styleUrls: ['./followers-filter.component.scss', '../styles.scss'],
})
export class FollowersFilterComponent {
  @Input() followers: Followers;
  @Input() filterLabel = 'Followers';
  @Input() isDisabled: boolean;
  @Output() triggerUpdate: EventEmitter<any> = new EventEmitter();

  readonly minFollowersLimit = FollowerCountFilterLimitsEnum.MINIMUM;
  readonly maxFollowersLimit = FollowerCountFilterLimitsEnum.MAXIMUM;

  triggerUpdateEvent(): void {
    this.triggerUpdate.emit({ min: this.followers.min, max: this.followers.max });
  }

  // TODO: type to event: Event and (event.target as HTMLInputElement)
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  checkValue(event, followersCountKey: 'min' | 'max'): void {
    if (event.target.value < this.minFollowersLimit) {
      event.target.value = this.minFollowersLimit;
      this.followers[followersCountKey] = this.minFollowersLimit;
      return;
    }
    if (event.target.value > this.maxFollowersLimit) {
      event.target.value = this.maxFollowersLimit;
      this.followers[followersCountKey] = this.maxFollowersLimit;
      return;
    }
  }
}
