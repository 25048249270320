<div *ngIf="linksGroupedByDates?.length === 0" class="align-text-image-center">
  <img class="img-center" src="../../../../assets/images/no-notes-found.svg">
  <h4>No links found</h4>
  <br>
  <p>Looks like you have no links for {{username}}</p>
</div>

<div *ngIf="linksGroupedByDates?.length > 0" class="full-window-height">
  <nb-card *ngFor="let linkByDate of linksGroupedByDates; let groupIndex = index">
    <nb-card-body class="notes-card">
      <ngx-date-grouped-links [groupIndex]="groupIndex" [linksGroupedByDate]="linkByDate"
        (deletedLinkEventEmitter)="captureDeletedLink($event)" (editedLinkEventEmitter)="captureEditedLink($event)"></ngx-date-grouped-links>
    </nb-card-body>
  </nb-card>
</div>