import { TiktokInfluencerFilters } from './tiktok.interface';
import { InfluencerFilters, AudienceFilters } from '../@core/data/influencer-filter.service';
import { TiktokAudienceFilters } from '../@core/data/tiktok/tiktok.service';
import { TwitterInfluencerFilters, TwitterAudienceFilters } from '../@core/data/twitter/twitter.service';
import { ContentSortKey, InfluencerSortKey, LastestOldestKey, Platforms } from '../enums';
import { FacebookInfluencerFilters } from '../pages/facebook-discovery/facebook-influencers-filter.service';
import { YoutubeInfluencerFilters } from '../pages/youtube-discovery/youtube-influencers-filter.service';

export interface InfluencerSearchFilters {
  influencerFilters:
    | InfluencerFilters
    | FacebookInfluencerFilters
    | YoutubeInfluencerFilters
    | TiktokInfluencerFilters
    | TwitterInfluencerFilters;
  audienceFilters?: AudienceFilters | TiktokAudienceFilters | TwitterAudienceFilters;
  sortKey: InfluencerSortKey;
  onlyTopInfluencers?: boolean;
}

export interface InfluencerSavedSearchFilters {
  clientId?: string;
  platform: Platforms;
  filterPrivacy: SearchFiltersPrivacy;
  isDefault: boolean;
  filterName: string;
  filter: InfluencerSearchFilters;
  filterSlugName?: string;
  sharedWithOrgs?: string[];
}

export type SearchFiltersPrivacy = 'private' | 'org' | 'global';

export interface InfluencerSortOption {
  label: string;
  value: InfluencerSortKey;
}

export interface ContentSortOption {
  label: string;
  value: ContentSortKey;
}

export interface LatestOldestSortOption {
  label: string;
  value: LastestOldestKey;
}

export interface TabChange {
  nextId: string;
  activeId: string;
}

export enum DiscoveryActiveTab {
  Influencer = 'influencer-filter',
  Groups = 'groups-filter',
  Audience = 'audience-filter',
}
