import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class CollaborationPortalGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isCollaborationPortalEnabled = this.auth.isCollaborationPortalEnabled();
    if (!isCollaborationPortalEnabled) {
      console.error(`CollaborationPortalGuard rejected access to ${state.url}`);
      this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
    }
    return isCollaborationPortalEnabled;
  }
}
