import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../@core/data/user.service';
import { Note } from '../../../interfaces';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../@core/feature-flag/flags';
import { CollaboratorService } from '../../../collaborator/collaborator.service';
import { SubscribedClientUser } from '../../../enums';

@Component({
  selector: 'ngx-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
  consumerId: string;
  isIAMEnabled = false;

  @Input() notes: Note[];
  @Input() username: string;
  @Input() notesShown: 'shared' | 'private' | 'all';
  @Input() isSingleUserOrg: boolean;
  @Input() isCollabPortal = false;
  @Input() isGroupsOrCampaign = false;
  @Output() editedNoteEventEmitter = new EventEmitter();
  @Output() deletedNoteEventEmitter = new EventEmitter();
  @Output() downloadFileEventEmitter = new EventEmitter();

  isAgencyFeature = false;

  constructor(
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
    private collaboratorService: CollaboratorService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isAgencyFeature = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.AGENCY_X_BRAND_GROUP);
    // will be used to check note ownership
    if (!this.isCollabPortal) {
      let clientUser: SubscribedClientUser;
      [clientUser, this.isIAMEnabled] = await Promise.all([
        this.userService.getClientUser(),
        this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.IAM_MULTI_INSTANCE_SUPPORT),
      ]);
      this.consumerId = this.isIAMEnabled ? (clientUser.account?.id as string) : clientUser.userId;
    } else {
      this.consumerId = this.collaboratorService.getExternalUserId() as string;
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureEditedNote($event) {
    // noteId will suffice to identify note when emitting changes as opposed to group/note/listIndex in dateGroupedNoteComponent
    const noteId = $event.noteId;
    const editedNoteAsHtml = $event.editedNoteAsHtml;
    const deletedNoteFile = $event.deletedNoteFile;
    const privacy = $event.privacy;
    this.editedNoteEventEmitter.emit({
      noteId,
      editedNoteAsHtml,
      deletedNoteFile,
      privacy,
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureDeletedNote($event) {
    const noteId = $event.noteId;
    this.deletedNoteEventEmitter.emit({
      noteId,
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureDownloadedFile($event) {
    const file = $event.file;
    this.downloadFileEventEmitter.emit({ file });
  }

  getNotesMessage(): string {
    switch (this.notesShown) {
      case 'shared':
        if (this.isAgencyFeature && this.isGroupsOrCampaign)
          return `Notes saved as 'Shared' by default will be visible to all accounts within your organization, as well as external accounts with whom this group is shared.`;
        return 'Notes saved as `Shared` by default will be visible to all the account holders in your organization. ';
      case 'private':
        return 'Notes saved as `Private` will be visible only to you. ';
      default:
        return '';
    }
  }

  getEmptyNotesMessage(): string {
    if (this.isCollabPortal) {
      return 'All new notes will be visible to all the accounts that have access to this group.';
    }
    switch (this.notesShown) {
      case 'shared':
        return 'Add new notes to see it here.<br /><br />All new notes, which are shared by default, will be visible to all account holder in your organization.';
      case 'private':
        return 'Add new notes and <strong>make them private</strong> to see it here.<br /><br />All new notes that are private will be visible only to you.';
      default:
        return 'Add new notes to see it here.';
    }
  }

  getEmptyNotesImageURL(): string {
    switch (this.notesShown) {
      case 'shared':
        return '../../../../assets/images/no-notes-found-shared.svg';
      default:
        return '../../../../assets/images/no-notes-found-private.svg';
    }
  }

  checkIfOwnNote(id: string): boolean {
    return id === this.consumerId;
  }
}
