import { Component } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ShowMessage } from '../../../../interfaces';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './public-app-reset-password-link.component.html',
  styleUrls: ['./public-app-reset-password-link.component.scss'],
})
export class NgxPublicResetPasswordLinkComponent {
  showMessages: ShowMessage = {};
  errors = [];
  email: string;
  submitted = false;
  shop: string;
  storeName: string;
  navigation: Navigation;

  constructor(private authService: AuthService, private router: Router) {
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit(): void {
    if (this.navigation.previousNavigation) {
      this.shop = this.navigation.extras.state.query.shop;
      this.storeName = this.navigation.extras.state.query.storeName;
      this.email = this.navigation.extras.state.email;
    } else {
      this.router.navigate(['404-not-found']);
    }
  }

  async resetPassword(): Promise<void> {
    if (!this.email) return;

    this.submitted = true;
    await this.authService.resetPassword(this.email, { shop: this.shop }).catch((err) => {
      this.showMessages.error = true;
      this.errors.push(err.message);
      this.submitted = false;
    });
  }

  navigateToRequestResetLink(): Promise<boolean> {
    const queryParams = { shop: this.shop, storeName: this.storeName };
    return this.router.navigate(['auth', 'public', 'request-password'], { queryParams });
  }
}
