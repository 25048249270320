import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TwitterContentSortKey } from '../../../enums';
import {
  GroupsWithLogic,
  HashtagWithLogic,
  InfluencerWithLogic,
  TwitterContentSearchResult,
} from '../../../interfaces';
import { ContentTimeFilter, SearchModifiers } from '../content-filter.service';
import { Followers } from '../influencer-filter.service';
import { Location } from '../location.service';

@Injectable()
export class TwitterContentService {
  private filtersChangedSource = new Subject<TwitterContentFilters>();
  filtersChanged$ = this.filtersChangedSource.asObservable();

  constructor(private http: HttpClient) {}

  sendContentFiltersChangedEvent(contentFilters: TwitterContentFilters): void {
    this.filtersChangedSource.next(contentFilters);
  }

  searchContent(filters: TwitterContentFilters, page: number, allContent = false): Promise<TwitterContentSearchResult> {
    return this.http
      .post<TwitterContentSearchResult>(`${environment.api}/api/twitter/content/`, filters, {
        params: {
          page: page.toString(),
          allContent: allContent.toString(),
        },
      })
      .toPromise();
  }

  getTopContent(location: string): Promise<TwitterContentSearchResult> {
    return this.http
      .get<TwitterContentSearchResult>(`${environment.api}/api/twitter/content/top`, {
        params: { location },
      })
      .toPromise();
  }

  exportMediaReport(contentIds: string[], campaignSlugName?: string): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/twitter/content/export/search`,
        {
          contentIds: contentIds,
          campaignSlugName,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }
}

// content search types

export class TwitterContentFilters {
  time = new ContentTimeFilter();
  influencers: InfluencerWithLogic[] = [];
  groups: GroupsWithLogic[] = [];
  keywords: HashtagWithLogic[] = [];
  interests: HashtagWithLogic[] = [];
  sortKey: TwitterContentSortKey = 'recency';
  followers = new Followers();
  countries: Location[] = [];
  searchModifiers: SearchModifiers = new SearchModifiers();
}
