import { chain } from 'lodash';
import { AgeBucket, AgeGenderBucket, LabelBucket } from '../@core/data/influencer.service';
import utils from '../@core/utils/utils';

export function setGenderDistribution(genderBuckets: LabelBucket[]): { series: []; legend?: [] } {
  let genderDataOptions;
  if (!utils.isBucketsEmpty(genderBuckets)) {
    genderDataOptions = {
      legend: {
        data: genderBuckets?.map((gender) => gender.label),
      },
      series: [
        {
          name: 'Gender',
          data: genderBuckets?.map((gender) => {
            return {
              value: gender.count,
              name: gender.label,
            };
          }),
        },
      ],
    };
  } else {
    genderDataOptions = {
      series: [],
    };
  }
  return genderDataOptions;
}

export function setAgeGenderDistribution(
  ageBuckets: AgeBucket[],
  ageGenderBuckets: AgeGenderBucket[],
  adultAudiencePercentage: number,
): { ageDataOptions?: any; ageGenderDataOptions?: any; adultAudiencePercentage?: string } {
  const ageDistribution = {};
  ageDistribution['ageDataOptions'] = setDataOptions(ageBuckets ?? [], true);
  ageDistribution['ageGenderDataOptions'] = setDataOptions(ageGenderBuckets ?? [], false);
  ageDistribution['adultAudiencePercentage'] = adultAudiencePercentage;
  return ageDistribution;
}

export function getAudienceCityLocation(cityDistribution) {
  if (!cityDistribution?.length) {
    return [];
  }
  return chain(cityDistribution)
    .orderBy('value', 'desc')
    .map((city) => ({ name: city.name, value: city.value * 100 }))
    .slice(0, 20)
    .value();
}

export function setDataOptions(buckets: AgeBucket[] | AgeGenderBucket[], isAgeOnly: boolean) {
  if ((isAgeOnly && utils.isBucketsEmpty(buckets)) || (!isAgeOnly && utils.isAgeGenderBucketsEmpty(buckets))) {
    return {
      series: [],
    };
  }

  const series = setSeries(buckets, isAgeOnly);

  return {
    xAxis: [
      {
        data: ['<19', '19-25', '26-32', '33-39', '40-46', '46+'],
      },
    ],
    yAxis: [
      {
        name: 'Percentage of followers',
      },
    ],
    series,
    colorBy: 'series',
  };
}

export function setSeries(buckets: AgeBucket[] | AgeGenderBucket[], isAgeOnly: boolean) {
  return isAgeOnly
    ? [
        {
          name: 'Age Groups',
          data: utils.toPercentagePoints(buckets),
          itemStyle: {
            borderWidth: '0',
          },
        },
      ]
    : [
        {
          name: 'Male',
          data: utils.toPercentagePointsByGender(buckets, 'male'),
          stack: 'Age Group',
          type: 'bar',
          itemStyle: {
            borderWidth: '0',
          },
        },
        {
          name: 'Female',
          stack: 'Age Group',
          data: utils.toPercentagePointsByGender(buckets, 'female'),
          type: 'bar',
          itemStyle: {
            borderWidth: '0',
          },
        },
      ];
}
