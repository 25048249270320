import { Component, Input } from '@angular/core';
import { max } from 'lodash';
import { environment } from '../../../environments/environment';
import utils from '../../@core/utils/utils';
import { PlatformsV2, VeraPlatforms } from '../../enums';

@Component({
  selector: 'ngx-bar-chart-with-info',
  templateUrl: './bar-chart-with-info.component.html',
  styleUrls: ['./bar-chart-with-info.component.scss'],
})
export class BarChartWithInfoComponent {
  @Input() barChartOptionsList: BarChartWithInfoComponentOptions[];
  @Input() platform: PlatformsV2;

  getMaxValue(): number {
    // XXX: Using max value instead of 100% since the engagement score are very low, and the UI effectively shows a histogram.
    return max(this.barChartOptionsList.map((o) => o.value));
  }

  getRelativePerc(value: number): number {
    // XXX: Return min of 29% to show the numbers
    const minWidth = 39;
    return Math.max(this.getRoundedValue((value / this.getMaxValue()) * 100.0), minWidth);
  }

  getRoundedValue(value: number): number {
    return Math.round(value);
  }

  /**
   * If unable to load profile image, get downloadable image from fluence api
   */
  checkError(imgUrl: string, index: number): void {
    if (imgUrl) {
      const url = new URL(imgUrl);
      if (imgUrl && url.origin !== environment.api && this.barChartOptionsList[index]) {
        this.barChartOptionsList[index].profilePicUrl = utils.getInstagramCDNUrl(imgUrl);
      }
    }
  }

  getProfileUrl(username: string) {
    return utils.getInfluencerProfileUrl(this.platform, username);
  }
}

export class BarChartWithInfoComponentOptions {
  value: number;
  fullName: string;
  username: string;
  profilePicUrl: string;
}
