/* eslint-disable no-useless-escape */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'parseUrl' })
export class ParseUrl implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  urls =
    /(\b((https?|http):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?)/gi; // Find/Replace URL's in text

  transform(text: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this.parseUrl(text));
  }

  private parseUrl(text: string) {
    // Find/Replace URL's in text
    if (text.match(this.urls)) {
      text = text.replace(this.urls, function replacer($1, $2, $3) {
        const url = $1;
        const urlClean = url.replace('' + $3 + '://', '');

        return '<a href="' + url + '" target="_blank">' + urlClean + '</a>';
      });
    }

    return text;
  }
}
