import { LabelBucket } from '../@core/data/influencer.service';
import { CampaignStatus } from '../enums';
import {
  CampaignMediaReportingStatus,
  CommentAnalysis,
  FacebookMediaReportData,
  InstagramMediaReportData,
  MediaReportData,
  TiktokMediaReportData,
  TwitterMediaReportData,
  YoutubeMediaReportData,
} from './reports.interface';

export interface BasicCampaignInfo {
  id: string;
  name: string;
  status: CampaignStatus;
}

export interface CampaignReport {
  name: string;
  id: string;
  createdAt: string;
  status: CampaignStatus;
  selected?: boolean;
  pending_media_count?: number;
  total_media_count?: number;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}

export interface FacebookCampaignReport {
  name: string;
  campaignId: string;
  createdAt: string;
  status: CampaignStatus;
  selected?: boolean;
  pending_media_count?: number;
  total_media_count?: number;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}

export interface TwitterCampaignReport {
  name: string;
  campaignId: string;
  createdAt: string;
  status: CampaignStatus;
  selected?: boolean;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}

export interface YoutubeCampaignReport {
  name: string;
  campaignId: string;
  createdAt: string;
  status: CampaignStatus;
  selected?: boolean;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}

export interface TiktokCampaignReport {
  name: string;
  campaignId: string;
  createdAt: string;
  status: CampaignStatus;
  selected?: boolean;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}

export class CampaignForm {
  name: string;
  mediaIds: string[];
  campaignSlug?: string;
}

export class CampaignEditForm {
  name: string;
  campaignId: string;
  mediaIds: string[];
}

export interface CampaignReportData {
  mediaUnderAnalysis: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  campaign: CampaignReport;
  mediaReports: MediaReportData[];
  aggLocations: LabelBucket[];
  aggregatedCommentAnalysis: CommentAnalysis;
  mediaComments?: MediaComment[];
  excludedInfluencerUsernames?: string[];
  status?: CampaignMediaReportingStatus;
}

export interface FacebookCampaignReportData {
  mediaUnderAnalysis: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  campaign: CampaignReport;
  mediaReports: FacebookMediaReportData[];
  aggLocations: LabelBucket[];
  aggregatedCommentAnalysis: CommentAnalysis;
  mediaComments?: MediaComment[];
}

export interface TwitterCampaignReportData {
  mediaUnderAnalysis: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  campaign: CampaignReport;
  mediaReports: TwitterMediaReportData[];
  aggLocations: LabelBucket[];
  aggregatedCommentAnalysis: CommentAnalysis;
  mediaComments?: MediaComment[];
}

export interface YoutubeCampaignReportData {
  mediaUnderAnalysis: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  campaign: CampaignReport;
  mediaReports: YoutubeMediaReportData[];
  aggregatedCommentAnalysis: CommentAnalysis;
  mediaComments?: MediaComment[];
}

export interface TiktokCampaignReportData {
  mediaUnderAnalysis: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  campaign: CampaignReport;
  mediaReports: TiktokMediaReportData[];
  aggLocations: LabelBucket[];
  aggregatedCommentAnalysis: CommentAnalysis;
  mediaComments?: MediaComment[];
}

export interface InstagramCampaignReportData {
  mediaUnderAnalysis: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  campaign: CampaignReport;
  mediaReports: InstagramMediaReportData[];
  aggLocations: LabelBucket[];
  aggregatedCommentAnalysis: CommentAnalysis;
  mediaComments?: MediaComment[];
  excludedInfluencerUsernames?: string[];
}

export interface MediaComment {
  id: string;
  name?: string;
  username?: string;
  sentiment: string;
  text: string;
  mediaPk: string;
}

export interface AggregatedComment {
  id: string;
  name?: string;
  username?: string;
  content: string;
  mediaPk: string;
}

export interface AggregatedComments {
  positive: AggregatedComment[];
  negative: AggregatedComment[];
  neutral: AggregatedComment[];
}

export interface InstagramCampaignReport {
  name: string;
  campaignId: string;
  createdAt: string;
  status: CampaignStatus;
  selected?: boolean;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}
