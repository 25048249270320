import { Injectable } from '@angular/core';
import { EmailSequenceService } from '../../../@core/data/email-sequence.service';
import { Subscription } from 'rxjs';
import {
  CreateSequenceModalParams,
  EditSequenceModalParams,
  SequenceUpsertedEvent,
  UpsertableSequence,
} from '../../../interfaces';
import { ToastrService } from 'ngx-toastr';
import { FollowUpEmailComponent } from './follow-up-email/follow-up-email.component';
import { ResultModalComponent } from '../../../shared/result-modal/result-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditSequenceModal } from './create-edit-email-sequence/create-edit-email-sequence.modal';
import { PlatformsV2 } from '../../../enums';

@Injectable()
export class EmailSequenceUtils {
  campaignWorkflowConfigured = false;
  constructor(
    private emailSequenceService: EmailSequenceService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
  ) {}

  private emailSequenceUpsertedSubscription: Subscription;
  async subscribeToEmailSequence(campaignWorkflowConfigured: boolean): Promise<void> {
    this.campaignWorkflowConfigured = campaignWorkflowConfigured;
    this.emailSequenceUpsertedSubscription = this.emailSequenceService.emailSequenceUpserted$.subscribe(
      (event: SequenceUpsertedEvent) => {
        switch (event.kind) {
          case 'SEQUENCE_CREATED': {
            const metadata = (event as SequenceUpsertedEvent<'SEQUENCE_CREATED'>).metadata;
            this.openEmailSequenceStagesModal(metadata);
            break;
          }
          case 'SEQUENCE_EDITED': {
            const metadata = (event as SequenceUpsertedEvent<'SEQUENCE_EDITED'>).metadata;
            this.openEmailSequenceStagesModal(metadata, false);
            break;
          }
          case 'EMAILS_ADDED': {
            const metadata = (event as SequenceUpsertedEvent<'EMAILS_ADDED'>).metadata;
            this.openEmailSequenceResultModal(metadata?.sequenceName, 'SAVED', undefined, metadata.sequenceId);
            break;
          }
          case 'INFLUENCERS_ADDED': {
            const metadata = (event as SequenceUpsertedEvent<'INFLUENCERS_ADDED'>).metadata;
            this.openEmailSequenceResultModal(
              metadata?.sequenceName,
              'ADDED',
              metadata.numberOfInfluencers,
              metadata.sequenceId,
            );
            break;
          }
          case 'ENABLED': {
            const metadata = (event as SequenceUpsertedEvent<'ENABLED'>).metadata;
            this.openEmailSequenceResultModal(metadata?.sequenceName, 'ENABLED');
            break;
          }
          case 'CREATE_SEQUENCE': {
            const metadata = (event as SequenceUpsertedEvent<'CREATE_SEQUENCE'>).metadata;
            this.openCreateSequencesModal(metadata);
            break;
          }
          case 'EDIT_SEQUENCE': {
            const metadata = (event as SequenceUpsertedEvent<'EDIT_SEQUENCE'>).metadata;
            this.openEditSequencesModal(metadata);
            break;
          }
        }
      },
    );
  }

  async unSubscribeToSequence(): Promise<void> {
    this.emailSequenceUpsertedSubscription?.unsubscribe();
  }

  async enableSequence(sequenceId: string): Promise<void> {
    await this.emailSequenceService.changeEmailSequenceStatus(sequenceId, true);
  }

  openEditSequencesModal(sequence: EditSequenceModalParams): void {
    const newEmailSequenceModal = this.modalService.open(CreateEditSequenceModal, {
      size: 'lg',
      windowClass: 'new-email-sequence-modal',
      centered: true,
      backdrop: 'static',
    });
    const modalComponent = newEmailSequenceModal.componentInstance as CreateEditSequenceModal;
    modalComponent.campaignSlugName = sequence.campaignSlugName;
    modalComponent.platform = sequence.platform as PlatformsV2;
    modalComponent.sequenceId = sequence.sequenceId;
    modalComponent.campaignWorkflowConfigured = this.campaignWorkflowConfigured;
  }

  openCreateSequencesModal(sequence: CreateSequenceModalParams): void {
    const newEmailSequenceModal = this.modalService.open(CreateEditSequenceModal, {
      size: 'lg',
      windowClass: 'new-email-sequence-modal',
      centered: true,
      backdrop: 'static',
    });
    const modalComponent = newEmailSequenceModal.componentInstance as CreateEditSequenceModal;
    modalComponent.campaignSlugName = sequence.campaignSlugName;
    modalComponent.platform = sequence.platform as PlatformsV2;
    modalComponent.selectedInfluencers = sequence.selectedInfluencers ?? [];
    modalComponent.campaignWorkflowConfigured = this.campaignWorkflowConfigured;
  }

  openEmailSequenceStagesModal(sequence: UpsertableSequence, isNew = true): void {
    if (sequence._id && sequence.name) {
      const followUpEmailModal = this.modalService.open(FollowUpEmailComponent, {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        windowClass: 'modal-xl',
      });
      const modalContent = followUpEmailModal.componentInstance as FollowUpEmailComponent;
      modalContent.sequenceName = sequence.name;
      modalContent.isSequenceEnabled = sequence.isEnabled;
      modalContent.campainSlugName = sequence.campaignSlugName;
      modalContent.sequenceId = sequence._id;
      modalContent.isNewSequence = isNew;
      modalContent.platform = sequence.platform as PlatformsV2;
      modalContent.campaignWorkflowConfigured = this.campaignWorkflowConfigured;
    }
  }

  async openEmailSequenceResultModal(
    sequenceName: string,
    modalPurpose: 'SAVED' | 'ADDED' | 'ENABLED',
    numberOfInfluencers?: number,
    sequenceId?: string,
  ): Promise<void> {
    let primaryEvent: SequenceUpsertedEvent;
    let secondaryEvent: SequenceUpsertedEvent;
    const confirmModal = this.modalService.open(ResultModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      windowClass: '',
    });

    const modalContent = confirmModal.componentInstance as ResultModalComponent;
    switch (modalPurpose) {
      case 'SAVED':
        modalContent.headerText = 'Saved Successfully';
        modalContent.contentText = `<strong>${sequenceName}</strong> Saved Successfully<br/>You can enable it now, or later via Email Sequences.`;
        modalContent.secondaryActionButton = {
          label: 'Keep Disabled',
          type: 'outline',
        };
        modalContent.primaryActionButton = {
          label: 'Enable Now',
          type: 'fill',
        };
        primaryEvent = {
          kind: 'ENABLED',
          metadata: {
            sequenceId: sequenceId ?? '',
            sequenceName,
          },
        };
        secondaryEvent = {
          kind: 'NONE',
          metadata: null,
        };
        break;
      case 'ADDED':
        modalContent.headerText = 'Added Successfully';
        modalContent.contentText = `<strong>${numberOfInfluencers}</strong> Influencer${
          numberOfInfluencers && numberOfInfluencers !== 1 ? 's' : ''
        } added to <strong>${sequenceName}</strong> successfully.<br><br>
      You can go to Email Sequences to enable the sequence and start sending emails.`;
        modalContent.secondaryActionButton = {
          label: 'Back to Campaign',
          prefix: 'far fa-chevron-left',
          type: 'ghost',
        };
        modalContent.primaryActionButton = {
          label: 'Go to Email Sequences',
          suffix: 'far fa-arrow-right',
          type: 'fill',
        };
        primaryEvent = {
          kind: 'OPEN_SEQUENCES',
          metadata: null,
        };
        secondaryEvent = {
          kind: 'NONE',
          metadata: null,
        };

        break;
      case 'ENABLED':
        modalContent.headerText = 'Enabled Successfully';
        modalContent.contentText = `<strong>${sequenceName}</strong> has been enabled successfully<br/>You can track it in Email Sequences.`;
        modalContent.secondaryActionButton = {
          label: 'Back to Campaign',
          prefix: 'far fa-chevron-left',
          type: 'ghost',
        };
        modalContent.primaryActionButton = {
          label: 'Go to Email Sequences',
          suffix: 'far fa-arrow-right',
          type: 'fill',
        };

        primaryEvent = {
          kind: 'OPEN_SEQUENCES',
          metadata: null,
        };
        secondaryEvent = {
          kind: 'NONE',
          metadata: null,
        };

        break;
    }
    modalContent.areContentsCentered = true;
    modalContent.showCloseOption = false;
    modalContent.image = 'assets/images/payment-successful.svg';
    modalContent.closeOnAction = false;
    modalContent.buttonClicked.subscribe(async (type) => {
      let event: SequenceUpsertedEvent = {
        kind: 'NONE',
        metadata: null,
      };
      switch (type) {
        case 'PRIMARY':
          if (modalPurpose === 'SAVED') {
            try {
              modalContent.loading = true;
              await this.enableSequence(sequenceId ?? '');
              this.toastrService.success(`Enabled the sequence: ${sequenceName}`);
              event = primaryEvent;
            } catch {
              this.toastrService.error(`Couldn't enable the sequnce: ${sequenceName}`);
            } finally {
              modalContent.loading = false;
            }
          } else {
            event = primaryEvent;
          }
          break;
        case 'SECONDARY':
          event = secondaryEvent;
          break;
      }

      if (event) {
        this.emailSequenceService.sendEmailSequenceUpsertedEvent(event);
      }
      modalContent.loading = false;
      confirmModal.close();
    });
    return;
  }
}
