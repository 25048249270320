import { InfluencerLookup } from './influencer.interface';
import { PlatformsV2 } from '../enums';

export interface Sequence {
  _id?: string;
  name: string;
  terminationRules: { kind: string }[];
  influencerIds?: string[];
  timing: EmailSequenceTimingInterface;
  campaignSlugName: string;
  platform: string;
  clientId: string;
  createdBy: string;
  isDeleted?: boolean;
  isEnabled: boolean;
  eventTimestamp?: EmailSequenceTimeStamp;
}

export type SequenceUpsertedEventType =
  | 'OPEN_SEQUENCES'
  | 'SEQUENCE_CREATED'
  | 'SEQUENCE_EDITED'
  | 'EMAILS_ADDED'
  | 'ENABLED'
  | 'CREATE_SEQUENCE'
  | 'EDIT_SEQUENCE'
  | 'INFLUENCERS_ADDED'
  | 'NONE';

export interface SequenceUpsertedEvent<T = SequenceUpsertedEventType> {
  kind: T;
  metadata: T extends 'SEQUENCE_CREATED' | 'SEQUENCE_EDITED'
    ? UpsertableSequence
    : T extends 'EMAILS_ADDED'
    ? { sequenceId: string; sequenceName: string; stages: Stage[] }
    : T extends 'ENABLED'
    ? { sequenceId: string; sequenceName: string }
    : T extends 'CREATE_SEQUENCE'
    ? CreateSequenceModalParams
    : T extends 'EDIT_SEQUENCE'
    ? EditSequenceModalParams
    : T extends 'INFLUENCERS_ADDED'
    ? {
        sequenceId: string;
        sequenceName: string;
        numberOfInfluencers: number;
      }
    : null;
}

export interface EditSequenceModalParams extends CreateSequenceModalParams {
  sequenceId: string;
}

export interface CreateSequenceModalParams {
  platform: PlatformsV2;
  campaignSlugName: string;
  selectedInfluencers?: InfluencerLookup[];
}

export interface UpsertableSequence extends Sequence {
  influencerUsernames?: string[];
}

export interface SequenceAPIResponse {
  sequence: Sequence;
  success: boolean;
}

export interface SequenceMinimalInfo {
  _id?: string;
  name: string;
  campaignSlugName: string;
  clientId: string;
  isEnabled: boolean;
  enabledAt: Date;
  createdAt: Date;
  influencersCount?: number;
  platform: string;
  terminationRules: { kind: string }[];
}

export interface SequenceUniqueInfo extends SequenceMinimalInfo {
  delivered: number;
  uniqueOpens: number;
  uniqueReplies: number;
  uniqueClicks: number;
}

export interface SequenceUniqueInfoWithLoaders extends SequenceUniqueInfo {
  changingStatus?: boolean;
}

interface EmailSequenceTimingInterface {
  timeZone: {
    title: string;
    key: string;
  };
  days: string[];
  fromTime: string;
  toTime: string;
}

interface EmailSequenceTimeStamp {
  updatedAt?: Date;
  createdAt?: Date;
  enabledAt?: Date;
}
export interface TimeZone {
  title: string;
  key: string;
  label: string;
}

interface EmailSequenceTimingInterface {
  timeZone: {
    title: string;
    key: string;
  };
  days: string[];
  fromTime: string;
  toTime: string;
}

interface EmailSequenceTimeStamp {
  updatedAt?: Date;
  createdAt?: Date;
  enabledAt?: Date;
}
export interface TimeZone {
  title: string;
  key: string;
  label: string;
}

export interface GenericAction {
  kind: string;
}

export class SendMailAction implements GenericAction {
  kind: 'SEND_MAIL';
  subject: string;
  body: string;
}

export type Action = SendMailAction;

export interface Stage {
  _id?: string;
  sequenceId: string;
  index: number;
  delayInMs: number;
  action: Action;
  isDeleted?: boolean;
}

export enum EmailSequenceFilters {
  ALL = 'ALL',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
