import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { Location } from '../../../@core/data/location.service';

@Component({
  selector: 'ngx-countries-filter',
  templateUrl: './countries-filter.component.html',
  styleUrls: ['./countries-filter.component.scss', '../styles.scss'],
})
export class CountriesFilterComponent {
  @Input() allCountries: Observable<Location[]>;
  @Input() selectedCountries: string[];
  @Input() loading: boolean;

  @Output() triggerUpdate: EventEmitter<string[]> = new EventEmitter();

  triggerUpdateEvent(): void {
    this.triggerUpdate.emit(this.selectedCountries);
  }
}
