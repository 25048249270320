import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { FollowersGrowth } from '../../../@core/data/influencer-filter.service';
import { FeatureFlagService } from '../../../@core/feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../../../@core/feature-flag/flags';

interface FollowersGrowthBucketDropdown {
  bucket: { label: string; value: Pick<FollowersGrowth, 'bucket'>['bucket'] };
  valueRange: { label: string; value: { min: number; max?: number } };
}

@Component({
  selector: 'ngx-followers-growth-filter',
  templateUrl: './followers-growth-filter.component.html',
  styleUrls: ['./followers-growth-filter.component.scss', '../styles.scss'],
})
export class FollowersGrowthFilterComponent implements OnInit, OnChanges {
  @Input() followersGrowth: FollowersGrowth;
  @Input() filterLabel = 'Followers Growth';
  @Output() triggerUpdate: EventEmitter<any> = new EventEmitter();

  selectedFollowersGrowth: FollowersGrowthBucketDropdown;
  isRestricted = false;
  bucketLoading = true;

  bucketModes: Pick<FollowersGrowthBucketDropdown, 'bucket'>['bucket'][] = [
    { label: 'None', value: null },
    { label: 'Last Week', value: 'last_week' },
    { label: 'Last Month', value: 'last_month' },
  ];

  valueRangeModes: Pick<FollowersGrowthBucketDropdown, 'valueRange'>['valueRange'][] = [
    { label: 'None', value: null },
    { label: '0% - 10%', value: { min: 0, max: 10 } },
    { label: '10% - 50%', value: { min: 10, max: 50 } },
    { label: '50% - 100%', value: { min: 50, max: 100 } },
    { label: '> 100%', value: { min: 100 } },
  ];

  constructor(private featureFlagService: FeatureFlagService) {}

  async ngOnInit(): Promise<void> {
    this.isRestricted = await this.featureFlagService.isFeatureReleased(RELEASE_FLAGS.APP_UPDATES);
    if (!this.isRestricted) {
      this.bucketModes.push(
        { label: 'Last 3 Months', value: 'last_3_months' },
        { label: 'Last 6 Months', value: 'last_6_months' },
      );
    }
    this.selectedFollowersGrowth = {
      bucket: this.bucketModes.find((bm) => bm.value === this.followersGrowth?.bucket),
      valueRange: this.valueRangeModes.find((vrm) => isEqual(vrm.value, this.followersGrowth?.valueRange)),
    };
    this.bucketLoading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const updatedFollowersGrowth: FollowersGrowth = changes.followersGrowth.currentValue;
    this.selectedFollowersGrowth = {
      bucket: this.bucketModes.find((bm) => bm.value === updatedFollowersGrowth?.bucket),
      valueRange: this.valueRangeModes.find((vrm) => isEqual(vrm.value, updatedFollowersGrowth?.valueRange)),
    };
  }

  triggerUpdateEvent(event) {
    if (this.selectedFollowersGrowth.bucket && this.selectedFollowersGrowth.valueRange) {
      if (
        this.selectedFollowersGrowth.bucket.value === null ||
        this.selectedFollowersGrowth.valueRange.value === null
      ) {
        delete this.followersGrowth?.bucket;
        delete this.followersGrowth?.valueRange;
      } else {
        this.followersGrowth.bucket = this.selectedFollowersGrowth.bucket.value;
        this.followersGrowth.valueRange = this.selectedFollowersGrowth.valueRange.value;
      }

      this.triggerUpdate.emit(event);
    }
  }
}
