import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EmailTemplate } from '../../interfaces';

@Injectable()
export class EmailTemplateService {
  constructor(private http: HttpClient) {}

  getInfluencerRegistrationTemplates(): Promise<{
    types: { type: string; templates: EmailTemplate[] }[];
    supportedVariables: string[];
    invitationTypes: { type: string; templates: EmailTemplate[] }[];
  }> {
    return this.http
      .get<{
        types: { type: string; templates: EmailTemplate[] }[];
        supportedVariables: string[];
        invitationTypes: { type: string; templates: EmailTemplate[] }[];
      }>(environment.api + `/api/instagram/profile/email-templates`)
      .toPromise();
  }
}
