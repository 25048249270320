import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PopperContent } from 'ngx-popper';

@Component({
  selector: 'ngx-update-influencer',
  templateUrl: './update-influencer.component.html',
  styleUrls: ['./update-influencer.component.scss', '../admin-controls.scss'],
})
export class UpdateInfluencerComponent {
  @Input() disabled: boolean;

  @Output() updateGenderEvent: EventEmitter<void> = new EventEmitter();
  @Output() updateLocationEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild('actionsContextMenu') actionsContextMenu: PopperContent;

  updateGender(): void {
    this.updateGenderEvent.emit();
    this.actionsContextMenu.hide();
  }

  updateLocation(): void {
    this.updateLocationEvent.emit();
    this.actionsContextMenu.hide();
  }
}
