import { InfluencerLookup } from './influencer.interface';
import { GroupFilterEvent, GroupInfo } from '../@core/data/group.service';
import { AgeGroup } from '../enums';
import { CategoryInfo } from './brand.interface';

export interface GroupFilter {
  group: GroupInfo;
  event: GroupFilterEvent;
}

export interface IdsSharedTo {
  clientIds: string[];
}

export interface ModificationStatus {
  success: boolean;
  data?: string[];
}

interface OverlapData {
  actual: number;
  reach: number;
  reachPercentage: number;
  exclusiveReach: number;
  exReachPercentage: number;
}

export interface SainsburyData {
  uniqueReach: number;
  uniqueReachPercentage: number;
}

export interface GroupStatistics {
  overlapData?: OverlapData;
  pks?: number[];
  status?: 'valid' | 'invalid' | 'pending';
  sainsburyData?: SainsburyData;
  updatedAt?: number;
  averageEngagement?: number;
  totalReach?: number;
  totalInfluencers?: number;
}

export interface LabeledAgeGroup {
  label: string;
  ageGroup: AgeGroup;
}

export interface InfluencerDiscoveryLookup extends InfluencerLookup {
  category: string;
  isIncluded?: boolean;
}

export const getInfluencerDiscoveryLookupFromUsername = (username: string): InfluencerDiscoveryLookup => {
  return {
    username,
    isIncluded: true,
    category: '',
    fullName: '',
    picture: '',
  };
};
