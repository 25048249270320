import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ai-tab',
  templateUrl: './tab.control.html',
  styleUrls: ['./tab.control.scss'],
})
export default class TabComponent {
  @Input() tabTitle: string;
  @Input() id: string;
  @Input() active = false;
  @Input() prefix: TemplateRef<HTMLElement>;
  @Input() suffix: TemplateRef<HTMLElement>;
  @Input() tabIcon: string;
  @Input() disable = false;
  @Input() dataCy?: string = '';
}
