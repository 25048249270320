<div>
  <div class="search-header"><span>Create groups by adding relevant influencers.</span></div>
  <div class="tab-action-bar">
    <div class="af-heading-h1">Groups ({{influencerGroups?.length}})</div>
    <ai-button type="text" label="Create New Group" (click)="createNewGroup()"></ai-button>
  </div>
  <div class="search-group-input">
        <form>
            <ai-input placeholder="Search by group name or tag" [formControl]="search"></ai-input>
        </form>
    </div>
    <div *ngIf="loading" class="loader">
      <ngx-loader></ngx-loader>
    </div>
  <div class="group-list">
    <div class="group-item active" [class.active]="activeGroupSlugName === group.slugName"
      (click)="appendUrlGroupNameToParams(group.slugName)"
      *ngFor="let group of influencerGroups | searchFilter: search.value">
      <ai-tooltip placement="top" style="width: max-content;" [tooltips]="[group.name || group.groupName]"
        [disabled]="(group.name || group.groupName)?.length <= 25">
        <div class="group-name">{{group.name || group.groupName|truncate:25}}</div>
      </ai-tooltip>

      <div class="group-tags" *ngIf="group.tags && group.tags.length">
        <div *ngFor="let tag of (group.tags.length <= 3 ? group.tags : group.tags.slice(0, 2));">{{tag}}</div>
        <div *ngIf="group.tags.length > 3">
          <ai-tooltip placement="top" [tooltips]="[getGroupExtendedTags(group.tags)]">
            +{{group.tags.length - 2}}
          </ai-tooltip>
        </div>
      </div>
    </div>
  </div>
</div>
