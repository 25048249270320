import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SubscriptionService } from '../data/subscription.service';

@Injectable()
export class SubscriptionGuardService implements CanActivate {
  constructor(private subscriptionService: SubscriptionService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    const isSubscribed = await this.subscriptionService.isSubscribedUser();
    if (!isSubscribed) {
      this.router.navigate(['pages', 'account']);
      return false;
    }

    return true;
  }
}
