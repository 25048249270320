<div class="btn-container">
    <button class="sort-btn" [popper]="actionsContextMenu" [popperTrigger]="'click'" popperApplyClass="context-menu-md"
        [popperPlacement]="'bottom-end'" [disabled]="disabled">
        Mark Selected As <i class="fas fa-chevron-down"></i>
    </button>
</div>

<popper-content #actionsContextMenu>
    <div class="list-group">
        <div class="list-group-item list-group-item-action cursor-pointer" (click)="markUserBL()">
            <div class="item-text">User & BL</div>
        </div>
        <div class="list-group-item list-group-item-action cursor-pointer" (click)="markBrand()">
            <div class="item-text">Brand </div>
        </div>
    </div>
</popper-content>