import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { merge } from 'lodash';
import { PrintService } from '../../pages/print/print-layout/print.service';
import { BaseEchartsPrintedComponent } from './echarts-printed.component';

@Component({
  selector: 'ngx-echarts-bar',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss'],
})
export class EchartsBarComponent extends BaseEchartsPrintedComponent implements AfterViewInit, OnDestroy {
  options: any = {};
  themeSubscription: any;

  @Input() dataOptions: any = {};
  @Input() isAgeGender = false;
  printedChart: boolean;

  constructor(private theme: NbThemeService, private print: PrintService) {
    super();
    this.printedChart = print.isPrinting;
  }

  ngAfterViewInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      const echarts: any = config.variables.echarts;

      this.options = merge(
        {
          backgroundColor: echarts.bg,
          color: this.isAgeGender
            ? [colors.warningLight, colors.infoLight, colors.dangerLight, colors.successLight, colors.primaryLight]
            : [colors.primaryLight],
          tooltip: {
            trigger: 'axis',
            position: 'top',
            confine: true,
            axisPointer: {
              type: 'shadow',
            },
            formatter: (params) =>
              this.isAgeGender
                ? `${params[0].name}: ${(params[0].value + params[1].value).toFixed(2)}% ${params[0].marker} M: ${(
                    (params[0].value / (params[0].value + params[1].value)) *
                    100
                  ).toFixed(2)}% ${params[1].marker} F: ${(
                    (params[1].value / (params[0].value + params[1].value)) *
                    100
                  ).toFixed(2)}%`
                : `${params[0].marker} ${params[0].name}: ${params[0].value}%`,
          },
          grid: {
            left: '7%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
                rotate: 0,
              },
            },
          ],
          yAxis: [
            {
              nameRotate: 90,
              nameLocation: 'middle',
              nameGap: 30,
              type: 'value',
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              splitLine: {
                lineStyle: {
                  color: echarts.splitLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
              },
            },
          ],
          series: [
            {
              type: 'bar',
              barWidth: '60%',
            },
          ],
        },
        this.dataOptions,
      );
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  onChartEvent(event: any) {
    console.log(event);
  }
}
