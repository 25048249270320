import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import TabComponent from './tab/tab.control';

@Component({
  selector: 'ai-tabset',
  templateUrl: './tabs.control.html',
  styleUrls: ['./tabs.control.scss'],
})
export default class TabsetComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Input() class: 'secondary' | 'primary' | 'tertiary';
  @Input() tabsetClass = '';
  @Input() titleClass = 'heading-h2';

  currentTab: TabComponent;

  @Output() onTabChange: EventEmitter<TabComponent & { activeId: string; nextId: string }> = new EventEmitter();
  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTabById(id: string) {
    const activeTab = this.tabs.find((tab) => tab.id === id);
    if (activeTab?.id) {
      this.selectTab(activeTab);
    }
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
    if (this.currentTab?.id && this.currentTab?.id !== tab?.id) {
      this.onTabChange.emit({ ...tab, activeId: this.currentTab?.id, nextId: tab?.id });
    }
    this.currentTab = tab;
  }
}
