import { Component } from '@angular/core';
import * as moment from 'moment';
import { PrintService } from './print.service';
import { WhiteLabelService } from '../../../@core/data/whitelabel.service';

@Component({
  selector: 'print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss'],
})
export class PrintLayoutComponent {
  generationDate = moment(new Date()).format('dddd, MMMM Do YYYY, h:mm:ss a');
  readonly documentType: string;
  readonly useWhiteLabelLogo: boolean;
  constructor(printService: PrintService, private whiteLabelService: WhiteLabelService) {
    this.documentType = printService.documentName;
    this.useWhiteLabelLogo = whiteLabelService.getDomain() !== 'affable';
  }
}
