<div id="tableDiv">
  <ng-container *ngTemplateOutlet="data?.length || showTableWithEmptyRows ? nonEmptyState : emptyState"></ng-container>
</div>

<ng-template #emptyState>
  <ng-template #defaultEmptyStateTemplate>No data to show</ng-template>
  <ng-container *ngTemplateOutlet="tableEmptyState || defaultEmptyStateTemplate">
  </ng-container>
</ng-template>

<ng-template #nonEmptyState>
  <table [nbSpinner]="loading" nbSpinnerSize="small"  id="table" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150"
    [scrollWindow]="false" (scrolled)="scrolledToEnd()" [infiniteScrollContainer]="infiniteScrollContainer"
    fromRoot="true" [ngClass]="{'no-borders':hideBorders, 'sticky-header':stickyHeader}" ngxInsufficientItems
    [existingItems]="data?.length" (loadMoreItems)="scrolledToEnd()">
    <tr [ngClass]="{'no-borders':hideBorders, 'sticky-header':stickyHeader}">
      <th class="checkbox" *ngIf="showCheckbox" [ngClass]="hideBorders ? 'no-borders' : ''">
        <nb-checkbox *ngIf="!hideHeaderCheckbox" status="success" title="select all"
          (change)="toggleTableSelection($event)" [value]="selectAllCheckbox">
        </nb-checkbox>
      </th>
      <ng-container *ngTemplateOutlet="headers || defaultHeaderTemplate; context: {$implicit: data}">
      </ng-container>
    </tr>
    <tr *ngFor="let row of data; index as i" class="ai-row" [class.selecting]="row.hover"
      [ngClass]="hideBorders ? 'no-borders' : ''" (mouseover)="row.hover = true"
      (mouseleave)="pendingOverlay?row.hover=true:row.hover=false;">
      <td class="checkbox" *ngIf="showCheckbox" [ngClass]="hideBorders ? 'no-borders' : ''">
        <nb-checkbox status="success" [(ngModel)]="row['selected']" (ngModelChange)="toggleRowSelection($event, i)">
        </nb-checkbox>
       
      </td>
      <ng-container *ngTemplateOutlet="rows || defaultRowTemplate; context: {$implicit: row,index: i}">
      </ng-container>
    </tr>
  </table>
  <ngx-loader class="table-loader" *ngIf="paginationLoader"></ngx-loader>
</ng-template>

<!-- If no template is provided, use keys as headers and display values in rows -->
<ng-template #defaultHeaderTemplate let-data>
  <th *ngFor="let header of Object.keys(data[0])">{{header}}</th>
</ng-template>
<ng-template #defaultRowTemplate let-row>
  <td *ngFor="let header of Object.keys(row)">{{row[header]}}</td>
</ng-template>