import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import utils from '../../@core/utils/utils';
import { PartialContent } from '../../interfaces';

@Pipe({ name: 'thumbnail', pure: true })
export class InstagramThumbnailPipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  async transform(content: PartialContent | string, maxWidth = 320): Promise<string> {
    if (!content) {
      return undefined;
    }
    let code = null;
    try {
      if (typeof content !== 'string') {
        code = new URL(content.media.urlLarge).pathname.split('/')[2];
      } else {
        // content is the old deprecated url with /p/media
        const hostname = new URL(content).hostname;
        if (hostname !== 'instagram.com' && hostname !== 'www.instagram.com') {
          return content;
        }
        code = content.split('/')[4];
      }
    } catch (e) {
      if (e.message.includes('Invalid URL')) {
        console.error(`Can't get thumbnail URL, invalid URL`);
        return undefined;
      }
    }

    if (maxWidth === 0) {
      return `https://www.instagram.com/p/${code}/?utm_source=ig_embed&utm_campaign=loading`;
    }

    try {
      // replace environment.api >> http://api.staging.affable.ai to view PDF images !
      const url = `${environment.api}/cdn/instagram/media/${code}`;
      const thumbnailUrl = (await this.http.get<{ thumbnail_url: string; author_name: string }>(url).toPromise())
        .thumbnail_url;
      return utils.getInstagramCDNUrl(thumbnailUrl);
    } catch (err) {
      return `https://www.instagram.com/p/${code}`;
    }
  }
}
