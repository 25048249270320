import { ModuleWithProviders, NgModule } from '@angular/core';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { ActiveSubscriptionGuard } from './active-subscription-guard.service';
import { AdminGuardService } from './admin-guard.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { CommunityGuardService } from './community-guard.service';
import { CreatorPortalGuardService } from './creator-portal-guard.service';
import { FacebookGuardService } from './facebook-guard.service';
import { InsightsGuardService } from './insights-guard.service';
import { InstagramGuardService } from './instagram-guard.service';
import { LocalStorageService } from './local-storage.service';
import { NotLiteGuardService } from './not-lite-guard.service';
import { SubscriptionGuardService } from './subscription-guard.service';
import { TiktokGuardService } from './tiktok-guard.service';
import { TwitterGuardService } from './twitter-guard.service';
import { YoutubeGuardService } from './youtube-guard.service';
import { InfluencerDiscoveryGuard, ContentDiscoveryGuard } from './discovery.guard';
import { VeraInstagramGuardService } from './vera-instagram-guard.service';
import { MagicSearchGuardService } from './magic-search-guard.service';
import { ConsoleGuardService } from './console-guard.service';
import { RedirectToVeraGuard } from './redirect-to-vera.guard';
import { CollaborationPortalGuardService } from './collaboration-portal-guard.service';
import { AccountIdentityGuard } from './account-identity-guard.service';

const AUTH_SERVICES = [
  AuthService,
  AuthGuardService,
  AccountIdentityGuard,
  AdminGuardService,
  NotLiteGuardService,
  SubscriptionGuardService,
  ActiveSubscriptionGuard,
  LocalStorageService,
  FacebookGuardService,
  InstagramGuardService,
  YoutubeGuardService,
  InfluencerDiscoveryGuard,
  ContentDiscoveryGuard,
  InsightsGuardService,
  TiktokGuardService,
  TwitterGuardService,
  CreatorPortalGuardService,
  CommunityGuardService,
  VeraInstagramGuardService,
  RedirectToVeraGuard,
  MagicSearchGuardService,
  ConsoleGuardService,
  CollaborationPortalGuardService,
];

@NgModule({
  providers: [...AUTH_SERVICES],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<unknown> {
    return <ModuleWithProviders<unknown>>{
      ngModule: AuthModule,
      providers: [
        ...AUTH_SERVICES,
        JwtModule.forRoot({
          jwtOptionsProvider: {
            provide: JWT_OPTIONS,
            useFactory: jwtOptionsFactory,
            deps: [LocalStorageService],
          },
        }).providers,
      ],
    };
  }
}

export function jwtOptionsFactory(localStorageService: LocalStorageService): {
  tokenGetter: () => string | null;
  whitelistedDomains: string[];
} {
  return {
    tokenGetter: () => {
      return location?.href.includes('collabs')
        ? localStorageService.getItem('collaborator_access_token')
        : localStorageService.getItem('access_token');
    },
    whitelistedDomains: [environment.host, environment.auth0ApiHost, environment.sequenceApiHost],
  };
}
