import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlAbstractDirective } from '../control';
import { AutoComplete } from '../control.enum';

@Component({
  selector: 'ai-textarea',
  templateUrl: './textarea.control.html',
  styleUrls: ['./textarea.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaControl),
      multi: true,
    },
  ],
})
export class TextareaControl extends ControlAbstractDirective implements OnInit {
  @Input() autoComplete: AutoComplete = 'off';
  @Input() telCode: string;
  @Input() spellCheck = false;
  @Input() readonly = false;
  @Input() set disable(value: boolean) {
    if (value && this.control.enabled) {
      this.control.disable();
    }
    if (!value && this.control.disabled) {
      this.control.enable();
    }
  }

  /**
   * @ignore
   */
  hide = false;

  /**
   * @ignore
   */
  ngOnInit(): void {
    super.ngOnInit();
  }
}
