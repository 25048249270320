import { InfluencerService } from './../../../../@core/data/influencer.service';
import { ToastrService } from 'ngx-toastr';
import { StandardDropdownChoice } from '../../../../interfaces/shared.interface';
import { EmailSequenceService } from '../../../../@core/data/email-sequence.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import utils from '../../../../@core/utils/utils';
import { PlatformsV2 } from '../../../../enums';
import { InfluencerLookup, Sequence, TimeZone, UpsertableSequence } from '../../../../interfaces';
import { DayDeliveryOptions } from '../../../../utils/constants';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ngx-create-edit-email-sequence-modal',
  templateUrl: './create-edit-email-sequence.modal.html',
  styleUrls: ['./create-edit-email-sequence.modal.scss'],
})
export class CreateEditSequenceModal implements OnInit {
  @Input() platform: PlatformsV2;
  @Input() selectedInfluencers: InfluencerLookup[] = [];
  @Input() campaignSlugName = '';
  @Input() sequenceId: string;
  @Input() campaignWorkflowConfigured = false;

  timezones: TimeZone[];

  deliveryDays: StandardDropdownChoice[] = [
    { value: DayDeliveryOptions.WEEKDAYS, label: 'Weekdays (Mon-Fri)' },
    { value: DayDeliveryOptions.WEEKENDS, label: 'Weekends (Sat-Sun)' },
    { value: DayDeliveryOptions.ANY, label: 'Any day of the week' },
  ];
  terminationOptions: StandardDropdownChoice[] = [];

  fromAvailableHours: StandardDropdownChoice[] = [];
  toAvailableHours: StandardDropdownChoice[] = [];
  emailSendingGapHour = 2;

  sequenceForm: FormGroup;
  isInfluencerSelectionValid = false;
  isExistingSequenceEnabled = false;

  isLoading = false;
  isLoadingInfluencers = false;

  copyOfSelectedInfluencers: InfluencerLookup[] = [];
  existingInfluencerUsernames: string[] = [];
  totalExistingInfluencers = 0;
  existingInfluencersPaginationLimit = 100;
  defaultNumberOfInfluencersVisible = 100;
  loadedInfluencersIndex = 0;
  isGetWillBeTerminatedUsernamesLoading: boolean;

  get selectedTerminationRule(): string {
    return this.sequenceForm.get('terminationRule')?.value;
  }

  get willBeTerminatedUsernames(): string[] {
    return this.selectedInfluencers
      .filter(({ willBeTerminatedInSequence }) => !!willBeTerminatedInSequence)
      .map(({ username }) => username);
  }

  constructor(
    private activeModal: NgbActiveModal,
    private emailSequenceService: EmailSequenceService,
    private influencerService: InfluencerService,
    private toastrService: ToastrService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);

    this.fromAvailableHours = this.getAvailableHours();
    this.timezones = utils.getTimezonesListWithLabel();
    this.terminationOptions = utils.getTerminationConditionsAsOptions(this.campaignWorkflowConfigured);

    this.sequenceForm = new FormGroup({
      name: new FormControl('', Validators.required),
      terminationRule: new FormControl(this.terminationOptions?.[0]?.value, Validators.required),
      timezone: new FormControl(utils.getCurrentTimezone() ?? 'Asia/Singapore', Validators.required),
      whenToDeliver: new FormControl(this.deliveryDays[0].value, Validators.required),
      fromTime: new FormControl(10, Validators.required),
      toTime: new FormControl(17, Validators.required),
    });

    this.toAvailableHours = this.getAvailableHours(true);
    this.sequenceForm.get('fromTime')?.valueChanges.subscribe((value) => {
      this.toAvailableHours = this.getAvailableHours(true, value);

      if (this.sequenceForm.value['toTime'] < value + this.emailSendingGapHour) {
        this.sequenceForm.patchValue({ toTime: value + this.emailSendingGapHour });
      }
    });

    if (this.isOnEditMode) {
      this.isLoading = true;
      try {
        const existingSequence = await this.emailSequenceService.getEmailSequence(this.sequenceId);
        this.setFromValueFromBasicSequence(existingSequence);
        this.existingInfluencerUsernames = existingSequence.influencerUsernames ?? [];
        this.isExistingSequenceEnabled = existingSequence.isEnabled;
        this.totalExistingInfluencers = existingSequence.influencerUsernames?.length ?? 0;
        this.populateInfluencersWithDetails(true);
      } catch (error) {
        this.toastrService.error('Failed to retrieve email-sequence, please try again!');
        this.isLoading = false;
      }
    } else {
      this.sequenceForm
        .get('terminationRule')
        ?.valueChanges.pipe(distinctUntilChanged())
        .subscribe((value) => this.getWillBeTerminatedUsernames(value));
    }
  }

  async getWillBeTerminatedUsernames(value: string) {
    this.isGetWillBeTerminatedUsernamesLoading = true;
    this.selectedInfluencers.forEach((inf) => (inf.willBeTerminatedInSequence = false));
    if (value === 'NEVER' || value === 'REPLY_RECEIVED') {
      this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);
      this.isGetWillBeTerminatedUsernamesLoading = false;
      return;
    }
    const willBeTerminatedUsernames: string[] = await this.emailSequenceService
      .getMatchingInfluencersThatWillBeTerminatedByPolicy(
        this.campaignSlugName,
        this.selectedInfluencers.map((influencer) => influencer.username),
        { kind: value },
        this.platform,
      )
      .then(({ willBeTerminatedUsernames }) => willBeTerminatedUsernames)
      .catch(() => []);

    willBeTerminatedUsernames.forEach((username) => {
      const foundInfluencer = this.selectedInfluencers.find((inf) => inf.username === username);
      if (foundInfluencer) {
        foundInfluencer.willBeTerminatedInSequence = true;
      }
    });
    this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);
    this.isGetWillBeTerminatedUsernamesLoading = false;
  }

  getAvailableHours(isTo = false, fromTime = 10): StandardDropdownChoice[] {
    const hours: StandardDropdownChoice[] = [];
    for (let i = 0; i <= (isTo ? 23 : 23 - this.emailSendingGapHour); i++) {
      const hour = utils.convertNumberToTwelveHourFormat(i);
      hours.push({
        value: i,
        label: hour,
        disabled: isTo ? (i >= fromTime + this.emailSendingGapHour ? false : true) : false,
      });
    }
    return hours;
  }

  async populateInfluencersWithDetails(isFirstTime = false): Promise<void> {
    const usernamesToBeSearched = this.existingInfluencerUsernames?.slice(
      this.loadedInfluencersIndex,
      this.loadedInfluencersIndex + this.existingInfluencersPaginationLimit,
    );
    if (usernamesToBeSearched?.length) {
      this.isLoadingInfluencers = true;

      this.influencerService
        .getInfluencersByUsernames(usernamesToBeSearched, this.platform, this.campaignSlugName)
        .then((influencers) => {
          this.selectedInfluencers = [...this.selectedInfluencers, ...influencers];
          this.copyOfSelectedInfluencers = cloneDeep(this.selectedInfluencers);

          this.loadedInfluencersIndex += usernamesToBeSearched?.length;
        })
        .catch(() => {
          this.toastrService.error('Failed to retrieve sequence influencers, please try again!');
        })
        .finally(() => {
          this.isLoadingInfluencers = false;
          if (isFirstTime) {
            this.isLoading = false;
          }
        });
    } else if (isFirstTime) {
      this.isLoading = false;
    }
  }

  closeModal(result?: any): void {
    this.activeModal.close(result);
  }

  changeSelection(): void {
    this.closeModal(false);
  }

  receiveUpdatedInfluencers(updatedInfluencerInfo: { influencers: InfluencerLookup[]; isValid: boolean }): void {
    if (this.isOnEditMode) {
      this.totalExistingInfluencers =
        this.existingInfluencerUsernames?.slice(this.loadedInfluencersIndex)?.length +
          updatedInfluencerInfo?.influencers?.length ?? 0;
    }
    this.selectedInfluencers = updatedInfluencerInfo.influencers;
    this.isInfluencerSelectionValid = updatedInfluencerInfo.isValid;
  }

  async toNextStep(): Promise<void> {
    this.isLoading = true;
    if (this.isOnEditMode) {
      this.emailSequenceService
        .updateEmailSequence(this.sequenceId, {
          ...(await this.convertFormEntriesToSequence()),
        })
        .then((updatedSequence) => {
          this.emailSequenceService.sendEmailSequenceUpsertedEvent({
            kind: 'SEQUENCE_EDITED',
            metadata: updatedSequence?.sequence,
          });
          this.closeModal();
        })
        .catch(() => {
          this.toastrService.error('Failed to edit email-sequence, please try again!');
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.emailSequenceService
        .createEmailSequence(await this.convertFormEntriesToSequence())
        .then((updatedSequence) => {
          this.emailSequenceService.sendEmailSequenceUpsertedEvent({
            kind: 'SEQUENCE_CREATED',
            metadata: updatedSequence?.sequence,
          });
          this.closeModal();
        })
        .catch(() => {
          this.toastrService.error('Failed to create email-sequence, please try again!');
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  get isNextDisabled(): boolean {
    return (
      !this.sequenceForm.valid ||
      !this.isInfluencerSelectionValid ||
      this.isLoading ||
      !!this.willBeTerminatedUsernames?.length ||
      this.isGetWillBeTerminatedUsernamesLoading
    );
  }

  async convertFormEntriesToSequence(): Promise<Partial<UpsertableSequence>> {
    const { name, timezone, whenToDeliver, fromTime, toTime } = this.sequenceForm.value;
    const terminationRule = this.isOnEditMode
      ? this.sequenceForm.controls?.['terminationRule']?.value
      : this.sequenceForm.value?.['terminationRule']; // TODO: move to the other form fields once the terminationRules is enabled in edit sequence modal

    const terminationRules: Sequence['terminationRules'] = [];
    if (terminationRule !== 'NEVER') {
      terminationRules.push({ kind: terminationRule });
    }
    const days = utils.getDaysListFromDeliveryDays(whenToDeliver);
    return {
      name,
      campaignSlugName: this.campaignSlugName,
      platform: this.platform.toUpperCase(),
      terminationRules,
      influencerUsernames: this.isOnEditMode
        ? [
            ...this.selectedInfluencers.map((influencer) => influencer.username),
            ...this.existingInfluencerUsernames.slice(this.loadedInfluencersIndex),
          ]
        : this.selectedInfluencers.map((influencer) => influencer.username) ?? [],
      timing: {
        timeZone: {
          title: this.timezones.find((tz) => tz.key === timezone)?.title ?? '',
          key: timezone,
        },
        days,
        fromTime: utils.getHourStringFromNumber(fromTime),
        toTime: utils.getHourStringFromNumber(toTime),
      },
    };
  }

  setFromValueFromBasicSequence(sequence: UpsertableSequence): void {
    const { fromTime, toTime } = utils.getFromAndToNumberFromHoursString(
      sequence.timing.fromTime,
      sequence.timing.toTime,
      this.emailSendingGapHour,
    );

    this.sequenceForm.setValue({
      name: sequence.name,
      terminationRule: sequence.terminationRules?.[0]?.kind ?? 'NEVER',
      timezone: sequence.timing.timeZone.key,
      whenToDeliver: utils.getDayDeliveryOptionsFromDays(sequence.timing.days),
      fromTime,
      toTime,
    });
  }

  get isOnEditMode(): boolean {
    return !!this.sequenceId;
  }
}
