import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from '../../@core/data/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-support-widget',
  templateUrl: './support-widget.component.html',
  styleUrls: ['./support-widget.component.scss'],
})
export class SupportWidgetComponent implements OnInit, OnDestroy {
  hideSupportButton = false;
  displaySupportMenu = false;
  supportOptions = [
    {
      icon: 'fa-regular fa-memo-circle-info',
      header: 'Explore Help Articles',
      body: `Discover comprehensive help articles in our centralized help center.`,
      link: `https://support.bazaarvoice.com/s/affable-help-center`,
    },
    // {
    //   icon: 'fa-regular fa-envelope',
    //   header: 'Email Support',
    //   body: `Click here to email us at
    //   <a class="af-link-text" href = "mailto: support@bazaarvoice.com?subject=Re:Affable Support">support@bazaarvoice.com</a>
    //   for assistance.`,
    //   link: `mailto: support@bazaarvoice.com?subject=Re:Affable Support`,
    // },
    {
      icon: 'fa-regular fa-users-rectangle',
      header: 'Visit Support Community',
      body: `Access or create support tickets in our support community.`,
      link: `https://support.bazaarvoice.com/s/?language=en_US`,
    },
  ];
  clickInside = false;

  @HostListener('document:click')
  onClickAnywhere(): void {
    if (!this.clickInside) {
      if (this.displaySupportMenu) this.displaySupportMenu = false;
    } else this.clickInside = false;
  }

  @HostListener('click')
  onClick(): void {
    this.clickInside = true;
  }

  routerSubscription: Subscription;
  userUpdateSubscription: Subscription;

  constructor(private userService: UserService, private router: Router) {}

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.userUpdateSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // * Hide support button on Collaboration-Portal pages
        this.hideSupportButton = this.router.url.includes('/collabs');
      });

    this.userUpdateSubscription = this.userService.userUpdated$.subscribe((updated) => {
      if (updated) {
        this.hideIntercom();
      }
    });
    this.hideIntercom();
  }

  hideIntercom(): void {
    window['Intercom']?.('update', {
      hide_default_launcher: true,
    });
  }

  onSupportClicked(): void {
    this.displaySupportMenu = !this.displaySupportMenu;
  }
}
