<div class="form-group">
    <label class="filter-label" *ngIf="displayLabel">Gender</label>
    <div class="form-row">
        <nb-checkbox [(ngModel)]="gender.male" (change)="triggerUpdateEvent()" class="col">
            Male
        </nb-checkbox>
        <nb-checkbox data-cy="female-checkbox-filter" [(ngModel)]="gender.female" (change)="triggerUpdateEvent()" class="col">
            Female
        </nb-checkbox>
    </div>
</div>
