<!-- This button triggers the overlay and is it's origin   -->
<div #trigger style="margin: 0; padding: 0" [ngSwitch]="type" (click)="displayOverlay()">
  <div *ngSwitchCase="'modern'" id="modern-drop-down" class="drop-down">
    <div class="leading">
      <ng-content select="[leading]"></ng-content>
    </div>
    <div class="title">
      <ng-content></ng-content>
    </div>
    <div class="trailing">
      <ng-content select="[trailing]"></ng-content>
    </div>
  </div>
  <ai-button *ngSwitchDefault [ngClass]="{'sort-class': type==='sort'}" [prefix]="prefix"
    [type]="buttonOptions?.type || 'icon'" [label]="buttonOptions?.label" [suffix]="suffix"
    [split]="buttonOptions?.split" [size]="buttonOptions?.size" [color]="buttonOptions?.color"
    [length]="buttonOptions?.length" [disabled]="buttonOptions?.disabled" [typography]="buttonOptions?.typography"
    [prefixImage]="buttonOptions?.prefixImage"
    style="display: block !important">
  </ai-button>
</div>
