<div *ngIf="!loading" class="notes-container">
  <!-- Influencer Metadata will only available on the profile page -->
  <ngx-influencer-metadata *ngIf="influencerMetadata" (editedMetadataEmitter)="updateMetadata($event)"
    [metadata]="influencerMetadata" [cancelMetaDataChanges]="cancelMetaDataChanges" [basicInfo]="basicInfluencerInfo"
    [platform]="platform"></ngx-influencer-metadata>
  <ngx-spinner *ngIf="loadingBasicInfoWithMetadata"></ngx-spinner>

  <!-- Add Note will only be available on the profile page and campaigns page (controlled by showAddNote) -->
  <ngx-add-note [isCollabPortal]="isCollabPortal" [isGroup]="page==='campaigns'||page==='groups'" *ngIf="showAddNote" [clearNote]="cancelChanges" (addNoteEvent)="addNote($event)"
    [isSingleUserOrg]="isSingleUserOrg"></ngx-add-note>
  <div *ngIf="showAddNote" class="v-separator"></div>

  <!-- Tabset for Shared and Private Notes for multi-user organizations -->
  <nb-tabset #tabset *ngIf="!isSingleUserOrg && !isCollabPortal">
    <nb-tab tabTitle="Shared ({{sharedNotes.length}})">
      <!-- List of Shared Notes -->
      <ngx-notes-list (editedNoteEventEmitter)="editNote($event)" (deletedNoteEventEmitter)="deleteNote($event)"
        [isCollabPortal]="isCollabPortal" 
        [isGroupsOrCampaign]="page==='campaigns'||page==='groups'"
        (downloadFileEventEmitter)="downloadFile($event)" [notes]="sharedNotes" [notesShown]="'shared'"
        [username]="username"></ngx-notes-list>
    </nb-tab>
    <nb-tab tabTitle="Private ({{privateNotes.length}})">
      <!-- List of Private Notes -->
      <ngx-notes-list (editedNoteEventEmitter)="editNote($event)" (deletedNoteEventEmitter)="deleteNote($event)"
        (downloadFileEventEmitter)="downloadFile($event)"
        [isCollabPortal]="isCollabPortal" 
        [isGroupsOrCampaign]="page==='campaigns'||page==='groups'"
        [notes]="privateNotes" [notesShown]="'private'"
        [username]="username" [isSingleUserOrg]="isSingleUserOrg"></ngx-notes-list>
    </nb-tab>
  </nb-tabset>

  <ngx-notes-list *ngIf="isCollabPortal"
  (editedNoteEventEmitter)="editNote($event)" (deletedNoteEventEmitter)="deleteNote($event)"
  [isCollabPortal]="isCollabPortal" 
  [isGroupsOrCampaign]="page==='campaigns'||page==='groups'"
  (downloadFileEventEmitter)="downloadFile($event)" [notes]="sharedNotes" [notesShown]="'shared'"
  [username]="username"></ngx-notes-list>
  <!-- Notes List for Single User Organizations -->
  <ngx-notes-list *ngIf="isSingleUserOrg" (editedNoteEventEmitter)="editNote($event)"
    (deletedNoteEventEmitter)="deleteNote($event)" (downloadFileEventEmitter)="downloadFile($event)" [notes]="allNotes"
    [isCollabPortal]="isCollabPortal" 
    [isGroupsOrCampaign]="page==='campaigns'||page==='groups'"
    [notesShown]="'all'" [username]="username" [isSingleUserOrg]="isSingleUserOrg"></ngx-notes-list>
</div>
<ngx-spinner *ngIf="loading"></ngx-spinner>