import { YoutubeInfluencerInfo } from './youtube.interface';
import { ClientUser } from './user.interface';
import { CampaignReport } from './campaign-report.interface';
import { Campaign, CampaignWithInfluencers } from '../@core/data/campaigns.service';
import { Cost, InfluencerMetadata } from '../@core/data/metadata.service';
import {
  CreatorMediaInsights,
  InfluencerType,
  Platform,
  Platforms,
  PlatformsV2,
  ProposalStatus,
  ProposalStatusAPI,
  SortDirection,
  SortStatus,
  Status,
  SubmittedContentStatus,
  SubmittedContentType,
} from '../enums';
import { FixedPay } from './campaign-workflow.interface';

export interface CampaignAggregateStats {
  totalInfluencers: number;
  totalEngagement: number;
  totalPosts: number;
  totalStories: number;
  totalBudget: number;
  totalEstimatedReach: number;
  totalEstimatedMediaValue: number;
  totalEstimatedImpression: number;
  totalLikes: number;
  totalComments: number;
  totalShopifySales?: string;
  totalPZSales?: string;
}

export interface ProposalStatusUpdate {
  influencerId: string;
  proposalRemarkByBrand?: string;
}

export interface ProposalStatusUpdateResponse {
  proposalStatus: ProposalStatusAPI;
  campaignSlugName: string;
  influencerId: string;
  proposalRemarkByBrand: string;
  fixedPay?: FixedPay;
}

export interface CampaignInfluencer {
  influencer: InfluencerInfo | YoutubeInfluencerInfo;
  influencerMetadata: InfluencerMetadata;
  selected: boolean;
  externalMappings?: Map<string, ExternalLink>;
  postCount?: number;
  storyCount?: number;
}

export interface CampaignContentInfluencer {
  pk: string;
  username: string;
  fullName: string;
  isPrivate: boolean;
  labels: string[];
  picture: string;
  selected: boolean;
  approvalStatus: string;
  lastUpdatedAt: number;
}

export interface SortingRule {
  username: SortStatus;
  followers: SortStatus;
  estimatedMediaValue: SortStatus;
  status: SortStatus;
}

export interface StatusesCount {
  status: Status;
  count: number;
}

export interface ProposalStatusesCount {
  proposalStatusAPI: ProposalStatusAPI;
  proposalStatus: ProposalStatus;
  count: number;
}

export interface InfluencerTypeCount {
  type: InfluencerType;
  count: number;
}

export function formatProposalStatus(statusAPI: string): ProposalStatus {
  switch (statusAPI) {
    case ProposalStatusAPI.submitted:
      return ProposalStatus.received;
    case ProposalStatusAPI.rejected:
      return ProposalStatus.declined;
    case ProposalStatusAPI.approved:
      return ProposalStatus.approved;
    case ProposalStatusAPI.seen:
      return ProposalStatus.seen;
    case ProposalStatusAPI.reinvited:
      return ProposalStatus.reinvited;
  }
}

export function formatProposalStatusAPItoStatus(status: ProposalStatusAPI): Status {
  const mapping: { [ProposalStatusAPI.approved]: Status; [ProposalStatusAPI.rejected]: Status } = {
    [ProposalStatusAPI.rejected]: Status.rejected,
    [ProposalStatusAPI.approved]: Status.confirmed,
  };

  // Ensure that the status is one of the allowed values
  if (!(status in mapping)) {
    throw new Error('Invalid ProposalStatusAPI value.');
  }

  return mapping[status];
}

export function formatProposalStatusUIToAPI(statusUI: ProposalStatus): ProposalStatusAPI {
  switch (statusUI) {
    case ProposalStatus.received:
      return ProposalStatusAPI.submitted;
    case ProposalStatus.declined:
      return ProposalStatusAPI.rejected;
    case ProposalStatus.approved:
      return ProposalStatusAPI.approved;
    case ProposalStatus.reinvited:
      return ProposalStatusAPI.reinvited;
    case ProposalStatus.seen:
      return ProposalStatusAPI.seen;
  }
}

export interface InfluencerInfo {
  id?: string;
  username: string;
  name: string;
  profilePictureUrl: string;
  biography: string;
  followers: number;
  engagementScore?: number;
  email?: string;
  estimatedMediaValue: number;
  salesGenerated?: SalesGeneratedWithCurrency; // PartnerizeOrderValue - sales generated
  linkClicks?: number;
  linksAge?: string;
  isRegistered?: boolean;
  locations?: string[];
}

export interface SalesGeneratedWithCurrency {
  amount: number;
  currency: string;
}

export interface ProposalStatusesCountResponse {
  proposalStatus: ProposalStatusAPI;
  count: number;
}

export interface CampaignInfluencersResponse {
  influencers: CampaignInfluencer[];
  hasMoreInfluencers: boolean;
  statusesCount?: StatusesCount[];
  proposalStatusesCount?: ProposalStatusesCountResponse[];
  influencerTypeCount?: InfluencerTypeCount[];
}

export interface CampaignContentInfluencersResponse {
  influencers: CampaignContentInfluencer[];
  hasMoreInfluencers: boolean;
  totalInfs: number;
  totalPendingInfs: number;
}

export interface CampaignsInfosResponse {
  campaigns: Campaign[];
  totalCount: number;
  hasMoreCampaigns: boolean;
  activeCampaignsCount: number;
}
export interface CampaignsInfosWithInfluencersResponse {
  campaigns: CampaignWithInfluencers[];
  totalCount: number;
  hasMoreCampaigns: boolean;
  activeCampaignsCount: number;
}
// Campaign content types

// Campaign Report types

export interface CampaignReportResponse {
  reports: CampaignReport[];
  total: number;
  hasMoreReports: boolean;
}

export interface PartnerizeCampaign {
  campaignId: string;
  title: string;
}

export interface PartnerizeCampaignPublisher {
  publisherId: string;
  publisherName: string;
}

export interface ExternalLink {
  name: string;
  id: string;
}

export interface Creator {
  name: string;
  email: string;
}

// Campaign Stories types

export interface CampaignStoriesResponse {
  stories: Story[];
  hasMoreStories: boolean;
  totalStoriesNumber: number;
}

export interface CampaignStoriesCountResponse {
  totalCampaignStories: number;
  influencers: {
    [key: string]: number;
  };
}

export interface Story {
  pk: number;
  influencer: InfluencerInfo;
  takenAt: number;
  pictureURL: string;
  estimatedMediaValue: number;
  estimatedReach: number;
  estimatedImpressions: number;
  videoURL: string;
  selected: boolean;
  isFlaggedByCopyright?: boolean;
  areInsightsVerified?: boolean;
}

export interface SelectedStory {
  influencerUsername: string;
  takenAt: number;
  pk: number;
}

export interface PartnerizeCampaignPublisherWithOrderValue extends PartnerizeCampaignPublisher {
  orderValue: number;
}

export interface PublishersStats {
  endTime: string;
  startTime: string;
  currency: string;
  publishers: PartnerizeCampaignPublisherWithOrderValue[];
}

export interface ClientOrganizationMembers {
  _id: string; // clientId
  email: string;
  organization: string; // Organization Id
  name?: string;
}

export interface AccountOrganizationMember {
  organizationName: string;
  userEmail: string;
  id: string;
  userId: string;
  organizationId: string;
  createdAt: Date;
  updatedAt?: Date;
}

export interface Sort {
  sortCriteria: string;
  sortOrder: SortDirection;
}

export interface CampaignInfluencerAssignee {
  _id: string;
  name?: string;
  email: string;
}

export interface CampaignLevelInfluencerMetadata {
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  assignee?: CampaignInfluencerAssignee;
  assignedShippingDetailsId?: string;
}

export interface UpdateInfluencerResponse {
  cost?: Cost[];
  platform: Platforms;
  shopifySales?: number;
  shopifyCurrency?: string;
  status: Status;
  metadata?: CampaignLevelInfluencerMetadata;
}

export interface Creator {
  name: string;
  email: string;
}

export interface ExternalLink {
  name: string;
  id: string;
}

export interface CampaignInfoStoreConfigurations {
  isEcommerceLinked: boolean;
  pricingRuleId: string;
  pricingRuleEnabled?: boolean;
}

export interface CampaignMinimal {
  name: string;
  campaignName?: string;
  id: string;
  createdAt: string;
  influencers?: {
    username: string;
    fullName: string;
    picture: string;
  }[];
}

export interface CreatorCampaign {
  id: string;
  name: string;
  slug: string;
  clientId: string;
  organization: string;
  mediaInsights: CreatorMediaInsights[];
}

export type CampaignsInfluencers = {
  [campaignSlugName: string]: {
    campaignInfo: Campaign;
    influencers: {
      username: string;
      pk: number;
      fullName: string;
      email: string;
      picture: string;
    }[];
  };
};

export interface CampaignStory {
  username: string;
  mentions: string[];
  hashtags: string[];
  publishedDate: number;
  platform: PlatformsV2;
}

export interface InfluencerSubmittedMedia {
  type: string;
  url: string;
}

export interface InfluencerSubmittedContent {
  mediaUrls: InfluencerSubmittedMedia[];
  isExternalLink: boolean;
  type?: SubmittedContentType;
  mediaCaption?: string;
  mediaTitle?: string;
  campaignSlugName: string;
  influencerId: string;
  platform: Platform;
  contentId: string;
  status: SubmittedContentStatus;
  version: number;
  createdAt: Date;
  approvedAt?: Date;
  updatedAt: Date;
}

export interface CommentResource {
  contentId: string;
  feedback: string;
}

export interface InfluencerContentResponse {
  content: InfluencerSubmittedContent;
  comment?: CommentResponse;
}
export interface Comment extends CommentResource {
  id?: string;
  client: string;
  createdAt: Date;
  updatedAt: Date;
  contentVersion: number;
}

export interface CommentResponse {
  comment?: Comment;
  clientInfo?: Partial<ClientUser>;
  message?: string;
}

export interface NewCommentResponse {
  comment: Comment;
  emailSent: boolean;
}
