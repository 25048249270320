import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ngx-tooltip',
  styleUrls: ['./tooltip.component.scss'],
  templateUrl: 'tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  @Input() tooltip: string;

  @Input() placement = 'bottom-right';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
