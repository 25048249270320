import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-audience-estimate-modal',
  templateUrl: './audience-estimate-modal.component.html',
  styleUrls: ['./audience-estimate-modal.component.scss'],
})
export class AudienceEstimateModalComponent {
  @Input() availableProfileUsernames: string[];
  @Input() inProgressProfileUsernames: string[];
  @Input() failedProfileUsernames: string[];
  showMoreLimit = 6;
  limit = {
    'In Progress': this.showMoreLimit,
    Failed: this.showMoreLimit,
    Available: this.showMoreLimit,
  };

  constructor(private activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.close();
  }

  retryFailed(): void {
    this.activeModal.close(this.failedProfileUsernames);
  }
}
