import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent {
  inputText = '';

  @Input() headerText: string;

  @Input() headerIconClass: string;

  @Input() contentText: string;

  @Input() centerContent? = true;

  @Input() contentTextList: string[];

  @Input() listItemClass: string;

  @Input() buttonText = 'confirm';

  @Input() buttonClass? = 'btn btn-xs btn-primary';

  @Input() imageURL = '';

  @Input() imageClass = '';

  @Input() cancelButton: boolean;

  @Input() showCloseOption = true;

  @Input() showInputText = false;

  constructor(private activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.close();
  }

  confirm(): void {
    this.activeModal.close(this.showInputText ? this.inputText : true);
  }
}
