import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageInfo } from '../../../interfaces';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LogicalFilterType } from '../../../enums';

@Component({
  selector: 'ngx-languages-filter',
  templateUrl: './language-filter.component.html',
  styleUrls: ['./language-filter.component.scss', '../styles.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(250)),
    ]),
  ],
})
export class LanguageFilterComponent {
  @Input() languages: Observable<LanguageInfo[]> | LanguageInfo[];
  @Input() selectedLanguages: LanguageInfo[];
  @Input() loadingLanguages: boolean;
  @Input() searchModifiers: LogicalFilterType;
  @Input() showLabel = true;

  @Output() searchModifiersChange: EventEmitter<LogicalFilterType> = new EventEmitter();

  @Output() triggerUpdate: EventEmitter<LanguageInfo[]> = new EventEmitter();

  triggerUpdateEvent(): void {
    this.triggerUpdate.emit(this.selectedLanguages);
  }

  toggleLanguageLogic(language: LanguageInfo): void {
    language.isIncluded = !language.isIncluded;
    this.triggerUpdateEvent();
  }

  updateSearchModifierEvent(): void {
    this.searchModifiersChange.emit(this.searchModifiers);
  }

  searchLanguages = (keyword: string, language: LanguageInfo): boolean => {
    return language.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
  };
}
