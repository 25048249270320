import { round } from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { GaugeOptions } from '../gauge/gauge.component';
import { ResourceType } from '../../@core/data/resource-access-control.service';
import { CollaborationInfluencerService } from '../../collaborator/influencer-profile-page/collaboration-influencer.service';
import { CollabInfluencerEngagement } from '../../interfaces';

@Component({
  selector: 'ngx-profile-engagement',
  templateUrl: './profile-engagement.component.html',
  styleUrls: ['./profile-engagement.component.scss'],
})
export class ProfileEngagementComponent implements OnInit {
  public totalEngagement: number;
  public engagement: CollabInfluencerEngagement;
  public avgEngagementGaugeOptions: GaugeOptions[] = [];

  @Input() resourceId: string;
  @Input() resourceType: ResourceType;
  @Input() influencerUsername: string;
  @Input() areInsightsVerified: boolean;
  @Input() tooltipMessages: Map<string, string>;

  constructor(private collaborationInfluencerService: CollaborationInfluencerService) {}

  async ngOnInit(): Promise<void> {
    this.engagement = await this.collaborationInfluencerService.fetchInfluencerEngagement(
      this.influencerUsername,
      this.resourceId,
      this.resourceType,
    );
    const avgTotalEngagementRatio = (this.engagement?.avgCommentsRatio ?? 0) + (this.engagement?.avgLikesRatio ?? 0);
    const avgTotalEngagement = avgTotalEngagementRatio * 100;
    this.totalEngagement = round(avgTotalEngagementRatio * (this.engagement?.followersCount ?? 0), 0);
    this.avgEngagementGaugeOptions.push({
      value: round(avgTotalEngagement, 2),
      badgeColor: this.getGaugeBadgeColorAndText(round(avgTotalEngagement, 0))[0],
      badgeText: this.getGaugeBadgeColorAndText(round(avgTotalEngagement, 0))[1],
    });
  }

  private getGaugeBadgeColorAndText(value: number): string[] {
    if (value < 1) {
      return ['DANGER', 'Poor'];
    }
    if (value >= 1 && value < 2.5) {
      return ['WARNING', 'Average'];
    }
    if (value >= 2.5 && value < 5) {
      return ['GOOD', 'Good'];
    }
    if (value >= 5) {
      return ['VERY_GOOD', 'Very Good'];
    }
    return ['#42db7d', ''];
  }
}
