<ng-container *ngIf="types.length">
  <div class="selector">
    <div #listSectionRef class="sections">
      <section *ngFor="let type of types;let i=index">
        <ng-template *ngTemplateOutlet="templateList; context: {$implicit: type,i:i}">
        </ng-template>
      </section>
    </div>
  </div>
  <div class="template">
    <div class="detail">
      <input type="text" class="bold" [(ngModel)]=" isDeleteMode ?deleteConfirmText:name"
        [readonly]="isDeleteMode || !isEditMode">

      <div class="action">
        <ng-container
          *ngTemplateOutlet="(isEditMode && editModeTemp) || (isDeleteMode && deleteModeTemp)|| nonEditingTemp">
        </ng-container>

        <ng-template #editModeTemp>
          <ai-button size="small" type="text" label="CANCEL" (click)="cancel()"></ai-button>
          <ai-button size="small" [disabled]="isSavingEnabled" label="SAVE" (click)="save()"></ai-button>
        </ng-template>
        <ng-template #deleteModeTemp>
          <ai-button size="small" type="text" label="NO" (click)="isDeleteMode = false"></ai-button>
          <ai-button size="small" label="YES" (click)="delete()"></ai-button>
        </ng-template>
        <ng-template #nonEditingTemp>
          <ai-button size="small" type="text" label="DELETE" *ngIf="allowDelete" (click)="isDeleteMode = true">
          </ai-button>
          <ai-button size="small" type="text" label="EDIT"
            (click)="currentTypeIndex?isEditMode = true:createNew({subject:this.subject,template:this.template})">
          </ai-button>
        </ng-template>
      </div>
    </div>
    <div class="subject">
      Sub: <input type="text" [(ngModel)]="subject" [readonly]="!isEditMode">
    </div>
    <ng-container *ngIf="isEditMode; else viewMessage">
      <ai-ckeditor
        #ckeditorRef
        [supportedTemplatingVariables]="supportedTemplatingVariables"
        [defaultTemplate]="template"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="template"
        [apiPath]="apiPath">
      </ai-ckeditor>
    </ng-container>
    <ng-template #viewMessage>
      <div class="message-body" [innerHTML]="template | highlightVariable"></div>
    </ng-template>
  </div>
</ng-container>

<ng-template #templateList let-section let-index="i">
  <div class="header">
    <h3>{{section.type === 'suggested'?'Suggested templates by Affable':'Templates created by your organization'}}
      ({{section.templates?.length}})</h3>

  </div>
  <nb-list>
    <nb-list-item *ngFor="let item of section.templates | callback: searchText?filter.bind(this):null;let i = index;"
      [class.selected]="item.selected" [class.not-edited]="isEditMode ||isDeleteMode" (click)="select(index,item.i)">
      {{item.name}}
    </nb-list-item>
  </nb-list>
</ng-template>
