import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Campaign } from '../@core/data/campaigns.service';
import { CreatorMediaInsights } from '../enums';
import { CampaignMinimal, CreatorCampaign, RefreshInsightsResponse } from '../interfaces';

@Injectable()
export class CreatorCampaignService {
  exportingFlag = false;
  constructor(private http: HttpClient) {}

  addMediaToCampaign(org: string, slug: string, media: CreatorMediaInsights): Promise<AddMediaToCampaignApiResponse> {
    return this.http
      .post(`${environment.api}/api/creators/campaigns/${encodeURIComponent(org)}/${slug}/media`, {
        ...media,
      })
      .toPromise() as Promise<AddMediaToCampaignApiResponse>;
  }

  getInsightsCampaignReports(): Promise<Campaign[]> {
    return this.http.get(`${environment.api}/api/creators/campaigns`).toPromise() as Promise<Campaign[]>;
  }

  getInsightsCampaignReport(id: string): Promise<CreatorCampaign> {
    return this.http.get(`${environment.api}/api/creators/campaigns/${id}`).toPromise() as Promise<CreatorCampaign>;
  }

  createReport(reportName: string): Promise<CampaignMinimal> {
    return this.http
      .post<CampaignMinimal>(`${environment.api}/api/creators/campaigns`, {
        reportName: reportName,
      })
      .toPromise();
  }

  exportInsightsReport(id: string): Promise<any> {
    return this.http
      .get(`${environment.api}/api/creators/campaigns/export/${id}`, {
        responseType: 'blob',
      })
      .toPromise();
  }
  async exportInsightsReportAsPPT(id: string): Promise<any> {
    return this.http
      .get(`${environment.api}/api/creators/campaigns/export-ppt/${id}`, {
        responseType: 'blob',
      })
      .toPromise();
  }

  refreshInsights(id: string): Promise<RefreshInsightsResponse> {
    return this.http
      .post<RefreshInsightsResponse>(`${environment.api}/api/creators/campaigns/${id}/update-insights`, {})
      .toPromise();
  }

  async saveCreatorAccount(accessToken: string): Promise<void> {
    await this.http
      .post(`${environment.api}/api/creators/facebook/register-account`, {
        accessToken,
      })
      .toPromise();
  }
}

export class AddMediaToCampaignApiResponse {
  msg: string;
}
