import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class NotLiteGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  // blocks access if the user isLite property set to true (search only user)
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLite = this.auth.isLiteUser();
    if (isLite) {
      console.error('lite guard rejected access to ' + state.url);
      this.router.navigate(['pages', 'dashboard']);
    }
    return !isLite;
  }
}
