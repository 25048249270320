import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { YoutubeContentSortKey } from '../../../enums';
import { YoutubeContentSearchResponse } from '../../../interfaces';
import { YoutubeContentFilters } from '../content-filter.service';

@Injectable()
export class YoutubeContentService {
  constructor(private http: HttpClient) {}

  searchContent(
    filters: YoutubeContentFilters,
    page: number,
    allContent = false,
  ): Promise<YoutubeContentSearchResponse> {
    return this.http
      .post<YoutubeContentSearchResponse>(`${environment.api}/api/youtube/content/`, filters, {
        params: {
          page: page.toString(),
          allContent: allContent.toString(),
        },
      })
      .toPromise();
  }

  getTopContent(location: string, sortKey: YoutubeContentSortKey): Promise<YoutubeContentSearchResponse> {
    return this.http
      .get<YoutubeContentSearchResponse>(`${environment.api}/api/youtube/content/top`, {
        params: { location, sortKey },
      })
      .toPromise();
  }

  exportMediaReport(contentIds: string[], campaignSlugName?: string): Promise<Blob> {
    return this.http
      .post(
        `${environment.api}/api/youtube/content/export/search`,
        {
          contentIds: contentIds,
          campaignSlugName,
        },
        { responseType: 'blob' },
      )
      .toPromise();
  }
}
