import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { ThemeModule } from '../../@theme/theme.module';
import { SharedModule } from '../../shared/shared.module';
import { CampaignReportsComponent } from './campaign-reports.component';
import { CommentSnapshotsModalComponent } from './reports/comment-snapshots/comment-snapshots-modal/comment-snapshots-modal.component';
import { CommentSnapshotsComponent } from './reports/comment-snapshots/comment-snapshots.component';
import { MediaInsightsComponent } from './reports/media-insights/media-insights.component';
import { ReportCommentAnalysisComponent } from './reports/report-comment-analysis/report-comment-analysis.component';
import { ReportsComponent } from './reports/reports.component';
import { AnalysisPlaceholderComponent } from './reports/analysis-placeholder/analysis-placeholder.component';

@NgModule({
  imports: [CommonModule, ThemeModule, MomentModule, SharedModule, InfiniteScrollModule, LazyLoadImageModule],
  declarations: [
    CampaignReportsComponent,
    ReportsComponent,
    CommentSnapshotsComponent,
    CommentSnapshotsModalComponent,
    MediaInsightsComponent,
    ReportCommentAnalysisComponent,
    AnalysisPlaceholderComponent,
  ],
  exports: [CommentSnapshotsComponent],
})
export class CampaignReportsModule {}
