import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgProgress } from 'ngx-progressbar';
import { Tweet, TweetAuthor, TwitterProfile } from '../../interfaces';

@Component({
  selector: 'ngx-tweet-details-modal',
  templateUrl: './tweet-details-modal.component.html',
  styleUrls: ['./tweet-details-modal.component.scss'],
})
export class TweetDetailsModalComponent implements OnInit {
  @Input() tweet: Tweet;
  @Input() isMediaPost: boolean;
  @Input() profile: TwitterProfile | TweetAuthor;
  @Input() currency: string;
  @Input() hideStats = false;

  constructor(private activeModal: NgbActiveModal, public progress: NgProgress) {}

  ngOnInit(): void {
    this.progress.ref().start();
  }

  closeModal(): void {
    this.activeModal.close();
  }

  getTwitterURL(): string {
    return 'https://twitter.com/' + this.profile.username + '/status/' + this.tweet.id;
  }

  openTweet(): void {
    window.open(this.getTwitterURL(), '_blank');
  }
}
