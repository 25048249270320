import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SubscribedClientUser, SubscriptionPlan, SubscriptionStatus } from '../../enums';
import { UserService } from './user.service';

/**
 * @deprecated
 */
@Injectable()
export class SubscriptionService {
  constructor(private http: HttpClient, private userService: UserService) {}

  /**
   * @deprecated
   */
  saveSubscription(stripeToken: string, plan: SubscriptionPlan): Promise<void> {
    return this.http
      .post<void>(`${environment.api}/api/payment`, {
        stripeToken,
        plan,
      })
      .toPromise();
  }

  resumeSubscription(): Promise<void> {
    return this.http.post<void>(`${environment.api}/api/payment/resume`, {}).toPromise();
  }

  cancelSubscription(): Promise<void> {
    return this.http.delete<void>(`${environment.api}/api/payment`, {}).toPromise();
  }

  async isFreeUser(clientUser?: SubscribedClientUser): Promise<boolean> {
    if (clientUser === undefined) {
      clientUser = await this.userService.getClientUser();
    }

    return clientUser?.app_metadata.stripePlan === 'AFFABLE_FREE';
  }

  async isProUser(clientUser?: SubscribedClientUser): Promise<boolean> {
    if (clientUser === undefined) {
      clientUser = await this.userService.getClientUser();
    }

    return Boolean(
      clientUser?.app_metadata.stripePlan === 'AFFABLE_PRO_MONTHLY' ||
        clientUser?.app_metadata.stripePlan === 'AFFABLE_PRO_OFFLINE',
    );
  }

  async isSubscribedUser(clientUser?: SubscribedClientUser): Promise<boolean> {
    if (clientUser === undefined) {
      clientUser = await this.userService.getClientUser();
    }
    return !!clientUser?.app_metadata?.stripePlan;
  }

  async getSubscriptionStatus(clientUser?: SubscribedClientUser): Promise<SubscriptionStatus> {
    if (clientUser === undefined) {
      clientUser = await this.userService.getClientUser();
    }

    const isValidSubscription = await this.isSubscribedUser(clientUser);

    // TODO: CHECK CANCEL_ON_PERIOD_END

    if (isValidSubscription) {
      // - The client is under a valid subscription
      // - The client can set the subscription to cancel on period end
      return 'SUBSCRIBED';
    }

    // - The client doesn't have a valid subscription
    // - The client has no credits
    // - The client can subscribe to a plan
    // - The client can top-up credits
    return 'NONE';
  }

  async getSubscriptionPlan(clientUser?: SubscribedClientUser): Promise<SubscriptionPlan | string> {
    if (clientUser === undefined) {
      clientUser = await this.userService.getClientUser();
    }
    return clientUser?.app_metadata?.stripePlan;
  }
}
