<div class="customizer-container">
    <header>
        <h2 class="heading-h2">Customize Table</h2>
        <i class="close fa-regular fa-xmark" (click)="close()"></i>
    </header>
    <section>
        <div class="left">
            <form class="search">
                <ai-input placeholder="Search column" name="searchText" [(ngModel)]="searchText"></ai-input>
            </form>
            <ul>
                <form>
                    <li *ngFor="let col of cols; index as i">
                        <ai-checkbox [disabled]="col.locked" [customClasses]="col.locked?'locked':''"
                            [ngModelOptions]="{standalone: true}" [ngModel]="!col.hide"
                            (ngModelChange)="col.hide = !$event;toggleSelected(i,!$event)"></ai-checkbox>
                        <span class="body-2">{{col.name}}</span>
                    </li>
                </form>
            </ul>
        </div>
        <div class="seperator"></div>
        <div class="right">
            <h2 class="sub-heading-h2">Selected Columns (7)</h2>
            <p class="body-4">Rearrange properties</p>
            <ul cdkDropList  (cdkDropListDropped)="drop($event)"> 
                <li *ngFor="let col of cols | callback: hideCols" cdkDrag> 
                    <i class="prefix fa-regular fa-grip-dots-vertical"></i>
                    <span class="body-2">{{col.name}}</span>
                </li>
            </ul>
        </div>
    </section>
    <footer>
        <ai-button label="NEW COLUMN" type="outline" prefix="fa-regular fa-plus"></ai-button>
        <ai-button label="APPLY SETTINGS" prefix="true" (click)="saveColumns()"></ai-button>
    </footer>
</div>