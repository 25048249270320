import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { IgEmbedService } from './igembed.service';

/***
 * Use this component to Embed IG Images in the app
 * NOTE: We rely on the blockquote to be in the DOM
 * by the time ngAfterViewInit is called.
 *
 * For ex: if we are in a NB Tabs context, the DOM won't actually be in the DOM so the script will fail
 * Long term fix is to use something like ng-in-viewport
 * (too heavy for our usecase, it installs selemium :eyeroll: )
 *
 * If you only see a placeholder,
 * make sure that instgrm.Embeds.process() is called!
 * check campaigns.component.ts
 */
@Component({
  selector: 'ngx-ig-embed',
  templateUrl: './igembed.component.html',
  styleUrls: ['./igembed.component.scss'],
})
export class IgEmbedComponent implements OnInit, AfterViewInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() content: any;
  @Input() code?: string;
  internalUrl: string;

  constructor(private embedService: IgEmbedService) {}

  ngAfterViewInit(): void {
    this.embedService.detectEmbed.next();
  }

  ngOnInit(): void {
    const code = this.code || this.extractCode();
    this.internalUrl = `https://www.instagram.com/p/${code}/?utm_source=ig_embed&utm_campaign=loading`;
  }

  extractCode(): string {
    if (!this.content) {
      return undefined;
    }
    if (typeof this.content !== 'string') {
      return new URL(this.content.media.urlLarge).pathname.split('/')[2];
    } else {
      const hostname = new URL(this.content).hostname;
      if (hostname !== 'instagram.com' && hostname !== 'www.instagram.com') {
        return undefined;
      }
      return this.content.split('/')[4];
    }
  }
}
