<div class="modal-header">
    <h6>Send Reminder</h6>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <span class="total-influencer">Total influencers - {{ influencers?.length }}</span>
    <div class="influencer-container">
        <table>
            <tbody>
                <tr class="influencer" *ngFor="let influencer of influencers">
                    <td align="left">
                        <nb-user [name]="influencer.influencer.name" [picture]="influencer.influencer.profilePictureUrl"
                            [title]="influencer.influencer.username" size="small">
                        </nb-user>
                    </td>
                    <td align="right">
                        <span class="delivered-text">Delivered {{ influencer.influencerMetadata.latestOrderDeliveredAt }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="modal-footer">
    <button class="af-outline btn btn-outline-primary btn-xs" (click)="sendReminder()">
        Send a reminder
    </button>
</div>