<div class="influencer-info-container" style="flex-direction: row;">
  <div class="influencer-info" *ngIf="socialMediaProfiles?.instagram"
       (click)="openUrl(socialMediaProfiles.instagram.accountLink)" style="width: unset">
                <span container="body" [ngbPopover]="tooltipMessages.get('open-instagram-page')"
                      placement="top-right" triggers="hover">
                      <img class="platform-icon" src="../../../assets/images/platforms/social-links/Instagram_Glyph_Black.png">
                </span>
    <span *ngIf='printable'>
      {{socialMediaProfiles.instagram.accountLink}}
    </span>
  </div>
  <div class="influencer-info" *ngIf="socialMediaProfiles?.youtube" (click)="openUrl(socialMediaProfiles.youtube.accountLink)"
       style="width: unset">
                <span container="body" [ngbPopover]="tooltipMessages.get('open-youtube-page')"
                      placement="top-right" triggers="hover">
                      <img class="platform-icon" src="../../../assets/images/platforms/social-links/youtube_social_icon_dark.png">
                </span>
  </div>
  <div class="influencer-info" *ngIf="socialMediaProfiles?.facebook"
       (click)="openUrl(socialMediaProfiles.facebook.accountLink)" style="width: unset">
                <span container="body" [ngbPopover]="tooltipMessages.get('open-facebook-page')"
                      placement="top-right" triggers="hover">
                <i class="ion-logo-facebook" aria-hidden="true"></i>
                  </span>
  </div>
  <div class="influencer-info" *ngIf="socialMediaProfiles?.tiktok"
       (click)="openUrl(socialMediaProfiles.tiktok.accountLink)" style="width: unset">
                <span container="body" [ngbPopover]="tooltipMessages.get('open-tiktok-page')"
                      placement="top-right" triggers="hover">
                  <img class="platform-icon" src="../../../assets/images/platforms/social-links/tiktok-icon.png">
                </span>
  </div>
  <div class="influencer-info" *ngIf="socialMediaProfiles?.twitter"
       (click)="openUrl(socialMediaProfiles.twitter.accountLink)" style="width: unset">
                <span container="body" [ngbPopover]="tooltipMessages.get('open-twitter-page')"
                      placement="top-right" triggers="hover">
                      <img class="platform-icon" src="../../../assets/images/platforms/social-links/x-logo-black.png">
                </span>
  </div>
</div>
