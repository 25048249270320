<div class="date-container">
    <ai-dropdown [options]="simpleOptionsFMultipleSelect" (action)="calendeMenuChanged($event)" [multi]="false"
    [type]="'modern'" data-cy="date-container">
    <i class="fa-regular fa-calendar-lines" leading></i>
    <span>{{defaultCalender}}</span>
    <i class="fa-regular fa-chevron-down" trailing></i>
</ai-dropdown>
<div class="date-label af-body-2" *ngIf="defaultCalender!==calenderEnum.customDate && displayTimeStamp">
  From {{timeFilter.from.toUTCString().slice(0,16)}} to {{timeFilter.to.toUTCString().slice(0,16)}}
</div>
<ngx-date-range *ngIf="defaultCalender === calenderEnum.customDate" [@fadeInOut] type="mordern" [timeFilter]="timeFilter"
    (valueChange)="datesChangeUpdate()" fromPlaceHolder="- - -  - -, - - - -" toPlaceHolder="- - -  - -, - - - -"
    dateFormat="mmm dd, yyyy" [useUTCFormat]="true">
</ngx-date-range>
</div>
