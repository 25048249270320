import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {
  @Input() infoOptions: InfoOptions;

  @Input() compact: boolean;

  @Input() inlineInfo: boolean;

  hasPercentage(): boolean {
    return this.infoOptions.percentage !== undefined;
  }
}

export class InfoOptions {
  iconClassName: string;
  title: string;
  description: number;
  percentage?: number;
}
