import { Component, Input, OnInit } from '@angular/core';
import { AiTableConfig } from '../../helpers';
import { OverlayRef } from '@angular/cdk/overlay';
import { ColumnCustomization } from '../../common.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ai-table-customizer',
  templateUrl: 'table-customizer.control.html',
  styleUrls: ['table-customizer.control.scss'],
})
export class TableCustomizerControl implements OnInit {
  @Input() dataSource: AiTableConfig<unknown>;
  @Input() overlayRef: OverlayRef;
  cols = [];
  searchText: string;

  filterCols(columns: Array<Partial<ColumnCustomization>>) {
    return columns
      .filter(({ hide, property }) => !hide && !['selected', 'actions'].includes(property))
      .map((a) => ({ ...a }));
  }

  ngOnInit(): void {
    this.cols = this.filterCols(this.dataSource?.table?.columns);
  }

  toggleSelected(index: number, value: boolean) {
    (this.cols[index] as ColumnCustomization).hide = value;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.cols, event.previousIndex, event.currentIndex);
  }

  hideCols(column: Partial<ColumnCustomization>) {
    return !column?.hide;
  }

  close() {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }

  saveColumns() {
    //TODO:: PATCH API call here with this.cols
  }
}
