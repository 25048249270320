import moment from 'moment';
import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Currency, PlatformsUpperEnum, PlatformsV2, SubscribedClientUser } from '../../enums';
import { InfluencerProfileCard } from '../influencer-profile-card/influencer-profile-card.component';
import { TableComponent } from '../table/table.component';
import { GroupInfluencer, GroupInfluencers, StandardInfluencer } from '../../@core/data/group.service';
import { isEmpty, isUndefined, map } from 'lodash';
import { NbSidebarService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { DropDownOption } from '../../../../../controls/src';
import {
  GroupWithRac,
  InfluencerGroupHelperService,
  getInfluencerProfileCardInfo,
  transformToStandardInfluencer,
} from './influencer-group.helper.service';
import { DropdownTriggerForDirective } from '../../../../../controls/src/template-dropdown/trigger.directive';
import { tabEnum } from '../add-to/add-to.component';
import utils from '../../@core/utils/utils';
import { InfluencerLookup } from '../../interfaces';
import { DropDownAction, Value } from '../../../../../controls/src/common.enum';
import { UserService } from '../../@core/data/user.service';
import { FormControl } from '@angular/forms';
import { GroupStatsComponent } from './group-stats/group-stats.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ngx-influencer-group',
  templateUrl: './influencer-group.component.html',
  styleUrls: ['./influencer-group.component.scss'],
})
export class InfluencerGroupComponent implements OnInit {
  @Input() platform: PlatformsUpperEnum;
  @ViewChild('influencerTable', { static: false }) influencerTable: TableComponent;
  @ViewChildren(DropdownTriggerForDirective) addToButton: QueryList<DropdownTriggerForDirective>;
  @ViewChild('groupStats', { static: false }) groupStats: GroupStatsComponent;
  readonly tabEnum = tabEnum;
  search = new FormControl('');
  overlayLoadingFlag = false;

  queryObject: {
    slugName: string;
    platform?: PlatformsUpperEnum;
    page: number;
    limit: number;
    filter?: Record<string, string[]>;
    search?: string;
    sortBy?: 'influencer' | 'followers' | 'engagementRatio';
    sortDirection?: 'asc' | 'desc';
  } = {
    slugName: '',
    platform: undefined,
    page: 0,
    limit: 15,
    search: '',
    sortBy: 'engagementRatio',
    sortDirection: 'desc',
  };

  loading = false;
  paginationLoader = false;
  currency: string = Currency.USD;
  selectedInfluencers: StandardInfluencer[] = [];
  groupInfo: GroupWithRac;
  client: SubscribedClientUser;
  groupInfluencers: { data: GroupInfluencers; total: number } = { data: [], total: 0 };

  showAllNotesSidebar = false;
  showInfluencerNoteSidebar = false;
  sidePanelInfluencer: StandardInfluencer;
  allGroupInfluencersSelected = false;

  activeGroupSlugNameSubscription: Subscription;

  listenSearch(): void {
    this.search.valueChanges.pipe(debounceTime(300)).subscribe(this.searchInfluencers.bind(this));
  }

  get selectedRowsCount(): number {
    return this.selectedRows.length || 0;
  }

  get selectedRows() {
    return this.influencerTable?.cachedSelectedRows || [];
  }

  updateSelectedInfluencers(): void {
    this.selectedInfluencers = this.selectedRows.map((influencer) =>
      transformToStandardInfluencer(influencer, this.platform),
    );
  }

  get showGroupTags(): boolean {
    return Boolean(this.groupInfo?.tags?.length);
  }

  get hasMoreData(): boolean {
    return this.groupInfluencers?.total > (this.queryObject.page || 1) * (this.queryObject.limit || 1);
  }
  get activeGroupSlugName(): string | null {
    return isEmpty(this.queryObject.slugName) ? null : this.queryObject.slugName;
  }

  get groupExtendedTags(): string {
    return [...(this.groupInfo.tags ?? [])].splice(2, 3).join(', ');
  }

  get groupExcludedInfluenersLength(): number {
    return (
      (this.groupInfo?.excludedInfluencerUsernames ? this.groupInfo.excludedInfluencerUsernames.length : 0) +
      (this.groupInfo?.influencersToBeProfiled ? this.groupInfo.influencersToBeProfiled.length : 0)
    );
  }

  get groupLastUpdateBy(): string | void {
    return this.groupInfo?.updatedBy || this.groupInfo?.ownerEmail;
  }

  get groupLastUpdateAt(): string | void {
    return moment(this.groupInfo?.updatedAt || this.groupInfo?.createdAt || this.groupInfo?.timestamp).format(
      'MMM D, YYYY',
    );
  }

  get groupSharedWith(): string[] {
    return [
      ...(this.groupInfo?.sharedWithNames || []).map((name) => `${this.client.company}:${name}`),
      ...(this.groupInfo?.rac || []).map((rac) => `${rac?.consumerTeam?.name}:${rac?.consumer?.value}`),
    ];
  }

  get estimateAudienceInsightsTooltip(): string {
    return `Trigger audience estimation for all selected influencers to view estimated audience insight on their profile pages.`;
  }

  get isSendMailDisabled(): boolean {
    return !this.selectedRowsCount;
  }

  get isEstimateAudienceInsightsVisible(): boolean {
    return [PlatformsUpperEnum.INSTAGRAM, PlatformsUpperEnum.VERAINSTAGRAM].includes(this.platform);
  }

  get platformV2(): PlatformsV2 {
    return utils.convertToPlatformV2(this.platform);
  }

  get getSharedWithTooltip(): string {
    return this.groupInfo.sharedWith?.slice(3).join('\n') ?? '';
  }

  get isDeleteDisabled(): boolean {
    return this.groupInfluencers?.data?.length === 1;
  }

  dropDownOptions: DropDownOption = {
    groupOptions: [
      {
        items: [
          {
            key: ContextMenu.viewAllNotes,
            value: false,
            disabled: false,
          },
          {
            key: ContextMenu.deleteGroup,
            value: false,
            disabled: false,
          },
        ],
        selector: 'none',
      },
    ],
  };
  groupActionDropDownOptions: DropDownOption = {
    groupOptions: [
      {
        items: [
          {
            key: ContextMenu.export,
            value: false,
            disabled: true,
          },
          {
            key: ContextMenu.delete,
            value: false,
            disabled: true,
          },
        ],
        selector: 'none',
      },
    ],
    header: 'Select influencers to apply the below actions.',
  };

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private sidebarService: NbSidebarService,
    private toasterService: ToastrService,
    private helperService: InfluencerGroupHelperService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.currency = (await this.userService.getLinkedCurrency()) ?? this.currency;
    this.addEstimateAudienceInsightsOption();
    this.subscribeToQueryParams();
    this.fetchClient();
    this.listenSearch();
  }

  async fetchClient(): Promise<void> {
    this.client = await this.userService.getClientUser();
  }

  reviewStatusDropdownOptions: DropDownOption = {
    groupOptions: [
      {
        selector: 'checkmark',
        items: [
          { displayName: 'Not Reviewed', key: 'PENDING', value: false },
          { displayName: 'Approved', key: 'APPROVED', value: false },
          { displayName: 'Rejected', key: 'REJECTED', value: false },
        ],
      },
    ],
  };

  ngOnDestroy(): void {
    this.activeGroupSlugNameSubscription?.unsubscribe();
  }

  get isGroupSharedWithCollaborator(): boolean {
    return !!this.groupInfo?.rac?.length;
  }

  influencerEmailSetter({ username, email }: InfluencerLookup): void {
    const selectedInfluencer =
      email && username && this.selectedRows.find((selected) => selected.username === username);
    if (selectedInfluencer) {
      selectedInfluencer.email = email;
    }
  }

  get isEmptyState(): boolean {
    return (
      !this.isLoading &&
      this.groupInfluencers.total === 0 &&
      isEmpty(this.queryObject.filter) &&
      isEmpty(this.queryObject.search) &&
      !this.paginationLoader
    );
  }

  get isLoading(): boolean {
    return isEmpty(this.queryObject.filter) && this.loading;
  }

  platformCanBeShared(): boolean {
    return (
      this.platform === 'INSTAGRAM' ||
      this.platform === 'VERAINSTAGRAM' ||
      this.platform === 'TIKTOK' ||
      this.platform === 'YOUTUBE'
    );
  }

  resetQueryObject(): void {
    this.search.setValue('');
    this.resetStatusFilter();
    this.queryObject = {
      slugName: '',
      platform: this.platform,
      page: 0,
      limit: 15,
      search: '',
      filter: {},
      sortBy: 'engagementRatio',
      sortDirection: 'desc',
    };
  }

  resetStatusFilter(): void {
    this.reviewStatusDropdownOptions.groupOptions[0].items.forEach((item) => (item.value = false));
  }

  sortData(sortBy: 'influencer' | 'followers' | 'engagementRatio'): void {
    this.queryObject.sortBy = sortBy;
    this.queryObject.sortDirection = this.queryObject.sortDirection === 'asc' ? 'desc' : 'asc';
    this.overlayLoadingFlag = true;
    this.queryObject.page = 0;
    this.getInfluencers('overWrite');
  }

  searchInfluencers(search: string): void {
    this.queryObject.search = search;
    this.overlayLoadingFlag = true;
    this.queryObject.page = 0;
    this.getInfluencers('overWrite');
  }

  async loadNextPage(): Promise<void> {
    if (!this.hasMoreData) {
      return;
    }
    this.paginationLoader = true;
    this.queryObject.page = (this.queryObject.page ?? 0) + 1;
    await this.getInfluencers();
    this.updateSelectedInfluencers();
  }

  subscribeToQueryParams(): void {
    this.activeGroupSlugNameSubscription = this.route.queryParams.subscribe((params) => {
      const groupSlugName = params['group'] || '';
      if (this.queryObject.slugName !== groupSlugName) {
        this.resetQueryObject();
        this.queryObject.slugName = groupSlugName;
        this.groupInfluencers = { data: [], total: 0 };
        this.loading = true;
        this.loadData();
      } else {
        this.loading = false;
      }
    });
  }

  async statusFilterUpdated(event: DropDownAction): Promise<void> {
    const reviewedStatus: string[] =
      event.groupOptions[0].items?.filter((i) => !!i.value).map((i) => i.key as string) || [];
    this.queryObject.filter = { reviewedStatus };
    this.queryObject.page = 0;
    this.overlayLoadingFlag = true;
    await this.getInfluencers('overWrite');
  }

  async loadData(): Promise<void> {
    try {
      this.loading = true;
      await Promise.all([this.getGroupInfo(), this.getInfluencers('overWrite')]);
    } catch (error) {
      console.error('Error while loading data:', error);
    } finally {
      this.loading = false;
      this.paginationLoader = false;
      this.allGroupInfluencersSelected = false;
      this.updateDropdownOptions();
    }
  }

  async getGroupInfo(): Promise<void> {
    try {
      if (isEmpty(this.queryObject.slugName)) {
        return;
      }

      const groupInfo = await this.helperService.getGroup(this.queryObject.slugName, this.platform);
      if (groupInfo) {
        this.groupInfo = groupInfo;
      }
    } catch (e) {
      console.error(`Error meanwhile getting groupInfo: ${e}`);
    }
  }

  async getInfluencers(type: 'concat' | 'overWrite' = 'concat'): Promise<void> {
    try {
      if (isEmpty(this.queryObject.slugName)) {
        return;
      }
      const result = await this.helperService.getGroupInfluencers(
        this.queryObject.slugName,
        this.platform,
        this.queryObject.page,
        this.queryObject.limit,
        this.queryObject.filter,
        isUndefined(this.queryObject.search) ? '' : this.queryObject.search,
        isUndefined(this.queryObject.sortBy) ? 'engagementRatio' : this.queryObject.sortBy,
        isUndefined(this.queryObject.sortDirection) ? 'desc' : this.queryObject.sortDirection,
      );

      this.groupInfluencers.data =
        type === 'overWrite' ? result.data : ([...this.groupInfluencers.data, ...result.data] as GroupInfluencers);

      this.groupInfluencers.total = result.totalCount;
    } catch (error) {
      console.error('Error while getting influencers:', error);
    } finally {
      this.loading = false;
      this.paginationLoader = false;
      this.overlayLoadingFlag = false;
    }
  }

  loadFullTableDataEvent() {
    this.influencerTable.setTableSelection(true);
    this.overlayLoadingFlag = false;
  }

  contextMenuUpdate($event): void {
    const lastAction = $event?.lastAction?.key;
    switch (lastAction) {
      case ContextMenu.viewAllNotes:
        this.viewAllNotes();
        break;
      case ContextMenu.deleteGroup:
        this.deleteGroup();
        break;
      case ContextMenu.estimateAudienceInsights:
        this.estimateAudienceInsights();
        break;
      case ContextMenu.export:
        this.exportInfluencers();
        break;
      case ContextMenu.delete:
        this.deleteInfluencers(map(this.selectedInfluencers, 'influencerPk'));
        break;
      default:
        throw new Error(`Unsupported action: ${lastAction}`);
    }
  }

  viewAllNotes(): void {
    this.showAllNotesSidebar = true;
    this.sidebarService.expand('notes');
  }

  openInfluencerNotesSideBar(influencer: GroupInfluencer): void {
    this.showInfluencerNoteSidebar = true;
    this.sidePanelInfluencer = transformToStandardInfluencer(influencer, this.platform);
    this.sidebarService.expand('notes');
  }

  closeSidebar(): void {
    this.showInfluencerNoteSidebar = false;
    this.showAllNotesSidebar = false;
    this.sidebarService.collapse('notes');
  }

  getInfluencerProfileUrl(influencer: StandardInfluencer): string {
    return utils.getInfluencerProfileUrl(this.platform, influencer['username']);
  }

  encodeUsername(username: string): string {
    return encodeURIComponent(username);
  }

  deleteGroup(): void {
    this.helperService.openDeleteGroupModal(this.groupInfo, this.platform);
  }

  async estimateAudienceInsights(): Promise<void> {
    const usernames = map(this.selectedInfluencers, 'username');
    try {
      this.overlayLoadingFlag = true;
      await this.helperService.estimateAudienceInsights(usernames);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.overlayLoadingFlag = false;
    }
  }

  async exportInfluencers(): Promise<void> {
    try {
      if (this.selectedRowsCount === 0) {
        return;
      }
      const selectedInfluencersUsernames = this.selectedRows.map((influencer) => influencer['username']);
      await this.helperService.exportInfluencers(
        this.platform,
        selectedInfluencersUsernames,
        this.groupInfo.slugName,
        this.groupInfo.name,
        false,
      );
      this.toasterService.success(`Successfully Exported Group!`);
    } catch (error) {
      if (error) {
        this.handleError(error);
      }
    }
  }

  get totalInfluencers(): number {
    return this.groupInfluencers.total;
  }

  deleteInfluencers(selectedInfluencersIds: string[]): void {
    if (this.selectedRowsCount === 0) {
      return;
    }

    this.overlayLoadingFlag = true;
    this.helperService
      .removeInfluencerFromGroupById(
        this.groupInfo,
        selectedInfluencersIds,
        this.platform,
        this.allGroupInfluencersSelected,
      )
      .then(async () => {
        this.loadData();
        this.toasterService.success(
          `${
            this.allGroupInfluencersSelected ? this.totalInfluencers : selectedInfluencersIds?.length
          } Influencer(s) removed from group`,
        );
      })
      .catch(() => {
        this.toasterService.error(`Something went wrong while deleting influencers`);
      })
      .finally(() => {
        this.overlayLoadingFlag = false;
      });
  }

  deleteInfluencer(influencer: GroupInfluencer): void {
    this.deleteInfluencers([influencer['id']]);
  }

  getInfluencerProfileCardInfo(influencer: GroupInfluencer): InfluencerProfileCard {
    return getInfluencerProfileCardInfo(influencer, this.platform);
  }

  async openProfileInfluencersModal(): Promise<void> {
    const result = await this.helperService.openProfileInfluencersModal(this.groupInfo, this.platform);
    if (result && result.slugName === this.queryObject.slugName) {
      this.loadData();
    }
  }

  rowClick(influencer: GroupInfluencer): void {
    if (influencer['username']) {
      this.updateDropdownOptions();
      this.updateSelectedInfluencers();
    }
  }

  selectAllEvent(isAllSelected: boolean): void {
    this.allGroupInfluencersSelected = isAllSelected;
    this.updateSelectedInfluencers();
    this.updateDropdownOptions();
  }

  updateDropdownOptions(): void {
    this.groupActionDropDownOptions.groupOptions[0].items.forEach((item) => {
      item.value = false;
      item.disabled = !this.selectedRowsCount;
    });
    this.allGroupInfluencersSelected = this.groupInfluencers.data.length === this.selectedRowsCount;

    // Do not enable delete when all influencers are selected
    if (this.allGroupInfluencersSelected) {
      this.disableDropdownOption(ContextMenu.delete);
    }
  }

  disableDropdownOption(key: Value): void {
    const option = this.groupActionDropDownOptions.groupOptions[0].items.find((item) => item.key === key);
    if (option) {
      option.disabled = true;
    }
  }

  addEstimateAudienceInsightsOption(): void {
    if (this.isEstimateAudienceInsightsVisible) {
      const existingOption = this.groupActionDropDownOptions.groupOptions[0].items.find(
        (item) => item.key === ContextMenu.estimateAudienceInsights,
      );

      if (!existingOption) {
        this.groupActionDropDownOptions.groupOptions[0].items.push({
          key: ContextMenu.estimateAudienceInsights,
          value: false,
          disabled: true,
        });
      }
    }
  }

  addToClosed(): void {
    const [addToButton] = this.addToButton.toArray();
    addToButton.destroyDropdown();
  }

  async openExcludedInfluencersModal(): Promise<void> {
    await this.helperService.openExcludedInfluencersModal(this.groupInfo, this.platform);
  }

  async editGroup(): Promise<void> {
    this.loading = true;
    try {
      const platform = [PlatformsUpperEnum.INSTAGRAM, PlatformsUpperEnum.VERAINSTAGRAM].includes(this.platform)
        ? PlatformsUpperEnum.VERAINSTAGRAM
        : this.platform;
      const { isSuccess, isModified, data } = await this.helperService.openEditGroupModal(this.groupInfo, platform);

      if (!isModified || !data) {
        return;
      } else if (!isSuccess) {
        throw new Error();
      }

      this.groupInfo.tags = data.tags;
      this.groupInfo.name = data['groupName'];
      this.groupInfo.updatedAt = data.updatedAt;
      this.groupInfo.updatedBy = data.updatedBy;
      this.toasterService.success('Successfully updated group');
    } catch (err) {
      this.toasterService.error('Something went wrong while updating group. Please try again.');
    } finally {
      this.loading = false;
    }
  }

  openShareGroupModal(): void {
    this.helperService.openShareGroupModal(this.groupInfo, this.platform).then((result) => {
      if (result?.success) {
        this.getGroupInfo();
      }
    });
  }

  handleError(error: any): void {
    this.toasterService.error(error?.error?.message || 'Something went wrong! Please contact support');
  }

  getInitials(input: string): string {
    const name = input.includes('@') ? input.split('@')[0] : input;
    const words = name.split(/\s+/);
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join('');
  }

  getRemainingSharedWith(sharedWith: string[] = []): string[] {
    const usersDictionary = sharedWith.slice(3).reduce((acc: Record<string, string[]>, user) => {
      const [origin, identity] = user.split(':');
      return Object.assign(acc, { [origin]: [...(acc[origin] || []), identity] });
    }, {});

    return [
      Object.keys(usersDictionary)
        .map(
          (origin) => `<span>${origin}:<br/></span>
          <span>${usersDictionary[origin].join('<br/>')}</span>`,
        )
        .join('<br/><br/>'),
    ];
  }

  getInfluencerLocations(countries: string[] = [], numberOfLocations = 2): string {
    return utils.getInfluencerLocations(countries, numberOfLocations);
  }

  sendMail(): void {
    if (this.isSendMailDisabled) {
      return;
    }

    this.helperService.sendMail(
      this.platform,
      this.selectedRows.map((selectedInfluencer) => ({
        email: selectedInfluencer.email,
        id: selectedInfluencer.influencerPk,
        username: selectedInfluencer.username,
        picture: selectedInfluencer.profilePicture,
        fullName: selectedInfluencer.name ?? selectedInfluencer.username,
      })) as InfluencerLookup[],
      this.influencerEmailSetter.bind(this),
    );
  }
}

export interface SelectedInfluencer {
  influencerPk: string;
  username: string;
  platform: PlatformsUpperEnum;
  profilePicture: string | undefined;
  fullName?: string;
  email?: string;
}

export enum ContextMenu {
  viewAllNotes = 'All Notes',
  deleteGroup = 'Delete Group',
  estimateAudienceInsights = 'Estimate Audience Insights',
  export = 'Export',
  delete = 'Delete',
}
