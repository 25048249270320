import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CampaignsService } from '../../../@core/data/campaigns.service';
import { GroupInfo, GroupService } from '../../../@core/data/group.service';
import { UserService } from '../../../@core/data/user.service';
import { Platforms, SubscribedClientUser } from '../../../enums';
import { ClientOrganizationMembers } from '../../../interfaces';

@Component({
  selector: 'ngx-unshare-group-modal',
  templateUrl: './unshare-group-modal.component.html',
  styleUrls: ['./unshare-group-modal.component.scss'],
})
export class UnshareGroupModalComponent implements OnInit {
  @Input() group: GroupInfo;
  @Input() platform: Platforms;

  client: SubscribedClientUser;

  sharedWith: string[];
  membersToRemove: string[];
  organizationMembers: ClientOrganizationMembers[];

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private groupService: GroupService,
    private userService: UserService,
    private campaignsService: CampaignsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.membersToRemove = [];
    this.client = await this.userService.getClientUser();
    this.campaignsService.getOrganizationMembers(this.group.owner).subscribe((orgMembers) => {
      if (orgMembers?.length) {
        this.organizationMembers = orgMembers.filter((member) => this.group.sharedWith.indexOf(member.email) !== -1);
      } else {
        this.organizationMembers = [];
      }
    });
    this.sharedWith = this.group.sharedWith;
  }

  closeModal(): void {
    this.activeModal.close();
  }

  unshareGroup(): void {
    const clientIdsToRemove = this.getIds(this.membersToRemove);
    this.groupService
      .unshareGroup(this.group.slugName, clientIdsToRemove, this.platform)
      .then((result) => {
        if (result.success) {
          this.toastrService.success('Group shared successfully');
        } else {
          const stillShared = clientIdsToRemove.filter((clientId) => result.data.includes(clientId));
          this.toastrService.error(`Access removal failed for ${stillShared.join(', ')}`);
        }
        if (result.data) {
          this.group.sharedWith = this.getEmails(result.data);
        }
        this.closeModal();
        if (!this.group.sharedWith.includes(this.client.email) && this.group.owner !== this.client.userId) {
          this.groupService.fetchInfluencerGroups(this.platform);
          this.groupService.sendGroupFilterChangedEvent();
        }
      })
      .catch(() => {
        this.toastrService.error('Failed to remove members from group access, please try again!');
        this.closeModal();
      });
  }

  getIds(emails: string[]): string[] {
    return emails.map((email) =>
      email === this.client.email
        ? this.client.userId
        : this.organizationMembers.find((member) => member.email === email)._id,
    );
  }

  getEmails(clientIds: string[]): string[] {
    return clientIds.map((clientId) =>
      clientId === this.client.userId
        ? this.client.email
        : this.organizationMembers.find((member) => member._id === clientId).email,
    );
  }
}
