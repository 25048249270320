<div class="signup">
  <div class="details">
    <div class="description">
      <label>Find relevant influencers, manage campaigns and measure the performance.</label>
    </div>
    <img src="../../../../../../../assets/images/signup.svg" alt="" class="illustration">
    <div class="shopify">
      <div class="shopify-store">
        <img src="../../../../../../../assets/images/shopify-logo.svg" alt="" class="shopify-icon">
        <label>Shopify Store</label>
      </div>
      <div class="col small-line"></div>
      <div class="shopify-details">
        <label>{{storeName}}</label>
        <label>{{shopUrl}}</label>
      </div>
    </div>
  </div>

  <div class="col line"></div>

  <div class="signup-form">
    <div class="title">
      <img style="height: 72px;" alt="" affLogo>
    </div>

    <h5 class="title">Create new Affable account</h5>

    <form #form="ngForm" autocomplete="off">
      <div class="form-group">
        <label for="input-name">Name</label>
        <input name="name" [(ngModel)]="user.name" id="input-name" class="form-control" #name="ngModel" type="text"
          [required]="true" pattern="\s*([A-Za-z]+\s?){1,3}\s*"
          [class.form-control-danger]="name.invalid && name.touched">
        <small class="form-text error" *ngIf="name.invalid && name.touched"> Valid Name is
          required!
        </small>
      </div>

      <div class="form-group">
        <label for="input-org">Organization</label>
        <input name="org" [(ngModel)]="user.organization" id="input-org" class="form-control" #org="ngModel" type="text"
          [required]="true" pattern="\s*([A-Za-z]+\s?)+\s*"
          [class.form-control-danger]="org.invalid && org.touched">
        <small class="form-text error" *ngIf="org.invalid && org.touched"> Valid Organization is
          required!
        </small>
      </div>

      <div class="form-group">
        <label for="input-phone">Phone number</label>
        <div class="phone-number-container">
        <nb-select placeholder="NgModel" [(ngModel)]="user.phone.code" name="code" class="phone-code" status="basic" size="medium">
          <nb-option class="phone-code-option" *ngFor="let code of countryDialCodes" value="{{code}}">
            {{code}}
          </nb-option>
        </nb-select>
        <input name="phone" [(ngModel)]="user.phone.number" id="input-phone" class="form-control phone-number" #phone="ngModel" type="number"
          [required]="true" pattern="[0-9]{3,14}" (keydown.space)="$event.preventDefault();"
          [class.form-control-danger]="phone.invalid && phone.touched">
        </div>
        <small class="form-text error" *ngIf="phone.invalid && phone.touched"> Valid Phone number is
          required!
        </small>
      </div>

      <div class="form-group">
        <label for="input-email">Email</label>
        <input name="email" [(ngModel)]="user.email" id="input-email" class="form-control" #email="ngModel" type="email"
          email [required]="true" [class.form-control-danger]="email.invalid && email.touched"
          (keydown.space)="$event.preventDefault();">
        <small class="form-text error" *ngIf="email.invalid && email.touched"> Valid email is
          required!
        </small>
        <small *ngIf="accountExists"><i class="fa fa-exclamation-triangle warning"></i>It appears you already
          have an account with this email address. <a (click)="login()">Log in</a></small>
      </div>

      <div class="form-group">
        <label for="input-password">Password</label>
        <div class="password-input">
          <input name="password" [(ngModel)]="user.password" id="input-password" class="form-control"
            [type]="showPassword ? 'text' : 'password'" #password="ngModel" pattern="(?=.*\d)[\s\S]{8,}"
            [class.form-control-danger]="password.invalid && password.touched" [required]="true">
          <span (click)="showPassword = !showPassword">
            {{showPassword ? 'Hide' : 'Show'}}
          </span>
        </div>
        <small class="form-text error" *ngIf="password.invalid && password.touched"> Valid
          password
          is required!
        </small>
      </div>

      <div *ngIf="!accountExists">
        <label class="password-label">Password should</label>
        <ul>
          <li>Be a minimum of 8 characters</li>
          <li>Contain at least 1 number</li>
        </ul>
      </div>

      <button
        [disabled]="!(user.name || user.organization || user.email || user.password || user.phone.number || user.phone.code) || !form.valid && (name.touched || org.touched || email.touched || password.touched || phone.touched)"
        (click)="signup()" class="btn btn-primary btn-xs"> CREATE ACCOUNT </button>

      <small class="form-text" style="padding-top: 1rem;">
        By creating an Affable account, you agree to our <br>
        <a href="https://www.affable.ai/legals/tnc" target="_blank"><strong>Terms of Service</strong></a> and <a
          href="https://www.affable.ai/legals/privacy" target="_blank"><strong>Privacy
            Policy</strong></a>
      </small>
      <div class="login">
        <label>Already have an account? <a (click)="login()"><strong>Log in</strong></a></label>
      </div>
    </form>

  </div>
</div>

<nb-spinner *ngIf="loading" class="large-spinner"></nb-spinner>
