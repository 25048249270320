import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AllPlatform } from '../../enums';
import { LinkedInfluencers } from '../../interfaces';

@Injectable()
export class SocialProfilesLinkerService {
  constructor(public http: HttpClient) {}

  getAllSocialProfiles(username: string, platform: AllPlatform): Promise<LinkedInfluencers> {
    return this.http
      .post<LinkedInfluencers>(environment.api + `/api/users/profiles`, {
        username,
        platform,
      })
      .toPromise()
      .then((response) =>
        response ? response : { instagram: null, facebook: null, youtube: null, tiktok: null, twitter: null },
      )
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }
}
