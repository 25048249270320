import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Link } from '../../../../../@core/data/campaigns.service';

@Component({
  selector: 'ngx-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input()
  linkIndex: number;

  editingLink = false;
  deletingLink = false;
  link: Link;
  editedTitle: string;
  isCollapsed = false;

  @Input()
  set linkValue(value: Link) {
    this.link = value;
    this.editedTitle = value.title;
  }

  @Output() editLinkEvent = new EventEmitter();
  @Output() deleteLinkEvent = new EventEmitter();

  enableEditing(): void {
    this.editingLink = true;
  }

  disableEditing(): void {
    this.editingLink = false;
  }

  editLink(): void {
    const linkIndex = this.linkIndex;
    this.editLinkEvent.emit({ linkIndex, linkTitle: this.editedTitle });
    this.link.title = this.editedTitle;
  }

  enableDeleting(): void {
    this.deletingLink = true;
  }

  disableDeleting(): void {
    this.deletingLink = false;
  }

  deleteLink(): void {
    const linkIndex = this.linkIndex;
    this.deleteLinkEvent.emit({ linkIndex });
  }

  getLinkId(link: string): string {
    return link.replace(/^https?:\/\//, '');
  }
}
