<div class="modal-header">
  <div *ngIf="!comments.media">
    <div *ngIf="comments.sentiment =='negative'" class="header">
      <img src="assets/images/emotions/02_sentiment_dissatisfied.svg">
      <span>Overall Negative Comments</span>
    </div>
    <div *ngIf="comments.sentiment =='positive'" class="header">
      <img src="assets/images/emotions/01_sentiment_satisfied.svg">
      <span>Overall Positive Comments</span>
    </div>
    <div *ngIf="comments.sentiment =='neutral'" class="header">
      <img src="assets/images/emotions/03_sentiment_neutral.svg">
      <span>Overall Neutral Comments</span>
    </div>
  </div>

  <div *ngIf="comments.media">
    <nb-user [name]="creator?.name" [picture]="creator?.picture">
    </nb-user>
  </div>

  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="!comments.media">
    <div *ngIf="comments.sentiment =='negative'" class="sub-header">
      <span>{{comments.content.length}} Negative Comments</span>
    </div>
    <div *ngIf="comments.sentiment =='positive'" class="sub-header">
      <span>{{comments.content.length}} Positive Comments</span>
    </div>
    <div *ngIf="comments.sentiment =='neutral'" class="sub-header">
      <span>{{comments.content.length}} Neutral Comments</span>
    </div>
  </div>

  <div *ngIf="comments.media">
    <div *ngIf="comments.sentiment =='negative'" class="sub-header">
      <img src="assets/images/emotions/02_sentiment_dissatisfied.svg">
      <span>{{comments.content.length}} Negative Comments</span>
    </div>
    <div *ngIf="comments.sentiment =='positive'" class="sub-header">
      <img src="assets/images/emotions/01_sentiment_satisfied.svg">
      <span>{{comments.content.length}} Positive Comments</span>
    </div>
    <div *ngIf="comments.sentiment =='neutral'" class="sub-header">
      <img src="assets/images/emotions/03_sentiment_neutral.svg">
      <span>{{comments.content.length}} Neutral Comments</span>
    </div>
  </div>

  <nb-card class="comments-container">
    <ng-container *ngFor="let comment of comments.content;index as i; even as isEven">
      <div [class]="isEven?'comment even':'comment odd'">
        <ng-container>
          <!-- TODO: When we support Youtube, pass commenter channel id -->
          <a *ngIf="comment.username" href="{{this.profileBaseUrl}}{{comment.username}}" target="_blank"
            class="comment-username">{{comment.username}}</a>
        </ng-container>
        <ai-dropdown
          *ngIf="editSentimentEnabledPlatforms.includes(platform.toLowerCase())"
          class="comment-options ai-btn-table"
          (action)="onChangeCommentSentiment($event,commentSentimentEdits[i],comment)" [closeOnSelect]="true"
          [buttonOptions]="{
          type:'ghost',
          prefix:'far fa-ellipsis-vertical'
        }" [options]="commentOptions[i]"></ai-dropdown>
        <p class="comment-content">{{comment.content}}</p>
        <div *ngIf="commentSentimentEdits[i]?.sentiment" class="footer-label af-caption">Edited to
          {{commentSentimentEdits[i]?.sentiment}}</div>
      </div>
    </ng-container>
  </nb-card>
</div>
