<div class="banner-container">
    <div class="description-container">
        <p class="banner-description">{{ description }}</p>
        <div class="chip-container">
            <span class="chip" *ngFor="let influencerDetails of influencers | slice:0:5">@{{ influencerDetails?.influencer?.username }}</span>
            <span class="chip clickable" *ngIf="influencers?.length > 5" (click)="showAllInfluencer()">+ {{ influencers?.length - 5 }} more</span>
        </div>
    </div>
    <div class="action-buttons-container">
        <button class="af-outline btn btn-outline-primary btn-xs" (click)="sendReminder()">
            Send a reminder
        </button>
        <div class="vertical-line"></div>
        <button class="close" aria-label="Close" (click)="closeReminder()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>