<div class="public-app-container">
<div class="body-container">
  <div class="d-flex">
    <div>
      <h1>Welcome to Affable!</h1>
      <h3 class="mt-4 mb-3">Find relevant influencers, manage campaigns and measure the performance of the influencer marketing campaigns.</h3>
      <h2 class="mb-4">Let’s explore Affable by following these step-by-step instructions.</h2>
    </div>
    <div class="dashboard-graphic">
    </div>
  </div>

  <div class="card-container mt-2 d-flex justify-content-between">
   <div *ngFor="let item of actions;let i = index" class="m-2">
    <ai-card >
      <div class="action-item">
        <div>{{item.title}}</div>
        <img src="assets/images/{{item.image}}" alt="">
        <ul *ngFor="let description of item.description">
         <li>{{description}}</li>
       </ul>
       <ai-button length="block" (click)="navigate(item.navigationUrl)" [label]="item.buttonText"></ai-button>
      </div>
    </ai-card>
   </div>
  </div>
</div>
</div>
