import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ngx-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() text = 'NEW';
  @Input() status = 'success';
  @Input() expiresAt?: string;

  @HostBinding('style.display') get display() {
    return this.isBadgeExpired ? 'none' : 'unset';
  }
  get isBadgeExpired(): boolean {
    return !!this.expiresAt && new Date(this.expiresAt).getTime() < new Date().getTime();
  }
}
