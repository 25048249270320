import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlAbstractDirective } from '../control';
import { AutoComplete } from '../control.enum';

@Component({
  selector: 'ai-tel-input',
  templateUrl: './tel-input.control.html',
  styleUrls: ['./tel-input.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelInputControl),
      multi: true,
    },
  ],
})
export class TelInputControl extends ControlAbstractDirective implements OnInit {
  @Input() autoComplete: AutoComplete = 'off';
  @Input() telCode: string;
  @Input() enablePlaceholder = false;
  @Input() searchCountryFlag = true;
  @Input() maxLength = 20;
  @Input() phoneValidation = true;
  @Input() separateDialCode = true;
  @Input() selectFirstCountry = false;
  @Input() selectedCountryISO;
  @Input() readonly = false;
  @Input() set disable(value: boolean) {
    if (value && this.control.enabled) {
      this.control.disable();
    }
    if (!value && this.control.disabled) {
      this.control.enable();
    }
  }

  /**
   * @ignore
   */
  hide = false;

  /**
   * @ignore
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.hide = this.inputType === 'password';
  }
}
