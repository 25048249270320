import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent {
  @Input() filesToAttach: File[] = [];
  @Input() validFile: boolean;
  @Output() validFileChange = new EventEmitter<boolean>();
  @Input() disabled = false;

  // used in label for attribute, which specify the id of the element that the label is bound to
  // ids must be unique in case there are two instances of the attachments component in the dom file
  @Input() id: string;

  @Input() attachmentSizeHint: string;

  attachementError: string;

  maxAttachmentSize = 1024 * 1024 * 25;

  handleFileInput(fileList: File[]): void {
    if (this.disabled) {
      return;
    }
    if (fileList?.length > 0) {
      const uniqueFileNames = new Set();
      const uniqueUploadFiles = [];
      for (const existingFile of this.filesToAttach) {
        if (!uniqueFileNames.has(existingFile.name)) {
          uniqueFileNames.add(existingFile.name);
        }
      }

      for (const newFile of fileList) {
        if (!uniqueFileNames.has(newFile.name)) {
          uniqueFileNames.add(newFile.name);
          uniqueUploadFiles.push(newFile);
        }
      }
      this.filesToAttach.push(...uniqueUploadFiles);
      this.validateFiles();
    }
  }

  validateFiles(): void {
    const totalSize = this.filesToAttach.map((file) => file.size).reduce((a, b) => a + b, 0);
    if (totalSize > this.maxAttachmentSize) {
      this.attachementError = 'Attachments exceeded maximum size limit of 25MB';
      this.validFile = false;
      this.validFileChange.emit(this.validFile);
    } else {
      this.attachementError = '';
      this.validFile = this.filesToAttach.length !== 0;
      this.validFileChange.emit(this.validFile);
    }
  }

  removeAttachment(fileToRemove: File): void {
    for (let i = 0; i < this.filesToAttach?.length; i++) {
      const file = this.filesToAttach[i];
      if (file.name === fileToRemove.name) {
        this.filesToAttach.splice(i, 1);
        break;
      }
    }
    this.validateFiles();
  }
}
