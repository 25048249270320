<nb-card *ngIf="!preview" class="modal-card">
  <nb-card-header class="modal-header"><label>{{modalHeader}}</label>
    <button class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </nb-card-header>
  <nb-card-body class="modal-body">
    <div class="row mt-1">
      <div class="col-sm-12">
        <form [formGroup]="newChatForm" (ngSubmit)="sendChat()">
          <div class="row mt-2">
            <label class="col-sm-2 remove-right-padding">Influencers ({{selectedInfluencers?.length}})</label>
            <div class="col-sm-10 remove-left-padding">
              <ng-select [disabled]="isEmbeded" placeholder="Enter username or name" addTagText="Search for influencer"
                typeToSearchText="Type to search for influencer" [items]="influencers | async" [multiple]="true"
                [hideSelected]="true" [typeahead]="influencerNameInput" [loading]="loadingInfluencers"
                [virtualScroll]="true" [markFirst]="false" [(ngModel)]="selectedInfluencers"
                [ngModelOptions]="{standalone: true}" [clearable]="!isChipOnEditMode">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value chip" data-cy="influencer-email-input"
                    [ngClass]="{'warn-background': !isEmailValid(item.email)}"
                    *ngFor="let item of filterInfluencersToShow(items)" (dblclick)="handleChipEdit(item, true)">
                    <span *ngIf="!item.editable" class="ng-value-label" (mouseover)="hoverInfluencer = item"
                      [popper]="fullEmail" [popperTrigger]="'hover'" [popperHideOnClickOutside]="true"
                      popperApplyClass="context-menu"><b>
                        {{item.fullName}}</b>
                      <ng-container *ngIf="!isEmailValid(item.email)"> @{{item.username}}</ng-container>
                      <ng-container *ngIf="isEmailValid(item.email)"> ({{item.email.split("@")[1]}})
                      </ng-container>
                    </span>
                    <span *ngIf="!item.editable" class="ng-value-icon right" (click)="clear(item)"
                      aria-hidden="true">×</span>
                    <div *ngIf="item.editable" class="actions">
                      <input type="email" data-cy="edit-email-field" [ngModelOptions]="{standalone: true}"
                        class="form-control edit-tag" [ngModel]="item.email" autofocus #emailInput="ngModel"
                        customEmailValidator [ngClass]="{'warn-background': !emailInput.valid}">
                      <button title="Save" data-cy="save-email-button" (click)="handleSaveChip(emailInput, item)"
                        [disabled]="!emailInput.valid"><i class="fa fa-check"></i></button>
                      <button title="Cancel" (click)="handleChipEdit(item, false)"><i class="fa fa-close"></i></button>
                    </div>
                  </div>
                  <span class="ng-value chip more" (click)=showAllInfluencers() *ngIf="showMoreBtnVisible">+
                    {{items.length - maxInitialInfluencersVisible}} MORE</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div class="row mt-2">
                    <div class="col-md-1">
                      <img src={{item.picture}} class="user-round" ngxHandleBlockedImage='true'>
                    </div>
                    <div class="col-md-10">
                      <span [ngOptionHighlight]="search" class="brand-name">{{item.fullName}}</span><br>
                      <span [ngOptionHighlight]="search" class="brand-username">@{{item.username}}</span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div *ngIf="numberOfInfluencersWithInvalidOrMissingEmail()" class="fx-row email-warn">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                {{getMessageForInfluencersWithNoValidEmail()}}
                <button class="text-btn" (click)="removeInfluencersWithNoValidEmail()">Remove from list</button>
              </div>
            </div>
          </div>
          <div class="row mt-2 align-items-center" *ngIf="campaignSlugName">
            <label class="col-sm-2 remove-right-padding">Select Template</label>
            <div class="col-sm-4 remove-left-padding">
              <ng-select class="show-arrow" dropdownPosition="bottom" placeholder="Select Email Template"
                [(ngModel)]="emailTemplates[0] ? emailTemplates[0]._id : emailTemplates" [items]="emailTemplates"
                [formControl]="emailTemplate" bindLabel="name" bindValue="_id" [markFirst]="false"
                (change)="loadTemplate($event)">
              </ng-select>
            </div>
            <div class="col-sm-6 remove-left-padding">
              <p class="text"> or Are you looking to create a new email template? <button class="text-btn"
                  (click)="openTemplates()">Click here</button></p>
            </div>
          </div>
          <div class="row mt-2">
            <label class="col-sm-2 remove-right-padding">Subject</label>
            <div class="col-sm-10 remove-left-padding">
              <input class="width-full input-group" data-cy="email-subject-field" placeholder="Subject of your email"
                formControlName="subject">
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-2 remove-right-padding"></div>
            <div class="col-sm-10 remove-left-padding">
              <div class="tips" *ngIf="showTips">
                <div class="tip">
                  <i class="fa fa-lightbulb-o"></i>
                  <div class="content">
                    <p>
                      <span class="title">
                        Tips:
                      </span>
                      To personalise, use the <b> Insert Variables </b> drop-down to insert the influencer's
                      <code>{{getTemplatingVariablesForTipsMessage(0,5)}}</code>,
                      <span *ngIf="getTemplatingVariablesForTipsMessage(5,8)">or
                        <code>{{getTemplatingVariablesForTipsMessage(5,8)}}</code>,</span>
                      or type <b>@</b> to open the variables popover and select from the list.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <label class="col-sm-2 remove-right-padding">Message</label>
            <div class="col-sm-10 remove-left-padding">
              <ai-ckeditor [supportedTemplatingVariables]="supportedTemplatingVariables"
                [defaultTemplate]="defaultTemplateMessage" formControlName="message" data-cy="email-message-field"
                [apiPath]="apiPath">
              </ai-ckeditor>
              <ngx-attachments [filesToAttach]="filesToAttach" [(validFile)]="validFile" [id]="'newChatFile'"
                [attachmentSizeHint]="'(Within a maximum size of 25MB, file types such as mov, mpeg, mpg, avi, jpeg, png, pdf, doc, and docx are supported.)'">
              </ngx-attachments>
            </div>
          </div>
        </form>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div style="width:100%">
      <span *ngIf="restrictedKeywordsUsed" class="fx-row warn-font">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <span>Some of the template keywords are not allowed.</span>
      </span>
      <span *ngIf="errorInSendMail" class="fx-row error-font">
        <i class="fa fa-times" aria-hidden="true"></i>
        Looks like you are out of credits! Contact the team or message unlocked influencers.
      </span>

      <div class="footer-tip">
        <ngx-tooltip [placement]="'top-left'">
        </ngx-tooltip>
        <label *ngIf="!preview">
          Click “<b>Preview</b>” to check or edit the name, salutation, and content of each influencer before sending
          it.
        </label>
        <label *ngIf="preview">
          Click on the influencer's name to see the mail preview.
        </label>
      </div>
    </div>

    <div class="send-mail" data-cy="send-mail-button-modal">
      <button *ngIf="!preview" class="btn btn-xs btn-outline-primary text" (click)="previewChat()"
        [disabled]="!influencersWithValidEmail()?.length || !newChatForm.value.subject || !newChatForm.value?.message || loading || !validFile">
        Preview
      </button>
      <button (click)="sendChat()" class="btn btn-xs btn-primary" [disabled]="isSendingMailDisabled">
        Send Mail
      </button>
    </div>
  </nb-card-footer>
  <nb-spinner *ngIf="loading"
    [message]="noOfMessagesSent? 'Sending '+ noOfMessagesSent +'/'+totalNumberOfMessagesToBeSend + ' emails...':' Sending...'">
  </nb-spinner>
  <nb-spinner *ngIf="generatingPreview" message="Generating preview..."></nb-spinner>
</nb-card>

<ngx-chat-preview *ngIf="preview" [supportedTemplatingVariables]="supportedTemplatingVariables"
  [chatPreviewList]="chatPreviewInfluencers" [filesToAttach]="filesToAttach" [platform]="platform"
  [campaignSlugName]="campaignSlugName" [subject]="newChatForm.value.subject" [message]="newChatForm.value.message"
  (closeModal)="closeModal($event)" (closePreview)="preview = false" [mailType]="mailType">
</ngx-chat-preview>

<popper-content #fullEmail>
  <div *ngIf="hoverInfluencer?.email" class="list-group" (dblclick)="handleChipEdit(hoverInfluencer, true)">
    <div class="list-group-item">
      <p>{{hoverInfluencer.email}}</p>
    </div>
  </div>
</popper-content>