/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, Output, EventEmitter } from '@angular/core';

import saveAs from 'file-saver';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { InfluencerMetadata, MetadataService } from '../../../@core/data/metadata.service';
import { NotesService } from '../../../@core/data/notes.service';
import { UserService } from '../../../@core/data/user.service';
import utils from '../../../@core/utils/utils';
import { Platforms, PlatformsV2, VeraPlatforms } from '../../../enums';
import {
  FacebookInfluencer,
  Influencer,
  Note,
  TiktokInfluencer,
  TwitterInfluencer,
  YoutubeInfluencer,
  InstagramInfluencer,
  BasicInfluencerInfo,
} from '../../../interfaces';
import { omit } from 'lodash';

// Parent element for Notes
// Supports Campaigns, Groups, and Profile pages

// Structure:
// NotesSidebarComponent
// - InfluencerMetadataComponent
// - AddNoteComponent
// - NotesListComponent
// -- Note
type InfluencerType =
  | Influencer[]
  | FacebookInfluencer[]
  | TwitterInfluencer[]
  | YoutubeInfluencer[]
  | TiktokInfluencer[]
  | InstagramInfluencer[];
@Component({
  selector: 'ngx-notes-sidebar',
  templateUrl: './notes-sidebar.component.html',
  styleUrls: ['./notes-sidebar.component.scss'],
})
export class NotesSidebarComponent {
  @Input() platform: PlatformsV2;
  @Input() campaignSlugName?: string;
  @Input() isCollabPortal = false;
  @Input() page: 'campaigns' | 'profile' | 'groups';
  @Input() selectedGroupSlug: string | undefined;
  @Output() editedMetadataEmitter = new EventEmitter();
  @Output() onNotesChanged = new EventEmitter();

  basicInfluencerInfo: BasicInfluencerInfo;
  influencerMetadata: InfluencerMetadata;
  cancelMetaDataChanges: boolean;
  username: string;
  loading = false;
  loadingBasicInfoWithMetadata = false;
  allNotes: Note[] = [];
  privateNotes: Note[] = [];
  sharedNotes: Note[] = [];
  cancelChanges: boolean;
  isSingleUserOrg = false;
  showAddNote = false;

  constructor(
    private notesService: NotesService,
    private userService: UserService,
    private toastrService: ToastrService,
    private metadataService: MetadataService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.isCollabPortal) {
      this.isSingleUserOrg = await this.userService.checkIfSingleUserOrg();
    }

    // Hide AddNote from Groups page since Notes are not specific to one influencer
    this.showAddNote = this.page === 'campaigns' || this.page === 'profile';
    // InfluencerMetadata is only shown on the profile page
    if (this.page === 'profile') {
      this.metadataService.cancelChangesEvent.subscribe(() => {
        this.cancelMetaDataChanges = true;
      });
    }
  }

  // Input to load notes for campaigns and profile pages: single influencer
  @Input()
  set influencerUsername(value: string) {
    this.username = value;
    this.loading = true;

    // Only load basicInfluencerInfo on the Profile page. Group Notes are not specific to one influencer and there is already a separate sidebar for Notes Metadata on Campaigns
    if (this.page === 'profile') {
      this.loadingBasicInfoWithMetadata = true;
      this.loadBasicInfoWithMetadata(this.username, this.platform);
    }

    // get notes and separate private and shared if multi-user organization
    this.loadNotes(this.username, this.platform);
  }

  // Input to load notes for Groups page: multiple influencers
  @Input()
  set influencers(value: InfluencerType) {
    this.reset();
    const influencersData = value;
    const influencerUsernames = this.getUsernamesList(value);
    if (!influencerUsernames.length || (this.page === 'groups' && !this.selectedGroupSlug)) return;
    this.loading = true;
    this.notesService
      .fetchNotesForInfluencers(influencerUsernames, this.platform, !!this.selectedGroupSlug)
      .then((groupNotes) => {
        this.reset();
        const influencersWithNotes = groupNotes.filter((influencer) => influencer.notes?.length > 0);

        for (const influencer of influencersWithNotes) {
          // get dataset of influencer profile for reference

          const data = this.getInfluencer(influencer.influencerUsername, influencersData);

          for (const note of influencer.notes) {
            // decorate notes with influencer profile for use when rendering notes
            const noteWithInfluencerData = {
              ...note,
              ...this.getInfluencerInfo(data),
            };

            this.allNotes.push(noteWithInfluencerData);
            if (!this.isSingleUserOrg) {
              note.isPrivate
                ? this.privateNotes.push(noteWithInfluencerData)
                : this.sharedNotes.push(noteWithInfluencerData);
            }
          }
        }
      })
      .then(() => (this.loading = false));
  }

  @Input()
  set panelClosed(value: boolean) {
    this.cancelChanges = value;
  }

  reset(): void {
    this.allNotes = [];
    this.privateNotes = [];
    this.sharedNotes = [];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getUsernamesList(value): string[] {
    if (!value) {
      return [];
    }
    if (this.platform === 'Instagram') {
      return value.map((influencer) => influencer?.profile.username);
    } else return value.map((influencer) => influencer?.username);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getInfluencer(username: string, influencersData: any[]) {
    if (this.platform === 'Instagram') {
      return influencersData.find((influencerData) => influencerData.profile.username === username);
    } else {
      return influencersData.find((influencerData) => influencerData.username === username);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getInfluencerInfo(influencer) {
    if (this.platform === 'Instagram') {
      return {
        influencerUsername: influencer.profile.username,
        influencerName: influencer.profile.fullName,
        influencerPicture: influencer.profile.picture,
      };
    } else if (this.platform === VeraPlatforms.instagram) {
      return {
        influencerUsername: influencer.username,
        influencerPicture: influencer.profilePicture,
        influencerName: influencer.fullName,
      };
    } else {
      return {
        influencerUsername: influencer.username,
        influencerName: influencer.name,
        influencerPicture: influencer.profilePictureUrl,
      };
    }
  }

  loadBasicInfoWithMetadata(username: string, platform: Platforms | VeraPlatforms): void {
    this.metadataService
      .getBasicInfoWithMetadata(username, platform)
      .then((infoWitMetadata) => {
        if (infoWitMetadata['Error']) {
          throw Error(infoWitMetadata['Error']);
        }
        this.influencerMetadata = infoWitMetadata?.influencerMetadata;
        this.basicInfluencerInfo = omit(infoWitMetadata, 'influencerMetadata');
      })
      .catch(() => {
        console.log('Error in fetching influencer metadata'); // Intentional
      })
      .finally(() => (this.loadingBasicInfoWithMetadata = false));
  }

  async loadNotes(username: string, platform: Platforms | VeraPlatforms): Promise<void> {
    const notes = this.isCollabPortal
      ? await this.notesService.fetchNotesForInfluencerInCollabGroup(
          username,
          platform,
          this.selectedGroupSlug as string,
        )
      : await this.notesService.fetchNotesForInfluencer(username, platform, !!this.selectedGroupSlug);

    this.allNotes = notes;
    if (!this.isSingleUserOrg) {
      this.allNotes?.forEach((note) => {
        note.isPrivate ? this.privateNotes.push(note) : this.sharedNotes.push(note);
      });
    }
    this.loading = false;
  }

  updateMetadata(influencerMetadata: Partial<InfluencerMetadata>): void {
    this.influencerMetadata = { ...this.influencerMetadata, ...influencerMetadata };
    this.metadataService
      .updateInfluencerMetadata(this.username, this.platform, this.influencerMetadata)
      .then(() => this.toastrService.success('Influencer updated successfully'))
      .catch(() => this.toastrService.error('Failed to update influencer'));
    this.editedMetadataEmitter.emit(this.influencerMetadata);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addNote($event): void {
    this.loading = true;
    const newNoteAsHtml = utils.getHtmlfromPlainText($event.newNote);
    const newNoteFile: File = $event.file;
    this.notesService
      .addNote(
        this.username,
        newNoteAsHtml,
        this.platform,
        newNoteFile,
        $event.isPrivate,
        this.isCollabPortal,
        this.campaignSlugName,
        this.selectedGroupSlug as string,
      )
      .then((notePojo: Note) => {
        this.onNotesChanged.emit(notePojo);
        // manually add note to loaded notes array
        if (this.isSingleUserOrg) {
          this.allNotes.unshift(notePojo);
        } else if (notePojo.isPrivate) {
          this.privateNotes.unshift(notePojo);
        } else {
          this.sharedNotes.unshift(notePojo);
        }
      })
      .catch((res) => {
        let errorMessage: string = res.error.message;
        if (res.status === 429) {
          errorMessage = 'Notes attachments size limit reached!';
        }
        this.toastrService.error(errorMessage);
      })
      .then(() => (this.loading = false));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editNote($event): void {
    this.notesService
      .editNote(
        $event.noteId,
        $event.editedNoteAsHtml,
        this.platform,
        $event.deletedNoteFile,
        $event.privacy,
        this.isCollabPortal,
        this.selectedGroupSlug as string,
      )
      .then((notePojo: Note) => {
        if (notePojo) {
          // finds existing note and replaces it with notePojo
          this.replaceNote(notePojo);
          this.onNotesChanged.emit(notePojo);
        }
      })
      .then(() => (this.loading = false));
  }

  replaceNote(notePojo: Note): void {
    if (this.isSingleUserOrg) {
      this.replaceEntry(this.allNotes, notePojo);
    } else if (notePojo.isPrivate) {
      this.replaceEntry(this.privateNotes, notePojo);
    } else {
      this.replaceEntry(this.sharedNotes, notePojo);
    }
  }

  replaceEntry(notesArray: Note[], notePojo: Note): void {
    const { index, firstInfluencerIndex } = this.getNoteIndices(notesArray, notePojo);

    if (index !== -1) {
      // Copy creator and influencer info from old note (which is not returned by note edit API) into the new entry
      notePojo = this.copyAddtlInfo(notePojo, notesArray, index);

      notesArray.splice(index, 1);

      // insert new note to the top of the notes for an influencer since notes are in descending order per influencer
      notesArray.splice(firstInfluencerIndex, 0, notePojo);
      return;
    }

    // If note changed privacy, it will not be found in the array corresponding to its new privacy
    if (index === -1 && notesArray !== this.allNotes) {
      // Delete old entry from previousArray
      const previousArray = notesArray === this.privateNotes ? this.sharedNotes : this.privateNotes;
      const { index } = this.getNoteIndices(previousArray, notePojo);
      // copy info before deleting old entry
      notePojo = this.copyAddtlInfo(notePojo, previousArray, index);
      previousArray.splice(index, 1);

      // Add new entry
      notesArray.splice(firstInfluencerIndex, 0, notePojo);
    }
  }

  getNoteIndices(notesArray: Note[], notePojo: Note): { firstInfluencerIndex: number; index: number } {
    const index = _.findIndex(notesArray, (note) => note.id === notePojo.id);
    const firstInfluencerIndex = _.findIndex(notesArray, (note) => note.influencerId === notePojo.influencerId);
    return { index, firstInfluencerIndex };
  }

  copyAddtlInfo(notePojo: Note, notesArray: Note[], index: number): Note {
    const oldNote = notesArray[index];
    notePojo.creatorName = oldNote.creatorName;
    notePojo.creatorPicture = oldNote.creatorPicture;
    notePojo.influencerName = oldNote.influencerName;
    notePojo.influencerPicture = oldNote.influencerPicture;

    return notePojo;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  deleteNote($event): void {
    this.notesService
      .deleteNote($event.noteId, this.isCollabPortal, this.selectedGroupSlug as string)
      .then(() => {
        let index = -1;

        if (this.isSingleUserOrg) {
          index = _.findIndex(this.allNotes, (note) => note.id === $event.noteId);
          this.allNotes.splice(index, 1);
          this.onNotesChanged.emit(this.allNotes[0]);
        } else {
          index = _.findIndex(this.sharedNotes, (note) => note.id === $event.noteId);
          if (index !== -1) {
            this.sharedNotes.splice(index, 1);
            this.onNotesChanged.emit(this.sharedNotes[0]);
          } else {
            index = _.findIndex(this.privateNotes, (note) => note.id === $event.noteId);
            this.privateNotes.splice(index, 1);
            this.onNotesChanged.emit(this.privateNotes[0]);
          }
        }
      })
      .then(() => (this.loading = false));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  downloadFile($event): void {
    const file = $event.file;
    this.notesService
      .downloadNoteAttachement(file.path)
      .then((res) => {
        const blob = new Blob([res]);
        saveAs(blob, file.name);
      })
      .catch(() => null);
  }
}
