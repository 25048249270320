import { RecommendationList } from '../@core/data/content.service';
import { AudienceStats, BrandWithMediaItems } from '../@core/data/influencer.service';
export interface InstagramProfile {
  id: string;
  username: string;
  fullName: string;
  email: string;
  profilePicture: string;
  biography: string;
  followersCount: number;
  phone: string;
  engagement: InstagramEngagement;
  interests: string[];
  locations: string[];
  estimatedReach: number;
  estimatedMediaValue: number;
  estimatedImpressions: number;
  audienceStatistics?: AudienceStats;
  isRegistered?: boolean;
  areInsightsVerified?: boolean;
}

interface InstagramEngagement {
  avgLikesRatio: number;
  avgCommentsRatio: number;
  avgLikes: number;
  avgComments: number;
  rate: number;
  ratePercentage: number;
}

export interface InstagramInfluencerMedia {
  id: string;
  code: string;
  commentCount: number;
  likeCount: number;
  mediaProductType: string;
  mediaType: InstagramMediaType;
  caption: string;
  brands: string[];
  isSponsored?: boolean;
  authorDocument: Author;
  takenAt: string;
  estimatedReach?: number;
  estimatedMediaValue?: number;
  estimatedImpressions?: number;
  currency?: string;
  playCount?: number;
}

interface Author {
  id: string;
  igId: string;
  username: string;
  fullName: string;
}

export type InstagramMediaType = 'CAROUSEL_ALBUM' | 'IMAGE' | 'VIDEO';

export interface InstagramInfluencerBrand {
  fullName: string;
  username: string;
  profilePicUrl: string;
  id: string;
  igId: string;
}

export interface InstagramInfluencerBrandMedia {
  postURL: string;
  takenAt: string;
  mediaURL: string;
  sponsored: boolean;
  show?: boolean;
}

export interface InstagramInfluencer {
  id: string;
  igId: string;
  username: string;
  fullName: string;
  biography: string;
  profilePicture: string;
  followersCount: number;
  email: string;
  phone: string;
  isRegistered?: boolean;
  influencerType?: string;
  selected?: boolean;
  engagementRate?: number;
  engagementScore?: number;
  isPotentialInfluencer?: boolean;
  isProfiling?: boolean;
  disableAnalyse?: boolean;
  areInsightsVerified?: boolean;
}

export interface VeraInstagramInfluencer {
  id: string;
  igId: string;
  username: string;
  fullName: string;
  biography: string;
  profilePicture: string;
  followersCount: number;
  email: string;
  phone: string;
  isRegistered?: boolean;
  influencerType?: string;
  selected?: boolean;
  engagementRate?: number;
  engagementScore?: number;
  isPotentialInfluencer?: boolean;
  isProfiling?: boolean;
  disableAnalyse?: boolean;
  areInsightsVerified?: boolean;
}

export interface InstagramInfluencerAPIResponse {
  influencers: InstagramInfluencer[];
  total: number;
  recommendation: RecommendationList[];
}

export class BrandAffinityData {
  fullName: string;
  fullNameLower?: string;
  pk: string;
  profilePicUrl: string;
  username: string;
}

export interface BasicInstagramInfluencerInfo {
  username: string;
  fullName: string;
  picture: string;
  email?: string;
}

export function toBrandWithMediaItems(brandAffinity: BrandAffinityData): BrandWithMediaItems {
  return {
    fullName: brandAffinity.fullName,
    profilePicUrl: brandAffinity.profilePicUrl,
    username: brandAffinity.username,
    mediaItems: [],
    show: false,
    expanded: false,
  };
}
