/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../@core/auth/auth.service';
import { UserService } from '../../../../@core/data/user.service';
import { COUNTRY_DIAL_CODES } from '../../../../utils/constants';

class User {
  name: string;
  email: string;
  password: string;
  organization: string;
  phone: {
    code: string;
    number: string;
  };

  constructor(email?: string) {
    this.email = email;
    this.phone = {
      code: '+1',
      number: '',
    };
  }
}

@Component({
  selector: 'ngx-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  shopUrl: string;
  storeName: string;
  email: string;
  user: User;
  accountExists = false;
  showPassword = false;
  loading = false;
  countryDialCodes = Object.keys(COUNTRY_DIAL_CODES);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.shopUrl = this.route.snapshot.queryParamMap.get('shop');
    this.storeName = this.route.snapshot.queryParamMap.get('storeName');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.user = new User(this.email);
  }

  login() {
    const queryParams = { shop: this.shopUrl, storeName: this.storeName, email: this.email };
    return this.router.navigate(['auth', 'public', 'login'], { queryParams });
  }

  signup(): void {
    if (
      !this.user.email ||
      !this.user.password ||
      !this.user.name ||
      !this.user.organization ||
      !this.user.phone.number ||
      !this.user.phone.code
    ) {
      return;
    }

    if (this.shopUrl) {
      const accountDetails = {
        email: this.user.email,
        password: this.user.password,
        fullName: this.user.name,
        orgName: this.user.organization,
        shopUrl: this.shopUrl,
        phone: {
          code: this.user.phone.code,
          number: this.user.phone.number.toString(),
        },
      };
      this.loading = true;
      this.userService
        .createClientUser(accountDetails)
        .then(async () => {
          // Login to OAuth using newly created creds and save to local storage
          await this.authService.login(this.user.email, this.user.password).catch((error) => {
            this.loading = false;
            this.toastrService.error(error.message);
          });

          this.toastrService.success('Account created successfully');
          this.router.navigateByUrl('/subscription');
        })
        .catch((err) => {
          this.loading = false;
          if (err?.error?.statusCode === 409) {
            this.accountExists = true;
          }
        });
    } else {
      this.toastrService.error('Missing store link');
    }
  }
}
