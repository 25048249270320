<div *ngIf="!compact && !inlineInfo" class="info-container">
  <div class="icon {{ infoOptions.iconClassName }}"></div>
  <div class="details">
    <div class="title">{{ infoOptions.title }}</div>
    <div class="description">{{ infoOptions.description | formatNumber:2 }}</div>
    <div *ngIf="hasPercentage()" class="percentage"> {{infoOptions.percentage | formatNumber:2}}%</div>
  </div>
</div>

<div *ngIf="inlineInfo" class="info-container">
  <div class="icon {{ infoOptions.iconClassName }}"></div>
  <div class="details">
    <div class="title">{{ infoOptions.title }}</div>
    <div class="description">{{ infoOptions.description | formatNumber:2 }} {{ infoOptions.description?'|':''}} {{infoOptions.percentage | formatNumber:2}}%</div>
  </div>
</div>

<div *ngIf="compact" class="info-container compact">
  <div class="icon {{ infoOptions.iconClassName }}" title="{{infoOptions.title}}"></div>
  <div class="details">
    <div class="description">{{ infoOptions.description | formatNumber:2 }}</div>
    <div *ngIf="hasPercentage()" class="percentage"> {{infoOptions.percentage | formatNumber:2}}%</div>
  </div>
</div>
