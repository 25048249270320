<div>

  <div class="inputs-container">
    <input type="text" name="title" placeholder="Title" [(ngModel)]="model.title">
  </div>

  <div class="inputs-container">
    <input type="text" name="customLink" placeholder="Custom Link Text (optional)" [(ngModel)]="model.customLink" pattern="[^\s]*">
  </div>
  <div class="error">
    <span *ngIf="invalidCustomLink">{{invalidCustomLink}}</span>
  </div>
  <div class="inputs-container">
    <textarea rows="3" cols="50" type="text" [(ngModel)]="model.long_url"
      placeholder="Long url with http(s)://"> </textarea>
    </div>
    <div class="error">
      <span *ngIf="invalidUrl">{{invalidUrl}}</span>
    </div>
  <div class="actions">
    <button class="btn btn-primary btn-xs" (click)="addLink()">Create</button>
  </div>
</div>