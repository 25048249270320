<ngx-auth-block>
  <div class="title">
    <img style="height: 100px;" affLogo>
  </div>
  <h2 class="title">Sign In</h2>
  <div *ngIf="isMobileDevice()" class="alert alert-warning" role="alert">
    <small><strong>Recommendation!</strong></small>
    <small> This app works best on a desktop browser </small>
  </div>
  <small class="form-text sub-title">Hello! Sign in with your username or email</small>

  <form #form="ngForm" autocomplete="off">
    <div *ngIf="showMessages.success && messages && messages?.length > 0" class="alert alert-success" role="alert">
      <div><strong>Hooray!</strong></div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>

    <div class="form-group">
      <label for="input-email" class="sr-only">Email address</label>
      <input name="email" [(ngModel)]="user.email" id="input-email" class="form-control" placeholder="Email address"
        #email="ngModel" (keydown.space)="$event.preventDefault();" email
        [class.form-control-danger]="email.invalid && email.touched" [required]="true" data-cy="email-input">
      <small class="form-text error" *ngIf="email.invalid && email.touched"> Valid email is
        required!
      </small>
    </div>

    <div class="form-group">
      <label for="input-password" class="sr-only">Password</label>
      <input name="password" [(ngModel)]="user.password" type="password" id="input-password" class="form-control"
        placeholder="Password" #password="ngModel" [class.form-control-danger]="password.invalid && password.touched"
        [required]="true" data-cy="password-input">
      <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required"> Password
        is
        required!
      </small>
    </div>

    <div class="form-group" style="display: flex; justify-content: space-between; align-items: center;">
      <nb-checkbox status="success" [value]="tosAccepted"
        (change)="this.tosAccepted = !this.tosAccepted"
        class="checkbox-input" id="tos-checkbox">
        <small class="form-text" style="margin-top: 0;">
          <strong>By signing in you accept our
          <a href="https://www.affable.ai/legals/tnc" class="af-link-text" target="_blank"> terms of service</a>
          </strong>
        </small>
        <small class="form-text error" *ngIf="!tosAccepted"> You must accept our terms before signing in</small>
      </nb-checkbox>

      <small class="form-text" style="margin-top: 0;">
        <a class="af-link-text" routerLink="../request-password"><strong>Forgot Password?</strong></a>
      </small>
    </div>

    <ai-button [disabled]="loading || !form.valid || !tosAccepted" (click)="login()" typography="body-2" label="SIGN IN"
      suffix="far fa-arrow-right-to-arc" length="block" data-cy="sign-in-button">
    </ai-button>

    <div *ngIf="showMessages.error && messages && messages?.length > 0" class="alert alert-danger"
      style="margin-top: 1rem" role="alert">
      <div><strong>Oops!</strong></div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>

    <small class="form-text" style="padding-top: 1rem;" *ngIf="domain === 'affable'">
      Don't have an account? <a class="af-link-text" href="https://www.affable.ai/#contactus"><strong>Schedule a
          Demo</strong></a>
    </small>

    <small class="form-text" style="padding-top: 1rem;" *ngIf="domain === 'affable'">
      Facing issues logging in? Reach us at <a class="af-link-text"
        href="mailto:help@affable.ai"><strong>Support</strong></a>
    </small>
  </form>

  <nb-spinner *ngIf="loading" class="large-spinner"></nb-spinner>
</ngx-auth-block>
