import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../../@core/data/admin.service';
import { UserService } from '../../@core/data/user.service';
import { SubscribedClientUser } from '../../enums';
import { InfluencerSavedSearchFilters, SearchFiltersPrivacy } from '../../interfaces';
import utils from '../../@core/utils/utils';

@Component({
  selector: 'ngx-save-filter-modal',
  templateUrl: './save-filter-modal.component.html',
  styleUrls: ['./save-filter-modal.component.scss'],
})
export class SaveFilterModalComponent implements OnInit {
  @Input() savedSearchFilters: InfluencerSavedSearchFilters[];
  @Input() selectedFilter: InfluencerSavedSearchFilters;
  @Input() editMode = false;
  editFilter: InfluencerSavedSearchFilters;

  searchName: string;
  filterPrivacy: SearchFiltersPrivacy = 'private';
  isDefault = true;
  saveAsNew = true;
  selectedFilterSlugName: string;
  dropdownSearchList: InfluencerSavedSearchFilters[];
  user: SubscribedClientUser;
  organizations: string[] = [];
  selectedOrganizations: string[] = [];
  organizationsBeforeEdit: string[] = [];
  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private adminService: AdminService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getClientUser();
    const accountId = utils.getAccountIdFromClientUser(this.user);
    if (this.editMode) {
      this.editFilter = this.selectedFilter;
      this.selectedFilter = undefined;
      this.organizationsBeforeEdit = this.editFilter.sharedWithOrgs;
      this.setFormData(this.editFilter);
      this.dropdownSearchList = this.savedSearchFilters.filter(
        (f) => f.filterSlugName !== this.editFilter.filterSlugName && f.clientId === accountId,
      );
    } else {
      this.dropdownSearchList = this.savedSearchFilters.filter((f) => f.clientId === accountId);
    }
    if (this.user.isAdminUser) {
      this.adminService.getAllOrganizationsNames().then((orgs) => (this.organizations = orgs.filter(Boolean)));
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  saveSearch(): void {
    const orgsUpdated =
      this.editMode &&
      this.filterPrivacy === 'global' &&
      !!(
        this.findDifference(this.selectedOrganizations, this.organizationsBeforeEdit) ||
        this.findDifference(this.organizationsBeforeEdit, this.selectedOrganizations)
      );
    this.activeModal.close({
      searchName: this.searchName,
      filterPrivacy: this.filterPrivacy,
      isDefault: this.isDefault,
      saveAsNew: this.saveAsNew,
      editMode: this.editMode,
      selectedSlugName: this.selectedFilterSlugName,
      sharedWithOrgs:
        this.filterPrivacy === 'global' && (this.saveAsNew || (this.editMode && orgsUpdated))
          ? this.selectedOrganizations
          : undefined,
    });
  }

  selectSavedSearch(): void {
    if (!this.saveAsNew && this.selectedFilter) {
      this.saveAsNew = false;
      this.searchName = this.editMode ? this.editFilter.filterName : undefined;
      this.selectedFilterSlugName = this.selectedFilter.filterSlugName;
    }
  }

  searchNameChanged(): void {
    if (!this.saveAsNew && this.searchName?.length > 0) {
      this.saveAsNew = true;
      this.selectedFilter = undefined;
      this.selectedFilterSlugName = undefined;
    }
  }

  setFormData(filter: InfluencerSavedSearchFilters): void {
    this.searchName = filter.filterName;
    this.filterPrivacy = filter.filterPrivacy;
    this.isDefault = filter.isDefault;
    this.selectedFilterSlugName = filter.filterSlugName;
    this.selectedOrganizations = filter.sharedWithOrgs || [];
  }

  findDifference(array1: string[] = [], array2: string[] = []): string[] {
    return array1.filter((x) => array2.indexOf(x) === -1);
  }
}
