import { Component, Input } from '@angular/core';
import { PrintService } from '../print-layout/print.service';
import {
  InstagramPredictedPriceRange,
  LinkedInfluencers,
  ProfileAbout,
  TiktokProfile,
  YoutubeInfluencerProfile,
} from '../../../interfaces';
import { InfoOptions } from '../../../shared/info-with-icon/info.component';
import { TimeSeriesItem } from '../../../@core/data/influencer.service';
import { HEIGHTS } from '../../../enums/print.enum';

@Component({
  selector: 'ngx-print-chart-container',
  templateUrl: './print-chart-container.component.html',
  styleUrls: ['./print-chart-container.component.scss'],
})
export class PrintChartContainerComponent {
  totalPages: number;
  profileImageNotLoaded: boolean;

  // card static height's
  heights = HEIGHTS;

  // common input's
  @Input() id;
  @Input() pageTag: boolean;
  @Input() page: number;
  @Input() description: string;
  @Input() influencerStats;

  // BIO
  @Input() influencer;
  @Input() profile: ProfileAbout | TiktokProfile | YoutubeInfluencerProfile; // FB
  @Input() influencerLocationName: string;
  @Input() influencerPriceRanges: InstagramPredictedPriceRange[];
  @Input() costs: string;
  @Input() socialMediaProfiles: LinkedInfluencers;

  // AUDIENCE GENDER DISTRIBUTION
  @Input() genderDataOptions;

  // AUDIENCE AGE DISTRIBUTION
  @Input() adultAudiencePercentage;
  @Input() ADULT_AUDIENCE_AGE: number;
  @Input() genderOverlay;
  @Input() ageGenderDataOptions;
  @Input() ageDataOptions;

  // AUDIENCE LOCATION DISTRIBUTION
  @Input() distributionData;

  // ENGAGEMENT
  @Input() engagementData;
  @Input() reactionsEngagementData: InfoOptions[]; //FB

  // FOLLOWERS TIMELINE DISTRIBUTION
  @Input() platform;
  @Input() followersTimeline: TimeSeriesItem[]; //FB

  // FOLLOWERS ENGAGEMENT TIMELINE
  @Input() engagementOptions;

  // AUDIENCE INTEREST DISTRIBUTION
  @Input() audienceInterestsDataOptions;

  hasPageTag: boolean;
  private readonly username;
  constructor(printService: PrintService) {
    this.totalPages = printService.totalPages;
    this.platform = printService.platform;
    this.hasPageTag = printService.isPaginated;
    this.username = printService.username;
  }

  // if no image url trigger sentry
  errorLoadingImage(): void {
    this.profileImageNotLoaded = true;
    throw Error(`[PDF: IMAGE URL NOT FOUND]: ${this.username}`);
  }
}
