import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePicker } from '../date-range.component';
import { DropDownOption } from '../../../../../../controls/src';
import * as moment from 'moment';
import { ContentTimeFilter } from '../../../@core/data/content-filter.service';
import { animate, style, transition, trigger } from '@angular/animations';
export enum Calender {
  thisMonth = 'This Month',
  lastMonth = 'Last Month',
  lastThreeMonth = 'Last 3 Month',
  lastSixMonth = 'Last 6 Month',
  customDate = 'Custom Date',
}

@Component({
  selector: 'ngx-date-range-v2',
  templateUrl: './date-range-v2.component.html',
  styleUrls: ['./date-range-v2.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('0.5s', style({ opacity: 1 }))]),
      transition(':leave', [animate('0.5s', style({ opacity: 0 }))]),
    ]),
  ],
})
export class DateRangeV2Component implements OnInit {
  defaultCalender = Calender.thisMonth;
  simpleOptionsFMultipleSelect: DropDownOption;
  calenderEnum = Calender;
  @Input() timeFilter: DatePicker | ContentTimeFilter;
  @Input() displayTimeStamp = false;
  @Output() onChange: EventEmitter<DatePicker | ContentTimeFilter> = new EventEmitter();

  calendeMenuChanged($event): void {
    this.defaultCalender = $event?.lastAction?.key;
    switch (this.defaultCalender) {
      case Calender.thisMonth:
        this.timeFilter.from = moment(new Date()).startOf('M').toDate();
        this.timeFilter.to = new Date();
        break;
      case Calender.lastMonth:
        this.timeFilter.from = moment(new Date()).subtract(1, 'M').toDate();
        this.timeFilter.to = new Date();
        break;
      case Calender.lastThreeMonth:
        this.timeFilter.from = moment(new Date()).subtract(3, 'M').toDate();
        this.timeFilter.to = new Date();
        break;
      case Calender.lastSixMonth:
        this.timeFilter.from = moment(new Date()).subtract(6, 'M').toDate();
        this.timeFilter.to = new Date();
        break;
      case Calender.customDate:
        this.timeFilter.from = new Date(
          this.timeFilter.from.getUTCFullYear(),
          this.timeFilter.from.getUTCMonth(),
          this.timeFilter.from.getUTCDate(),
        );
        this.timeFilter.to = new Date();
    }
    this.roundDateTimeFilter();
    this.onChange.emit(this.timeFilter);
  }
  datesChangeUpdate(): void {
    this.roundDateTimeFilter();
    this.onChange.emit(this.timeFilter);
  }

  ngOnInit(): void {
    this.timeFilter.from = moment(new Date()).startOf('M').toDate();
    this.timeFilter.to = new Date();

    this.simpleOptionsFMultipleSelect = {
      groupOptions: [
        {
          items: [
            {
              key: Calender.thisMonth,
              value: true,
            },
            {
              key: Calender.lastMonth,
              value: false,
            },
            {
              key: Calender.lastThreeMonth,
              value: false,
            },
            {
              key: Calender.lastSixMonth,
              value: false,
            },
            {
              key: Calender.customDate,
              value: false,
            },
          ],
          selector: 'checkmark',
          position: 'prefix',
        },
      ],
    };
    this.roundDateTimeFilter();
    this.onChange.emit(this.timeFilter);
  }

  roundDateTimeFilter() {
    const formatter = new Intl.DateTimeFormat('en-US');
    this.timeFilter.from = moment.utc(formatter.format(this.timeFilter.from), 'MM/DD/YYYY').startOf('day').toDate();
    this.timeFilter.to = moment.utc(formatter.format(this.timeFilter.to), 'MM/DD/YYYY').endOf('day').toDate();
  }
}
