/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/auth/auth.service';

@Component({
  selector: 'ngx-redirect-to-default',
  template: '',
})
export class RedirectToDefaultComponent implements OnInit {
  constructor(private router: Router, private auth: AuthService) {}

  async ngOnInit() {
    this.router.navigateByUrl(await this.auth.getDefaultHomeUrl());
  }
}
