<div class="form-group">
  <div class="filter-label-container">
    <label class="filter-label" *ngIf="showLabel">Languages</label>
    <ngx-badge *ngIf="showLabel" expiresAt="15-FEB-2023"></ngx-badge>
    <ngx-tooltip *ngIf="showLabel" size="large"
      [tooltip]="'Click - to exclude selected language.<br>Click + to include selected language.'"
      [placement]="'bottom-left'"></ngx-tooltip>
    <div class="toggle-switch-container">
      <ngx-toggle-switch @fadeInOut *ngIf="selectedLanguages?.length>1" [(searchModifier)]="searchModifiers"
        (searchModifierChange)="updateSearchModifierEvent()">
      </ngx-toggle-switch>
    </div>
  </div>
  <div class="input-group input-group-sm">
    <ng-select placeholder="Eg. English" notFoundText="No Languages found" dropdownPosition="bottom" [items]="languages"
      [multiple]="true" [hideSelected]="true" [virtualScroll]="true" [loading]="loadingLanguages" [markFirst]="false"
      (add)="triggerUpdateEvent()" (clear)="triggerUpdateEvent()" (remove)="triggerUpdateEvent()"
      [(ngModel)]="selectedLanguages" [searchFn]="searchLanguages">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value {{item.isIncluded}}" *ngFor="let item of items">
          <span class="ng-value-label">{{item.name}} </span>
          <span class="ng-value-icon" (click)="toggleLanguageLogic(item)" aria-hidden="true" *ngIf="item.isIncluded">
            <i class="fa fa-minus" aria-hidden="true"></i>
          </span>
          <span class="ng-value-icon" (click)="toggleLanguageLogic(item)" aria-hidden="true" *ngIf="!item.isIncluded">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span [ngOptionHighlight]="search">{{item.name}}</span><br>
      </ng-template>
    </ng-select>
  </div>
</div>