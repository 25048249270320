<div class="list-scroll">
  <div class="bar-chart" *ngFor="let option of barChartOptionsList; let i = index">
    <div class="row bar-chart-row">
      <div class="col-md-7">
        <a href="{{getProfileUrl(option?.username)}}" target="_blank"> 
          <nb-user class="bar-chart-user" [picture]="option.profilePicUrl"
                   [title]="option.fullName" [name]="option.username" size="large">
                   <img id="hidden_image" src="{{option.profilePicUrl}}" (error)="checkError(option.profilePicUrl, i)" style="display: none;"/>
          </nb-user>
        </a>
      </div>
      <div class="col-md-5">
          <nb-progress-bar [value]="getRelativePerc(option.value)" status="info">
            {{option.value | percent: '1.0-2'}}
          </nb-progress-bar>
      </div>
    </div>
  </div>
</div>

