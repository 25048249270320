<nb-layout windowMode>
  <nb-layout-column style="padding: 1rem 2rem;">
    <div class="jumbotron">
      <img style="height: 400px; padding: 3rem;" src="../../../../../assets/images/server-under-maintenance.svg">
      <h4>Where are we?</h4>
      <br>
      <p>Uh oh! We are under maintenance and will be back soon.</p>
      <p>We regret the inconvenience caused.</p>
      <br>
    </div>
  </nb-layout-column>
</nb-layout>
