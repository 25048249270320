<main>
  <div class="modal-header">
    <span>{{isEmailSequencingEnabled ? 'Email Sequences and Templates' : 'Email Templates'}}</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="viewInfo" class="info-container">
    <em class="fa-regular fa-circle-info"></em>
    <span>
      New and edited templates will be saved accross the organization
    </span>
    <button aria-label="Close" (click)="closeInfo()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-loader *ngIf="!types.length"></ngx-loader>
    <div class="action" *ngIf="types?.length">
      <form class="mr-4" *ngIf="invitationsTab?.active">
        <ai-input [formControl]="searchText" [disable]="isEditModeOn" placeholder="Search template">
        </ai-input>
      </form>
      <ai-button *ngIf="!emailSequencesTab?.active" [popper]="addToContextMenu" [popperTrigger]="'click'"
        popperApplyClass="context-menu" [disabled]="isEditModeOn" [popperPlacement]="'bottom-end'"
        label="CREATE NEW TEMPLATE" suffix="fa-regular fa-angle-down divider" length="block" type="outline"
        color="primary">
      </ai-button>
    </div>
    <nb-tabset #tabset *ngIf="types?.length">
      <nb-tab #emailSequencesTab [disabled]="isEditModeOn" tabTitle="Email Sequences" *ngIf="isEmailSequencingEnabled">
        <div class="templates">
          <ngx-email-sequences #emailSequencesComp [isSharedCampaign]="isSharedCampaign" [campaignSlugName]="campaignSlugName" (close)="closeModal()"
            [platform]="platform">
          </ngx-email-sequences>
        </div>
      </nb-tab>
      <nb-tab #invitationsTab [disabled]="isEditModeOn"
        tabTitle="{{isEmailSequencingEnabled? 'Email Templates' : 'Invitations & Reminders'}}">
        <div class="templates">
          <ngx-invitations #invitationsComp [searchText]="searchText.value" [campaignSlugName]="campaignSlugName"
            [types]="invitationTypes" [supportedTemplatingVariables]="supportedTemplatingVariables"></ngx-invitations>
        </div>
      </nb-tab>
      <nb-tab #autoReplyTab tabTitle="Auto-replies" [disabled]="isEditModeOn">
        <ngx-auto-reply-tab #autoReplyTabComp [campaignSlugName]="campaignSlugName"
          [contentApprovalTypes]="contentApprovalTypes" [proposalTypes]="proposalTypes"
          [supportedTemplatingVariables]="supportedTemplatingVariables"></ngx-auto-reply-tab>
      </nb-tab>
    </nb-tabset>
  </div>
</main>

<popper-content #addToContextMenu>
  <div class="list-group">
    <ng-container>
      <label>Invitation & Reminders</label>
      <div class="list-group-item list-group-item-action" (click)="createNewTemplate();addToContextMenu.hide()">
        Invitation/Reminder Email
      </div>
      <div class="h-separator"></div>
    </ng-container>
    <ng-container>
      <label>Auto-replies</label>
      <div class="list-group-item list-group-item-action"
        (click)="createAutoReplyTemplate(0, 'proposal'); addToContextMenu.hide()">
        Proposal Approval
      </div>
      <div class="list-group-item list-group-item-action"
        (click)="createAutoReplyTemplate(1, 'proposal');addToContextMenu.hide()">
        Proposal Rejection
      </div>
      <div class="list-group-item list-group-item-action"
        (click)="createAutoReplyTemplate(0, 'content-approval'); addToContextMenu.hide()">
        Content Approval
      </div>
      <div class="list-group-item list-group-item-action"
        (click)="createAutoReplyTemplate(1, 'content-approval');addToContextMenu.hide()">
        Content Reviewed
      </div>
    </ng-container>
  </div>
</popper-content>