<nb-card class="modal-card">
    <nb-card-header class="modal-header"><label>
            {{isOnEditMode ? 'Edit Email Sequence': 'New Email Sequence'}}

        </label>
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </nb-card-header>
    <nb-card-body class="modal-body">
        <div *ngIf="isLoading" class="loading-parent">
            <ngx-loader class="modal-loader"></ngx-loader>
        </div>
        <div class="row mt-1" *ngIf="!isLoading">
            <div class="col-sm-12">
                <div *ngIf="isExistingSequenceEnabled" class="info-container">
                    <i class="far fa-exclamation-triangle" aria-hidden="true"></i>
                    <span>
                        This sequence is
                        <strong>ENABLED.</strong> Changes will reflect only for emails not yet sent and for influencers
                        not yet added.
                    </span>
                </div>
                <form [formGroup]="sequenceForm">
                    <div class="row mt-2">
                        <label class="col-sm-3">Sequence Name<sup>*</sup></label>
                        <div class="col-sm-9">
                            <ai-input label="" key="name" placeholder="Enter sequence name">
                            </ai-input>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-3">Timezone</label>
                        <div class="col-sm-9">
                            <ai-select label="" [clearable]="false" [items]="timezones" bindValue="key"
                                bindLabel="label" key="timezone">
                            </ai-select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-3">
                            Terminate sequence when: 
                            <ngx-tooltip
                                style="float: none;"
                                [tooltip]="'Termination policy cannot be changed once sequence is enabled'">
                            </ngx-tooltip>
                            <i *ngIf="isGetWillBeTerminatedUsernamesLoading" class="fa-light fa-circle-notch fa-spin"></i>
                        </label>
                        <div class="col-sm-9">
                            <ai-select label="" [items]="terminationOptions" key="terminationRule" bindValue="value"
                                bindLabel="label" [clearable]="false" [searchable]="false" [disable]="isExistingSequenceEnabled || isGetWillBeTerminatedUsernamesLoading"
                                [isWarnBorderEnabled]="!!willBeTerminatedUsernames?.length">
                            </ai-select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label class="col-sm-3">When to deliver: </label>
                        <div class="col-sm-4">

                            <ai-select label="" [items]="deliveryDays" key="whenToDeliver" bindValue="value"
                                bindLabel="label" [clearable]="false" [searchable]="false">
                            </ai-select>
                        </div>
                        <div class="col-sm-5 row delivery-range">
                            <div class="col row">
                                <label class="col-sm-3">From: </label>
                                <div class="col-sm-9">

                                    <ai-select label="" [items]="fromAvailableHours" key="fromTime" bindValue="value"
                                        bindLabel="label" [clearable]="false" [searchable]="false">
                                    </ai-select>
                                </div>

                            </div>
                            <div class="col row">
                                <label class="col-sm-3">To: </label>
                                <div class="col-sm-9">

                                    <ai-select label="" [items]="toAvailableHours" key="toTime" bindValue="value"
                                        bindLabel="label" [clearable]="false" [searchable]="false">
                                    </ai-select>
                                </div>

                            </div>
                        </div>
                    </div>

                    <ai-influencer-select [selectedInfluencers]="copyOfSelectedInfluencers" [platform]="platform"
                        [campaignSlugName]="campaignSlugName" [loadingInfluencers]="isLoadingInfluencers"
                        [maxNumberOfInfluencersVisible]="defaultNumberOfInfluencersVisible" [isRequired]="false"
                        [isPaginated]="isOnEditMode" [totalInfluencers]="totalExistingInfluencers"
                        (updatedInfluencersEmitter)="receiveUpdatedInfluencers($event)" [isScrollable]="true"
                        [selectedTerminationRule]="selectedTerminationRule"
                        (fetchMoreEmitter)="populateInfluencersWithDetails()" [singleItemClearable]="false">
                    </ai-influencer-select>
                </form>
            </div>
        </div>
        <div class="row mt-1 action-btns">
            <ai-button type="ghost" (click)="closeModal()" label="DISCARD" [disabled]="isLoading">
            </ai-button>
            <ai-button (click)="toNextStep()" [disabled]="isNextDisabled" label="SAVE AND NEXT">
            </ai-button>
        </div>
    </nb-card-body>
</nb-card>