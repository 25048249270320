import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  InstagramProfile,
  InstagramInfluencerMedia,
  InstagramInfluencerBrandMedia,
  InstagramPredictedPriceRange,
  BrandAffinityData,
} from '../../../interfaces';
import { AudienceStats, TimeSeriesItem } from '../influencer.service';

@Injectable()
export class InstagramProfileService {
  constructor(private http: HttpClient) {}

  getInstagramProfile(username: string): Promise<InstagramProfile | null> {
    return this.http
      .get(environment.api + `/api/instagram/profile/${username}`)
      .toPromise()
      .then((json: any) => {
        if (json) {
          return this.mapJsonToInstagramProfile(json);
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  async getInfluencerMedias(username: string): Promise<InstagramInfluencerMedia[]> {
    return this.http
      .get(environment.api + `/api/instagram/profile/${username}/media`)
      .toPromise()
      .then((json: any) => {
        if (json) {
          return json;
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  async getFollowerEngagementTimeline(username: string): Promise<any> {
    return this.http
      .get(environment.api + `/api/instagram/profile/${username}/engagement-timeline`)
      .toPromise()
      .then((json: any) => {
        if (json) {
          return json;
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  async getInfluencerBrandedContentDist(username: string, days: number): Promise<any> {
    return this.http
      .get(environment.api + `/api/instagram/profile/${username}/branded-content-distribution`, {
        params: { days: days.toString() },
      })
      .toPromise()
      .then((json: any) => {
        if (json) {
          return json;
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  async getBrandMedias(username: string, brand: string): Promise<InstagramInfluencerBrandMedia[]> {
    return this.http
      .get(environment.api + `/api/instagram/profile/${username}/brands/${brand}/medias`)
      .toPromise()
      .then((json: any) => {
        if (json) {
          return json;
        }
        return null;
      })
      .catch((err) => {
        if (err.status === 404) {
          return null;
        }
        throw err;
      });
  }

  async getInfluencerPriceRanges(username: string): Promise<InstagramPredictedPriceRange[]> {
    return this.http
      .get<InstagramPredictedPriceRange[]>(environment.api + `/api/instagram/profile/${username}/prices`)
      .toPromise()
      .catch((err) => {
        if (err.status === 404) {
          return [];
        }
        throw err;
      });
  }

  getInfluencerFollowerTimeseries(username: string): Promise<{ followersTimeseries: TimeSeriesItem[] }> {
    return this.http
      .get<{ followersTimeseries: TimeSeriesItem[] }>(
        environment.api + `/api/instagram/profile/${username}/follower-timeline`,
      )
      .toPromise();
  }

  getSortedBrandAffinityData(
    username: string,
    sortByField = 'recency',
    isSponsored = 'false',
  ): Promise<BrandAffinityData[]> {
    let queryParams = new HttpParams().append('sortBy', sortByField);
    queryParams = queryParams.append('isSponsored', isSponsored);
    return this.http
      .get<BrandAffinityData[]>(environment.api + `/api/instagram/profile/${username}/brands`, { params: queryParams })
      .toPromise();
  }

  exportInfluencerProfile(username: string): Promise<any> {
    return this.http
      .get(`${environment.api}/api/instagram/export/profile/${username}`, {
        responseType: 'blob',
      })
      .toPromise();
  }

  analyzeAudience(username: string): Promise<any> {
    return this.http.post(`${environment.api}/api/instagram/profile/${username}/analyze-audience`, {}).toPromise();
  }

  bulkAnalyzeAudience(
    usernames: string[],
  ): Promise<{ inProgressUsernames: string[]; availableUsernames: string[]; failedUsernames: string[] }> {
    return this.http
      .post<{ inProgressUsernames: string[]; availableUsernames: string[]; failedUsernames: string[] }>(
        `${environment.api}/api/instagram/analyze-audience`,
        { usernames },
      )
      .toPromise();
  }

  mapJsonToInstagramProfile(json: any): InstagramProfile {
    return {
      ...json,
      audienceStatistics: this.mapJsonToAudienceStatistics(json['audienceStatistics']),
    };
  }

  mapJsonToAudienceStatistics(json: any): AudienceStats | null {
    if (!json) {
      return null;
    }

    return {
      ageDistribution: {
        buckets: json['ageDistribution'],
      },
      adultAudiencePercentage: json['adultAudiencePercentage'],
      ageGenderDistribution: json['ageGenderDistribution'],
      genderDistribution: {
        buckets: json['genderDistribution'],
      },
      locationDistribution: json['locationDistribution'],
      cityDistribution: json['cityDistribution'],
    };
  }
}
