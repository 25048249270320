<div class="tooltip-container">
  <span class="tooltip-wrapper" [ngbPopover]="bubbleContent" [placement]="placement" triggers="hover" container="body">
      {{baseText}}
  </span>
</div>
<ng-template #bubbleContent>
    <div *ngFor="let tooltip of tooltips">
      {{tooltip}}
    </div>
</ng-template>
