import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NbAuthComponent, NbLogoutComponent, NbResetPasswordComponent } from '@nebular/auth';
import {
  NgxInsufficientPermissionComponent,
  ServerErrorComponent,
  NgxLoginComponent,
  NgxNotFoundComponent,
  NgxPublicRequestPasswordComponent,
  NgxPublicResetPasswordLinkComponent,
  NgxRequestPasswordComponent,
  NgxServerUnavailableErrorComponent,
  PublicAppLoginComponent,
  PublicAppSubscriptionPlans,
  PublicAppSubscriptionSuccess,
  SignupComponent,
} from './@theme/components';
import { AuthGuardService } from './@core/auth/auth-guard.service';
import { PrintModule } from './pages/print/print.module';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(({ PagesModule }) => PagesModule),
  },
  {
    path: 'collabs',
    loadChildren: () =>
      import('./collaborator/collaborator.module').then(({ CollaboratorModule }) => CollaboratorModule),
  },
  {
    path: 'subscription',
    component: PublicAppSubscriptionPlans,
    canActivate: [AuthGuardService],
  },
  {
    path: 'subscription/success',
    component: PublicAppSubscriptionSuccess,
    canActivate: [AuthGuardService],
  },
  {
    path: 'creators',
    loadChildren: () => import('./creators/creators.module').then(({ CreatorsModule }) => CreatorsModule),
  },
  {
    path: 'shopify',
    loadChildren: () => import('./shopify/shopify.module').then(({ ShopifyModule }) => ShopifyModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NgxLoginComponent,
      },
      {
        path: 'login',
        component: NgxLoginComponent,
      },
      {
        path: 'public/login',
        component: PublicAppLoginComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NgxRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
      {
        path: 'signup',
        component: SignupComponent,
      },
      {
        path: 'public/request-password',
        component: NgxPublicRequestPasswordComponent,
      },
    ],
  },
  {
    path: 'auth/public/reset-password-link',
    component: NgxPublicResetPasswordLinkComponent,
  },
  {
    path: '500ServerError',
    component: ServerErrorComponent,
  },
  {
    path: '403InsufficientPermission',
    component: NgxInsufficientPermissionComponent,
  },
  {
    path: 'ServerUnderMaintenance',
    component: NgxServerUnavailableErrorComponent,
  },
  {
    path: '404-not-found',
    component: NgxNotFoundComponent,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), PrintModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
