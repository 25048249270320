import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../../@core/data/campaigns.service';
import { Platforms, VeraPlatforms } from '../../enums';
import { ActiveCampaign } from '../../pages/community/community.type';

@Component({
  selector: 'ngx-influencer-campaign-list',
  styleUrls: ['./influencer-campaign-list.component.scss'],
  templateUrl: './influencer-campaign-list.component.html',
})
export class InfluencerCampaignListComponent {
  @Input() campaigns: Campaign[] = [];
  @ViewChild('allCampaigns') allCampaignsModal: TemplateRef<unknown>;

  constructor(private modalService: NgbModal, private router: Router) {}

  openAllCampaignsModal(): void {
    this.modalService.open(this.allCampaignsModal, {
      windowClass: '',
      centered: true,
      backdrop: 'static',
    });
  }

  navigateToCommunity(campaign: ActiveCampaign | Campaign, modal?: NgbActiveModal): void {
    if (!campaign.sharedWith) {
      return;
    }
    const campaignsBaseUrl =
      campaign.platform.toLowerCase() === Platforms.instagram.toLowerCase()
        ? '/pages/campaigns'
        : campaign.platform.toLowerCase() === VeraPlatforms.instagram.toLowerCase()
        ? '/pages/veraInstagram/campaigns'
        : '/pages/' + campaign.platform.toLowerCase() + '/campaigns';
    this.router.navigate([campaignsBaseUrl + '/view/' + campaign.campaignSlugName]);
    modal && modal.close();
  }
}
