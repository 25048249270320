import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AudienceFilters, InfluencerFilters } from '../../@core/data/influencer-filter.service';
import { InfluencerSearchFilterService } from '../../@core/data/influencer-search-filter.service';
import { InfluencerSortKey, Platforms } from '../../enums';
import { InfluencerSavedSearchFilters } from '../../interfaces';
import { LoadFilterModalComponent } from '../load-filter-modal/load-filter-modal.component';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { SaveFilterModalComponent } from '../save-filter-modal/save-filter-modal.component';

@Component({
  selector: 'ngx-save-influencers-search-filters',
  templateUrl: './save-influencer-search-filters.component.html',
  styleUrls: ['./save-influencer-search-filters.component.scss'],
})
export class SaveInfluencersSearchFilterComponent implements OnDestroy {
  @Input() platform: Platforms;
  @Input() influencerFilters: InfluencerFilters;
  @Input() audienceFilters: AudienceFilters;
  @Input() sortInfluencersKey: InfluencerSortKey;
  @Input() showResetButton: boolean;

  searchFiltersChangedSubscription: Subscription;
  selectedFiltersChangedSubscription: Subscription;

  savedSearchFilters: InfluencerSavedSearchFilters[] = [];
  selectedSearchFilter: InfluencerSavedSearchFilters;

  @Output() resetFiltersEvent: EventEmitter<void> = new EventEmitter();

  constructor(
    private filterService: InfluencerSearchFilterService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
  ) {
    this.searchFiltersChangedSubscription = this.filterService.influencerSavedSearchFiltersChanged$.subscribe(
      (savedSearchFilters) => {
        this.savedSearchFilters = savedSearchFilters;
      },
    );
    this.selectedFiltersChangedSubscription = this.filterService.selectedSavedSearchFiltersChanged$.subscribe(
      (savedSearchFilters) => {
        this.selectedSearchFilter = savedSearchFilters;
      },
    );
  }

  ngOnDestroy(): void {
    this.searchFiltersChangedSubscription.unsubscribe();
    this.selectedFiltersChangedSubscription.unsubscribe();
  }

  loadFilter(filter: InfluencerSavedSearchFilters): void {
    this.filterService.setSelectedInfluencerSavedSearchFilters(filter);
  }

  async openSaveFilterModal(): Promise<void> {
    const saveFilterModal = this.modalService.open(SaveFilterModalComponent, {
      windowClass: 'fit-content-modal',
      centered: true,
      backdrop: 'static',
    });

    const modalContent: SaveFilterModalComponent = saveFilterModal.componentInstance;
    modalContent.savedSearchFilters = this.savedSearchFilters;
    await saveFilterModal.result
      .then(async (res) => {
        if (res['saveAsNew']) {
          const searchFilters = {
            platform: this.platform,
            filterPrivacy: res.filterPrivacy,
            isDefault: res.isDefault,
            filterName: res.searchName,
            filter: {
              influencerFilters: this.influencerFilters,
              audienceFilters: this.audienceFilters,
              sortKey: this.sortInfluencersKey,
            },
            sharedWithOrgs: res.sharedWithOrgs,
          };
          this.saveSearchFilters(searchFilters);
        } else {
          const searchFilters: Partial<InfluencerSavedSearchFilters> = {
            platform: this.platform,
            filterPrivacy: res.filterPrivacy,
            isDefault: res.isDefault,
            filterSlugName: res.selectedSlugName,
            filter: {
              influencerFilters: this.influencerFilters,
              audienceFilters: this.audienceFilters,
              sortKey: this.sortInfluencersKey,
            },
          };
          this.editSearchFilters(searchFilters);
        }
      })
      .catch(() => null);
  }

  async openEditFilterModal(selectedFilter: InfluencerSavedSearchFilters): Promise<void> {
    const saveFilterModal = this.modalService.open(SaveFilterModalComponent, {
      windowClass: 'fit-content-modal',
      centered: true,
      backdrop: 'static',
    });

    const modalContent: SaveFilterModalComponent = saveFilterModal.componentInstance;
    modalContent.savedSearchFilters = this.savedSearchFilters;
    modalContent.selectedFilter = selectedFilter;
    modalContent.editMode = true;
    await saveFilterModal.result
      .then(async (res) => {
        const searchFilters: Partial<InfluencerSavedSearchFilters> = {
          platform: this.platform,
          filterPrivacy: res.filterPrivacy,
          isDefault: res.isDefault,
          filterSlugName: selectedFilter.filterSlugName,
        };
        // for replacing search `a`, we will delete `a` and rename selected search with `a.filterName`
        if (!res['saveAsNew']) {
          const filterToDelete: InfluencerSavedSearchFilters = this.savedSearchFilters.find(
            (f) => f.filterSlugName === res.selectedSlugName,
          );
          await this.filterService.deleteSearchFilters(this.platform, filterToDelete.filterSlugName);
          searchFilters['filterName'] = filterToDelete.filterName;
        } else {
          searchFilters['filterName'] = res.searchName;
          searchFilters['sharedWithOrgs'] = res.sharedWithOrgs;
        }
        this.editSearchFilters(searchFilters);
      })
      .catch(() => null);
  }

  saveSearchFilters(searchFilters: InfluencerSavedSearchFilters): void {
    this.filterService
      .saveSearchFilters(searchFilters)
      .then(() => {
        this.toastrService.success('Filters saved');
        this.filterService.fetchSearchFilters(this.platform, true);
      })
      .catch(() => {
        this.toastrService.error('Failed to save search filters');
      });
  }

  editSearchFilters(searchFilters: Partial<InfluencerSavedSearchFilters>): void {
    this.filterService
      .updateSearchFilters(searchFilters)
      .then(() => {
        this.toastrService.success('Filters updated');
        this.filterService.fetchSearchFilters(this.platform, true);
      })
      .catch(() => {
        this.toastrService.error('Failed to update search filters');
      });
  }

  openLoadFilterModal(): void {
    const loadFilterModal = this.modalService.open(LoadFilterModalComponent, {
      windowClass: 'fit-content-modal',
      centered: true,
      backdrop: 'static',
    });

    const modalContent: LoadFilterModalComponent = loadFilterModal.componentInstance;
    modalContent.savedSearchFilters = this.savedSearchFilters;
    loadFilterModal.result.then(async (res) => {
      if (res) {
        switch (res.action) {
          case 'load':
            this.loadFilter(res.filter);
            break;
          case 'edit':
            if (res.filter.filterPrivacy === 'global') {
              const filter = await this.filterService.fetchSearchFilterBySlugName(
                this.platform,
                res.filter.filterSlugName,
              );
              res.filter.sharedWithOrgs = filter.sharedWithOrgs;
            }
            this.openEditFilterModal(res.filter);
            break;
          case 'delete':
            this.openDeleteFilterModal(res.filter);
            break;
          case 'default':
            this.updateDefaultSearch(res.filter);
            break;
          case 'privacy':
            this.setFilterPrivacy(res.filter);
            break;
        }
      }
    });
  }

  async openDeleteFilterModal(searchFilter: InfluencerSavedSearchFilters): Promise<void> {
    const confirmDeleteModal = this.modalService.open(MessageModalComponent, {
      size: 'lg',
      centered: true,
    });
    const modalContent = confirmDeleteModal.componentInstance as MessageModalComponent;
    modalContent.headerText = 'Delete Search';
    modalContent.contentText = `Are you sure you want to delete the saved search ${searchFilter.filterName}`;

    const deleteConfirmed = await confirmDeleteModal.result;
    if (deleteConfirmed) {
      this.filterService
        .deleteSearchFilters(this.platform, searchFilter.filterSlugName)
        .then(() => {
          this.toastrService.success(`Deleted Search ${searchFilter.filterName}`);
          if (this.selectedSearchFilter?.filterSlugName === searchFilter.filterSlugName) {
            this.filterService.setSelectedInfluencerSavedSearchFilters(null);
          }
          this.filterService.fetchSearchFilters(this.platform, true);
        })
        .catch((err) => {
          if (err?.error?.errCode === 'NOT_AUTHORIZED') {
            this.toastrService.error(err?.error?.message);
          } else {
            this.toastrService.error('Failed to delete search filters');
          }
        });
    }
  }

  updateDefaultSearch(filter: InfluencerSavedSearchFilters): void {
    this.filterService
      .updateDefaultSearch(this.platform, filter.filterSlugName, !filter.isDefault)
      .then(() => {
        this.toastrService.success('Default search updated');
        this.filterService.fetchSearchFilters(this.platform);
      })
      .catch(() => {
        this.toastrService.error('Failed to update search filters');
      });
  }

  setFilterPrivacy(filter: InfluencerSavedSearchFilters): void {
    const filterPrivacy = filter.filterPrivacy;
    const searchFilters: Partial<InfluencerSavedSearchFilters> = {
      platform: this.platform,
      filterPrivacy: filter.filterPrivacy,
      filterSlugName: filter.filterSlugName,
    };
    this.filterService
      .updateSearchFilters(searchFilters)
      .then(() => {
        this.toastrService.success(`Search marked ${filterPrivacy === 'org' ? 'shared' : filterPrivacy}`);
        this.filterService.fetchSearchFilters(this.platform);
      })
      .catch(() => {
        this.toastrService.error('Failed to update search filters');
      });
  }

  sendRestFiltersEvent(): void {
    this.resetFiltersEvent.emit();
  }

  get resetButtonVisible(): boolean {
    return this.showResetButton;
  }

  get filterNameVisible(): boolean {
    return this.selectedSearchFilter && this.showResetButton;
  }
}
