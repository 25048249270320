import { Component, Input, OnInit } from '@angular/core';
import { PROFILE_TOOLTIPS } from '../../enums';
import { LinkedInfluencers } from '../../interfaces';

@Component({
  selector: 'ngx-social-profiles',
  templateUrl: './social-profiles.component.html',
  styleUrls: ['./social-profiles.component.scss'],
})
export class SocialProfilesComponent implements OnInit {
  @Input() socialMediaProfiles: LinkedInfluencers;
  @Input() printable = false;

  tooltipMessages: Map<string, string>;

  ngOnInit(): void {
    this.tooltipMessages = new Map<string, string>();
    Object.keys(PROFILE_TOOLTIPS).forEach((key) => this.tooltipMessages.set(key, PROFILE_TOOLTIPS[key]));
  }

  openUrl(url: string): void {
    if (!url.includes('www') && !url.includes('http')) {
      // complete the url if abc.com/username
      url = `http://www.${url}`;
    }
    window.open(url, '_blank');
  }
}
