import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit {
  chartOptions: any = {};

  @Input()
  gaugeOptions: GaugeOptions;

  @Input()
  printingStyle: string;

  @Input()
  withBorder: boolean;

  enableColourLevels = false;
  colorConfig = {
    DANGER: '#ff4c6a',
    WARNING: '#ffa100',
    GOOD: '#40dc7e',
    VERY_GOOD: 'green',
  };
  private _finalColor = '#42db7d';

  ngOnInit(): void {
    this.enableColourLevels = this.gaugeOptions.badgeText !== undefined;
    if (this.gaugeOptions.colorConfig) {
      this.colorConfig = this.gaugeOptions.colorConfig;
    }
    this._finalColor = this.colorConfig[this.gaugeOptions.badgeColor] || this._finalColor;
    this.chartOptions = {
      series: [
        {
          name: this.gaugeOptions.notApplicable ? 'NA' : '',
          clockWise: true,
          hoverAnimation: false,
          center: ['45%', '50%'],
          radius: this.withBorder ? ['70%', '90%'] : ['80%', '90%'],
          data: [
            {
              value: this.gaugeOptions.value,
              name: '',
              label: {
                normal: {
                  position: 'center',
                  formatter: this.gaugeOptions.notApplicable ? '{a}' : '{d}%',
                  textStyle: {
                    fontSize: '16',
                    fontWeight: '600',
                    color: this.gaugeOptions.notApplicable ? '#8f9bb3' : '#000000',
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: this._finalColor,
                },
              },
              tooltip: {
                show: false,
              },
              hoverAnimation: false,
            },
            {
              value: 100 - this.gaugeOptions.value,
              name: '',
              tooltip: {
                show: false,
              },
              hoverAnimation: false,
              itemStyle: {
                normal: {
                  color: '#ebeff5',
                },
              },
              label: {
                normal: {
                  position: 'inner',
                },
              },
            },
          ],
        },
      ],
    };
  }
}

export class GaugeOptions {
  value: any;
  valueFormatter?: string;
  label?: string;
  subLabel?: string;
  tooltip?: string;
  badgeColor?: string;
  badgeText?: string;
  colorConfig?: any;
  noteKey?: string;
  noteValue?: string;
  notApplicable?: boolean;
}
