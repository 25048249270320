import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinksGroupedByDates } from '../../../interfaces';

@Component({
  selector: 'ngx-links-list',
  templateUrl: './links-list.component.html',
  styleUrls: ['./links-list.component.scss'],
})

// To use this, you also need to register all its dependencies
// which are ListNotesComponent, DateGroupedNoteComponent, NoteComponent
export class LinksListComponent {
  @Input() listIndex: number;

  @Input() linksGroupedByDates: LinksGroupedByDates[];

  @Input() username: string;

  @Output() editedLinkEventEmitter = new EventEmitter();
  @Output() deletedLinkEventEmitter = new EventEmitter();

  captureEditedLink($event) {
    const groupIndex = $event.groupIndex;
    const linkIndex = $event.linkIndex;
    const listIndex = this.listIndex;
    const linkTitle = $event.linkTitle;
    this.editedLinkEventEmitter.emit({
      linkIndex,
      groupIndex,
      listIndex,
      linkTitle,
    });
  }

  captureDeletedLink($event) {
    const groupIndex = $event.groupIndex;
    const linkIndex = $event.linkIndex;
    const listIndex = this.listIndex;
    this.deletedLinkEventEmitter.emit({
      linkIndex,
      groupIndex,
      listIndex,
    });
  }
}
