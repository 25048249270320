import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { RELEASE_FLAGS } from '../feature-flag/flags';
import { environment } from '../../../environments/environment';

@Injectable()
export class RedirectToVeraGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public ffService: FeatureFlagService) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Intention of this is to only redirect to Vera if we consider it to be released globally
    // This is so that we can test it on app.beta.affable.ai and verify that it works
    // Once we are happy, this can either be remove from env, or added to all envs.
    // We redirect IFF both vera is considered released, and the feature flag is enabled!
    if (!environment.veraReleased) {
      return true;
    }
    const veraEquivalentUrl = this.buildVeraInstagramUrl(state.url);
    this.router.navigateByUrl(veraEquivalentUrl);
    return false;
  }

  private buildVeraInstagramUrl(url: string): string {
    const segments = url.split('/');
    segments.splice(2, 0, 'veraInstagram');
    return segments.join('/');
  }
}
