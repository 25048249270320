import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BrandWithMediaItems, MediaItem } from '../../@core/data/influencer.service';

@Component({
  selector: 'ngx-brand-user',
  templateUrl: './brand-user.component.html',
  styleUrls: ['./brand-user.component.scss'],
})
export class BrandUserComponent {
  @Input() brand: BrandWithMediaItems;
  @Input() sponsoredTooltip: string;
  @Output() toggleBrand = new EventEmitter<BrandWithMediaItems>();

  toggleBrandMenu(brand: BrandWithMediaItems): void {
    this.toggleBrand.emit(brand);
  }

  openInstagramPost(post: MediaItem): void {
    window.open(post.postURL, '_blank');
  }
}
