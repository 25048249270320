<div class="aiControl" (click)="parent === 'dropdown' ? aiControl.click() : ''">
  <input
    #aiControl
    [class]="customClasses"
    [formControl]="control"
    [id]="'cb-' + controlId"
    type="checkbox"
    [checked]="checked"
    [indeterminate]="indeterminate"
    (change)="handleChange($event);change.emit($event)"
  />
    <label [for]="'cb-' + controlId" [class]="classes" [class.wrap]="!labelSpacing">
      <div class="picture-container" *ngIf="image">
        <div [class]="'size-'+labelType" [ngStyle]="getImageStyle"></div>
        <img [class]="'verfication-badge '+labelType" src="assets/images/verified-badge.png"*ngIf="isRegistered"/>
      </div>
      <div *ngIf="!!label || !!subLabel" [class]="'labelText labelContent-'+labelType+' text-'+type+' overflow-'+overflow">
        <span *ngIf="label" [innerHTML]="label"></span>
        <span [class]="'placeholder-text subLabel-'+labelType" *ngIf="subLabel" [innerHTML]="getSubLabelDisplay()"></span>
      </div>
    </label>
    <span class="suffix body-3" *ngIf="suffix">{{suffix}}</span>
    <div style="cursor: pointer" *ngIf="type === 'checkmark'" (click)="aiControl.click()">
      <i class="fa-light fa-check" [ngClass]="!checked ? 'ai-op1' : 'ai-op100'"> </i>
    </div>
</div>
