<nb-accordion multi>
  <nb-accordion-item [expanded]="isExpanded">
    <nb-accordion-item-header>Shipping</nb-accordion-item-header>
    <nb-accordion-item-body>

      <ngx-loader *ngIf="loading"></ngx-loader>

      <div *ngIf="!loading">

        <form class="shipping-form" [formGroup]="shippingAddressForm">

          <ai-input label="First Name" [required]="true" key="firstName" name="firstName">
          </ai-input>
          <ai-input label="Last Name" [required]="true" key="lastName" name="lastName"></ai-input>
          <ai-input label="Email" type="email" key="email" name="email">
          </ai-input>

          <ai-tel-input label="Phone" name="phone" key="phone" [selectedCountryISO]="phoneCode">
          </ai-tel-input>
          <ai-textarea label="Address Line 1" [required]="true" placeholder="Shipping address here" key="line1"
            name="line1"></ai-textarea>

          <ai-textarea label="Address Line 2" placeholder="Additional address here" key="line2" name="line2">
          </ai-textarea>

          <ai-select label="Country" bindValue="value" name="country" bindLabel="name" [required]="true"
            [items]="countries" key="country"></ai-select>

          <ai-input label="City" [required]="true" key="city" name="city"></ai-input>
          <ai-input label="State" [required]="true" key="state" name="state"></ai-input>
          <ai-input label="Postal Code" [required]="true" key="postalCode" name="postalCode">
          </ai-input>
          <div class='row button-row'>
            <ai-button type="text" (click)="resetForm()" label="RESET"></ai-button>
            <ai-button [disabled]="isSubmitDisabled()" (click)="onSubmit()" label="SUBMIT"></ai-button>
          </div>
        </form>
      </div>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>