import { Component, Input, OnInit } from '@angular/core';
import { InfluencerLookup } from '../../interfaces';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MailType, SubscribedClientUser, VeraPlatforms } from '../../enums';
import { NewChatComponent } from '../../chat/new-chat/new-chat.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from '../../@core/data/chat.service';
import { UserService } from '../../@core/data/user.service';

@Component({
  selector: 'influencer-registration-invite',
  templateUrl: './influencer-registration-invite.component.html',
  styleUrls: ['./influencer-registration-invite.component.scss'],
})
export class InfluencerRegistrationInviteComponent implements OnInit {
  @Input()
  unRegisteredInfluencers: InfluencerLookup[] = [];
  @Input()
  enableBorderColoring = true;

  platform = VeraPlatforms.instagram;
  defaultTemplateMessage: string;
  defaultSubjectMessage: string;
  registrationTemplatingVariables: string[];
  user: SubscribedClientUser;

  constructor(
    private chatService: ChatService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    const templates = await this.chatService.getTemplatesForRegistration('registration');
    this.user = await this.userService.getClientUser();
    this.registrationTemplatingVariables = templates?.supportedVariables ?? [];
    this.defaultTemplateMessage = templates?.invitationTypes[0]?.templates[0]?.template ?? '';
    this.defaultSubjectMessage = templates?.invitationTypes[0]?.templates[0]?.subject ?? '';
  }

  copyInviteLink(): void {
    navigator.clipboard.writeText(this.user.veraInvitationLink);
    this.toastrService.success('URL was copied to clipboard');
  }
  messageInfluencer(): void {
    const newChatModal = this.modalService.open(NewChatComponent, {
      size: 'lg',
      windowClass: 'new-email-modal',
      centered: true,
      backdrop: 'static',
    });
    const modalComponent = newChatModal.componentInstance as NewChatComponent;
    modalComponent.selectedInfluencers = this.unRegisteredInfluencers;
    modalComponent.platform = this.platform;
    modalComponent.defaultTemplateMessage = this.defaultTemplateMessage;
    modalComponent.subject = this.defaultSubjectMessage;
    modalComponent.mailType = MailType.default;
    modalComponent.supportedTemplatingVariables = this.registrationTemplatingVariables;
    modalComponent.modalHeader = 'Registration Invite';

    newChatModal.result.then(
      async (result) => {
        if (result) {
          if (result?.status?.failure === 0) {
            const now = new Date();
            this.toastrService.success(`Successfully initiated email with ${result.influencers?.length}
          ${result.influencers?.length === 1 ? 'influencer' : 'influencers'}`);
          }
        }
      },
      () => ({}),
    );
  }

  getClassesForContainer(): string {
    const classes = ['d-flex', 'align-items-center'];
    if (this.enableBorderColoring) {
      classes.push('bordered');
    }

    return classes.join(' ');
  }
}
