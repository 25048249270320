export enum RELEASE_FLAGS {
  TEST_FEATURE_FLAG = 'test-feature-flag',
  VERA_ESTIMATE_AUDIENCE = 'vera-estimate-audience',
  YT_REPORT_EDIT_COMMENT_SENTIMENT = 'yt-report-edit-comment-sentiment',
  VIEW_PENDING_REPORT = 'view-pending-report',
  VIEW_PENDING_REPORT_FOR_TT_AND_TT = 'view-pending-report-for-tt-and-yt',
  AGENCY_X_BRAND_GROUP = 'agency-x-brand-group',
  CONSOLE_AUTH_BANNER = 'console-authenticate-banner',
  ACTIVATE_BRAND_SETTINGS = 'activate-brand-settings',
  APP_UPDATES = 'app-updates',
  BULK_ESTIMATE_AUDIENCE = 'bulk-estimate-audience',
  IAM_MULTI_INSTANCE_SUPPORT = 'iam-multi-instance-support',
  ORGANIZATION_SETTINGS = 'organization-settings',
  COLLAB_PORTAL_INFLUENCER_ADDITIONAL_INFO = 'collab-portal-influencer-additional-info',
  NO_CHAT_SECURITY_BYPASS = 'no-chat-security-bypass',
}
