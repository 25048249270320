import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { merge } from 'lodash';
import { BaseEchartsPrintedComponent } from './echarts-printed.component';

@Component({
  selector: 'ngx-echarts-line',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss'],
})
export class EchartsLineComponent extends BaseEchartsPrintedComponent implements AfterViewInit, OnDestroy {
  options: any = {};
  themeSubscription: any;

  @Input() dataOptions: any = {};
  @Input() printingStyle: string;
  printedChart: boolean;

  constructor(private theme: NbThemeService) {
    super();
  }

  ngAfterViewInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      const echarts: any = config.variables.echarts;

      this.options = merge(
        {
          backgroundColor: echarts.bg,
          color: [colors.info],
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}',
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
                rotate: 0,
              },
            },
          ],
          yAxis: [
            {
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              splitLine: {
                lineStyle: {
                  color: echarts.splitLineColor,
                },
              },
              axisLabel: {
                textStyle: {
                  color: echarts.textColor,
                },
              },
            },
          ],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          series: [],
        },
        this.dataOptions,
      );
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  onChartEvent(event: any) {
    console.log(event);
  }
}
