<div class="modal-body">
  <div class="video-content-display" [style.height]="loaded ? 'auto' : '0px'">
    <iframe id="video-content-iframe" class="iframe-display" frameborder="0" allow="encrypted-media"
      (load)="loaded = true; progress.ref().complete();">
    </iframe>
  </div>
  <div class="video-content-information">
    <div class="video-content-header">
      <a *ngIf="inContentDiscoveryPage" href="/pages/youtube/profile/{{channelName}}" class="video-content-author"
        target="_blank">
        <nb-user [name]="video.title" [picture]="profilePictureUrl" [title]="channelName" size="large">
        </nb-user>
      </a>
      <div *ngIf="!inContentDiscoveryPage" class="video-content-author">
        <nb-user [name]="video.title" [picture]="profilePictureUrl" [title]="channelName" size="large">
        </nb-user>
      </div>
      <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="video-content-body">

      <div class="video-content-stats">
        <div class="stat" *ngIf="!hideStats && video?.estimatedReach">
          <label>Est. Reach</label> {{video.estimatedReach | formatNumber:2}}
        </div>
        <div class="stat" *ngIf="!hideStats && video?.estimatedImpressions">
          <label>Est. Impressions</label> {{video.estimatedImpressions | formatNumber: 2}}
        </div>
        <div class="stat" *ngIf="!hideStats && video?.estimatedMediaValue">
          <label>EMV</label> {{video.estimatedMediaValue | currency:currency}}
        </div>
        <div class="h-space" *ngIf="!hideStats"></div>
        <div class="stat">
          <i class="far fa-calendar"></i>
          {{publishedAt | ngxCapitalize}}
        </div>
        <div class="stat" *ngIf="!hideStats">
          <i class="{{isRestricted ? '' : 'far fa-eye'}}"></i>
          {{video.statistics.viewCount | formatNumber:0}} Views
        </div>
        <div class="stat" *ngIf="!hideStats">
          <i class="{{isRestricted ? 'ion-md-thumbs-up' : 'far fa-heart'}}"></i>
          {{video.statistics.likeCount | formatNumber:0}} Likes
        </div>
        <div class="stat" *ngIf="!hideStats">
          <i class="{{isRestricted ? 'fa fa-message-lines' : 'far fa-comment'}}"></i>
          {{video.statistics.commentCount | formatNumber:0}} Comments
        </div>
      </div>
      <div class="video-content-list" *ngIf="video.topicNames?.length > 0">
        <div class="video-content-list-header">Category</div>
        <span *ngFor="let topic of video.topicNames">
          {{topic}}
        </span>
      </div>
      <div class="video-content-caption youtube-description">{{video.description}}</div>
    </div>
    <div class="video-content-footer">
      <a class="btn btn-xs btn-primary btn-block" href="https://www.youtube.com/watch?v={{video.id}}" target="_blank">
        <div class="youtube-btn-text" *ngIf="isRestricted">
          <i class="fab fa-youtube" style="font-size: 20px; margin-right: 5px;"></i> GO TO YOUTUBE
        </div>
        <div *ngIf="!isRestricted">
          <i class="fab fa-youtube"></i> GO TO YOUTUBE
        </div>
      </a>
    </div>
  </div>
</div>