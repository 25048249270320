import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlAbstractDirective } from '../control';
import { CheckBoxType } from '../common.enum';

@Component({
  selector: 'ai-checkbox',
  templateUrl: './checkbox.control.html',
  styleUrls: ['./checkbox.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxControl),
      multi: true,
    },
  ],
})
export class CheckboxControl extends ControlAbstractDirective {
  @Input() checked: boolean;
  @Input() indeterminate: boolean;
  @Input() labelWidth: number | '' = '';
  @Input() labelSize: string = 'body-3';
  @Input() labelSpacing = true;
  @Input() customClasses: string;
  // more options goes here !
  @Input() type: CheckBoxType = 'checkbox';
  @Input() parent: 'dropdown' | '' = '';
  @Input() image: string;
  @Input() imageStyle: { [a: string]: string };
  @Input() subLabel: string;
  @Input() isRegistered: boolean;
  @Input() labelType: 'singleLine' | 'doubleLine' = 'singleLine';
  @Input() overflow: 'none' | 'hidden' = 'none';
  get classes() {
    return [`ai-w${this.labelWidth}`, this.labelSize, this.type];
  }
  getSubLabelDisplay() {
    if (this.labelType === 'singleLine') return `(${this.subLabel})`;
    else if (this.labelType === 'doubleLine') return `${this.subLabel}`;
  }
  @Output() change = new EventEmitter();

  /**
   * @ignore
   */
  onChange = (_: boolean) => {
    this.action.emit({ key: this.key, value: this.value });
  };
  /**
   * @ignore
   */
  onTouch = (_: boolean) => {};

  /**
   * @ignore
   */
  registerOnChange(fn: (_: boolean) => {}): void {
    this.onChange = fn;
  }

  /**
   * @ignore
   */
  registerOnTouched(fn: (_: boolean) => {}): void {
    this.onTouch = fn;
  }

  /**
   * @ignore
   */
  handleChange(event) {
    this.checked = event.srcElement.checked;
    this.onChange(this.checked);
    this.onTouch(this.checked);
  }

  get getImageStyle() {
    return {
      'background-image': 'url(' + this.image + ')',
      ...(this.imageStyle ? this.imageStyle : {}),
    };
  }
}
