import { PlatformsV2 } from './../../enums/social.enum';
import { InfluencerShippingDetails } from './../../interfaces/proposal.interface';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ShippingDetailsService {
  constructor(private http: HttpClient) {}

  @Output() cancelChangesEvent: EventEmitter<void> = new EventEmitter();

  cancelChanges(): void {
    this.cancelChangesEvent.emit();
  }

  getInfluencerShippingDetails(shippingId: string): Promise<InfluencerShippingDetails> {
    return this.http
      .get<InfluencerShippingDetails>(environment.api + `/api/shipping-details/${shippingId}`)
      .toPromise();
  }

  addInfluencerShippingDetails(
    shippingDetails: InfluencerShippingDetails,
    platform: PlatformsV2,
    username: string,
  ): Promise<InfluencerShippingDetails> {
    return this.http
      .put<InfluencerShippingDetails>(environment.api + `/api/shipping-details/`, {
        shippingDetails,
        metadata: {
          username,
          platform,
        },
      })
      .toPromise();
  }
}
