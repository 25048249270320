<!-- Empty Notes State -->
<div *ngIf="notes && notes.length === 0">
  <h6>No {{ (notesShown === 'all' || isCollabPortal )? '' : notesShown === 'shared' ? 'Shared ' : 'Private ' }}Notes Yet</h6>
  <br />
  <img class="img-center" src="{{ getEmptyNotesImageURL() }}" />
  <div innerHTML="{{ getEmptyNotesMessage() }}" class="empty-notes-message"></div>
</div>

<!-- Set of notes -->
<div *ngIf="notes && notes.length > 0">

  <div *ngIf="!isSingleUserOrg && !isCollabPortal" class="helper-text">
    {{getNotesMessage()}}
  </div>

  <nb-card *ngFor="let note of notes">
    <!-- Change capturing is transfered to this component from previous dateGroupedNoteComponent -->
    <ngx-note
      (deleteNoteEvent)="captureDeletedNote($event)"
      (editNoteEvent)="captureEditedNote($event)"
      (downloadFileEvent)="captureDownloadedFile($event)"
      [noteValue]="note"
      [ownNote]="checkIfOwnNote(note.clientId)"
      [isSingleUserOrg]="isSingleUserOrg"
      [isCollabPortal]="isCollabPortal"
    ></ngx-note>
  </nb-card>
</div>
