import { FeatureFlagService } from './../feature-flag/feature-flag.service';
import { escapeRouteParamsText } from './../../utils/common.utils';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../data/user.service';
import { AuthService } from './auth.service';
import { RELEASE_FLAGS } from '../feature-flag/flags';
import utils from '../utils/utils';

@Injectable()
export class AccountIdentityGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      if (this.authService.isAuthenticated()) {
        await this.featureFlagService
          .isFeatureReleased(RELEASE_FLAGS.IAM_MULTI_INSTANCE_SUPPORT)
          .then(async (isEnabled) => {
            if (isEnabled) {
              await this.userService.setupOrganizationsOfClient(
                false,
                utils.sanitzeURIQueryParam(route.queryParams?.['org']),
              );
            }
          });
        this.userService.getClientUser();
      } else {
        throw Error('Not authenticated');
      }
    } catch (error) {
      const escapedMessage = escapeRouteParamsText(error?.message);
      this.authService.notifyUnauthorized();
      this.router.navigate(['auth', 'login', { event: 'session-revoked', message: escapedMessage }]);
    }

    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return await this.canActivate(route);
  }
}
