<div class="aiControl" [ngClass]="{'warn-background': isWarnBorderEnabled}">
  <label class="sub-heading-h2" *ngIf="label" [innerHTML]="label" [ngClass]="{'required-field': required}">
  </label>
  <ng-select [items]="items" [bindLabel]="bindLabel" [bindValue]="bindValue" #aiControl [placeholder]="placeholder"
    (focus)="focused$.next(true)" (blur)="focused$.next(false)" [formControl]="control" [searchable]="searchable"
    [clearable]="clearable" [attr.disabled]="disabled ? '' : null" [readonly]="readonly">
    <ng-template ng-label-tmp let-item="item">
      <span class="ng-select-option"> {{item[bindLabel]}}</span>
    </ng-template>
  </ng-select>

  <div *ngIf="hint || error" class="body-4 mt-2 d-flex">
    <ng-container *ngIf="control.touched && error && !focused && !hideError; else hintTemplate">
      <span *ngIf="!hideError" [innerHTML]="error" class="error text-left"></span>
    </ng-container>
    <ng-template #hintTemplate>
      <span *ngIf="hint" [innerHTML]="hint" class="hint text-left"></span>
    </ng-template>
  </div>
</div>