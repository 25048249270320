import { Component } from '@angular/core';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ShowMessage } from '../../../../interfaces';
import { UserCredentials } from '../../../../enums/user.enum';

@Component({
  selector: 'ngx-request-password',
  styles: [
    ':host .links{display:flex;justify-content:space-between}:host .form-group:last-of-type{margin-bottom:3rem}',
  ],
  templateUrl: './request-password.component.html',
})
export class NgxRequestPasswordComponent {
  showMessages: ShowMessage = {};
  errors = [];
  messages = [];
  user: UserCredentials = new UserCredentials();
  submitted = false;

  constructor(private authService: AuthService) {}

  async resetPassword(): Promise<void> {
    if (!this.user.email) return;

    this.submitted = true;
    const result = await this.authService.resetPassword(this.user.email, null).catch((err) => {
      this.showMessages.error = true;
      this.errors.push(err.message);
      this.submitted = false;
    });

    if (result) {
      this.showMessages.success = true;
      this?.messages.push(result);
    }
  }
}
