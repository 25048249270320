/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { LabelBucket } from '../../@core/data/influencer.service';

@Component({
  selector: 'ngx-location',
  styleUrls: ['./location.component.scss'],
  template: ` <div echarts [options]="options" class="echart"></div> `,
})
export class LocationComponent implements AfterViewInit {
  data: any;
  options: any;
  themeSubscription: any;

  @Input()
  set locationData(locationData: LabelBucket[]) {
    const data = locationData || [];

    this.data = {
      labels: data.map((d) => d.label),
      datasets: data.map((d) => (d.count * 100).toFixed(2)),
    };
  }

  constructor(private theme: NbThemeService) {}

  ngAfterViewInit(): void {
    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      const echarts: any = config.variables.echarts;

      this.options = {
        backgroundColor: echarts.bg,
        color: [colors.success],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          // boundaryGap: [0, 0],
          splitLine: {
            show: false,
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: echarts.axisLineColor,
            },
          },
          axisLabel: {
            textStyle: {
              color: echarts.textColor,
            },
          },
        },
        yAxis: {
          type: 'category',
          data: this.data.labels,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: echarts.axisLineColor,
            },
          },
          axisLabel: {
            textStyle: {
              color: echarts.textColor,
            },
          },
        },
        series: [
          {
            name: 'Score',
            type: 'bar',
            barWidth: '60%',
            data: this.data.datasets,
          },
        ],
      };
    });
  }
}
