import { CommonModule, CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CreatorCampaignService } from '../../creators/creator-campaign.service';
import { CampaignInfluencerService } from '../../pages/campaigns/campaign/campaign-influencers/campaign-influencers.service';
import { AdminService } from './admin.service';
import { CampaignReportsService } from './campaign-reports.service';
import { CampaignWorkflowService } from './campaign-workflow/campaign-workflow.service';
import { CampaignsService } from './campaigns.service';
import { AffiliateService } from './campaigns/affiliate.service';
import { ProposalService } from './campaigns/proposal.service';
import { CategoryService } from './category.service';
import { ChatService } from './chat.service';
import { ContentFilterService } from './content-filter.service';
import { ContentService } from './content.service';
import { EcommerceService } from './ecommerce.service';
import { FacebookContentService } from './facebook/facebook-content.service';
import { FacebookTrendsService } from './facebook/facebook-trends.service';
import { FacebookService } from './facebook/facebook.service';
import { GroupService } from './group.service';
import { InfluencerFilterHelperService, InfluencerFilterService } from './influencer-filter.service';
import { InfluencerService } from './influencer.service';
import { LocationService } from './location.service';
import { MediaService } from './media.service';
import { MetadataService } from './metadata.service';
import { NewsService } from './news.service';
import { NotesService } from './notes.service';
import { ReportsService } from './reports.service';
import { InfluencerSearchFilterService } from './influencer-search-filter.service';
import { SocialProfilesLinkerService } from './social-profiles-linker.service';
import { StoriesService } from './stories.service';
import { SubscriptionService } from './subscription.service';
import { SurveyService } from './survey.service';
import { TiktokContentService } from './tiktok/tiktok-content.sevice';
import { TiktokProfileService } from './tiktok/tiktok-profile.service';
import { TiktokTrendsService } from './tiktok/tiktok-trends.service';
import { TiktokService } from './tiktok/tiktok.service';
import { TrendsReportService } from './trends-report.service';
import { TwitterContentService } from './twitter/twitter-content.service';
import { TwitterProfileService } from './twitter/twitter-profile.service';
import { TwitterService } from './twitter/twitter.service';
import { UserService } from './user.service';
import { WhiteLabelService } from './whitelabel.service';
import { YoutubeContentService } from './youtube/youtube-content.service';
import { YoutubeService } from './youtube/youtube.service';
import { FixedPayService } from './campaigns/fixed-pay.service';
import { ShippingDetailsService } from './shipping-details.service';
import { InstagramProfileService } from './instagram/instagram-profile.service';
import { InstagramDiscoveryService } from './instagram/instagram-discovery.service';
import { EmailTemplateService } from './email-template.service';
import { InstagramContentService } from './instagram/instagram-content.service';
import { InstagramProfilingJobService } from './instagram/instagram-profiling-job.service';
import { MagicSearchService } from './magic-search-service';
import { EmailSequenceService } from './email-sequence.service';
import { EmailSequenceUtils } from '../../pages/campaigns/email-sequences/email-sequence.utils';
import { InstagramBrandComparisionService } from './instagram/instagram-brand-comparision.service';

const SERVICES = [
  UserService,
  InfluencerService,
  ReportsService,
  InfluencerFilterService,
  InfluencerFilterHelperService,
  MediaService,
  NewsService,
  AdminService,
  SubscriptionService,
  CategoryService,
  LocationService,
  CampaignsService,
  ChatService,
  GroupService,
  ContentService,
  ContentFilterService,
  TrendsReportService,
  StoriesService,
  NotesService,
  FacebookService,
  YoutubeService,
  FacebookContentService,
  FacebookTrendsService,
  YoutubeContentService,
  CreatorCampaignService,
  MetadataService,
  CampaignReportsService,
  TiktokService,
  TiktokContentService,
  TiktokProfileService,
  TiktokTrendsService,
  WhiteLabelService,
  SocialProfilesLinkerService,
  EcommerceService,
  SurveyService,
  TwitterService,
  TwitterContentService,
  TwitterProfileService,
  CampaignWorkflowService,
  ProposalService,
  ShippingDetailsService,
  CampaignInfluencerService,
  AffiliateService,
  InfluencerSearchFilterService,
  FixedPayService,
  InstagramProfileService,
  InstagramDiscoveryService,
  InstagramContentService,
  EmailTemplateService,
  EmailSequenceService,
  InstagramProfilingJobService,
  MagicSearchService,
  EmailSequenceUtils,
  InstagramBrandComparisionService,
];

const PIPES = [CurrencyPipe];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES, ...PIPES],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<unknown> {
    return <ModuleWithProviders<unknown>>{
      ngModule: DataModule,
      providers: [...SERVICES, ...PIPES],
    };
  }
}
