import { Role } from '../@core/data/admin.service';
import { Auth0User, ClientUser, InfluencerNewsItem, RawAuth0User, StoreTypes } from '../interfaces';

export type NewInfluencerNewsItem = Pick<InfluencerNewsItem, 'title' | 'url' | 'publication' | 'publishedAt'>;

export type DetailedClientUser = ClientUser & RawAuth0User & Auth0User & { roles: Role[]; organizationName?: string };

export type QuotaPlatform = 'INSTAGRAM' | 'FACEBOOK' | 'YOUTUBE' | 'TIKTOK' | 'TWITTER';

export const ECommerceStoreTypes = ['shopify', 'magento'] as const;
export type AnyStore = 'SHOPIFY' | 'MAGENTO';

export type ECommerceStoreType = typeof ECommerceStoreTypes[number];
export type StoreType = keyof StoreTypes;

export type CredentialsOf<T extends StoreType> = StoreTypes[T];

export type MagentoRestApiVersion = 'V1' | 'async/V1' | 'async/bulk/V1';

export type DomainKey = 'affable' | 'dentsu' | 'betagged';
