import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PopperContent } from 'ngx-popper';

@Component({
  selector: 'ngx-mark-selected',
  templateUrl: './mark-selected.component.html',
  styleUrls: ['./mark-selected.component.scss', '../admin-controls.scss'],
})
export class MarkSelectedComponent {
  @Input() disabled: boolean;

  @Output() markUserBLEvent: EventEmitter<void> = new EventEmitter();
  @Output() markBrandEvent: EventEmitter<void> = new EventEmitter();

  @ViewChild('actionsContextMenu') actionsContextMenu: PopperContent;

  markUserBL(): void {
    this.markUserBLEvent.emit();
    this.actionsContextMenu.hide();
  }

  markBrand(): void {
    this.markBrandEvent.emit();
    this.actionsContextMenu.hide();
  }
}
