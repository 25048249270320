import { Component, Input, OnInit } from '@angular/core';
import { chain, omit } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CampaignsService, Link } from '../../../@core/data/campaigns.service';
import { Platforms } from '../../../enums';
import { LinksGroupedByDates } from '../../../interfaces';

@Component({
  selector: 'ngx-campaign-links',
  templateUrl: './campaign-links.component.html',
  styleUrls: ['./campaign-links.component.scss'],
})
export class CampaignLinksComponent implements OnInit {
  @Input() platform: Platforms;
  @Input() campaignSlugName: string;

  username: string;
  loading = false;
  cancelChanges: boolean;
  linksGroupedByDates: LinksGroupedByDates[] = [];

  links: Link[];

  @Input()
  set influencerUsername(value: string) {
    this.username = value;
  }

  @Input()
  set panelClosed(value: boolean) {
    this.cancelChanges = value;
  }

  constructor(private campaignService: CampaignsService, private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.loading = true;
    this.campaignService
      .getLinks(this.campaignSlugName, this.username, this.platform)
      .then((result) => {
        if (result[0]['httpStatusCode']) {
          this.toastrService.error(result[0]['message']);
        } else {
          this.linksGroupedByDates = this.groupLinksByDates(result);
        }
      })
      .catch((res) => (res.error ? this.toastrService.error(res.error?.message) : ''))
      .then(() => (this.loading = false));
  }

  groupLinksByDates(links: Link[]): LinksGroupedByDates[] {
    if (!links || links?.length === 0) {
      return [];
    }
    return chain(links)
      .groupBy((link) => link.created_at)
      .map((value, key) =>
        omit({
          date: new Date(key),
          links: value,
        }),
      )
      .value();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  addLink($event): void {
    this.loading = true;
    const newLink = $event.newLink;
    this.campaignService
      .addLink(this.campaignSlugName, this.username, newLink, this.platform)
      .then((links: Link[]) => {
        this.linksGroupedByDates = this.groupLinksByDates(links);
      })
      .catch((res) => this.toastrService.error(res.error?.message))
      .then(() => (this.loading = false));
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editLink($event): void {
    this.loading = true;
    this.linksGroupedByDates[$event.groupIndex].links[$event.linkIndex].title = $event.linkTitle;
    this.campaignService
      .updateLink(
        this.campaignSlugName,
        this.username,
        this.linksGroupedByDates[$event.groupIndex].links[$event.linkIndex],
        this.platform,
      )
      .then((linkPojo: Link) => {
        this.loading = false;
        this.linksGroupedByDates[$event.groupIndex].links[$event.noteIndex] = linkPojo;
      });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  deleteLink($event): void {
    this.loading = true;
    this.campaignService
      .deleteLink(
        this.campaignSlugName,
        this.username,
        this.linksGroupedByDates[$event.groupIndex].links[$event.linkIndex].link,
        this.platform,
      )
      .then(() => {
        this.loading = false;
        this.linksGroupedByDates[$event.groupIndex].links.splice($event.linkIndex, 1);
        if (this.linksGroupedByDates[$event.groupIndex].links?.length === 0) {
          this.linksGroupedByDates.splice($event.groupIndex, 1);
        }
      });
  }
}
