import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
})
export class UserBadgeComponent {
  @Input() size = 'medium';
  @Input() showName: string;
  @Input() picture: string;
  @Input() imgSrc: string;
  @Input() tooltip: string;
  @Input() name: string;
  @Input() title: string;
  @Input() badgeOverClass: string;
}
