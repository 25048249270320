import { Product, ProductSelected } from '../interfaces';

export type InfluencerProductSelectionWithOriginalProduct = ProductSelected & {
  product: Product;
};

// Tabs

export enum CampaignWorkflowSubTabs {
  proposalSettings = 'Proposal settings',
  campaignBrief = 'Campaign brief',
  productGiftings = 'Product gifting',
  fixedPay = 'Fixed pay settings',
  paymentSettings = 'Affiliate settings',
  influencerTasks = 'Influencer tasks',
  brandAssets = 'Brand assets',
  otherDetails = 'Other details',
  socialAccounts = 'Social accounts',
  contentRights = 'Content rights',
}
