import { InjectionToken } from '@angular/core';
import { BrandedContentService } from '../interfaces';

export const INFLUENCERS_PER_PAGE = 20;

export type ServiceType = 'Post' | 'Video' | 'Story';

export type PriceRange =
  | 'less than $50'
  | '$50 - $100'
  | '$100 - $150'
  | '$150 - $200'
  | '$200 - $300'
  | '$300 - $400'
  | '$400 - $500'
  | '$500 - $750'
  | '$750 - $1000'
  | '$1000 - $1500'
  | '$1500 - $2000'
  | '$2000 - $3000'
  | '$3000 - $4000'
  | '$4000 - $5000'
  | 'more than $5000';

export const BRANDED_CONTENT_SERVICE = new InjectionToken<BrandedContentService>('branded-content.service');
