import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Application } from '../../pages/community/community.type';

@Component({
  selector: 'ngx-table',
  styleUrls: ['./table.component.scss'],
  templateUrl: './table.component.html',
})
export class TableComponent {
  selectAllCheckbox: boolean;
  totalSelectedRows = 0;

  /**
   * container class that has the scroll e.g. '.scrollable-container'
   */
  @Input() stickyHeader = false;
  @Input() infiniteScrollContainer = '.scrollable-container';
  @Input() loading = false;
  @Input() showCheckbox = false;
  @Input() hideHeaderCheckbox = false;
  @Input() data!: Application[];
  @Input() hasMoreData = false;
  @Input() paginationLoader = false;
  @Input() pendingOverlay = false;
  @Input() hideBorders = false;
  @Input() showTableWithEmptyRows = false;
  @Output() loadFullTableDataEvent: EventEmitter<void> = new EventEmitter();
  @ContentChild('headers') headers!: TemplateRef<unknown>;
  @ContentChild('rows') rows!: TemplateRef<unknown>;
  @Output() loadNextPage = new EventEmitter();
  @Output() lastClicked = new EventEmitter<unknown>();
  @Output() selectAllEvent = new EventEmitter<unknown>();

  cachedSelectedRows: any[] = [];

  ngOnChanges(): void {
    this.setAllRowSelected();
  }

  get selectedRows(): any[] {
    return this.data.filter((row) => row['selected']);
  }
  scrolledToEnd(): void {
    this.loadNextPage.emit(null);
  }

  toggleTableSelection(e: Event): void {
    this.selectAllCheckbox = (<HTMLInputElement>e.target).checked;
    if (this.selectAllCheckbox) {
      this.selectAllRows();
    } else {
      this.setTableSelection(this.selectAllCheckbox);
    }
    this.lastClicked.emit(e.target);
    this.selectAllEvent.emit(this.selectAllCheckbox);
  }

  selectAllRows(): void {
    if (!this.hasMoreData) {
      this.setTableSelection(true);
    } else {
      this.loadFullTableDataEvent.emit();
    }
  }

  setAllRowSelected() {
    if (!this.selectAllCheckbox) {
      return;
    }
    this.setTableSelection(true);
  }

  setTableSelection(isSelected: boolean): void {
    this.selectAllCheckbox = isSelected;
    isSelected ? (this.totalSelectedRows = this.data.length) : (this.totalSelectedRows = 0);
    this.data.forEach((row) => (row['selected'] = isSelected));
    this.cachedSelectedRows = this.selectedRows;
  }

  toggleRowSelection(e: Event, index: number): void {
    const isRowSelected = !!e;
    if (this.data?.length > index) {
      this.data[index]['selected'] = isRowSelected;
    }
    if (!isRowSelected) {
      this.selectAllCheckbox = false;
      this.totalSelectedRows--;
    } else {
      this.totalSelectedRows++;
    }
    this.cachedSelectedRows = this.selectedRows;
    this.lastClicked.emit(this.data[index]);
  }
}

export interface TablePaginater {
  page?: number;
  pageLimit?: number;
  paginate?: string;
  searchTerm?: string;
  sortBy?: string;
  sortDirection?: string;
}

export const toQueryTablePaginater = (paginator: TablePaginater) => ({
  pageNumber: paginator.page?.toString() ?? '',
  pageLimit: paginator.pageLimit ? (paginator.pageLimit > 0 ? paginator.pageLimit?.toString() : '') : '',
  searchTerm: paginator.searchTerm ?? '',
  sortBy: paginator.sortBy ?? '',
  sortDirection: paginator.sortDirection ?? '',
});
