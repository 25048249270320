import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { LogicalFilterType } from '../../../enums';
import { HashtagWithLogic } from '../../../interfaces';

@Component({
  selector: 'ngx-hashtag-caption-filter',
  templateUrl: './hashtag-caption-filter.component.html',
  styleUrls: ['./hashtag-caption-filter.component.scss', '../styles.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(250)),
    ]),
  ],
})
export class HashtagCaptionFilterComponent {
  @Input() hashtags: HashtagWithLogic[];
  @Input() searchModifiers: LogicalFilterType;

  @Output() hashtagsChange: EventEmitter<HashtagWithLogic[]> = new EventEmitter();
  @Output() searchModifiersChange: EventEmitter<LogicalFilterType> = new EventEmitter();

  @ViewChildren('hashtagSelect') hashtagSelect: any[];

  // use this to convert search word to a tag
  convertToTag = (caption: string): { label: string; isIncluded: boolean } => {
    const hashtag = caption.trim().startsWith('#') ? caption.trim().slice(1) : caption.trim();
    return hashtag ? { label: caption, isIncluded: true } : undefined;
  };

  onHashtagSelectBlur(): void {
    this.hashtagSelect.forEach((element) => {
      if (element['filterValue'] || element['searchTerm']) {
        const newTag = this.convertToTag(element['filterValue'] || element['searchTerm']);
        if (newTag) this.hashtags = [...this.hashtags, newTag];
        element['filterValue'] = element['searchTerm'] = '';
      }
    });
  }

  toggleHashtagLogic(item: HashtagWithLogic): void {
    item.isIncluded = !item.isIncluded;
    this.updateHashtagsEvent();
  }

  updateHashtagsEvent(): void {
    this.hashtagsChange.emit(this.hashtags);
  }

  updateSearchModifierEvent(): void {
    this.searchModifiersChange.emit(this.searchModifiers);
  }
}
