import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbTokenStorage } from '@nebular/auth';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as echarts from 'echarts';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgProgressModule } from 'ngx-progressbar';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { TokenStorage } from './@core/auth/token-storage';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import sentryVersion from '../environments/sentry.version';
import { SupportWidgetComponent } from './shared/support-widget/support-widget.component';

if (environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    beforeSend(event) {
      // hook: callback invoked during event submission, allowing to optionally modify the event before it is sent to Sentry.
      console.log(
        `%c  Sentry Logger [${event.level}] > event_id: ${event.event_id}`,
        'background: #222; color: #bada55',
      );
      return event;
    },
    /**
     * Ignore the error caused by nebular library layout component after angular upgrade
     * https://github.com/akveo/nebular/issues/2221#issuecomment-597208682
     */
    ignoreErrors: [
      new RegExp('Cannot read propert.*nativeElement'),
      'can\'t access property "nativeElement", this.scrollableContainerRef is undefined',
      'this.scrollableContainerRef is undefined',
      "undefined is not an object (evaluating 'this.scrollableContainerRef.nativeElement')",
    ],
    release: sentryVersion,
    allowUrls: [
      'https://app.affable.ai/',
      'https://www.paypal.com/sdk/js',
      'https://checkout.stripe.com/',
      'https://js.stripe.com/v2/',
      'https://api.affable.ai/',
      'http://app.staging.affable.ai/',
      'http://api.staging.affable.ai/',
      'http://localhost:5000/',
      'http://localhost:4200/',
    ],
    debug: true,
    enabled: environment.production,
    environment: environment.production ? 'production' : 'development',
  });
}

export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    // Check if there is an error loading the chunk
    if (err?.message && err?.message.indexOf('Error: Loading chunk') >= 0) {
      // Check if is the first time the error happened
      if (localStorage.getItem('lastChunkError') !== err?.message) {
        // Save the last error to avoid an infinite reload loop if the chunk really does not exists after reload
        localStorage.setItem('lastChunkError', err?.message);
        // If true is passed as an argument the page will hard refresh (cached data will not be used for the refresh)
        location.reload(true);
        return;
      } else {
        // The chunk really does not exists after reload
        console.error("We really don't find the chunk...");
        if (localStorage.getItem('lastChunkError')) {
          localStorage.removeItem('lastChunkError');
        }
      }
    }
    Sentry.captureException(err);
    console.error(err);
  }
}

export function errorHandlerProvider(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [AppComponent, SupportWidgetComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      newestOnTop: true,
      tapToDismiss: false,
    }),
    NgbModule,
    NgxEchartsModule.forRoot({ echarts }),
    ThemeModule,
    CoreModule,
    NgProgressModule,
    DeviceDetectorModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerProvider },
    { provide: NbTokenStorage, useClass: TokenStorage },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {}
