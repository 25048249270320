<nb-layout windowMode>
  <nb-layout-column>
  <div class="public-app-reset-password-link">
    <div class="container">
      <div class="public-app-reset-password-link-title">
        <img alt=""class="logo" affLogo>
        <h3>Password reset link was sent</h3>
        <img src="/assets/images/illustration_reset_password_link.svg" class="reset-illustration" alt="">
      </div>
      
      <div class="message-container">
        <p> We emailed you the reset link to <b> {{email}}</b> </p>
        <p> Is the email address incorrect? Enter the right email ID <a (click)="navigateToRequestResetLink()"> here.</a> </p>
        <p> When you get the email, click on the link instantly to reset the password.
          If you don’t see it in your inbox, check your spam folder.</p>
        </div>
        
        <h6>Haven’t received the link yet?</h6>
        <button [disabled]="submitted" class="btn btn-block btn-primary" [class.btn-pulse]="submitted" (click)="resetPassword()"> resend mail
      </button>       
      
    </div>
    <nb-spinner *ngIf="submitted" class="large-spinner"></nb-spinner>
  </div>
</nb-layout-column>
</nb-layout>
