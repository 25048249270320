<div *ngIf="this.editingLink" class="link-item">
  <textarea rows="3" cols="30" type="text" [(ngModel)]="editedTitle"> </textarea>
  <div class="edit-controls">
    <button (click)="editLink()" title="Save"><i class="fa fa-check"></i></button>
    <button (click)="disableEditing()" title="Cancel"><i class="fa fa-close"></i></button>
  </div>
</div>
<div *ngIf="!this.editingLink" class="link-item edit-actions">
  <div class="link-text">
    <div [innerHTML]="link.title" class="font-weight-bold"></div>
    <a [innerHTML]="getLinkId(link.link)" href="javascript:void(0);" [attr.aria-expanded]="isCollapsed" aria-controls="collapseBitly" (click)="isCollapsed = !isCollapsed" class="bitlyLink"></a>
    <a title="Open" href="{{link.link}}" target="_black" class="fa fa-link"></a>
    <div id="collapseBitly" [collapse]="!isCollapsed" [isAnimated]="true">
      <div [innerHTML]="link.long_url"></div>
    </div>
  </div>
  <div class="edit-controls">
    <button (click)="enableEditing()" title="Edit"><i class="fa fa-edit"></i>
    </button>
    <button (click)="enableDeleting()" title="Delete"><i class="fa fa-trash-o"></i>
    </button>
  </div>
</div>
<div class="confirm-delete" *ngIf="this.deletingLink">
  Delete this link
  <button (click)="deleteLink()" title="Save"><i class="fa fa-check"></i></button>
  <button (click)="disableDeleting()" title="Cancel"><i class="fa fa-close"></i></button>
</div>