import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { isNaN } from 'lodash';

@Component({
  selector: 'ai-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.element.play();
  }
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  @Input() videoSrc: string; //'path/to/your/video.mp4';

  @Output() close: EventEmitter<void> = new EventEmitter();

  get element() {
    return this.videoPlayer?.nativeElement;
  }
}
