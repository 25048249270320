<div class="slider-container form-group">
  <label *ngIf="label">{{label}}</label><br *ngIf="label">
  <small>{{description}}</small>
  <div class="slider-inner-container">
    <div class="slider-label left">{{value?.toFixed(1)}} {{unitLabel}}</div>
    <input type="range" [min]="min" [max]="max" [step]="step" class="slider"
      [ngClass]="(label==='Suspicious followers')?'fake-followers':'engagement-ratio'" [(ngModel)]="value"
      (input)="onInputChange()">
    <div class="slider-label right">{{max}}<span class="plus-label" *ngIf="label === 'Engagement Ratio'">+</span>
      {{unitLabel}} </div>
  </div>
</div>
