import { TimePeriodOption } from '../interfaces';

const inputFilesNames = [
  'affiliateOrdersCsv',
  'emailAttachments',
  'brandPortalLogo',
  'storyImage',
  'applicationPageLogo',
  'landingPageImage',
  'campaignImage',
  'productImage',
  'taskAttachment',
  'noteAttachment',
] as const;
export type InputFilesNames = typeof inputFilesNames[number];

export type Value = string | number | boolean | undefined;
export type ArrayValue = string[] | number[] | boolean[];
export type KeyValue = { [key: string]: Value | ArrayValue | KeyValue };

export type Currencies = 'IDR' | 'PH' | 'USD' | 'SGD' | 'EUR' | 'GBP' | 'MYR' | 'TWD' | 'INR';

export enum Currency {
  IDR = 'IDR',
  PH = 'PH',
  USD = 'USD',
  SGD = 'SGD',
  EUR = 'EUR',
  GBP = 'GBP',
  MYR = 'MYR',
  TWD = 'TWD',
  INR = 'INR',
}

export enum CreatorsPortalSupportedLanguages {
  ENGLISH = 'en',
  CHINESE = 'zh',
}

export type SortDirection = 'asc' | 'desc' | '';
export const rotationConfig: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};
export enum SortType {
  asc = 'asc',
  desc = 'desc',
}

export type TimePeriodKey = 'LAST_WEEK' | 'LAST_MONTH' | 'LAST_THREE_MONTHS' | 'LAST_YEAR';

export const timePeriods: TimePeriodOption[] = [
  { label: 'Last Week', value: 'LAST_WEEK' },
  { label: 'Last Month', value: 'LAST_MONTH' },
  { label: 'Last 3 Months', value: 'LAST_THREE_MONTHS' },
  { label: 'Last Year', value: 'LAST_YEAR' },
];

export const dashboardMetricsArray = [
  { label: 'Number of Posts', value: 'NUMBER_OF_POSTS' },
  { label: 'Total Engagement', value: 'ABSOLUTE_ENGAGEMENT' },
  { label: 'Number of Unique Influencers', value: 'NUMBER_OF_INFLUENCERS' },
  { label: 'Total Estimated Reach', value: 'ESTIMATED_REACH' },
];

export const granularityArray = ['MONTH', 'WEEK', 'DAY'];

export type MediaType = 'video' | 'photo' | 'carousel' | 'igtv' | 'animated_gif';

export type MediaProductType = 'FEED' | 'REELS';

export type MessageType = 'message' | 'event';

export type LogicalFilterType = 'AND' | 'OR';

export const CONTENT_PER_PAGE = 20;
export const MAX_PAGES = 75;
