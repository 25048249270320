<div class="modal-body">
    <div class="content-display">
        <div class="video-error" *ngIf="loadError">
            <i class="far fa-exclamation-triangle"></i> <span>Failed to load video!</span>
        </div>
        <div class="video-placeholder" *ngIf="loadingVideoURL && !video.missing">
            <img [src]="video.video.cover" (error)="handleCoverImgError($event)">
        </div>

        <!--        <video *ngIf="!loadingVideoURL" [poster]="video.video.cover" controls autoplay>-->
        <!--            <source [src]="videoURL" type="video/mp4" (error)='loadError = true'>-->
        <!--        </video>-->
    </div>
    <div class="content-information">
        <div class="content-header">
            <a href="/pages/tiktok/profile/{{video.author.username}}" class="content-author" target="_blank">
                <!-- This img tag is just for handling broken image -->
                <img src="{{video.author.picture}}" (error)='handleProfileCoverImgError($event)'
                    style="display: none;" />
                <nb-user [name]="video.author.name" [picture]="video.author.picture" [title]="video.author.username"
                    size="large">
                </nb-user>
            </a>
            <button class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="content-body">
            <div class="content-stats">
                <div class="stat" *ngIf="!hideStats && video?.estimatedReach">
                    <label>Est. Reach</label> {{video.estimatedReach | formatNumber:2}}
                </div>
                <div class="stat" *ngIf="!hideStats && video?.estimatedImpressions">
                    <label>Est. Impressions</label> {{video.estimatedImpressions | formatNumber: 2}}
                </div>
                <div class="stat" *ngIf="!hideStats && video?.estimatedMediaValue">
                    <label>EMV</label> {{video.estimatedMediaValue | currency:currency}}
                </div>
                <div class="h-space" *ngIf="!hideStats"></div>
                <div class="stat">
                    <i class="far fa-calendar"></i>
                    {{postingTime}}
                </div>
                <div class="stat" *ngIf="!hideStats">
                    <i class="far fa-play"></i>
                    {{video.statistics.playCount | number}} Plays
                </div>
                <div class="stat" *ngIf="!hideStats">
                    <i class="far fa-heart"></i>
                    {{video.statistics.diggCount | number}} Likes
                </div>
                <div class="stat" *ngIf="!hideStats">
                    <i class="far fa-comment"></i>
                    {{video.statistics.commentCount | number}} Comments
                </div>
                <div class="stat" *ngIf="!hideStats">
                    <i class="far fa-share"></i>
                    {{video.statistics.shareCount | number}} Shares
                </div>
            </div>
            <div class="content-list" *ngIf="video.hashtags?.length > 0">
                <i class="far fa-hashtag"></i>
                <div class="list">
                    <span *ngFor="let hashtag of video.hashtags">
                        #{{hashtag}}
                    </span>
                </div>
            </div>
            <div class="content-list" *ngIf="video.taggedUsers?.length > 0">
                <i class="far fa-at"></i>
                <div class="list">
                    <span *ngFor="let user of video.taggedUsers">
                        {{user}}
                    </span>
                </div>
            </div>
            <div class="content-caption">{{video.description}}</div>
        </div>
        <div class="content-footer">
            <a class="btn btn-xs btn-primary btn-block" [attr.href]="videoExternalURL" target="_blank">
                <i class="fab fa-tiktok"></i>
                GO TO TIKTOK
            </a>
        </div>
    </div>
</div>