<nb-card [size]="size">
    <nb-card-header>
        <span> Recent Tweets</span>
        <ngx-tooltip tooltip="Recent Tweets by this influencer"></ngx-tooltip>
    </nb-card-header>
    <nb-card-body>
        <div class="tweets">
            <nb-list>
                <nb-list-item *ngFor="let tweet of tweets">
                    <div *ngIf="isContentTweets" class="tweet-checkbox">
                        <nb-checkbox class="card-check" status="success" [(ngModel)]="tweet.checked"
                            [disabled]="!tweet.checked && checkedCount() >= maxCheck"></nb-checkbox>
                    </div>
                    <div *ngIf="isContentTweets" class="tweet-author">
                        <a href="/pages/twitter/profile/{{tweet.author?.username}}" class="content-author"
                            target="_blank" title="{{tweet.author?.name}}">
                            <nb-user [name]="tweet.author?.name" [picture]="tweet.author?.profilePictureUrl"
                                [title]="tweet.author?.username" size="large">
                            </nb-user>
                        </a>
                        <div class="date">
                            {{tweet.createdAt | date: 'mediumDate' }}
                        </div>
                    </div>
                    <div class="tweet" (click)="openTweetDetailsModal(tweet)">
                        <div *ngIf="!isContentTweets" class="details">
                            <div class="date">
                                {{tweet.createdAt | date: 'mediumDate' }}
                            </div>
                        </div>
                        <!-- tweet media -->
                        <div *ngIf="tweet.media && tweet.media?.length >0 && tweet.media[0]?.url"
                            [class.video-thumbnail]="tweet.media[0].type=='video'">
                            <i *ngIf="tweet.media[0].type=='video'" class="fa fa-play"></i>
                            <img [src]="tweet.media[0].url">
                        </div>

                        <!-- Tweet description-->
                        <div *ngIf="tweet.description" class="description block-with-text">
                            {{tweet.description}}
                        </div>
                        <div *ngIf="!tweet.description" class="description not-found block-with-text">
                            No description found!
                        </div>

                        <!--Tweet stats-->
                        <div *ngIf="tweet.statistics && !hideStats" class="stats">
                            <!-- <div *ngIf="tweet.statistics.replyCount >= 0 " class="stat-item">
                                <i class="far fa-comment"></i>{{tweet.statistics.replyCount | formatNumber:2 }}
                                replies
                            </div> -->
                            <div *ngIf="tweet.statistics.retweetCount >= 0 " class="stat-item">
                                <i class="far fa-retweet"></i> {{ tweet.statistics.retweetCount | formatNumber:2 }}
                                retweets
                            </div>
                            <div *ngIf="tweet.statistics.likeCount >= 0 " class="stat-item">
                                <i class="far fa-heart"></i> {{tweet.statistics.likeCount | formatNumber:2}}
                                likes
                            </div>
                            <!-- <div *ngIf="tweet.statistics.quoteCount >= 0" class="stat-item">
                                <i class="far fa-quote-left"></i>
                                {{tweet.statistics.quoteCount | formatNumber:2}} Quote Tweets
                            </div> -->
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </div>
    </nb-card-body>
    <nb-card-footer>
    </nb-card-footer>
</nb-card>