<div class="toolbar">
  <div class="left">
    <form>
      <ai-input placeholder="Search influencers"></ai-input>
    </form>
  </div>
  <div class="right">
    <div class="actions">
      <ai-button prefix="fa-regular fa-table" type="icon" color="var(--background-base)" (click)="openCustomizer()"></ai-button>
      <ai-button prefix="fa-regular fa-ellipsis-vertical" type="icon" color="var(--background-base)"></ai-button>
    </div>
  </div>
</div>
<cdk-table [dataSource]="dataSource" [style.width]="width" [style.height]="height">

  <!-- Checkbox Column -->
  <ng-container cdkColumnDef="selected">
    <cdk-header-cell *cdkHeaderCellDef>
      <form>
        <ai-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="dataSource.allSelected"
          (change)="$event ? dataSource.toggleAllRows($event) : null" [checked]="dataSource.allChecked"
          [indeterminate]="dataSource.allIndeterminate" [labelSpacing]="false"></ai-checkbox>
      </form>
    </cdk-header-cell>
    <cdk-cell *cdkCellDef="let row">
      <form>
        <ai-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="row.selected"
          [labelSpacing]="false"></ai-checkbox>
      </form>
    </cdk-cell>
  </ng-container>

  <ng-container *ngFor="let col of dataSource.cols">
    <ng-container [cdkColumnDef]="col?.dataKey">
      <cdk-header-cell *cdkHeaderCellDef [style.flex]="col?.width">
        <i class="prefix {{col?.prefixIcon}}" *ngIf="col?.prefixIcon"></i>
        <span [innerHTML]="col?.name || col?.dataKey"></span>
        <i class="action fa-regular fa-bars-sort" *ngIf="col?.filterable"></i>
        <i class="action fa-regular fa-sort" *ngIf="col?.sortable"></i>
      </cdk-header-cell>
      <cdk-cell *cdkCellDef="let row" class="body-2" [style.flex]="col?.width">
        <ai-table-cell [row]="row" [col]="col"></ai-table-cell>
      </cdk-cell>
    </ng-container>
  </ng-container>

    <!-- Actions Column -->
    <ng-container cdkColumnDef="actions">
      <cdk-header-cell *cdkHeaderCellDef>
        <span>Actions</span>
      </cdk-header-cell>
      <cdk-cell *cdkCellDef="let row">
        <i class="fa-regular fa-ellipsis-vertical"></i>
      </cdk-cell>
    </ng-container>

  <cdk-header-row *cdkHeaderRowDef="dataSource.shownCols;sticky:true"></cdk-header-row>
  <cdk-row *cdkRowDef="let row; columns: dataSource.shownCols;"></cdk-row>

</cdk-table>