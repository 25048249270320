<div class="modal-header">
  <span>Edit Group Name</span>
  <button class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <input type="text" placeholder="Enter group name" class="input-group" [(ngModel)]="groupName" #groupnameinput
      trim />
  </div>

  <div class="form-group" *ngIf="isAgencyBrandGroupEnabled">
    <label class="af-sub-heading-h2 p-0">Tags</label>
    <ng-select #groupTagSelect placeholder="Add tags, maximum 5 tags (Ex: Macro influencers, Fitness, Nike, etc.)"
      [addTag]="true" [selectableGroup]="true" [multiple]="true" [markFirst]="false" [clearOnBackspace]="false"
      [isOpen]="false" [(ngModel)]="groupTags" [items]="groupTags" (keydown)="groupTagKeyDown($event)"
      [maxSelectedItems]="5">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value chip" *ngFor="let item of items">
          <span class="ng-value-label">{{item}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
<div class="modal-footer">
  <button class="secondary-text" (click)="closeModal()">CANCEL</button>
  <button class="primary" (click)="EditGroupName()" [disabled]="!groupName.trim()">SAVE GROUP</button>
</div>