import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { Platform, Platforms, VeraPlatforms } from '../../enums';
import utils from '../../@core/utils/utils';
import { CommunityApplicantGenderEnum, CommunityApplicantShippingAddress } from '../../pages/community/community.type';
@Component({
  selector: 'ngx-influencer-profile-card',
  styleUrls: ['./influencer-profile-card.component.scss'],
  templateUrl: './influencer-profile-card.component.html',
})
export class InfluencerProfileCardComponent {
  @Input() profileInfo: InfluencerProfileCard;
  @Input() title = 'Basic Info';
  @Input() tileType: 'legacy' | 'modern' = 'legacy';
  @Input() isCrossPlatformCard = false;
  @Input() profilePictureSize: 'tiny' | 'small' | 'medium' | 'large' | 'giant' = 'medium';
  @Input() disableProfileNavigation = false;
  @ViewChild('fullProfileModal') fullProfileModal: TemplateRef<unknown>;
  profile: Profile;
  socialMediaInfo: SocialMediaInfo;
  profilePictureUrl: string;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    if (this.profileInfo.platform === 'tiktok') {
      this.replaceBadProfilePicUrl();
    } else {
      this.profilePictureUrl = this.profileInfo.profilePictureUrl;
    }
  }

  get islegacyTileLegacy(): boolean {
    return this.tileType === 'legacy';
  }

  get getUserTitle(): string {
    return this.isCrossPlatformCard ? 'View application' : this.profileInfo?.username;
  }

  get getInfluencerProfileUrl(): string {
    return utils.getInfluencerProfileUrl(this.profileInfo?.platform, this.profileInfo?.username);
  }

  hyperlinkCardClicked(): void {
    if (this.isCrossPlatformCard) {
      this.openFullProfileModal();
    } else {
      window.open(this.getInfluencerProfileUrl, '_blank');
    }
  }

  openFullProfileModal(): void {
    this.profile = this.getFullProfileData();
    this.socialMediaInfo = this.getSocialMediaInfo;
    this.modalService.open(this.fullProfileModal, {
      size: 'sm',
      windowClass: '',
      centered: true,
      backdrop: 'static',
    });
  }

  private replaceBadProfilePicUrl(): void {
    utils
      .replaceBadTiktokMediaUrls<InfluencerProfileCard>([this.profileInfo], 'profilePictureUrl')
      .then(([updatedData]) => {
        this.profilePictureUrl = updatedData.profilePictureUrl;
      });
  }

  getFullProfileData(): Profile {
    return {
      gender: this.profileInfo.gender,
      birthdate: this.profileInfo.birthdate,
      email: this.profileInfo.email,
      phone: this.profileInfo.phone,
      shippingAddress: this.profileInfo.shippingAddress,
      paypalEmail: this.profileInfo.paypalEmail,
    };
  }

  get getSocialMediaInfo(): SocialMediaInfo {
    return {
      instagramUsername: this.profileInfo.instagramUsername,
      facebookUsername: this.profileInfo.facebookUsername,
      youtubeUsername: this.profileInfo.youtubeUsername,
      tiktokUsername: this.profileInfo.tiktokUsername,
      twitterUsername: this.profileInfo.twitterUsername,
    };
  }

  handleProfileCoverImgError(error): void {
    const src = error?.target?.currentSrc;
    if (src) {
      switch (this.profileInfo?.platform) {
        case 'tiktok':
          {
            const newUrl = utils.changeTiktokMediaHost(src);
            if (newUrl !== src) {
              this.profileInfo.profilePictureUrl = newUrl;
            }
          }
          break;
        case 'instagram':
          {
            const url = new URL(src);
            if (src && url.origin !== environment.api) {
              this.profileInfo.profilePictureUrl = utils.getInstagramCDNUrl(src);
            }
          }
          break;
      }
    }
  }

  getGenderIcon(gender: CommunityApplicantGenderEnum): string {
    switch (gender) {
      case CommunityApplicantGenderEnum.MALE:
        return 'fa-mars';
      case CommunityApplicantGenderEnum.FEMALE:
        return 'fa-venus';
      default:
        return 'fa-genderless';
    }
  }

  getPlatformImage(platform: Platform): string {
    return platform ? `../../../../assets/images/platforms/${platform.toLowerCase()}.svg` : '';
  }

  navigateToProfile(username: string, platform: Platform): void {
    if (!username || !platform) {
      return;
    }
    let profileUrl = '';
    switch (platform) {
      case Platforms.instagram.toLowerCase():
      case VeraPlatforms.instagram.toLowerCase():
        profileUrl = 'https://www.instagram.com/' + username;
        break;
      case Platforms.facebook.toLowerCase():
        profileUrl = 'https://www.facebook.com/' + username;
        break;
      case Platforms.youtube.toLowerCase():
        profileUrl = 'http://www.youtube.com/@' + username;
        break;
      case Platforms.tiktok.toLowerCase():
        profileUrl = 'https://www.tiktok.com/@' + username;
        break;
      case Platforms.twitter.toLowerCase():
        profileUrl = 'http://www.twitter.com/' + username;
        break;
    }
    window.open(profileUrl, '_blank');
  }
}

export interface InfluencerProfileCard extends Profile, SocialMediaInfo {
  name: string;
  username: string;
  profilePictureUrl: string;
  platform: Platform;
  isRegistered?: boolean;
}

interface Profile {
  gender?: CommunityApplicantGenderEnum;
  birthdate?: Date;
  email?: string;
  phone?: string;
  shippingAddress?: CommunityApplicantShippingAddress;
  paypalEmail?: string;
}

interface SocialMediaInfo {
  instagramUsername?: string;
  facebookUsername?: string;
  youtubeUsername?: string;
  tiktokUsername?: string;
  twitterUsername?: string;
}
