import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';

import { InfluencerLookup } from '../../../interfaces';
import { ChatService } from '../../../@core/data/chat.service';
import { MailType, Platforms } from '../../../enums';
import { CampaignsService } from '../../../@core/data/campaigns.service';
import { MetadataService } from '../../../@core/data/metadata.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-chat-preview',
  templateUrl: './chat-preview.component.html',
  styleUrls: ['./chat-preview.component.scss'],
})
export class ChatPreviewComponent implements OnInit {
  // search and filter
  searchKeyword: string;
  selectedSorting: Sortings;
  Sortings = Object.values(Sortings);

  // allChatList has the original chat list from response, chats has what UI show
  allChatsList: InfluencerLookup[];
  chats: InfluencerLookup[];

  // currently selected chat
  selectedChat: InfluencerLookup;
  selectedChatPreview = '';
  selectedChatEmail: string;

  // list of all usernams and chat previews (interpolated/edited)
  cachedChatPreview: Map<string, string> = new Map();
  sendChatPreview: Map<string, string> = new Map();

  // Client preview action
  action: Actions;
  Actions = Actions;

  // TODO: Remove if unnecessary
  @HostListener('window: reset-content', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openCustomPopup(event) {
    // TODO: consume reset content $event
  }

  get noOfMessagesSent(): number {
    return this.chatService.noOfMessagesSent;
  }
  totalNumberOfMessagesToBeSend = 0;
  // template varaibles array can't be empty
  @Input() supportedTemplatingVariables: string[] = [];
  previewChatForm: FormGroup;

  generatingPreview: boolean;
  errorInSendMail: boolean;
  loading: boolean;
  restrictedKeywordsUsed: boolean;

  @Input() set chatPreviewList(value: InfluencerLookup[]) {
    this.chats = [...value];
    this.allChatsList = [...value];
    this.selectedChat = this.chats[0];
  }

  // original message from new chat view
  @Input() filesToAttach: File[];
  @Input() platform: Platforms;
  @Input() campaignSlugName: string;
  @Input() subject: string;
  @Input() message: string;
  @Input() mailType: MailType = MailType.default;

  @Output() closeModal = new EventEmitter();
  @Output() closePreview = new EventEmitter();

  constructor(
    public sanitizer: DomSanitizer,
    private chatService: ChatService,
    private toastrService: ToastrService,
    private campaignsService: CampaignsService,
    private metadataService: MetadataService,
  ) {}

  ngOnInit(): void {
    this.selectedSorting = Sortings.none;
    this.action = Actions.default;

    this.previewChatForm = new FormGroup({
      message: new FormControl(''),
    });
    this.getpreviewChat();
  }
  get apiPath(): string {
    return environment.api;
  }

  private sortAsc(list: InfluencerLookup[]): InfluencerLookup[] {
    return [...list].sort((a, b) => (a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0));
  }

  private sortDesc(list: InfluencerLookup[]): InfluencerLookup[] {
    return [...list].sort((a, b) => (a.fullName > b.fullName ? -1 : b.fullName > a.fullName ? 1 : 0));
  }

  searchSortChats(): void {
    const sortedList =
      this.selectedSorting === Sortings.asc
        ? this.sortAsc(this.allChatsList)
        : this.selectedSorting === Sortings.desc
        ? this.sortDesc(this.allChatsList)
        : this.allChatsList;

    this.chats = this.searchKeyword
      ? sortedList.filter(
          (chat) =>
            chat.fullName.toLocaleLowerCase().indexOf(this.searchKeyword.toLocaleLowerCase()) !== -1 ||
            chat.username.toLocaleLowerCase().indexOf(this.searchKeyword.toLocaleLowerCase()) !== -1,
        )
      : sortedList;
    if (this.chats.length) {
      this.selectedChat = this.chats[0];
      this.previewChat(this.selectedChat);
    }
  }

  // handles mouse click event on influencer in the list
  previewChat(chat: InfluencerLookup) {
    this.action = Actions.default;
    this.selectedChat = chat;
    if (!this.cachedChatPreview.has(this.selectedChat.username)) {
      this.getpreviewChat();
    } else {
      this.selectedChatPreview = this.cachedChatPreview.get(this.selectedChat.username);
    }
  }

  // handles save edited chat cick
  saveEditedPreviewChat(): void {
    this.getpreviewChat(true);
    if (this.selectedChatEmail !== this.selectedChat.email) {
      this.updateMetadata(this.selectedChat.username, this.selectedChatEmail);
    }
    this.action = Actions.default;
  }

  updateMetadata(influencerUsername: string, updatedEmail: string) {
    this.metadataService
      .getInfluencerMetadata(influencerUsername, this.platform)
      .then((influencerMetadata) => {
        if (influencerMetadata['Error']) {
          throw Error(influencerMetadata['Error']);
        }
        influencerMetadata.email = updatedEmail;
        this.metadataService
          .updateInfluencerMetadata(influencerUsername, this.platform, influencerMetadata)
          .then(() => {
            this.toastrService.success('Email updated successfully');
            this.selectedChat.email = this.selectedChatEmail;
            if (this.campaignSlugName) {
              this.campaignsService.sendInfluencersChangedEvent(
                this.campaignSlugName,
                this.campaignsService.influencersChanges.influencersAdded,
              );
            }
          })
          .catch(() => {
            console.log('Error in updating influencer metadata'); // Intentional
            this.toastrService.error("Email couldn't be updated");
          });
      })
      .catch(() => {
        console.log('Error in fetching influencer metadata'); // Intentional
        this.toastrService.error("Email couldn't be updated");
      });
  }

  // gets the interpoated preview chat from API
  getpreviewChat(updatedMessage = false) {
    this.generatingPreview = true;
    const msg = updatedMessage ? this.previewChatForm.value?.message : this.message;
    this.chatService
      .previewChat(
        this.selectedChat.username,
        this.campaignSlugName,
        this.subject,
        msg,
        this.filesToAttach,
        this.platform,
      )
      .then((res) => {
        this.selectedChatPreview = res.message;
        this.cachedChatPreview.set(this.selectedChat.username, this.selectedChatPreview);
        this.sendChatPreview.set(this.selectedChat.username, msg);
        this.generatingPreview = false;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status === 400) {
          this.restrictedKeywordsUsed = true;
          this.toastrService.error(error.error.Error || error.error.message);
          this.loading = false;
          this.generatingPreview = false;
          if (!updatedMessage) {
            this.closePreview.emit();
          }
          return;
        }
        this.generatingPreview = false;
        this.errorInSendMail = true;
      });
  }

  backToChat(): void {
    this.closePreview.emit();
  }

  sendChat(): void {
    const allUsernames = this.chats.map((chat) => chat.username);
    this.totalNumberOfMessagesToBeSend = allUsernames.length;

    this.loading = true;
    this.chatService
      .startNewChat(
        allUsernames,
        this.campaignSlugName,
        this.subject,
        this.message,
        this.filesToAttach,
        this.platform,
        this.cachedChatPreview,
        this.mailType,
      )
      .then((status) => {
        this.loading = false;
        this.errorInSendMail = false;
        if (status?.failure) {
          this.toastrService.warning(`${status.failure} email you sent was undeliverable`);
        } else {
          this.campaignsService.emailInfluencersSuccessEvent(allUsernames, this.mailType);
        }
        this.closeModal.emit({ status, influencers: this.chats });
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status === 429) {
          this.toastrService.error(error.error.message);
        }
        if (error.status === 400) {
          this.restrictedKeywordsUsed = true;
          this.toastrService.error(error.error.message);
          this.loading = false;
          return;
        }
        this.loading = false;
        this.errorInSendMail = true;
      });
  }

  // handes edit button click
  editChat(): void {
    const msg = this.selectedChatPreview.replace(/class='placeholder'/g, '');
    this.selectedChatEmail = this.selectedChat.email;
    this.previewChatForm.setValue({ message: msg });
    this.action = Actions.editing;
  }

  deleteInfluencer(): void {
    const index = this.chats.findIndex((inf) => inf.username == this.selectedChat.username);
    this.chats = this.chats.filter((inf) => inf.username != this.selectedChat.username);
    this.allChatsList = this.allChatsList.filter((inf) => inf.username != this.selectedChat.username);
    this.selectedChat = index < this.chats.length ? this.chats[index] : this.chats[index - 1];
    this.previewChat(this.selectedChat);
    this.action = Actions.default;
  }

  closeChatModal(): void {
    this.closeModal.emit();
  }
}

enum Sortings {
  none = 'None',
  asc = 'A to Z',
  desc = 'Z to A',
}

enum Actions {
  default = 'default',
  editing = 'editing',
  deleting = 'deleting',
}
