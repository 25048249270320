import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';
import { NgProgress } from 'ngx-progressbar';
import { environment } from '../../../../environments/environment';
import { Content } from '../../../@core/data/content.service';
import utils from '../../../@core/utils/utils';

@Component({
  selector: 'ngx-instagram-content-modal',
  styleUrls: ['./instagram-content-modal.component.scss'],
  templateUrl: './instagram-content-modal.component.html',
})
export class InstagramContentModalComponent implements OnInit {
  lodashIsNil = isNil;

  @Input() content: Content;
  @Input() isInfluencerRegistered: boolean;

  @Input()
  inCampaign: boolean;

  loaded = false;
  @Input() currency: string;
  constructor(private activeModal: NgbActiveModal, public progress: NgProgress, @Inject(DOCUMENT) private document) {}

  ngOnInit(): void {
    this.progress.ref().start();
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
