import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ShowMessage } from '../../../../../interfaces';
import { UserCredentials } from '../../../../../enums/user.enum';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './public-app-request-password.component.html',
  styleUrls: ['./public-app-request-password.component.scss'],
})
export class NgxPublicRequestPasswordComponent {
  showMessages: ShowMessage = {};
  errors = [];
  user: UserCredentials;
  submitted = false;
  shop: string;
  email: string;
  storeName: string;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.shop = this.route.snapshot.queryParamMap.get('shop');
    this.storeName = this.route.snapshot.queryParamMap.get('storeName');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.user = new UserCredentials(this.email);
    if (!(this.shop && this.storeName)) {
      this.router.navigate(['404-not-found']);
    }
  }

  async resetPassword(): Promise<boolean> {
    if (!this.user.email) return;

    this.submitted = true;
    const reset = await this.authService.resetPassword(this.user.email, { shop: this.shop }).catch((err) => {
      this.showMessages.error = true;
      this.errors.push(err.message);
      this.submitted = false;
    });

    if (reset) {
      const queryParams = { shop: this.shop, storeName: this.storeName, email: this.email };
      return this.router.navigateByUrl('auth/public/reset-password-link', {
        state: { query: queryParams, email: this.user.email },
      });
    }
  }

  navigateToLogin() {
    const queryParams = { shop: this.shop, storeName: this.storeName, email: this.email };
    return this.router.navigate(['auth', 'public', 'login'], { queryParams });
  }
}
