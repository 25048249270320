import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent implements OnInit, OnDestroy {
  interval;
  timeLeft = 15;
  imgSrc = 1;

  constructor(public modal: NgbActiveModal) {
    this.imgSrc = Math.floor(Math.random() * 2) + 1;
  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 1) {
        this.timeLeft--;
      } else {
        this.modal.close();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.interval);
  }
}
