import { Injectable } from '@angular/core';
import { KeyValue } from '../../enums';
import { tryGet } from '../../utils';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserguidingService {
  userId: string;
  user: any;

  constructor(private router: Router) {}

  initialize(): void {
    window['userGuidingLayer'].push({
      event: 'onIdentificationComplete',
      fn: (data: any) => {
        this.user = data?.userStorage;
      },
    });
  }

  track(action: string, payload?: KeyValue): void {
    window['userGuiding']?.track(action, payload);
  }

  identify(payload: KeyValue, userId?: string): void {
    try {
      if (userId) {
        this.userId = userId;
      }

      if (!this.user) {
        return tryGet(() => window['userGuiding']?.identify(this.userId, payload));
      }

      const [key] = Object.keys(payload);
      const [value] = Object.values(payload) as number[];
      const identifierCount: number = this.user.attributes?.[key] ?? 0;

      payload[key] = identifierCount + value || 1;
      this.user.attributes[key] = identifierCount ? identifierCount + value : value;

      tryGet(() => window?.['userGuiding']?.identify(this.userId, payload));
    } catch (e) {
      console.error(`Error meanwhile in userGuiding track ${e}`);
    }
  }

  getSource(): Source {
    const currentUrl = this.router.url;
    const sources: Record<string, Source> = {
      '/community': 'community',
      '/console': 'console',
      '/profile': 'profile',
      '/discovery': 'discovery',
      '/campaign': 'campaign',
    };

    const matchingSourceEntry = Object.entries(sources).find(([url]) => currentUrl.includes(url));
    if (matchingSourceEntry) {
      return matchingSourceEntry[1];
    }

    console.error(`USERGUIDING: Unable to identify source from url: ${currentUrl}`);
    return null;
  }

  getSourceIdentifier(identifier: string): string {
    const source = this.getSource();
    return source ? `${source}_${identifier}` : identifier;
  }
}

export type Source = 'discovery' | 'console' | 'community' | 'profile' | 'campaign';
