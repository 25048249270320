import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MailType } from '../../../enums';
import { CampaignInfluencer } from '../../../interfaces';
import { CampaignInfluencerService } from '../../../pages/campaigns/campaign/campaign-influencers/campaign-influencers.service';

@Component({
  selector: 'ngx-show-all-influencer-modal',
  templateUrl: './show-all-influencer-modal.component.html',
  styleUrls: ['./show-all-influencer-modal.component.scss'],
})
export class ShowAllInfluencerModalComponent implements OnInit {
  @Input() influencers: CampaignInfluencer[];

  constructor(private activeModal: NgbActiveModal, private campaignInfluencerService: CampaignInfluencerService) {}

  ngOnInit(): void {
    this.influencers.forEach((inf) => {
      if (inf.influencerMetadata && inf.influencerMetadata.latestOrderDeliveredAt) {
        inf.influencerMetadata.latestOrderDeliveredAt = moment(
          new Date(inf.influencerMetadata.latestOrderDeliveredAt),
        ).fromNow();
      }
    });
  }

  sendReminder(): void {
    this.activeModal.close();
    this.campaignInfluencerService.startChatWithInfluencers(
      this.influencers.map((inf) => ({ ...inf, selected: true, editable: true })),
      MailType.reminder,
    );
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
