<div [ngSwitch]='id' class='page-wrapper'
  [ngStyle]="{'margin-top': id !== 'bio'? '60px' : '30px', 'padding-top': id !== 'bio'? '30px' : '02px'}">

  <!--  PRINTED PROFILE SUMMARY (BIO)-->
  <div *ngSwitchCase="'bio'">
    <!-- ./start Printed profile summary -->

    <!--  ./start IG-PROFILE  -->
    <ng-template [ngIf]="platform=== 'Instagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='print-graphics'>
        <!--
          Given that we are not utilizing page tags or pagination,
          there is no need for a bio header
         .hide
         -->
        <div class='profile-header-bar hide'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='bio'>
          <div class='avatar'>
            <div class='img-wrapper '>
              <img *ngIf='!profileImageNotLoaded' [src]='influencer?.profile?.picture' (error)='errorLoadingImage()'
                alt="" />
              <img *ngIf='profileImageNotLoaded' style='filter: invert(98%); opacity: 0.8'
                src='../../../../assets/images/print/user.png' alt="" />
            </div>
            <div class='contacts-wrapper'>
              <div class='contacts' *ngIf='influencer?.profile.contact'>
                <div class='contact' *ngIf='influencer?.profile.contact.publicEmail'>
                  <i class='ion-ios-mail' aria-hidden='true'></i>
                  <span>
                    {{influencer?.profile.contact.publicEmail}}
                  </span>
                </div>
                <div class='contact' *ngIf='!!influencer?.profile?.contact?.publicPhoneNumber'>
                  <i class='fa fa-phone' aria-hidden='true'></i>
                  <span>
                    {{influencer?.profile.contact.publicPhoneNumber}}
                  </span>
                </div>
              </div>
            </div>
            <div class='interests-wrapper'>
              <span ngbButtonLabel class='btn btn-xs btn-secondary category-label'
                *ngFor='let interest of influencerStats?.interests'>
                <span>{{interest}}</span>
              </span>
            </div>
          </div>

          <div class='info'>
            <h5 class='name'> {{ influencer?.profile?.fullName }}</h5>
            <div class='biography-wrapper'>
              <h1>‟</h1>
              <p class='biography' *ngIf='influencer?.profile?.biography'>{{ influencer?.profile?.biography }}</p>
              <p class='biography placeholder' *ngIf='!influencer?.profile?.biography'>biography not available</p>
            </div>
            <div class='stats-wrapper'>
              <div class='stats'>
                <div class='stats-icon'>
                  <i class='fa fa-heart' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Followers
                  </h5>
                  <h3>
                    {{ influencer?.profile?.followerCount | number }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerLocationName'>
                <div class='stats-icon'>
                  <i class='fa fa-map' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Location
                  </h5>
                  <h3>
                    {{ influencerLocationName }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerPriceRanges?.length'>
                <div class='stats-icon'>
                  <i class='fa fa-tag' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Costs
                  </h5>
                  <h3>
                    {{ costs }}
                  </h3>
                </div>
              </div>
            </div>
            <div class='socials-wrapper'>
              <div class='social-link' *ngIf='socialMediaProfiles?.instagram?.accountLink'>
                <i class='ion-logo-instagram ig-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.instagram?.accountLink}}">
                  <span> {{socialMediaProfiles?.instagram?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.youtube?.accountLink'>
                <i class='ion-logo-youtube yt-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.youtube?.accountLink}}">
                  <span> {{socialMediaProfiles?.youtube?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.facebook?.accountLink'>
                <i class='ion-logo-facebook fb-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.facebook?.accountLink}}">
                  <span> {{socialMediaProfiles?.facebook?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.tiktok?.accountLink'>
                <img class='tiktok-icon tk-color' src='../../../../assets/images/tiktok-icon.svg' alt="">
                <a target="_blank" href="{{socialMediaProfiles?.tiktok?.accountLink}}">
                  <span> {{socialMediaProfiles?.tiktok?.accountLink}} </span>
                </a>
              </div>


              <div class='social-link' *ngIf='socialMediaProfiles?.twitter?.accountLink'>
                <i class='ion-logo-twitter tw-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.twitter?.accountLink}}">
                  <span> {{socialMediaProfiles?.twitter?.accountLink}} </span>
                </a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!--  ./end IG-PROFILE  -->

    <!--  ./start VERA-PROFILE  -->
    <ng-template [ngIf]="platform=== 'VeraInstagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='print-graphics'>
        <!--
            Given that we are not utilizing page tags or pagination,
            there is no need for a bio header
           .hide
           -->
        <div class='profile-header-bar hide'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='bio'>
          <div class='avatar'>
            <div class='img-wrapper '>
              <img *ngIf='!profileImageNotLoaded' [src]='influencer.profilePicture' (error)='errorLoadingImage()'
                alt="" />
              <img *ngIf='profileImageNotLoaded' style='filter: invert(98%); opacity: 0.8'
                src='../../../../assets/images/print/user.png' alt="" />
            </div>
            <div class='contacts-wrapper'>
              <div class='contacts' *ngIf='influencer.phone || influencer.email'>
                <div class='contact' *ngIf='influencer.email'>
                  <i class='ion-ios-mail' aria-hidden='true'></i>
                  <span>
                    {{influencer.email}}
                  </span>
                </div>
                <div class='contact' *ngIf='!!influencer?.phone'>
                  <i class='fa fa-phone' aria-hidden='true'></i>
                  <span>
                    {{influencer.phone}}
                  </span>
                </div>
              </div>
            </div>
            <div class='interests-wrapper'>
              <span ngbButtonLabel class='btn btn-xs btn-secondary category-label'
                *ngFor='let interest of influencerStats?.interests'>
                <span>{{interest}}</span>
              </span>
            </div>
          </div>

          <div class='info'>
            <h5 class='name'> {{ influencer?.fullName }}</h5>
            <div class='biography-wrapper'>
              <h1>‟</h1>
              <p class='biography' *ngIf='influencer?.biography'>{{ influencer?.biography }}</p>
              <p class='biography placeholder' *ngIf='!influencer?.biography'>biography not available</p>
            </div>
            <div class='stats-wrapper'>
              <div class='stats'>
                <div class='stats-icon'>
                  <i class='fa fa-heart' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Followers
                  </h5>
                  <h3>
                    {{ influencer?.followersCount | number }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerLocationName'>
                <div class='stats-icon'>
                  <i class='fa fa-map' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Location
                  </h5>
                  <h3>
                    {{ influencerLocationName }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerPriceRanges?.length'>
                <div class='stats-icon'>
                  <i class='fa fa-tag' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Costs
                  </h5>
                  <h3>
                    {{ costs }}
                  </h3>
                </div>
              </div>
            </div>
            <div class='socials-wrapper'>
              <div class='social-link' *ngIf='socialMediaProfiles?.instagram?.accountLink'>
                <i class='ion-logo-instagram ig-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.instagram?.accountLink}}">
                  <span> {{socialMediaProfiles?.instagram?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.youtube?.accountLink'>
                <i class='ion-logo-youtube yt-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.youtube?.accountLink}}">
                  <span> {{socialMediaProfiles?.youtube?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.facebook?.accountLink'>
                <i class='ion-logo-facebook fb-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.facebook?.accountLink}}">
                  <span> {{socialMediaProfiles?.facebook?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.tiktok?.accountLink'>
                <img class='tiktok-icon tk-color' src='../../../../assets/images/tiktok-icon.svg' alt="">
                <a target="_blank" href="{{socialMediaProfiles?.tiktok?.accountLink}}">
                  <span> {{socialMediaProfiles?.tiktok?.accountLink}} </span>
                </a>
              </div>


              <div class='social-link' *ngIf='socialMediaProfiles?.twitter?.accountLink'>
                <i class='ion-logo-twitter tw-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.twitter?.accountLink}}">
                  <span> {{socialMediaProfiles?.twitter?.accountLink}} </span>
                </a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!--  ./end VERA-PROFILE  -->

    <!--  ./start FB-PROFILE  -->
    <ng-template [ngIf]="platform=== 'Facebook'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class="print-graphics">
        <div class='profile-header-bar hide'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='bio '>

          <div class='avatar'>
            <div class='img-wrapper '>
              <img *ngIf='!profileImageNotLoaded' [src]='profile?.profilePictureUrl' (error)='errorLoadingImage()'
                alt="" />
              <img *ngIf='profileImageNotLoaded' style='filter: invert(98%); opacity: 0.8'
                src='../../../../assets/images/print/user.png' alt="" />
            </div>
            <div class='contacts-wrapper'>
              <div class='contacts' *ngIf='profile?.contact'>
                <div class='contact' *ngIf='profile?.contact?.email'>
                  <i class='ion-ios-mail' aria-hidden='true'></i>
                  <span>
                    {{profile.contact.email}}
                  </span>
                </div>
                <div class='contact' *ngIf='!!profile.contact.phone'>
                  <i class='fa fa-phone' aria-hidden='true'></i>
                  <span>
                    {{profile.contact.phone}}
                  </span>
                </div>
              </div>
            </div>
            <div class='interests-wrapper'>
              <span ngbButtonLabel class='btn btn-xs btn-secondary category-label'
                *ngFor='let category of  profile?.category'>
                <span>{{category.name}}</span>
              </span>
            </div>
          </div>

          <div class='info'>
            <h5 class='name'> {{ profile?.name }}</h5>
            <div class='biography-wrapper'>
              <h1>‟</h1>
              <p class='biography' *ngIf='profile?.biography'>{{ profile?.biography}}</p>
              <p class='biography placeholder' *ngIf='!profile?.biography'>biography not available</p>
            </div>
            <div class='stats-wrapper'>
              <div class='stats'>
                <div class='stats-icon'>
                  <i class='fa fa-heart' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Followers
                  </h5>
                  <h3>
                    {{ profile?.followers | number }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerLocationName'>
                <div class='stats-icon'>
                  <i class='fa fa-map' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Location
                  </h5>
                  <h3>
                    {{ influencerLocationName }}
                  </h3>
                </div>
              </div>
              <div class='stats'>
                <div class='stats-icon'>
                  <i class='fa fa-thumbs-up' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Likes
                  </h5>
                  <h3>
                    {{ profile?.likes | number }}
                  </h3>
                </div>
              </div>
            </div>
            <div class='socials-wrapper'>
              <div class='social-link' *ngIf='socialMediaProfiles?.instagram?.accountLink'>
                <i class='ion-logo-instagram ig-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.instagram?.accountLink}}">
                  <span> {{socialMediaProfiles?.instagram?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.youtube?.accountLink'>
                <i class='ion-logo-youtube yt-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.youtube?.accountLink}}">
                  <span> {{socialMediaProfiles?.youtube?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.facebook?.accountLink'>
                <i class='ion-logo-facebook fb-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.facebook?.accountLink}}">
                  <span> {{socialMediaProfiles?.facebook?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.tiktok?.accountLink'>
                <img class='tiktok-icon tk-color' src='../../../../assets/images/tiktok-icon.svg' alt="">
                <a target="_blank" href="{{socialMediaProfiles?.tiktok?.accountLink}}">
                  <span> {{socialMediaProfiles?.tiktok?.accountLink}} </span>
                </a>
              </div>


              <div class='social-link' *ngIf='socialMediaProfiles?.twitter?.accountLink'>
                <i class='ion-logo-twitter tw-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.twitter?.accountLink}}">
                  <span> {{socialMediaProfiles?.twitter?.accountLink}} </span>
                </a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!--  ./end FB-PROFILE  -->

    <!--  ./start TT-PROFILE  -->
    <ng-template [ngIf]="platform=== 'Tiktok'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='print-graphics'>
        <div class='profile-header-bar hide'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='bio'>
          <div class='avatar'>
            <div class='img-wrapper '>
              <img *ngIf='!profileImageNotLoaded' [src]='profile?.profilePictureUrl' (error)='errorLoadingImage()'
                alt="" />
              <img *ngIf='profileImageNotLoaded' style='filter: invert(98%); opacity: 0.8'
                src='../../../../assets/images/print/user.png' alt="" />
            </div>
            <div class='contacts-wrapper'>
              <div class='contacts' *ngIf='profile?.email'>
                <div class='contact' *ngIf='profile?.email'>
                  <i class='ion-ios-mail' aria-hidden='true'></i>
                  <span>
                    {{profile?.email || 'no_email'}}
                  </span>
                </div>

              </div>
            </div>

          </div>

          <div class='info'>
            <h5 class='name'> {{ profile?.name }}</h5>
            <div class='biography-wrapper'>
              <h1>‟</h1>
              <p class='biography' *ngIf='profile?.biography'>{{ profile?.biography }}</p>
              <p class='biography placeholder' *ngIf='!profile?.biography'>biography not available</p>
            </div>
            <div class='stats-wrapper'>
              <div class='stats'>
                <div class='stats-icon'>
                  <i class='fa fa-heart' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Followers
                  </h5>
                  <h3>
                    {{profile?.profileStatistics?.followerCount | number }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='profile?.countries'>
                <div class='stats-icon'>
                  <i class='fa fa-map' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Location
                  </h5>
                  <h3>
                    {{ profile?.countries }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerPriceRanges?.length'>
                <div class='stats-icon'>
                  <i class='fa fa-tag' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Costs
                  </h5>
                  <h3>
                    {{ costs }}
                  </h3>
                </div>
              </div>
            </div>
            <div class='socials-wrapper'>
              <div class='social-link' *ngIf='socialMediaProfiles?.instagram?.accountLink'>
                <i class='ion-logo-instagram ig-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.instagram?.accountLink}}">
                  <span> {{socialMediaProfiles?.instagram?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.youtube?.accountLink'>
                <i class='ion-logo-youtube yt-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.youtube?.accountLink}}">
                  <span> {{socialMediaProfiles?.youtube?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.facebook?.accountLink'>
                <i class='ion-logo-facebook fb-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.facebook?.accountLink}}">
                  <span> {{socialMediaProfiles?.facebook?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.tiktok?.accountLink'>
                <img class='tiktok-icon tk-color' src='../../../../assets/images/tiktok-icon.svg' alt="">
                <a target="_blank" href="{{socialMediaProfiles?.tiktok?.accountLink}}">
                  <span> {{socialMediaProfiles?.tiktok?.accountLink}} </span>
                </a>
              </div>


              <div class='social-link' *ngIf='socialMediaProfiles?.twitter?.accountLink'>
                <i class='ion-logo-twitter tw-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.twitter?.accountLink}}">
                  <span> {{socialMediaProfiles?.twitter?.accountLink}} </span>
                </a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!--  ./end TT-PROFILE  -->

    <!--  ./start YT-PROFILE  -->
    <ng-template [ngIf]="platform==='Youtube'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='print-graphics'>
        <div class='profile-header-bar hide'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='bio'>
          <div class='avatar'>
            <div class='img-wrapper '>
              <img *ngIf='!profileImageNotLoaded' [src]='profile?.channel?.profilePictureUrl'
                (error)='errorLoadingImage()' alt="" />
              <img *ngIf='profileImageNotLoaded' style='filter: invert(98%); opacity: 0.8'
                src='../../../../assets/images/print/user.png' alt="" />
            </div>
            <div class='contacts-wrapper'>
              <div class='contacts' *ngIf='profile?.channel?.email'>
                <div class='contact' *ngIf='profile?.channel?.email'>
                  <i class='ion-ios-mail' aria-hidden='true'></i>
                  <span>
                    {{profile?.channel?.email || 'no_email'}}
                  </span>
                </div>

              </div>
            </div>
            <div class='interests-wrapper'>
              <span ngbButtonLabel class='btn btn-xs btn-secondary category-label'
                *ngFor='let category of  profile?.interests'>
                <span>{{category}}</span>
              </span>
            </div>
          </div>

          <div class='info'>
            <h5 class='name'> {{ profile?.channel?.name }}</h5>
            <div class='biography-wrapper'>
              <h1>‟</h1>
              <p class='biography' *ngIf='profile?.channel?.description'>{{ profile?.channel?.description }}</p>
              <p class='biography placeholder' *ngIf='!profile?.channel?.description'>biography not available</p>
            </div>
            <div class='stats-wrapper'>
              <div class='stats'>
                <div class='stats-icon'>
                  <i class='fa fa-heart' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Subscribers
                  </h5>
                  <h3>
                    {{profile?.channel?.statistics?.subscriberCount | number }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='profile?.channel?.countries'>
                <div class='stats-icon'>
                  <i class='fa fa-map' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Location
                  </h5>
                  <h3>
                    {{ profile?.channel?.countries }}
                  </h3>
                </div>
              </div>
              <div class='stats' *ngIf='influencerPriceRanges?.length'>
                <div class='stats-icon'>
                  <i class='fa fa-tag' aria-hidden='true'></i>
                </div>
                <div class='stats-value'>
                  <h5>
                    Costs
                  </h5>
                  <h3>
                    {{ costs }}
                  </h3>
                </div>
              </div>
            </div>
            <div class='socials-wrapper'>
              <div class='social-link' *ngIf='socialMediaProfiles?.instagram?.accountLink'>
                <i class='ion-logo-instagram ig-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.instagram?.accountLink}}">
                  <span> {{socialMediaProfiles?.instagram?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.youtube?.accountLink'>
                <i class='ion-logo-youtube yt-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.youtube?.accountLink}}">
                  <span> {{socialMediaProfiles?.youtube?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.facebook?.accountLink'>
                <i class='ion-logo-facebook fb-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.facebook?.accountLink}}">
                  <span> {{socialMediaProfiles?.facebook?.accountLink}} </span>
                </a>
              </div>

              <div class='social-link' *ngIf='socialMediaProfiles?.tiktok?.accountLink'>
                <img class='tiktok-icon tk-color' src='../../../../assets/images/tiktok-icon.svg' alt="">
                <a target="_blank" href="{{socialMediaProfiles?.tiktok?.accountLink}}">
                  <span> {{socialMediaProfiles?.tiktok?.accountLink}} </span>
                </a>
              </div>


              <div class='social-link' *ngIf='socialMediaProfiles?.twitter?.accountLink'>
                <i class='ion-logo-twitter tw-color'></i>
                <a target="_blank" href="{{socialMediaProfiles?.twitter?.accountLink}}">
                  <span> {{socialMediaProfiles?.twitter?.accountLink}} </span>
                </a>
              </div>


            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!--  ./end YT-PROFILE  -->


    <!-- ./end Printed profile summary -->
  </div>

  <!--AUDIENCE AGE DISTRIBUTION-->
  <div *ngSwitchCase="'audience-age-distribution'" class='page-section'>
    <!--   ./start AUDIENCE AGE DISTRIBUTION-->


    <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
      <div class='profile-header-bar'>
        <div *ngIf='pageTag && hasPageTag'>
          page [ {{page}} / {{totalPages}} ]
        </div>
      </div>
      <div class='header'>
        <span>Audience Age Distribution</span>
      </div>
      <div class='info'>
        <div class='desc'>
          {{description}}
        </div>
        <div class='chips'>
          <span class='info-chip' *ngIf='adultAudiencePercentage'><strong>{{ADULT_AUDIENCE_AGE}}
              + Audience</strong>: {{adultAudiencePercentage}}%</span>
        </div>

      </div>
      <div class='chart-wrapper'>
        <ngx-echarts-bar [isAgeGender]=true *ngIf='genderOverlay && ageGenderDataOptions?.series?.length > 0'
          [dataOptions]='ageGenderDataOptions'></ngx-echarts-bar>
        <ngx-echarts-bar *ngIf='!genderOverlay && ageDataOptions?.series?.length > 0' [dataOptions]='ageDataOptions'>
        </ngx-echarts-bar>
      </div>
    </div>


    <!--   ./end AUDIENCE AGE DISTRIBUTION-->
  </div>

  <!--AUDIENCE GENDER DISTRIBUTION-->
  <div *ngSwitchCase="'audience-gender-distribution'" class='page-section'>
    <!-- ./start Audience Gender Distribution -->
    <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
      <div class='profile-header-bar'>
        <div *ngIf='pageTag && hasPageTag'>
          page [ {{page}} / {{totalPages}} ]
        </div>
      </div>
      <div class='header'>
        <span>Audience Gender Distribution</span>
      </div>
      <div class='info'>
        <div class='desc'>
          {{description}}
        </div>
      </div>
      <div class='chart-wrapper'>
        <ngx-echarts-pie *ngIf='genderDataOptions?.series?.length > 0' [dataOptions]='genderDataOptions'
          [displayLabel]="true">
        </ngx-echarts-pie>
      </div>
    </div>
    <!--    ./end FB-->

    <!--  ./end Audience Gender Distribution-->
  </div>

  <!--AUDIENCE LOCATION DISTRIBUTION-->
  <div *ngSwitchCase="'audience-location-distribution'" class='page-section'>
    <!-- ./start  AUDIENCE LOCATION DISTRIBUTION -->


    <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
      <div class='profile-header-bar'>
        <div *ngIf='pageTag && hasPageTag'>
          page [ {{page}} / {{totalPages}} ]
        </div>
      </div>
      <div class='header'>
        <span>Audience Location Distribution</span>
      </div>
      <div class='info'>
        <div class='desc'>
          {{description}}
        </div>
      </div>
      <div class='space'></div>
      <div class='chart-wrapper'>
        <div class='location-map'>
          <div class='audience-table'>
            <table>
              <tr>
                <th style="width: 100%">Country</th>
                <th>Audience Percentage</th>
              </tr>
              <tr *ngFor='let data of distributionData ; let i=index'>
                <td *ngIf='i<11'>{{data.label}}</td>
                <td *ngIf='i<11'>{{data.value | number}} %</td>
              </tr>
            </table>
          </div>
          <ngx-echarts-bubble-map [mapData]='distributionData'></ngx-echarts-bubble-map>
        </div>
      </div>
    </div>
    <!-- ./end  AUDIENCE LOCATION DISTRIBUTION -->
  </div>

  <!--ENGAGEMENT-->
  <div *ngSwitchCase="'engagement'" class='page-section'>
    <!-- ./start  ENGAGEMENT  -->
    <!--    ./start IG || Vera-->
    <ng-template [ngIf]="platform=== 'Instagram' || platform=== 'VeraInstagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart print-graphics'>
        <div class='profile-header-bar'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Engagement</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
          <div class='chips'>
            <span class='info-chip'><strong>Est. Reach: </strong>{{influencerStats?.estimatedReach |
              formatNumber:2}}</span>
            <span class='info-chip'><strong>Est. Impressions: </strong>{{influencerStats?.estimatedImpressions |
              formatNumber:2}}</span>
          </div>
        </div>
        <div class='chart-wrapper'>
          <div class='engagement-charts space'>
            <ngx-gauge *ngFor='let engagement of engagementData' [gaugeOptions]='engagement'
              [printingStyle]="'printed-pie'"></ngx-gauge>
          </div>
        </div>

      </div>
    </ng-template>
    <!--    ./end IG || Vera-->

    <!--    ./start FB  => custom FB || TT || YT Chart !-->
    <ng-template [ngIf]="platform=== 'Facebook'|| platform=== 'Tiktok' || platform=== 'Youtube'">
      <div [ngStyle]="{'maxHeight.px': heights[platform+id]}" class='printed-chart print-graphics'>
        <div class='profile-header-bar '>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Engagement</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
          <div class='chips'>
            <span class='info-chip'><strong>Est. Reach: </strong>{{profile?.estimatedReach ||
              profile?.channel?.estimatedReach | formatNumber:2}}</span>
            <span class='info-chip'><strong>Est. Impressions: </strong>{{profile?.estimatedImpressions
              ||profile?.channel?.estimatedImpressions |
              formatNumber:2}}</span>
          </div>
        </div>
        <div class='chart-wrapper'>
          <!--       TODO review space class below-->
          <div class='engagement-facebook-charts'>
            <div class="reactions-engagement" *ngIf="reactionsEngagementData?.length">
              <ngx-info *ngFor="let engagement of reactionsEngagementData" [infoOptions]="engagement" [compact]="true">
              </ngx-info>
            </div>
            <div class="engagement">
              <div *ngFor="let engagement of engagementData; index as i">
                <div class="ngx-info">
                  <ngx-info [infoOptions]="engagement"></ngx-info>
                  <div *ngIf="i < engagementData?.length - 1" class="vertical-separator"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-template>
    <!--    ./end FB || TT || YT-->

    <!-- ./end  ENGAGEMENT  -->
  </div>

  <!--FOLLOWERS TIMELINE DISTRIBUTION-->
  <div *ngSwitchCase="'follower-timeline'" class='page-section'>
    <!--  ./start FOLLOWERS TIMELINE DISTRIBUTION  -->
    <!--    ./start IG || Vera-->
    <ng-template [ngIf]="platform=== 'Instagram' || platform=== 'VeraInstagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
        <div class='profile-header-bar'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Followers Timeline</span>
        </div>
        <div class='info'>
          <div class='desc'>
            <!-- TODO: need a review ! -->
            {{description}}
          </div>
        </div>
        <div class='chart-wrapper'>
          <ngx-followers-timeline-card [followersTimeSeries]='influencerStats?.followersTimeseries'
            [platformName]='platform' [printable]='true'>
          </ngx-followers-timeline-card>
        </div>
      </div>
    </ng-template>
    <!--    ./end IG || Vera-->

    <!--    ./start FB || YT || TT-->
    <ng-template [ngIf]="platform=== 'Facebook' || platform=== 'Tiktok' || platform=== 'Youtube'">
      <div [ngStyle]="{'height.px': heights[id]}" class='printed-chart print-graphics'>
        <div class='profile-header-bar '>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Followers Timeline</span>
        </div>
        <div class='info'>
          <div class='desc'>
            <!-- TODO: need a review ! -->
            {{description}}
          </div>
        </div>
        <div class='chart-wrapper'>
          <ngx-followers-timeline-card [followersTimeSeries]='followersTimeline' [platformName]='platform'
            [printable]='true'>
          </ngx-followers-timeline-card>
        </div>

      </div>
    </ng-template>
    <!--    ./end FB || YT || TT-->

    <!--  ./end FOLLOWERS TIMELINE DISTRIBUTION  -->
  </div>

  <!--FOLLOWERS ENGAGEMENT TIMELINE-->
  <div *ngSwitchCase="'engagement-timeline'" class='page-section'>
    <!-- ./start  FOLLOWERS ENGAGEMENT TIMELINE  -->
    <!--    ./start IG || Vera-->
    <ng-template [ngIf]="platform=== 'Instagram' || platform=== 'VeraInstagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
        <div class='profile-header-bar print-graphics'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Engagement Timeline</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
          <div class='chips'>
          </div>
        </div>
        <div class='chart-wrapper'>
          <div class='engagement-charts'>
            <ngx-echarts-area-stack [dataOptions]='engagementOptions' [openNewTabURL]='true'></ngx-echarts-area-stack>
          </div>
        </div>
      </div>
    </ng-template>
    <!--    ./end IG || Vera-->

    <!--    ./start FB-->
    <ng-template [ngIf]="platform=== 'Facebook'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart print-graphics'>
        <div class='profile-header-bar '>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Engagement Timeline</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
          <div class='chips'>
          </div>
        </div>
        <div class='chart-wrapper'>
          <div class='engagement-charts'>
            <ngx-echarts-area-stack [dataOptions]='engagementOptions' [openNewTabURL]='true'></ngx-echarts-area-stack>
          </div>
        </div>
      </div>
    </ng-template>
    <!--    ./end FB-->

    <!-- ./end  FOLLOWERS ENGAGEMENT TIMELINE  -->
  </div>

  <!--BRANDED CONTENT DISTRIBUTION-->
  <div *ngSwitchCase="'branded-content-distribution'" class='page-section'>
    <!-- ./start  BRANDED CONTENT DISTRIBUTION -->
    <!--    ./start IG -->
    <ng-template [ngIf]="platform=== 'Instagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
        <div class='profile-header-bar'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Branded Content Distribution</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
        </div>
        <div class='chart-wrapper'>
          <div class='branded-content-charts'>
            <ngx-branded-content-distribution-card [printable]='true'></ngx-branded-content-distribution-card>
          </div>
        </div>
      </div>
    </ng-template>
    <!--    ./end IG -->

    <!--    ./start Vera -->
    <ng-template [ngIf]="platform=== 'VeraInstagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
        <div class='profile-header-bar'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Branded Content Distribution</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
        </div>
        <div class='chart-wrapper'>
          <div class='branded-content-charts'>
            <ngx-branded-content-distribution-card [printable]='true' [platform]="'VeraInstagram'"></ngx-branded-content-distribution-card>
          </div>
        </div>
      </div>
    </ng-template>
    <!--    ./end Vera -->

    <!-- ./end BRANDED CONTENT DISTRIBUTION -->
  </div>

  <!--AUDIENCE INTEREST DISTRIBUTION-->
  <div *ngSwitchCase="'audience-interest-distribution'" class='page-section'>
    <!-- ./start AUDIENCE INTEREST DISTRIBUTION -->
    <ng-template [ngIf]="platform=== 'Instagram'">
      <div [ngStyle]="{'maxHeight.px': heights[id]}" class='printed-chart  print-graphics'>
        <div class='profile-header-bar'>
          <div *ngIf='pageTag && hasPageTag'>
            page [ {{page}} / {{totalPages}} ]
          </div>
        </div>
        <div class='header'>
          <span>Audience Interest Distribution</span>
        </div>
        <div class='info'>
          <div class='desc'>
            {{description}}
          </div>
          <span class='desc'>Primary Influencer Related Interests</span>
          <div class='btn-group btn-divided-group btn-group-full-width influencer-interests'>
            <span ngbButtonLabel class='btn btn-xs btn-primary category-label'
              *ngFor='let interest of influencerStats?.interests'>
              <span>{{interest}}</span>
            </span>
          </div>
        </div>
        <div class='chart-wrapper'>
          <span>Other Interests</span>
          <div style='width: 100%; height: 55%'>
            <ngx-echarts-horizontal-bar [dataOptions]='audienceInterestsDataOptions' colorChoice='success'>
            </ngx-echarts-horizontal-bar>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- ./end AUDIENCE INTEREST DISTRIBUTION-->
  </div>

</div>